import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import { DateTime } from 'luxon';


const getMultipleRoomsReportData = async (roomsToDownload, downloadTime, api, period) => {

    let roomIds = roomsToDownload.reduce((acc, room) => {
        if(room.checked) {
            acc.push(room.id)
        }
        return acc;
    }, []);
    
    let response = await api.getMultipleRoomsReport(roomIds, downloadTime.from.toISO(), downloadTime.to.toISO(), period);

    return response;

}

const reportData = async (filteredResults, domain_name, downloadTime) => {

    let csvData = [
        ['Time', 'Room', 'Waiting', 'Active', 'Wait']
    ];

    let sorted = [];

    let filename = 'CrowdHandler Report';

    if (domain_name.length) {
        let name = domain_name[0].name.split('//')[1].split(".").join('-');
        filename = name;
    }

    filename = `${filename}-${downloadTime.from.toFormat('yyyy-LL-dd-HH-mm')}-${downloadTime.to.toFormat('yyyy-LL-dd-HH-mm')}.csv`;

    // filename = `${filename}.csv`;

    for (let i = 0; i < filteredResults.length; i++) {
        const row = filteredResults[i];
        let date = DateTime.fromISO(row.time.toISO());
        let rowData= {
            time: row.time,
            time_display:  `${date.toLocaleString(DateTime.TIME_24_SIMPLE)} ${date.toLocaleString()}`,
            title: row.room_title,
            waiting: row.waiting.toString(),
            transacting: row.transacting.toString(),
            wait_time: row.wait.toString(),
        };
        sorted.push(rowData);
    }

    sorted = sortBy(sorted, 'time');

    csvData = sorted.map((row) => {
        return [
            row.time_display,
            row.title,
            row.waiting,
            row.transacting,
            row.wait_time,
        ]
    });

    csvData = csvData.reverse();
    csvData.push(['Time', 'Room', 'Waiting', 'Active', 'Wait']);
    csvData = csvData.reverse();

    convertToCsv(filename, csvData);

}

const generateChartDownloadData = async (rooms, downloadRooms, downloadTime, api) => {

    let roomIds = [];
    let roomNames = [];
    let roomsById = groupBy(rooms, 'id');

    
    
    for (const key in downloadRooms) {
        if (downloadRooms.hasOwnProperty(key)) {
            if (downloadRooms[key] && roomsById[key]) {
                roomIds.push(key);
                roomNames.push(roomsById[key][0].title);
            }
        }
    }
    
    let response = await api.getMultipleRoomsReport(roomIds, downloadTime.from.date.toISO(), downloadTime.to.date.toISO());

    let results = [];
    for (let i = 0; i < response.length; i++) {
        results = results.concat( response[i].data.result );
        
    }
    results = results.map((result) => {
        result.time = DateTime.fromISO(result.time, { zone: 'utc' });    
        return result;
    });

    let filename = `${roomNames.join('-')}.csv`;
  
    let filteredData = results.filter((slot)=>{
        return roomIds.indexOf(slot.room_id) !== -1;
    })
   
    let csvData = [
        ['Time', 'Room', 'Waiting']
    ]

    for (let i = 0; i < filteredData.length; i++) {
        const row = filteredData[i];
        let date = DateTime.fromISO(row.time.toISO());
        let rowData= [
            `${date.toLocaleString(DateTime.TIME_24_SIMPLE)} ${date.toLocaleString()}`,
            roomsById[row.room_id][0].title,
            row.waiting.toString(),
        ];
        csvData.push(rowData);
    }

    convertToCsv(filename, csvData);
}

const convertToCsv = (fName, rows) => {
    let csv = '';
    for (let i = 0; i < rows.length; i++) {
        let row = rows[i];
        for (let j = 0; j < row.length; j++) {
            let val = row[j] === null ? '' : row[j].toString();
            val = val.replace(/\t/gi, " ");
            if (j > 0)
                csv += ',';
            csv += val;
        }
        csv += '\n';
    }

    // for UTF-16
    let cCode, bArr = [];
    bArr.push(255, 254);
    for (let n = 0; n < csv.length; ++n) {
        cCode = csv.charCodeAt(n);
        bArr.push(cCode & 0xff);
        bArr.push(cCode / 256 >>> 0);
    }

    let blob = new Blob([new Uint8Array(bArr)], { type: 'text/csv;charset=UTF-16LE;' });
    if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, fName);
    } else {
        let link = document.createElement("a");
        if (link.download !== undefined) {
            let url = window.URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("type", 'text/csv');
            link.setAttribute("download", fName);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        }
    }
}

export {generateChartDownloadData, getMultipleRoomsReportData, reportData};