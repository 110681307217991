import React, { useState, useRef, useEffect, useContext } from "react";
import {
    useHistory,
    useLocation,
    Redirect
} from "react-router-dom";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { AuthContext } from '../../providers/AuthProvider';
import queryString from 'query-string';

import {
    Container,
    Grid,
    Button,
    FormControl,
    InputLabel,
    OutlinedInput,
    Typography,
    FormHelperText,
    InputAdornment,
    IconButton,
    Link
} from "@material-ui/core";
import { Link as RouterLink } from 'react-router-dom';
import { EyeIcon, EyeOffIcon } from '../../icons';
import { makeStyles } from '@material-ui/core/styles';


import ErrorSnackBar from '../../components/ErrorSnackBar';

const useStyles = makeStyles((theme) => ({
    verificationPanel: {
        marginBottom: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    resendCodePanel: {
        marginTop: theme.spacing(2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: theme.palette.primary.main,
        backgroundColor: '#fff',
    },
}));

var Buffer = require('buffer/').Buffer;



export default function NewPassword({ state, signIn, authorised, ...rest }) {

    const chAuth = useContext(AuthContext);

    const classes = useStyles();

    let location = useLocation();
    let history = useHistory();
    let { from } = location.state || "/";
    let [showPassword, setShowPassword] = useState(false);
    let [showResetPasswordPanel, setShowResetPasswordPanel] = useState(false);

    let [username, setUsername] = useState();
    let [region, setRegion] = useState();
    let [session, setSession] = useState();
    let [displayMFACodeInput, setDisplayMFACodeInput] = useState();
    let [alertBarStatus, setAlertBarStatus] = useState('success');
    let [authenticating, setAuthenticating] = useState('success');
    let [fields, setFields] = useState({
        mfa_code: ''
    });

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    // get any params so we can attempt an auto login
    let params = queryString.parse(location.search)




    let { dispatch } = rest;
    // Store a ref so we can clear up when the component is unmounted
    let location_state = (location.state) ? location.state : undefined;
    let [message, setMessage] = useState('');
    let [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);

    // Redirect to dashboard if already authorised
    useEffect(() => {
        if (authorised) {
            history.replace('/');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

   

    const formik = useFormik({
        initialValues: {
            username: (location_state && location_state.email) ? location_state.email : '',
            newPassword: '',
            code: ''
        },
        validationSchema: Yup.object({
            username: Yup.string().required('Required'),
            newPassword: Yup.string()
                .required('A password is required')
                .min(8, "Password requires a minimum of 8 characters")
                .matches(/\d+/, { message: 'Password must contain a number' })
                .matches(/[a-z]+/, { message: 'Password must contain lowercase letters' })
                .matches(/[A-Z]+/, { message: 'Password must contain uppercase letters' })
                .matches(/[=+\-^$*.[\]{}()?"!@#%&/\\,><':;|_~`]/, { message: 'Password must contain a special character' })
                .test('test-whitespace', 'Spaces are not allowed',
                    function (value) {
                        var inValid = /\s/;
                        var k = inValid.test(value);
                        return !k;
                    })
                .test('test-invalid character', 'An invalid character has been found',
                    function (value) {
                        var inValid = /^[a-zA-Z0-9=+\-^$*.[\]{}()?"!@#%&\/\\,><':;|_~`]*$/;
                        var k = inValid.test(value);
                        return k;
                    }),
            code: Yup.string().required('Required'),
        }),
        onSubmit: async (values) => {
            dispatch({ type: 'SET_IS_LOADING', payload: true });

            let result = await chAuth.confirmResetPassword({
                username: values.username.trim(),
                confirmationCode: values.code.trim(),
                newPassword: values.newPassword.trim()
            });

            if (result.error) {
                setMessage(result.message)
                setOpenErrorSnackbar(true);
                setShowResetPasswordPanel(true)
                dispatch({ type: 'SET_IS_LOADING', payload: false });
            } else {
                let signin_response = await chAuth.handleSignIn({
                    username: values.username,
                    password: values.newPassword
                });

                let { cognitoUser, ChallengeName, Region, Session, error } = signin_response;

                if (cognitoUser) {
                    signIn({ history, from, reason: null, Region });
                }

                if (ChallengeName) {

                    setUsername(values.username.trim());
                    setRegion(Region);
                    setSession(Session);


                    if (ChallengeName === 'SMS_MFA' || ChallengeName === 'SOFTWARE_TOKEN_MFA') {
                        setDisplayMFACodeInput(true);
                        dispatch({ type: 'SET_IS_LOADING', payload: false });
                    }

                }

                if (error) {
                    setMessage(error.message)
                    setAlertBarStatus('error');
                    setOpenErrorSnackbar(true);
                    dispatch({ type: 'SET_IS_LOADING', payload: false });
                }
            }

        }
    })

    useEffect(() => {

        try {
            if (params.u) {
    
                let email = Buffer.from(params.u, 'base64');
                email = email.toString('ascii');
    
                formik.setFieldValue('username', email.trim())
                
            }
        } catch (error) {
            
        }
        
    }, []);

    const isMountedRef = useRef(null);

    useEffect(() => {
        isMountedRef.current = true;
        dispatch({ type: 'SET_IS_LOADING', payload: false });
        return () => {
            isMountedRef.current = false;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const closeErrorSnackbar = () => {
        setOpenErrorSnackbar(false)
    }

    const handleFields = (event) => {
        setFields({
            ...fields,
            [event.target.name]: event.target.value.trim()
        })
    }

    const handleMFASubmit = async (event) => {
        event.preventDefault();
        setAuthenticating(true);
        dispatch({ type: 'SET_IS_LOADING', payload: true });
        // You need to get the code from the UI inputs
        // and then trigger the following function with a button click

        // If MFA is enabled, sign-in should be confirmed with the confirmation code

        let response = await chAuth.respondToAuthChallenge({
            UserCode: fields.mfa_code.trim(),
            Username: username,
            ChallengeName: 'SOFTWARE_TOKEN_MFA',
            Region: region,
            Session: session
        });

        let { cognitoUser, type, message } = response;

        if (cognitoUser) {
            signIn({ history, from, reason: null });
        }

        if (type) { // there's been a exception
            setMessage(message);
            setAlertBarStatus('error');
            setOpenErrorSnackbar(true);
            dispatch({ type: 'SET_IS_LOADING', payload: false });
        }


    }

    return (

        <>


            {authorised
                ?
                <Redirect to="/" />
                :

                <Container maxWidth='sm'>
                    {!showResetPasswordPanel && !displayMFACodeInput
                        ?
                        <Grid container component="form">
                            <Grid item xs={12}>
                                <FormControl fullWidth margin="normal" variant="outlined">
                                    <InputLabel htmlFor="username">Username</InputLabel>
                                    <OutlinedInput
                                        type="text"
                                        id='username'
                                        {...formik.getFieldProps('username')}
                                        labelWidth={63}
                                        placeholder="Username"
                                        aria-label="Username"
                                        aria-describedby="username"
                                    ></OutlinedInput>
                                </FormControl>
                                {formik.touched.username && formik.errors.username ? <Typography variant="subtitle2" color="error">{formik.errors.username}</Typography> : null}

                            </Grid>

                            <Grid item xs={12}>
                                <Typography>
                                    We’ve sent a code to your email address. Enter your new password and enter the code below to reset your password.
                                </Typography>
                            </Grid>


                            <Grid item xs={12}>
                                <FormControl fullWidth margin="normal" variant="outlined">
                                    <InputLabel htmlFor="newPassword">New Password</InputLabel>
                                    <OutlinedInput
                                        type={showPassword ? 'text' : 'password'}
                                        id='newPassword'
                                        {...formik.getFieldProps('newPassword')}
                                        labelWidth={90}
                                        placeholder="New Password"
                                        aria-label="New Password"
                                        aria-describedby="newPassword"
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                >
                                                    {showPassword ? <EyeOffIcon /> : <EyeIcon />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    ></OutlinedInput>
                                </FormControl>
                                <FormHelperText><strong>Required.</strong> Passwords require at least 8 characters and must include letters, numbers and at least one special character from this set<br /><strong>{`= + - ^ $ * . [ ] { } ( ) ? " ! @ # % & / \\ , > < ' : ; | _ ~ \` `}</strong></FormHelperText>
                                {formik.touched.newPassword && formik.errors.newPassword ? <Typography variant="subtitle2" color="error">{formik.errors.newPassword}</Typography> : null}

                            </Grid>


                            <Grid item xs={12}>
                                <FormControl fullWidth margin="normal" variant="outlined">
                                    <InputLabel htmlFor="username">Code</InputLabel>
                                    <OutlinedInput
                                        type="text"
                                        id='code'
                                        {...formik.getFieldProps('code')}
                                        labelWidth={34}
                                        placeholder="Code"
                                        aria-label="Code"
                                        aria-describedby="code"
                                    ></OutlinedInput>
                                </FormControl>
                                {formik.touched.code && formik.errors.code ? <Typography variant="subtitle2" color="error">{formik.errors.code}</Typography> : null}
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={2} direction="column">
                                    <Grid item>
                                        <Button variant="contained"
                                            disableElevation
                                            disabled={!formik.isValid || !formik.dirty}
                                            size="small"
                                            color="secondary" onClick={formik.handleSubmit}>Reset Password</Button>
                                    </Grid>
                                    <Grid item>

                                        <Button variant="contained"
                                            color="primary"
                                            disableElevation
                                            size="small"
                                            component={RouterLink}
                                            to="/login"
                                        >Login</Button>
                                    </Grid>
                                </Grid>
                            </Grid>

                        </Grid>
                        :
                        null
                    }

                    {showResetPasswordPanel && !displayMFACodeInput

                        ?
                        <Grid container spacing={2} justifyContent="center" alignItems="center" >
                            <Grid item xs={12} style={{ textAlign: 'center' }}>
                                <Typography paragraph variant="h5">Your code has expired.</Typography>
                                <Typography paragraph>Please request a new code.</Typography>
                            </Grid>
                            <Grid item xs={12} style={{ textAlign: 'center' }}>
                                <Button variant="contained"
                                    color="primary"
                                    disableElevation
                                    size="small"
                                    component={RouterLink}
                                    to="/reset-password"
                                >Forgot Password</Button>
                            </Grid>
                        </Grid>
                        :
                        null}

                    {displayMFACodeInput
                        ?
                        <Grid container autoComplete="off" component="form" onSubmit={(event) => handleMFASubmit(event)} className={classes.verificationPanel}>
                            <Grid>

                                <Typography>Enter an MFA code to complete sign-in.</Typography>
                                <FormControl fullWidth margin="normal" variant="outlined">
                                    <InputLabel htmlFor="password">MFA Code</InputLabel>
                                    <OutlinedInput
                                        type="text"
                                        id='mfa_code'
                                        name='mfa_code'
                                        value={fields.mfa_code}
                                        onChange={(event) => handleFields(event)}
                                        labelWidth={60}
                                        placeholder="MFA Code"
                                        aria-label="mfa_code"
                                        aria-describedby="mfa_code"
                                    ></OutlinedInput>
                                </FormControl>

                                <Button variant="contained"
                                    onClick={handleMFASubmit}
                                    size="small"
                                    disableElevation
                                    type="submit"
                                    color="secondary">
                                    Submit
                                </Button>
                                <Typography style={{ marginTop: 20 }}>
                                    <Link target="_blank" href="https://www.crowdhandler.com/docs/80001081002-two-factor-authentication">Need help?</Link>
                                </Typography>
                            </Grid>
                        </Grid>
                        :
                        null
                    }


                    <ErrorSnackBar message={message} open={openErrorSnackbar} closeErrorSnackbar={closeErrorSnackbar} />
                </Container>
            }
        </>
    )
}