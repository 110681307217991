import { handleSignInSuccess } from './handleSignInSuccess.js';

const handleRespondToAuthChallenge = async (payload) => {
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", process.env.REACT_APP_CH_COGNITO_API_KEY_EU);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(payload);

  
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
  
    try {
      let response = await fetch(`${process.env.REACT_APP_CH_COGNITO_API_EU}respond-mfa`, requestOptions);
      response = await response.json();

      if (response.ChallengeName) {
        return {ChallengeName: response.ChallengeName};
      }

      if (response.type) {
        return {type: response.type, message: response.message};
      }


      if (response) {
        let {cognitoUser} = await handleSignInSuccess(response);
        return {cognitoUser}
      }
      return response;
    } catch (error) {
      console.log(error);
    }
}

export default handleRespondToAuthChallenge;