import React, { useState, useEffect, useReducer, useRef } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
    Typography,
    FormControl,
    InputLabel,
    OutlinedInput,
    Button,
    Grid,
    FormHelperText,
    Stepper,
    Step,
    StepLabel,
    InputAdornment,
    Divider,
    LinearProgress,
    CircularProgress,
    IconButton,
    Link,
    Collapse,
} from "@material-ui/core";

import '../PurgeButton.css';

import HelpCircleIcon from '../icons/HelpCircleIcon';
import ArrowDownCircleIcon from '../icons/ArrowDownCircleIcon';
import ArrowUpCircleIcon from '../icons/ArrowUpCircleIcon';
import RotateCWIcon from '../icons/RotateCWIcon';

import PendingCertificate from './proxyOnboarding/PendingCertificate';
import Deployed from './proxyOnboarding/Deployed';
import Pending from './proxyOnboarding/Pending';
import SetOrigin from './proxyOnboarding/SetOrigin';
import DistributionPending from './proxyOnboarding/DistributionPending';
import DomainProxyURLExclusionList from './proxyOnboarding/DomainProxyURLExclusionList';
import ErrorSnackBar from './ErrorSnackBar';
import difference from 'lodash/difference';
import intersection from 'lodash/intersection';

import { CopyButton } from './';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        paddingTop: theme.spacing(2)
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    stepper: {
        paddingTop: 0,
        '& .MuiStepIcon-root.MuiStepIcon-completed': {
            color: theme.palette.success.main
        }
    },
    code: {
        color: theme.palette.secondary.dark
    },
    proxyInfo: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        '& p': {
            marginBottom: theme.spacing(1),
        }
    },
    proxyInfoHeader: {
        paddingBottom: 0,

        '& h6': {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2)
        }
        // lineHeight: 1
    },
    pendingPanel: {
        marginTop: '10px'
    },
    advancedPanel: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    purgeButton : {
        '& svg' : {
            transform: 'rotate(90deg)',
            transitionProperty: 'all',
            transitionDuration: '2s'
        }
    },
    bypassToken : {
        borderRadius: 12,
        backgroundColor: 'rgb(247, 250, 252)',
        fontSize: '16px',
        
        fontFamily: 'monospace',
        '&:hover' : {
            backgroundColor: 'rgb(247, 250, 252)',
        },
        '& .MuiInputBase-root .MuiOutlinedInput-notchedOutline' : {
            borderColor: 'rgba(0, 0, 0, 0.23)',
        },
        '& .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline' : {
            borderColor: 'rgba(0, 0, 0, 0.23)',
        },

    }

}));


const stripProtocol = (domainName) => {
    const regex = /http[s?]:\/\//gm;
    return domainName.replace(regex, '');
}


const DomainProxyOnboarding = ({ api, domainName, settings, setProcessing }) => {

    /**
     * There are a few states that are being set
     * 
     * 0.   certificateStatus - MISSING
     *      Certificate is being requested
     * 
     * 10.  certificateStatus - PENDING_VALIDATION
     *      Pending Certificate set
     * 
     * 20.  originStatus - OK or ERROR
     *      USe found origin or set their own
     * 
     * 40.  PENDING - PENDING
     *      Traffic to cdn-test2.crowdhandler.com is not yet traversing through Crowdhandler
     * 
     * 50.  trafficStatus- DEPLOYED
     *      Customer traffic is pointing to CrowdHandler.
     */

    const classes = useStyles();
    const [activeStep, setActiveStep] = useState(0);
    const [activeContent, setActiveContent] = useState(0);
    const [requestingCertificate, setRequestingCertificate] = useState(false);
    const [requestingCertificateError, setRequestingCertificateError] = useState(false);
    const [, setCreateDistributionTime] = useState();
    const [createDistributionInterval, setCreateDistributionInterval] = useState();
    const [displayCloudFrontUsedError, setDisplayCloudFrontError] = useState(false);
    const [flushCacheMessage, setFlushCacheMessage] = useState();
    const [purgingCache, setPurgingCache] = useState(false);
    
    const [open, setOpen] = React.useState(false);
    const handleClick = () => {
        setOpen(!open);
    };
    
    const [message, setMessage] = useState('');
    const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
    const closeErrorSnackbar = () => {
        setOpenErrorSnackbar(false)
    }
    const [alertBarStatus, setAlertBarStatus] = useState('success')
    const [checkDist, setCheckDist] = useState(false)
    const [updatingList, setUpdatingList] = useState(false)


    const isMounted = useRef(true);
        
    let requestedCertResponseInterval;

    useEffect(() => {

        const checkDistributionUpdate = async () => {

            let intervalId = setInterval(async () => {
                let distributionStatus = await api.getDistributionStatus(stripProtocol(domainName));
                if (distributionStatus.status === 'Deployed') {
                    clearInterval(intervalId);
                    setCheckDist(false);
                    
                    dispatch({ type: 'UPDATE_STEP', payload: { id: 2, title: "CrowdHandler Ready" } });
                    dispatchStatus({ type: 'UPDATE_DISTRIBUTION_STATUS', payload: distributionStatus });
                    let originStatus = await api.getOriginStatus(stripProtocol(domainName));
                    dispatchStatus({ type: 'UPDATE_ORIGIN_STATUS', payload: originStatus });

                    formik.setFieldValue('originDomain', '');
                    // if (originStatus.results.origin) {
                        //     formik.setFieldValue('originDomain', originStatus.results.origin);
                        // }

                    if (status.trafficStatus && status.trafficStatus.status === 'DEPLOYED') {
                        setActiveStep(3);
                        setActiveContent(50);
                    } else if (status.trafficStatus && status.trafficStatus.status === 'PENDING') {
                        setActiveContent(25);
                        setActiveStep(2);
                    } else {
                        setActiveContent(25);
                        setActiveStep(2);
                    }
                    setUpdatingList(false)
                    
                    

                    let l_domainName = stripProtocol(domainName);
                    let behaviourList = await api.getBehaviourList(l_domainName);
                    if(!behaviourList.error){
                        if (behaviourList.results.behaviours) {
                            update_default_bypass_list(behaviourList.results.behaviours)
                        }
                    }
                    
                    
    
                } else if (distributionStatus.status === 'InProgress') {

                    if(isMounted.current) {
                    dispatch({ type: 'UPDATE_STEP', payload: { id: 2, title: "Distribution Pending" } });
                    dispatchStatus({ type: 'UPDATE_DISTRIBUTION_STATUS', payload: distributionStatus });
                    setActiveContent(45);
                    setActiveStep(2);
                    }
                }
            }, 10000);

            setCreateDistributionInterval(intervalId)
        }

        if (checkDist) {
            checkDistributionUpdate();
            setCheckDist(false);
        }
        
        
        
        return () => {
            setCheckDist(false);
            if(createDistributionInterval) {
                clearInterval(createDistributionInterval);
            }
            isMounted.current = false;
        };


    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkDist])

    

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            originDomain: ''
        },
        validationSchema: Yup.object({
            originDomain: Yup.string().required('Required')
        }),
        onSubmit: async (values) => {
            let payload = {
                ...values,
                domain: stripProtocol(domainName)
            }
            let createDistribution_result = await api.createDistribution(payload);
            setCreateDistributionTime(Date.now());

            if (!createDistribution_result.error && isMounted.current) {
                dispatch({ type: 'UPDATE_STEP', payload: { id: 1, title: "Creating Distribution" } });
                setActiveContent(70);
                setActiveStep(2);

                setCheckDist(true);

            } else {
                // show error message
                // TODO handle error
                // console.log('createDistribution_result', createDistribution_result);
            }
        },
    });
    
    

    const requestCertificate = async () => {
        setRequestingCertificate(true);
        setRequestingCertificateError(false);
        let l_domainName = stripProtocol(domainName);
        let isCloudFront = await api.getOriginStatus(l_domainName);

        if (isCloudFront.results.cloudfront === 'true') {
            // TODO handler this status
            setDisplayCloudFrontError(true);
        } else {
            let requestedCertResponse = await api.requestCertificate(l_domainName);

            if (requestedCertResponse.error) {
                setRequestingCertificate(false);
                setRequestingCertificateError(true)
                return false;
            } else {

                requestedCertResponseInterval = setInterval(() => {

                    const requestCert = async () => {
                        let certificateStatus = await api.getCertificateStatus(l_domainName);

                        if (certificateStatus.status === 'ERROR') {
                            clearInterval(requestedCertResponseInterval);
                            setRequestingCertificate(false);

                            return false;
                        }

                        if (certificateStatus.status !== 'MISSING') {
                            clearInterval(requestedCertResponseInterval);
                            setRequestingCertificate(false);
                            if (certificateStatus.status === 'PENDING_VALIDATION' && isMounted.current) {
                                // display pending validation message
                                dispatch({ type: 'UPDATE_STEP', payload: { id: 0, title: "Certificate Pending" } });
                                dispatchStatus({ type: 'UPDATE_CERTIFICATE_STATUS', payload: certificateStatus });
                                setActiveContent(10);
                            }
                        }
                    }

                    if (isRendered.current) {
                        requestCert();
                    } else {
                        clearInterval(requestedCertResponseInterval);
                    }

                }, 5000);

            }
            if (isRendered.current && requestedCertResponse) {
                // setActiveContent(30);
            }
        }
    }

    function reducer(state, action) {
        switch (action.type) {
            case 'UPDATE_STEP':
                let newSteps = state.map((step) => {
                    if (step.id === action.payload.id) {
                        step = action.payload;
                    }
                    return step;
                })
                return newSteps;
            default:
                throw new Error();
        }
    }

    const [steps, dispatch] = useReducer(reducer, [
        { id: 0, title: 'Validating Certificate' },
        { id: 1, title: 'Set Origin' },
        { id: 2, title: 'Enable CrowdHandler' },
    ]);


    const [originHelperText, setOriginHelperText] = useState('');

    let isRendered = useRef(false);

    function statusReducer(state, action) {

        switch (action.type) {
            case 'UPDATE_SUBDOMAIN_STATUS':
                return {
                    ...state,
                    subdomainStatus: action.payload
                };
            case 'UPDATE_TRAFFIC_STATUS':
                return {
                    ...state,
                    trafficStatus: action.payload
                };
            case 'UPDATE_DISTRIBUTION_STATUS':
                return {
                    ...state,
                    distributionStatus: action.payload
                };
            case 'UPDATE_CERTIFICATE_STATUS':
                return {
                    ...state,
                    certificateStatus: action.payload
                };
            case 'UPDATE_ORIGIN_STATUS':
                return {
                    ...state,
                    originStatus: {"results":{"error":"A valid origin domain was not found."},"status":"ERROR"}
                    // originStatus: action.payload
                };
            case 'UPDATE_BEHAVIOUR_LIST':
                return {
                    ...state,
                    behaviourList: action.payload
                };
            case 'ORIGINAL_BEHAVIOUR_LIST':
                return {
                    ...state,
                    originalBehaviourList: action.payload
                };
            case 'DEFAULT_BYPASS_LIST':
                return {
                    ...state,
                    defaultBypassList: action.payload
                };
            default:
                throw new Error();
        }
    }

    const [status, dispatchStatus] = useReducer(statusReducer,
        {
            subdomainStatus: { status: undefined },
            trafficStatus: undefined,
            distributionStatus: undefined,
            certificateStatus: undefined,
            originStatus: undefined,
            behaviourList: [],
            originalBehaviourList: [],
            defaultBypassList: [
                "*.css","*.ico","*.jpg","*.jpeg","*.js", ".otf", "*.png","*.svg","*.tff","*.webp","*.woff*"
            ],
        }
    );

    useEffect(() => {
        const hasSubdomain = async () => {
            let l_domainName = stripProtocol(domainName);
            let subdomainStatus = await api.hasSubdomain(l_domainName);

            if(isMounted.current) {
            dispatchStatus({ type: 'UPDATE_SUBDOMAIN_STATUS', payload: subdomainStatus });
            }
        }
        hasSubdomain();
    }, [api, domainName]);
    
    useEffect(() => {
        const hasSubdomain = async () => {
            let l_domainName = stripProtocol(domainName);
            let behaviourList = await api.getBehaviourList(l_domainName);

            if(!behaviourList.error){
                if (behaviourList.results.behaviours) {

                    update_default_bypass_list(behaviourList.results.behaviours)
                    
                }
            }
            
        }
        hasSubdomain();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    
    const update_default_bypass_list = (behaviour_list) => {

        let updated_default_list = intersection(status.defaultBypassList, behaviour_list);
        let list = difference(behaviour_list, updated_default_list);
        dispatchStatus({ type: 'DEFAULT_BYPASS_LIST', payload: updated_default_list });
        dispatchStatus({ type: 'UPDATE_BEHAVIOUR_LIST', payload: list });
        dispatchStatus({ type: 'ORIGINAL_BEHAVIOUR_LIST', payload: list });

    }


    useEffect(() => {

        isRendered.current = true;
        const checkForTrafficStatus = async () => {
            let l_domainName = stripProtocol(domainName);
            let trafficStatus = await api.getTrafficStatus(l_domainName);
            // if the domain has a valid trafficStatus { "status": "DEPLOYED" } then display the status - end of process
            if (trafficStatus.status === 'DEPLOYED') {
                let distributionStatus = await api.getDistributionStatus(l_domainName);
                if(isMounted.current) {
                    dispatch({ type: 'UPDATE_STEP', payload: { id: 0, title: "Certificate Issued" } });
                    dispatch({ type: 'UPDATE_STEP', payload: { id: 2, title: "CrowdHandler Enabled" } });
                    dispatchStatus({ type: 'UPDATE_DISTRIBUTION_STATUS', payload: distributionStatus });
                    dispatchStatus({ type: 'UPDATE_TRAFFIC_STATUS', payload: trafficStatus });
                    setActiveContent(50);
                    setActiveStep(3);
                }
            }

            // if the domain has a trafficStatus { "status": "PENDING" } then check the distribution status

            if (trafficStatus.status === 'PENDING') {
                    if(isMounted.current) {
                        dispatchStatus({ type: 'UPDATE_TRAFFIC_STATUS', payload: trafficStatus });
                    }
                    let distributionStatus = await api.getDistributionStatus(l_domainName);

                if (distributionStatus.status === 'Deployed') {
                    // show the Change Origin Panel
                    if(isMounted.current) {
                        dispatch({ type: 'UPDATE_STEP', payload: { id: 0, title: "Certificate Issued" } });
                        dispatch({ type: 'UPDATE_STEP', payload: { id: 2, title: "CrowdHandler Ready" } });
                        dispatchStatus({ type: 'UPDATE_DISTRIBUTION_STATUS', payload: distributionStatus });

                        let originStatus = await api.getOriginStatus(l_domainName);
                        dispatchStatus({ type: 'UPDATE_ORIGIN_STATUS', payload: originStatus });

                        setActiveContent(25);
                        setActiveStep(2);
                    }
                }

                if (distributionStatus.status === 'InProgress' && isMounted.current) {
                    dispatch({ type: 'UPDATE_STEP', payload: { id: 0, title: "Certificate Issued" } });
                    dispatch({ type: 'UPDATE_STEP', payload: { id: 2, title: "Distribution Pending" } });
                    dispatchStatus({ type: 'UPDATE_DISTRIBUTION_STATUS', payload: distributionStatus });
                    setActiveContent(45);
                    setActiveStep(3);
                }

                if(isMounted.current) {
                    dispatchStatus({ type: 'UPDATE_DISTRIBUTION_STATUS', payload: distributionStatus })
                }

            }
            // else check their certificate status
            if (trafficStatus.status === 'ERROR' && isMounted.current) {
                dispatchStatus({ type: 'UPDATE_TRAFFIC_STATUS', payload: trafficStatus });
                let certificateStatus = await api.getCertificateStatus(l_domainName);

                // if the certificate is missing give the user the chance to request one
                if (certificateStatus.status === 'MISSING' && isMounted.current) {
                    // display request certificate panel
                    // this will kick of the request
                    // poll the getCertificateStatus endpoint to update.
                    dispatch({ type: 'UPDATE_STEP', payload: { id: 0, title: "Request Certificate" } });
                    setActiveContent(5);
                }

                if (certificateStatus.status === 'PENDING_VALIDATION' && isMounted.current) {
                    // display pending validation message
                    dispatch({ type: 'UPDATE_STEP', payload: { id: 0, title: "Certificate Pending" } });
                    dispatchStatus({ type: 'UPDATE_CERTIFICATE_STATUS', payload: certificateStatus });
                    setActiveContent(10);
                }

                if (certificateStatus.status === 'ISSUED' && isMounted.current) {
                    
                    // check the origin
                    dispatch({ type: 'UPDATE_STEP', payload: { id: 0, title: "Certificate Issued" } });
                    let originStatus = await api.getOriginStatus(l_domainName);
                    let distributionStatus = await api.getDistributionStatus(l_domainName);

                    // if (originStatus.results.origin) {
                    //     formik.setFieldValue('originDomain', originStatus.results.origin);
                    //     setOriginHelperText(
                    //         'We are able to automatically recommend an origin for you.'
                    //     )
                    // }

                    // if (originStatus.status === 'ERROR') {
                    // }
                    
                    setOriginHelperText(
                        'We were unable to automatically recommend an origin for you. Please manually enter the origin domain that you would like CrowdHandler to proxy traffic to.'
                    )
                    setActiveContent(1);
                    setActiveStep(1);
                    dispatchStatus({ type: 'UPDATE_ORIGIN_STATUS', payload: originStatus });
                    dispatchStatus({ type: 'UPDATE_DISTRIBUTION_STATUS', payload: distributionStatus });

                    if (distributionStatus.status === 'InProgress' && isMounted.current) {
                        dispatchStatus({ type: 'UPDATE_DISTRIBUTION_STATUS', payload: distributionStatus });
                        dispatch({ type: 'UPDATE_STEP', payload: { id: 2, title: "Distribution Pending" } });
                        setActiveContent(45);
                        setActiveStep(2);

                        setCheckDist(true);
                    } 
                }

            }

        }

        if (domainName) {
            checkForTrafficStatus()
        }

        return () => {
            isRendered.current = false;
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [api, domainName])


    const getStepContent = (step) => {

        switch (step) {
            case 0:
                return <LinearProgress />
            case 5:
                return <Grid container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={1}>
                    {displayCloudFrontUsedError ?
                    <Grid item>
                        <Typography>CrowdHandler does not currently support configurations where a pre-existing CloudFront distribution exists for the domain being protected.</Typography>
                    </Grid>
                    :
                    null
                    }
                    <Grid item>
                        <Typography>Click Request Certificate to begin TLS Certificate Validation</Typography>
                    </Grid>
                    <Grid item>
                        <Button
                            color="secondary"
                            disableElevation
                            variant="outlined"
                            onClick={() => { requestCertificate() }}
                            endIcon={(requestingCertificate) ? <CircularProgress size={10} color="secondary" /> : null}
                        >
                            {(requestingCertificate) ? `Requesting Certificate` : `Request Certificate`}
                            
                            </Button>

                    </Grid>

                    <Grid item>
                            <Typography><strong>Before you begin - </strong>Want to use CrowdHandler’s DNS integration to protect an apex domain? Your DNS provider will need to support CNAME flattening. Check out our <Link href="https://support.crowdhandler.com/support/solutions/articles/80000203605-cdn-integration-getting-started" target="_blank">documentation</Link> for more information</Typography>
                    </Grid>

                    {requestingCertificateError ? <Grid item><Typography>There has been an error requesting the certificate</Typography></Grid> : null}
                </Grid>
            case 7:
                return <Grid container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={1}>

                    <Grid item align="center">
                        {status.trafficStatus.results.message ? status.trafficStatus.results.message : null}
                        {status.trafficStatus.results.error ? status.trafficStatus.results.error : null}
                    </Grid>
                </Grid>
            case 10:
                return <PendingCertificate stripProtocol={stripProtocol} classes={classes} status={status} domainName={domainName} validationInfo={status.certificateStatus.results.validationInfo} />
            case 20:
                return <SetOrigin status={status} originHelperText={originHelperText} formik={formik} classes={classes} />
            case 25:
                // force the clearing of this interval
                return <Grid container alignItems="center">
                    <Grid container spacing={2} className={classes.proxyInfoHeader}>
                        <Grid item>
                            <Typography>A CrowdHandler endpoint has been deployed for <strong>{status.distributionStatus.results.domainName}</strong> but traffic to <strong>{status.distributionStatus.results.domainName}</strong> is not yet traversing through CrowdHandler.</Typography>
                            <DistributionPending classes={classes} status={status} />
                            {/* <SetOrigin status={status} originHelperText={originHelperText} formik={formik} classes={classes} /> */}
                        </Grid>
                    </Grid>
                </Grid>
            case 30:
                return <Grid container alignItems="center">
                    <Grid container spacing={2} className={classes.proxyInfoHeader}>
                        <Grid item>
                            <Typography variant="button">A certificate has been requested.</Typography>
                            <Typography>Please check back later for further instructions.</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            case 40:
                return <Pending classes={classes} status={status} />
            case 45:
                return <Grid container alignItems="center">
                    <Grid container spacing={2} className={classes.proxyInfoHeader}>
                        <Grid item>
                            <CircularProgress />
                        </Grid>
                        <Grid item>
                            <Typography variant="button">Distribution Pending</Typography>
                            <Typography>This can take several minutes.</Typography>
                            <Typography>Please wait or check back later for further instructions.</Typography>
                        </Grid>
                    </Grid>
                </Grid>

            case 50:
                return <Deployed status={status} classes={classes} />

            case 70:
                return <Grid container alignItems="center">
                    <Grid container spacing={2} className={classes.proxyInfoHeader}>
                        <Grid item>
                            <CircularProgress />
                        </Grid>
                        <Grid item>
                            <Typography variant="button">Creating Distribution</Typography>
                            <Typography>Please check back later for further instructions.</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            case 75:
                return <Grid container alignItems="center">
                    <Grid container spacing={2} className={classes.proxyInfoHeader}>
                        <Grid item>
                            <CircularProgress />
                        </Grid>
                        <Grid item>
                            <Typography variant="button">Updating Distribution</Typography>
                            <Typography>Please check back later for further instructions.</Typography>
                        </Grid>
                    </Grid>
                </Grid>

            case 1:
                return <Grid container>
                    {status.originStatus
                        ?
                        <>
                            <Grid item xs={12}>

                                <FormControl fullWidth margin="dense" variant="outlined">
                                    <InputLabel htmlFor="title">Origin</InputLabel>
                                    <OutlinedInput
                                        labelWidth={50}
                                        type="text"
                                        placeholder="Origin Domain"
                                        aria-label="originDomain"
                                        aria-describedby="title"
                                        {...formik.getFieldProps('originDomain')}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    size="small"
                                                    disableElevation
                                                    disabled={(formik.errors.originDomain || !formik.values.originDomain) ? true : false}
                                                    onClick={formik.handleSubmit}
                                                >
                                                    Create Distribution
                </Button>
                                            </InputAdornment>
                                        }
                                    />
                                    <FormHelperText>{originHelperText}</FormHelperText>
                                </FormControl>
                            </Grid>

                        </>
                        :
                        null
                    }
                </Grid>;

            default:
                return 'Unknown step';
        }
    }

    const handleCDNHelp = () => {
        if (window.FreshworksWidget) {
            window.FreshworksWidget('open', 'article', {
                id: 80000203605 // article ID
            });
        }
    }

    const [updatingOrigin, setUpdatingOrigin] = useState(false);
    const [originUpdated, setOriginUpdated] = useState(false);

    let updateOriginIntervalId;

    const formikURExclusionList = useFormik({
        enableReinitialize: true,
        validationSchema: Yup.object({
            urLExclusionList: Yup.string().test('test-name', 'Maximum number of entries exceeded', 
                function(value) {
                    // your logic
                    if (value) {
                        let list = value.split('\n');

                        if ((list.length + status.defaultBypassList.length + 3) > 32 ) {
                            return false;
                        }
                    } 
                    return true;
                })
        }),
        initialValues: {
            urLExclusionList: (status.behaviourList) ? status.behaviourList.join('\n') : ''
        },
        onSubmit: async (values) => {
            setUpdatingList(true);
            let entries = (values.urLExclusionList !== '' || values.urLExclusionList !== undefined) ? values.urLExclusionList : [];
            
            let to_add = difference(entries.split('\n'), status.behaviourList).filter((element) => {
                return element !== '';
            }).map((element) => {
                return element.trim()
            });

            let to_delete = difference(status.behaviourList, entries.split('\n')).filter((element) => {
                return element !== '';
            }).map((element) => {
                return element.trim();
            });

            let groups = {
                'add': to_add,
                'delete': to_delete
            }

            let errors = [];
            setProcessing(true);
            let l_domainName = stripProtocol(domainName);
            const timer = ms => new Promise(res => setTimeout(res, ms))
            async function load () {

                for (const key in groups) {
                    if (Object.prototype.hasOwnProperty.call(groups, key)) {
                        const elements = groups[key];
                        let result;
                        if (key === 'add' && elements.length) {
                            result = await api.addBehaviourList(l_domainName, elements)
                        }
                        if (key === 'delete' && elements.length) {
                            result = await api.removeBehaviourList(l_domainName, elements)
                        }
                            

                        if (result && result.error) {
                            errors.push(result)
                        }

                        await timer(1000);
                        
                    }
                }

                if(errors.length) {
                    setAlertBarStatus('error');
                    setMessage('One or more items in the list could not be updated');
                    setOpenErrorSnackbar(true);
                }

                dispatch({ type: 'UPDATE_STEP', payload: { id: 2, title: "Updating Distribution" } });
                setActiveContent(45);
                setOpen(false);
                setProcessing(false);

                setCheckDist(true);


            }

            load();



        }
    })

    const formikUpdateOrigin = useFormik({
        enableReinitialize: true,
        initialValues: {
            originDomain: (status.distributionStatus) ? status.distributionStatus.results.origin : ''
        },
        validationSchema: Yup.object({
            originDomain: Yup.string().required('Required')
        }),
        onSubmit: async (values) => {
            setOriginUpdated(false);
            setUpdatingOrigin(true);
            let l_domainName = stripProtocol(domainName);
            await api.originUpdate(l_domainName, values.originDomain);

            updateOriginIntervalId = setInterval(async () => {

                let response = await api.getDistributionStatus(l_domainName);

                if (response.status === 'Deployed') {
                    setOriginUpdated(true);
                    setUpdatingOrigin(false);
                    setMessage('Origin Updated')
                    setAlertBarStatus('success');
                    setOpenErrorSnackbar(true);
                    clearInterval(updateOriginIntervalId);
                    formikUpdateOrigin.setFieldValue('originDomain', '');
                }
                else if (response.status === 'Error') {
                    setOriginUpdated(false);
                    setUpdatingOrigin(false);
                    setMessage('Error Updating Snackbar')
                    setAlertBarStatus('error');
                    setOpenErrorSnackbar(true);
                    clearInterval(updateOriginIntervalId);
                    formikUpdateOrigin.setFieldValue('originDomain', '');
                }


            }, 10000)
        },
    });

    const cachePurge = async (domainName) => {


        setPurgingCache(true)
        let res = await api.cachePurge(domainName);
        
        if (res.status === 'OK') {
            setFlushCacheMessage(res.results.message)
        } else {
            setFlushCacheMessage()
        }
    }


    const [snackPack, setSnackPack] = useState([]);
    const [messageInfo, setMessageInfo] = useState(undefined);
    const [openSnackBar, setOpenSnackBar] = useState(false);
    useEffect(() => {
        if (snackPack.length && !messageInfo) {
          // Set a new snack when we don't have an active one
          setMessageInfo({ ...snackPack[0] });
          setSnackPack((prev) => prev.slice(1));
          setOpenSnackBar(true);
        } else if (snackPack.length && messageInfo && open) {
          // Close an active snack when a new one is added
          setOpenSnackBar(false);
        }
      }, [snackPack, messageInfo, open]);
    
      const handleSnackBarClick = (message) => {
        setSnackPack((prev) => [...prev, { message, key: new Date().getTime() }]);
      };
    
      const handleSnackBarClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenSnackBar(false);
      };
    
      const handleSnackBarExited = () => {
        setMessageInfo(undefined);
      };



    return (
        <>
        <Grid container spacing={2} direction="row" justifyContent="center" alignItems="flex-start" >
            <Grid item xs={12}>
                <IconButton size="small" color="secondary" style={{ marginRight: '10px' }} onClick={() => {
                    handleCDNHelp()
                }}>
                    <HelpCircleIcon size={10}></HelpCircleIcon>
                </IconButton>
                <Typography variant="button">DNS Installation</Typography>
                <Divider />

            </Grid>

            <Grid item xs={4}>
                <Stepper activeStep={activeStep} orientation="vertical" className={classes.stepper}>
                    {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        return (
                            <Step key={label.title} {...stepProps}>
                                <StepLabel {...labelProps}>{label.title}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
            </Grid>
            <Grid item xs={8} className={classes.infoPanel}>

                {getStepContent(activeContent)}

            </Grid>

            <ErrorSnackBar message={message} open={openErrorSnackbar} closeErrorSnackbar={closeErrorSnackbar} status={alertBarStatus}/>

            
            </Grid>
            {activeStep === 3 || activeContent === 25
            ?
            <>
                    <Grid container spacing={0}>
                    <Grid item xs={12}>
                    <Button fullWidth variant="outlined" endIcon={open ? <ArrowUpCircleIcon /> : <ArrowDownCircleIcon />} onClick={handleClick}>Settings</Button>
                    </Grid>
                <Collapse in={open} timeout="auto" unmountOnExit>

                
                    <Grid item xs={12}  className={classes.advancedPanel}>
                        <Typography variant="subtitle1">
                            {!updatingOrigin
                            ?
                            `Amend Origin`
                            :
                            `Amending Origin`
                            }
                            </Typography>

                        {!updatingOrigin
                        ?
                        <>
                        <FormControl fullWidth margin="dense" variant="outlined">
                            <InputLabel htmlFor="title">Origin</InputLabel>
                            <OutlinedInput
                                id="originDomain"
                                name="originDomain"
                                labelWidth={50}
                                type="text"
                                placeholder="Origin Domain"
                                aria-label="originDomain"
                                aria-describedby="title"
                                {...formikUpdateOrigin.getFieldProps('originDomain')}
                                
                            />
                            <FormHelperText><strong>Warning</strong> - Updating the origin will immediately instruct CrowdHandler to begin routing traffic to the domain submitted. Be sure that the origin is correct and configured to serve traffic before updating.</FormHelperText>
                        </FormControl>
                        <FormControl variant="outlined">
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                disableElevation
                                disabled={(formikUpdateOrigin.errors.originDomain || !formikUpdateOrigin.values.originDomain) ? true : false}
                                onClick={() => { formikUpdateOrigin.handleSubmit(); }}
                                >
                                Update Origin
                            </Button>
                            </FormControl>
                            </>
                        :
                        <Grid container>
                            <Grid item style={{marginRight:'20px'}}>
                                <CircularProgress size={20}/>
                            </Grid>
                           
                        </Grid>
                        }

                        {originUpdated ? 
                         <Grid container>
                         <Grid item>
                             <Typography>The origin has been updated</Typography>
                         </Grid>
                     </Grid>
                        : null }
                    </Grid>

                    <Divider></Divider>

                    <Grid item xs={12}  className={classes.advancedPanel}>
                        <Typography variant="subtitle1">No-Bypass Token</Typography>
                        <Typography><Link target="_blank" href="https://support.crowdhandler.com/support/solutions/articles/80000140077">Learn how to configure</Link></Typography>
                       
                        <FormControl fullWidth margin="dense" variant="outlined" className={classes.bypassToken}>

                            <OutlinedInput  value={status.distributionStatus.results['x-ch-no-bypass']} readOnly
                            
                            
                            endAdornment={
                                <InputAdornment position="end">
                                    <CopyButton
                                    color="primary"
                                    text={status.distributionStatus.results['x-ch-no-bypass']}
                                    onCopy={() => {
                                        handleSnackBarClick(`${status.distributionStatus.results['x-ch-no-bypass']} copied`);
                                    }}
                                    />
                                </InputAdornment>
                            }
                            
                            ></OutlinedInput>
                        </FormControl>
                        <FormHelperText>This token will be privately sent to your website on each request via the x-ch-no-bypass header.</FormHelperText>

                        

                    </Grid>

                    <Divider></Divider>

                    <Grid item xs={12}  className={classes.advancedPanel}>

                        <Grid container>
                            <Grid md={8} xs={12} item>
                                <Typography variant="subtitle1">Flush Cache</Typography>
                                <Typography>Purge static assets cached by CrowdHandler.</Typography>                            
                                <FormControl variant="outlined">
                                    <Button
                                        className={purgingCache ? 'purgeButton' : ''}
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        endIcon={<RotateCWIcon/>}
                                        disableElevation
                                        onClick={() => { 
                                            cachePurge(stripProtocol(domainName));
                                        }}
                                    >

                                    {!purgingCache
                                    ?
                                    `Purge`
                                    :
                                    `Purging`
                                }

                                    </Button>
                        
                                </FormControl>
                                <FormHelperText style={{marginBottom: '10px'}}>This can take up to 5 minutes to complete.</FormHelperText>
                                    {flushCacheMessage
                                    ?
                                    <Typography>{flushCacheMessage}</Typography>
                                    :
                                    null
                                    }
                            </Grid>
                        </Grid>
                    </Grid>

                    <Divider></Divider>


                    <Grid item xs={12}  className={classes.advancedPanel}>

                        <DomainProxyURLExclusionList
                            default_bypass_list={status.defaultBypassList}
                            formikURExclusionList={formikURExclusionList}
                            list={status.behaviourList}
                        />
                        

                    </Grid>
                    
                    <ErrorSnackBar
                        status="success"
                        open={openSnackBar}
                        key={messageInfo ? messageInfo.key : undefined}
                        autoHideDuration={2000}
                        onClose={handleSnackBarClose}
                        onExited={handleSnackBarExited}
                        message={messageInfo ? messageInfo.message : undefined}
                    />


                    
                </Collapse>
                </Grid>
                </>
            :
            null
            }
            </>
        
    );

}

export default DomainProxyOnboarding;