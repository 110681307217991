import React from 'react';
import { ReactComponent as Edit } from './feather/edit.svg';

import { SvgIcon } from '@material-ui/core';


export default function EditIcon(props) {
    return (
        <SvgIcon {...props} component={Edit} className="feather"/>
    );
  }