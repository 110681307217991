import React from 'react';

import {
    Grid,
    FormGroup,
    FormControl,
    OutlinedInput,
    FormHelperText,
    InputLabel,
    Typography,
    Slider,
    Link
  } from '@material-ui/core';
  import { makeStyles } from '@material-ui/core/styles';
  import { CapacityWarningPanel } from './';
  import { LinkWithoutNavigateProps } from '../';
  import { Link as RouterLink } from 'react-router-dom';


  const useStyles = makeStyles((theme) => ({
    capacitySlider: {
      margin: `${theme.spacing(4)}px 0`,
      '& .MuiSlider-markLabel' : {
        top:'16px',
        '&[data-index="0"]' : {
          left: '5px !important'
        },
        '&[data-index="1"]' : {
          left: 'calc(100% - 5px) !important'
        } 
      }
    },
    dateError: {
      backgroundColor: theme.palette.error.main,
      color: '#ffffff',
      borderRadius: '4px',
      padding: theme.spacing(1)
    },
  }));


const RoomCapacity = ({formik, availableRoomCapacity, capacityStep, handleRoomsCapacityChange, domainId, numberOfRooms, action}) => {

    const classes = useStyles();
    return (
        <Grid item xs={12} className={`ch-room-form-group ${classes.capacitySlider}`}>

                  <FormGroup>
                  <InputLabel htmlFor="capacity">Room Capacity</InputLabel>
                  <Grid container justifyContent="space-between" spacing={4}>
                    <FormControl fullWidth margin="normal" variant="outlined" component={Grid} xs={10} item>

                      <Slider
                      id="capacity"
                      step={capacityStep}
                      aria-label="Room Capacity"
                      onChange={(ev, b) => handleRoomsCapacityChange(ev, b)}
                      value={availableRoomCapacity > 0 ? formik.values.capacity: 0}
                      min={0}
                      max={availableRoomCapacity > 0 ? availableRoomCapacity : 100}
                      disabled={availableRoomCapacity > 0 ? false: true}
                      marks={

                        availableRoomCapacity > 0
                        ?
                        [
                        {
                          value: 0,
                          label: '0',
                        },
                        {
                          value: availableRoomCapacity,
                          label: (availableRoomCapacity < 1000) ? availableRoomCapacity : `${availableRoomCapacity/1000}K`,
                        },
                      ]
                      :
                      [
                        {value: 0,
                          label: '0',},{value: 100,
                            label: '100',}
                      ]
                    
                    }
                      valueLabelDisplay="off"
                    />
                    
                    </FormControl>
                    <FormControl  component={Grid} xs={2} item>
                      <OutlinedInput
                        disabled={!availableRoomCapacity || availableRoomCapacity < 1}
                        type="number"
                        id="capacity"
                        {...formik.getFieldProps('capacity')}
                        labelWidth={72}
                        aria-label="Capacity"
                        inputProps={{
                          min:"0",
                          max:availableRoomCapacity
                        }}
                        style={{backgroundColor: '#fff'}}
                        notched={false}
                        />
                    </FormControl>
                    </Grid>

                  { formik.values.capacity >= 50 && availableRoomCapacity > 0 ?
                    <FormHelperText style={{zIndex: 10}}>The number of people this waiting room can hold before <Link target="_blank" href="https://www.crowdhandler.com/docs/80000674052-what-happens-when-a-waiting-room-is-full-">becoming full</Link></FormHelperText>

                  : null }


                  {availableRoomCapacity <= 0 ?
                  <>
                    <Typography className={classes.helpertext_error} color="error">
                      You currently have no available capacity. 
                    {numberOfRooms && numberOfRooms < 2 && action==='add' ?
                    
                    ` Create this room then reduce the capacity on an existing room.`
                    
                    : ` Reduce capacity on an existing room in order to allocate capacity to this room.`}
                    </Typography>
                  </>
                    :
                    null
                  }
                    {formik.values.capacity < 1 && availableRoomCapacity && availableRoomCapacity > 0 ?
                        
                        <CapacityWarningPanel color={'red'}>
                        <Typography>
                        This room will <Link target="_blank" href="https://www.crowdhandler.com/docs/80000674052-what-happens-when-a-waiting-room-is-full-">always be full</Link>, if you are trying to set the rate, use <RouterLink component={LinkWithoutNavigateProps} to={`/domains/${domainId}`}>the domain settings</RouterLink>
                         </Typography>
                     </CapacityWarningPanel>

                      :
                      null
                    }
                    {formik.values.capacity < 50 && formik.values.capacity > 0 && availableRoomCapacity && availableRoomCapacity > 0 ?
                     <CapacityWarningPanel color={'#ff5722'}>
                     <Typography>
                     This is a very small waiting room, if you are trying to set the rate, use <RouterLink component={LinkWithoutNavigateProps} to={`/domains/${domainId}`}>the domain settings</RouterLink>
                        </Typography>
                    </CapacityWarningPanel>
                      :
                      null
                    }

                  </FormGroup>

                </Grid>
    )
}

export default RoomCapacity;