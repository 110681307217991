import React from 'react';
import { ReactComponent as ChevronUp } from './feather/chevron-up.svg';

import { SvgIcon } from '@material-ui/core';


export default function ChevronUpIcon(props) {
    return (
        <SvgIcon {...props} component={ChevronUp} className="feather"/>
    );
  }