import React from 'react';
import { ReactComponent as Search } from './feather/search.svg';

import { SvgIcon } from '@material-ui/core';


export default function SearchIcon(props) {
    return (
        <SvgIcon {...props} component={Search} className="feather"/>
    );
  }