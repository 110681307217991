import React, { useState, useEffect, useCallback } from "react";
import { makeStyles } from '@material-ui/core/styles';
import ErrorSnackBar from '../../components/ErrorSnackBar';
import {
  Grid,
  Typography,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Slider,
  Switch,
  FormControlLabel,
  Select,
  MenuItem,
  Button
} from '@material-ui/core';
import groupBy from 'lodash/groupBy';
import grey from '@material-ui/core/colors/grey';
import {RecaptureBlock, FraudSliderRow, ReCaptchaSwitch, SavingBar} from '../../components'
import UpgradeCTAPanel from '../../components/UpgradeCTAPanel'



const headerBGColor = grey[100];
const headerColor = grey[700];

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  container: {},
  titleRow: {
    '& td' : {
      paddingTop: '22px',
      paddingBottom: theme.spacing(2),
      borderBottom: 'none',
      borderTop: `2px solid ${grey[200]}`,
      backgroundColor: headerBGColor,
      color: headerColor,
      fontWeight: 'bold',
      fontSize: '12px'
    }
  }
}));

const Fraud = ({ api, state, dispatch, ...rest }) => {

  const classes = useStyles();

  useEffect(() => {
    const getData =  async () => {
      let rooms = await api.getRooms();
      dispatch({ type: 'UPDATE_ROOMS', payload: rooms });
    }

    if(!state.rooms) {
      getData();
    }
 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.rooms]);

  const [upgradeMessage, setUpgradeMessage] = useState('ReCAPTCHA integration is not available on your current plan. To enable ReCAPTCHA please upgrade to a supporting plan.');
  const [displayUpgradeButton, setDisplayUpgradeButton] = useState(true);

  let [groups, setGroups] = useState([]);
  let [message, setMessage] = useState('');
  let [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  
  const closeErrorSnackbar = () => {
    setOpenErrorSnackbar(false)
  }

  useEffect(() => {
    const formatGroups = () => {

      let roomsByDomain = groupBy(state.rooms, 'domainID');
      let groups = state.domains.map((domain) => {
        let rooms = roomsByDomain[domain.id];
        domain.rooms = rooms;
        return domain;
      });

      setGroups(groups);
      
    }
    
    if (state.rooms && state.domains) {
      formatGroups()
    }

    // return () => {
    //   cleanup
    // };
  }, [state.rooms, state.domains]);


  const FraudDomainCard = ({domain}) => {

    let [saving, setSaving] = useState(false)

    

    let block = <Grid item xs={12} md={4} lg={3} key={domain.id}>
        <Card style={{height: '100%'}}>
        <CardContent style={{position:'relative'}}>
            <SavingBar saving={saving} />
            <TableContainer className={classes.container}>
            <Table>
            <TableHead></TableHead>
            <TableBody>
              <TableRow className={classes.titleRow} key={domain.id}>
                <TableCell>{domain.name}</TableCell>
                <TableCell>ReCaptcha Required</TableCell>
                {/* <TableCell>Pause Sessions Above</TableCell>
                <TableCell>Delete Sessions Above</TableCell> */}
                {/* <TableCell></TableCell> */}
              </TableRow>
              {domain.rooms.map((room) => {                  
                  return <FraudSliderRow saving={saving} key={room.id} room={room} state={state} dispatch={dispatch} setSaving={setSaving} api={api}/>
              })}
              </TableBody>
            </Table>
            </TableContainer>
      </CardContent>
    </Card>
    </Grid>;

    return block;


  }

  const generateRows = () => {

    let rows = [];
    groups.forEach(
      (domain) => {
        if (domain.rooms) {

          rows.push(
            <FraudDomainCard key={domain.id} domain={domain} />
          )
        }
      }
    )

    return rows;

  }


  return (

      <Grid container spacing={4} className={classes.root}>
        <Grid item xs={12}>

          { state.plan.allowCaptcha && state.rooms
          
          ?
          <RecaptureBlock state={state} api={api} dispatch={dispatch}/>
        
          :
          <UpgradeCTAPanel upgradeMessage={upgradeMessage} displayUpgradeButton={displayUpgradeButton} />

          }
        </Grid>

        { state.plan.allowCaptcha && state.rooms
        ?
          generateRows()
        :null
        }

      <ErrorSnackBar message={message} open={openErrorSnackbar} closeErrorSnackbar={closeErrorSnackbar} />

    </Grid>
  );
};

export default Fraud;



