import React, { Suspense, lazy } from "react";

import { Switch, Route } from "react-router-dom";

import { RouteWithLayout, PublicRouteWithLayout } from './lib/RouteWithLayout';
import { Login as LoginLayout } from './layouts';
import { Main as MainLayout } from './layouts';
import { Admin as AdminLayout } from './layouts';

import {
    Login,
    Verify,
    ResetPassword,
    NewPassword,
    Verification,
    DomainsEdit,
    DomainsIPAddresses,
    DomainsDeploymentSettings,
    AddRoom,
    AddRoomAmend,
    Account,
    NotFound,
    RoomSessionLayout,
    QueueJump,
    QueueJumpManageCodes,
    Confirmation,
    RoomCapacity,
    Reports,
    Fraud,
    EmailMe,
    TemplatePreview
} from './views';

const Rooms = lazy(() => import('./views/Rooms/Rooms'));
const Domains = lazy(() => import('./views/Domains/Domains'));
const Templates = lazy(() => import('./views/Templates/Templates'));
const Dashboard = lazy(() => import('./views/Dashboard/Dashboard'));


const Routes = (props) => {
    let { loginReturnPath, signIn, state, api, docs, ...rest } = { ...props };

    return (
        <Suspense fallback={<div>Loading...</div>}>
        <Switch>
            <Route path="./payment_confirm_response.html" component={'div'} />
            <PublicRouteWithLayout
                component={Login}
                state={state}
                api={api}
                exact
                layout={LoginLayout}
                path="/login"
                {...rest}
                signIn={signIn}
            />
            <PublicRouteWithLayout
                component={Verify}
                state={state}
                api={api}
                exact
                layout={LoginLayout}
                path="/verify"
                {...rest}
                signIn={signIn}
            />
            <PublicRouteWithLayout
                component={ResetPassword}
                state={state}
                api={api}
                exact
                layout={LoginLayout}
                path="/reset-password"
                {...rest}
                signIn={signIn}
            />
            <PublicRouteWithLayout
                component={NewPassword}
                state={state}
                api={api}
                exact
                layout={LoginLayout}
                path="/new-password"
                {...rest}
                signIn={signIn}
            />
            <PublicRouteWithLayout
                component={NotFound}
                state={state}
                exact
                layout={LoginLayout}
                path="/verify-account"
                {...rest}
            />
            <PublicRouteWithLayout
                component={Confirmation}
                exact
                layout={LoginLayout}
                path="/confirmation"
                {...rest}
                signIn={signIn}
                state={state}
            />
           
            <RouteWithLayout
                {...rest}
                component={Domains}
                exact
                layout={AdminLayout}
                path="/domains"
                title="Domains"
                state={state}
                api={api}

            />
            <RouteWithLayout
                {...rest}
                component={NotFound}
                exact
                layout={MainLayout}
                state={state}
                path="/domains/add"
            />

            <RouteWithLayout
                {...rest}
                component={DomainsIPAddresses}
                exact
                layout={AdminLayout}
                path="/domains/ips/:domainId"
                title="Edit Domain"
                state={state}
                api={api}
            />
            <RouteWithLayout
                {...rest}
                component={DomainsDeploymentSettings}
                exact
                layout={AdminLayout}
                path="/deployment-settings/:domainId"
                title="Domain Deployment Settings"
                state={state}
                api={api}
            />
            <RouteWithLayout
                {...rest}
                component={DomainsEdit}
                exact
                layout={AdminLayout}
                path="/domains/:domainId"
                title="Edit Domain"
                state={state}
                api={api}
            />
            <RouteWithLayout
                {...rest}
                component={EmailMe}
                exact
                layout={AdminLayout}
                path="/email-me"
                title="Email Me"
                state={state}
                api={api}
            />
            <RouteWithLayout
                {...rest}
                component={Fraud}
                exact
                layout={AdminLayout}
                path="/recaptcha"
                title="ReCAPTCHA"
                state={state}
                api={api}
            />

            <RouteWithLayout
                {...rest}
                component={Reports}
                exact
                layout={AdminLayout}
                path="/reports"
                title="Reports"
                state={state}
                currentlySelectedDomain={state.currentlySelectedDomain}
                api={api}
                {...rest}
            />

            <RouteWithLayout
                {...rest}
                component={Rooms}
                exact
                layout={AdminLayout}
                path="/rooms"
                title="Waiting Rooms"
                state={state}
                currentlySelectedDomain={state.currentlySelectedDomain}
                api={api}
                {...rest}
            />
            <RouteWithLayout
                {...rest}
                component={RoomCapacity}
                exact
                layout={AdminLayout}
                path="/rooms/manage-room-capacity"
                title="Manage Room Capacity"
                state={state}
                currentlySelectedDomain={state.currentlySelectedDomain}
                api={api}
                {...rest}
            />
            <RouteWithLayout
                {...rest}
                component={AddRoomAmend}
                exact
                layout={AdminLayout}
                path="/rooms/:action/:id"
                title="Add Waiting Rooms"
                state={state}
                currentlySelectedDomain={state.currentlySelectedDomain}
                api={api}
                />
            <RouteWithLayout
                {...rest}
                component={AddRoomAmend}
                exact
                layout={AdminLayout}
                path="/rooms/:action/:id"
                title="Edit Waiting Room"
                state={state}
                currentlySelectedDomain={state.currentlySelectedDomain}
                api={api}
                />
            {/* <RouteWithLayout
                {...rest}
                component={EditRoom}
                exact
                layout={AdminLayout}
                path="/rooms/edit/:roomId"
                title="Edit Waiting Room"
                state={state}
                currentlySelectedDomain={state.currentlySelectedDomain}
                api={api}
                /> */}
            <RouteWithLayout
                {...rest}
                component={NotFound}
                exact
                layout={MainLayout}
                state={state}
                path="/rooms/add"
                />
            {/* <RouteWithLayout
                {...rest}
                component={RoomSessions}
                exact
                layout={AdminLayout}
                path="/rooms/sessions/:roomId"
                title="Room Sessions"
                state={state}
                rooms={state.rooms}
                api={api}
            /> */}
            <RouteWithLayout
                {...rest}
                component={RoomSessionLayout}
                exact
                layout={AdminLayout}
                path="/rooms/sessions"
                title="Room Sessions"
                state={state}
                rooms={state.rooms}
                api={api}
            />
            <RouteWithLayout
                {...rest}
                component={AddRoom}
                exact
                layout={AdminLayout}
                path="/rooms/:roomId"
                title="Waiting Rooms"
                state={state}
                rooms={state.rooms}
                api={api}
            />

            <RouteWithLayout
                {...rest}
                component={Templates}
                exact
                layout={AdminLayout}
                path="/templates"
                title="Templates"
                api={api}
                state={state}
            />
            <RouteWithLayout
                {...rest}
                component={TemplatePreview}
                exact
                layout={AdminLayout}
                path="/template-preview/:templateId/:roomId"
                title="Template Preview"
                api={api}
                state={state}
            />
            <RouteWithLayout
                {...rest}
                component={Account}
                exact
                layout={AdminLayout}
                path="/account"
                title="Account"
                api={api}
                state={state}
                docs={docs}
            />
            <RouteWithLayout
                {...rest}
                component={Account}
                exact
                layout={AdminLayout}
                path="/account/:tabID"
                title="Account"
                api={api}
                state={state}
                docs={docs}
            />
            <RouteWithLayout
                {...rest}
                component={QueueJump}
                exact
                layout={AdminLayout}
                path="/priority-access"
                title="Priority Access"
                api={api}
                state={state}
                docs={docs}
            />
            <RouteWithLayout
                {...rest}
                component={QueueJumpManageCodes}
                exact
                layout={AdminLayout}
                path="/priority-access/manage-codes/:groupId"
                title="Priority Access - Manage Codes"
                api={api}
                state={state}
                docs={docs}
            />
            <RouteWithLayout
                {...rest}
                component={Dashboard}
                exact
                layout={AdminLayout}
                path="/"
                title="Dashboard"
                state={state}
                api={api}
                showDomainSelector={true}
            />

            {/* Finally, catch all unmatched routes */}
            <RouteWithLayout
                {...rest}
                component={NotFound}
                exact
                layout={MainLayout}
            />
        </Switch></Suspense>)

}

export default Routes;
