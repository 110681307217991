import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { GridOverlay, DataGrid } from '@mui/x-data-grid';
import ZoomInIcon from '../icons/ZoomInIcon';
import IPDataDialog from './IPDataDialog';
import SessionsTableDataGrid from './SessionsTableDataGrid';
import './SessionTable.css';
import ErrorSnackBar from './ErrorSnackBar';

import useMediaQuery from '@material-ui/core/useMediaQuery';

import { Skeleton } from '@material-ui/lab';

import {
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core';

import {IPData, SessionAgent} from './';



const useStyles = makeStyles((theme) => ({
  table: {
    // maxWidth: 440,
  },
  betaBadge: {
    '& .MuiBadge-badge': {
        fontSize: '10px',
        backgroundColor: 'transparent',
        color: theme.palette.secondary.main,
        top: '15px',
        right: '-10px',
    }
  },

  paper: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(2),
    width: '100%',
    zIndex: 1,
    position: 'relative',
  },
  cardContent: {
    width: 'auto'
  },
  cell: {
    wordWrap: 'break-word',
    padding: '8px'
  },
  iconLink: {
    lineHeight: 0
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.primary.main,
    backgroundColor: 'rgba(255,255,255,0.3)',
  },
  sessionInfo : {
    margin: `${theme.spacing(2)}px 0`,
  },
  meter : {
    boxSizing: 'content-box',
    height: '20px', // Can be anything 
    position: 'relative',
    background: '#555',
    borderRadius: '0px',
    padding: '2px',
    width: '100%',
    boxShadow: 'inset 0 -1px 1px rgba(255, 255, 255, 0.3)',

    '& > span' : {
      display: 'block',
      height: '100%',
      borderTopRightRadius: '0px',
      borderBottomRightRadius: '0px',
      borderTopLeftRadius: '0px',
      borderBottomLeftRadius: '0px',
      boxShadow: 'inset 0 2px 9px rgba(255, 255, 255, 0.3), inset 0 -2px 6px rgba(0, 0, 0, 0.4)',
      position: 'relative',
      overflow: 'hidden',
    }, 
  },
  meterValue: {
    color: '#fff',
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    textAlign: 'center',
    height: '100%',
    lineHeight: 1.7,
  }
}
));



export default function SessionsTable({
                        api,
                        sessions,
                        showRequests,
                        loading,
                        browserIcon,
                        hasAnomalyDetection,
                        hasFirewall,
                        paginationClick,
                        queryParams})
                        {

  const ip_control_small = useMediaQuery(`@media (min-width:960px)`);
  const displayTouched = useMediaQuery(`@media (min-width:1240px)`);
  const displayJoined = useMediaQuery(`@media (min-width:1240px)`);
  const classes = useStyles();
  const [rows, setRows] = React.useState([]);


  useEffect(() => {
    let rows = sessions.map((session, index) => {
      let data = {}
      data.id = index;
      data.token = session.token;
      data.joined = session.joinedTime;
      data.touched = session.touchedTime;
      data.ip = session.ip;
      if (hasFirewall) {
        data.ip_data = session.ip_data;
      }
      if(hasAnomalyDetection) {
        data.risk = session.score;
      }
      data.agent = session.agent;
      data.position = session.position;
      data.requests = {token: session.token, ip: session.ip} ;

      return data;
    })

    setRows((prevState) => {
        return [...rows];
      }
    );


    
  },[hasAnomalyDetection, hasFirewall, sessions]);

  let columns = [];

  //token
  columns.push(
    { field: 'token', headerName: 'Token', editable: false, width: 160,
    renderCell: (params) => {
      return (
        <CopyToClipboard text={params.value}
        onCopy={() => {
          setMessage('Copied token to clipboard');
          setAlertBarStatus('success');
          setOpenErrorSnackbar(true);
        }}
        >
          <Typography className="copy">{params.value}</Typography>
        </CopyToClipboard>
      )
    }}
  )
  // joined
  columns.push({ field: 'joined', headerName: 'Joined', editable: false, width: 140, hide:!displayJoined  }  )
  // touched
  columns.push({ field: 'touched', headerName: 'Touched', editable: false, width: 140, hide:!displayTouched    })
  // ip
  columns.push({
    field: 'ip',
    headerName: 'IP',
    editable: false,
    width: ip_control_small ? null : 100,
    flex: ip_control_small ? 1 : null ,
    renderCell: (params) => {
      return (params.value) ? 
      <CopyToClipboard text={params.value}
      onCopy={() => {
        setMessage('Copied IP to clipboard');
        setAlertBarStatus('success');
        setOpenErrorSnackbar(true);
      }}
      >
      <Typography className="copy">{params.value}</Typography>
    </CopyToClipboard>
      : <></>;
    },
    valueFormatter: ({ value }) => `${value}`,
  })

  if(hasFirewall) {
    columns.push(
      {
        field: 'ip_data',
        headerName: 'IP Info',
        editable: false,
        width: 110,
        renderCell: (params) => {
          if(params.value) {
            return <IPData ip_data={params.value}/>
          } else {
            return <Skeleton width={'100%'}></Skeleton>
          }
        },
        // valueFormatter: ({ value }) => `${value.ip}`,
    },
    )
  }

  if(hasAnomalyDetection) {
    columns.push(
      { field: 'risk', renderHeader: () => {

        return  <>Risk</>

      }, editable: false, width: 80,
      renderCell: ({value}) => {
          try {

            let colors = {
              'green': '#00bf0c',
              'amber': '#ffa900',
              'red': '#FF0000',
              'grey': '#808080',
            }

            
            let color = 'green';
            if (value > 60) {
              color = 'red';
            } else if (value > 29) {
              color = 'amber';
            } else {
              color = 'green';
            }

            let width = (value < 101) ? `${value}%` : `100%`;

            let backgroundMeter = {
              background: value === 0 ? '#adadad' : '#555',
            }

            // width = value===0 ? '2px' : width;

            if (!hasAnomalyDetection) {
              color = 'grey'
            }

            return (

              <div className={classes.meter} style = {backgroundMeter}>
              <span style={{width: width, backgroundColor: colors[color]}}></span>
              <div className={classes.meterValue}>{`${value}%`}</div>
              </div>

            )

          } catch (error) {
            console.log(error);
            return '-';
          }
    },
  }
    )
  }

  columns.push(
    { field: 'agent_data', headerName: 'Agent', editable: false, width: 96,
    valueFormatter: ({ value }) => {
      if(value) {
        if(value.ua) {
          return value.ua;
        }
      }
      return '';

    },
    renderCell: (params) => {
      if(params.value) {
        return <SessionAgent browserIcon={browserIcon} {...params}/>
      } else {
        return <Skeleton width={'100%'}></Skeleton>
      }
    },
  }
  )

  columns.push({ field: 'position', headerName: 'Position', editable: false, width: 107, type:'number',
  valueFormatter: ({ value }) => {
    if(value === 0) {
      return 'Active';
    }
    return value;

   
  }, });

  columns.push({ field: 'requests', renderHeader: () => {
    return <></>;
  }, filterable: false, sortable: false, editable: false, width: 80, renderCell: (params) => {
    return <Tooltip title="View Details">
    <span>
    <IconButton
      onClick={(event) => {
        showRequests(
          event,
          params.value
          )
        }}
        className={classes.iconLink}
        >
      <ZoomInIcon />
    </IconButton>
    </span>
  </Tooltip>
  },
});

  let [openIPDataDialog, setOpenIPDataDialog] = useState(false);
  let [drawerInfo, setDrawerInfo] = useState();

  
  // useEffect(() => {
  //   if(filters.archivedUsers === 1) {
  //     setSortModel([{ field: 'risk', sort: 'desc' }])
  //   }
  // }, [filters.archivedUsers]);

 


  

  let [message, setMessage] = useState('');
  let [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  let [alertBarStatus, setAlertBarStatus] = useState('');
  const closeErrorSnackbar = () => {
    setOpenErrorSnackbar(false);
  }


  return (
        <div style={{
          height: (rows < 1) ? '300px' : 'auto',
        }}>

          
{/* {JSON.stringify(filters, null, 4)} */}



         
          <SessionsTableDataGrid
          api={api}
          rows={rows}
          columns={columns}
          archiveUsers={queryParams && queryParams.includeActiveUsers === 2 ? true: false}
          paginationClick={paginationClick}
          pageNo={queryParams && queryParams.page ? queryParams.page + 1 : 1}
          loading={loading}
          defaultSortModel={[
            {
              field: 'position',
              sort: 'asc',
            },
          ]}
          />
          

          {/* <IPDataDialog openDialog={openIPDataDialog} handleDialogClose={handleDialogClose} drawerInfo={drawerInfo} /> */}
          <ErrorSnackBar message={message} open={openErrorSnackbar} status={alertBarStatus} closeErrorSnackbar={closeErrorSnackbar} />

{/* <SessionAgentDialog openAgentDialog={openAgentDialog} handleClose={() => {
                    setOpenAgentDialog(false);
                    setAgentData(null)
                }
            } agentData={agentData}/> */}


          </div>

  );
}













