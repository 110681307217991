import React from 'react';
import {Backdrop, Paper, Card, Typography} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#000',
    backgroundColor: 'rgba(255,255,255,0.8)'
  },
  root: {
    minWidth: 275,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold'
  },
  pos: {
    marginBottom: 12,
  },
}));



const ReloadPanel = ({open}) => {
  const classes = useStyles();

  const handleClick = () => {
    window.location.replace('/login')
  }




  return (
    <div>

    <Backdrop className={classes.backdrop} open={open} onClick={handleClick}>
        <Paper>
        <Card className={classes.root} variant="outlined">
      <CardContent>
        <Typography className={classes.title} gutterBottom>
        Please sign in again
        </Typography>
        <Typography variant="body2" component="p">
        You were signed out of your account. Please reload to sign in to Crowdhandler again.
        </Typography>
      </CardContent>
      <CardActions>
        <Button onClick={handleClick} color="secondary" variant="outlined" size="small">Reload</Button>
      </CardActions>
    </Card>
          
        </Paper>
      </Backdrop>
        </div>
  );

}


export default ReloadPanel;