
import React, {useState, useEffect} from 'react';
// import { makeStyles } from '@material-ui/core/styles';

import {
  Grid,
  FormControl,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Button,
  Paper,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';

import { useTheme, makeStyles } from '@material-ui/core/styles';

// const useStyles = makeStyles({
//   table: {
//     minWidth: 650,
//   },
// });

const useStyles = makeStyles((theme) => ({
  title: {
      '&:hover' : {
        textDecoration  : 'underline',
        cursor: 'pointer'
      }
    },
}));


const ReportsMainTable = ({rows, updateChart, updateCheckboxes, checkboxes}) => {
  const classes = useStyles();


  // useEffect(() => {

  //   let checkboxes = {}

  //   if(rows) {
  //     rows.forEach(row => {
  //       checkboxes[row.id] = true
  //     });
  //   }

  //   setCheckboxes(checkboxes);

  // }, [rows]);

  const handleRowClick = (value) => {
    updateCheckboxes({
      ...checkboxes,
      [value] : !checkboxes[value]
    });
   
  }

  return (
    <TableContainer component={Paper}>
      <Table aria-label="Report table">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Room</TableCell>
            <TableCell align="right">Min.</TableCell>
            <TableCell align="right">Max.</TableCell>
            <TableCell align="right">Avg.</TableCell>
            <TableCell align="right">Avg. Wait</TableCell>
            <TableCell align="right">Max. Wait</TableCell>
            
          </TableRow>
        </TableHead>
        <TableBody>

          {!rows.length ?
          <>
          <TableCell colSpan={7} align="center">No Data</TableCell>
          
          </>
          : null}
          {rows.sort((a, b) => {
              if (a.avg > b.avg) {
                return -1;
              } else if (a.avg < b.avg) {
                return 1;
              }
              return 0;
            }).map((row) => (
            <TableRow
            key={row.id}
            hover
            onClick={(event) => handleRowClick(row.id)}
            role="checkbox"
            aria-checked={checkboxes[row.id]}
            tabIndex={-1}
                    
            >
              <TableCell component="th" scope="row">

                
              <Checkbox
                checked={(checkboxes[row.id]) ? checkboxes[row.id] : false}
              
              />
              </TableCell>
              <TableCell align="left" style={{color:`${row.color}`}}
                className={classes.title}> {row.title}</TableCell>
              <TableCell align="right">{row.min.value}</TableCell>
              <TableCell align="right">{row.max.value}</TableCell>
              <TableCell align="right">{row.avg}</TableCell>
              <TableCell align="right">{(row.avg_waiting) ? `${row.avg_waiting} mins.` : `–`}</TableCell>
              <TableCell align="right">{(row.max_wait_time) ? `${row.max_wait_time} mins.` : `–`}</TableCell>
              
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default ReportsMainTable
