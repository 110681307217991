
import Config from '../config'
import { DateTime } from 'luxon';

export const AddRoomActiveToRoom = (rooms) => {
    let formattedRooms = rooms.reduce((acc, room) => {

        let activateTime = DateTime.fromISO(room.queueActivatesOn);
        let countdownTime = DateTime.fromISO(room.countdownStartsOn);
        var now = DateTime.local();
    
        if (countdownTime < now) {
          room.countdownStartsOnFormatted = "Active";
          room.countdownIsActive = true;
        } else {
          room.countdownStartsOnFormatted = `${countdownTime.toFormat(Config.DATE_FORMATS.FULL_NO_TIME)} ${countdownTime.toFormat(Config.DATE_FORMATS.TIME_NO_DAY)}`
          room.countdownIsActive = false;
        }
        if (activateTime < now) {
          room.queueActivatesOnFormatted = "Active";
          room.queueActivatesIsActive = true;
        } else {
          room.queueActivatesOnFormatted = `${activateTime.toFormat(Config.DATE_FORMATS.FULL_NO_TIME)} ${activateTime.toFormat(Config.DATE_FORMATS.TIME_NO_DAY)}`
          room.queueActivatesIsActive = false;
        }
        acc.push(room)
        return acc;
      },[]);

      return formattedRooms;
}
