import React, { useState, useEffect, useContext } from "react";
import {AuthContext} from '../../providers/AuthProvider';
import {
    useHistory,
    useLocation
} from "react-router-dom";

import {
    Container,
    Grid,
    Button,
    FormControl,
    InputLabel,
    OutlinedInput,
    Typography,
    Divider,
    InputAdornment,
    IconButton,
    Link
} from "@material-ui/core";

import { makeStyles } from '@material-ui/core/styles';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
// import PropTypes from 'prop-types';
import {EyeIcon, EyeOffIcon} from '../../icons';
import ErrorSnackBar from '../../components/ErrorSnackBar';

const useStyles = makeStyles((theme) => ({
    verificationPanel: {
        marginBottom: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    resendCodePanel: {
        marginTop: theme.spacing(2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: theme.palette.primary.main,
        backgroundColor: '#fff',
      },
}));


function Login({ state, signIn, authorised, api, dispatch, ...rest }) {

    

    const history = useHistory();
    const location = useLocation();
    const classes = useStyles();
    
    const chAuth = useContext(AuthContext);

    let [username, setUsername] = useState();
    let [region, setRegion] = useState();
    let [session, setSession] = useState();

    let [login, setLogin] = useState(true);
    let [displayMFACodeInput, setDisplayMFACodeInput] = useState(false);
    let [message, setMessage] = useState('');
    let [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
    let [alertBarStatus, setAlertBarStatus] = useState();

    let [authenticating, setAuthenticating] = useState(false);
    let { from } = location.state || "/" ;
    let [fields, setFields] = useState({
        newPassword: '',
        code: '',
        mfa_code : ''
    });

    let [showPassword, setShowPassword] = useState(false);
    
    // Redirect to dashboard if already authorised
    useEffect(() => {
        if(authorised){
            history.replace('/');
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
      };
    
      const handleMouseDownPassword = (event) => {
        event.preventDefault();
      };

    const loginForm = useFormik({
        initialValues: {
            username: '',
            password: '',
        },
        validationSchema: Yup.object({
            username: Yup.string().required('A username is required'),
            password: Yup.string().required('A password is required')
                .min(8, "Password requires a minimum of 8 characters")
                .matches(/\d+/, { message: 'Password must contain a number' })
                .matches(/[a-z]+/, { message: 'Password must contain lowercase letters' })
                .matches(/[A-Z]+/, { message: 'Password must contain uppercase letters' })
                .matches(/[=+\-^$*.[\]{}()?"!@#%&/\\,><':;|_~`]/, { message: 'Password must contain a special character' }),
        }),
        onSubmit: async (values) => {
            dispatch({ type: 'SET_IS_LOADING', payload: true });
            let signin_response = await chAuth.handleSignIn({
                username : values.username.trim(),
                password : values.password.trim()
            });
            
            let {cognitoUser, ChallengeName, Region, Session, error} = signin_response;
            if (cognitoUser) {
                signIn({history, from, reason: null, Region});
            }

            if (ChallengeName) {

                setUsername(values.username.trim());
                setRegion(Region);
                setSession(Session);


                if (ChallengeName === 'NEW_PASSWORD_REQUIRED') {
                    setAuthenticating(false);
                    setLogin(false);
                    dispatch({type:'SET_IS_LOADING', payload: false});
                } else if (ChallengeName === 'PASSWORD_VERIFIER') {
                    setAuthenticating(false);
                    handleViewShowVerification();
                    dispatch({type:'SET_IS_LOADING', payload: false});
                } else if (ChallengeName === 'SMS_MFA' || ChallengeName === 'SOFTWARE_TOKEN_MFA') {
                    setAuthenticating(false);
                    setLogin(false);
                    setDisplayMFACodeInput(true);
                    dispatch({type:'SET_IS_LOADING', payload: false});
                }

            }

            if (error) {
                setMessage(error.message)
                setAlertBarStatus('error');
                setOpenErrorSnackbar(true);
                dispatch({ type: 'SET_IS_LOADING', payload: false });
            }
        }
    })

    


    const closeErrorSnackbar = () => {
        setOpenErrorSnackbar(false)
    }


    const handleFields = (event) => {
        setFields({
            ...fields,
            [event.target.name]: event.target.value.trim()
        })
    }


    const handleViewShowVerification = () => {
        setLogin(false)
    }

    const handleMFASubmit = async (event) => {
        event.preventDefault();
        setAuthenticating(true);
        dispatch({ type: 'SET_IS_LOADING', payload: true });
        // You need to get the code from the UI inputs
        // and then trigger the following function with a button click
       
        // If MFA is enabled, sign-in should be confirmed with the confirmation code

        let response = await chAuth.respondToAuthChallenge({
            UserCode : fields.mfa_code.trim(),
            Username: username,
            ChallengeName : 'SOFTWARE_TOKEN_MFA',
            Region : region,
            Session: session
        });

        let {cognitoUser, type, message} = response;


        if (cognitoUser) {
            signIn({ history, from, reason: null});
        }

        if (type) { // there's been a exception
            setMessage(message);
            setAlertBarStatus('error');
            setOpenErrorSnackbar(true);
            dispatch({type:'SET_IS_LOADING', payload: false});
        }

}

    return (

            <Container maxWidth='sm'>
                

                

                {login
                    ?
                    <>
                    <Grid container component="form" onSubmit={loginForm.handleSubmit} className={classes.verificationPanel}>
                        <Grid item xs={12}>
                            <FormControl fullWidth margin="normal" variant="outlined">
                                <InputLabel htmlFor="username">Username</InputLabel>
                                <OutlinedInput
                                    type="text"
                                    id='username'
                                    name='username'
                                    {...loginForm.getFieldProps('username')}
                                    labelWidth={63}
                                    placeholder="Username"
                                    aria-label="Username"
                                    aria-describedby="username"
                                    disabled={authenticating}
                                ></OutlinedInput>
                            </FormControl>
                            {loginForm.touched.username && loginForm.errors.username ? <Typography variant="subtitle2" color="error">{loginForm.errors.username}</Typography> : null}

                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth margin="normal" variant="outlined">
                                <InputLabel htmlFor="password">Password</InputLabel>
                                <OutlinedInput
                                    type={showPassword ? 'text' : 'password'}
                                    id='password'
                                    name='password'
                                    {...loginForm.getFieldProps('password')}
                                    labelWidth={60}
                                    placeholder="Password"
                                    aria-label="Password"
                                    aria-describedby="password"
                                    disabled={authenticating}
                                    endAdornment={
                                        <InputAdornment position="end">
                                          <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                          >
                                            {showPassword ? <EyeOffIcon /> : <EyeIcon /> }
                                          </IconButton>
                                        </InputAdornment>
                                      }
                                ></OutlinedInput>
                            </FormControl>
                            {loginForm.touched.password && loginForm.errors.password ? <Typography variant="subtitle2" color="error">{loginForm.errors.password}</Typography> : null}

                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="contained"
                                disabled={authenticating || !loginForm.isValid}
                                size="small"
                                disableElevation
                                type="submit"
                                color="secondary">
                                {authenticating ? `Signing In` : `Sign In`}
                            </Button>
                        </Grid>



                    </Grid>
                    <Divider />
                    <Grid container spacing={1} direction="row" className={classes.resendCodePanel}>
                        <Grid item xs={12}>
                        <Button
                        disableElevation
                        size="small"
                        variant="contained"
                        color="secondary"
                        component={RouterLink}
                        to={{
                            pathname : `/reset-password`,
                            state: { email: fields.username } 
                        }}
                  
                >

                            Forgot Password?
                            </Button>
                        </Grid>
                    </Grid>
                    </>
                    :
                    null
                }

                {displayMFACodeInput
                    ?
                    <Grid container autoComplete="off" component="form" onSubmit={(event) => handleMFASubmit(event)} className={classes.verificationPanel}>
                    <Grid>

                        <Typography>Enter an MFA code to complete sign-in.</Typography>
                        <FormControl fullWidth margin="normal" variant="outlined">
                                <InputLabel htmlFor="password">MFA Code</InputLabel>
                                <OutlinedInput
                                    type="text"
                                    id='mfa_code'
                                    name='mfa_code'
                                    value={fields.mfa_code}
                                    onChange={(event) => handleFields(event)}
                                    labelWidth={60}
                                    placeholder="MFA Code"
                                    aria-label="mfa_code"
                                    aria-describedby="mfa_code"
                                ></OutlinedInput>
                            </FormControl>

                            <Button variant="contained"
                                onClick={handleMFASubmit}
                                size="small"
                                disableElevation
                                type="submit"
                                color="secondary">
                                Submit
                            </Button>
                            <Typography style={{marginTop:20}}>
                                <Link target="_blank" href="https://www.crowdhandler.com/docs/80001081002-two-factor-authentication">Need help?</Link>
                            </Typography>
                    </Grid>
                    </Grid>
                    :
                    null
                    }

            
                <ErrorSnackBar message={message} open={openErrorSnackbar} status={alertBarStatus} closeErrorSnackbar={closeErrorSnackbar} />
            
            </Container>
    )
}

export default withRouter(Login);
