import React, {FunctionComponent, useEffect, useState} from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import clsx from 'clsx';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

interface Props {
    domains: { id: string, name: string }[];
    domainId: string;
    onChange?: (v: string) => void,
    stylesOverride?: () => ClassNameMap<'formControl'| 'formControlSelect' | 'hideFormControlSelect'>;
    updating?: boolean;
}

const useStyles = makeStyles((theme) => ({
    formControl: {
        marginLeft: 'auto',
    },
    formControlSelect: {
        color: '#ffffff',

        '& svg' : {
            color: '#ffffff'
        }
    },
    hideFormControlSelect: {
        display: 'none'
    }
}));

const AdminHeaderDomainSelector: FunctionComponent<Props> = ({onChange, stylesOverride, updating, domainId, domains}) => {
    let classes = useStyles();

    const [currentDomainID, setCurrentDomainID] = useState(domainId);

    useEffect(() => {
        setCurrentDomainID(domainId);
    }, [domainId]);

    if(stylesOverride) {
        classes = stylesOverride();
    }

    const updateDomain = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        setCurrentDomainID(event.target.value as string)
        if (typeof onChange === 'function') onChange(event.target.value as string);
    }
    
    return (
        <FormControl size="small" variant="outlined" className={classes.formControl}>
                <Select
                className={clsx({
                    [classes.formControlSelect] : !updating,
                    [classes.hideFormControlSelect]: updating
                })}
                disabled={updating}
                labelId="demo-simple-select-outlined-label"
                value={currentDomainID}
                defaultValue={currentDomainID}
                onChange={(event) => {updateDomain(event)}}
                label="Current Domain"
                { ...{notched: false }}
                >
                {domains.sort((a, b) => {
                    return a.name > b.name ? 1:-1;
                }).map((d)=>{
                    return <MenuItem key={d.id} value={d.id}>{d.name}</MenuItem>
                })}
                </Select>
            </FormControl>
    );
}

export default AdminHeaderDomainSelector;