import React, { useState, useEffect, useContext } from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/core/styles';
import {AuthContext} from '../../providers/AuthProvider';

import {
    ListItem,
    Grid,
    ListItemAvatar,
    Avatar,
    Typography,
    Button,
    Tooltip,
    IconButton,
    DialogTitle,
    DialogContent,
    DialogActions,
    Dialog,
    Switch,
    FormControlLabel
} from "@material-ui/core";
import UserIcon from '../../icons/UserIcon';
import TrashIcon from '../../icons/TrashIcon';
import Config from '../../config';

const useStyles = makeStyles((theme) => ({
    avatar : {
        backgroundColor: theme.palette.secondary.main,
    }
}));


const AccountUserRow = ({
    user,
    api,
    state,
    dispatch,
    setShowLoader,
    selectedAccountHolder,
    displayAccountHolderButton,
    handlerUpdatePrimaryAccount,
    signOut
}) => {
    
    const chAuth = useContext(AuthContext);
    let classes = useStyles();

    let [role, setRole] = useState((user.role === '2') ? false: true);
    let [roleTitle, setRoleTitle] = useState('');
    let [userToDelete] = useState(user);
    let [openDeleteUserDialog, setOpenDeleteUserDialog] = useState(false);
    let [openAccountHolderDialog, setOpenAccountHolderDialog] = useState(false);

    const theme = useTheme();
    const upSmall = useMediaQuery(theme.breakpoints.up('sm'));

    const handleRoleSelectChange = () => {

        let newValue = !role;
        setRole(newValue);
        handleSave(newValue)
    }

    const deleteUser = async () => {
        setShowLoader(true)
        setOpenDeleteUserDialog(false);
       
        const {idToken} = await chAuth.getIDToken();

        let result = await api.deleteUserRole(userToDelete.username, userToDelete.email, userToDelete.region, idToken.getJwtToken());
        let newUsers = state.accountInformation.get('users').reduce((acc, user) => {
            if (user.username !== result.id) {
                acc.push(user);
            }
            return acc;
        }, []);

        if (userToDelete.username === idToken.payload['cognito:username']) {
           try {
                await signOut()
           } catch (error) {
               console.log(error);
           }
        } else {
            dispatch({
                type: 'UPDATE_ACCOUNT_INFORMATION', payload: {
                    users: newUsers
                }
            });
        }

        setShowLoader(false);

    }

    const handleCancel = () => {
        setOpenDeleteUserDialog(false)
    }
    const handleAccountHolderCancel = () => {
        setOpenAccountHolderDialog(false)
    }

    const handleSave = async (newValue) => {
        setShowLoader(true);

        let roleId;


        switch (newValue) {
            case true:
                roleId = '1';
                break;
            default:
                roleId = '2';
                break;
        }
        const {jwtToken} = await chAuth.getJwtToken();
        
        try {
            let result = await api.updateUserRole(user.username, roleId, jwtToken, user.region);

            if (result.error) {
                return false;
            }

            let newAttributes = result.UserAttributes.reduce((acc, att) => {
    
                switch (att.Name) {
                    case 'sub':
                        acc.username = att.Value;
                        break;
                    case 'custom:role':
                        acc.role = att.Value;
                        break;
                    case 'email':
                        acc.email = att.Value;
                        break;
                    default:
                        break;
                }
                return acc;
            }, {});

            // merge the attributes
            const new_user = {
                ...user,
                ...newAttributes
            }

            let new_users = state.accountInformation.get('users').map((user) => {
                if (user.username === new_user.username) {
                    return new_user;
                } else {
                    return user;
                }
            });

            dispatch({
                type: 'UPDATE_ACCOUNT_INFORMATION', payload: {
                    users: new_users
                }
            });


        } catch (error) {
            console.log({error});
        }

        setShowLoader(false);

    }


    useEffect(() => {
        const getRoleTitle = () => {
            let role = Config.ROLES.filter((role) => {
                if (role.id === user.role) {
                    return true;
                }

                return false;
            });

            if (role.length) {
                setRoleTitle(role[0].title)
            }
        }

        getRoleTitle();

    }, [user.role])

    

    return (
        <ListItem component="div">

            <Grid container direction="row" justifyContent="flex-start" alignItems="center">

            {upSmall
            ?
            <Grid item  md={1}>
            <ListItemAvatar>

                {user.accountHolder ?
                <Tooltip title="Primary Account Holder" aria-label="Primary Account Holder">
                <Avatar className={classes.avatar}>
                    <UserIcon />
                </Avatar>
                </Tooltip>
                :
                <Avatar>
                    <UserIcon />
                </Avatar>
                }
            </ListItemAvatar>
            </Grid>
            :
            null
            }

          
            <Grid item xs={upSmall ? 7 : 12} md={upSmall ? 7 : 12}>
                <Typography>{user.email}</Typography>
                
            </Grid>

          

            {state.accountInformation.get('user').attributes['custom:role'] === '1'
            ?
       

            upSmall
            ?
            <Grid item xs={6} md={3}>

                <FormControlLabel
                            control={
                                <Switch
                                checked={role}
                                onChange={handleRoleSelectChange}
                                name="roleSwitcher"
                            />
                            }
                            label="Administrator"
                        />
                </Grid>
            :
            <Grid item xs={6} md={1}>
                <FormControlLabel
                            control={
                                <Switch
                                checked={role}
                                onChange={handleRoleSelectChange}
                                name="roleSwitcher"
                            />
                            }
                            label="Administrator"
                        />
                </Grid>
            

            :
            null}



            {upSmall
            ?

                
            <Grid item xs={1} md={1}>
                <Grid container justifyContent="flex-end">
                    {(state.accountInformation.get('user').attributes['custom:role'] === '1')
                        ?

                        user.accountHolder || !displayAccountHolderButton
                        ?
                        <Tooltip title="You cannot delete the Primary Account Holder" aria-label="You cannot delete the Primary Account Holder">
                            <IconButton style={{color: '#cacaca'}}>
                            <TrashIcon />
                        </IconButton>
                        </Tooltip>
                        :
                        <Tooltip title="Delete User" aria-label="Delete User">
                            <IconButton   aria-label="Delete User" onClick={() => {setOpenDeleteUserDialog(true)}}>
                            <TrashIcon />
                        </IconButton>
                        </Tooltip>

                        :
                        null
                    }
                </Grid>
            </Grid>

            :
            <Grid item xs={6}>
                <Grid container justifyContent="flex-end">
                    {(state.accountInformation.get('user').attributes['custom:role'] === '1')
                        ?
                        <Tooltip title="Delete User" aria-label="Delete User">
                            <IconButton aria-label="Delete User" onClick={() => {setOpenDeleteUserDialog(true)}}>
                            <TrashIcon />
                        </IconButton>
                        </Tooltip>

                        :
                        null
                    }
                </Grid>
                </Grid>
            }

            </Grid>


            {/* <Grid container direction="row" justifyContent="center" alignItems="center">
                <Grid item xs={6} md={2}>
                    <ListItemAvatar>
                        <Avatar>
                            <UserIcon />
                        </Avatar>
                    </ListItemAvatar>

                </Grid>
                <Grid item xs={6} md={5}>
                    <Typography>{user.email}</Typography>
                </Grid>
                <Grid item xs={10} md={4}>

                <FormControlLabel
                            control={
                                <Switch
                                checked={role}
                                onChange={handleRoleSelectChange}
                                name="roleSwitcher"
                            />
                            }
                            label="Administrator"
                        />

                </Grid>

                    
                <Grid item xs={2} md={1}>
                    {(state.accountInformation.get('user').attributes['custom:role'] === '1')
                        ?
                        <Tooltip title="Delete User" aria-label="Delete User">
                            <IconButton aria-label="Delete User" onClick={() => {setOpenDeleteUserDialog(true)}}>
                            <TrashIcon />
                        </IconButton>
                        </Tooltip>

                        :
                        null
                    }
                </Grid>

            </Grid> */}

            <Dialog
                disableEscapeKeyDown
                maxWidth="xs"
                aria-labelledby="confirmation-dialog-title"
                open={openDeleteUserDialog}
                onClose={(event, reason) => {
                    if (reason === 'backdropClick') {
                      return;
                    }
                    setOpenDeleteUserDialog(false);
                  }}

            >
                <DialogTitle id="confirmation-dialog-title">Delete User?</DialogTitle>

                {user.accountHolder ?
                 <DialogContent dividers>
                     <Typography>{`You cannot delete the account holder`}</Typography>
                </DialogContent>
                :
                <DialogContent dividers>
                    <Typography>{`Are you sure you want to delete the user ${userToDelete.email}?`}</Typography>
                </DialogContent>
                }
                <DialogActions>
                    <Button autoFocus onClick={handleCancel} color="primary">
                        {!user.accountHolder ? 'Cancel' : 'Ok'}
                    </Button>
                    {!user.accountHolder ?
                    <Button onClick={deleteUser} color="secondary">
                        Delete
                    </Button>
                    :
                    null
                    }
                </DialogActions>
            </Dialog>


            <Dialog
                disableEscapeKeyDown
                maxWidth="xs"
                aria-labelledby="confirmation-dialog-title"
                open={openAccountHolderDialog}
                onClose={(event, reason) => {
                    if (reason === 'backdropClick') {
                      return;
                    }
                    setOpenAccountHolderDialog(false);
                  }}

            >
                <DialogTitle id="confirmation-dialog-title">Make Primary Account Holder?</DialogTitle>

               
                <DialogContent dividers>
                    <Typography>{`Are you sure you want to make ${selectedAccountHolder} the primary account holder?`}</Typography>
                </DialogContent>
               
                <DialogActions>
                    <Button autoFocus onClick={handleAccountHolderCancel} color="primary">
                        Cancel
                    </Button>
                    <Button color="secondary" onClick={() => {
                        setOpenAccountHolderDialog(false);
                        setOpenDeleteUserDialog(false);
                        handlerUpdatePrimaryAccount()
                    }}>
                        Ok
                    </Button>
                  
                </DialogActions>
            </Dialog>

        </ListItem>
    )
}

export default AccountUserRow;