import React from 'react';
import { useField } from 'formik';
import { OutlinedInput, Typography } from "@material-ui/core";


const ChFormikTextField = ({ ...props }) => {
    const [field, meta] = useField(props);
    return (
        <>
            <OutlinedInput
                {...field} {...props}
            >
            </OutlinedInput>
            {meta.touched && meta.error ? (
                <Typography variant="subtitle2">{meta.error}</Typography>
            ) : null}
        </>
    );
};

export default ChFormikTextField;