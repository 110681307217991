export default {
    javascript: 'JavaScript',
    dns: 'DNS',
    cloudflare: 'Cloudflare',
    cloudfront: 'AWS CloudFront',
    api: 'Custom',
    wordpress: 'WordPress',
    '.net': '.NET',
    akamai: 'Akamai',
    shopifyjs: 'ShopifyJS',
  }