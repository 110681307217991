import React from "react";
import AddDomain from '../AddDomain';
import {
    Typography,
    Grid,
    Button
} from '@material-ui/core';

import {Link as RouterLink} from 'react-router-dom';
const AddDomainFromDashboardPanel = ({api, dispatch}) => {


    return(
        <Grid container spacing={2} direction='column'>
        <Grid item >
            <Typography variant="h3" style={{marginBottom: '12px'}}>No domains have been found</Typography>
        </Grid>
        <Grid item >
            <Typography >Add a domain from the domains screen</Typography>  
        </Grid>
        <Grid item >
            <Button component={RouterLink} variant="contained" color="secondary" to={`domains`}>Domains</Button>
        </Grid>
        </Grid>
    )
}

export default AddDomainFromDashboardPanel;