import React from 'react';
import { ReactComponent as Info } from './feather/info.svg';

import { SvgIcon } from '@material-ui/core';


export default function InfoIcon(props) {
    return (
        <SvgIcon {...props} component={Info} className="feather"/>
    );
  }