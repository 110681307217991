import React, {useEffect, useState, useRef, useCallback} from "react";
import {
    Grid,
    Typography,
    Tooltip,
    IconButton
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import ExternalLinkIcon from '../../../icons/ExternalLinkIcon';

import Config from '../../../config';

import JoinImage from './images/join-wr-image.png';
import OnboardStepper from '../OnboardStepper';
import {TypeWriterComponent} from '../../';




const JoinARoomPanel =  ({domain, rooms}) => {
    const [room, setRoom] = useState();
    let typeWriterData = useRef([])
        const options = {
            cursor: '',
            delay: 15,
            autostart: false
        }

    useEffect(() => {
      let room = rooms.reduce((acc, room) => {  
        if (room.domainID === domain.id) {
          acc.push(room)
        }
        return acc;
      },[]);
      setRoom(room[0]);
      
    }, [domain, rooms]);


    return (
        <Grid>

        {room
        ?
        <>

            <OnboardStepper step={1} />

            {!room.isPropagated ? <Typography>Please wait while your room propagates</Typography> : null}

            <TypeWriterComponent strings={[
              `Let’s take a look at your waiting room.`,
              `Click the icon in the table below to join <strong>${room.title}</strong>`,
              'Once you’ve seen the waiting room, come back to this dashboard, and you’ll see your session on the graph above (this may take up to a minute).',
            ]}
            />
           

          </>
          :
          null
        }
    </Grid>
)

}

export default JoinARoomPanel;

