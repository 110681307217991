import React, { useState, useEffect, lazy, memo } from "react";
import {
    Grid,
    Paper,
    Typography,
    CircularProgress,
    Button,
} from '@material-ui/core';
import { useHistory } from "react-router-dom";
import { DateTime } from 'luxon';
import Config from '../../config'
import { makeStyles } from '@material-ui/core/styles';
import { DashboardURLsTable, DashboardRoomsTable } from '..';

import { AddARoomPanel, ActivateARoomPanel, ExplainRatePanel, JavaScriptImplementationPanel, JoinARoomPanel, WordPressImplementationPanel, SelectImplementationPanel, QuickLinksPanel, WaitForImplementation  } from "./ImplementationPanels";


const DashboardChart = lazy(() => import('./DashboardChart'));
const DashboardResponseDonut = lazy(() => import('./DashboardResponseDonut'));
const DashboardSettings = lazy(() => import('./DashboardSettings'));
const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
        width: 'auto'
    },
    onboardpaper: {
        padding: theme.spacing(4),
        marginBottom: theme.spacing(2),
        width: 'auto',
    },
    chartContainer: {
        position: 'relative'
    },
    downloadButton: {
        backgroundColor: '#ffffff',
        '&:hover': {
            backgroundColor: '#ffffff',
        }
    },
    noDataImageBlock: {
        color: theme.palette.grey[100],
        marginBottom: theme.spacing(4),
        backgroundImage: 'url(bg-images/bg.jpg)',
        backgroundSize: 'cover',
        backgroundPositionY: 'bottom',
        height: '320px',
        width: '100%',
    },
    noWaitingAll: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'inline-block'
        },
    },
    noWaitingNone: {
        display: 'inline-block',
        [theme.breakpoints.up('md')]: {
            display: 'none'
        },
    },
    fixHeight: {
        // display:'flex',
        '& .MuiPaper-root' : {
            height: '100%',
            boxSizing: 'border-box'
            // width: '100%',
            // justifyContent: 'stretch'
        }
    },
    warning : {
       color:'red'
    },
    backdrop: {
        width: '100%'
    },
}));

const DashboardCharts = ({
    firstRun,
    dashboardState,
    state,
    rooms,
    chartLoading,
    updateSettings,
    currentDomain,
    handleSettingsChange,
    urlData,
    errorGettingURLData,
    api,
    dispatch,
    graphData,
    dispatchDash
}) => {

    const classes = useStyles();
    let history = useHistory();
    let [domainRooms, setDomainRooms] = useState([]);
    let [hasRedSegments, setHasRedSegments] = useState(false);

    const displayInfoPanel = () => {

        let panel;

        if (dashboardState.info_panel.state) {
            switch (dashboardState.info_panel.state) {
                case "activate_a_room":
                    panel = <ActivateARoomPanel dashboardState={dashboardState} room={rooms[0]}/>
                    break;
                case "add_a_room":
                    panel = <AddARoomPanel domain={currentDomain} dashboardState={dashboardState} api={api} dispatch={dispatch} state={state}/>
                    break;
                case "add_a_room_with_implementation":
                    panel = <AddARoomPanel domain={currentDomain} dashboardState={dashboardState} api={api} dispatch={dispatch} state={state}/>
                    break;
                case "join_a_room":
                    panel = <JoinARoomPanel domain={currentDomain} rooms={rooms}/>
                    break;
                case "javascript":
                    panel = <JavaScriptImplementationPanel pkey={state.publicKey} domain={currentDomain} api={api}/>
                    break;
                case "wordpress":
                    panel = <WordPressImplementationPanel api={api} pkey={state.publicKey} domain={currentDomain} rooms={rooms}/>
                    break;
                case "dns":
                case "cloudfront":
                case "cloudflare":
                case "shopifyjs":
                case "akamai":
                case "api":                    
                case ".net":                    
                    panel = <QuickLinksPanel domainRooms={domainRooms} domain={currentDomain} domains={state.domains}/>
                    break;
                case "wait_for_implementation":
                    panel = <WaitForImplementation />
                    break;
                case "explain_rate":
                    panel = <ExplainRatePanel />
                    break;
                case 'select_implementation':
                    panel = <SelectImplementationPanel domain={currentDomain} api={api} dispatch={dispatch}/>
                    break;
                default:
                    panel = null;
                    break;
            }
        }


        return panel;
    }



    useEffect(() => {
        const filterRoomsByDomain = () => {
            let roomsByDomain = rooms.reduce((acc, room)=>{
                if (room.domainID === currentDomain.id) {

                    let activateTime = DateTime.fromISO(room.queueActivatesOn);
                    let countdownTime = DateTime.fromISO(room.countdownStartsOn);
                    var now = DateTime.local();
                
                    if (countdownTime < now) {
                        room.countdownStartsOnFormatted = "Active";
                    } else {
                        room.countdownStartsOnFormatted = `${countdownTime.toFormat(Config.DATE_FORMATS.FULL_NO_TIME)} ${countdownTime.toFormat(Config.DATE_FORMATS.TIME_NO_DAY)}`
                    }
                
                    if (activateTime < now) {
                        room.queueActivatesOnFormatted = "Active";
                    } else {
                        room.queueActivatesOnFormatted = `${activateTime.toFormat(Config.DATE_FORMATS.FULL_NO_TIME)} ${activateTime.toFormat(Config.DATE_FORMATS.TIME_NO_DAY)}`
                    }
                    
                    acc.push(room);
                }
                return acc;
            }, []);

            setDomainRooms(roomsByDomain);
        }

       if (rooms && currentDomain) {
        filterRoomsByDomain()
       }
    }, [rooms, currentDomain]);




    return (
        <>
        {firstRun ?
        <Grid container spacing={2}  direction="row" justifyContent="center" alignItems="center" style={{
            height:'300px'
        }}>
            <Grid item>
                <CircularProgress color="primary" />
            </Grid>
        </Grid>
            :
        <Grid container spacing={2} component="section" direction="row" justifyContent="center">
            <Grid item xs={12}>
                <div className={classes.chartContainer}>
                    <DashboardChart firstRun={firstRun} currentDomain={currentDomain} dashboardState={dashboardState} state={state} graphData={graphData} loading={chartLoading}></DashboardChart>
                </div>
            </Grid>
        </Grid>
        }

       

        {!firstRun && dashboardState.show_dashboard_settings?
        <Grid container spacing={2} component="section">
            <Grid item md={4} xs={12} className={classes.fixHeight}>
                <Paper className={classes.paper}>
                    <DashboardSettings
                        displayEmptyRoomImage={dashboardState.display_empty_room_image}
                        rooms={rooms}
                        updateSettings={updateSettings}
                        handleSettingsChange={handleSettingsChange}
                        settings={currentDomain}
                        classes={classes}
                        state={state}
                        hasRedSegments={hasRedSegments}
                    />
                </Paper>
            </Grid>            

            {dashboardState.info_panel.state === 'default' && urlData ?
            <>
                <Grid item md sm={6} xs={12} className={classes.fixHeight}>
                    <Paper className={classes.paper}>
                        <DashboardURLsTable urlData={urlData} errorGettingURLData={errorGettingURLData}/>
                    </Paper>
                </Grid>

                
                <Grid item md={2} sm={6} xs={12} className={classes.fixHeight} style={{minWidth: '250px'}}>
                    <Paper className={classes.paper}>
                        <DashboardResponseDonut setHasRedSegments={setHasRedSegments} api={api} currentDomainId={currentDomain.id} currentDomain={currentDomain} parent_classes={classes} />
                    </Paper>
                </Grid>
                
            </>
            :
            null
            }

            {dashboardState.info_panel.state !== 'default'
            ?
            <Grid item md={8} xs={12} className={classes.fixHeight}>
                    <Paper className={`implementation-panel ${classes.onboardpaper}`}>{displayInfoPanel()}</Paper>
            </Grid>
            :
            null
            }

            {
            domainRooms && domainRooms.length
                ?

                <Grid item xs={12} className={classes.fixHeight}>
                    <DashboardRoomsTable api={api} dispatch={dispatch} id={state.currentDomain.id} dispatchDash={dispatchDash} state={state} rooms={domainRooms} dashboardState={dashboardState}/>
                </Grid>
                :
                <Grid item xs={12} className={classes.fixHeight}>
                        <Paper className={classes.paper} style={{
                            display: "flex",
                            alignContent: "center",
                            justifyContent: "center",
                        }}>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <Typography component="p" variant="h5" style={{marginBottom: 0}}>No rooms on this domain</Typography>
                        </Grid>
                        <Grid item>
                            <Button
                                className={[classes.addButton, 'button_anim'].join(' ')}
                                onClick={() => {
                                    history.push(`/rooms/add/${state.currentDomain.id}`, {from:'dash', action: dashboardState.info_panel.state ? dashboardState.info_panel.state : null});
                                }}
                                variant="contained"
                                disableElevation
                                size="small"
                                color="secondary">Add Room</Button>

                        </Grid>

                    </Grid>
                        </Paper>
                </Grid>
                
            }



        </Grid>
        : null}
        </>
    )
}

export default memo(DashboardCharts);