import React, { useState } from "react";
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { makeStyles } from '@material-ui/core/styles';
import {
    Grid,
    FormControl,
    InputLabel,
    OutlinedInput,
    Card,
    CardContent,
    Typography,
    FormControlLabel,
    Switch
} from "@material-ui/core";
import PrimaryButton from '../PrimaryButton';


const useStyles = makeStyles((theme) => ({

    formControl: {
        width: '100%'
    },
    card: {
        width: '100%'
    }
}));

const AccountAddUser = ({ handleAddUser }) => {

    const classes = useStyles();
    let [role, setRole] = useState(false);

    const handleRoleSelectChange = () => {
        let newValue = !role;
        setRole(newValue);
    }

    const formik = useFormik({
        initialValues: {
            emailAddress: ''
        },
        validationSchema: Yup.object({
            emailAddress: Yup.string().required('Required'),
        }),
        onSubmit: async (values) => {
            values.role = role;
            values.emailAddress = values.emailAddress.trim()
            let result = await handleAddUser(values);
            if(result) {
                formik.resetForm();
            }
        },
    });

    return (

        <Card className={classes.card}>
            <CardContent>
                <Grid container spacing={2} alignItems="center" justifyContent="center">
                    <Grid item xs={12}>
                        <Typography variant="subtitle1">Add User</Typography>
                    </Grid>

                    <Grid item xs={12} sm={8}>

                        <FormControl fullWidth margin="dense" variant="outlined">
                            <InputLabel required htmlFor="title">Email Address</InputLabel>
                            <OutlinedInput
                                size="small"
                                id="emailAddress"
                                name="emailAddress"
                                labelWidth={86}
                                type="text"
                                placeholder="Email Address"
                                aria-label="emailAddress"
                                aria-describedby="title"
                                {...formik.getFieldProps('emailAddress')}
                            />

                            {formik.touched.emailAddress && formik.errors.emailAddress ? <Typography variant="subtitle2" color="error">{formik.errors.emailAddress}</Typography> : null}

                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4} align="center">
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={role}
                                    onChange={handleRoleSelectChange}
                                    name="roleSwitcher"
                                />
                            }
                            label="Administrator"
                        />

                    </Grid>

                    <Grid item xs={12} align="right">
                        <FormControl margin="dense">
                            <PrimaryButton onClick={formik.handleSubmit}>Invite</PrimaryButton>
                        </FormControl>
                    </Grid>
                </Grid>

            </CardContent>
        </Card>



    )
}

export default AccountAddUser;