import React, {useState} from 'react';
import SaveIcon from '../icons/SaveIcon';
import TrashIcon from '../icons/TrashIcon';
import { useFormik } from 'formik';
import { DateTime } from 'luxon';
import Config from '../config';
import * as Yup from 'yup';
import clsx from 'clsx';
import SavingBar from './SavingBar';
import './SessionTable.css';

import IPDataDialog from './IPDataDialog';

import {IPData} from './';

import { 
  IconButton,
  FormControl,
  OutlinedInput,
  Select,
  MenuItem,
  Tooltip,
  Grid,
  Link,
  TableCell,
  Typography,

} from '@material-ui/core';




const BlockPassEditRow = ({ processing, classes, row, id, domainID, updateIPAddress, deleteIPAddress, hasAnomalyDetection }) => {

    let [editAddress, setEditAddress] = useState(false);
    let [openDialog, setOpenDialog] = useState(false);
    let [drawerInfo, setDrawerInfo] = useState();

    const handleDialogClose = (event, reason) => {
      setDrawerInfo(null);
      setOpenDialog(false);
    }
    

    const formik = useFormik({
      initialValues: {
          id: row.id,
          note: row.note || '',
          list: row.list
      },
      enableReinitialize: true,
      validationSchema: Yup.object({
      }),
      onSubmit: async (values) => {
        let data = {
          ...values
        }
        updateIPAddress(data);
        setEditAddress(true);
        setEditAddress(false);
      },
    });


    const onDeleteIPAddressHandler = (id) => {
      deleteIPAddress(id)
    }


    

    return (
      <>
      <TableCell component="th" scope="row" className={classes.cell}>

        
        {row.address}
          
        </TableCell>

        <TableCell data-field="ip_data" className={classes.iconcell} colSpan="2">
          <Grid  onClick={(event) => {
          setDrawerInfo(row.ip_data);
          setOpenDialog(true);
        }}>
            <IPData ip_data={row.ip_data}/>
          </Grid>

          <IPDataDialog handleDialogClose={handleDialogClose} openDialog={openDialog} drawerInfo={drawerInfo}/>



         
          
        
        </TableCell>
        
      <TableCell align="center" className={classes.cell} style={{position:'relative'}} >
        <SavingBar saving={editAddress} />
        <FormControl fullWidth margin="dense" variant="outlined">
          <OutlinedInput
            id={`note_id_${id}`}
            name="note"
            {...formik.getFieldProps('note')}
            aria-label="note"
            className={classes.saveIcon}
            disabled={processing}
          />
        </FormControl>
      </TableCell>
      <TableCell align="center"
      className={clsx({
        [classes.cell]: true,
        [classes.reduce]: row.list !== 'live',
      })}
      >

          {(row.sessions && row.list === 'live') ?

            hasAnomalyDetection ? <Link href={`/rooms/sessions?domainID=${domainID}&roomID=all&search=${row.address}`}>{row.sessions}</Link> : row.sessions

            : row.sessions || null}
        </TableCell>
      
        <TableCell align="center" className={classes.cell} >
          {(row.started) ?
            <Grid container direction='column'>
              <Grid item>
                <Typography>
                  {DateTime.fromISO(row.started).toLocaleString(
                    {
                      ...DateTime.TIME_SIMPLE
                    }
                  )}
                </Typography>
              </Grid>

              {DateTime.fromISO(row.started).startOf('day') < DateTime.now().startOf('day') ? 
              
              <Grid>
                <Typography style={{fontSize: 12}}>
                  {DateTime.fromISO(row.started).toLocaleString(
                    {
                      ...DateTime.DATE_SHORT,
                    }
                  )}
                </Typography>
              </Grid>
              : null }
              
            </Grid>


        : null}

      </TableCell>
      <TableCell align="center" className={classes.cell} >

      <FormControl variant="outlined" size="small" hiddenLabel fullWidth margin='dense'>
        <Select
          labelId="IP Address Status"
          id={`IP-Address-Stataus-${id}`}
          value={formik.values.list}
          onChange={formik.handleChange('list')}
          size="small"
          labelWidth={0}
          disabled={processing}
        >
          {(row.list === 'live') ?
          <MenuItem value='live'>Change Status</MenuItem>
          : null}
          <MenuItem value='warn'>Warn</MenuItem>
          <MenuItem value='ignore'>Ignore</MenuItem>
          <MenuItem value='block'>Block</MenuItem>
          <MenuItem value='pass'>Bypass</MenuItem>
          <MenuItem value='prioritize'>Prioritize</MenuItem>
        </Select>
      </FormControl>

      </TableCell>


      <TableCell align="right" className={classes.cell} >
          <Tooltip title="Save Changes" aria-label="Save IP Address Changes">
            <IconButton size="small" type="submit" aria-label="Update IP Addresses" className={classes.margin} onClick={formik.handleSubmit}  disabled={processing}>
              <SaveIcon/>
              </IconButton>
              </Tooltip>
      
      <Tooltip title="Delete" aria-label="Delete IP Address Entry">

            <IconButton size="small"  aria-label="Update IP Addresses" className={classes.margin} onClick={() => {onDeleteIPAddressHandler(row.id)}}  disabled={processing}>
              <TrashIcon/>
              </IconButton>
              </Tooltip>
      </TableCell>
  
       
      </>
    )
  
  }

export default BlockPassEditRow;