import React from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import { CheckCircleIcon } from 'icons';
import { Typography } from '@material-ui/core';

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: '#206D9C',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#206D9C',
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

const useQontoStepIconStyles = makeStyles((theme) => ({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: theme.palette.primary.main,
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: theme.palette.primary.main,
    zIndex: 1,
    fontSize: 18,
  },
}));

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? <CheckCircleIcon color="secondary" className={classes.completed} /> : <div className={classes.circle} />}
    </div>
  );
}

const OnboardStepper = ({step}) => {
  


  return  (
    <Grid style={{
      textAlign: 'center'
    }}>
     
        <Typography variant="h6">Onboarding Helper</Typography>
        <Stepper component={Grid} alternativeLabel activeStep={step} connector={<QontoConnector />} style={{paddingTop: '12px'}}>
        {['Set up your room', 'Check out your room', 'Set the rate', 'Select Implementation'].map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

     

    </Grid>
  )
}

export default OnboardStepper;