import React from "react";
import {Link as RouterLink} from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';


import { Grid, Button, Typography, Card } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    paper: {
      padding: theme.spacing(1),
    },
    message: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    }
  }));

const UpgradeCTAPanel = (props) => {

    const classes = useStyles();
    let {upgradeMessage, displayUpgradeButton} = props;
    return (
       <Grid container direction="row"
       justifyContent="center"
       spacing={0}
       alignItems="center" component={Card} className={classes.paper}>

            <Grid item className={classes.message}>
                <Typography>
                {upgradeMessage}
                </Typography>
            </Grid>
            {displayUpgradeButton
            ?
            <Grid item>
                <Button
                color="secondary"
                variant="contained"
                component={RouterLink} to='/account/upgrade'>
                    Upgrade
                </Button>
            </Grid>
            :
            null
            }
        </Grid>
    );
}

export default UpgradeCTAPanel;