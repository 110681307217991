import React, { useState, useEffect, useContext } from "react";
import QRCode from 'qrcode.react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { makeStyles, styled } from '@material-ui/core/styles';
import {
    Grid,
    Button,
    Card,
    CardContent,
    FormControl,
    InputLabel,
    OutlinedInput,
    Typography,
    Chip,
    FormHelperText,
    InputAdornment,
    IconButton
} from "@material-ui/core";
import { EyeIcon, EyeOffIcon } from '../../icons';
import ErrorSnackBar from '../ErrorSnackBar';
import { Skeleton } from '@material-ui/lab';

import {AuthContext} from '../../providers/AuthProvider';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        width: '100%'
    },
    accountDetailsList: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    mfaTitle: {
        marginBottom: '20px'
    },
    mfaInfo: {
        marginBottom: '20px'
    },
    verificationPanel: {
        margin: '10px 0'
    }
}));

const CardStyled = styled(Card)({
    marginBottom: '20px'
});

const AccountSecurity = ({ state, api, dispatch, setShowLoader }) => {


    let [message, setMessage] = useState('');
    let [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
    let [alertBarStatus, setAlertBarStatus] = useState('success');

    const closeErrorSnackbar = () => {
        setOpenErrorSnackbar(false)
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>

                    {state.accountInformation
                        ?
                        <UserListItem state={state} api={api} setMessage={setMessage} accountInformation={state.accountInformation} setAlertBarStatus={setAlertBarStatus} setOpenErrorSnackbar={setOpenErrorSnackbar} setShowLoader={setShowLoader} dispatch={dispatch} />
                        :
                        <Skeleton><UserListItem /></Skeleton>
                    }

                </Grid>
            </Grid>


            <ErrorSnackBar message={message} open={openErrorSnackbar} status={alertBarStatus} closeErrorSnackbar={closeErrorSnackbar} />
        </>

    )
}

export default AccountSecurity;


const UserListItem = ({ accountInformation, setAlertBarStatus, setOpenErrorSnackbar, setMessage, state, api, setShowLoader, dispatch }) => {


    const chAuth = useContext(AuthContext);

    const formik = useFormik({
        initialValues: {
            currentPassword: '',
            newPassword: '',
        },
        validationSchema: Yup.object({
            currentPassword: Yup.string()
            .required('Your current password is required'),
            newPassword: Yup.string()
                .required('A new password is required')
                .min(8, "Password requires a minimum of 8 characters")
                .matches(/\d+/, { message: 'Password must contain a number' })
                .matches(/[a-z]+/, { message: 'Password must contain lowercase letters' })
                .matches(/[A-Z]+/, { message: 'Password must contain uppercase letters' })
                .matches(/[=+\-^$*.[\]{}()?"!@#%&/\\,><':;|_~`]/, { message: 'Password must contain a special character' })
                .test('test-whitespace', 'Spaces are not allowed',
                    function (value) {
                        var inValid = /\s/;
                        var k = inValid.test(value);
                        return !k;
                    })
                .test('test-invalid character', 'An invalid character has been found',
                    function (value) {
                        var inValid = /^[a-zA-Z0-9=+\-^$*.[\]{}()?"!@#%&\/\\,><':;|_~`]*$/;
                        var k = inValid.test(value);
                        return k;
                    })
        }),
        onSubmit: async (values) => {

            let { cognitoUser } = await chAuth.currentSession()
            cognitoUser.changePassword(values.currentPassword, values.newPassword, (err, result) => {
                if (err) {
                    let message = 'There has been a problem updating your password';
                    switch (err.code) {
                        case 'LimitExceededException':
                            message = 'You have tried too many times. Please try again later'
                            break;
                        case 'InvalidPasswordException':
                            message = err.message;
                            break;

                        default:
                            break;
                    }
                    setAlertBarStatus('error')
                    setMessage(message);
                    setOpenErrorSnackbar(true);
                } else {
                    setAlertBarStatus('success')
                    setMessage('Password changed');
                    setOpenErrorSnackbar(true);
                }

            });

        }
    })

    const classes = useStyles();

    let [mfa, setMfa] = useState(false);
    let [mfaCode, setMfaCode] = useState('');
    let [showPassword, setShowPassword] = useState(false);
    let [showCurrentPassword, setShowCurrentPassword] = useState(false);

    const [displayMFASetup, setDisplayMFASetup] = useState(false);
    const [QRCodeStr, setQRCodeStr] = useState('');

    let [emailAddress, setEmailAddress] = useState();

    useEffect(() => {

        if (accountInformation.get('user').attributes && !emailAddress) {
            setEmailAddress(accountInformation.get('user').attributes.email)
        }

    }, [accountInformation, emailAddress]);

   


    const enableMFA = async () => {

        try {

            const {SecretCode} = await chAuth.setUpTOTP();
            if (SecretCode) {
                setMfa(true);
                const str = `otpauth://totp/${emailAddress}?secret=${SecretCode}&issuer=CrowdHandler`;
                setQRCodeStr(str);
                setDisplayMFASetup(true);
                setShowLoader(false);
            }
        } catch (error) {
            
            let message = 'There has been a problem enabling MFA. Please refresh and try again';
            setAlertBarStatus('error');
            setMessage(message);
            setOpenErrorSnackbar(true);
            setShowLoader(false);
        }
    }

    const disableMFA = async () => {
        try {
            setShowLoader(true);
            let {result, error} = await chAuth.updateMFAPrefs({Enabled: false, PreferredMfa: false});

            if (result) {
                let message = 'MFA has been disabled';
                setMessage(message);
                setAlertBarStatus('success');
                setOpenErrorSnackbar(true);
                let new_user = accountInformation.get('user');
                new_user.mfa_prefs = 'NOMFA';
                dispatch({ type: 'UPDATE_ACCOUNT_INFORMATION', payload: { user: new_user } })
            }

            if (error) {
                let message = 'There has been a problem disabling MFA. Please refresh and try again';
                setMessage(message);
                setAlertBarStatus('error');
                setOpenErrorSnackbar(true);
            }

            setShowLoader(false);
           
        } catch (error) {
            let message = 'There has been a problem disabling MFA. Please refresh and try again';
            setAlertBarStatus('error');
            setMessage(message);
            setShowLoader(false);
        }

    }

    const handleVerifyToken = async (event) => {
        event.preventDefault();
        setShowLoader(true);
        try {
            let {result, error} = await chAuth.verifyMfa({UserCode : mfaCode});

            if (error) {
                let message = 'The has been a problem enabling MFA';
                setAlertBarStatus('error');

                setMessage(message);
                setOpenErrorSnackbar(true);
                setShowLoader(false);
            }

            if (result) {
                let message = 'MFA has been enabled';
                setAlertBarStatus('success');

                setMessage(message);
                setOpenErrorSnackbar(true);
                let new_user = accountInformation.get('user');
                new_user.mfa_prefs = 'SOFTWARE_TOKEN_MFA';
                dispatch({ type: 'UPDATE_ACCOUNT_INFORMATION', payload: { user: new_user } })
                
                setShowLoader(false);
                setDisplayMFASetup(false);
            }
            setShowLoader(false);
        } catch (error) {
            let message = 'The has been a problem enabling MFA';
            setAlertBarStatus('error');

            setMessage(message);
            setOpenErrorSnackbar(true);
            setShowLoader(false);
            console.log(error);
            setDisplayMFASetup(false);
        }

        
    }

    const handleClickShowPassword = (type) => {
        if (type === 'current') {
            setShowCurrentPassword(!showCurrentPassword);

        } else {

            setShowPassword(!showPassword);
        }
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <>

            {
                accountInformation
                    ?
                    <Grid container spacing={2}>
                        <Grid item>
                            <Typography paragraph variant="h6">{accountInformation.get('user').attributes.email}</Typography>
                        </Grid>
                    </Grid>
                    :
                    null
            }


            <CardStyled>
                <CardContent>

                    <Typography component="h3" variant="h6">Change password</Typography>
                    <Grid container direction='column'>
                        <Grid item xs={12} md={6}>
                            <FormControl margin="normal" variant="outlined" fullWidth>
                                <InputLabel htmlFor="currentPassword">Current Password</InputLabel>
                                <OutlinedInput
                                    type={showCurrentPassword ? 'text' : 'password'}
                                    id='currentPassword'
                                    {...formik.getFieldProps('currentPassword')}
                                    labelWidth={110}
                                    placeholder="Current Password"
                                    aria-label="Current Password"
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => { handleClickShowPassword('current') }}
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                                {showPassword ? <EyeOffIcon /> : <EyeIcon />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                ></OutlinedInput>
                            </FormControl>
                            {formik.touched.currentPassword && formik.errors.currentPassword ? <Typography variant="subtitle2" color="error">{formik.errors.currentPassword}</Typography> : null}

                        </Grid>

                        <Grid item xs={12} md={6}>
                            <FormControl margin="normal" variant="outlined" fullWidth>
                                <InputLabel htmlFor="newPassword">New Password</InputLabel>
                                <OutlinedInput
                                    type={showPassword ? 'text' : 'password'}
                                    id='newPassword'
                                    {...formik.getFieldProps('newPassword')}
                                    labelWidth={90}
                                    placeholder="New Password"
                                    aria-label="New Password"
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => { handleClickShowPassword('new') }}
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                                {showPassword ? <EyeOffIcon /> : <EyeIcon />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                ></OutlinedInput>
                            </FormControl>

                            <FormHelperText style={{ marginBottom: '12px' }}>Passwords require at least 8 characters and must include letters, numbers and at least one special character from this set<br /><strong>{`= + - ^ $ * . [ ] { } ( ) ? " ! @ # % & / \\ , > < ' : ; | _ ~ \` `}</strong></FormHelperText>
                            {formik.touched.newPassword && formik.errors.newPassword ? <Typography variant="subtitle2" color="error">{formik.errors.newPassword}</Typography> : null}
                        </Grid>
                    </Grid>




                    <Button variant="contained"
                        size="small"
                        disableElevation
                        color="secondary" onClick={() => { formik.handleSubmit() }}>Submit New Password</Button>
                </CardContent>
            </CardStyled>

            <CardStyled>
                <CardContent>
                    {state.accountInformation.get('user').mfa_prefs === 'SOFTWARE_TOKEN_MFA' ? <Chip size="small" color="primary" label="Enabled" style={{ float: 'right' }} /> : null}

                    <Typography className={classes.mfaTitle} component="h3" variant="h6">Two-factor authentication</Typography>
                    <Typography className={classes.mfaInfo}>Two-factor authentication adds an additional layer of security to your account by requiring more than just a password to sign in</Typography>

                    {state.accountInformation.get('user').mfa_prefs === 'SOFTWARE_TOKEN_MFA' ?

                        <Button
                            variant="contained"
                            size="small"
                            disableElevation
                            color="secondary"
                            onClick={() => {
                                disableMFA()
                            }}
                        >Disable MFA</Button>


                        : null}

                    {state.accountInformation.get('user').mfa_prefs !== 'SOFTWARE_TOKEN_MFA' && !displayMFASetup ?

                        <Button
                            variant="contained"
                            size="small"
                            disableElevation
                            color="secondary"
                            onClick={() => {
                                enableMFA()
                            }}
                        >Configure MFA</Button>

                        : null}

                    {displayMFASetup && mfa
                        ?
                        <Grid container autoComplete="off" direction="column" component="form" onSubmit={(event) => handleVerifyToken(event)} className={classes.verificationPanel}>
                            <Grid container spacing={2}>
                                <Grid item>
                                    <QRCode value={QRCodeStr} />
                                </Grid>
                                <Grid item md={2}>
                                    <Typography>Scan this QR code in your authenticator app and enter the code to enable.</Typography>
                                </Grid>
                            </Grid>

                            <Grid item>
                                <FormControl margin="normal" variant="outlined">
                                    <InputLabel htmlFor="password">MFA Code</InputLabel>
                                    <OutlinedInput
                                        type="text"
                                        id='mfa_code'
                                        name='mfa_code'
                                        value={mfaCode}
                                        onChange={(event) => setMfaCode(event.target.value)}
                                        labelWidth={60}
                                        placeholder="MFA Code"
                                        aria-label="mfa_code"
                                        aria-describedby="mfa_code"
                                        endAdornment={
                                            <Button variant="contained"
                                                disabled={!mfaCode}
                                                onClick={handleVerifyToken}
                                                size="small"
                                                disableElevation
                                                type="submit"
                                                color="secondary">
                                                Enable
                                            </Button>
                                        }
                                    ></OutlinedInput>
                                </FormControl>
                            </Grid>

                            <Grid item>
                                <Button variant="contained"
                                    onClick={() => {
                                        setDisplayMFASetup(false);
                                        setMfaCode('');
                                    }}
                                    size="small"
                                    disableElevation
                                    color="primary">
                                    Cancel
                                </Button>
                            </Grid>
                        </Grid>

                        :
                        null
                    }

                </CardContent>
            </CardStyled>





        </>
    )
}
