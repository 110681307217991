import "core-js/es";
import React from 'react';
import ReactDOM from 'react-dom';
import './scss/index.scss';
import 'airbnb-browser-shims';
import 'promise-polyfill/src/polyfill';
// import Symbol from "core-js-pure/features/symbol";
import theme from './theme';
import {ThemeProvider as ThemeProviderV4, StylesProvider, createGenerateClassName} from '@material-ui/core/styles';
import App from './App';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import semverGreaterThan from './lib/semverGreaterThan';
import axios from 'axios';
import jss from 'jss'
import Config from './config';
import {AuthProvider} from './providers/AuthProvider';

import { datadogRum } from '@datadog/browser-rum';


jss.setup({id: {minify: true}});

const generateClassName = createGenerateClassName({
  productionPrefix: 'ch-',
});


// Amplify.configure(Config.AMPLIFY);


datadogRum.init({
    applicationId: '29bbe97d-8717-4841-88fc-0018ef59caeb',
    clientToken: 'pubbbf8b001a804695e32b34b8d9dbe8205',
    site: 'datadoghq.com',
    service: 'crowdhandler-admin',
    env: process.env.REACT_APP_ENV,
    version: process.env.REACT_APP_VERSION,
    sampleRate: 100,
    trackInteractions: true
});

// call initial cachebusting
axios({
  method: 'get',
  url: `/meta.json?id=${process.env.REACT_APP_VERSION}`
})
.then( (response) => {       

  const latestVersion = response.data.version;
  const currentVersion = process.env.REACT_APP_VERSION;
  console.info(`${latestVersion}.${currentVersion}`);
  const shouldForceRefresh = semverGreaterThan( latestVersion, currentVersion);
    if (shouldForceRefresh) {
      window.location.reload();
    }
});



// require('date-time-format-timezone'); // polyfill is ready

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);


ReactDOM.render(
  <React.StrictMode>
      <Elements stripe={stripePromise}>
      <StylesProvider generateClassName={generateClassName}>
      <ThemeProviderV4 theme={theme}>
        <AuthProvider>
          <App/>
        </AuthProvider>
          </ThemeProviderV4>
      </StylesProvider>
        </Elements>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();


