import React, {useEffect} from "react";
import { makeStyles, withStyles } from '@material-ui/core/styles';


import {
    Typography,
    Grid,
    FormControlLabel,
    FormControl,
    Checkbox,
    InputLabel,
    OutlinedInput,
    FormHelperText,
    Divider,
    InputAdornment,
    Select,
    MenuItem,
    Tooltip,
    Link,

} from "@material-ui/core";
import clsx from 'clsx';


const useStyles = makeStyles((theme) => ({
    optionsBlock: {
        margin: `${theme.spacing(2)}px 0 `
    },
    inputDisabled: {
        '& :hover': {
            cursor: 'not-allowed'
        }
    },
    warningPanel: {
        marginTop: 20
    }
  
}));

const ValidationTextField = withStyles({
    root: {
      '& input:invalid + fieldset': {
        borderColor: 'red',
        borderWidth: 2,
      },
    },
  })(OutlinedInput);

const DomainOptions = ({ state, settings, formik, allowAutotune }) => {

    useEffect(() => {
        if (formik.values.oneInOneOut === false && formik.values.trackStock === true) {
            formik.setFieldValue("trackStock", false)
        }
    }, [formik]);

    const allowStockControl = state.plan.allowCustomTemplates;

    const classes = useStyles();

    const getAutotuneWarning = () => {
        if (!allowAutotune || state.plan.allowAutotune === 0) {

            if (!allowAutotune && settings.deployment !== 'shopifyjs') {
                return (

                    <Tooltip title={<Typography>You need a room that covers <strong>all</strong> of this domain or a room set to <strong>regex NOT</strong> to enable Autotune</Typography>}>
                        <Typography>Autotune</Typography>
                    </Tooltip>
                        
                    )
                } 
                
                if(state.plan.allowAutotune === 0) {
                    return (
                        <Tooltip title={<Typography>Upgrade to use Autotune</Typography>}>
                            <Typography>Autotune</Typography>
                        </Tooltip>
    
                )
            }
        }

        if(settings.deployment === 'shopifyjs') {
            return (
                <Tooltip title={<Typography>Disabled for Shopify integration</Typography>}>
                    <Typography>Autotune</Typography>
                </Tooltip>

            )
        }

        return <Typography>Autotune</Typography>;
    }

    return (
        <Grid container >
            <Grid item xs={12}>

                        <input type="hidden" name="id" value={settings.id}></input>
                        <Typography>{`Editing settings for ${settings.name}`}</Typography>
                        

                        <Grid container className={classes.optionsBlock} spacing={2}>
                                <Grid item xs={!allowAutotune || state.plan.allowAutotune === 0 ? 11 : 12} md={3}>
                                    <FormControl component="fieldset" className={classes.formControl}>
                                    
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    disabled={state.plan.allowAutotune === 0 || settings.deployment === 'shopifyjs' || !allowAutotune}
                                                    checked={formik.values.autotune}
                                                    name="autotune"
                                                    {...formik.getFieldProps('autotune')}
                                                />
                                            }
                                            label={getAutotuneWarning()}
                                        />


                                        
                                    </FormControl>

                                    
                                       
                                   
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <FormControl fullWidth margin="dense" variant="outlined">
                                        <InputLabel htmlFor="title">Rate per minute</InputLabel>
                                        <ValidationTextField
                                            id="rate"
                                            error={formik.values.rate < 1}
                                            name="rate"
                                            labelWidth={100}
                                            type="number"
                                            placeholder="rate"
                                            aria-label="rate"
                                            aria-describedby="title"
                                            inputProps={{
                                                min: 0,
                                                max: parseInt(state.plan.maxDomainRate, 10)
                                            }}
                                            disabled={formik.values.autotune}
                                            {...formik.getFieldProps('rate')}
                                        />
                                        {formik.values.rate < 1
                                        ?
                                        <FormHelperText color="error">No users will exit the waiting room</FormHelperText>
                                        :
                                        null}
                                    <FormHelperText>
                                        <Link target="_blank" href="https://www.crowdhandler.com/docs/80001016979-what-is-the-rate-exactly-">Number of users who exit the waiting room each minute</Link>
                                        </FormHelperText>
                                        
                                        {formik.touched.rate && formik.errors.rate ? <Typography variant="subtitle2" color="error">{formik.errors.rate}</Typography> : null}

                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={3}>

                                    <FormControl fullWidth margin="dense" variant="outlined">
                                        <InputLabel htmlFor="title">Maximum slow requests %</InputLabel>
                                        <OutlinedInput
                                            id="maxSlowRequestPercent"
                                            labelWidth={163}
                                            type="number"
                                            name="maxSlowRequestPercent"
                                            placeholder="maxSlowRequestPercent"
                                            aria-label="maxSlowRequestPercent"
                                            aria-describedby="title"
                                            inputProps={{
                                                min: 0,
                                                max: 10
                                            }}
                                            disabled={!formik.values.autotune}
                                        
                                            {...formik.getFieldProps('maxSlowRequestPercent')}
                                        />
                                        <FormHelperText>The percentage of requests to allow to exceed the Slow Request Threshold.</FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={3}>

                                    <FormControl fullWidth margin="dense" variant="outlined">
                                        <InputLabel htmlFor="title">Slow request threshold (ms)</InputLabel>
                                        <OutlinedInput
                                            id="slowRequestThreshold"
                                            name="slowRequestThreshold"
                                            labelWidth={172}
                                            type="number"
                                            placeholder="slowRequestThreshold"
                                            aria-label="slowRequestThreshold"
                                            aria-describedby="title"
                                            fullWidth
                                            inputProps={{
                                                min: 0,
                                                max: 30000,
                                                step: 100
                                            }}
                                            disabled={!formik.values.autotune}
                                            {...formik.getFieldProps('slowRequestThreshold')}
                                        />
                                        <FormHelperText>The number of milliseconds for a request to take before it is considered 'slow'.</FormHelperText>
                                    </FormControl>
                                </Grid>
                            <Divider />

                        </Grid>
                        <Grid container className={classes.optionsBlock}  spacing={1}>
                            <Grid item xs={12} md={3}>
                                <FormControlLabel
                                    control={
                                    <Checkbox
                                        {...formik.getFieldProps('oneInOneOut')}
                                        checked={formik.values.oneInOneOut}
                                        name="oneInOneOut" />
                                    }
                                    label="One in one out"
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <FormControl fullWidth margin="dense" variant="outlined">
                                    <InputLabel disabled={!formik.values.maxTransacting} htmlFor="title">Maximum active sessions</InputLabel>
                                    <OutlinedInput
                                        className={
                                            clsx(
                                                {
                                                    [classes.inputDisabled]: !formik.values.oneInOneOut
                                                }
                                            )
                                        }
                                        id="maxTransacting"
                                        name="maxTransacting"
                                        labelWidth={160}
                                        type="number"
                                        aria-label="maxTransacting url"
                                        aria-describedby="title"
                                        disabled={!formik.values.oneInOneOut || formik.values.trackStock}
                                        fullWidth
                                        inputProps={{
                                            min: formik.values.rate,
                                            max: parseInt(state.plan.maxWaitingRoomCapacity, 10),
                                            
                                        }}
                                        {...formik.getFieldProps('maxTransacting')}
                                    />
                                    <FormHelperText>Regardless of rate settings, active users will not be allowed to exceed this number on your domain</FormHelperText>
                                </FormControl>
                                {formik.touched.maxTransacting && formik.errors.maxTransacting ? <Typography variant="subtitle2" color="error">{formik.errors.maxTransacting}</Typography> : null}

                            </Grid>

                            <Grid item xs={12} md={6}>
                            <FormControlLabel
                                    control={<Checkbox {...formik.getFieldProps('trackStock')}
                                    disabled={!formik.values.oneInOneOut || !allowStockControl}
                                    checked={formik.values.trackStock} name="trackStock" />}
                                    label="Track stock availability"
                                />
                            </Grid>
                        </Grid>



                        <Divider />


                        <Grid container className={classes.optionsBlock}  spacing={1}>
                            <Grid item xs={12} md={3}>

                                <FormControlLabel
                                    control={<Checkbox  {...formik.getFieldProps('destroySessionsOnCheckout')} checked={formik.values.destroySessionsOnCheckout} name="destroySessionsOnCheckout" />}
                                    label="Destroy session on checkout"
                                />
                            </Grid>
                            <Grid item xs={12} md={9}>
                                <FormControl fullWidth margin="dense" variant="outlined">
                                    <InputLabel disabled={!formik.values.checkout} htmlFor="checkout">Confirmation URL</InputLabel>
                                    <OutlinedInput
                                        className={
                                            clsx(
                                                {
                                                    [classes.inputDisabled]: !formik.values.destroySessionsOnCheckout
                                                }
                                            )
                                        }
                                        id="checkout"
                                        name="checkout"
                                        labelWidth={116}
                                        type="text"
                                        fullWidth
                                        placeholder="Confirmation URL"
                                        aria-label="Confirmation URL"
                                        aria-describedby="title"
                                        disabled={!formik.values.destroySessionsOnCheckout}
                                        {...formik.getFieldProps('checkout')}
                                        startAdornment={<InputAdornment position="start" className={classes.slugAdornment}>{`${(settings) ? settings.name : ''}`}</InputAdornment>}

                                    />

                                    <FormHelperText>Supports <a href="https://www.crowdhandler.com/docs/80000599765-using-rexeg-for-url-pattern-matching" rel="noopener noreferrer" target="_blank">regular expressions</a></FormHelperText>

                                    {(formik.errors.checkout) ? <Typography variant="subtitle2" color="error">{formik.errors.checkout}</Typography> : null}

                                </FormControl>

                            </Grid>
                    </Grid>

                    <Divider />

                    <Grid container className={classes.optionsBlock}  spacing={1}>

                        <Grid item  xs={12} md={3}>

                            <Typography>Session expiry</Typography>
                        </Grid>

                        <Grid item xs={12} md={3}>

                            <FormControl fullWidth margin="dense" variant="outlined">
                                <InputLabel htmlFor="title">Timeout (minutes)</InputLabel>
                                <OutlinedInput
                                    id="timeout"
                                    name="timeout"
                                    labelWidth={116}
                                    type="number"
                                    placeholder="timeout"
                                    aria-label="timeout"
                                    aria-describedby="title"
                                    inputProps={{
                                        min: 0,
                                        max: 30
                                    }}
                                    {...formik.getFieldProps('timeout')}
                                />
                                <FormHelperText>After losing contact with the user for this many minutes their session will be lost, and they will be forced to reconnect.</FormHelperText>
                                {(formik.touched.timeout && formik.errors.timeout) ? <Typography variant="subtitle2" color="error">{formik.errors.timeout}</Typography> : null}

                            </FormControl>

                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControlLabel
                                control={<Checkbox {...formik.getFieldProps('sessionsExpireWhilstWaiting')} checked={formik.values.sessionsExpireWhilstWaiting} name="sessionsExpireWhilstWaiting" />}
                                label="Allow sessions to expire while waiting"
                            />
                        </Grid>

                    </Grid>

                    <Divider />

                    {state.hasFingerprinting
                    ?
                    
                    <Grid container className={classes.optionsBlock}  spacing={1}>
                        <Grid item  xs={12} md={3}>
                            <Typography>Device Fingerprinting</Typography>
                        </Grid>
                        <Grid item xs={12} md={3}>
                        <FormControl fullWidth margin="dense" variant="outlined">
                            <Select margin="dense" variant="outlined" fullWidth {...formik.getFieldProps('fingerprint')} id="fingerprint"
                                    name="fingerprint">
                                <MenuItem value="none">None</MenuItem>
                                <MenuItem value="basic">Basic</MenuItem>
                                <MenuItem value="advanced">Advanced</MenuItem>
                            </Select>
                        </FormControl>
                        {formik.values.fingerprint === 'none'
                        ?
                        <FormHelperText>Will not check device fingerprint against token</FormHelperText>                    
                        : null}
                        {formik.values.fingerprint === 'basic'
                        ?
                        <FormHelperText>Will prevent fewer token sharers, but will result in fewer false positives</FormHelperText>
                        : null}
                        {formik.values.fingerprint === 'advanced'
                        ?
                        <FormHelperText>Will prevent the most token sharers, but will result in more false positives</FormHelperText>
                        : null}
                        </Grid>
                       
                    </Grid>

                    :
                    null}

            </Grid>
        </Grid>
    )

}


export default DomainOptions;