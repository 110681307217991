import React from 'react';
import { ReactComponent as HelpCircle } from './feather/help-circle.svg';

import { SvgIcon } from '@material-ui/core';


export default function HelpCircleIcon(props) {
    return (
        <SvgIcon {...props} component={HelpCircle} className="feather"/>
    );
  }