import React, { useEffect, useState, useReducer, useRef } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ZoomInIcon from '../icons/ZoomInIcon';
import IPDataDialog from './IPDataDialog';
import './SessionTable.css';
import ErrorSnackBar from './ErrorSnackBar';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import NoSessionDataFoundOverlay from './NoSessionDataFoundOverlay';
import find from 'lodash/find'
import {
  IconButton,
  Tooltip,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  LinearProgress,
  Paper,
  Grid
} from '@material-ui/core';

import { IPData, SessionAgent } from '.';

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, headCells, displayJoined, displayTouched } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const classes = useStyles();

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => {
          let element;
          if ((headCell.id === "joined" && displayJoined) || (headCell.id === "touched" && displayTouched)) {

            element = <EnhancedTableHeadCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
              style={(headCell.width) ? {
                width: headCell.width,
                minWidth: headCell.width,
                maxWidth: headCell.width,
              } : null}
            >
              
              {headCell.sortable
                ?
                <EnhancedTableHeadEl>
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </span>
                  ) : null}
                </TableSortLabel>
                  </EnhancedTableHeadEl>
                :
                  <EnhancedTableHeadEl>
                  {headCell.label}
                  </EnhancedTableHeadEl>

              }
              
            </EnhancedTableHeadCell>

          } else if (headCell.id !== "joined" &&  headCell.id !== "touched") {


            element = <EnhancedTableHeadCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
              style={(headCell.width) ? {
                width: headCell.width,
                minWidth: headCell.width,
                maxWidth: headCell.width,
              } : null}
            >
              
              {headCell.sortable
                ?
                <EnhancedTableHeadEl>
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </span>
                  ) : null}
                </TableSortLabel>
                  </EnhancedTableHeadEl>
                :
                  <EnhancedTableHeadEl>
                  {headCell.label}
                  </EnhancedTableHeadEl>

              }
              
            </EnhancedTableHeadCell>

          }

          return element;



        })}
      </TableRow>
    </TableHead>
  );
}

const EnhancedTableHeadCell = withStyles({
  root: {
    height: 56,
    padding: '0 10px',
  },
})(TableCell);
const EnhancedTableHeadEl = withStyles({
  root: {
    borderRight: '2px solid #cacaca'
  },
})(Typography);


function CustomLoadingOverlay() {
  return (
    <Grid style={{
      background: 'rgba(255,255,255,0.9)',
      display: 'block',
      zIndex: 1,
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    }}>

      <div style={{ position: 'absolute', top: 0, width: '100%' }}>
        <LinearProgress />
      </div>
    </Grid>
  );
}


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const useStyles = makeStyles((theme) => ({
  table: {
    // maxWidth: 440,
  },
  betaBadge: {
    '& .MuiBadge-badge': {
      fontSize: '10px',
      backgroundColor: 'transparent',
      color: theme.palette.secondary.main,
      top: '15px',
      right: '-10px',
    }
  },

  paper: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(2),
    width: '100%',
    zIndex: 1,
    position: 'relative',
  },
  cardContent: {
    width: 'auto'
  },
  cell: {
    wordWrap: 'break-word',
    padding: '8px'
  },
  iconLink: {
    lineHeight: 0
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.primary.main,
    backgroundColor: 'rgba(255,255,255,0.3)',
  },
  sessionInfo: {
    margin: `${theme.spacing(2)}px 0`,
  },
  meter: {
    boxSizing: 'content-box',
    height: '20px', // Can be anything 
    position: 'relative',
    background: '#555',
    borderRadius: '0px',
    padding: '2px',
    width: '70%',
    boxShadow: 'inset 0 -1px 1px rgba(255, 255, 255, 0.3)',

    '& > span': {
      display: 'block',
      height: '100%',
      borderTopRightRadius: '0px',
      borderBottomRightRadius: '0px',
      borderTopLeftRadius: '0px',
      borderBottomLeftRadius: '0px',
      boxShadow: 'inset 0 2px 9px rgba(255, 255, 255, 0.3), inset 0 -2px 6px rgba(0, 0, 0, 0.4)',
      position: 'relative',
      overflow: 'hidden',
    },
  },
  meterValue: {
    color: '#fff',
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    textAlign: 'center',
    height: '100%',
    lineHeight: 1.7,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}
));

export default function SessionsTableBasic({
  api,
  sessions,
  showRequests,
  loading,
  hasAnomalyDetection,
  hasFirewall,
  setOpenAgentDialog,
  setAgentData,
  defaultOrder,
  matches
}) {

  const displayTouched = useMediaQuery(`@media (min-width:1240px)`);
  const displayJoined = useMediaQuery(`@media (min-width:1240px)`);
  const usePosLabel = useMediaQuery(`@media (min-width:1480px)`);


  let headCells = [];

  headCells.push({
    id: 'token',
    numeric: false,
    disablePadding: false,
    label: 'Token',
    width: 160
  },
    {
      id: 'joined',
      numeric: false,
      disablePadding: false,
      label: 'Joined',
      width: (usePosLabel) ? 100 : 80,
      sortable: true
    },
    {
      id: 'touched',
      numeric: false,
      disablePadding: false,
      label: 'Touched',
      width: (usePosLabel) ? 100 : 80,
      sortable: true
    },
    {
      id: 'ip',
      numeric: false,
      disablePadding: false,
      label: 'IP',
      sortable: true,
      width: (usePosLabel) ? 35 : null    })

  if (hasFirewall) {
    headCells.push({
      id: 'ip_data',
      numeric: false,
      disablePadding: false,
      label: 'IP Info',
      width: (usePosLabel) ? 100 : 80,
    })
  }
  if (hasAnomalyDetection) {
    headCells.push({
      id: 'score',
      numeric: false,
      disablePadding: false,
      label: 'Risk',
      sortable: true,
      width: 80
    });
  }


  headCells.push({
    id: 'agent_data',
    numeric: false,
    disablePadding: false,
    label: 'Agent',
    width: 96
  })


  headCells.push({
    id: 'position',
    numeric: true,
    disablePadding: false,
    label: (!usePosLabel) ? 'Pos' : 'Position',
    sortable: true,
    width: (!displayJoined || !displayTouched || !usePosLabel) ? 50 : 70
  });
  headCells.push({
    id: 'request',
    numeric: false,
    disablePadding: false,
    label: '',
    width: 50
  });



  const reducer = (userSessions, action) => {
    switch (action.type) {
      case 'ADD_SESSIONS':
        return {
          sessions: action.payload,
        };
      case 'UPDATE_SESSION':

        let sessions = userSessions.sessions.map((session) => {
          if (session.id === action.payload.id) {
            return action.payload;
          } else {
            return session;
          }
        })

        let newSessions = {
          ...userSessions,
          sessions: sessions
        };

        return newSessions;
      case 'UPDATE_SESSIONS':

        let updated_sessions = [...userSessions.sessions];
        for (let i = 0; i < updated_sessions.length; i++) {
          let row = updated_sessions[i];
          let match = find(action.payload, { id: row.ip });
          if (match) {
            row = match;
          }

        }
        return {
          ...userSessions,
          sessions: updated_sessions,
        };
      default:
        return userSessions;
    }
  };


  const [userSessions, dispatch] = useReducer(reducer, { sessions: [] });


  useEffect(() => {
    dispatch({ type: 'ADD_SESSIONS', payload: sessions })
  }, [sessions]);

  const classes = useStyles();
  const [rows, setRows] = React.useState([]);
  const [drawerInfo, setDrawerInfo] = useState()
  const [openIPDataDialog, setOpenIPDataDialog] = useState(false)



  const handleDialogClose = (event, reason) => {
    setDrawerInfo(null);
    setOpenIPDataDialog(false);
  }


  useEffect(() => {

    let rows = sessions.map((session, index) => {
      let data = {}
      data.id = index;
      data.token = session.token;
      data.joined = session.joinedTime;
      data.touched = session.touchedTime;
      data.ip = session.ip;
      if (hasFirewall) {
        data.ip_data = session.ip_data;
      }
      if (hasAnomalyDetection) {
        data.risk = session.score;
      }
      data.agent = session.agent;
      data.position = session.position;
      data.requests = { token: session.token, ip: session.ip };

      return data;
    })

    setRows((prevState) => {
      return [...rows];
    }
    );



  }, [hasAnomalyDetection, hasFirewall, sessions]);


  let [message, setMessage] = useState('');
  let [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  let [alertBarStatus, setAlertBarStatus] = useState('');
  const closeErrorSnackbar = () => {
    setOpenErrorSnackbar(false);
  }

  return (
    <div style={{
      height: (rows < 1) ? '300px' : 'auto',
      position: 'relative'
    }}>



      {loading ?
        <CustomLoadingOverlay />
        : null
      }
      <PureSessionsTableBasicList
        defaultOrder={defaultOrder}
        sessions={userSessions.sessions}
        headCells={headCells}
        hasAnomalyDetection={hasAnomalyDetection}
        hasFirewall={hasFirewall}
        classes={classes}
        loading={loading}
        setMessage={setMessage}
        setAlertBarStatus={setAlertBarStatus}
        setOpenErrorSnackbar={setOpenErrorSnackbar}
        setDrawerInfo={setDrawerInfo}
        setOpenIPDataDialog={setOpenIPDataDialog}
        setAgentData={setAgentData}
        setOpenAgentDialog={setOpenAgentDialog}
        showRequests={showRequests}
        api={api}
        dispatch={dispatch}
        displayTouched={displayTouched}
        displayJoined={displayJoined}
        usePosLabel={usePosLabel}

      />

      <IPDataDialog openDialog={openIPDataDialog} handleDialogClose={handleDialogClose} drawerInfo={drawerInfo} />
      <ErrorSnackBar message={message} open={openErrorSnackbar} status={alertBarStatus} closeErrorSnackbar={closeErrorSnackbar} />


    </div>

  );
}


const SessionsTableBasicList = ({
  defaultOrder,
  sessions,
  headCells,
  hasAnomalyDetection,
  hasFirewall,
  classes,
  loading,
  setMessage,
  setAlertBarStatus,
  setOpenErrorSnackbar,
  setDrawerInfo,
  setOpenIPDataDialog,
  setAgentData,
  setOpenAgentDialog,
  showRequests,
  api,
  dispatch,
  displayTouched,
  displayJoined,
  usePosLabel
}) => {

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState(defaultOrder);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {

    setOrderBy(()=>{
      return defaultOrder.value;
    });

    if (defaultOrder.value === 'score') {
      setOrder((prev) => {
        return 'desc'
      })
    } else {
      setOrder((prev) => {
        return 'asc'
      })
    }
  }, [defaultOrder]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - sessions.length) : 0;

  const resultsLength = useRef(0)
  const processed = useRef(false)

  useEffect(() => {
    if (sessions.length < page * rowsPerPage) {
      setPage(() => {
        return 0;
      })
    }
  }, [page, rowsPerPage, sessions, setPage]);


  // eslint-disable-next-line no-extend-native
  Array.prototype.sliceResults = function (start, end) {
    let result = this.slice(start, end);
    resultsLength.current = result.length
    return result;
  }

  const renderRisk = (value) => {

    try {

      let colors = {
        'green': '#00bf0c',
        'amber': '#ffa900',
        'red': '#FF0000',
        'grey': '#808080',
      }


      let color = 'green';
      if (value > 60) {
        color = 'red';
      } else if (value > 29) {
        color = 'amber';
      } else {
        color = 'green';
      }

      let width = (value < 101) ? `${value}%` : `100%`;

      let backgroundMeter = {
        background: value === 0 ? '#adadad' : '#555',
      }

      // width = value===0 ? '2px' : width;

      if (!hasAnomalyDetection) {
        color = 'grey'
      }

      return (

        <div className={classes.meter} style={backgroundMeter}>
          <span style={{ width: width, backgroundColor: colors[color] }}></span>
          <div className={classes.meterValue}>{`${value}%`}</div>
        </div>

      )

    } catch (error) {
      console.log(error);
      return '-';
    }

  }

  const getTableData = (sessions) => {
    processed.current = false;
    let sorted = stableSort(sessions, getComparator(order, orderBy));
    let sliced = sorted.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    let rows_to_process = []

    for (let i = 0; i < sliced.length; i++) {
      const element = sliced[i];
      if (!element.seen) {
        rows_to_process.push(element)
      }

    }


    const getTheData = async () => {

      let results = await Promise.all(
        rows_to_process.map(async (row) => {
          const getData = async () => {
            let res = [await api.getUserAgents(row.agent)];
            if (hasFirewall) {
              res.push(
                await api.getIPData(row.ip),
              )
            }
            const p = await Promise.all(res);

            if (hasFirewall) {
              row.agent_data = p[0]
              row.ip_data = p[1]
            } else {
              row.agent_data = p[0]
            }
            row.seen = true;
            return row;
          }
          return getData();
        })
      );

      processed.current = true;
      dispatch({ type: 'UPDATE_SESSIONS', payload: results });

    }

    if (rows_to_process.length) {
      getTheData()
    }

    return sliced;
  }

  const SessionTableCell = withStyles({
    root: {
      padding: '0 10px'
    },
  })(TableCell);




  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={sessions.length}
            headCells={headCells}
            displayJoined={displayJoined}
            displayTouched={displayTouched}
          />
          <TableBody>

            {!loading && sessions.length === 0 ?
              <TableRow>
                <TableCell align="center" colSpan={9}>
                  <NoSessionDataFoundOverlay />

                </TableCell>

              </TableRow>
              : null
            }


            {getTableData(sessions)
              .map((row, index) => {
                const labelId = `sessions-reporting-${index}`;
                return (
                  <TableRow
                    hover
                    key={row.id}
                    style={{
                      fontSize: (usePosLabel) ? '0.875rem !important' : '0.375rem !important'
                    }}
                  >

                    <SessionTableCell
                      data-field="token"
                      component="th"
                      id={labelId}
                      scope="row"
                      
                    >

                      <CopyToClipboard text={row.token}
                        onCopy={() => {
                          setMessage('Copied token to clipboard')
                          setAlertBarStatus('success');
                          setOpenErrorSnackbar(true);
                        }}
                      >
                        <Typography className="copy">{row.token}</Typography>
                      </CopyToClipboard>

                    </SessionTableCell>
                    {displayJoined ?
                      <SessionTableCell>{row.joinedTime}</SessionTableCell>
                      : null}
                      {displayTouched ?
                    <SessionTableCell>{row.touchedTime}</SessionTableCell>
                    : null}
                    <SessionTableCell data-field="ip">

                      <CopyToClipboard text={row.ip}
                        onCopy={() => {
                          setMessage('Copied IP to clipboard');
                          setAlertBarStatus('success');
                          setOpenErrorSnackbar(true);
                        }}
                      >
                        <Typography className="copy">{row.ip}</Typography>
                      </CopyToClipboard>
                    </SessionTableCell>

                    {hasFirewall ?
                      <SessionTableCell
                        data-field="ip_data"
                        onClick={() => {

                          if (row.ip_data) {
                            setDrawerInfo(row.ip_data);
                            setOpenIPDataDialog(true);
                          }
                        }}


                      >{(row.ip_data) ? <IPData ip_data={row.ip_data} /> : null}</SessionTableCell>
                      : null}
                    {hasAnomalyDetection ?
                      <SessionTableCell >{renderRisk(row.score)}</SessionTableCell>
                      : null}
                    <SessionTableCell
                      data-field="agent"
                      onClick={() => {
                        if (row.agent_data) {
                          setAgentData(row.agent_data);
                          setOpenAgentDialog(true);
                        }
                      }}
                    >{(row.agent_data) ? <SessionAgent value={row.agent_data} /> : null}</SessionTableCell>
                    <SessionTableCell align='right'>{(row.position === 0) ? `Active` : row.position}</SessionTableCell>
                    <SessionTableCell >
                      <Tooltip title="View Details">
                        <span>
                          <IconButton
                            onClick={(event) => {
                              showRequests(
                                event,
                                row
                              )
                            }}
                            className={classes.iconLink}
                          >
                            <ZoomInIcon />
                          </IconButton>
                        </span>
                      </Tooltip>
                    </SessionTableCell>
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: (53) * emptyRows,
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 50, 100]}
        component="div"
        count={sessions.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  )
}

const PureSessionsTableBasicList = React.memo(SessionsTableBasicList);