import React, { useState, useRef, useEffect } from "react";
import { datadogRum } from '@datadog/browser-rum';
import { confirmSignUp, resendSignUpCode, signOut } from 'aws-amplify/auth';

import {
    Container,
    Grid,
    Button,
    FormControl,
    InputLabel,
    OutlinedInput,
    Typography,
    Divider
} from "@material-ui/core";
import { Link as RouterLink } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

import * as Yup from 'yup';
import { useFormik } from 'formik';
// import PropTypes from 'prop-types';

import {
    useHistory
} from "react-router-dom";


import ErrorSnackBar from '../../components/ErrorSnackBar';

const useStyles = makeStyles((theme) => ({
    verificationPanel: {
        marginBottom: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    resendCodePanel: {
        marginTop: theme.spacing(2),
    },
    verificationSuccessPanel: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(4),
        '& h2' : {
            marginBottom: theme.spacing(2),
        },
        '& h6' : {
            marginBottom: theme.spacing(2),
        },
    },
}))


export default function Verification({ state, ...rest }) {
    const history = useHistory();
    const classes = useStyles();

    let [verificationSuccess, setVerificationSuccess] = useState(false)

    let { dispatch } = rest;
    // Store a ref so we can clear up when the component is unmounted


    const formik = useFormik({
        initialValues: {
            username: '',
            code: ''
        },
        isValid: false,
        validationSchema: Yup.object({
            username: Yup.string().email('A valid email is required').required('An email is required'),
            code: Yup.string().required('A verification code is required')
        }),
        onSubmit: async (values) => {
            confirmSignUp(formik.values.username, formik.values.code)
            .then((data) => {
                localStorage.setItem('username', formik.values.username);
                setVerificationSuccess(true)
                })
                .catch((error) => {
                    // Send a custom error with context
                    let err = new Error('Verification Error');

                    datadogRum.addError(err, {
                        ...error
                    });
                    if(error.code === "LimitExceededException") {
                        setMessage('The attempt limit has been exceeded, please try after some time');
                        setOpenErrorSnackbar(true);
                    }
                    if(error.code === "ExpiredCodeException") {
                        setMessage('Invalid code provided, please request a code again.');
                        setOpenErrorSnackbar(true);
                    }
                    if(error.code === "CodeMismatchException") {
                        setMessage('Invalid code provided, please request a code again.');
                        setOpenErrorSnackbar(true);
                    }
                    if(error.code === "NotAuthorizedException" && error.message === 'User cannot be confirmed. Current status is CONFIRMED') {
                        setMessage('Invalid code provided, please request a code again.');
                        setOpenErrorSnackbar(true);
                        history.push('/login');
                    }
                });
        },
    });


    let [message, setMessage] = useState('');

    let [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);

    const isMountedRef = useRef(null);

    useEffect(() => {
        isMountedRef.current = true;

        const reset = async () => {
            await signOut();
        }
        reset().catch((error) => {
            // console.log(error);
        });

        // dispatch({ type: 'SET_IS_LOADING', payload: false });
        dispatch({ type: 'RESET_APP' });
        return () => {
            isMountedRef.current = false;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const closeErrorSnackbar = () => {
        setOpenErrorSnackbar(false)
    }

    const handleResendConfirmationCode = async () => {
        let valid = await formik.validateForm();

        if (!valid.username) {
            try {
                let codeResult = await resendSignUpCode(formik.values.username);
                if (codeResult) {
                    setMessage('A verification code has been sent to your email address');
                    setOpenErrorSnackbar(true);
                }
            } catch (err) {
                let handleResendConfirmationCodeError = new Error('HandleResendConfirmationCode Error');

                datadogRum.addError(handleResendConfirmationCodeError, {
                    ...err
                });
            }
        } else {

        }


    }

    return (
        <Container maxWidth='sm'>
            <Grid container component="form" spacing={1} className={classes.verificationPanel}>

            {!verificationSuccess
            ?
                <>
                <Grid item xs={12}>
                    <Typography>Enter your email address and the verification code you received to verify your account.</Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth margin="normal" variant="outlined">
                        <InputLabel htmlFor="username">Email Address</InputLabel>
                        <OutlinedInput
                            type="email"
                            id='username'
                            {...formik.getFieldProps('username')}
                            labelWidth={88}
                            placeholder="Email Address"
                            aria-label="Email Address"
                            error={(formik.touched.username && formik.errors.username) ? true : false}
                        ></OutlinedInput>
                        {formik.touched.username && formik.errors.username ? (
                            <Typography variant="subtitle2" color="error">{formik.errors.username}</Typography>
                        ) : null}
                    </FormControl>
                    <FormControl fullWidth margin="normal" variant="outlined">
                        <InputLabel htmlFor="username">Code</InputLabel>
                        <OutlinedInput
                            type="text"
                            id='code'
                            {...formik.getFieldProps('code')}
                            labelWidth={30}
                            placeholder="Code"
                            aria-label="Code"
                            error={(formik.touched.code && formik.errors.code) ? true : false}
                        ></OutlinedInput>
                        {formik.touched.code && formik.errors.code ? (
                            <Typography variant="subtitle2" color="error">{formik.errors.code}</Typography>
                        ) : null}
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Button variant="contained"
                        size="small"
                        disableElevation
                        color="secondary" onClick={formik.handleSubmit}>Verify Account</Button>
                </Grid>

                </>
                :
                null
                }
            </Grid>

            {!verificationSuccess
            ?
            <Divider />
            :
            null
            }

            {!verificationSuccess
            ?
            <Grid container spacing={1} direction="row" className={classes.resendCodePanel}>
                <Grid item xs={12}>
                    <Typography>Need a new verification code?</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        size="small"
                        disableElevation
                        color="primary" onClick={handleResendConfirmationCode}>Resend Code</Button>
                </Grid>
            </Grid>
            :
            null
            }

            {verificationSuccess
            ?
            <Grid container spacing={2} direction="column" className={classes.verificationSuccessPanel} justifyContent="center">
                <Grid item xs={12}>
                    <Typography align="center" variant="h2">Welcome aboard!</Typography>
                    <Typography align="center" variant="h6">Your account has been verified.</Typography>
                </Grid>
                <Grid item xs={12}>
                <Typography align="center">
                    
                    <Button color="secondary" disableElevation variant="contained" component={RouterLink} to={`/login`}>Login</Button>
                    </Typography>
                </Grid>
            </Grid>
            :
            null
            }

            <ErrorSnackBar message={message} open={openErrorSnackbar} closeErrorSnackbar={closeErrorSnackbar} />
        </Container>
    )
}