import React, {useState} from 'react';

import {
  Grid,
  FormControl,
  OutlinedInput,
  FormHelperText,
  InputLabel,
  Typography,
} from '@material-ui/core';


const RoomMessage = ({formik}) => {

    const [messageLength, setMessageLength] = useState(0);


    return (
      <Grid className='ch-room-form-group'>
        <FormControl fullWidth margin="normal" variant="outlined">
          <InputLabel htmlFor="title">Message</InputLabel>
          <OutlinedInput
            id="message"
            {...formik.getFieldProps('message')}
            labelWidth={54}
            placeholder="Message"
            aria-label="Message"
            multiline
            rows={4}
            inputProps={{ maxLength: 512 }}
            style={{backgroundColor: '#fff'}}
            onKeyUp={ function(event) {
              setMessageLength(event.currentTarget.value.length);
            } }
          />
          <FormHelperText>Messages will update to all waiting users within one minute. {512 - messageLength} characters remaining</FormHelperText>
          {formik.touched.message && formik.errors.message ? <Typography variant="subtitle2" color="error">{formik.errors.message}</Typography> : null}
        </FormControl>
      </Grid>
    )
}

export default RoomMessage;