import React from 'react';
import { ReactComponent as AlertCircle } from './feather/alert-circle.svg';

import { SvgIcon } from '@material-ui/core';


export default function AlertCircleIcon(props) {
    return (
        <SvgIcon {...props} component={AlertCircle} className="feather"/>
    );
  }