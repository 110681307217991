import React, { useState, useEffect } from "react";
import { useFormik } from 'formik';
import * as Yup from 'yup';

import axios from 'axios';

import { makeStyles } from '@material-ui/core/styles';
import {
    Grid,
    FormControl,
    InputLabel,
    OutlinedInput,
    Typography,
    Card,
    CardContent,
    Link,
    Button
} from "@material-ui/core";
import ErrorSnackBar from './ErrorSnackBar';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        width: '100%'
    },
    accountDetailsList: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    accountDetailsListItem: {

    }
}));

const RecaptureBlock = ({ state, api, dispatch }) => {

    const classes = useStyles();

    let [captchaKeyPublic,setCcaptchaKeyPublic] = useState(state.accountInformation.get('account').captchaKeyPublic);
    let [message, setMessage] = useState('');
    let [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
    let [alertBarStatus, setAlertBarStatus] = useState('success');
    const closeErrorSnackbar = () => {
        setOpenErrorSnackbar(false)
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            sitekey: '',
            secretKey: ''
        },
        onSubmit: async (keys) => {

            let payload = {
                captchaKeyPublic:keys.sitekey,
                captchaKeyPrivate:keys.secretKey
            }

            let result = await api.updateAccount(payload);

            if (result) {
                setAlertBarStatus('success')
                setMessage('Your keys have been saved')
                setOpenErrorSnackbar(true)
                dispatch({type:'UPDATE_ACCOUNT_INFORMATION', payload: 
                    {
                        account: result,
                    }
                })

                dispatch({type:'UPDATE_RECAPTCHA_IS_SET', payload: (result.captchaKeyPrivate && result.captchaKeyPublic) ? true : false})
            } else {
                setAlertBarStatus('error')
                setMessage('There was an error saving your keys')
                setOpenErrorSnackbar(true)
            }

            checkValidKeys(result)

        },
    });

    const checkValidKeys = (result) => {
        let isSet = (result.captchaKeyPrivate && result.captchaKeyPublic) ? true : false;
        dispatch({type:'UPDATE_RECAPTCHA_IS_SET', payload: isSet});
    }


    useEffect(() => {
       const applyValidationState = (recaptchaIsSet) => {
           if (!recaptchaIsSet) {
            let roomsToReset = state.rooms.reduce((acc, room)=>{
                if(room.captcha !== 'not-required') {
                    room.captcha = 'not-required';
                    acc.push(room);
                }
                return acc;
            },[]);

            roomsToReset.forEach(async (room) => {
                let result = await api.addRoom({
                    ...room,
                  }, 'put');
                  if (result) {
                    dispatch({'type':'UPDATE_ROOMS', payload: result})
                  }
            });
            
           }
       }

       applyValidationState(state.recaptchaIsSet)
    }, [api, dispatch, state.recaptchaIsSet, state.rooms]);

    const clearCaptcha = async () => {

        let payload = {
            captchaKeyPublic: '',
            captchaKeyPrivate: ''
        }

        let result = await api.updateAccount(payload);
        if (result) {
            dispatch({type:'UPDATE_ACCOUNT_INFORMATION', payload: 
                {
                    account: result,
                }
            })
            
            dispatch({type:'UPDATE_RECAPTCHA_IS_SET', payload: (result.captchaKeyPrivate && result.captchaKeyPublic) ? true : false})
            formik.resetForm();
        }

        let roomsToReset = state.rooms.reduce((acc, room)=>{
            if(room.captcha !== 'not-required') {
                room.captcha = 'not-required';
                acc.push(room);
            }
            return acc;
        },[]);

        roomsToReset.forEach(async (room) => {
            let result = await api.addRoom({
                ...room,
              }, 'put');
              if (result) {
                dispatch({'type':'UPDATE_ROOMS', payload: result})
              }
        });



        // dispatch({type:'UPDATE_ROOMS', payload: roomsToReset})


    }

    useEffect(() => {
        if (state.accountInformation.get('account').captchaKeyPublic) {
            formik.setFieldValue('sitekey', state.accountInformation.get('account').captchaKeyPublic);
        }
        if(state.accountInformation.get('account').captchaKeyPrivate) {
            formik.setFieldValue('secretKey', state.accountInformation.get('account').captchaKeyPrivate);
        }
        
    }, []);




    return (
        <>
         <Card className={classes.card}>
            <CardContent>

                <div id="recaptcha-widget" />
            <Grid container spacing={2}>

                <Grid item xs={12}>
                    <Typography>
                        We integrate with <Link target="_blank" href="https://www.google.com/recaptcha/about/">Google ReCAPTCHA V2</Link> to protect you against spam and other types of automated abuse.
                    </Typography>
                    <Typography>
                        More information can be found on our support portal: <Link target="_blank" href="https://support.crowdhandler.com/a/solutions/articles/80000596959">Integrating Google ReCAPTCHA</Link>
                    </Typography>
                </Grid>
                <Grid item xs={12} md={5}>
                    <FormControl fullWidth margin="dense" variant="outlined">
                        <InputLabel>Site Key</InputLabel>
                        <OutlinedInput
                            size="small"
                            id="sitekey"
                            name="sitekey"
                            labelWidth={86}
                            type="text"
                            placeholder="Site Key"
                            aria-label="Site Key"
                            aria-describedby="title"
                            {...formik.getFieldProps('sitekey')}
                        />

                        {formik.touched.sitekey && formik.errors.sitekey ? <Typography variant="subtitle2" color="error">{formik.errors.sitekey}</Typography> : null}
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={5}>
                    <FormControl fullWidth margin="dense" variant="outlined">
                        <InputLabel>Secret Key</InputLabel>
                        <OutlinedInput
                            size="small"
                            id="secretKey"
                            name="secretKey"
                            labelWidth={86}
                            type="text"
                            placeholder="Secret Key"
                            aria-label="Secret Key"
                            aria-describedby="title"
                            {...formik.getFieldProps('secretKey')}
                        />

                        {formik.touched.secretKey && formik.errors.secretKey ? <Typography variant="subtitle2" color="error">{formik.errors.secretKey}</Typography> : null}
                    </FormControl>
                </Grid>

                <Grid item xs={12} md={2} >
                    
                        <FormControl margin="dense" fullWidth>
                            <Button color="secondary" variant="contained" disableElevation  onClick={formik.handleSubmit}>Save</Button>
                        </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="caption">
                        Removing one or more keys will disable ReCAPTCHA from your waiting room.
                    </Typography>
                </Grid>


            </Grid>

            </CardContent>
            </Card>
            <ErrorSnackBar message={message} open={openErrorSnackbar} status={alertBarStatus} closeErrorSnackbar={closeErrorSnackbar} />
        </>

    )
}

export default RecaptureBlock;

