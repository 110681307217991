import React, { useState, useEffect } from "react";
import {
    Grid,
    DialogTitle,
    DialogContent,
    DialogActions,
    Dialog,
    FormLabel,
    FormControl,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Button,
    Tooltip,
    IconButton
} from '@material-ui/core';
import { DateTimePicker } from '@material-ui/pickers/'

import { DownloadIcon } from '../../icons';
import { makeStyles } from '@material-ui/core/styles';
import { DateTime } from 'luxon';
import {generateChartDownloadData} from '../../lib/generateChartDownloadData';
import Config from '../../config';


const dateFormat = Config.DATE_FORMATS.FULL_WITH_YEAR;

const useStyles = makeStyles((theme) => ({

    downloadButton: {
        backgroundColor: '#ffffff',
        '&:hover': {
            backgroundColor: '#ffffff',
        },
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        zIndex: 1000
    }
}));


const DashboardDownloadWidget = ({ state, api, rooms }) => {
    const classes = useStyles();

    const [currentRooms, setCurrentRooms] = useState([])
    const [openDownloadDialog, setOpenDownloadDialog] = useState(false);
    const [downloadRooms, setDownloadRooms] = useState([]);

    const [downloadTime, setDownloadTime] = React.useState({
        from: {
            date: DateTime.local().toUTC().minus({ minutes: 60 }).startOf('minute'),
            value: DateTime.local().toUTC().minus({ minutes: 60 }).startOf('minute').toFormat('yyyy/MM/dd HH:mm')
        },
        to: {
            date: DateTime.local().toUTC().startOf('minute'),
            value: DateTime.local().toUTC().startOf('minute').toFormat('yyyy/MM/dd HH:mm')
        },
    });

    const downloadDataHandler = () => {
        setOpenDownloadDialog(true);
    }

    const handleDownloadOk = () => {
        generateChartDownloadData(state.rooms, downloadRooms, downloadTime, api);
        setOpenDownloadDialog(false)
    }

    useEffect(() => {
        const getCurrentRooms = () => {
            let currentRooms = rooms.filter((room) => {
                return room.domainID === state.currentDomain.id;
            });
            setCurrentRooms(currentRooms);
    
            let roomsChecked = currentRooms.reduce((acc, room) => {
                acc[room.id] = true;
                return acc;
            }, {});
            setDownloadRooms(roomsChecked);
        }

        if (rooms) {
            getCurrentRooms();
        } 
      
    }, [rooms, state.currentDomain.id]);


    const handleDownloadCancel = () => {
        setOpenDownloadDialog(false)
    }

    const onDateChange = (date, value, field_name) => {
        setDownloadTime({
          ...downloadTime,
          [field_name] : {
            date: date,
            value: value
          }
        })
      
      };
      
    const handleChange = (event) => {
        setDownloadRooms(
            {
                ...downloadRooms,
                [event.target.name]: event.target.checked
            }
        )
    };

    return (
        <>
            <Tooltip title="Download Data">
                <IconButton className={classes.downloadButton} size="small" onClick={() => { downloadDataHandler() }}>
                    <DownloadIcon />
                </IconButton>
            </Tooltip>

            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                maxWidth="xs"
                aria-labelledby="confirmation-dialog-title"
                open={openDownloadDialog}

            >
                <DialogTitle id="confirmation-dialog-title">Download Data</DialogTitle>
                <DialogContent dividers>
                    <Grid>


                        <FormControl component="fieldset" className={classes.formControl}>
                            <FormLabel component="legend">Select Data To Download</FormLabel>
                            <FormGroup>
                                {
                                    currentRooms.map((room) => {
                                        return <FormControlLabel
                                            key={room.id}
                                            control={<Checkbox checked={downloadRooms[room.id]} onChange={handleChange} name={room.id.toString()} />}
                                            label={room.title}
                                        />
                                    })
                                }
                            </FormGroup>
                        </FormControl>

                    </Grid>

                    <Grid>
                        <FormControl fullWidth margin="normal" variant="outlined" >
                        <DateTimePicker
                            ampm={true}
                            inputVariant="outlined"
                            onChange={(date, value) => {
                                onDateChange(date, value, 'from')
                            }}
                            inputValue={downloadTime.from.value}
                            value={downloadTime.from.date}
                            label="From"
                            format={dateFormat}
                            />
                        {/* <KeyboardDateTimePicker
                                ampm={true}
                                variant="inline"
                                onChange={(date, value) => {
                                    onDateChange(date, value, 'from')
                                }}
                                inputValue={downloadTime.from.value}
                                value={downloadTime.from.date}
                                label="From"
                                format="yyyy/MM/dd HH:mm"

                            /> */}
                        </FormControl>
                    </Grid>
                    <Grid>
                        <FormControl fullWidth margin="normal" variant="outlined" >
                        <DateTimePicker
                            ampm={true}
                            inputVariant="outlined"
                            onChange={(date, value) => {
                                onDateChange(date, value, 'to')
                            }}
                            inputValue={downloadTime.to.value}
                            value={downloadTime.to.date}
                            label="To"
                            format={dateFormat}
                            />

                            {/* <KeyboardDateTimePicker
                                ampm={true}
                                variant="inline"
                                onChange={(date, value) => {
                                    onDateChange(date, value, 'to')
                                }}
                                inputValue={downloadTime.to.value}
                                value={downloadTime.to.date}
                                label="To"
                                format="yyyy/MM/dd HH:mm"

                            /> */}
                        </FormControl>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleDownloadCancel} color="primary">
                        Cancel
        </Button>
                    <Button onClick={handleDownloadOk} color="secondary">
                        Download
        </Button>
                </DialogActions>
            </Dialog>
        </>
    )

}

export default DashboardDownloadWidget;