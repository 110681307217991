import React, { Children } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import {
    Tooltip,
    Link
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    warning : {
       color:'red'
    },
    help : {
        '&:hover' : {
            cursor: 'help !important'
        }
    },
}));




const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
      boxShadow: '0 2px 14px 0 rgba(0, 0, 0, 0.2)',
    //   '&:hover' : {
    //     cursor: 'help !important'
    //   }
      
    },
  }))(Tooltip);

  


  const HelpToolTip = ({title, placement, children}) => {

    const classes = useStyles();

    if (!title) {
        return children;
    }
    let defaults = {}

    defaults.placement = (placement) ? placement : 'bottom';

    
    return (
        <HtmlTooltip className={classes.help} title={title} placement={defaults.placement} interactive>
            {children}
        </HtmlTooltip>

    )
  }
  

export default HelpToolTip;