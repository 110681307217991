import React from 'react';
import { ReactComponent as Layers } from './feather/layers.svg';

import { SvgIcon } from '@material-ui/core';


export default function LayersIcon(props) {
    return (
        <SvgIcon {...props} component={Layers} className="feather"/>
    );
  }