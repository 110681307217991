import React, {useState, useRef} from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import EnhancedTableHead from '../EnhancedTableHead';
import clsx from 'clsx';

import {
  getComparator,
  stableSort
} from '../../lib/tableComparators'

import { Table, TableBody, TableCell, TableContainer, TableRow, Typography, Tooltip } from '@material-ui/core';

const headCells = [
  { id: 'url', numeric: false, disablePadding: true, label: 'URL' },
  { id: 'avg', numeric: true, disablePadding: false, label: 'Average' },
];

const useStyles = makeStyles((theme) => ({
  table: {
    // maxWidth: 440,
  },
  cell: {
    wordWrap: 'break-word',
    padding: '8px'
  },
  truncate : {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  urlcell: {
    [theme.breakpoints.down(770)]: {
      maxWidth: '2800px',
    },
    [theme.breakpoints.up(640)]: {
      maxWidth: '430px',
      // backgroundColor: 'orange',
    },
    [theme.breakpoints.up(720)]: {
      // backgroundColor: 'olivedrab',
      maxWidth: '600px',
    },
    [theme.breakpoints.up(960)]: {
      // backgroundColor: 'lime',
      maxWidth: '150px',
    },
    [theme.breakpoints.up(1024)]: {
      // backgroundColor: 'orangered',
      maxWidth: '132px',
    },
    [theme.breakpoints.up(1084)]: {
      // backgroundColor: 'darkred',
      maxWidth: '190px',
    },
    // [theme.breakpoints.up(1188)]: {
    //   backgroundColor: 'lime',
    //   maxWidth: '318px',
    // },
    [theme.breakpoints.up(1204)]: {
      // backgroundColor: 'lime',
      maxWidth: '255px',
    },
    [theme.breakpoints.up(1429)]: {
      // backgroundColor: 'brown',
      maxWidth: '340px',
    },
    [theme.breakpoints.up(1444)]: {
      // backgroundColor: 'brown',
      maxWidth: '355px',
    },
    [theme.breakpoints.up(1614)]: {
      // backgroundColor: 'red',
      maxWidth: '462px',
    },
    [theme.breakpoints.up(2000)]: {
      // backgroundColor: 'yellow',
      maxWidth: '700px',
    },
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

const StyledTableRow = withStyles((theme) => ({
  root: {
    '& th:nth-of-type(even)': {
      backgroundColor: theme.palette.grey[200],
    },
    '& td:nth-of-type(odd)': {
      backgroundColor: theme.palette.grey[200],
    }
  }
}))(TableRow);

const DashboardURLsTable = ( {urlData, errorGettingURLData} ) => {

  const classes = useStyles();
  const [orderBy, setOrderBy] = useState('avg');
  const [order, setOrder] = useState('desc');
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <>
      
 
    {!errorGettingURLData
    ?
    <TableContainer component='div'>
      <Table className={classes.table} size="small" aria-label="URLS in the last 60 seconds">

      <EnhancedTableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          headCells={headCells}
        />
        <TableBody >
          {stableSort(urlData, getComparator(order, orderBy))
          .map((row, index) => (

            <StyledTableRow key={index}>
              <TableCell style={{width:'100%'}} component="th" scope="row"  className={clsx({
                    [classes.cell]: true,
                    [classes.urlcell]: true,
                  })}
                >
                  <Tooltip title={`${row.url}`} aria-label={`${row.url}`}>
                    <Typography className={classes.truncate}>
                    {row.url}
                    </Typography>
                  </Tooltip>
                  </TableCell>
              <TableCell align="center" className={classes.cell} >{(row.avg) ? `${row.avg.toLocaleString()}ms` || null : null}</TableCell>
              
            </StyledTableRow>

          ))}
        </TableBody>
      </Table>
    </TableContainer>
    :null
    }
    

   
    </>
  );
}

export default DashboardURLsTable;



