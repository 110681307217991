import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { SlashIcon, ClockIcon  } from '../../icons';

const useStyles = makeStyles((theme) => ({
    roomDisabled : {
        color: theme.palette.error.main,
      },
      countDownActive : {
        color: theme.palette.warning.dark,
      },
      queueActive : {
        color: theme.palette.success.main,
      },
}));


const RoomsStatus = ({roomActiveState}) => {
    const classes = useStyles();

    if(!roomActiveState) {
        return null;
    }

    const Status = () => {
       if (!roomActiveState.protectingURLs) {
        return <SlashIcon className={classes.roomDisabled} />
       } else if (!roomActiveState.countdownIsActive &&  !roomActiveState.queueActivatesIsActive) {
        return <SlashIcon className={classes.roomDisabled} />
       } else if (roomActiveState.countdownIsActive &&  !roomActiveState.queueActivatesIsActive) {
        return <ClockIcon className={classes.countDownActive}/>
       } else {
        return <ClockIcon className={classes.queueActive}/>
       }
    } 
    return  Status();
}

export default RoomsStatus;