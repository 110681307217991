import React, { useState } from 'react';
import PlusCircleIcon from '../icons/PlusCircleIcon';
import NewTemplateUploadForm from './NewTemplateUploadForm';
import { useTheme } from '@material-ui/core/styles';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import {
    Grid,
    OutlinedInput,
    FormHelperText,
    FormControl,
    InputLabel,
    Button,
    FormControlLabel,
    Radio,
    RadioGroup,
    Typography,
} from '@material-ui/core';

export default function CustomTemplateNewTemplate({ classes, customer, api, templates, setMessage, setOpenErrorSnackbar, maxFileSize, ...rest }) {

    const { dispatch } = rest;
    let theme = useTheme();
    const [useLink, setUseLink] = useState(false);

    Yup.addMethod(Yup.string, 'checkTemplateExists', function (args) {
        const { message } = args;

        return this.test('check-template-exists', args, function (value) {
            const { createError } = this;
            const { templates } = args;
            if (!value) {
                return createError({ message: "The template is required" });
            }
            let fileNames = templates.reduce((acc, template) => {
                acc.push(template.name)
                return acc;
            }, []);
            let doesExist = fileNames.indexOf(value);
            if (doesExist > -1) {
                return createError({ message });
            }
            return true;
        });
    });

    const regex = /^https:\/\//; // https://regexr.com/39nr7

    const formik = useFormik({
        initialValues: {
            customTemplateName: '',
            customTemplateLink: ''
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            customTemplateName: Yup.string().required('Required').checkTemplateExists({
                templates: templates,
                message: 'This template name already exists. Template names must be unique'
            }),
            customTemplateLink: Yup.string().required('Required').matches(regex, { message: 'Templates must be served over https' })

        }),
        onSubmit: (values) => {
            let data = {
                url: values.customTemplateLink,
                name: values.customTemplateName
            }

            const addTemplate = async (data) => {
                let response = await api.addTemplate(data, 'post');
                if (response.error) {
                    setMessage(response.error.toUpperCase())
                    setOpenErrorSnackbar(true);
                } else {
                    formik.resetForm();
                    dispatch({ type: 'UPDATE_TEMPLATES', payload: response });
                }
                
            }

            addTemplate(data);
        },
    });

    


    return (
            <Grid container spacing={2} alignItems="flex-start" className={classes.addRowForm}>

                <Grid item xs={12} >
                    <TemplateTypeSelector setUseLink={setUseLink} />
                </Grid>

                {useLink
                    ?
                    <>

                        <Grid item xs={4} >
                            <FormControl fullWidth margin="dense" variant="outlined">
                                <InputLabel required htmlFor="customTemplateName">Template Name</InputLabel>
                                <OutlinedInput
                                    id="customTemplateName"
                                    labelWidth={106}
                                    {...formik.getFieldProps('customTemplateName')}
                                    placeholder="Name"
                                    aria-label="customTemplateName"
                                    aria-describedby="customTemplateName"
                                />
                                <FormHelperText>A unique name to identify your template</FormHelperText>
                                {formik.touched.customTemplateName && formik.errors.customTemplateName ? <Typography variant="subtitle2" color="error">{formik.errors.customTemplateName}</Typography> : null}

                            </FormControl>
                        </Grid>
                        <Grid item xs={8} >
                            <FormControl fullWidth margin="dense" variant="outlined">
                                <InputLabel required htmlFor="customTemplateLink">Template URL</InputLabel>
                                <OutlinedInput
                                    id="customTemplateLink"
                                    name="customTemplateLink"
                                    labelWidth={98}
                                    {...formik.getFieldProps('customTemplateLink')}
                                    placeholder="Name"
                                    aria-label="customTemplateLink"
                                    aria-describedby="customTemplateLink"
                                />
                                <FormHelperText>Your template needs to be a self contained HTML file available at a secure (https://) URL. The URL can be for a dynamic page, e.g. from your Content Management System. We will update our local copy once per minute</FormHelperText>
                                {formik.touched.customTemplateLink && formik.errors.customTemplateLink ? <Typography variant="subtitle2" color="error">{formik.errors.customTemplateLink}</Typography> : null}

                            </FormControl>

                        </Grid>
                        <Grid item xs={12} align="right">
                    
                            <Button component="span" disableElevation size="small" variant="contained" color="secondary"
                                disabled={(formik.errors.customTemplateLink || formik.errors.customTemplateName) ? true : false}
                                onClick={formik.handleSubmit}
                                style={{ color: (!formik.values.customTemplateLink || !formik.values.customTemplateName) ? theme.palette.grey[200] : theme.palette.common.white }}
                                endIcon={<PlusCircleIcon />}
                            >
                                Add
                        </Button>
                        </Grid>


                    </>
                    :
                    <>
                        <NewTemplateUploadForm templates={templates} dispatch={dispatch} customer={customer} api={api} maxFileSize={maxFileSize}/>
                    </>
                }
            </Grid>
    )

}



const TemplateTypeSelector = (props) => {

    let { setUseLink } = props;
    const [selectedIndex, setSelectedIndex] = useState('file');

    const handleChange = (event, index) => {
        setSelectedIndex(event.target.value);

        if (event.target.value === 'link') {
            setUseLink(true)
        } else {
            setUseLink(false)
        }
    };

    return (
        <Grid item xs={12}>
            <FormControl variant="outlined">
                <RadioGroup row aria-label="position" name="position" >
                    <FormControlLabel value="file" control={<Radio
                        onChange={handleChange}
                        checked={selectedIndex === 'file'}
                        />} label="Upload Custom Template from HTML file" />
                    <FormControlLabel value="link" control={<Radio
                        onChange={handleChange}
                        checked={selectedIndex === 'link'}
                    />} label="Add Custom Template from URL" />
                </RadioGroup>
            </FormControl>
        </Grid>
    )
}