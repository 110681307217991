import React, {useState, useEffect} from "react"
import {
    Grid,
    Typography,
    FormControl,
    Select,
    MenuItem,
    Checkbox,
    ListItemText,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      maxWidth: 300,
    },
    chip: {
      margin: 2,
    },
    noLabel: {
      marginTop: theme.spacing(3),
    },
  }));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AutoblockAttackerControl = ({ formik, values, setProcessing, hasAnomalyDetection, autoblockProcessing }) => {

  let classes= useStyles();
  let [selectValues, setSelectValues] = useState(values);

  useEffect(() => {
    setSelectValues(values)
  }, [values]);
 

    /*
        "is_tor": false,
        "is_icloud_relay": false,
        "is_proxy": false,
        "is_datacenter": false,
        "is_anonymous": false,
        "is_known_attacker": true,
        "is_known_abuser": true,
        "is_threat": true,
        "is_bogon": false,
        */
   
    const names = [
      {label: 'CrowdHandler Block Rules', value: 'global_rules'},
      {label: 'Known Attackers', value: 'is_known_attacker'},
      {label: 'Known Abusers', value: 'is_known_abuser'},
      {label: 'TOR', value: 'is_tor'},
      {label: 'Data Centers', value: 'is_datacenter'},
      {label: 'Proxys', value: 'is_proxy'},
      {label: 'Anonymous', value: 'is_anonymous'},
      {label: 'Bogons', value: 'is_bogon'},
      ];

    const handleChange = (e) => {
      setSelectValues(e.target.value);
    }

    const handleSubmit = (e) => {

      
      let ele = document.getElementById('threat-intelligence');
      

      function arrayEquals(a, b) {
        return Array.isArray(a) &&
          Array.isArray(b) &&
          a.length === b.length &&
          a.every((val, index) => val === b[index]);
      }

      // does the current formik value match the local value?
      // if it does then don't submit

      let equal = arrayEquals(selectValues, formik.values.blockThreats);


      if (!equal) {
        formik.setFieldValue("blockThreats", selectValues);
        setProcessing(true)
        formik.handleSubmit();
      }


    }


    return (

            <Grid item md={4} xs={12}>

<FormControl margin="dense" variant="outlined" fullWidth >

  {values
  
  ?
  <>
        <Typography>Known threats</Typography>
        <Select
          style={{marginTop: '9px'}}
          disabled={!hasAnomalyDetection || autoblockProcessing}        
          id="threat-intelligence"
          name="attackerThreats"
          multiple
          value={selectValues}
          onChange={handleChange}
          onClose={ handleSubmit}
          displayEmpty={true}
          renderValue={(selected) => {

            let result = names.reduce((acc, cur) => {              
              if (selectValues.indexOf(cur.value) !== -1) {
                acc.push(cur.label);
              };
              return acc;
            }, []);
            if (result.length) {
              return result.join(', ')
            }

            return ['Select threats to block'];
          }}
          MenuProps={MenuProps}
        >
          {names.map((name) => (
            <MenuItem key={name.value} value={name.value}>
              <Checkbox checked={selectValues.indexOf(name.value) > -1} />
              <ListItemText primary={name.label} />
            </MenuItem>
          ))}
        </Select>
        </>
:

null
}
      </FormControl>

            </Grid>

           
    )

}

export default AutoblockAttackerControl;