import React from 'react';
import { ReactComponent as Trash } from './feather/trash.svg';

import { SvgIcon } from '@material-ui/core';


export default function TrashIcon(props) {
    return (
        <SvgIcon {...props} component={Trash} className="feather"/>
    );
  }