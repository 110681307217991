import React, { useEffect, useRef, useState } from "react";
import clsx from 'clsx';
import { CopyButton } from '..';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Box
} from "@material-ui/core";
import {marked} from 'marked';
import groupBy from 'lodash/groupBy';
import ErrorSnackBar from '../ErrorSnackBar';

import Config from '../../config';


const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(2)
  },
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  tabPanel: {
    '& .MuiBox-root': {
      paddingTop: 0
    }
  },
  apiRateTypography: {
    marginBottom: theme.spacing(2)
  },
  apiListItem: {

    color: theme.palette.grey[600],
    paddingTop: 4,
    paddingBottom: 4,
    textTransform: 'uppercase',
    '& .MuiListItemText-root': {
      marginTop: 2,
      marginBottom: 2,

    },
    '& .MuiTypography-body1': {
      fontSize: '0.65rem',
      color: theme.palette.grey[600]
    },
    '& .feather': {
      width: '20px,',
      height: '20px,'
    }
  },
  apiListItemSecondary: {
    paddingLeft: '40px'
  },
  apiTitle: {
    fontSize: '16px',
    fontWeight: 400
  },
  apiContent: {
    marginTop: 0,
    paddingTop: 0,
    '& pre': {
      margin: 0
    },
    '& table': {
      textAlign: 'left',
      '& th': {
        padding: '0.2em 0.4em'
      },
      '& td': {
        padding: '0.2em 0.4em'
      },
    },
    '& a[href^="#"]': {
      color: theme.palette.secondary.main,
      textDecoration: 'none'
    }
  },
  apiCode: {
    '& pre code': {
      color: theme.palette.grey[700],
      background: theme.palette.grey[100],
      lineHeight: 1.5,
      display: 'inline-block',
      padding: '1em 4em 1em .5em',
      fontSize: '13px'
    },
    '& table' : {
      width: '100%',
      // border: '1px solid #D1D1D1',
      textAlign: 'left',
      borderCollapse: 'collapse',

      '& td' : {
        borderBottom: '1px solid #D1D1D1',
        padding: '7px 7px',
        fontSize: '13px',
      },
      '& th' : {
        borderBottom: '1px solid #D1D1D1',
        padding: '7px 7px',
      }
    }
  },
  stickyNav: {
    'position': '-webkit-sticky',
    // eslint-disable-next-line no-dupe-keys
    'position': 'sticky',
    top: 80,
    zIndex: 5,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  scrollablePre: {
    '& pre': {
      'overflow': 'auto'
    }
  },
  title: {
    fontSize: 14,
  },
  cardHeader : {
    background: theme.palette.grey[100],
    marginBottom: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    '& p' : {
      display: 'inline-block',
    },
    '& p:first-child' : {
      marginRight: theme.spacing(2),
      fontWeight: 'bold'
    }
  },
  cardHeaderTitle : {
    fontSize:  '14px'
  },
  textStyles : {
    fontFamily: 'Lato',
    '& ul li' : {
      marginBottom: '16px',
      fontSize: '0.875rem'
    },
    '& p':{
      fontSize: '0.875rem'
    }
  }
}));

const AccountAPI = ({ state, api, dispatch, setDocsMenu, docsMenu, docsPanel }) => {

  const classes = useStyles();
  const [snackPack, setSnackPack] = useState([]);
  const [open, setOpen] = useState(false);
  const [messageInfo, setMessageInfo] = useState(undefined);
  const theme = useTheme();
  const gettingDocs = useRef(false);

  let apiEndpoint = Config.CH_ENDPOINT.substring(8);
  apiEndpoint = Config.CH_ENDPOINT.substring(0, Config.CH_ENDPOINT.length-1);
  
  
  useEffect(() => {
    const getDocs = async () => {

      try {
        gettingDocs.current = true;
        let response = await api.getDocs();
        const regex = /(\/get|\/post|\/put|\/delete)/gm;
        const subst = ``;

        let data = response.map((docs) => {
          let m;
          while ((m = regex.exec(docs.resource)) !== null) {
            // This is necessary to avoid infinite loops with zero-width matches
            if (m.index === regex.lastIndex) {
              regex.lastIndex++;
            }
            // The result can be accessed through the `m`-variable.
            m.forEach((match) => {
              docs.verb = match.substr(1);
            });
          }
          docs.resource = docs.resource.replace(regex, subst);

          return docs;
        });

        const formatDocs = () => {

          const regex = /<id>/gm;
          const subst = `<span class="idMarker">id</span>`;

          let docsElement = data.filter((item) => {
            return item.id === "v1-docs-get-list";
          });

          let apiLimit = state.plan.apiRateLimit;
          let docPanel = docsElement[0];

          if (apiLimit !== undefined && state.plan.apiRateLimit > 0) {
            docPanel.use = docPanel.use.replace(/(<api-key-private>)/gm, state.privateKey);
          }
          docPanel.use = docPanel.use.replace(/(<api-key-public>)/gm, state.publicKey);
          
          if (apiLimit !== undefined) {
            docPanel.use = docPanel.use.replace(/(<api-rate-limit>)/gm, apiLimit);
          }
          // docPanel.use = docPanel.use.replace(lt_regex, '&lt;');
          // docPanel.use = docPanel.use.replace(gt_regex, '&gt;');
          
          docPanel.marked = marked(docPanel.use);
          // docPanel.params = marked(docPanel.params);

          if (gettingDocs.current) {
            dispatch({ type: 'SET_DOCS_PANEL', payload: docPanel });
          }
  
          let navDocs = data.filter((item) => {
            return item.id !== 'v1-docs-get-list';
          });

          navDocs = navDocs.map((item, index) => {
            item.a_position = index;
            return item;
          })

          let navDocsRoot = groupBy(navDocs.reduce((acc, item, index) => {
            let parts = item.resource.split('/');

            item.root = parts[0];
            acc.push(item);
            return acc;
          }, []), 'root');

          let docsNav = [];

          const format_output = (output) => {
            if (output !== null) {
              return JSON.stringify(output, undefined, 4);
            }
            return false;
          }

          for (const key in navDocsRoot) {
            if (navDocsRoot.hasOwnProperty(key)) {
              const element = navDocsRoot[key];

              let menuItem = {
                root: key,
                title: key,
                anchor: key,
                paths: [],
                strings: []
              }

              

           let elements = element.reduce((acc, item) => {
                item.resource = item.resource.replace(regex, subst);
                // item.use = item.use.replace(lt_regex, '&lt;');
                // item.use = item.use.replace(gt_regex, '&gt;');
                item.marked = marked(item.use);
                item.params_marked = undefined;
                item.output_formatted = undefined;

                if (item.params !== null) {
                  item.params_marked = marked(item.params);
                }

                if (item.params  === null && item.params_ref !== null) {
                  let copy = response.filter((element) => {
                    return element.id === item.params_ref;
                  });
                  if (copy.length) {
                    item.params_marked = marked(copy[0].params)
                  }
                }

                if (item.output !== null) {
                  item.output_formatted = format_output(item.output);
                }

                if (item.output === null && item.output_ref !== null) {

                  let copy = response.filter((element) => {
                    return element.id === item.output_ref;
                  });
                  if (copy.length) {
                    item.output_formatted = format_output(copy[0].output)
                  }
                }
                

               
                acc.push(item)
                return acc;
              }, []);

              let groups = groupBy(elements, 'resource');
              let keyGroups = []

              for (const key in groups) {
                if (groups.hasOwnProperty(key)) {
                  keyGroups.push({
                    root: key,
                    groups: groups[key]
                  });
                }
              }

              if (elements.length) {
                menuItem.paths = elements;
                menuItem.groups = keyGroups;
                docsNav.push(menuItem);
              }

            }
          }
          return docsNav;
        }

        let docs = await formatDocs(data);

        if (gettingDocs.current) {
          dispatch({ type: 'SET_DOCS', payload: docs });
        }

      } catch (error) {
        gettingDocs.current = false;
      }

      return () => {
        api.cancelRequest();
      }
    }

    getDocs();
   
    
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    if (snackPack.length && !messageInfo) {
      // Set a new snack when we don't have an active one
      setMessageInfo({ ...snackPack[0] });
      setSnackPack((prev) => prev.slice(1));
      setOpen(true);
    } else if (snackPack.length && messageInfo && open) {
      // Close an active snack when a new one is added
      setOpen(false);
    }
  }, [snackPack, messageInfo, open]);

  const handleAddSnackPackMessage = (message) => {
    setSnackPack((prev) => [...prev, { message, key: new Date().getTime() }]);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleExited = () => {
    setMessageInfo(undefined);
  };

  

  return (
    <>
      <Card className={`${classes.card} ${classes.textStyles}`}>
        <CardContent>
          <Typography component="h2" variant="h5">API</Typography>

          {state.publicKey
            ?
            <>
              <Grid container className={classes.apiCode}>
                <Grid item>

                  <Typography>
                    <CopyButton text={state.publicKey} onCopy={(e) => {
                      handleAddSnackPackMessage('Public Key copied');
                      }} />
                    Public Key
                      </Typography>
                      <Typography component="div">
                    <pre>
                      <code>{state.publicKey}</code>
                    </pre>
                      </Typography>
                  
                </Grid>
              </Grid>
            </>
            :
            null
          }

          {state.privateKey && (state.plan.apiRateLimit > 0)
            ?
            <>
              <Grid container className={classes.apiCode}>
                <Grid item>

                  <Typography>
                    <CopyButton text={state.privateKey} onCopy={() => {handleAddSnackPackMessage('Private Key copied')}} />
                    Private Key
                      </Typography>
                      <Typography component="div">

                    <pre>
                 
                      <code>{state.privateKey}</code>
                 
                    </pre>
                      </Typography>
                </Grid>
              </Grid>
            </>
            :
            null
          }
        </CardContent>
      </Card>


      {
        (state.docsPanel)
          ?
          <Card className={`${classes.card} ${classes.textStyles}`}>
            <CardContent>
              <Typography component="h2" variant="h5">Documentation</Typography>
              <div className={clsx({
                'mui-jss-MuiTypography-root-135': true,
                'MuiTypography-body1': true,
                [classes.scrollablePre]: true,
                [classes.apiCode]: true,
              })} dangerouslySetInnerHTML={{ __html: (state.docsPanel.marked) }}>

              </div>
            </CardContent>
          </Card>
          :
          null
      }

      {state.docs.map((element) => {
        return element.paths.map((path, index) => {

          return (
            <ApiBlock key={path.id} path={path} classes={classes} apiEndpoint={apiEndpoint} theme={theme}/>
          )
        })
      })}

      <ErrorSnackBar
        open={open}
        key={messageInfo ? messageInfo.key : undefined}
        autoHideDuration={2000}
        onClose={handleClose}
        onExited={handleExited}
        message={messageInfo ? messageInfo.message : undefined}
        status={'success'}
      />
    </>

  )
}
const ApiBlock = ({path, classes, apiEndpoint, theme}) => {
  return <Card className={`${classes.card} ${classes.textStyles}`} id={`${path.id}`}>

      <CardHeader
        className={classes.cardHeader}
        disableTypography
        title={
          <Typography>{path.verb.toUpperCase()}</Typography>
        }
        subheader={<Typography dangerouslySetInnerHTML={{ __html: (`${apiEndpoint}/<span style="font-weight: 600; color: ${theme.palette.secondary.main}">${path.resource}</span>`) }} ></Typography>}
      />
      
  <CardContent className={`${classes.apiContent} api-panel`}>
    <Box
      className={clsx({
        'mui-jss-MuiTypography-root-135': true,
        'MuiTypography-body1': true,
        [classes.apiCode]: true,
      })}
      dangerouslySetInnerHTML={{ __html: (path.marked) }}></Box>

      {
        path.params_marked
        ?
        <Box className={clsx({
          'MuiTypography-root': true,
          'MuiTypography-body1': true,
          [classes.apiCode]: true,
        })}
        >

        <Card elevation={0}>
          <CardContent>
          <Typography className={classes.title} color="textSecondary" gutterBottom>
          Parameters
          </Typography>
          <Box dangerouslySetInnerHTML={{ __html: (path.params_marked) }}></Box>
          </CardContent>
        </Card>         
        </Box>
        :
        null
      }
      
      <Divider/>

      {path.output_formatted
      ?
      <Box className={clsx({
        'MuiTypography-root': true,
        'MuiTypography-body1': true,
        [classes.apiCode]: true,
      })}>

        <Card elevation={0}>
          <CardContent>
          <Typography className={classes.title} color="textSecondary" gutterBottom>
            Response
          </Typography>
            <pre><code dangerouslySetInnerHTML={{ __html: (path.output_formatted) }}></code></pre>
          </CardContent>
        </Card>
      </Box>
      :
      null
      }


  </CardContent>



</Card>
}

export default AccountAPI;