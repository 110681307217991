import React, {useEffect, useRef} from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Redirect,
  useLocation
} from "react-router-dom";
import {
  useHistory,
} from "react-router-dom";
import axios from 'axios'
import semverGreaterThan from '../semverGreaterThan';


const RouteWithLayout = (props) => {
  const history = useHistory();
  let loc = useLocation();
  const versionChecked = useRef(false);
  
  useEffect(() => {
    const checkVersion = history.listen(() => {

      const url = `/meta.json?id=${process.env.REACT_APP_VERSION}`;
      
      if (versionChecked.current) {
        return false;
      }
      
      axios({
        method: 'get',
        url
      })
      .then( (response) => {     
        const latestVersion = response.data.version;
        const currentVersion = process.env.REACT_APP_VERSION;
        const shouldForceRefresh = semverGreaterThan(currentVersion, latestVersion);
          if (shouldForceRefresh) {
            window.location.reload();
          } else {
            versionChecked.current = true;
          }
      });
    });

    return () => {
      checkVersion();
    }
   
  }, [history]);




  const { layout: Layout, component: Component, ...rest } = props;
  return (
    rest.authorised
      ?
      <Route
        {...rest}
        render={props => (
          <Layout {...rest} >
            <Component  {...rest} />
          </Layout>
        )}
      />
      :
      <Redirect
        to={{
          pathname: "/login",
          state: { from: loc.pathname }
        }}
      />

  )

};

RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string
};

export default RouteWithLayout;
