import React, {useEffect, useState, useRef} from "react";
import {
    Grid,
    Typography
} from '@material-ui/core';

import Skeleton from '@material-ui/lab/Skeleton';
import DomainJavascriptOnboarding from "./DomainJavascriptOnboarding";

const JavaScriptImplementationPanel = ({pkey, domain, api}) => {

    let [checkingState, setCheckingState] = useState(true);
    let [jsState, setJsState] = useState();

    const isMounted = useRef(true)

    useEffect(() => {
        const CallTheJavaScriptDashCheck = async() => {
            let data = {
                "url" : domain.name,
                "key" : pkey
            }
            
            let response = await api.checkJS(data);
            if(isMounted.current) {
                setJsState({...response});
                setCheckingState(false);
            }
        }
        CallTheJavaScriptDashCheck();

        return () => {
            isMounted.current = false;
        }
    }, []);


return (
    <Grid item>
        {checkingState ?
        <>
        <Skeleton variant="text" />
        <Skeleton variant="text" />
        <Skeleton variant="text" />
        </>
        : null}

        {jsState && !checkingState ?
            <DomainJavascriptOnboarding publicKey={pkey} jscheck={jsState} domainLink={`/deployment-settings/${domain.id}`}/>
        : null}

    </Grid>
)

}

export default JavaScriptImplementationPanel;