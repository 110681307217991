import React, { useEffect, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios'
import semverGreaterThan from './semverGreaterThan';


function CacheBuster({ history, children }) {

  useEffect(() => {
    const checkVersion = history.listen(() => {

      const url = `/meta.json?id=${process.env.REACT_APP_VERSION}`;

      axios({
        method: 'get',
        url
      })
      .then( (response) => {     
        const latestVersion = response.data.version;
        const currentVersion = process.env.REACT_APP_VERSION;
        const shouldForceRefresh = semverGreaterThan(currentVersion, latestVersion);
          if (shouldForceRefresh) {
            window.location.reload();
          }
      });
    });

    console.log(history);
    return () => {
      checkVersion();
    }
   
  }, [history]);

  return <Fragment>{children}</Fragment>;


  
}


export default withRouter(CacheBuster);