import { Chart } from "chart.js";

const mainReportChartConfig = {
    type: 'line',
    // cubicInterpolationMode: 'default',
    data: {
        labels: [], // fill this with the times
        datasets: [], // the data from each room
    },
    options: {
        responsive: true,
        maintainAspectRatio: false,
        onHover: function (event, item) {
            if (item.length) {
                event.native.target.style.cursor = item[0].element ? 'zoom-in' : 'default';
                // if (item[0]._model.pointStyle) e.target.style.cursor= 'zoom-in'; // if  pointStyle  exists it is a point
            } else {
                event.native.target.style.cursor = 'auto';
            }
        },
        animations: {
            duration: 4000,
        },
        interaction: {
            mode: 'nearest',
            intersect: true,
        },
        scales: {
            x: {
                
                display: true,
                weight: 1400,
                grid: {
                    // display: false,
                    // drawBorder: true,
                    // drawOnChartArea: true,
                },
                ticks: {
                    autoSkip: true,
                    autoSkipPadding: 20,
                    // maxRotation: 0,
                    major: {
                        enabled: true
                    }
                    // callback : function(val, index){

                    //     console.log({val, index});
                    //     const labels = this.chart.data.labels;
                    //     console.log({labels});
                    //     // if(labels[index-1]===labels[index] && labels[index+1]===labels[index]){
                    //     //     return '.';
                    //     // }
                    // }
                  },
            },
            y: {
                min: 0,
                weight: 1000,
                grid: {
                    display: false,
                    // display: true,
                    // drawBorder: true,
                    // drawOnChartArea: true,
                },
                ticks: {
                    beginAtZero: true,
                    stepSize: 100,
                    callback: function(value, index, ticks) {
                        return value;
                    }
                }
            }
        },
        plugins : {
            legend: {
                display: false
            },
            tooltip: {
                axis: 'x',
                // position: 'nearest',
                // yAlign: 'bottom',
                // xAlign: 'center',
                usePointStyle: true,
                boxPadding: 0,
                callbacks: {
                    label(context) {                        
                        if(context.dataIndex > -1) {
                            const index = context.dataIndex;
                            const data = context.chart.data;
                            const dataSet = context.dataset;
                            let wait = (data.waitTimes[index] && data.waitTimes[index][context.datasetIndex]) ? ` Avg. Wait: ${data.waitTimes[dataSet.id][context.datasetIndex]} mins` : '';
                            
                            let label;
                            if (dataSet.label === 'Active') {
                                label = `${dataSet.label}: ${context.raw}`;
                            } else {
                                label = `${dataSet.label} - Waiting: ${context.raw}`;
                            }
                            return `${label} ${wait} `;
                        
                        }


                        // return `${context.dataset.label}`
                        //Label format: [ Herb Girls — Waiting: 1,536 Avg. wait: 20 mins ]
                        // if (tooltip.index > -1) {
                        // let dataSet = data.datasets[tooltip.datasetIndex];
                        // let wait = (data.waitTimes[dataSet.id] && data.waitTimes[dataSet.id][tooltip.index]) ? ` Avg. Wait: ${data.waitTimes[dataSet.id][tooltip.index]} mins` : '';
                        // let label;
                        // if (dataSet.label === 'Active') {
                        //     label = `${dataSet.label} - ${dataSet.data[tooltip.index]}`;
                        // } else {
                        //     label = `${dataSet.label} - Waiting: ${dataSet.data[tooltip.index]}`;
                        // }
                        //     return `${label} ${wait} `;
                        // }
                    }
    
                }
            },
        },
        
        
    }
}

export default mainReportChartConfig;