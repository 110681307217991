import React, {useEffect} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
    Grid,
    FormControl,
    OutlinedInput,
    FormHelperText,
    InputLabel,
    FormControlLabel,
    Checkbox,
    Typography
  } from '@material-ui/core';


const RoomStockAvailability = ({formik, displayStockLevels}) => {


    const stockWheel = React.createRef();

    useEffect(() => {

      if(stockWheel.current){
        stockWheel.current.addEventListener('wheel', (e) => {
          e.preventDefault();
        }, {passive: false})
      }
    }, []);

    return (
      <Grid container className='ch-room-form-group'>

        <Grid item xs={12} md={3}>
                    <FormControlLabel
                        control={
                          <Checkbox
                            {...formik.getFieldProps('displayStock')}
                            checked={formik.values.displayStock}
                            name="displayStock"
                            disabled={!displayStockLevels}
                          />
                        }
                        label="Show Stock Availability"
                        />
                </Grid>
                
                <Grid item xs={12} md={4}>
                  <FormControl fullWidth margin="dense" variant="outlined">
                    <InputLabel htmlFor="title">Availability</InputLabel>
                    <OutlinedInput
                      ref={stockWheel}
                      disabled={!formik.values.displayStock || !displayStockLevels}
                      type="number"
                      id="stockLevel"
                      {...formik.getFieldProps('stockLevel')}
                      labelWidth={72}
                      min="0"
                      aria-label="Stock Availability"
                      style={{backgroundColor: '#fff', touchAction: 'none'}}
                      // onWheel = {(event) => {
                      //   event.preventDefault();
                      //   return false;
                      // }}

                      />
                  </FormControl>

                  {formik.touched.stockLevel && formik.errors.stockLevel ? <Typography variant="subtitle2" color="error">{formik.errors.stockLevel}</Typography> : null}


                {
                !displayStockLevels ?
                <FormHelperText><RouterLink to="/account/plan">Upgrade</RouterLink> to use Stock Availability</FormHelperText>
                  :
                  null
                }
                </Grid>
        </Grid>
    )
}

export default RoomStockAvailability;