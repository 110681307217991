import React, {useEffect} from "react";
import {
    Grid,
    Typography,
    Button,
    Link
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { red } from '@material-ui/core/colors';
import { Link as RouterLink } from "react-router-dom";

import {
    ToolIcon
} from '../../../icons';

import mapIntegration from '../../../lib/implementationMap'





const QuickLinksPanel = ({domain, domainRooms}) => {

  const [roomActive, setRoomActive] = React.useState(false);


/*
    const get_implementation_guide = (domain) => {
        let url;
        let link_text;
        switch (domain.deployment) {
            case 'api':
                url = 'https://www.crowdhandler.com/docs/80000138228-introduction-to-the-api'
                link_text = 'Read our introduction to the API implementation'
                break;
            case 'akamai':
                url = 'https://www.crowdhandler.com/waiting-room/docs/80000694289'
                link_text = 'Read our introduction to the Akamai integration'
                break;
            case 'cloudflare':
                url = 'https://www.crowdhandler.com/docs/80000690332-cloudflare-integration-getting-started'
                link_text = 'Read our introduction to the Cloudflare integration'
                break;
            case 'cloudfront':
                url = 'https://www.crowdhandler.com/docs/80000833957-aws-cloudfront-integration-getting-started'
                link_text = 'Read our introduction to the CloudFront integration'
                break;
            default:
                url = 'https://www.crowdhandler.com/support/solutions/80000032299'
                link_text = 'Read our implementation guide'
                break;
        }

        return {url, link_text};
    }
*/
    useEffect(() => {
      let active = false;

      if(domainRooms){
        domainRooms.forEach(room => {
          if(room.queueActivatesIsActive || room.countdownIsActive ) {
            active = true;
          }
          
          if (room.patternType === 'disabled') {
            active = false;
          }

        });
      }

      setRoomActive(active)
    }, [domainRooms])


return (
<Grid container spacing={4}>
    <Grid item xs={12}>
    <Typography component="p" variant="h6" paragraph>Deployment: {mapIntegration[domain.deployment]}</Typography>
    
    {roomActive ? 
    <Typography paragraph style={{fontWeight:'bold'}}>
    No recent activity detected. This could be expected based on your configuration.
    </Typography>
    :
    <Typography paragraph style={{fontWeight:'bold'}}>
    No recent activity detected. This is expected based on your configuration.
    </Typography>
    }

    
          <Typography paragraph>
            If you have restrictive URL patterns, scheduled future activation dates, or are experiencing low traffic periods, this message is expected.
          </Typography>
          <Typography paragraph>
          This does not necessarily indicate a configuration problem. Please check your settings and contact support if you have any questions about expected functionality.
          </Typography>
    
            <Button startIcon={<ToolIcon/>} to={`/deployment-settings/${domain.id}`} component={RouterLink} color="secondary" variant="contained" disableElevation>Deployment Settings</Button>
    </Grid>
        
    
</Grid>
)

}

export default QuickLinksPanel;