import React from 'react';
import { ReactComponent as Users } from './feather/users.svg';

import { SvgIcon } from '@material-ui/core';


export default function UsersIcon(props) {
    return (
        <SvgIcon {...props} component={Users} className="feather"/>
    );
  }