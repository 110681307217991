import React, { useState, useContext } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import TrashIcon from '../icons/TrashIcon';
import DownloadIcon from '../icons/DownloadIcon';
import { ArrowUpCircleIcon, ArrowDownCircleIcon } from '../icons';
import InlineRowEditField from '../components/InlineRowEditField';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { v4 as uuidv4 } from 'uuid';
// import { Storage } from 'aws-amplify';

import CustomTemplateNewTemplate from './CustomTemplateNewTemplate';
import TemplateUploadField from './TemplateUploadField';
import Config from '../config';

import * as AWS from 'aws-sdk/global';
import { FetchHttpHandler } from "@smithy/fetch-http-handler";
import { PutObjectCommand, S3Client } from "@aws-sdk/client-s3";

import {AuthContext} from '../providers/AuthProvider';

import {
  Grid,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  FormControl,
  InputLabel,
  Card,
  CardHeader,
  CardContent,
  Tooltip,
  CircularProgress,
  Backdrop,
  Link,
} from '@material-ui/core';

import { TemplatePreviewButton } from 'components';

const useStyles = makeStyles((theme) => ({
  table: {
    // maxWidth: 440,
  },
  paper: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  cell: {
    wordWrap: 'break-word',
    padding: '16px'
  },
  addRowForm: {
    marginTop: 8,
    '& .MuiGrid-item': {
      paddingTop: 0,
      paddingBottom: 0,
    }
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.primary.main
  },
}));

const StyledTableRow = withStyles((theme) => ({
  root: {
    '& th:nth-of-type(even)': {
      backgroundColor: theme.palette.grey[200],
    },
    '& td:nth-of-type(odd)': {
      backgroundColor: theme.palette.grey[200],
    }
  },
}))(TableRow);

export default function CustomTemplatesTable({
  templates,
  name,
  handleDownloadTemplate,
  deleteTemplateHandler,
  accountInformation,
  rooms,
  api,
  domains,
  logo,
  setMessage,
  setOpenErrorSnackbar,
  customer,
  handleSetSelectedTemplate,
  roomsMarkup,
  previewURL,
  maxFileSize,
  ...rest }) {

  const chAuth = useContext(AuthContext);
  const { dispatch } = rest;
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const [processing, setProcessing] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  }

  const handleFileUpdate = async (data) => {
    setProcessing(true);    
    // get the existing template path
    let existing_template = templates.filter((template) => {
      return template.id === data.templateId;
    });

    let Key;

    if (existing_template.length) {
      // need to strip the start of the url
      Key = `public/${existing_template[0].url.substring(`https://${Config.AMPLIFY.Storage.S3.bucket}.s3.amazonaws.com/public/`.length)}`;
    } else {
      Key = `public/${accountInformation.get('customer').id}/${uuidv4()}.html`;
    }

    const {session} = await chAuth.handleCurrentSession();

    if (session && session.isValid()) {
      AWS.config.credentials.get(error => {
        if (error) {
          console.error(error);
          setProcessing(false);
        } else {
          // Instantiate aws sdk service objects now that the credentials have been updated.
  
          const AccessKeyId = AWS.config.credentials.data.Credentials.AccessKeyId,
            SecretKey = AWS.config.credentials.data.Credentials.SecretKey,
            SessionToken = AWS.config.credentials.data.Credentials.SessionToken;
  
          const creds = {
            accessKeyId: AccessKeyId,
            secretAccessKey: SecretKey,
            sessionToken: SessionToken
          };
  
          const s3Client = new S3Client({
            region: 'us-east-1',
            credentials: creds,
            requestHandler: new FetchHttpHandler({ keepAlive: false }),
          });
  
          const callPut = async () => {
  
            const PutObjectCInput = {
              Bucket: Config.AMPLIFY.Storage.S3.bucket,
              Key,
              Body: data.file,
              ContentType: 'text/html'
            }
  
            const command = new PutObjectCommand(PutObjectCInput);
  
            try {
              const response = await s3Client.send(command);
  
              if (response && response.$metadata.requestId) {
                let template_data_input = {
                  url: `https://${Config.AMPLIFY.Storage.S3.bucket}.s3.amazonaws.com/${Key}`,
                  id: existing_template[0].id
                }
                const addTemplate = async (data) => {
  
                  let response = await api.addTemplate({
                    url: data.url
                  }, 'put', data.id);
  
                  if (response) {
                    dispatch({ type: 'UPDATE_TEMPLATES', payload: response });
                    setProcessing(false);
                  }
                  
                }
                addTemplate(template_data_input);
              }
            } catch (error) {
              console.log(error);
              setProcessing(false);
            }
          }
  
          callPut();
  
        }
      });
    }

    

  }

  const handleTemplateNameUpdate = async (data, id) => {
    setProcessing(true);
    try {
      let response = await api.addTemplate(data, 'put', id);
      dispatch({ type: 'UPDATE_TEMPLATES', payload: response });
      setProcessing(false);
    } catch (error) {
      setProcessing(false);
      
    }
  }


  return (
    <>
      <Grid container component={Card} className={classes.paper}>
        <Grid item xs={12}>

          <CardHeader title={name} onClick={handleOpen} action=
            {
              <IconButton aria-label="settings">
                {
                  (open) ? <ArrowUpCircleIcon /> : <ArrowDownCircleIcon />
                }
              </IconButton>
            }
          >
          </CardHeader>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <CardContent>
              <Grid item xs={12}>
                
                <Card style={{marginBottom: 28}}>
                  <CardContent>
                    <Grid container direction='row'>
                      <Grid>
                        
                        <Typography>Download the Standard Template to understand the tag structure, which is based on IDs.</Typography>
                        <Typography>Any linked images, javascript and CSS will need to be loaded from separate fully qualified secure (https://) URLs that you have uploaded separately.</Typography>
                        <Typography>Do not link assets from the domain that you are protecting.</Typography>
                        <Typography><Link target="_blank"href="https://www.crowdhandler.com/docs/80000984420-creating-custom-templates">Creating custom templates</Link></Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <CustomTemplateNewTemplate
                templates={templates}
                customer={customer}
                api={api}
                setMessage={setMessage}
                setOpenErrorSnackbar={setOpenErrorSnackbar}
                classes={classes}
                maxFileSize={maxFileSize}
                {...rest} />

              <TableContainer component='div'>
                <Table className={classes.table} aria-label="List of templates for" >
                  <TableHead>
                    <TableRow>
                      <TableCell component="th" scope="row">Name</TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody >
                    {templates.map((template, rowIndex) => (
                      <TemplateTableRow
                        key={template.id}
                        template={template}
                        deleteTemplateHandler={deleteTemplateHandler}
                        classes={classes}
                        handleFileUpdate={handleFileUpdate}
                        handleTemplateNameUpdate={handleTemplateNameUpdate}
                        handleDownloadTemplate={handleDownloadTemplate}
                        templates={templates}
                        handleSetSelectedTemplate={handleSetSelectedTemplate}
                        rooms={rooms}
                        maxFileSize={maxFileSize}
                      />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

            </CardContent>
          </Collapse>
        </Grid>

        <Backdrop open={processing} className={classes.backdrop}>
          <Grid container justifyContent="center" alignItems="center" direction="column" spacing={4}>
            <Grid item>
              <CircularProgress color="inherit" />
            </Grid>
          </Grid>
        </Backdrop>


      </Grid>


    </>
  );
}


const TemplateTableRow = (props) => {

  let {
    template,
    deleteTemplateHandler,
    handleFileUpdate,
    classes,
    handleTemplateNameUpdate,
    handleDownloadTemplate,
    handleSetSelectedTemplate,
    rooms,
    maxFileSize
  } = props;

  let orginalValues = {
    templateName: template.name,
    customTemplateLink: template.url
  }

  let [, setSaving] = useState(false);
  const formik = useFormik({
    initialValues: {
      templateName: template.name,
      customTemplateLink: template.url
    },
    validationSchema: Yup.object({
      customTemplateLink: (template.isFile) ? Yup.string().nullable().default(null) : Yup.string().required('Required'),
      templateName: Yup.string().required('Required')

    }),
    onSubmit: (values) => {

      let formattedData = {
        name: values.templateName,
      }

      if (!template.isFile) {
        formattedData.url = values.customTemplateLink
      }

      handleTemplateNameUpdate(formattedData, template.id)
    },
  });

  const updateTemplate = (data) => {
    handleFileUpdate(data);
  }

  const downloadTemplate = async (template) => {
    handleDownloadTemplate(template.url, template.name);
  }


  return (
    <StyledTableRow >
      <TableCell component="th" scope="row" className={classes.cell}>
        <form onSubmit={formik.handleSubmit}>
          <FormControl fullWidth margin="dense" variant="outlined">
            <InputLabel htmlFor={`template_id_name_${template.id}`} id={`template_id_name_${template.id}_label`}>Template Name</InputLabel>
            <InlineRowEditField
              title={orginalValues.templateName}
              id={`template_id_name_${template.id}`}
              name="templateName"
              labelWidth={100}
              aria-label="Template Name"
              aria-describedby={`template_id_name_${template.id}_label`}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.templateName}
              handleSave={formik.handleSubmit}
              setSaving={setSaving}
              changed={orginalValues.templateName !== formik.values.templateName}
              disableSave={orginalValues.templateName === formik.values.templateName}

            />
            {formik.touched.templateName && formik.errors.templateName ? <Typography variant="subtitle2" color="error">{formik.errors.templateName}</Typography> : null}
            {/* <FormHelperText>{orginalValues.templateName}</FormHelperText> */}
            </FormControl>
        </form>
      </TableCell>

      <TableCell align="center" >
        {template.isFile
          ?
          <TemplateUploadField template={template} updateTemplate={updateTemplate} maxFileSize={maxFileSize} />
          :
          <FormControl fullWidth margin="dense" variant="outlined">
            <InputLabel required htmlFor="customTemplateLink">Template URL</InputLabel>

            <InlineRowEditField
              id={`template_id_url_${template.id}`}
              name="customTemplateLink"
              labelWidth={100}
              aria-label="Template URL"
              aria-describedby={`template_id_url_${template.id}_label`}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.customTemplateLink}
              handleSave={formik.handleSubmit}
              setSaving={setSaving}
              changed={orginalValues.customTemplateLink !== formik.values.customTemplateLink}
              disableSave={orginalValues.customTemplateLink === formik.values.customTemplateLink}
            />
            {formik.touched.customTemplateLink && formik.errors.customTemplateLink ? <Typography variant="subtitle2" color="error">{formik.errors.customTemplateLink}</Typography> : null}



          </FormControl>
        }
      </TableCell>

      <TableCell align="center" style={{ width: '20px' }}>

        <TemplatePreviewButton
          handleSetSelectedTemplate={handleSetSelectedTemplate}
          template={template}
          rooms={rooms}
        />


      </TableCell>

      <TableCell align="center" style={{ width: '20px' }}>

        {template.isFile ?
          <Tooltip title="Download this Template" aria-label="Download this Template">

            <IconButton onClick={() => { downloadTemplate(template) }}>
              <DownloadIcon></DownloadIcon>
            </IconButton>

          </Tooltip>
          : null}
      </TableCell>

      <TableCell align="center" style={{ width: '20px' }}>
        <Tooltip title="Delete this Template" aria-label="Delete this Template">
          <IconButton onClick={() => { deleteTemplateHandler(template) }} >
            <TrashIcon ></TrashIcon>
          </IconButton>
        </Tooltip>

      </TableCell>

    </StyledTableRow>
  )

};