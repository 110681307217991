import React, { useState, useEffect, useReducer } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import {Link as RouterLink} from 'react-router-dom';

import {
    Typography,
    Container,
    FormControl,
    InputLabel,
    Button,
    Grid,
    Paper,
    MenuItem,
    Select,
    Backdrop,
    CircularProgress
} from "@material-ui/core";
import { withRouter } from 'react-router-dom'; // To use the history object
import ErrorSnackBar from '../../components/ErrorSnackBar';
import DomainProxyOnboarding from '../../components/DomainProxyOnboarding';
import DomainCloudFlareOnboarding from '../../components/DomainCloudFlareOnboarding';
import DomainCloudFrontOnboarding from '../../components/DomainCloudFrontOnboarding';
import DomainJavascriptOnboarding from '../../components/Dashboard/ImplementationPanels/DomainJavascriptOnboarding';
import DomainCustomOnboarding from '../../components/DomainCustomOnboarding';
import DomainWordPressOnboarding from '../../components/DomainWordPressOnboarding';
import DomainAkamaiOnboarding from '../../components/DomainAkamaiOnboarding';
import DotNetOnboarding from '../../components/DotNetOnboarding';
import {DomainShopifyOnboarding} from '../../components/shopifyOnboarding';

import mapIntegration from '../../lib/implementationMap'

const implementationTypes = [
    'javascript',
    'dns',
    'wordpress',
    'cloudflare',
    'cloudfront',
    'akamai',
    '.net',
    'shopifyjs',
    'api',
]


const useStyles = makeStyles((theme) => ({
   
    margin: {
        margin: theme.spacing(6),
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    noUnderline: {
        '& .MuiInput-underline:before': {
            display: 'none'
        },
        '& .MuiInput-underline:after': {
            display: 'none'
        }
    },
    smallmargin: {
        marginBottom: theme.spacing(2),
    },
    withoutLabel: {
        marginTop: theme.spacing(6),
    },
    domainAdornment: {
        marginRight: 0
    },
    spinner: {
        marginLeft: 22,
        color: theme.palette.secondary
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: theme.palette.secondary.main,
      },
    section: {
        margin: '0'
    }

}));

const DomainsDeploymentSettings = ({ state, computedMatch, history, api, ...rest }) => {

    const classes = useStyles();
    const { dispatch } = rest;
    let { domains, plan } = state;

    let [cdnAllowed] = useState(state.plan.allowCDN);
    
    let [displayCDNPanel, setDisplayCDNPanel] = useState(false);
    const [isEditing] = useState(computedMatch.params.domainId);
    const [ready, setReady] = useState((isEditing) ? false : true);
    const [processing, setProcessing] = React.useState(false);

    let [message, setMessage] = useState('');
    let [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
    let [alertBarStatus, setAlertBarStatus] = useState('success');

    const closeErrorSnackbar = () => {
        setOpenErrorSnackbar(false)
    }

    function reducer(state, action) {

        switch (action.type) {
            
            case 'UPDATE_SETTINGS':
                let newSteps = {
                    ...state,
                    ...action.payload
                }
                return newSteps;
            default:
                throw new Error();
        }
    }

    const [settings, setSettings] = useReducer(reducer, {
        autotune: false,
        deployment: '',
        maxSlowRequestPercent: 2,
        slowRequestThreshold: 5000,
        timeout: 20,
        rate: 10,
        id: undefined,
        checkout: null
    });

    useEffect(() => {
        const getDomains = async () => {
            let domains = await api.getDomains();
            dispatch({ type: 'ADD_DOMAINS', payload: domains });
        }
        if (state.domains === undefined) {
            getDomains();
        }
    }, [api, state.domains, dispatch]);

    useEffect(() => {
        const getCurrentDomainData = async () => {

            if (computedMatch) {
                if (computedMatch.params.domainId) {
                    let domain = domains.filter(domain => {
                        return domain.id === computedMatch.params.domainId;
                    });
                    if (domain.length > 0) {

                        let formattedValues = {
                            autotune: (domain[0].autotune === 1) ? true : false,
                            deployment: (domain[0].deployment) ? domain[0].deployment : ''
                        }
                        let mergeValues = {
                            ...domain[0],
                            ...formattedValues
                        }

                        setSettings({ type: 'UPDATE_SETTINGS', payload: mergeValues });
                        setReady(true);

                        if (mergeValues.deployment === 'dns') {
                            setDisplayCDNPanel(true);
                        }
                    }
                }
            }
        }
        if (state.domains !== undefined) {
            getCurrentDomainData();
        }

    }, [computedMatch, domains, state.domains]);


    /**
       * Set Formik validation and Submit Handler
       */


    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            ...settings
        },
        validationSchema: Yup.object({
            // backend: Yup.string()
            //     .when('deployment', {
            //         is: 'proxy', // alternatively: (val) => val == true
            //         then: Yup.string().required('Required').matches(ipAddressRegex, { message: 'Please supply a valid IP Address' }),
            //     }),
            rate: Yup.number().max(plan.maxDomainRate, `Maximium value is ${plan.maxDomainRate}`),
            timeout: Yup.number().max(30, `Maximium timeout is 30`)
        }),
        onSubmit: async (values) => {

            // check domain name:

            if(values.timeout > 30) {
                values.timeout = 30;
            }

           
                setProcessing(true);
                let method = 'put';
    
                let formattedData = {
                    id: values.id,
                    autotune: (values.autotune) ? 1 : 0,
                    rate: parseInt(values.rate, 10),
                    slowRequestThreshold: parseInt(values.slowRequestThreshold, 10),
                    maxSlowRequestPercent: parseInt(values.maxSlowRequestPercent, 10),
                    timeout: parseInt(values.timeout, 10),
                    deployment: (values.deployment !== '') ? values.deployment : null
                }
        
                let mergedData = { ...values, ...formattedData };

                saveDomain(mergedData, method);
                
           
        },
    });

    const saveDomain = async (data, method) => {
        let result = await api.putDomain(data, method);
    
        if (!result.error) {
            dispatch({ type: 'UPDATE_DOMAINS', payload: result });
            dispatch({ type: 'SET_CURRENT_DOMAIN', payload: data });
            setProcessing(false);
            setMessage('Domain Updated');
            setAlertBarStatus('success');
            setOpenErrorSnackbar(true);
            
        } else {
            setProcessing(false);
            setMessage('Error updating domain');
            setAlertBarStatus('error');
            setOpenErrorSnackbar(true);
        }
    }



    return (
        <>
            {ready
                ?

                <Container maxWidth={false} spacing={2} className={classes.root} >


                    <form autoComplete="off" onSubmit={formik.handleSubmit}>
                        <input type="hidden" name="id" value={settings.id}></input>



                        <Grid container component={Paper} className={classes.paper} spacing={2}>
                            <Grid item xs={12} className={classes.section}>
                                <Typography >Select how Crowdhandler is implemented for {settings.name}.</Typography>
                                {formik.values.deployment !== settings.deployment ? <Typography color="primary">Settings will be applied when you save your domain</Typography>: null}
                            </Grid>
                            <Grid item xs={12} style={{marginBottom: '40px'}}>
                                <Grid container alignItems="flex-end" spacing={2}>
                                    <Grid item xs={5}>
                                        <FormControl fullWidth margin="dense" variant="outlined">
                                            <InputLabel id="deployment-select-label">Select Deployment</InputLabel>
                                            <Select
                                                labelWidth={500}
                                                label="Select Deployment"
                                                name="deployment"
                                                labelId="deployment-select-label"
                                                {...formik.getFieldProps('deployment')}
                                            >
                                                <MenuItem value=''>
                                                    <em>Select Deployment</em>
                                                </MenuItem>

                                                {implementationTypes.map((name) => {
                                                    return (
                                                        <MenuItem key={name} value={name}>{mapIntegration[name]}</MenuItem>

                                                    )
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    
                                        
                                   
                                {formik.values.deployment !== settings.deployment
                                        
                                        ?
                                        <Grid item>
                                            <FormControl margin="dense">
                                        <Button
                                            type="submit"
                                            disableElevation
                                            variant="contained"
                                            color="secondary"
                                            disabled={!cdnAllowed && formik.values.deployment==='dns'}
                                        >
                                            Save</Button>

                                            </FormControl>
                                        </Grid>
                                        :
                                        null
                                        }
                                </Grid>
                                




                            </Grid>

                            <Grid item xs={12}>
                            {formik.values.deployment === 'dns' && cdnAllowed && settings.deployment !== 'dns'
                            ?
                            
                                <Paper elevation={0}>
                                        <Typography variant="body2" color="textSecondary" component="p">
                                        Click Save to start the DNS re-pointing process
                                        </Typography>
                                    </Paper>
                            :
                            null
                            }

                            {formik.values.deployment === 'dns' && !cdnAllowed 
                            ?
                            <Typography><RouterLink to="/account/plan">Upgrade</RouterLink> to use DNS implementation</Typography>
                            :
                            null
                            }

                        {formik.values.deployment === 'javascript'
                            ?
                            <DomainJavascriptOnboarding state={state} publicKey={state.publicKey}/>
                            :
                            null
                            }

                            {formik.values.deployment === 'wordpress'
                            ?
                            <DomainWordPressOnboarding />
                            :
                            null
                            }


                            {formik.values.deployment === 'cloudflare'
                            ?
                           <DomainCloudFlareOnboarding />
                            :
                            null
                            }

                            {formik.values.deployment === 'akamai'
                            ?
                           <DomainAkamaiOnboarding privatekey={state.privateKey}/>
                            :
                            null
                            }

                            {formik.values.deployment === 'cloudfront'
                            ?
                           <DomainCloudFrontOnboarding />
                            :
                            null
                            }
                            {formik.values.deployment === '.net'
                            ?
                           <DotNetOnboarding />
                            :
                            null
                            }

                            {formik.values.deployment === 'api'
                            ?
                           <DomainCustomOnboarding />
                            :
                            null
                            }
                        
                            {formik.values.deployment === 'dns' && isEditing && cdnAllowed && displayCDNPanel
                                ?
                                <DomainProxyOnboarding
                                    api={api}
                                    domainName={settings.name}
                                    settings={settings}
                                    setProcessing={setProcessing}
                                />
                                :
                                null
                            }
                            
                            {formik.values.deployment === 'shopifyjs'
                            ?
                           <DomainShopifyOnboarding showGenerateWebhook={state.plan.allowCustomTemplates} domainName={settings.name} privatekey={state.privateKey} publickey={state.publicKey} api={api}/>
                            :
                            null
                            }
                            </Grid>
                        

                        </Grid>
                    </form>

                    <Backdrop className={classes.backdrop} open={processing}>
                        <CircularProgress color="inherit" disableShrink />
                    </Backdrop>
                    <ErrorSnackBar message={message} open={openErrorSnackbar} status={alertBarStatus} closeErrorSnackbar={closeErrorSnackbar} />

                </Container>
                :
                null
            }
        </>
    );
};

export default withRouter(DomainsDeploymentSettings);