import React from "react";
import {
    Typography,
    Grid,
    Paper,
    Link,
} from "@material-ui/core";


const DomainCloudFlareOnboarding = () => {
    return (

        <Grid container spacing={4}  >
            <Grid item xs={12}>

            <Paper elevation={0}>
       
          <Typography variant="body2" color="textSecondary" component="p">
          To install the Cloudflare worker check out our guide:<br/><Link target="_blank" href="https://www.crowdhandler.com/docs/80000690332-cloudflare-integration-getting-started">
                        Cloudflare Integration - Getting Started
                    </Link>
          </Typography>
       
    </Paper>

            </Grid>
        </Grid>
    )

}

export default DomainCloudFlareOnboarding;