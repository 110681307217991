import React from 'react';
import { Grid, Typography } from '@material-ui/core';


const GlobalFooter = ({align, state}) => {
    return (
        (state && state.ready && !state.isLoading) ?
        <div style={{paddingRight: 20, marginTop: 20, marginBottom: 20}}>
            <Grid container spacing={2} justifyContent={align}>
            <Typography>© Crowdhandler 2023</Typography>
            </Grid>
            </div>

            : null
            
       
    )
}

export default GlobalFooter;

