import React from 'react';
import { ReactComponent as Arrowdown } from './feather/arrow-down-circle.svg';

import { SvgIcon } from '@material-ui/core';


export default function ArrowDownCircleIcon(props) {
    return (
        <SvgIcon {...props} component={Arrowdown} className="feather"/>
    );
  }