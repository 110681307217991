import sortBy from 'lodash/sortBy';
import groupBy from 'lodash/groupBy';
import difference from 'lodash/difference';
import keys from 'lodash/keys';
import uniq from 'lodash/uniq';
import orderBy from 'lodash/orderBy';
import dashboardChartConfig from './dashboardChartConfig';
import { DateTime } from 'luxon';

export const chartBuilder = (response, rooms, theme, updating, chartConfig, chartStyle) => {

    let data = response;
    let config = (chartConfig) ? chartConfig : dashboardChartConfig;

    
    config = {
        ...config,
        data: {
            labels: [], // fill this with the times
            datasets: [], // the data from each room
        }
    }


    let roomsToFind = uniq(data.map((entry) => {
        return entry.room_id;
    }));

    const roomsIds = rooms.reduce((acc, room) => {
        if (roomsToFind.indexOf(room.id) !== -1) {
            acc.push(room.id);
        }
        return acc;
    }, []);


    let roomLength = roomsIds.length;

    let ordered = formatTimeSlotEntries(data, roomLength, roomsIds, rooms);

    config.data.datasets = buildInitialDataSets(rooms, roomsIds, theme, chartStyle);


    for (const key in ordered) {
        if (ordered.hasOwnProperty(key)) {
            const element = ordered[key];
            config.data.labels.push(DateTime.fromISO(key).toLocaleString(DateTime.TIME_24_SIMPLE));
            for (let index = 0; index < element.length; index++) {
                const time = element[index];

                for (let n = 0; n < config.data.datasets.length; n++) {
                    const dataset = config.data.datasets[n];
                    // dataset.tension = 1.5;

                    if (dataset.id === time.room_id) {
                        dataset.data.push(time.waiting)
                    }
                }
            }
        }
    }


    return config;
}

const formatTimeSlotEntries = (data, roomLength, roomsIds, rooms) => {

    if(roomLength === 0) {
        return {}
    }
    
    let parsedTimes = data.map((response) => {
        response.time = DateTime.fromISO(response.time, { zone: 'utc' }).toISO();
        response.room_title = rooms.filter((room) => {
            return room.id === response.room_id;
        })[0].title;
        return response;
    });

    
    let timeByRooms = groupBy(parsedTimes, 'time');
    let prevKey = undefined;

    for (const key in timeByRooms) {
        if (timeByRooms.hasOwnProperty(key)) {
            let rows = timeByRooms[key];


            // check to see if there are duplicates (too many rooms for the time slot)
            // if there are then grab the first one by grouping by the room_id and taking the first element
            if (rows.length > roomLength) {
                let rooms = groupBy(rows, 'room_id');
                let newRow = []
                for (const roomIDKey in rooms) {
                    if (rooms.hasOwnProperty(roomIDKey)) {
                        const element = rooms[roomIDKey];
                        newRow.push(element[0])
                    }
                }
                rows = newRow;
            }

            if (rows.length < roomLength) { // we're missing a room
                let existingRows = rows.reduce((acc, row) => {
                    acc.push(row.room_id);
                    return acc;
                }, []);
                // find the difference compared with the room ids from the rooms array
                let diff = difference(roomsIds, existingRows);
                for (let index = 0; index < diff.length; index++) {
                    const roomToAdd = diff[index];
                    let room = {
                        time: key,
                        room_id: roomToAdd,
                        waiting: 0,
                        transacting: 0
                    }
                    rows.push(room);
                }
            }

            timeByRooms[key] = sortBy(rows, 'room_id');

            let room = {
                time: key,
                room_id: 'transacting',
                waiting: rows.reduce((acc, room) => {
                    acc += room.transacting;
                    return acc;
                }, 0),
                transacting: 0
            }

            timeByRooms[key].push(room);

            let time = DateTime.fromISO(key, { zone: 'utc' });
            if (prevKey) {
                let secondsDiff = time - prevKey;
                if (secondsDiff > 60000) {

                    let slots = (secondsDiff - 60000) / 60000;
                    let previousRows = timeByRooms[prevKey.toISO()];

                    for (let index = 0; index <= slots; index++) {
                        let newKey = prevKey.plus({ minute: index });
                        timeByRooms[newKey.toISO()] = previousRows;
                    }
                }
            }
            prevKey = time
        }
    }

    for (const key in timeByRooms) {
        if (timeByRooms.hasOwnProperty(key)) {
            let rooms = timeByRooms[key];

            if (rooms) {
                for (let index = 0; index < rooms.length; index++) {
                    const element = rooms[index];
                    let time = DateTime.fromISO(key, { zone: 'utc' });
                    element.time = time;
                    element.timestamp = time.ts;
                }
            }
        }
    }

    var ordered = {};
    keys(timeByRooms).sort().forEach(key => {
        ordered[key] = timeByRooms[key];
    });

    return ordered;
}

const buildInitialDataSets = (rooms, roomsIds, theme, chartStyle) => {
    let roomsToUse = rooms.reduce((acc, room, index) => {
        if (roomsIds.indexOf(room.id) !== -1) {
            acc.push(room);
        }
        return acc;
    }, []);

    roomsToUse = orderBy(roomsToUse, ['id']);


    let labelData = roomsToUse.reduce((acc, room, index) => {
        if (roomsIds.indexOf(room.id) !== -1) {

            // find position of room in the rooms collection to maintain the colour
            acc.push({
                label: room.title,
                domain: room.domainID,
                id: room.id,
                data: [],
                fill: false,
                borderColor: room.color,
                backgroundColor: room.color,
                borderWidth: 2,
                pointRadius: 2,
                pointHitRadius: 4,
                roomID: room.id,
            })
        }
        return acc;
    }, []);

    if(roomsToUse.length) {

        labelData.push({
            label: 'Active',
            data: [],
            fill: {
                target: 'origin',
                above: theme.palette.action.hover,   // Area will be red above the origin
                // below: 'rgb(0, 0, 255)'    // And blue below the origin
            },
            id: 'transacting',
            backgroundColor: theme.palette.action.hover,
            borderColor: theme.palette.text.disabled,
            borderDash: (chartStyle && chartStyle.borderDash !== undefined) ? chartStyle.borderDash : [3, 6],
            borderWidth: (chartStyle && chartStyle.borderWidth !== undefined) ? chartStyle.borderWidth : 2,
            pointRadius: (chartStyle && chartStyle.pointRadius !== undefined) ? chartStyle.pointRadius : 2,
            pointHitRadius: (chartStyle && chartStyle.pointHitRadius !== undefined) ? chartStyle.pointHitRadius : 4,
            pointStyle: (chartStyle && chartStyle.pointStyle !== undefined) ? chartStyle.pointStyle : 'rectRot'
        });
    
    }


    return labelData;
}

export default { chartBuilder };