
import React from 'react';
import * as Yup from 'yup';
import { withFormik } from 'formik';
import { Grid, Button, FormControl, MenuItem, InputLabel } from "@material-ui/core";
import {ChFormikSelect} from '.';

const formikEnhancer = withFormik({
    mapPropsToValues: (props) => {

        return {
            tax_status: props.tax_exempt
            
        }
    },
    validationSchema: Yup.object().shape({
        tax_status: Yup.string().required('This is a required field')
    }),
    handleSubmit: (values, { setSubmitting, props }) => {

        props.handleTaxStatusUpdate(values)
    },

    displayName: 'TaxIdsStatusForm', // helps with React DevTools
});



const TaxStatusForm = ({handleReset, tax_ids, setEditTaxInformation, handleTaxStatusUpdate, errors, handleSubmit}) => {
  
    /*
     * These props are available from the formik form
    */
    
    // const {
    //     values,
    //     touched,
    //     errors,
    //     dirty,
    //     isSubmitting,
    //     handleChange,
    //     handleBlur,
    //     handleSubmit,
    //     handleReset,
    //     disableEditing,
    //     setEditBillingAddress,
    //     address,
    //     CountryCodes
    // } = props;

    return (
        <Grid component="form" container spacing={2} alignItems="flex-start" justifyContent="flex-start" direction="row" onSubmit={handleSubmit}>
                <Grid item >
                <FormControl fullWidth margin="dense" variant="outlined">
                    <InputLabel htmlFor="my-input">Tax Status</InputLabel>
                    <ChFormikSelect
                        size="small"
                        type="text"
                        name="tax_status"
                        label="Tax Status"
                    >
                    <MenuItem value='none'>Taxable</MenuItem>
                    <MenuItem value='exempt'>Exempt</MenuItem>
                    <MenuItem value='reverse'>Reverse</MenuItem>
                    </ChFormikSelect>
                </FormControl>
                </Grid>
            <Grid item >
            <FormControl fullWidth margin="dense" variant="outlined">
                <Button disableElevation variant="contained" color="secondary" type="submit"
                style={{"marginRight":"10px"}}>
                    Set Tax Status
                </Button>
                </FormControl>

            </Grid>
            </Grid>

    );
};

export default formikEnhancer(TaxStatusForm);