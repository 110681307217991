import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import TrashIcon from '../icons/TrashIcon';
import ShieldIcon from '../icons/ShieldIcon';
import SettingsIcon from '../icons/SettingsIcon';
import ToolIcon from '../icons/ToolIcon';
import AddDomain from '../components/AddDomain';

import { Link as RouterLink } from 'react-router-dom';

import {
  Table,
  Grid,
  Card,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Tooltip,
  CircularProgress,
  Button
} from '@material-ui/core';

import mapIntegration from '../lib/implementationMap'


const useStyles = makeStyles((theme) => ({
  table: {
    // maxWidth: 440,
  },
  paper: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  cell: {
    wordWrap: 'break-word',
    padding: '16px'
  }
}));

const StyledTableRow = withStyles((theme) => ({
  root: {
    '& th:nth-of-type(even)': {
      backgroundColor: theme.palette.grey[200],
    },
    '& td:nth-of-type(odd)': {
      backgroundColor: theme.palette.grey[200],
    }
  },
}))(TableRow);




export default function DomainsTable( {canAddDomains, domains, deleteDomainHandler, state, gettingData, api, dispatch} ) {

  const classes = useStyles();
  

 

  return (
    <>
    {canAddDomains
          ?
          <Grid container className={classes.paper}>
          <AddDomain api={api} dispatch={dispatch} />
          </Grid>
          :
          null
          }
    <Grid container component={Card} className={classes.paper}>

    <TableContainer component='div'>
      <Table className={classes.table} aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell component="th" scope="row">Domain</TableCell>
            <TableCell align="center">Rate</TableCell>
            <TableCell>Deployment</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
    
          </TableRow>
        </TableHead>
        <TableBody >


        
          {domains.map((domain) => (

            !gettingData
            ?
          

            <StyledTableRow key={domain.id}>
              <TableCell component="th" scope="row" className={classes.cell} data-name={domain.name}>{domain.name}</TableCell>
              <TableCell align="center" className={classes.cell} >{domain.autotuneRate}/minute</TableCell>
              <TableCell align="left" className={classes.cell} >
              <Tooltip title="Deployment Settings" aria-label="Deployment Settings">
              <Button
              style={{textTransform:'none'}}
        color={mapIntegration[domain.deployment] ? "primary" : "secondary"}
        className={classes.button}
        component={RouterLink}
        to={`deployment-settings/${domain.id}`}
        startIcon={<ToolIcon>Deployment Settings</ToolIcon>}
      > {mapIntegration[domain.deployment]? mapIntegration[domain.deployment] : 'Set Deployment'}</Button>
</Tooltip>



                
                </TableCell>
              <TableCell align="center">
                  <Tooltip title="Domain Settings" aria-label="Domain Settings">
                  <IconButton
                  component={RouterLink}
                  to={`/domains/${domain.id}`}
                  
                >
                  <SettingsIcon color="secondary"/>
                  </IconButton>
                    </Tooltip>
                </TableCell>
                <TableCell align="center">

                  {state.plan.allowFirewall === 0
                  ?
                  <Tooltip title="Upgrade to Access Firewall" aria-label="Upgrade to Access Firewall">
                    <IconButton
                    ><ShieldIcon color="secondary"/></IconButton>
                  </Tooltip>
                  :
                  <Tooltip title="Manage IP Addresses" aria-label="Manage IP Addresses">
                    <IconButton
                      component={RouterLink}
                      to={`/domains/ips/${domain.id}`}
                    ><ShieldIcon color="secondary"/></IconButton>
                  </Tooltip>
                  }


                </TableCell>
                <TableCell  align="center">
                  <Tooltip title="Delete" aria-label="Delete Domain">
                  <IconButton aria-label="delete" onClick={() => {deleteDomainHandler(domain)}}>
                  <TrashIcon />
                  </IconButton>
                  </Tooltip>
                </TableCell>
              
            </StyledTableRow>
            :
            <StyledTableRow key={domain.id}>
              <TableCell component="th" scope="row" className={classes.cell}>
              <CircularProgress color="primary" size={24}/>
              </TableCell>
              <TableCell align="center" className={classes.cell} ></TableCell>
              <TableCell align="left" className={classes.cell} ></TableCell>
              <TableCell align="center"></TableCell>
              <TableCell align="center"></TableCell>
              <TableCell  align="center"></TableCell>
              
            </StyledTableRow>
              

          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </Grid>
    </>
  );
}
