import React from 'react';
import PropTypes from 'prop-types';
import { LoginHeader, GlobalFooter } from '../components';
import { Container } from '@material-ui/core';

const Login = props => {

    const { children, state } = props;

    return (
        <>
            <LoginHeader />
            <Container spacing={2}>
                {children}
            </Container>
            <GlobalFooter align="center" state={state} />

        </>
    )

}

Login.propTypes = {
    children: PropTypes.node
};

export default Login;