import React from 'react';
import {sortBy} from 'lodash';

import {
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from '@material-ui/core';


const RoomTemplates = ({ formik, templates, allowCustomTemplates }) => {
    return (
        <Grid item xs={12} className='ch-room-form-group'>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs>
                    {templates.length > 1
                        ?
                        <>
                            <FormControl fullWidth margin="dense" variant="standard">
                                <InputLabel id="template_id_label">Template</InputLabel>

                                <Select required labelId="template_id_label" id="templateID"
                                    {...formik.getFieldProps('templateID')}>

                                    {sortBy(templates, [t => t.name.toLowerCase()]).map((template) => {

                                        if (allowCustomTemplates.toString() === '1') {
                                            return <MenuItem key={template.id} value={template.id}>{template.name}</MenuItem>;
                                        } else {
                                            if (template.custom === 0) {
                                                return <MenuItem key={template.id} value={template.id}>{template.name}</MenuItem>;
                                            } else {
                                                return null;
                                            }
                                        }
                                    })}
                                </Select>
                            </FormControl>
                        </>
                        :
                        null
                    }
                </Grid>
            </Grid>
        </Grid>
    )
}

export default RoomTemplates;