import React from 'react';
import { ReactComponent as CreditCard } from './feather/credit-card.svg';

import { SvgIcon } from '@material-ui/core';


export default function CreditCardIcon(props) {
    return (
        <SvgIcon {...props} component={CreditCard} className="feather"/>
    );
  }