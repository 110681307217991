import React, { useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import EnhancedTableHead from './EnhancedTableHead';
import PlusCircleIcon from '../icons/PlusCircleIcon';
import HelpCircleIcon from '../icons/HelpCircleIcon';
import BlockPassEditRow from './BlockPassEditRow';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import {
  getComparator,
  stableSort
} from '../lib/tableComparators'

import {
  Grid,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  IconButton,
  FormControl,
  InputLabel,
  OutlinedInput,
  Select,
  MenuItem,
  CardContent,
  FormHelperText,
  Typography,
  Tooltip,
  Dialog,
  DialogContent,
} from '@material-ui/core';
import { DateTime } from 'luxon';



const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  cell: {
    wordWrap: 'break-word',
    padding: '0 8px',
  },
  addRow: {

    '& th, td': {
      paddingBottom: '20px',
      paddingTop: '10px',
      borderBottom: '1px solid #cacaca',

    }
  },
  paper: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },

}));

const useTableStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  cell: {
    wordWrap: 'break-word',
    whiteSpace: 'nowrap',
    padding: '0 4px',
    border: 'none'
  },
  iconcell: {
    whiteSpace: 'nowrap',
    padding: '0 2px',
    border: 'none'
  },
  reduce: {
    color: theme.palette.grey[400],
  },
  saveIcon: {
    '& svg': {
      strokeWidth: 1,
      color: theme.palette.grey[400],
    },
    '& svg:hover': {
      cursor: 'pointer'
    }
    
  },
  ipDataRow : {
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.primary.main
  },
  ipData : {
    marginRight: '10px',
  },
  requiredRed : {
    color: theme.palette.error.main,
  },
}));


const DomainIPAddressesTable = ({ addresses, initialOrder, addIPAddresses, updateIPAddress, deleteIPAddress, domainID, processing, state }) => {

  const classes = useStyles();
  const tableclasses = useTableStyles();


  let headCells = [
    { id: 'address', numeric: false, disablePadding: true, label: 'Address', align: 'left', colspan: 3 },
    { id: 'note', numeric: true, disablePadding: true, label: 'Note', align: 'center' },
    { id: 'sessions', numeric: true, disablePadding: true, label: 'Sessions', align: 'center' },
    { id: 'started', numeric: true, disablePadding: true, label: 'Connected', align: 'center' },
    { id: 'list', numeric: false, disablePadding: true, label: 'Status', align: 'center' },
    { id: 'pass', numeric: true, disablePadding: true, label: '', align: 'center' },
  ];

  const [orderBy, setOrderBy] = useState((initialOrder) ? initialOrder : 'sessions');
  const [order, setOrder] = useState('desc');
  const [openHelp, setOpenHelp] = useState(false);
  
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleHelpClose = () => {
    setOpenHelp(false)
  }
  const handleHelpOpen = () => {
    setOpenHelp(true)
  }  

  return (
    <Grid container component={Card}>
      {state.plan.allowFirewall === 1
      ?
      <>
      <Grid item xs={12} style={{ 'position': 'relative' }} >

        <CardContent>
          <TableContainer component='div'>

            <Table className={classes.table} size="small" aria-label="IP Addresses for the current domain">
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                headCells={headCells}
              />
              <TableBody>
                <TableRow className={classes.addRow}>
                  <BlockPassRow classes={tableclasses} addIPAddresses={addIPAddresses} domainID={domainID} handleHelpOpen={handleHelpOpen} />
                </TableRow>
                {stableSort(addresses, getComparator(order, orderBy))
                  .map((row, index) => (
                    <React.Fragment key={index}>
                    <TableRow >
                      <BlockPassEditRow
                        hasAnomalyDetection={state.hasAnomalyDetection}
                        processing={processing}
                        domainID={domainID}
                        deleteIPAddress={deleteIPAddress}
                        updateIPAddress={updateIPAddress}
                        classes={tableclasses}
                        row={row}
                        id={index}
                      />
                    </TableRow>
                    </React.Fragment>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Grid>

      <Dialog open={openHelp} onClose={handleHelpClose}>
        <DialogContent>

          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <Typography variant="h6" style={{marginBottom: '6px'}}>
                Ignore
              </Typography>
              <Typography paragraph={true}>
                IP will be treated as normal, will not be auto-blocked.
              </Typography>
            </Grid>

            <Grid item md={6} xs={12}>
              <Typography variant="h6" style={{marginBottom: '6px'}}>
                Block
              </Typography>
              <Typography paragraph={true}>

              IP will be blocked.
                        </Typography>
            </Grid>

            <Grid item md={6} xs={12}>
              <Typography variant="h6" style={{marginBottom: '6px'}}>
                Bypass
              </Typography>
              <Typography paragraph={true}>

              IP will bypass CrowdHandler.
                        </Typography>
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography variant="h6" style={{marginBottom: '6px'}}>
                Prioritize
              </Typography>
              <Typography paragraph={true}>

              IP will go to front of queue.
                        </Typography>
            </Grid>
          </Grid>


        </DialogContent>
      </Dialog>
      </>
      :
      null
        }

    </Grid>
  );
}

export default DomainIPAddressesTable;


const BlockPassRow = ({ classes, addIPAddresses, domainID, handleHelpOpen }) => {

  const theme = useTheme();

  const formik = useFormik({
    initialValues: {
      address: '',
      note: '',
      list: 'ignore'
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      address: Yup.string().required('Required'),
    }),
    onSubmit: async (values) => {

      let data = {
        ...values,
        domainID
      }
      formik.resetForm();
      addIPAddresses(data)
    },
  });

  return (
    <>
      <TableCell component="th" scope="row" className={classes.cell} colSpan="3">
        <Grid container>
          <Grid item xs>
            <FormControl fullWidth margin="dense" variant="outlined">
              <InputLabel htmlFor="address_id">IP Address</InputLabel>
              <OutlinedInput
                id="address_id-add"
                name="address"
                {...formik.getFieldProps('address')}
                labelWidth={68}
                placeholder="IP Address"
                aria-label="address"
                aria-describedby="address"
              />
            </FormControl>
            <FormHelperText>IP Address (CIDR Ranges supported) <span className={classes.requiredRed}>{formik.touched.address && formik.errors.address ? formik.errors.address : null}</span></FormHelperText>
            

          </Grid>
        </Grid>
      </TableCell>

      <TableCell align="right" className={classes.cell} >
        <Grid container>
          <Grid item xs>
            <FormControl fullWidth margin="dense" variant="outlined">
              <InputLabel htmlFor="note_id">Note</InputLabel>
              <OutlinedInput
                id="note_id-add"
                name="note"
                {...formik.getFieldProps('note')}
                labelWidth={36}
                placeholder="Note"
                aria-label="note"
                aria-describedby="note"
              />
            </FormControl>
            <FormHelperText>Optional note for easy identification</FormHelperText>
          </Grid>
        </Grid>
      </TableCell>

      <TableCell align="right" className={classes.cell} ></TableCell>
      <TableCell align="right" className={classes.cell} ></TableCell>
      <TableCell align="center" className={classes.cell} >

        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs>
            <FormControl variant="outlined" size="small" hiddenLabel fullWidth margin='dense'>
              <Select
                labelId="IP Address Status"
                id={`IP-Address-Status-Add`}
                value={formik.values.list}
                onChange={formik.handleChange('list')}
                size="small"
                labelWidth={0}
                displayEmpty
              >
                <MenuItem value="" disabled>
                  Select Status
                </MenuItem>
                <MenuItem value='ignore'>Ignore</MenuItem>
                <MenuItem value='block'>Block</MenuItem>
                <MenuItem value='pass'>Bypass</MenuItem>
                <MenuItem value='prioritize'>Prioritize</MenuItem>
              </Select>
            </FormControl>

          </Grid>
          
        </Grid>
      </TableCell>

      <TableCell align="right" className={classes.cell} >
        <IconButton onClick={() => handleHelpOpen()} size="small">
          <HelpCircleIcon />
        </IconButton>
       
          <Tooltip title="Add IP Address" aria-label="Add IP Address">

            <IconButton  size="small"  onClick={formik.handleSubmit}>
              <PlusCircleIcon style={{ strokeWidth: 2, color: theme.palette.secondary.main }} />
            </IconButton>
          </Tooltip>
        

      </TableCell>
    </>
  )

}

