import React, { useState, useEffect, useCallback } from "react";
import { DateTime } from 'luxon';
import { withRouter } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import XCircleIcon from '../../icons/XCircleIcon';
import UpgradeCTAPanel from '../../components/UpgradeCTAPanel'
import CalculateCurrentCapacityLimit from '../../lib/CalculateCurrentCapacityLimit';
import RedirectDialog from '../../components/RedirectDialog';
import RoomWRLink from '../../components/rooms/RoomWRLink';
import Config from '../../config'
import '../../components/Styles.css'
import Skeleton from '@material-ui/lab/Skeleton';
import { AddRoomActiveToRoom } from "../../lib";

import {
  Container,
  Grid,
  FormControl,
  OutlinedInput,
  FormHelperText,
  InputLabel,
  Button,
  Snackbar,
  IconButton,
  Paper,
  InputAdornment,
  Input,
  Typography,
  Backdrop,
  CircularProgress,
} from '@material-ui/core';

// Page Components
import { RoomTitle, RoomProtectUrls, RoomDateSelectors, RoomMessage, RoomTemplates, RoomStockAvailability, RoomCapacity } from "components/rooms";
import { HelpPopper } from '../../components';
import {AuthContext} from '../../providers/AuthProvider.js';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(6),
  },
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  noUnderline: {
    '& .MuiInput-underline:before': {
      display: 'none'
    },
    '& .MuiInput-underline:after': {
      display: 'none'
    }
  },
  smallmargin: {
    marginBottom: theme.spacing(2),
  },
  withoutLabel: {
    marginTop: theme.spacing(6),
  },
  domainAdornment: {
    marginRight: 0
  },
  regexField: {
    height: '16px'
  },
  regexFieldInput: {
    alignItems: 'flex-start'
  },
  spinner: {
    marginLeft: 22,
    color: theme.palette.secondary
  },
  slugAdornment: {
    marginRight: '3px'
  },
  countdownStartsOn: {
    paddingRight: 0,
    [theme.breakpoints.up('lg')]: {
      paddingRight: 14,
    },
  },
  patternMatchInput: {
    marginTop: 0,
    paddingTop: 0,
    '& .MuiFormControl-marginDense' : {
      marginTop: 0,

    }
  },
  helpertext_error : {
    
    color: 'red'
  
},
  callout: {
    border: '1px solid',
    borderColor: theme.palette.secondary.main,
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(1),
  },
  
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.primary.main,
    // backgroundColor: '#fff',
  },
  section : {
    backgroundColor: '#ffffff',
    '&:nth-child(even)' : {
      backgroundColor: '#f8f8f8',
    },
    '& .ch-room-form-group' : {
      marginBottom: theme.spacing(4),
    },
    '& .ch-room-form-group:last-of-type' : {
      marginBottom: theme.spacing(2),
    }
  },
  sectionAlt : {
    backgroundColor: '#ffffff',
  },

}));

const now = DateTime.local();





const AddRoomAmend = ({ state, computedMatch, history, api, ...rest }) => {


  const classes = useStyles();

  let popperEl=React.useRef(null);
  const [showPopper, setShowPopper] = useState(true);
  const action = computedMatch.params.action;
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [canAddRoom] = useState(true);
  const { dispatch } = rest;
  const [domainId] = useState(computedMatch.params.action === 'add' ? computedMatch.params.id : false);
  const [initialCapacity] = useState(0);
  const [usedCapacity, setUsedCapacity] = useState(0);
  const [calculatingCapacity, setCalculatingCapacity] = useState(true);
  const [availableRoomCapacity, setAvailableRoomCapacity] = useState(0);
  const [, setMaxRoomCapacity] = useState(0);

  const [openRedirectDialog, setOpenRedirectDialog] = useState(false);
  const [followedRedirectUrl, setFollowedRedirectUrl] = useState();
  const [redirectType, setRedirectType] = useState(1);
  const [saveRoomValues, setSaveRoomValues] = useState();
  const [displayStockLevels, setDisplayStockLevels] = useState(true);
  const [roomData, setRoomData] = useState(false);
  const [savingRoom, setSavingRoom] = useState(false);
  const [loadingRoom, setLoadingRoom] = useState(action === 'edit' ? true: false);
  const [capacityStep, setCapacityStep] = useState(100);
  const [displayCapacitySlider, setDisplayCapacitySlider] = useState(false);
  const [roomFound, setRoomFound] = useState(true);
  const [roomActiveState, setRoomActiveState] = useState();
  const [customerID, setCustomerId] = useState();


  const upgradeMessage = 'You have filled your available room allocation. Upgrade to get extra rooms';
  
  const [domain, setDomain] = useState();
  const [dateInfo, setDateInfo] = useState({
    countdownStartsOn: {
      date: DateTime.utc().startOf('minute')
    },
    queueActivatesOn: {
      date: DateTime.utc().startOf('minute'),
    }
  });


  let fromDashboardOnboarding;
  let addRoomOnboarding;

  if (history.location && history.location.state) {
    if (history.location.state.from === 'dash' && history.location.state.action === 'activate_a_room') {
      fromDashboardOnboarding = true;
    }
    if (history.location.state.from === 'dash' && history.location.state.action === 'add_a_room') {
      addRoomOnboarding = true;
    }
  }

  // default settings
  const [settings, setSettings]= useState({
    title: "Waiting Room",
    message: "",
    urlRedirect: "/",
    urlPattern: "",
    capacity: 0,
    domainID: domainId,
    stock: null,
    stockLevel: 0,
    displayStock: false,
    patternType: 'all',
    templateID: '',
    slug: '',
    method: action === 'edit' ? 'put': 'post'
  });

  useEffect(() => {
    const getData =  async () => {
      let domains = await api.getDomains();
      dispatch({ type: 'ADD_DOMAINS', payload: domains });
      let rooms = await api.getRooms();
      dispatch({ type: 'UPDATE_ROOMS', payload: rooms });
      let templates = await api.getTemplates();
      if (templates) {
        dispatch({ type: 'UPDATE_TEMPLATES', payload: templates });
      }
    }

    let customTemplates = 0;
    if (state.plan) {
     customTemplates = state.plan.allowCustomTemplates;
    }

    if (customTemplates === 1) {
      setDisplayStockLevels(true);
    } else {
      setDisplayStockLevels(false);
    }

    getData();
 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(state.accountInformation) {
      setCustomerId(state.accountInformation.get("customer").id)
    }
  }, [state.accountInformation]);


  const handleRoomsCapacityChange = (event, newValue) => {
    if (newValue > (state.plan.maxWaitingRoomCapacity - usedCapacity + initialCapacity)) {
      newValue = state.plan.maxWaitingRoomCapacity - usedCapacity + initialCapacity
    }
    formik.setFieldValue('capacity', newValue);
    calculateCurrentCapacityLimit(roomData);
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };


  // FORMIK FORM INSTANCE
  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    initialValues: {
      ...settings
    },
    validationSchema: Yup.object({
      title: Yup.string().required('Required'),
      message: Yup.string().max(512, 'Maximum number of characters met'),
      stockLevel: Yup.string().when("displayStock", {
        is: (patternType) => {
          let validate = true;

          if (patternType) {
            validate = true;
          }
          if (!patternType) {
            validate = false;
          }
          return validate;

        },
        then: Yup.string().test('contains-domain', 'Stock Error', 
          function (value) {
            const { path, createError } = this;
            if (value < 0) {
              value = 0;
              formik.values.stockLevel = 0;
            }
            if (!value && value !== 0) {
              return createError({path, message: 'Stock is required'});
            } 
            return true;
          })
        
      }),
      slug: Yup.string()
      .when([], {
        is: () => action === 'edit',
        then: Yup.string().nullable().required('Required')
        .test(
          {
            name: 'slug-spaces',
            exclusive: true,
            message: 'Spaces are not allowed',
            test: (value) => {
  
              if(value) {
                var inValid = /\s/;
                var k = inValid.test(value);
                return !k;
              }
            },
          })
          .test(
            {
              name: 'slug-slashes',
              exclusive: true,
              message: 'Forward slashes are not allowed',
              test: (value) => {
  
                if(value) {
                  let res = value.indexOf('/');
                  if (res > -1) {
                    return false;
                  } else {
                    return true;
                  }
                }
              },
            }),
      }),
      urlRedirect: Yup.string().test('test-slash', 'Redirect needs to start with a forward slash', 
        function(value) {
            return value && value[0].indexOf('/') !== -1;
        }).required('Required')
        .test('test-whitespace', 'Spaces are not allowed',
      function(value){
        var inValid = /\s/;
        var k = inValid.test(value);
        return !k;
      }),
        urlPattern: Yup.string().when("patternType", {
          is: (patternType) => {
            let validate = true;

            if (patternType === 'all') {
              validate = false;
            }
            if (patternType === 'disabled') {
              validate = false;
            }
            return validate;

          },
          then: Yup.string().test('contains-domain', 'Do not include your domain', 
            function(value) {
              const { path, createError } = this;
              if (!value) {
                return createError({path, message: 'This is required'});
              } 
              let hasDomainInValue = (value.indexOf(`${domain.name}`) === -1) ? false : true;
              if (hasDomainInValue) {
                return createError({path, message: 'Do not include your domain'});
              }
              return true;
            })
          
        })
    }),
    onSubmit: async (values) => {

      setSavingRoom(true);

      // check that we are using a local domain
      let local = domain.name.indexOf('//127.0.0.1') > -1 || domain.name.indexOf('//localhost') > -1;

      if (domain && domain.deployment === 'javascript' && !local) {
        
        let result = await checkRedirect(values.urlRedirect.trim());
        if (result.response === 'error') {
          setRedirectType(2);

          if (result.error.statusText === 'REDIRECTLOOP') {
            setRedirectType(3);
          }

          if (result.error.statusText === 'TIMEOUT_ERROR') {
            setRedirectType(4);
          }

          if (result.error.statusText === 'ENOTFOUND') {
            setRedirectType(5);
          }


          setSaveRoomValues(values);
          setFollowedRedirectUrl(values.urlRedirect.trim())
          setOpenRedirectDialog(true);
          setSavingRoom(false);
          return false;
        } else if (result.redirects.length > 1 && values.urlRedirect.trim() !== result.response.redirectUrl.trim()) {
          setFollowedRedirectUrl(result.response.redirectUrl.trim())
          setRedirectType(6)
          setSaveRoomValues(values);
          setOpenRedirectDialog(true);
          setSavingRoom(false);
          return false;
        }

        

      
      }

    
      saveRoom(values)

    }
  });

  const calculateCurrentCapacityLimit = useCallback((room_data) => {

    if(!savingRoom) {
      let max_capacity = state.plan.maxWaitingRoomCapacity;
      let available_room_capacity = CalculateCurrentCapacityLimit(state.rooms, max_capacity, room_data ? room_data.capacity: 0);
      setUsedCapacity(() => {
          return state.plan.maxWaitingRoomCapacity - available_room_capacity
        }
      );
        
      setAvailableRoomCapacity(() => {
          return available_room_capacity
        }
      );
      setMaxRoomCapacity(() => {
        return available_room_capacity
      });
      
      setCalculatingCapacity(false);

  
      return available_room_capacity
    }
  
    
  },[state.plan.maxWaitingRoomCapacity, state.rooms, savingRoom]);

  
  const saveRoom = async (values) => {

      let dataToSave = {
        queueActivatesOn: DateTime.fromISO(dateInfo.queueActivatesOn.date, { zone: 'utc' }).toISO(),
        countdownStartsOn: DateTime.fromISO(dateInfo.countdownStartsOn.date, { zone: 'utc' }).toISO(),
        domainID: values.domainID,
        title: values.title,
        urlRedirect: values.urlRedirect,
        urlPattern: values.urlPattern,
        message: values.message,
        templateID: values.templateID,
        patternType: values.patternType,
        capacity: values.capacity,
        stock: (values.displayStock) ? parseInt(values.stockLevel, 10) : null,
      };
      
      let extraFieldsForUpdate = {}

      if(action === 'edit') {
        extraFieldsForUpdate.id = roomData.id;
        extraFieldsForUpdate.slug = values.slug;
      }

      let data = {
        ...dataToSave,
        ...extraFieldsForUpdate
      }
  
      let result = await api.addRoom(data, action ==='add' ? 'post' : 'put');
      if (result.error) {
        
          if (result.error.indexOf('regular expression') !== -1) {
            const capitalized = result.error.trim().charAt(0).toUpperCase() + result.error.trim().slice(1);
            setErrorMessage(capitalized);
          } else {
            setErrorMessage('There has been a problem adding your room');
          }
        
        setOpen(true);
        setSavingRoom(false);
      } else {
        dispatch({ type: 'UPDATE_ROOMS', payload: result });
        // await checkAutotune(result);
        setSavingRoom(false);
        history.goBack();
      }

  }

  // setting the capacity step based on the availably room capacity
  useEffect(() => {
    let step = Math.round((availableRoomCapacity/100) / 10) * 10;

    if (step < 1) {
      step = 1;
    }
    
    setCapacityStep(step)

  }, [availableRoomCapacity] );

  const handleRedirectDialogClose = (event, reason) => {

    if (reason === 'backdropClick') {
      return false;
    }
    setOpenRedirectDialog(false);
  }

  const handleRedirectDialogAgree = () => {
    setOpenRedirectDialog(false);
    saveRoom(saveRoomValues);
  }

  const handleRedirectDialogCancel = () => {
    setOpenRedirectDialog(false);
  }

  const checkRedirect = async (urlRedirect) => {


    let result = await api.checkRedirect(
      urlRedirect,
      domain.name
    );

    return result;
  }

  const setEditRoom = useCallback((room_id) => {

    try {
      let room_data = state.rooms.reduce((acc, room) => {
        if (room.id === room_id) {
          acc = room;
        }
        return acc;
      }, undefined);

      if (!room_data) {
        setRoomFound(false);
        return false;
      }

      let displayStock = false;
      let stockLevel = 0;

      if (!isNaN(parseInt(room_data.stock, 10))) {
        displayStock = true;
        stockLevel = parseInt(room_data.stock, 10);
      }


      let isActive = AddRoomActiveToRoom([room_data]);

      setRoomActiveState({
        queueActivatesIsActive: isActive[0].queueActivatesIsActive,
        countdownIsActive: isActive[0].countdownIsActive,
        protectingURLs: isActive[0].patternType !== 'disabled'
      })

      let domain = state.domains.filter((domain) => {
        return domain.id === room_data.domainID;
      });

      calculateCurrentCapacityLimit(room_data);
      setSettings((prev) => {
        return {
          ...prev,
          ...room_data,
          displayStock,
          stockLevel,
        }
      });

      if (state.rooms.length > 1) {
        setDisplayCapacitySlider(true);
      } else if ( (state.rooms.length < 2) && ((availableRoomCapacity - room_data.capacity) < 1)) {
        setDisplayCapacitySlider(false);
      }  else if ( (state.rooms.length < 2) && ((availableRoomCapacity - room_data.capacity) < availableRoomCapacity)) {
        setDisplayCapacitySlider(true);
      }

      setRoomData(room_data)

      setDateInfo(() => {
        return {
        countdownStartsOn: {
          date: DateTime.fromISO(room_data.countdownStartsOn)
        },
        queueActivatesOn: {
          date: DateTime.fromISO(room_data.queueActivatesOn)
        }
      }})

      setDomain((prev)=>{
        return {
          ...prev,
        ...domain[0]
        }
      })
    } catch (error) {
      console.log(error);
    }

    

  },[availableRoomCapacity, calculateCurrentCapacityLimit, state.domains, state.rooms]);

  const setAddRoom = useCallback((domain_id) => {

    let domain = state.domains.filter((domain) => {
      return domain.id === domain_id;
    });

    setDomain((prev)=>{
      return {
        ...prev,
      ...domain[0]
      }
    });

    
    let room_data = {
      capacity: 0,
      domainID: domain[0].id,
      method: 'post'
    }
    let available_room_capacity;
    let capacity

    if (state.rooms.length < 1) {
      capacity = state.plan.maxWaitingRoomCapacity;
      setAvailableRoomCapacity(capacity);
      setCalculatingCapacity(false);
      setDisplayCapacitySlider(false)
    } else {
      available_room_capacity  = calculateCurrentCapacityLimit(room_data);
      capacity = Math.round(available_room_capacity/2);
      setDisplayCapacitySlider(true)
    }
    setSettings((prev) => {
      return {
        ...prev,
        ...room_data,
        capacity
      }
    });


  },[calculateCurrentCapacityLimit, state.domains, state.plan.maxWaitingRoomCapacity, state.rooms]);


  useEffect(() => {
    
    if(state.templates) {

      let templates = state.templates.filter((template) => {
        return template.custom === 0;
      });

      setSettings((prev) => {
        return {
          ...prev,
          templateID: templates[0].id
        }
      });
    }
  }, [state.templates]);

  useEffect(() => {
    const populateSettings = async () => {
      
      if(action === 'add') {
        setAddRoom(computedMatch.params.id)
        return false;
      } else if (action === 'edit') { // Populate settings when in edit mode
        setEditRoom(computedMatch.params.id)
      }
      setLoadingRoom(false);
    };


  if (state.rooms && state.templates && state.domains) {

    populateSettings();
  }

}, [action, computedMatch.params.id, setAddRoom, setEditRoom, state.domains, state.rooms, state.templates]);


const [slugChangeMessage, setSlugChangeMessage] = useState();

  useEffect(() => {

    const checkSlugChange = () => {
      let room_data = roomData;

      if (room_data.slug !== formik.values.slug) {

        setSlugChangeMessage('Changing the URL when a room is active will break status updates for anyone in that room')
      } else {
        setSlugChangeMessage('')
      }

    }

    if (roomData && formik.values.slug && action === 'edit') {
      checkSlugChange();
    }


  }, [action, formik.values.slug, roomData])


  const calulateActiveState = useCallback (() => {

    if(roomData && dateInfo.queueActivatesOn.date && dateInfo.countdownStartsOn.date) {

      let room_data = {
        ...roomData,
        patternType : formik.values.patternType,
        queueActivatesOn: dateInfo.queueActivatesOn.date.toUTC().toISO(),
        countdownStartsOn: dateInfo.countdownStartsOn.date.toUTC().toISO(),
      }
      let isActive = AddRoomActiveToRoom([room_data]);
      setRoomActiveState({
        queueActivatesIsActive: isActive[0].queueActivatesIsActive,
        countdownIsActive: isActive[0].countdownIsActive,
        protectingURLs: isActive[0].patternType !== 'disabled'
      })
    }
    
  },[roomData, formik.values.patternType, dateInfo])
  


  useEffect(() => {
    calulateActiveState(formik.values);
  }, [calulateActiveState, formik.values]);


  return (


    roomFound ?
    <div className={classes.root}>

      {!loadingRoom
        ?
        <Container
          maxWidth="md"
          spacing={4}
        >

          {
            (!canAddRoom)
              ?
              <UpgradeCTAPanel upgradeMessage={upgradeMessage} />
              :
              null
          }
         

          {
            (canAddRoom || domainId ) 
              ?
              <Grid container spacing={4} component={Paper} className={classes.paper}>


                
                <Grid item xs={12} className={`${classes.section}`} component="section">
                  <RoomTitle formik={formik} />
                </Grid>

                <Grid item xs={12} className={`${classes.section}`} component="section">

                  {/* <RoomsStatus roomActiveState={roomActiveState}/> */}


                {/* {popperEl.current && fromDashboardOnboarding ?
                  <HelpPopper message={
                    <Grid>
                      <Typography>Change Protect URLs to <strong>All of {domain.name}</strong> and Save your room</Typography>
                    </Grid>
                  } showPopper={showPopper} anchorEl={popperEl.current} setShowPopper={setShowPopper}/>
                : null} */}

                  {!domain?<Skeleton height={86}/>:
                  <RoomProtectUrls ref={ fromDashboardOnboarding ? popperEl : null} formik={formik} domain={domain}/>
                  }
                  
                  <RoomDateSelectors customerID={customerID} calulateActiveState={calulateActiveState} setRoomData={setRoomData} roomActiveState={roomActiveState} roomData={roomData} setDateInfo={setDateInfo} dateInfo={dateInfo} type={action} patternType={formik.values.patternType}/>
                </Grid>

                <Grid item xs={12} className={`${classes.section}`} component="section">
                  <RoomMessage formik={formik} />
                  {state.templates && state.templates.length > 1 ?
                  <RoomTemplates formik={formik} templates={state.templates ? state.templates : []} allowCustomTemplates={state.plan.allowCustomTemplates} />
                  :
                null
                }
                <RoomStockAvailability formik={formik} displayStockLevels={displayStockLevels} />
                </Grid>

                {action === 'edit' || displayCapacitySlider ?
                  
                <Grid item xs={12} className={`${classes.section}`} component="section">
                  {calculatingCapacity && displayCapacitySlider ?
                  <Typography>Calculating Available Capacity</Typography>
                  :
                  displayCapacitySlider ? 
                  <RoomCapacity
                  formik={formik}
                  availableRoomCapacity={availableRoomCapacity}
                  numberOfRooms={state.rooms ? state.rooms.length : undefined}
                  capacityStep={capacityStep}
                  handleRoomsCapacityChange={handleRoomsCapacityChange}
                  domainId={settings.domainID}
                  action={action}
                  />
                  : null
                  }


                  {action === 'edit'
                  ?
                 <>
                 
                  <Grid className='ch-room-form-group'>
                    <FormControl fullWidth margin="normal" variant="outlined">
                      <InputLabel htmlFor="title">Waiting room slug</InputLabel>
                      <OutlinedInput
                        id="slug"
                        style={{backgroundColor: '#fff'}}
                        {...formik.getFieldProps('slug')}
                        labelWidth={110}
                        placeholder="<URL>"
                        aria-label="URL"
                        startAdornment={<InputAdornment position="start" className={classes.slugAdornment}>{`${Config.CH_WR_ENDPOINT}`}</InputAdornment>}
                        endAdornment={
                          
                         
                            <InputAdornment position="end" >
                              <RoomWRLink slug={formik.values.slug} api={api} countDown={dateInfo.countdownStartsOn.date}/>
                            </InputAdornment>


                        }
                      />
                      {formik.touched.slug && formik.errors.slug ? <Typography variant="subtitle2" color="error">{formik.errors.slug}</Typography> : null}
                    </FormControl>
                    {slugChangeMessage
                    ?
                    <FormHelperText>{slugChangeMessage}</FormHelperText>
                    :
                    null}
                  </Grid>
                  <Grid className='ch-room-form-group'>
                    <FormControl fullWidth margin="normal" variant="outlined">
                      <InputLabel htmlFor="title">Redirect users to</InputLabel>
                      <OutlinedInput
                        style={{backgroundColor:'#fff'}}
                        id="urlRedirect"
                        {...formik.getFieldProps('urlRedirect')}
                        labelWidth={106}
                        aria-label="URL Redirect"
                        required
                        startAdornment={<InputAdornment position="start" className={classes.slugAdornment}>{`${(domain) ? `${domain.name}` : ''}`}</InputAdornment>}
                      />
                      {formik.touched.urlRedirect && formik.errors.urlRedirect ? <Typography variant="subtitle2" color="error">{formik.errors.urlRedirect}</Typography> : null}
                      <FormHelperText>Users who join the waiting room directly will be redirected to this URL. Users sent to the waiting room from your site are redirected back to the url they requested.</FormHelperText>
                    </FormControl>
                  </Grid>
                  </>
                  :null}

                </Grid>
                : null}

                

                

                {(computedMatch.params.domainId)
                  ?
                  <Input name="domainID" type="hidden" value={computedMatch.params.domainId} />
                  :
                  null
                }


                <Grid item xs={12} align="right">
                  <Button
                    disableElevation
                    color="primary"
                    onClick={history.goBack}>
                    Cancel</Button>

                    {popperEl.current && addRoomOnboarding ?

                    <HelpPopper message={
                      <Grid>
                        <Typography>Create your first room!</Typography>
                      </Grid>
                    } showPopper={showPopper} anchorEl={popperEl.current} setShowPopper={setShowPopper}/>
                    : null}
                  <Button
                    ref={ addRoomOnboarding ? popperEl : null}
                    className={addRoomOnboarding ? 'button_anim' : ''}
                    style={{ marginLeft: 8 }}
                    disableElevation
                    variant="contained"
                    color="secondary"
                    disabled={!formik.isValid || !domain}
                    onClick={formik.handleSubmit}>
                    {action==='add' ? 'Create' : 'Save'} Room</Button>
                </Grid>
              </Grid>

              :
              null
          }
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            message={errorMessage}
            action={
              <>

                <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                  <XCircleIcon fontSize="small" />
                </IconButton>
              </>
            }
          />

          <Backdrop className={classes.backdrop} open={savingRoom}>
            <Grid container justifyContent="center" alignItems="center" direction="column" spacing={4}>
              <Grid item>
                <CircularProgress color="inherit" />
              </Grid>
            </Grid>
          </Backdrop>

          <RedirectDialog
          domain={domain}
          openRedirectDialog={openRedirectDialog}
          handleRedirectDialogClose={handleRedirectDialogClose}
          onClose={handleRedirectDialogClose}
          followedRedirectUrl={followedRedirectUrl}
          handleRedirectDialogAgree={handleRedirectDialogAgree}
          domainId={(domain) ? domain.id : null}
          redirectType={redirectType}
          handleRedirectDialogCancel={handleRedirectDialogCancel}

        
          />

        </Container>
        :
        <Grid container>
          <Grid><Typography>LOADING</Typography></Grid>
        </Grid>
      }
    </div>
    : 
    <Typography>Room not found</Typography>
  );
};

export default withRouter(AddRoomAmend);


