import React from 'react'
import { Typography, Grid, FormControl, InputLabel, OutlinedInput, Button } from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    verificationPanel: {
        marginBottom: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    }
}));

const RequestVerificationLink = ({username, handleFields, resetTempPassword, header, message}) => {

    const classes = useStyles();

    return(

        <Grid container className={classes.verificationPanel}>
                    <Grid item xs={12}>
                        <Typography variant="h6" align="center" style={{
                            marginBottom: '12px'
                        }}>
                            {header ? header : 
                            `Your login link has expired. Please request a new one.`
                            }
                        </Typography>

                        {message ? message : null}

                        <Grid item xs={12}>
                            <FormControl fullWidth margin="normal" variant="outlined">
                                <InputLabel htmlFor="username">Username</InputLabel>
                                <OutlinedInput
                                    type="text"
                                    id='username'
                                    name='username'
                                    value={username}
                                    onChange={(event) => handleFields(event)}
                                    labelWidth={63}
                                    placeholder="Username"
                                    aria-label="Username"
                                    aria-describedby="username"
                                ></OutlinedInput>
                            </FormControl>
                        </Grid>


                        <Button
                             variant="contained"
                             size="small"
                             disableElevation
                             color="secondary"
                        onClick={() => {
                            resetTempPassword();
                        }}>Request a new link</Button>
                    </Grid>
                </Grid>
    )
}

export default RequestVerificationLink;
