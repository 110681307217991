import { createHash }  from 'crypto-browserify';
import difference from 'lodash/difference';
import uniq from 'lodash/uniq';


/**
 * Returns an array of unique, randomly generated md5 hashes
 * @param {array} existingCodes 
 * @param {number} amountToGenerate 
 * @param {array} codes 
 * @param {number} extras 
 */
export const codeGenerator = (existingCodes, amountToGenerate, codes, extras) => {

    let quantity = extras || amountToGenerate;

    for (let index = 0; index < quantity; index++) {
        let rand = (Date.now() + getRandomArbitrary(1, 1000000)).toString();
        codes.push(
            createHash('md5').update(rand).digest('hex')
        )
    }

    let u_codes = uniq(codes, true);
    u_codes = filterDuplicatesFromExistingCodes(existingCodes, u_codes);
    let missing = amountToGenerate - u_codes.length;

    if (missing > 0) {
        codeGenerator(existingCodes, amountToGenerate, u_codes, missing)
    }

    return u_codes;

}

/**
 * Checks the new codes vs the existing codes to 
 * determine if there are any duplicates.
 * @param {array} existingCodes 
 * @param {array} newCodes 
 */
const filterDuplicatesFromExistingCodes = (existingCodes, newCodes) => {
    let diff = difference(newCodes, existingCodes);
    return diff;
}

/**
 * Returns a random  umber between min & max values
 * @param {number} min 
 * @param {number} max 
 */
const getRandomArbitrary = (min, max) => {
    return Math.floor(Math.random() * (max - min) + min);
}

/**
 * Return a single md5 code
 */
export const generateSingleCode = () => {
    let rand = (Date.now() + getRandomArbitrary(1, 1000000)).toString();
    let code = createHash('md5').update(rand).digest('hex');
    return code;
}

