import {
  unstable_createMuiStrictModeTheme as createMuiTheme,
} from '@material-ui/core/styles';

// import globals from './globals';
// import palette from './palette';
// import typography from './typography';
import overrides from './overrides';


import MainTheme from './MainTheme';

MainTheme.overrides = overrides;

const theme = createMuiTheme(MainTheme);

export default theme;
