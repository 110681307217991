import React, { useState, useRef, useContext, useEffect } from 'react';
import {
    Grid,
    OutlinedInput,
    FormHelperText,
    FormControl,
    InputLabel,
    Button,
    Typography,
    Input,
    Backdrop,
    CircularProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { PlusCircleIcon, PaperClipIcon } from '../icons';
import { v4 as uuidv4 } from 'uuid';
import Config from '../config';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import * as AWS from 'aws-sdk/global';
import { FetchHttpHandler } from "@smithy/fetch-http-handler";
import { PutObjectCommand, S3Client } from "@aws-sdk/client-s3";
import {AuthContext} from '../providers/AuthProvider';
import ErrorSnackBar from './ErrorSnackBar';


const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }));


const NewTemplateUploadForm = ({ templates, dispatch, customer, api, maxFileSize }) => {

    
    const chAuth = useContext(AuthContext);

    const classes = useStyles();

    let [showLoader, setShowLoader] = useState(false);
    let [templateFile, setTemplateFile] = useState();
    let [message, setMessage] = useState('');
    let [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);

    let fileInput = useRef();

    const closeErrorSnackbar = () => {
        setOpenErrorSnackbar(false)
    }


    const handleTemplateFileUpdate = (target) => {
        if (target.files.length) {
            let file  = target.files[0]; 
            setTemplateFile(file);
            formik.setFieldValue('newTemplate', file.name, true)
            formik.setFieldValue('fileSize', file.size, true)
            
            if (!formik.values.customTemplateUploadName) {
                formik.setFieldValue('customTemplateUploadName', file.name, false)
            }

        } else {
            setTemplateFile(undefined);
            formik.setFieldValue('newTemplate', undefined)
        }
    }

    const formik = useFormik({
        initialValues: {
            customTemplateUploadName: '',
            newTemplate: '',
            fileSize : 0,
        },
        validateOnChange: true,
        validationSchema: Yup.object().shape({
            customTemplateUploadName: Yup.string()
                .required('Required')
                .test('textExists', 'This name already exists. Please provide a unique name', (value) => {                    
                    let fileNames = templates.reduce((acc, template) => {
                        acc.push(template.name)
                        return acc;
                    }, []);

                    let doesExist = fileNames.indexOf(value);
                    if (doesExist > -1) {
                        return false;
                    }
                    return true;
                }),
            newTemplate: Yup.string().required('Required'),
            fileSize: Yup.number()
            .test('testFileSize', 'File size exceeds limit', function(value) {
              
                if (value && value > maxFileSize) {
                    return false;
                } else {
                    return true
                }
                
            }),
        }),

        onSubmit: async (values) => {

            setShowLoader(true);

            if (templateFile && templateFile.size > maxFileSize) {
                setMessage('File size exceeds limit')
                setOpenErrorSnackbar(true);
                setShowLoader(false);
                return false;
            }

            let Key = `public/${customer.id}/${uuidv4()}.html`;
            const {session} = await chAuth.handleCurrentSession();

            if (session && session.isValid()) {
                AWS.config.credentials.get(error => {
                    if (error) {
                        console.error(error);
                        setMessage('There was an error uploading your template. Please try again')
                        setOpenErrorSnackbar(true);
                        setShowLoader(false);
                    } else {
                        // Instantiate aws sdk service objects now that the credentials have been updated.
                        
                        const AccessKeyId = AWS.config.credentials.data.Credentials.AccessKeyId,
                            SecretKey = AWS.config.credentials.data.Credentials.SecretKey,
                            SessionToken = AWS.config.credentials.data.Credentials.SessionToken;
    
                        const creds = {
                            accessKeyId: AccessKeyId,
                            secretAccessKey: SecretKey,
                            sessionToken: SessionToken
                        };
    
                        const s3Client = new S3Client({
                            region: 'us-east-1',
                            credentials: creds,
                            requestHandler: new FetchHttpHandler({ keepAlive: false }),
                        });
    
                        const callPut = async () => {
                            const command = new PutObjectCommand({
                                Bucket: Config.AMPLIFY.Storage.S3.bucket,
                                Key,
                                Body: templateFile,
                                ContentType: 'text/html'
                            });
    
                            try {
                                const response = await s3Client.send(command);    
                                if (response && response.$metadata.requestId) {
                                    let data = {
                                        url: `https://${Config.AMPLIFY.Storage.S3.bucket}.s3.amazonaws.com/${Key}`,
                                        name: values.customTemplateUploadName
                                    }
                                    const addTemplate = async (data) => {
                                        let response = await api.addTemplate(data, 'post');
                                        if (response)
                                            dispatch({ type: 'UPDATE_TEMPLATES', payload: response });
                                    }
                                    formik.resetForm();
                                    addTemplate(data);
                                    setShowLoader(false);
                                }
                            } catch (error) {
                                console.log(error);
                                setMessage('There was an error uploading your template. Please try again')
                                setOpenErrorSnackbar(true);
                                setShowLoader(false);
                            }
                        }
    
                        callPut();
    
                        
                    }
            });
            }

        },
    });

    return (
        <>
            <Grid item xs={8} >
                <FormControl fullWidth margin="dense" variant="outlined">
                    <InputLabel required htmlFor="customTemplateName">Template Name</InputLabel>
                    <OutlinedInput
                        id="customTemplateUploadName"
                        name="customTemplateUploadName"
                        labelWidth={106}
                        {...formik.getFieldProps('customTemplateUploadName')}
                        placeholder="Name"
                        aria-label="customTemplateUploadName"
                        aria-describedby="customTemplateUploadName"
                    />
                    <FormHelperText>The name will be pre populated from the first html file you select</FormHelperText>
                    {(formik.touched.customTemplateUploadName && formik.errors.customTemplateUploadName) ? <Typography variant="subtitle2" color="error">{formik.errors.customTemplateUploadName}</Typography> : null}

                </FormControl>

            </Grid>
            <Grid item xs={4}>
                <FormControl fullWidth margin="dense">
                    <label htmlFor="template-upload">
                        <Button fullWidth component="span" disableElevation size="small" variant="contained" color="secondary"
                            endIcon={<PaperClipIcon />}
                        >Select HTML file</Button>
                    </label>
                    <input accept="text/html"
                        ref={fileInput}
                        id='template-upload'
                        type="file"
                        onChange={(event) => {
                            handleTemplateFileUpdate(event.currentTarget);
                        }}
                        name="newTemplate"
                        style={{ 'display': 'none' }}
                    />
                    <FormHelperText>{formik.values.newTemplate ?  formik.values.newTemplate : null}</FormHelperText>
                    <Typography>Maximum file size: 500Kb</Typography>
                    {(formik.touched.newTemplate && formik.errors.newTemplate) ? <Typography variant="subtitle2" color="error">{formik.errors.newTemplate}</Typography> : null}
                    {(formik.errors.fileSize) ? <Typography variant="subtitle2" color="error">{formik.errors.fileSize}</Typography> : null}
                </FormControl>

                <Input type="hidden" {...formik.getFieldProps('fileSize')}/>
            </Grid>

            <Grid item xs={12} align="right">
                <Button component="span" disableElevation size="small" variant="contained" color="secondary"
                    onClick={formik.handleSubmit}
                    disabled={( !formik.values.customTemplateUploadName || (!templateFile) || formik.errors.customTemplateUploadName || !formik.isValid) ? true : false}
                    // style={{ color: (formik.errors.newTemplate || formik.errors.customTemplateUploadName) ? theme.palette.grey[200] : theme.palette.common.white }}
                    endIcon={<PlusCircleIcon />}>Add</Button>

            </Grid>

            <ErrorSnackBar message={message} open={openErrorSnackbar} closeErrorSnackbar={closeErrorSnackbar} />
            <Backdrop className={classes.backdrop} open={showLoader}>
                <Grid container justifyContent="center" alignItems="center" direction="column" spacing={4}>
                    <Grid item>
                        <CircularProgress color="inherit" />
                    </Grid>
                </Grid>
            </Backdrop>
        </>
    )

}

export default NewTemplateUploadForm;