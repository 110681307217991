import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useStripe } from '@stripe/react-stripe-js';
import Config from '../../config'
import { DateTime } from 'luxon';
import { CheckIcon, XIcon } from '../../icons';

import {
    Typography,
    Grid,
    Chip,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Button,
    Card,
    CardContent
} from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    card: {
        marginBottom: theme.spacing(2),
        width: '100%'
    },
    cardContent: {
        position: 'relative'
    },
    chip: {
        position: 'absolute',
        top: theme.spacing(1),
        right: theme.spacing(1)
    }

}));

const dateFormat = Config.DATE_FORMATS.FULL_NO_TIME;


export default function CheckoutForm({ balance, selectedPlan, selectedPrice, currentPlan, state, handleClose, plans, preferredCard, updateSubscription, setStep}) {

    let classes = useStyles();
    const stripe = useStripe();
    let [isUpgrade] = useState(() => {
        let currentPrice = parseInt(state.accountInformation.get('price'), 10);
        let newPrice = parseInt(selectedPrice.unit_amount, 10);
        return (newPrice > currentPrice);
    });


    
    const getDateStringFromUnixTimestamp = (timestamp) => {
        let date = DateTime.fromSeconds(timestamp);
        let formatted = date.toFormat(dateFormat);
        return formatted;
    }

    const handleUpdateSubscription = async (isUpgrade) => {

        let payload = {
            priceId: selectedPrice.id,
            subscriptionId: state.accountInformation.get('subscription'),
            paymentMethod: state.accountInformation.get('customer').invoice_settings.default_payment_method,
            customerId: state.accountInformation.get('customer').id,
            currentPriceId: state.accountInformation.get('priceId'),
            currency: state.accountInformation.get('customer').currency
        };
        setStep(1);
        updateSubscription(payload, isUpgrade);

    }

    const noBalance = () => {

        if (state.accountInformation.get('invoice_preview')) {
            try {
                let b = balance * -1;
                if (b >= state.accountInformation.get('invoice_preview').total) {
                    return true;
                }
                
            } catch (error) {
                return false;
            }
        }
        
        return false;

    }

    return (
        <>
            <Grid container spacing={2}>

                <Chip color="secondary" label="Selected Plan" className={classes.chip} />
                <Typography component="h2" variant="h5">{selectedPlan.name}</Typography>

                {!isUpgrade ?
                <Card raised={false}>
                <CardContent>
                <Typography color="error">
                    Please check you are happy with your currently implemented domains, waiting rooms and limits before downgrading to the {selectedPlan.name} plan, because the downgrade process will make its best guesses in trimming these where you exceed your new plan limits.
                </Typography>
                </CardContent>
                </Card>
                : null}

                <>
                <TableContainer>
                                <Table className={classes.table} aria-label="Plan Details" size="small">
                                    <TableBody>
                                        {/* Domains */}
                                        <TableRow>
                                            <TableCell>Domains</TableCell>
                                            <TableCell align="right">{(selectedPlan.metadata.maxDomainCount > 1) ? `Up to ${selectedPlan.metadata.maxDomainCount}` : selectedPlan.metadata.maxDomainCount }</TableCell>
                                        </TableRow>
                                        {/* Max. outflow rate */}
                                        <TableRow >
                                            <TableCell>Ingress rate</TableCell>
                                            <TableCell align="right">{(selectedPlan.metadata.maxDomainRate > 1) ? `Up to ${selectedPlan.metadata.maxDomainRate}` : selectedPlan.metadata.maxDomainRate }</TableCell>
                                        </TableRow>
                                        {/* Waiting Rooms */}
                                        <TableRow>
                                            <TableCell>Waiting Rooms</TableCell>
                                            <TableCell align="right">{(selectedPlan.metadata.maxWaitingRoomCount > 1) ? `Up to ${selectedPlan.metadata.maxWaitingRoomCount}` : selectedPlan.metadata.maxWaitingRoomCount }</TableCell>
                                        </TableRow>
                                        {/* Waiting Room Capacity */}
                                        <TableRow>
                                            <TableCell>Waiting Room Capacity</TableCell>
                                            <TableCell align="right">{(selectedPlan.metadata.maxWaitingRoomCapacity > 1) ? `Up to ${selectedPlan.metadata.maxWaitingRoomCapacity}` : selectedPlan.metadata.maxWaitingRoomCapacity }</TableCell>
                                        </TableRow>
                                        {/* Custom Templates */}
                                        <TableRow>
                                            <TableCell>Custom Templates</TableCell>
                                            <TableCell align="right">{(selectedPlan.metadata.allowCustomTemplates === '0') ? <XIcon /> : <CheckIcon /> }</TableCell>
                                        </TableRow>

                            

                                        {/* ReCaptcha */}
                                        <TableRow>
                                            <TableCell>Recaptcha</TableCell>
                                            <TableCell align="right">{(selectedPlan.metadata.allowCustomTemplates === '0') ? <XIcon /> : <CheckIcon /> }</TableCell>
                                        </TableRow>
                                        
                                        {/* Reporting */}
                                        <TableRow>
                                            <TableCell>Reporting</TableCell>
                                            <TableCell align="right">{(selectedPlan.metadata.allowReports === '0') ? <XIcon /> : <CheckIcon /> }</TableCell>
                                        </TableRow>
                                        
                                        {/* Device Fingerprinting */}
                                        <TableRow>
                                            <TableCell>Device Fingerprinting</TableCell>
                                            <TableCell align="right">{(selectedPlan.metadata.allowFingerprint === '0') ? <XIcon /> : <CheckIcon /> }</TableCell>
                                        </TableRow>
                                        {/* Autotune */}
                                        <TableRow>
                                            <TableCell>Autotune</TableCell>
                                            <TableCell align="right">{(selectedPlan.metadata.allowAutotune === '0') ? <XIcon /> : <CheckIcon /> }</TableCell>
                                        </TableRow>
                                        {/* Priority Access */}
                                        <TableRow>
                                            <TableCell>Priority Access</TableCell>
                                            <TableCell align="right">{(selectedPlan.metadata.allowPriorityAccess === '0') ? <XIcon /> : <CheckIcon /> }</TableCell>
                                        </TableRow>
                                        {/* IP Control */}
                                        <TableRow>
                                            <TableCell>IP Control</TableCell>
                                            <TableCell align="right">{(selectedPlan.metadata.allowFirewall === '0') ? <XIcon /> : <CheckIcon /> }</TableCell>
                                        </TableRow>
                                        {/* API */}
                                        <TableRow>
                                            <TableCell>Full API</TableCell>
                                            <TableCell align="right">{(parseInt(selectedPlan.metadata.apiRateLimit, 10) < 1) ? <XIcon /> : <CheckIcon /> }</TableCell>
                                        </TableRow>
                                        {/* ANOMALY */}
                                        <TableRow>
                                            <TableCell>Anomaly Detection and Threat Blocking</TableCell>
                                            <TableCell align="right">{(parseInt(selectedPlan.metadata.allowAnomaly, 10) < 1) ? <XIcon /> : <CheckIcon /> }</TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell>2FA</TableCell>
                                            <TableCell align="right">{parseInt(selectedPlan.metadata.allowMFA, 10) < 1 ? <XIcon /> : <CheckIcon />}</TableCell>
                                                      
                                        </TableRow>

                                        {/* CDN Implementation */}
                                        <TableRow>
                                            <TableCell>DNS Usage</TableCell>
                                            <TableCell align="right">{parseInt(selectedPlan.metadata.cdnUsage, 10) < 1 ? <XIcon /> : `${selectedPlan.metadata.cdnUsage}TB`}</TableCell>
                                        </TableRow>
                                        
                                        <TableRow>
                                            <TableCell>DNS Implementation</TableCell>
                                            <TableCell align="right">{(parseInt(selectedPlan.metadata.apiRateLimit, 10) < 4) ? <XIcon /> : <CheckIcon /> }</TableCell>
                                        </TableRow>
                                        {/* Log retention */}
                                        <TableRow>
                                            <TableCell>Log retention</TableCell>
                                            <TableCell align="right">{(parseInt(selectedPlan.metadata.maxLogRetentionDays, 10) > 1) ? `Up to ${selectedPlan.metadata.maxLogRetentionDays} days` : `${selectedPlan.metadata.maxLogRetentionDays} day` }</TableCell>
                                        </TableRow>
                                        {/* Users */}
                                        <TableRow>
                                            <TableCell>Seats</TableCell>
                                            <TableCell align="right">{(parseInt(selectedPlan.metadata.maxUsers, 10) > 1) ? `Up to ${selectedPlan.metadata.maxUsers}` : `${selectedPlan.metadata.maxUsers}` }</TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell>Support Response</TableCell>
                                            <TableCell align="right">{selectedPlan.metadata.sla}</TableCell>
                                        </TableRow>
                                            
                                    </TableBody>
                                </Table>
                            </TableContainer>
                
                </>

                {!preferredCard && !noBalance()
                ?

                <Grid item xs={12}>
                    {noBalance()}
                    <Typography>You need to add a prefered card to the billing section in order to continue</Typography>
                </Grid>
                :
                <Grid item xs={12}>
                    <Button onClick={() => { handleUpdateSubscription(isUpgrade) }} disableElevation variant='contained' color='secondary' fullWidth type="submit" disabled={!stripe}>
                {isUpgrade ? `Purchase plan @`: `Downgrade plan to `}

                {state.accountInformation.get('customer').balance < 0 && (state.accountInformation.get('customer').balance *-1 > selectedPrice.unit_amount)
                
                ?
                `${state.accountInformation.get('currency')}${(selectedPrice.unit_amount / 100).toFixed(2)}/month using credit.`
            
                :
                `${state.accountInformation.get('currency')}${(selectedPrice.unit_amount / 100).toFixed(2)}/month - [${preferredCard.brand} •••• ${preferredCard.last4}]`
                
                }
                    </Button>
                </Grid>
                }

                <Grid item xs={12}>
                    <Button disableElevation onClick={() => { handleClose() }} variant='contained' color='primary' fullWidth type="button">Cancel</Button>
                </Grid>

                {isUpgrade && state.accountInformation.get('invoice_preview')
                    ?
                    (state.accountInformation.get('customer').currency === 'gbp')
                        ?
                        <Grid item xs={12}>
                            <Typography>
                                {`
                                Your current subscription will be cancelled and you will begin a new subscription starting today.`
                                }
                            </Typography>
                            
                            <Typography>
                                Total: {`${state.accountInformation.get('currency')}${(state.accountInformation.get('invoice_preview').total / 100).toFixed(2)} (${state.accountInformation.get('currency')}${(state.accountInformation.get('invoice_preview').subtotal / 100).toFixed(2)} + ${state.accountInformation.get('currency')}${(state.accountInformation.get('invoice_preview').tax / 100).toFixed(2)} VAT)`}
                            </Typography>

                            {/* includes tax */}


                            {state.accountInformation.get('invoice_preview').proration_data.amount
                            ?
                            <Typography>
                                {`
                                We will refund you
                                ${state.accountInformation.get('currency')}${((state.accountInformation.get('invoice_preview').proration_data.total * -1) / 100).toFixed(2)}
                                for ${state.accountInformation.get('invoice_preview').proration_data.description[0].toLowerCase() + state.accountInformation.get('invoice_preview').proration_data.description.slice(1)}
                                `}
                            </Typography>
                            :
                            null
                            }



                        </Grid>
                        :
                        <Grid item xs={12}>
                            <Typography>
                                {`
                                Your current subscription will be cancelled and you will begin a new subscription starting today.`
                                }
                            </Typography>
                            
                            <Typography>
                                Total: {`${state.accountInformation.get('currency')}${state.accountInformation.get('invoice_preview').total / 100}`}
                            </Typography>

                            {/* includes tax */}


                            {state.accountInformation.get('invoice_preview').proration_data.amount
                            ?
                            <Typography>
                                {`
                                We will refund you
                                ${state.accountInformation.get('currency')}${((state.accountInformation.get('invoice_preview').proration_data.total * -1) / 100).toFixed(2)}
                                for ${state.accountInformation.get('invoice_preview').proration_data.description[0].toLowerCase() + state.accountInformation.get('invoice_preview').proration_data.description.slice(1)}
                                `}
                            </Typography>
                            :
                            null
                            }
                        </Grid>
                    :
                    null
                }

                {!isUpgrade && state.accountInformation.get('invoice_preview')
                    ?

                    <Grid item xs={12}>
                            {(state.accountInformation.get('customer').currency === 'gbp')
                            ?
                        <Typography>
                            You will be charged {`${state.accountInformation.get('currency')}${state.accountInformation.get('invoice_preview').total / 100} (${state.accountInformation.get('currency')}${state.accountInformation.get('invoice_preview').subtotal / 100} + ${state.accountInformation.get('currency')}${state.accountInformation.get('invoice_preview').tax / 100} VAT)`} on {(state.accountInformation.get('invoice_preview').next_payment_attempt &&
                                getDateStringFromUnixTimestamp(
                                    state.accountInformation.get('invoice_preview').next_payment_attempt
                                )) ||
                                ''}
                        </Typography>

                            :
                        <Typography>
                            You will be charged {`${state.accountInformation.get('currency')}${state.accountInformation.get('invoice_preview').total / 100}`}  on {(state.accountInformation.get('invoice_preview').next_payment_attempt &&
                                getDateStringFromUnixTimestamp(
                                    state.accountInformation.get('invoice_preview').next_payment_attempt
                                )) ||
                                ''}
                        </Typography>
                            }
                    </Grid>

                    :
                    null
                }

            </Grid>

        </>
    );
}
