import React from "react";
import {
    Select,
    MenuItem,
    FormControlLabel,
  } from '@material-ui/core';

const ReCaptchaSwitch = ({room, recaptchaIsSet, update, label, saving}) => {

    const [state, setState] = React.useState({
      captchaRequired: room.captchaRequired
    });
    
    const onSwitchChangeChange = (event) => {

        setState({
            ...state,
            captcha : event.target.value
        });
        update({
            captcha : event.target.value
        });
        
    // const onSwitchChangeChange = (event) => {
    //     console.log('onSwitchChangeChange');
    //     setState({
    //         ...state,
    //         captchaRequired : (event.target.checked) ? 1 : 0
    //     });
    //     update({
    //         captchaRequired : (event.target.checked) ? 1 : 0
    //     });
        
    }
  
    return (
      <FormControlLabel style={{margin: '0 auto'}} control={
        <Select
            value={room.captcha}
            onChange={onSwitchChangeChange}
            size="small"
            labelWidth={0}
            disabled={!recaptchaIsSet}
          >
            <MenuItem value='not-required'>Never</MenuItem>
            <MenuItem value='required'>Always</MenuItem>
            <MenuItem value='required-over-rate'>Over Rate</MenuItem>
          </Select>
      } /> 
    )
    // return (
    //   <FormControlLabel style={{margin: '0 auto'}} control={
    //     <Switch
    //     disabled={!recaptchaIsSet}
    //     checked={(state.captchaRequired === 1) ? true: false }
    //     name={room.id}
    //     onChange={onSwitchChangeChange}
    //   />
    //   }
    //   label={(label) ? `${label}` : ''}/> 
    // )
  }

  export default ReCaptchaSwitch;