import React from 'react';
import { ReactComponent as ChevronsRight } from './feather/chevrons-right.svg';

import { SvgIcon } from '@material-ui/core';


export default function ChevronsRightIcon(props) {
    return (
        <SvgIcon {...props} component={ChevronsRight} className="feather"/>
    );
  }