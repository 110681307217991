import React, { useState, useRef, useEffect } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { ItemTypes } from '../ItemTypes'
import InlineRowEditField from '../components/InlineRowEditField';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import { Link as BrowserLink } from 'react-router-dom';

import DragIndicatorIcon from '../icons/DragIndicatorIcon';
import XCircleIcon from '../icons/XCircleIcon';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import {
  Typography,
  TableRow,
  TableCell,
  InputLabel,
  FormControl,
  IconButton,
  Button,
  Switch,
  Grid,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  handle: {
    backgroundColor: 'white',
    color: '#cacaca',
    width: '22px',
    height: '50px',
    display: 'flex',
    marginRight: '0px',
    marginLeft: 'auto',
    cursor: 'grab',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dragging: {
    borderTop: `2px solid ${theme.palette.success.main}`,
    overflow: 'hidden',
    cursor: 'grabbing',
    '& .material': {
      color: theme.palette.success.main
    }
  },
  priorityDragging: {
    opacity: 0
  }

}));

const PAGroup = ({ id, group, name, code, index, movePAGroup, classes, roomID, deleteGroup, updateGroup, updatePriorityOrderHandler }) => {


  // let defaultValues = {
  //   groupName: group.name,
  //   groupCode: group.code,
  // }
  const PAGclasses = useStyles();

  const [defaultValues, setDefaultValues] = useState({
    groupName: name,
    groupCode: code,
  });

  useEffect(() => {
    setDefaultValues({
      groupName: name,
      groupCode: code,
    })
  }, [name, code]);
  const [rowIsDragging, setRowIsDragging] = useState(false);

  /**
   * Set Formik validation and Submit Handler
   */
  const formik = useFormik({
    initialValues: defaultValues,
    validationSchema: Yup.object({
      groupName: Yup.string().required('Required'),
      groupCode: (group.code !== null) ? Yup.string().required('Required').min(3, "Minimum of 3 characters") : Yup.string().nullable().default(null)
    }),
    onSubmit: (values) => {
      let formattedData = {
        roomID: roomID,
        code: values.groupCode,
        name: values.groupName,
        type: group.type,
        id: group.id,
        priority: group.priority,
      }

      updateGroup(formattedData);
    },
  });

  /**
   * https://github.com/react-dnd/react-dnd
   * https://react-dnd.github.io/react-dnd/about
   * https://react-dnd.github.io/react-dnd/examples/sortable/simple
   * Store a ref for the drag/drop
   */
  const ref = useRef(null)
  const [, drop] = useDrop({
    accept: ItemTypes.BOX,
    collect: (monitor) => ({
      isActive: monitor.canDrop() && monitor.isOver()
    }),
    drop: (item, monitor) => {
      updatePriorityOrderHandler(item)
    },
    hover(item, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      
      // Time to actually perform the action
      movePAGroup(dragIndex, hoverIndex)
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  })
  const [{ opacity, height }, drag, preview] = useDrag({
    item: { type: ItemTypes.BOX, id, index, data: group },
    collect: (monitor) => {
      return {
        isDragging: monitor.isDragging(),
        opacity: monitor.isDragging() ? 0.1 : 1,
        height: monitor.isDragging() ? '0px' : 'auto',
      }
    },
    end: (item, monitor) => {
      setRowIsDragging(false);
    },
    /**
     * If the current row has fields that have focus then prevent dragging
     */
    canDrag: (monitor) => {
      let nameElement = document.getElementById(`group_id_name_${roomID}_${group.id}`);
      let codeElement = document.getElementById(`group_id_code_${roomID}_${group.id}`);
      if (document.activeElement === nameElement || document.activeElement === codeElement) {
        return false;
      }
      setRowIsDragging(true);
      return true;
    }
  })

  drag(drop(ref));

  const handleChange = (group) => {

    let preActivationAccess;
    switch (group.preActivationAccess) {
      case 1:
        preActivationAccess = 0;
        break;
      case 0:
        preActivationAccess = 1;
        break;
      default:
        preActivationAccess = 1;
    }

    let formattedData = {
      ...group,
      preActivationAccess 
    }

    updateGroup(formattedData);

  }

  return (
    <TableRow key={group.id} ref={preview} style={{ opacity, height }}
      className={clsx({
        [PAGclasses.dragging]: rowIsDragging,
      })}>
      <TableCell component="th" scope="row" className={classes.cellFirst} data-name={formik.values.groupName}>
        <Grid onSubmit={formik.handleSubmit}>
          <FormControl fullWidth margin="dense" variant="outlined">
            <InputLabel style={{display:'none'}} htmlFor={`group_id_name_${roomID}_${group.id}`} id={`group_id_name_${roomID}_${group.id}_label`}>Name</InputLabel>
            <InlineRowEditField
              id={`group_id_name_${roomID}_${group.id}`}
              name="groupName"
              aria-label="Name"
              aria-describedby={`group_id_name_${roomID}_${group.id}_label`}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.groupName}
              handleSave={formik.handleSubmit}
              changed={formik.initialValues.groupName !== formik.values.groupName}
              disableSave={formik.initialValues.groupCode !== formik.values.groupCode}
              />
            {formik.touched.groupName && formik.errors.groupName ? <Typography variant="subtitle2" color="error">{formik.errors.groupName}</Typography> : null}
          </FormControl>
        </Grid>
      </TableCell>
      <TableCell align="left" className={classes.cell} >
        <Switch
          checked={group.preActivationAccess === 1 ? true : false}
          onChange={() => {
            handleChange(group)
          }}
          name="preActivation"
          inputProps={{ 'aria-label': 'Pre Activation' }}
        />
      </TableCell>
      <TableCell align="left" className={classes.cell} >{group.type}</TableCell>
      <TableCell align="left" className={classes.cell} >
        {(group.type === 'multi-use')
          ?
          <Grid onSubmit={formik.handleSubmit}>
            <FormControl variant="outlined" fullWidth margin="dense">
              <InputLabel style={{display:'none'}} htmlFor={`group_id_code_${roomID}_${group.id}`} id={`group_id_code_${roomID}_${group.id}_label`}>Code</InputLabel>
              <InlineRowEditField
                id={`group_id_code_${roomID}_${group.id}`}
                name="groupCode"
                aria-label="Code"
                aria-describedby={`group_id_code_${roomID}_${group.id}_label`}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.groupCode}
                handleSave={formik.handleSubmit}
                changed={formik.initialValues.groupCode !== formik.values.groupCode}
                disableSave={formik.initialValues.groupCode === formik.values.groupCode || formik.errors.groupCode}

              />
              {formik.touched.groupCode && formik.errors.groupCode ? <Typography variant="subtitle2" color="error">{formik.errors.groupCode}</Typography> : null}
            </FormControl>
          </Grid>
          :
          <Button component={BrowserLink}
            to={`/priority-access/manage-codes/${group.id}`}
            disableElevation
            size="small"
            variant="contained"
            color="secondary"
          >
            Manage Codes
        </Button>

        }
      </TableCell>
      <TableCell align="center" className={classes.cell} >
        <IconButton onClick={() => { deleteGroup(group) }}>
          <XCircleIcon />
        </IconButton>
      </TableCell>
      <TableCell align="right" className={classes.cell} >
        <div ref={ref} className={PAGclasses.handle}>
          <DragIndicatorIcon />
        </div>
      </TableCell>
    </TableRow>
  )
}

export default PAGroup;

