import React from 'react';
import {
    List,
    ListItemText,
    ListItem,
    Typography,
    Grid,
    Tabs,
    Tab,
    Box,
    Collapse,
    Backdrop,
    CircularProgress,
    FormControl,
    TextField,
    Button,
    Link
  } from '@material-ui/core';

const DomainProxyURLExclusionList = ({default_bypass_list, formikURExclusionList, list}) => {

    const [rowsLength, setRowsLength] = React.useState(list.length);


    return (
        <>
        <Typography variant="subtitle1" >URL exclusion list</Typography>
        <Typography >A list of patterns that will be bypassed. <Link href="https://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/distribution-web-values-specify.html#DownloadDistValuesPathPattern" target="_blank">Help creating patterns.</Link></Typography>
        <Typography >Default patterns: <strong>{default_bypass_list.join(', ')}</strong> are cached by default for 300 seconds. </Typography>
        <Typography >For more information see <Link href="https://www.crowdhandler.com/docs/80001160874-dns-implementation-default-pattern-exclusions" target="_blank">DNS Implementation: Default Pattern Exclusions</Link></Typography>

        <Grid container>
            <Grid item xs={12}>
                <FormControl fullWidth margin="dense" variant="outlined">
                    <TextField
                        id="outlined-multiline-static"
                        label="Bypass List"
                        multiline
                        minRows={(rowsLength > 4) ? rowsLength : 4}
                        {...formikURExclusionList.getFieldProps('urLExclusionList')}
                        variant="outlined"
                    />
                </FormControl>

                {formikURExclusionList.touched.urLExclusionList && formikURExclusionList.errors.urLExclusionList ? <Typography variant="subtitle2" color="error">{formikURExclusionList.errors.urLExclusionList}</Typography> : null}

            </Grid>
        </Grid>

        <FormControl margin="dense" variant="outlined">
            <Button
            variant="contained"
            color="primary"
            size="small"
            disableElevation
            disabled={(formikURExclusionList.errors.urLExclusionList) ? true : false}
            onClick={(event) =>{ formikURExclusionList.handleSubmit(); }}
            >
                Update URL exclusion list
            </Button>
        </FormControl>




        </>
    )

}

export default DomainProxyURLExclusionList;