import React from 'react';
import { ReactComponent as ChevronRight } from './feather/chevron-right.svg';

import { SvgIcon } from '@material-ui/core';


export default function ChevronRightIcon(props) {
    return (
        <SvgIcon {...props} component={ChevronRight} className="feather"/>
    );
  }