import React, { createContext, useState, useRef } from "react";

import {
  AuthenticationDetails,
  CognitoUserPool,
  CognitoUser,
  CognitoUserSession,
  CognitoIdToken,
  CognitoAccessToken,
  CognitoRefreshToken,
} from 'amazon-cognito-identity-js';

import handleCognitoSignIn from "./AuthProviderComponents/handleCognitoSignIn";
import handleRespondToAuthChallenge from "./AuthProviderComponents/handleRespondToAuthChallenge.js";
import handleSignOut from "./AuthProviderComponents/handleSignOut.js";

import * as AWS from 'aws-sdk/global';

import Config from '../config';

const EU_UserPool = Config.EU_UserPool;
const US_UserPool = Config.US_UserPool;


const CHCognitoEndpoint = process.env.REACT_APP_CH_COGNITO_API_EU;
const X_API_KEY = process.env.REACT_APP_CH_COGNITO_API_KEY_EU;
// Create two context:
// AuthContext: to query the context state
// AuthDispatchContext: to mutate the context state
const AuthContext = createContext(undefined);
const AuthDispatchContext = createContext(undefined);

// A "provider" is used to encapsulate only the
// components that needs the state in this context
const AuthProvider = ({ children }) => {
  const [userDetails, setUserDetails] = useState({
    username: ""
  });
  const [isAuthorised, setIsAuthorized] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const attributes = useRef()
  const cognitoUserGlobal = useRef()
  const sessionUserAttributes = useRef()
  const region = useRef();
  const session = useRef();


  const getCredentials = async () => {
    try {
      return AWS.config.credentials;
    } catch (error) {
      console.log(error);
    }
  }


  const getAttributes = async () => {

    // NOTE: getSession must be called to authenticate user before calling getUserAttributes

    if(attributes.current !== undefined) {
      return attributes.current;
    }

    // attempt to get a US user
    let data = await getCognitoUser(EU_UserPool);
    if (!data) {
      data = await getCognitoUser(US_UserPool);
    }
    if (!data) {
      return []
    }

    const {UserPoolId, ClientId} = data;

    var poolData = {
      UserPoolId, // Your user pool id here
      ClientId, // Your client id here
    };

    const userPool = new CognitoUserPool(poolData);
    const cognitoUser = userPool.getCurrentUser();

    if (cognitoUser != null) {

      return new Promise((resolve, reject) => {

        cognitoUser.getSession(function (err, session) {
          if (err) {
            alert(err.message || JSON.stringify(err));
            return;
          }

          return cognitoUser.getUserData((err, data) => {
            if (err) {
              reject(err);
            }
            const { PreferredMfaSetting, UserMFASettingList, UserAttributes } = data;
            resolve({UserAttributes, PreferredMfaSetting, UserMFASettingList});
            
          });

          // Instantiate aws sdk service objects now that the credentials have been updated.
          // example: var s3 = new AWS.S3();
        });
        
       
      });

      
    }
  }

  const getCognitoUser = ({
    UserPoolId,
    ClientId,
    IdentityPoolId,
    Region
  }) => {
   return new Promise((resolve, reject) => {
    try {
      var poolData = {
        UserPoolId, // Your user pool id here
        ClientId, // Your client id here
      };
      var userPool = new CognitoUserPool(poolData);
      var cognitoUser = userPool.getCurrentUser();
      if (cognitoUser !== null) {

        // save a reference to the region
        region.current = Region;

        resolve({
          cognitoUser,
          Region,
          IdentityPoolId,
          UserPoolId,
          ClientId
        })
      } else {
        resolve(false);
      }
      
    } catch (error) {
      reject(false);
    }
   })
    
  }

 
  const getAccessToken = async () => {

    const response = await currentSession();
    if (response && response.session) {
      return {accessToken: response.session.getAccessToken().getJwtToken()};
    } else {
      return false;
    }
    
  }

  const getIDToken = async () => {
    const response = await currentSession();
    if (response && response.session) {
      return {idToken: response.session.getIdToken()};
    } else {
      return false;
    }
  }

  const getJwtToken = async () => {
    const response = await currentSession();

    if (response && response.session) {
      return {jwtToken: response.session.getIdToken().getJwtToken()};
    } else {
      return false;
    }

  }

  const getRegion = () => {
    return region.current;
  }

  const fetchUser = async () => {
    let user = await getCognitoUser(EU_UserPool);
    if (!user) {
      user = await getCognitoUser(US_UserPool);
    }

    return user;
  }

  const currentSession = async () => {

    try {
      // attempt to get a US user
      let user = await fetchUser();
      if (user) {
        let {cognitoUser} = user;
        let session = await new Promise((resolve, reject) => {
          cognitoUser.getSession((err, session) => {

            if (err) {
              reject(err);
            }
            if (session) {
              resolve(session);
            } else {
              resolve({session: false})
            }
          })
        });

        return {session, cognitoUser};
      } else {
        return {session: false, cognitoUser : false};
      }



    } catch (error) {
      
    }
  }

  const signOut = async () => {
    try {
      let cognitoUserData = await getCognitoUser(EU_UserPool);
      if (!cognitoUserData) {
        cognitoUserData = await getCognitoUser(US_UserPool);
      }
      const { cognitoUser } = cognitoUserData;
      if (cognitoUser) {
        cognitoUser.signOut();
      }

      if ( AWS.config.credentials && AWS.config.credentials.clearCachedId ) {
        await AWS.config.credentials.clearCachedId()
      }

      handleSignOut();

      window.location.replace('/login');

    } catch (error) {
      console.log(error);
    }
  }

  const handleCurrentSession = async () => {

    // attempt to get a US user
    let user = await fetchUser();

    if (user) {
      let { cognitoUser } = user;
      let session = await new Promise((resolve, reject) => {
        cognitoUser.getSession((err, session) => {

          if (err) {
            reject(err);
          }
          if (session) {
            resolve(session);
          }
        })
      });

      const { Region, UserPoolId, IdentityPoolId } = user;
      const Logins = {
        [`cognito-idp.${Region}.amazonaws.com/${UserPoolId}`]: session.getIdToken().getJwtToken()
      }

      // clear the cache before creating the new session
      if ( AWS.config.credentials && AWS.config.credentials.clearCachedId ) {
        AWS.config.credentials.clearCachedId()
      }

      AWS.config.region = Region;
      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId, // your identity pool id here
        Logins
      });

      await AWS.config.credentials.refresh();

      setIsAuthorized(true);

      return { session, cognitoUser };

    } else {

      setIsAuthorized(false);
      return { session : undefined, cognitoUser: undefined };
    }
  }

  const checkAuthorized = async () => {
    if (!isAuthorised) {
      signOut();
      return false;
    } else {
      return true;
    }

  }

  const handleSignIn = async ({ username, password }) => {

    let {cognitoUser, ChallengeName, Region, ChallengeParameters, Session, error } = await handleCognitoSignIn({ username, password });

    let poolData = {
      UserPoolId: Region === 'us-east-1' ? US_UserPool.UserPoolId : EU_UserPool.UserPoolId, // Your user pool id here
      ClientId: Region === 'us-east-1' ? US_UserPool.ClientId : EU_UserPool.ClientId, // Your client id here
    };

    let userPool = new CognitoUserPool(poolData);
    let userData = {
      Username: username,
      Pool: userPool,
    };

    session.current = Session;

    cognitoUserGlobal.current = new CognitoUser(userData);

    handleCurrentSession();

    region.current = Region;
    // send values back to Login.js
    if (ChallengeName) {
      return {ChallengeName, ChallengeParameters, Session, Region}
    }
    if (error) {
      return {error}
    }
    if (cognitoUser) {
      return cognitoUser.getSession((err, session) => {
        if (session) {
          return {cognitoUser, Region};
        } else {
          return false;
        }
      });
    }

    
  }

  const respondToAuthChallenge = async ({ UserCode, Username, Region, Session, ChallengeName }) => {
    let { cognitoUser, type, message } = await handleRespondToAuthChallenge({ UserCode, Username, Region, Session, ChallengeName })

    if (type || message) {
      return { type, message }
    }

    if (cognitoUser) {
      return cognitoUser.getSession((err, session) => {
        if (session) {
          return { cognitoUser };
        } else {
          return false;
        }
      });
    } else {
      return false;
    }
  }

  const setUpTOTP = async () => {
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", X_API_KEY);
    myHeaders.append("Content-Type", "application/json");

    let {accessToken} = await getAccessToken();

    var raw = JSON.stringify({
      AccessToken : accessToken,
      // Session : session,
      region: region.current
    });
  
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
  
    try {
      let response = await fetch(`${CHCognitoEndpoint}/enable-mfa`, requestOptions);
      let {SecretCode} = await response.json();

      return {SecretCode};
    } catch (error) {
      console.log(error);
    }
  }

  const verifyMfa = async ({UserCode}) => {
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", X_API_KEY);
    myHeaders.append("Content-Type", "application/json");

    let {accessToken} = await getAccessToken();

    var raw = JSON.stringify({
      AccessToken : accessToken,
      UserCode,
      region: region.current
    });
  
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    try {
      let response = await fetch(`${CHCognitoEndpoint}/verify-mfa`, requestOptions);
      let code = await response.json();

      if (code.Status === 'SUCCESS') {
        var totpMfaSettings = {
          PreferredMfa: true,
          Enabled: true,
        };

        let data = await getCognitoUser(US_UserPool);
        if (!data) {
          data = await getCognitoUser(EU_UserPool);
        }

        const {cognitoUser} = data;

        return cognitoUser.getSession(async (err, session) => {
          if (session) {
            const refreshUserTokensResponse = await new Promise(async (resolve, reject) => {

              try {
                const refresh_token = await session.getRefreshToken();
                cognitoUser.refreshSession(refresh_token, (err, session) => {
                  if (session) {
                    const tokens = {
                      IdToken: session.getIdToken().getJwtToken(),
                      AccessToken: session.getAccessToken().getJwtToken(),
                      RefreshToken: session.getRefreshToken().token
                    }

                    let setSignInUserSessionResponse = cognitoUser.setSignInUserSession(new CognitoUserSession({
                      IdToken: new CognitoIdToken(tokens),
                      AccessToken: new CognitoAccessToken(tokens),
                      RefreshToken: new CognitoRefreshToken(tokens),
                    }));

                    resolve({ result: 'SUCCESS', setSignInUserSessionResponse: setSignInUserSessionResponse })

                  }
                });
              } catch (error) {
                reject({error});
              }

            });

            let {result} = refreshUserTokensResponse;
            if (result) {
              await cognitoUser.setUserMfaPreference(null, totpMfaSettings, function(err, result) {
                if (err) {
                 console.log(err.message || JSON.stringify(err));
                }
              });
            }


            return refreshUserTokensResponse;
            
            


          }
        
        })
        
      }

    } catch (error) {
      console.log(error);
    }
  }


  const authenticateUser = async ({ username, password, region }) => {
    
    let authenticationData = {
      Username: username,
      Password: password,
    };

    let authenticationDetails = new AuthenticationDetails(
      authenticationData
    );
    let poolData = {
      UserPoolId: region === 'us' ? US_UserPool.UserPoolId : EU_UserPool.UserPoolId, // Your user pool id here
      ClientId: region === 'us' ? US_UserPool.ClientId : EU_UserPool.ClientId, // Your client id here
    };

    let userPool = new CognitoUserPool(poolData);
    let userData = {
      Username: username,
      Pool: userPool,
    };

    cognitoUserGlobal.current = new CognitoUser(userData);

    return new Promise((resolve, reject) => {

      cognitoUserGlobal.current.authenticateUser(authenticationDetails, {
        onSuccess: function (result) {
          let accessToken = result.getAccessToken().getJwtToken();
        },
  
        onFailure: function (error) {
          resolve( {error : {
            name: error.name,
            message: error.message,
          }} )
        },
  
        mfaSetup: function (challengeName, challengeParameters) {
          cognitoUserGlobal.current.associateSoftwareToken(this);
        },
  
        associateSecretCode: function (secretCode) {
          let challengeAnswer = prompt('Please input the TOTP code.', '');
          cognitoUserGlobal.current.verifySoftwareToken(challengeAnswer, 'My TOTP device', this);
        },
  
        selectMFAType: function (challengeName, challengeParameters) {
          let mfaType = prompt('Please select the MFA method.', ''); // valid values for mfaType is "SMS_MFA", "SOFTWARE_TOKEN_MFA"
          cognitoUserGlobal.current.sendMFASelectionAnswer(mfaType, this);
        },
  
        totpRequired: function (secretCode) {
          let challengeAnswer = prompt('Please input the TOTP code.', '');
          cognitoUserGlobal.current.sendMFACode(challengeAnswer, this, 'SOFTWARE_TOKEN_MFA');
        },
  
        mfaRequired: function (codeDeliveryDetails) {
          let verificationCode = prompt('Please input verification code', '');
          cognitoUserGlobal.current.sendMFACode(verificationCode, this);
        },
  
        newPasswordRequired: function (userAttributes, requiredAttributes) {
            // User was signed up by an admin and must provide new
          // password and required attributes, if any, to complete
          // authentication.
          // the api doesn't accept this field back
          delete userAttributes.email_verified;
          delete userAttributes.email;
          
          // store userAttributes on global variable
          sessionUserAttributes.current = userAttributes;
          resolve( {
            'ChallengeName' : 'NEW_PASSWORD_REQUIRED',
            userAttributes
          })
        }
  
      });

    })


  }

  const handleNewVerificationPassword = (newPassword) => {

    try {

      return new Promise((resolve, reject) => {

        cognitoUserGlobal.current.completeNewPasswordChallenge(newPassword, sessionUserAttributes.current,
          {
            onSuccess: async (result) => {
              let accessToken = result.getAccessToken().getJwtToken();

              let user = await fetchUser();

              let data = await getCognitoUser(EU_UserPool);
              if (!data) {
                data = await getCognitoUser(US_UserPool);
              }

              resolve(user);
            },
      
            onFailure: function (err) {
              reject(err)
            },
          });
      })
      
    } catch (error) {
      console.log(error);
    }
  }

  const setAWSCredentials = ({region, IdentityPoolId, UserPoolId, IdToken}) => {

    const Logins = {
      [`cognito-idp.${region}.amazonaws.com/${UserPoolId}`]: IdToken
    }

    return new Promise(async (resolve, reject) => {

      try {
        AWS.config.region = region;
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
          IdentityPoolId, // your identity pool id here
          Logins
        });
  
        await AWS.config.credentials.refresh();
  
        resolve(true);
        
      } catch (error) {
        
        reject(error);
      }

    })
  }


  const confirmResetPassword = async ({ confirmationCode, newPassword, username }) => {
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", process.env.REACT_APP_CH_COGNITO_API_KEY_EU);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "username": username,
      "confirmationCode": confirmationCode,
      "newPassword": newPassword
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    let response = await fetch(`${process.env.REACT_APP_CH_COGNITO_API_EU}new-password`, requestOptions);
    response = await response.json();

    return response;

  }


  const changePassword = async ({oldPassword, newPassword}) => {

    let {cognitoUser} = await fetchUser();

    cognitoUser.changePassword(oldPassword, newPassword,  (err, result) => {
      if (err) {
        alert(err.message || JSON.stringify(err));
        return err;
      }

      return result;
    });
  }

  const updateMFAPrefs = async ({ Enabled, PreferredMfa }) => {
    return await new Promise(async (resolve, reject) => {
      try {
        const { session, cognitoUser } = await currentSession();
        cognitoUser.setUserMfaPreference(null, {
          PreferredMfa,
          Enabled,
        }, (err, result) => {
          if (result === 'SUCCESS') {
            const refresh_token = session.getRefreshToken();
            cognitoUser.refreshSession(refresh_token, (err, session) => {
              if (session) {
                try {
                  const tokens = {
                    IdToken: session.getIdToken().getJwtToken(),
                    AccessToken: session.getAccessToken().getJwtToken(),
                    RefreshToken: session.getRefreshToken().token
                  }
                  cognitoUser.setSignInUserSession(new CognitoUserSession({
                    IdToken: new CognitoIdToken(tokens),
                    AccessToken: new CognitoAccessToken(tokens),
                    RefreshToken: new CognitoRefreshToken(tokens),
                  }));
                  resolve({ result: 'SUCCESS' });
                } catch (error) {
                  reject({ error: 'ERROR' });
                }
              }
            });
          }
        });
      } catch (error) {
        reject({ error: 'ERROR' });
      }
    })
  }

 

  const value = {
    userDetails,
    setUserDetails,
    isLoggedIn,
    setIsLoggedIn,
    isAuthorised,
    handleSignIn,
    currentSession,
    getAttributes,
    handleCurrentSession,
    getIDToken,
    getJwtToken,
    setUpTOTP,
    updateMFAPrefs,
    verifyMfa,
    getRegion,
    signOut,
    authenticateUser,
    handleNewVerificationPassword,
    changePassword,
    confirmResetPassword,
    respondToAuthChallenge,
    getCredentials,
    checkAuthorized
  }

  return (
    <AuthContext.Provider value={value}>
      <AuthDispatchContext.Provider value={{ setUserDetails, setIsLoggedIn }}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthContext.Provider>
  );
}

export { AuthProvider, AuthContext, AuthDispatchContext };