import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import {
    Tooltip,
    Grid,
    Typography
} from '@material-ui/core';

import {SmartPhoneIcon, MonitorIcon, CPUIcon} from '../icons';
import ChromeLogo from '../icons/browser_logos/chrome_16x16.png';
import SamsungLogo from '../icons/browser_logos/samsung-internet_16x16.png';
import OperaLogo from '../icons/browser_logos/opera_16x16.png';
import GlobeIcon from '../icons/GlobeIcon';
import AndroidWebViewLogo from '../icons/browser_logos/android-webview_16x16.png';
import EdgeLogo from '../icons/browser_logos/edge_16x16.png';
import FireFoxLogo from '../icons/browser_logos/firefox_16x16.png';
import SafariLogo from '../icons/browser_logos/safari_16x16.png';
import HardDriveIcon from '../icons/HardDriveIcon';

const useStyles = makeStyles((theme) => ({
  hover : {
    cursor: 'pointer'
  }
}));


const SessionAgent = ({value}) => {

  const classes = useStyles()

  const browserIcon = useMemo(() => {

    if (!value) {
        return null;
    }
  
    if (!value.browser || !value.browser.name) {
        return null;
    }
  
    let name = value.browser.name.toLowerCase();
    let logo;
  
    if (name.indexOf('safari') !== -1) {
        logo = SafariLogo;
      } else if(name.indexOf('chrome') !== -1) {
        logo = ChromeLogo;
      } else if(name.indexOf('samsung') !== -1) {
        logo = SamsungLogo;
      } else if(name.indexOf('android') !== -1) {
        logo = AndroidWebViewLogo;
      } else if(name.indexOf('edge') !== -1) {
        logo = EdgeLogo;
      } else if(name.indexOf('firefox') !== -1) {
        logo = FireFoxLogo;
      } else if(name.indexOf('opera') !== -1) {
        logo = OperaLogo;
      } else {
        return <GlobeIcon style={{width: '16px', height: '16px'}}/>;
      }
      return <img src={logo} alt={name} style={{width: '16px', height: '16px'}}/>;
  
  }, [value]);

  
    let OSIcon = useMemo(() => {

      
      if(!value || !value.os) {
        return null;
      }

      if (!value.os.icon) {
        return null;
      }

        
        let icon = value.os.icon;
        
        try {
          const regex = /assets.userstack.com\/icon\//gm;
          const subst = `assets.userstack.com/icons/`;
          icon = value.os.icon.replace(regex, subst);
        } catch (error) {          
          return null;      
        }

        if (value.os.icon.toLowerCase().indexOf('android') !== -1) {
          icon = 'https://assets.userstack.com/icons/os/android.png';
        }
        if (value.os.icon.toLowerCase().indexOf('ipados') !== -1) {
          icon = 'https://assets.userstack.com/icons/os/iphone.png';
        }
        if (value.os.name && value.os.name.toLowerCase().indexOf('chrome') !== -1) {
          icon= ChromeLogo;
        }
        return <img src={icon} alt={`${value.os.name} ${value.os.version}` } style={{width: '16px', height: '16px'}}/>;
      },[value]);


    if(!value) return null;

    if (value.type && value.type.indexOf('browser') > -1) {
        return (
            <Grid className={classes.hover}>
             <Tooltip title={(value.browser && value.browser.name)}> 
             <span style={{marginRight:'6px'}}>
                {value.browser.name && browserIcon}
             </span>
            </Tooltip>

            {value.os && value.os.family !== null
            ?
            //  <Tooltip title={value.os.name}> 
             <Tooltip title={value.os.name ? value.os.name : value.os.family ? value.os.family : 'Unknown'}> 
                <span style={{marginRight:'6px'}}>
                    {value.os.name && OSIcon}
                    {/* <Typography>{value.os.family}</Typography> */}
                    {/* {value.os.family && HardDriveIcon ? <HardDriveIcon style={{width: '16px', height: '16px'}}/> : null} */}
                </span> 
            </Tooltip>
            :
            null
            }


            {value.type.indexOf('mobile') > -1
            ?
             <Tooltip title={'Mobile'}> 
                <span style={{marginRight:'6px'}}>
                    <SmartPhoneIcon style={{width: '16px', height:'16px'}}/>
                </span> 
            </Tooltip>
            :
            <Tooltip title={'Desktop'}> 
                <span style={{marginRight:'6px'}}>
                    <MonitorIcon style={{width: '16px', height:'16px'}}/>
                </span> 
            </Tooltip>
            }

           
            </Grid>
        );
    }

    if (value.type && value.type.indexOf('crawler') > -1) {

        if (value.crawler.is_crawler && value.crawler.category) {
            let name = (value.browser.name) ? value.browser.name : value.crawler.category;
           
            return (
                <Grid className={classes.hover}>
          {value.browser && value.browser.name ?
          <Tooltip title={(value.browser && value.browser.name)}> 
             <span style={{marginRight:'6px'}}>
                {value.browser.name && browserIcon}
             </span>
            </Tooltip>
          : null}


                <Tooltip title={value.crawler.category.replace('_', ' ').replace('-', ' ')}>
                  <span>
                <CPUIcon style={{width:'16px', height:'16px'}}/>
                  </span>       
                </Tooltip>
                </Grid>
            );
        }
    }

    if (value.type && value.type.indexOf('email-client') > -1) {

        if (value.browser.name) {
           
            return (
                <Grid className={classes.hover}>
          {value.browser && value.browser.name ?
              <Tooltip title={`App: ${value.browser.name}`}>
              <span>
            <CPUIcon style={{width:'16px', height:'16px'}}/>
              </span>       
            </Tooltip>
            : null
          }
        </Grid>
        );

                
        }
    }
    if (value.type && value.type.indexOf('app') > -1) {

        if (value.browser.name) {
           
            return (
                <Grid className={classes.hover}>
          {value.browser && value.browser.name ?
              <Tooltip title={`App: ${value.browser.name}`}>
              <span>
            <CPUIcon style={{width:'16px', height:'16px'}}/>
              </span>       
            </Tooltip>
            : null
          }
        </Grid>
        );

                
        }
    }

    if (value.type && value.type.indexOf('unknown') > -1) {
        return (

             <Tooltip title={(value.ua)}> 
             <span>
                <Typography>unknown</Typography>
             </span>
            </Tooltip>
           
        );
    }
    return <></>


    
}

export default SessionAgent;

