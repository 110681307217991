import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { getCurrentUser, setUpTOTP, signOut, fetchAuthSession, updatePassword, updateMFAPreference, verifyTOTPSetup } from 'aws-amplify/auth';

import {
    Typography,
    FormControl,
    InputLabel,
    OutlinedInput,
    InputAdornment,
    Button,
    Grid,
    FormHelperText,
    
} from "@material-ui/core";
import { useHistory } from "react-router-dom";

// import { PlusCircleIcon, MinusCircleIcon } from "icons";

import ErrorSnackBar from './ErrorSnackBar';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    paper: {
        padding: theme.spacing(2),
    },
}));



const AddDomain = ({api, dispatch}) => {
    let history = useHistory();
    const classes = useStyles();

    const [message, setMessage] = React.useState('');
    const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
    const [alertBarStatus, setAlertBarStatus] = React.useState('success');
    const [duplicateEntryError, setDuplicateEntryError] = React.useState('');
    const [processing, setProcessing] = React.useState(false);
    const [openForm, setOpenForm] = React.useState(false);

    /**
     * Set Formik validation and Submit Handler
     */
    const regex = /^(?!:\/\/)([a-zA-Z0-9]+\.)?([a-zA-Z0-9][a-zA-Z0-9-]+\.)+([a-zA-Z]{2,})+$/igm;

    Yup.addMethod(Yup.string, "domainNameValidation", function (errorMessage) {
        return this.test(`domain-name-validation`, errorMessage, function (value) {
          const { path, createError } = this;
          let m;

          let matches=[];

            while ((m = regex.exec(value)) !== null) {
                // This is necessary to avoid infinite loops with zero-width matches
                if (m.index === regex.lastIndex) {
                    regex.lastIndex++;
                }
                
                // The result can be accessed through the `m`-variable.
                m.forEach((match, groupIndex) => {
                    matches.push(match);
                    // console.log(`Found match, group ${groupIndex}: ${match}`);
                });
            }

            // if (value === 'localhost' || value === '127.0.0.1' || matches.length) {
            if (matches.length) {
                return true;
            } else {
                return createError({ path, message: errorMessage })
            }

        });
      });
    

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: '',
            autotune: false,
            maxSlowRequestPercent: 2,
            slowRequestThreshold: 5000,
            timeout: 20,
            id: undefined
        },
        validationSchema: Yup.object({
            name: Yup.string().required('Required')
            .domainNameValidation('This doesn’t appear to be a correctly formatted domain name'),
        }),
        onSubmit: async (values, {resetForm}) => {

            setProcessing(true);
            
            // check domain name:
            let domain_name = `https://${values.name}`;
            const { idToken } = await fetchAuthSession();
            let domainExists  = await api.checkDomain( idToken, {
                domain: domain_name
            });
            if (domainExists.exists) {
                setDuplicateEntryError(`Duplicate entry for ${domain_name}. Domain name must be unique.`);
                setProcessing(false);
            } else {

                if(values.timeout > 30) {
                    values.timeout = 30;
                }

                
                setDuplicateEntryError(false)
    
                let formattedData = {
                    name: domain_name
                }
    
               
                let mergedData = { ...values, ...formattedData };
                let result = await api.putDomain(mergedData, 'post');
    
                if (!result.error) {
                    setMessage('Domain added');
                    setOpenErrorSnackbar(true);
                    setAlertBarStatus('success');
                    await dispatch({ type: 'ADD_DOMAINS', payload: result });

                    // get the added domain
                    let addedDomain = result.reduce((acc, domain) => {
                        if (domain.name === formattedData.name) {
                            acc = domain.id;
                        }

                        return acc;
                    }, '');
                    
                    await dispatch({ type: 'UPDATE_SELECTED_DOMAIN', payload: addedDomain });
                    formik.setFieldValue('name', '');
                    resetForm()
                    setOpenForm(false)
                    setProcessing(false);

                    if(result.length === 1) {
                        history.push(`/`, {from:'addDomain'});
                    }
                } else {
                    if (result.error.indexOf('Duplicate entry') > -1) {
                        setDuplicateEntryError(`Duplicate entry for ${formattedData.name}. Domain name must be unique.`);
                    }
                    setMessage('Error adding domain');
                    setAlertBarStatus('error');
                    setOpenErrorSnackbar(true);
                    setProcessing(false);
                }
            }


           
        },
    });

    const closeErrorSnackbar = () => {
        setOpenErrorSnackbar(false)
    }


    return (
<>
        <Grid xs={12} item>
            <FormControl fullWidth margin="normal" variant="outlined">
                        <InputLabel htmlFor="title">Add domain name</InputLabel>
                        <OutlinedInput
                            id="name"
                            labelWidth={110}
                            placeholder="Domain name"
                            aria-label="Domain name"
                            aria-describedby="title"
                            {...formik.getFieldProps('name')}
                            startAdornment={<InputAdornment position="start" className={classes.slugAdornment}>{`https://`}</InputAdornment>}
                            endAdornment={
                                <Button
                                    style={{ marginLeft: 8 }}
                                    type="submit"
                                    disableElevation
                                    size="small"
                                    variant="contained"
                                    color="secondary"
                                    disabled={(processing || formik.errors.name) ? true : false}
                                    onClick={() => {
                                       formik.handleSubmit()
                                    }}
                                >
                                    Add</Button>
                            }
                        />

                        {formik.touched.name && formik.errors.name ? <Typography variant="subtitle2" color="error">{formik.errors.name}</Typography> : null}
                        {duplicateEntryError ? <Typography variant="subtitle2" color="error">{duplicateEntryError}</Typography> : null}

                        <FormHelperText>Fully qualified domain e.g. 'https://www.yourdomain.com'</FormHelperText>
                        <ErrorSnackBar message={message} open={openErrorSnackbar} status={alertBarStatus} closeErrorSnackbar={closeErrorSnackbar} />
                    </FormControl>

    
          </Grid>

       
        

            
           


</>

    )

}


export default AddDomain;