import React, { useState } from "react";
import {
    Typography,
    Grid,
    Paper,
    CardContent,
    CardHeader,
    CardActionArea,
    CardMedia,
    CardActions,
    Link,
    Button

} from "@material-ui/core";
import { CopyButton } from '../..';
import ErrorSnackBar from '../../ErrorSnackBar';
import { Link as RouterLink } from 'react-router-dom';

import { CheckCircleIcon, AlertCircleIcon, ToolIcon } from "icons";

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    CodeBlockHolder: {
        position: 'relative'
    },
    CodeBlockPre: {
        flexGrow: 1,
        overflowX: 'auto',
        margin: 0,
        height: '100%',
        whiteSpace: 'break-spaces',
        wordSpacing: 'normal',
        hyphens: 'none',
        border: '1px solid #eee',
        borderRadius: '4px',
        color: '#616161',
        display: 'inline-block',
        padding: '1em 4em 1em .5em',
        fontSize: '13px',
        background: '#f5f5f5',
        lineHeight: 1.5,
        wordBreak: 'break-all'

    },
    copyButton: {
        position: 'absolute',
        right: '5px',
        top: '5px',
    },
    domainError: {
        fontSize: '12px',
        textTransform: 'uppercase',
        fontWeight: 'bold',
        padding:'10px 0'
    }


}));


const DomainJavascriptOnboarding = ({ publicKey, jscheck, domainLink }) => {
    let [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
    let [message, setMessage] = useState(false);

    const closeErrorSnackbar = () => {
        setOpenErrorSnackbar(false)
    }

    const classes = useStyles();
    return (

        <Grid container spacing={4}>
            <Grid item xs={12}>

                <Paper elevation={0}>

                    <>

                        <Grid container justifyContent="space-between">

                        <Grid item>
                    <Typography variant="h6" component="p">Deployment: JavaScript</Typography>
                </Grid>

                            {jscheck && jscheck.results !== 'error'
                            ?
                            <Grid item>
                                {jscheck && jscheck.ch_key_correct && jscheck.ch_script
                                    ?
                                    <Grid container >
                                        <Grid item><CheckCircleIcon style={{ marginRight: '20px', color: 'green' }} /></Grid>
                                        <Grid item><Typography style={{ fontSize: 16, marginBottom: 12 }}>
                                            Tag detected
                                        </Typography></Grid>
                                    </Grid>

                                    : null}
                                {jscheck && !jscheck.ch_key_correct && jscheck.ch_script
                                    ?
                                    <Grid container >
                                        <Grid item><AlertCircleIcon style={{ marginRight: '20px', color: 'orange' }} /></Grid>
                                        <Grid item><Typography style={{ fontSize: 16, marginBottom: 12 }}>
                                            JavaScript detected but the api key is incorrect
                                        </Typography></Grid>
                                    </Grid>

                                    : null}
                                {jscheck && !jscheck.ch_key_correct && !jscheck.ch_script
                                    ?
                                    <>
                                    <Grid container >
                                        <Grid item><AlertCircleIcon style={{ marginRight: '20px', color: 'red' }} /></Grid>
                                        <Grid item>
                                            <Typography style={{ fontSize: 16, marginBottom: 12 }}>
                                            JavaScript is not detected on your site
                                            </Typography>
                                            <Typography style={{ fontSize: 12, marginBottom: 12, maxWidth: 400 }}>
                                            If you are using using JavaScript or GTM to install your CrowdHandler script then this warning is expected
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    </>

                                    : null}

                            </Grid>

                            : null}

                            {jscheck && jscheck.error && jscheck.error.toLowerCase() === 'network error'
                            ?
                            <Grid item>
                                    <Grid container >
                                        <Grid item><AlertCircleIcon style={{ marginRight: '20px', color: 'orange' }} /></Grid>
                                        <Grid item><Typography style={{ fontSize: 16, marginBottom: 12 }}>
                                        We were unable to check your javascript integration
                                        </Typography></Grid>
                                    </Grid>
                            </Grid>
                            :
                            null
                            }
                        </Grid>

{jscheck && jscheck.code && jscheck.code === 'ECONNREFUSED' ? <Grid><Typography variant="h6"  className={classes.domainError}>Unable to connect to the domain</Typography></Grid> : null }
{jscheck && jscheck.code && jscheck.code === 'ENOTFOUND' ? <Grid><Typography variant="h6"  className={classes.domainError}>Domain not found</Typography></Grid> : null }
{jscheck && jscheck.code && jscheck.code === 'ERR_BAD_REQUEST' ? <Grid><Typography variant="h6"  className={classes.domainError}>Unable to connect to the domain</Typography></Grid> : null }

                        <div>
                            <Grid className={classes.CodeBlockHolder}>
                            <Typography style={{ fontSize: 16, marginBottom: 12 }}>
                                Insert this tag into the head of all pages you wish to protect.
                                </Typography>
                                <CopyButton className={classes.copyButton} text={`<script src="https://wait.crowdhandler.com/js/latest/main.js?id=${publicKey}"></script>`} onCopy={() => {
                                    setMessage('Copied');
                                    setOpenErrorSnackbar(true);

                                }} />
                                <pre className={
                                    classes.CodeBlockPre
                                }>

                                    <code>
                                        {`<script src="https://wait.crowdhandler.com/js/latest/main.js?id=${publicKey}"></script>`}
                                    </code>
                                </pre>
                                
                            </Grid>


                        </div>

                        <Grid  style={{padding:'10px 0'}}>
                            <Typography variant="body2" color="textSecondary" component="p">
                                <Link target="_blank" href="https://www.crowdhandler.com/docs/80000186064-javascript-integration-getting-started">
                                    JavaScript Integration Guide
                                </Link>
                            </Typography>
                            <Typography>
                                It is important that this script is run as soon as possible so we recommend that you insert it before all other script tags
                            </Typography>
                        </Grid>

                        {domainLink
                        ?
                        <Grid item xs={12}>
                            <Button startIcon={<ToolIcon/>} to={domainLink} component={RouterLink} color="secondary" variant="contained" disableElevation>Deployment Settings</Button>

                        </Grid>
                        // <Typography variant="body2" color="textSecondary" component="p" style={{padding:'0 0 10px 0'}}><RouterLink to={domainLink} className="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary">Other Integration Options</RouterLink></Typography>
                        :
                        null
                        }
                        

                    </>








                </Paper>

            </Grid>
            <ErrorSnackBar message={message} status="success" open={openErrorSnackbar} closeErrorSnackbar={closeErrorSnackbar} />


        </Grid>
    )

}

export default DomainJavascriptOnboarding;