import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2)
    }
}));

const LoginHeader = props => {

    const classes = useStyles();

    return (
        <Grid
            className={classes.root}
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
        >
            <img src="/crowdhandler-logo--strip-blue.svg" alt="CrowdHandler" />
           
        </Grid>
    )
}
export default LoginHeader;