import React, { useEffect, useRef } from "react";
import Chart from 'chart.js/auto'
import { Grid } from '@material-ui/core';
import { HSLToHex } from '../../lib';

// Chart.defaults.global.defaultFontFamily = `'Lato',sans-serif`;
// Chart.defaults.global.elements.line.cubicInterpolationMode = 'monotone';


const AutoBlockDonut = ({data, title}) => {

    let chart = useRef(null);
    const currentChart = useRef();
    
    useEffect(() => {

        const setData = () => {
            
            if (data.data.length < 1) {
                data.data.push(10);
                data.labels.push(`No ${data.title}`);
                data.nodata = true;
            }

            const prop_data = {...data}

            console.log({prop_data});
            if(chart.current) {
                const hue_max = 360;
                let h_step = hue_max/data.labels.length;
                let h = 0;
                const backgroundColor = (data.nodata) ? '#cacaca' : data.labels.map((room) => {
                    let color = HSLToHex(h, 80, 40);
                    h = h+h_step;
                    return color;
                });


                
                let config = {
                    type: 'doughnut',
                    data : {
                        datasets : [{
                            data: data.data,
                            backgroundColor: backgroundColor,
                            borderColor: backgroundColor,
                        }],
                        labels: data.labels,
                    },
                    options: {
                        responsive: true,
                        maintainAspectRatio: true,
                        aspectRatio: 1,

                        borderWidth: 0,
                        plugins : {
                            title: {
                                display: true,
                                text: [title],
                                position: 'bottom',
                                fontColor: "black"
                            },
                            legend: {
                                display: false,
                            },
                            tooltip : {
                                callbacks: {
                                    footer: () => {
                                        return;
                                    },
                                    afterLabel : () => {
                                        return;
                                    },
                                    title: function() {
                                        return null;
                                      },
                                    label(data) {

                                        console.log({data});

                                        if (data.label === 'No FALSE POSITIVES (0)') {
                                            data.raw = 0;
                                            data.formattedValue = '0';
                                            data.parsed = 0;
                                            return data.label;
                                        } else {
                                            return `${data.label} (${data.parsed})`;
                                        }
                                        
                                        // const index = tooltip.index;
                                        
                                        // if (tooltip.index > -1) {
                                        //     let value = data.datasets[0].data[index];
                                        //     let label = `${data.labels[index]}`;
                                        //     if (!prop_data.nodata) {
                                        //         label = `${label} (${value})`;
                                        //     }
                    
                                        //     return label;
                                        // }
                                    }
                                }
                            },
                        },
                        animation: {
                            animateScale: false,
                            animateRotate: true,
                        }
                    }
                }
                
                if(currentChart.current) {
                    currentChart.current.destroy();
                }
                const myChart = new Chart(chart.current, config);

                currentChart.current = myChart;
                myChart.update()
            }

        }

        if (data.labels) {
            setData();
        }
    }, [data, title]);



    return (
        <Grid container className="chart-container" justifyContent="center" >
            <Grid item>
                <div style={{
                    position: "relative",
                    height:'20vh',
                    width:'20vh',
                    margin: '0 auto'
                }}>
                    <canvas ref={chart} style={{
                        // 'display': 'block',
                        // 'boxSizing': 'border-box',
                    }}></canvas>

                </div>
            </Grid>

        </Grid>
    )


}


export default AutoBlockDonut;