import React from 'react';
import { ReactComponent as ChevronDown } from './feather/chevron-down.svg';

import { SvgIcon } from '@material-ui/core';


export default function ChevronDownIcon(props) {
    return (
        <SvgIcon {...props} component={ChevronDown} className="feather"/>
    );
  }