import React, { useState, useEffect } from "react";
import {
    Typography,
    Grid,
} from "@material-ui/core";
import { CopyButton, ErrorSnackBar } from '../';


const Pending = ({ status, classes }) => {
    const [snackPack, setSnackPack] = useState([]);
    const [messageInfo, setMessageInfo] = useState(undefined);
    const [open, setOpen] = useState(false);
    useEffect(() => {
        if (snackPack.length && !messageInfo) {
          // Set a new snack when we don't have an active one
          setMessageInfo({ ...snackPack[0] });
          setSnackPack((prev) => prev.slice(1));
          setOpen(true);
        } else if (snackPack.length && messageInfo && open) {
          // Close an active snack when a new one is added
          setOpen(false);
        }
      }, [snackPack, messageInfo, open]);
    
      const handleClick = (message) => {
        setSnackPack((prev) => [...prev, { message, key: new Date().getTime() }]);
      };
    
      const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpen(false);
      };
    
      const handleExited = () => {
        setMessageInfo(undefined);
      };

    return (
        <Grid container alignItems="center">
            {status.distributionStatus && status.distributionStatus.results && status.originStatus ?
                <>
                    <Grid container spacing={2} className={classes.proxyInfoHeader}>
                        <Grid item>
                            <Typography variant="button">Traffic to {status.distributionStatus.results.domainName} is not yet traversing through CrowdHandler</Typography>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} className={classes.proxyInfo}>
                        <Grid item>

                            {status.originStatus.status !== 'ERROR'
                                ?
                                <Typography>
                                    Change your <strong>CNAME</strong> record for <code className={classes.code}>{status.distributionStatus.results.domainName}</code> to point to <code className={classes.code}>{status.distributionStatus.results.value}.
                                    <CopyButton text={status.distributionStatus.results.value} onCopy={(e) => {
                                            handleClick('Domain name copied');
                                        }} />
                                    </code>
                                </Typography>
                                :
                                <>
                                    <Typography>
                                        Add a <strong>CNAME</strong> record for <code className={classes.code}>{status.distributionStatus.results.domainName}
                                        <CopyButton text={status.distributionStatus.results.domainName} onCopy={(e) => {
                                            handleClick('Domain name copied');
                                        }} />
                                        
                                        </code> that points to <code className={classes.code}>{`${status.distributionStatus.results.value}.`}
                                        <CopyButton text={`${status.distributionStatus.results.value}.`} onCopy={(e) => {
                                            handleClick('Domain name copied');
                                        }} />
                                      
                                        </code>
                                    </Typography>
                                    <Typography>
                                        <strong>NB</strong>: You must then remove any existing <strong>A</strong> record for <code className={classes.code}>{status.distributionStatus.results.domainName}</code>
                                    </Typography>
                                </>
                            }
                        </Grid>

                    </Grid>


                </>
                : null}

        <ErrorSnackBar
            open={open}
            key={messageInfo ? messageInfo.key : undefined}
            autoHideDuration={2000}
            onClose={handleClose}
            onExited={handleExited}
            message={messageInfo ? messageInfo.message : undefined}
            status={'success'}
        />


        </Grid>
    )

}

export default Pending;