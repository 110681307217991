import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import {
    Card,
} from '@material-ui/core';

import { AlertTriangleIcon } from 'icons';


const useStyles = makeStyles((theme) => ({
    card: {
        padding:'12px',
        border: '1px solid #d3d3d3',
        margin: '10px 0',
        display: 'flex'
    },
    alertTriangle : {
        display: 'inline-block',
        margin: '0 12px' 
    }

}));

const CapacityWarningPanel = ({color, children}) => {
    const classes = useStyles();
    return (
        <Card className={classes.card} elevation={0}>
            <AlertTriangleIcon style={{marginRight: '12px', color: color}} />
            {children}
        </Card>
    )

}

export default CapacityWarningPanel;