import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { ReportDownload, UpgradeCTAPanel, ErrorBoundary, ReportMainChart } from '../../components';
import groupBy from 'lodash/groupBy';
import isArray from 'lodash/isArray';

import {
  Button,
  Container,
  Grid,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Typography,
  Link
} from '@material-ui/core';
import { DateTime } from 'luxon';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  buttonHeader: {
    padding: theme.spacing(0),
    marginBottom: theme.spacing(2),
  },
  margin: {
    marginBottom: theme.spacing(6),
  },
  smallmargin: {
    marginBottom: theme.spacing(2),
  },
  withoutLabel: {
    marginTop: theme.spacing(6),
  },
  domainAdornment: {
    marginRight: 0
  },
  spinner: {
    marginLeft: 22,
    color: theme.palette.secondary
  },

}));



const Reports = ({ api, state, dispatch }) => {
  const classes = useStyles();

  const [canUseReports, setCanUseReports] = useState((state.plan.allowReports) ? true : false);
  const [chartLoading, setChartLoading] = useState(true);
  const [dataForChart, setDataForChart] = useState({
    updating: false,
    result: []
  });
  const upgradeMessage = 'Reports not available on your current plan. To enable Reports please upgrade to a supporting plan.'
  const displayUpgradeButton = true
  useEffect(() => {
    const getData =  async () => {
      let rooms = await api.getRooms();
      dispatch({ type: 'UPDATE_ROOMS', payload: rooms });
    }

    getData();
 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
  <>

      {state.rooms && state.currentDomain
      ?

<>
            {
              (!canUseReports)
                ?
                <UpgradeCTAPanel upgradeMessage={upgradeMessage} displayUpgradeButton={displayUpgradeButton} />
                :
                <ReportMainChart state={state} api={api} loading={chartLoading} ></ReportMainChart>
                
            }

      </>
      :
      null
      }

  </>
  );

};

export default Reports;