import React from 'react';
import SaveIcon from '../icons/SaveIcon';
import { makeStyles } from '@material-ui/core/styles';
import { OutlinedInput, Tooltip, IconButton } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  saveIcon: {
    '& svg' : {
      strokeWidth: 1,
      color: theme.palette.grey[400],
    },
    '& svg:hover' : {
      cursor: 'pointer'
    }
  }  
}));



const InlineRowEditField = (props) => {
    let { changed, handleSave, setSaving, disableSave, title, ...rest } = { ...props };


    
    let classes = useStyles();
    return (
      <OutlinedInput
      className={classes.saveIcon}
        {...rest}
        inputProps={{
          title: title
        }}
        endAdornment={
          <Tooltip title="Save" aria-label="Save">
            <span className="tooltip-listener">
            <IconButton onClick={(event)=>{ handleSave(event); }} disabled={(disableSave) ? disableSave : false} >

          <SaveIcon
            style={(changed & !disableSave) ? {color:'green'} : null}
            
            
            />
          </IconButton>
            </span>
          </Tooltip>
        }
      />
    )
  }

  export default InlineRowEditField;
  