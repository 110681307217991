
import React from "react";

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Link
} from '@material-ui/core';

import { Link as RouterLink } from 'react-router-dom'


const RedirectDialog = ({ domain, openRedirectDialog, handleRedirectDialogClose, handleRedirectDialogAgree, followedRedirectUrl, domainId, redirectType, handleRedirectDialogCancel }) => {

    return (

        <Dialog
            open={openRedirectDialog}
            onClose={handleRedirectDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"Following Redirects Path"}</DialogTitle>

            {redirectType === 1

                ?
                <DialogContent>
                    <DialogContentText>
                        The following path will be used for the redirect url.
                    </DialogContentText>
                    <DialogContentText>
                        <strong>{followedRedirectUrl}</strong>
                    </DialogContentText>
                    <DialogContentText>
                        We re-wrote your URL to avoid breaking the CrowdHandler session. <Link target="blank" href="https://www.crowdhandler.com/docs/80000381623-crowdhandler-integration-is-required-on-redirect-page">You can learn more about this issue in our knowledge base article.</Link>
                    </DialogContentText>
                </DialogContent>
                :
                null
            }

            {/* GENERIC ERROR */}
            {redirectType === 2
                ?
                <DialogContent>
                    <DialogContentText>
                        <strong>{followedRedirectUrl} </strong> redirected to a URL we could not follow to a destination on your domain. This is indicative of a problem.
                    </DialogContentText>
                    <DialogContentText>
                       <Link target="blank" href="https://www.crowdhandler.com/docs/80000381623-crowdhandler-integration-is-required-on-redirect-page">You can learn more about this issue in our knowledge base article.</Link>
                    </DialogContentText>
                </DialogContent>
            :
                null
            }

            {/* REDIRECTLOOP ERROR */}
            {redirectType === 3
                ?
                <DialogContent>
                   
                    <DialogContentText>
                        <strong>{followedRedirectUrl} </strong> redirected to a URL we could not follow to a destination on your domain. This is indicative of a problem.
                    </DialogContentText>
                    <DialogContentText>
                       <Link target="blank" href="https://www.crowdhandler.com/docs/80000381623-crowdhandler-integration-is-required-on-redirect-page">You can learn more about this issue in our knowledge base article.</Link>
                    </DialogContentText>
                </DialogContent>
        
            :
                null
            }

            {/* TIMEOUT_ERROR ERROR */}
            {redirectType === 4
                ?
                <DialogContent>
                    <DialogContentText>
                    We encountered a timeout error testing your redirect URL for <strong>{domain.name}</strong>. This is indicative of a problem.
                    </DialogContentText>
                    <DialogContentText>
                       <Link target="blank" href="https://www.crowdhandler.com/docs/80000384282-use-cases-and-limitations-of-the-javascript-integration">The JavaScript Integration needs the server to be able to handle the first hit to the website.</Link>
                    </DialogContentText>
                </DialogContent>
            :
            null
        }


            {/* ENOTFOUND ERROR */}
            {redirectType === 5
            ?
            <DialogContent>
            
                <DialogContentText>
                    The domain <strong>{domain.name}</strong> couldn't be found to check your redirect path. This is indicative of a problem.
                </DialogContentText>
                <DialogContentText>
                <Link target="blank" href="https://www.crowdhandler.com/docs/80000381623-crowdhandler-integration-is-required-on-redirect-page">You can learn more about this issue in our knowledge base article.</Link>
                </DialogContentText>
            </DialogContent>

            :
            null
            }



            {redirectType === 6
                ?
                <DialogContent>
                   
                    <DialogContentText>
                        The domain <strong>{domain.name}</strong> redirected to <strong>{followedRedirectUrl} </strong>. This is indicative of a problem.
                    </DialogContentText>
                    <DialogContentText>
                       <Link target="blank" href="https://www.crowdhandler.com/docs/80000381623-crowdhandler-integration-is-required-on-redirect-page">You can learn more about this issue in our knowledge base article.</Link>
                    </DialogContentText>
                </DialogContent>
        
            :
                null
            }
            

               
                <DialogActions>
                    <Button onClick={handleRedirectDialogAgree} disableElevation variant="contained" color="secondary" autoFocus>
                        Save and Close
                    </Button>
                    <Button onClick={handleRedirectDialogClose} color="primary" autoFocus>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>

    )

}

export default RedirectDialog;



