import React from 'react';
import { ReactComponent as ExternalLink } from './feather/external-link.svg';

import { SvgIcon } from '@material-ui/core';


export default function ExternalLinkIcon(props) {
    return (
        <SvgIcon {...props} component={ExternalLink} className="feather"/>
    );
  }