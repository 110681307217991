import React from "react";
import {
    Typography,
    Grid,
   
    FormControl,
    FormHelperText,
    InputLabel,
    InputAdornment,
    OutlinedInput,
    Button,
    Link,
} from "@material-ui/core";


const Deployed = ({ status, classes }) => {
    
    return (
        <Grid container alignItems="center" spacing={2} >
            <>
                <Grid container className={classes.proxyInfoHeader}>
                    <Grid item>
                        <Typography variant="h6">CrowdHandler is enabled and receiving traffic</Typography>
                    </Grid>

                    {status.distributionStatus.results
                        ?
                        <Grid container direction="column" className={classes.proxyInfoHeader}>


                            <Grid item>
                                <Typography>Your domain <code className={classes.code} variant="subtitle1">{status.distributionStatus.results.domainName}</code></Typography>
                            </Grid>
                            <Grid item>
                            <Typography>is resolving to Crowdhandler endpoint <code className={classes.code} variant="subtitle1">{status.distributionStatus.results.value}</code></Typography>
                            </Grid>
                        </Grid>
                        :
                        null
                    }
                </Grid>
                
                
                



                {/* <Grid container spacing={2} className={classes.proxyInfo}>
                </Grid> */}
            </>
        </Grid>
    )
}

export default Deployed;