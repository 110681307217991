import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';

import {
    Tooltip,
    Grid,
} from '@material-ui/core';

import AlertTriangleIcon from '../icons/AlertTriangleIcon';
import GlobeIcon from '../icons/GlobeIcon';

const useStyles = makeStyles((theme) => ({
  hover : {
    cursor: 'pointer'
  }
}));

const IPData = ({ip_data}) => {

  const classes = useStyles();

    const formatAddress = (info) => {

        let address = [];
  
        if(info.city) {
          address.push(info.city);
        }
        if(info.region) {
          address.push(info.region);
        }
        if(info.country_name) {
          address.push(info.country_name);
        }
        if(info.country_code) {
          address.push(info.country_code);
        }
        if(info.continent_name) {
          address.push(info.continent_name);
        }
  
        return address.join(', ');
  
      }

    return (
      
      <Grid  className={classes.hover} container justifyContent="flex-start" alignItems="center" spacing={1}>
        {ip_data && ip_data.emoji_flag ?
        <Grid item>
        <Tooltip title={ `${formatAddress(ip_data)}`} aria-label={`${formatAddress(ip_data)}` }>
            <span style={{
              paddingBottom : '2px',
              position : 'relative',
              display: 'inline-block'
            }}>
            {ip_data.emoji_flag}
            </span>
        </Tooltip>
        </Grid>
        : null}

        {ip_data && ip_data.asn && ip_data.asn.name ?
        <Grid item>
        <Tooltip
          title={`${ip_data.asn.name}`}
          aria-label={`${ip_data.asn.name}`}
          >
            <span>
            <GlobeIcon width={16} height={16} style={{width: '16px', height:'16px'}}/>
              </span>
          </Tooltip>
          </Grid>
        : null}

        {ip_data && ip_data.threat && ip_data.threat.is_threat ?
        <Grid item>
        <Tooltip title={`Threat: YES`}>
          <span>
            <AlertTriangleIcon width={16} height={16} color='error' style={{width: '16px', height:'16px'}}/> 
          </span>
        
          </Tooltip>
          </Grid>
        : null}
      </Grid>

    )

}

export default IPData;