

const CalculateCurrentCapacityLimit = (rooms, max_waiting_room_capacity, room_capacity, isCatchall) => {
    let total_capacity_used = rooms.reduce((acc, room) => {
        acc = acc + parseInt(room.capacity, 10);
        return acc;
    }, 0);

    let available_capacity = max_waiting_room_capacity - total_capacity_used;
    return parseInt(available_capacity, 10) + parseInt(room_capacity, 10); // this is how much is available for this particular room;
}

export default CalculateCurrentCapacityLimit;