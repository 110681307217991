import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import isArray from 'lodash/isArray';
import XCircleIcon from '../../icons/XCircleIcon';
import MenuIcon from '../../icons/MenuIcon';
import CodesTable from '../../components/CodesTable';
import {codeGenerator} from '../../lib'

import {
  Container,
  Grid,
  Button,
  FormControl,
  TextField,
  FormHelperText,
  OutlinedInput,
  IconButton,
  Drawer,
  Backdrop,
  CircularProgress,
} from '@material-ui/core';

//https://material-ui.com/components/tables/#EnhancedTable.js

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  },
  margin: {
    margin: theme.spacing(6),
  },
  textarea: {
    fontFamily: 'monospace',
    fontSize: 14,
    lineHeight: 1.2
  },
  buttonHeader: {
    padding: theme.spacing(0),
    marginBottom: theme.spacing(2),
  },
  divider: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  generateCodesButton: {
    marginBottom: theme.spacing(1),
  },
  generateCodesOr: {
    '&:before': {
      content: '"–"'
    },
    '&:after': {
      content: '"–"'
    }
  },
  drawerContent: {
    width: '350px',
    padding: theme.spacing(1)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const QueueJumpManageCodes = props => {

  const { computedMatch, api } = { ...props };

  let [group, setGroup] = useState({name: ''});
  let [groupCodes, setGroupCodes] = useState([]);
  let [codesQuantity, setCodesQuantity] = useState(20);
  let [codesToInsert, setCodesToInsert] = useState('');
  let [groupId] = useState(computedMatch.params.groupId);
  let [drawerOpen, setDrawerOpen] = useState(false);
  let [processing, setProcessing] = useState(false);
  let classes = useStyles();

  
  useEffect(() => {
    /**
     * Get the initial group codes based on the groupID from `computedMatch.params.groupId`
     */
    const getGroupCodes = async () => {
      let codes = await api.getGroupsCodes(groupId);
      setGroupCodes(codes);
      /**
       * Now get & set the group that the codes belong to
       */
      let groups = await api.getGroups(groupId);
      for (let index = 0; index < groups.length; index++) {
        const element = groups[index];
        if (element.id === groupId) {
          setGroup(element);
        }
      }
    }
    getGroupCodes();
  }, [groupId, api]);

  /**
   * Generates a list of codes and saves them in state
   */
  const generateCodes = () => {
    let existingCodesArray = groupCodes.reduce((acc, group) => {
      acc.push(group.value);
      return acc;
    }, []);
    let codes = [];
    codes = codeGenerator(existingCodesArray, codesQuantity, codes);
    setCodesToInsert(codes.join('\n'));
  }

  /**
   * Adds codes to the group
   */
  const addCodes = async () => {
    setProcessing(true);
    toggleDrawer({
      key: false,
      type: false
    })

    // If we have no codes then bail
    if (codesToInsert.length < 1) {
      return false;
    }
    
    // Create an array of codes
    let codes = codesToInsert.split('\n');
    
    //Remove any non alpha-numeric characters
    codes = codes.map((code) => {
      return code.replace(/([^a-zA-Z0-9])/g, '');
    });
  
    // and join them with a comma to create a comma delimited string
    let codesString = codes.join(',');
    
    // Send the string to the endpoint to add the codes to the group
    let response = await api.postGroupsBatch({ codeValues: codesString }, groupId);
    setProcessing(false);

    if (response.length) {
      setGroupCodes(response);
      setCodesToInsert('');
    }
  }

  /**
   * 
   * @param {array|number} id 
   */
  const deleteCode = async (id) => {
    if (isArray(id)) {
      setProcessing(true);
      let response = await api.deleteCodeByIds(id, groupId );
      setProcessing(false);
      setGroupCodes(response);
    } else {
      let response = await api.deleteCode(id);
      setGroupCodes(response);
    }
  }

  const toggleDrawer = (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(!drawerOpen);
  }

  return (
    <div>

      <Drawer anchor="right" open={drawerOpen} onClose={(event) => { toggleDrawer(event) }}  className={classes.drawer}>
  
        <div style={{ padding: 4 }}>
        <Grid container className={classes.drawerContent} spacing={1}>

          <Grid item xs={12} align="right">
            <IconButton onClick={(event) => {
              toggleDrawer(event)
            }}>
              <XCircleIcon/>
            </IconButton>
            </Grid>
          <Grid item xs={12}>
            <FormHelperText>Paste or type a list of codes here or generate a number of codes automatically by selecting the quantity required and clicking on Generate Codes</FormHelperText>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth margin="dense" variant="outlined">
              <TextField
                id="codes-textarea"
                label="Codes"
                multiline
                rows={20}
                value={codesToInsert}
                variant="outlined"
                inputProps={{
                  className: classes.textarea
                }}
                onChange={(event) => { setCodesToInsert(event.target.value) }} />
            </FormControl>
            <Grid item align="right">

            <Button
              variant="outlined"
              size="small"
              disableElevation
              color="primary"
              onClick={() => {setCodesToInsert('')}}>
                Clear
              </Button>
            </Grid>

          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="center" alignItems="center">
            <FormHelperText>Select your quantity and click Generate Codes to automatically create the codes. The codes will not be added until you click Save Codes.</FormHelperText>

            <OutlinedInput
              type="number"
              margin="dense"
              className={classes.generateCodesButton}
              value={codesQuantity}
              fullWidth
              onChange={(event) => { setCodesQuantity(event.target.value) }}>

            </OutlinedInput>
         
            <Button
              variant="contained"
              size="small"
              disableElevation
              color="primary"
              className={classes.generateCodesButton}
              fullWidth
              onClick={generateCodes}>Generate Codes</Button>

            </Grid>
          </Grid>
          <Grid item align="right" >

            <Button
              disabled={codesToInsert.length < 1}
              variant="contained"
              disableElevation
              color="secondary"
              onClick={(event) => {addCodes()}}>Save Codes</Button>

          </Grid>
        </Grid>
        </div>
      </Drawer>
      <Container
        maxWidth="lg"
        spacing={4}
      >
        <Grid container spacing={4} justifyContent="space-between">
          <Grid item xs={12} align="right">
            <Button onClick={(event) => toggleDrawer(event)}
              variant="contained"
              disableElevation
              color="secondary"
              size="small"
              endIcon={<MenuIcon></MenuIcon>}
              >Add Codes</Button>
          </Grid>

          <Grid item xs={12}>
            <CodesTable groupName={group.name} groupCodes={groupCodes} deleteCode={deleteCode} />
          </Grid>
        </Grid>

      </Container>

      <Backdrop className={classes.backdrop} open={processing}>
        <CircularProgress color="inherit" />
      </Backdrop>

    </div>
  );
};

export default QueueJumpManageCodes;