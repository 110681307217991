// import MuiButton from './MuiButton';
// import MuiIconButton from './MuiIconButton';
// import MuiPaper from './MuiPaper';
// import MuiTableCell from './MuiTableCell';
// import MuiTableBody from './MuiTableBody';
// import MuiTableHead from './MuiTableHead';
// import MuiTypography from './MuiTypography';
// import MuiFormControl from './MuiFormControl';
// import MuiDrawer from './MuiDrawer';
// import MuiFormHelperText from './MuiFormHelperText';
import MuiSvgIcon from './MuiSvgIcon';
// import MuiInputBase from './MuiInputBase';
import MuiOutlinedInput from './MuiOutlinedInput';
import MuiButtonBase from './MuiButtonBase';
import MuiCardHeader from './MuiCardHeader';

export default {
  // MuiDrawer,
  // MuiTypography,
  // // MuiButton,
  // MuiFormHelperText,
  // MuiTableCell,
  // MuiTableBody,
  MuiSvgIcon,
  MuiOutlinedInput,
  MuiButtonBase,
  MuiCardHeader
   // MuiInputBase
};
// export default {
//   MuiDrawer,
//   MuiButton,
//   MuiIconButton,
//   MuiPaper,
//   MuiTableHead,
//   MuiTypography,
//   MuiFormControl
// };
