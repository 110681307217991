import React, {useState} from 'react'
import XIcon from '../icons/XIcon';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Popper from '@material-ui/core/Popper';
const HelpPopper = ({showPopper, setShowPopper, anchorEl, message}) => {

    return (

        <Popper id="tooltip" open={showPopper} anchorEl={anchorEl} placement="bottom" disablePortal={true}
                    modifiers={{
                      flip: {
                        enabled: true,
                      },
                      arrow: {
                        enabled: true,
                        element: '#arrow',
                      },
                      offset: {
                        offset: '0, 20',

                      }
                    }}>
                      <div id="arrow" style={{
                        background: '#206D9C',
                      }}></div>
                      <Grid container>
                    <Grid style={{
                      backgroundColor: '#206D9C',
                      color: 'white',
                      padding: '16px',
                      borderRadius: '6px'
                    }}>
                      <div className="ch-popper-close"

                        onClick={() => {
                          setShowPopper(false)
                        }}>
                          <XIcon style={{width: '18px', height: '18px'}}/>
                      </div>
                      {message}
                      
                    </Grid>
                    </Grid>
                  </Popper>

                    
    )
}

export default HelpPopper;