import React from "react";
import {
    Grid,
} from '@material-ui/core';
import OnboardStepper from '../OnboardStepper';
import {TypeWriterComponent} from '../../';

const ExplainRatePanel = () => {

return (
    <Grid>

        {/* <LinearProgress variant="determinate" value={((pulse/60)*100) / 4} /> */}

        <OnboardStepper step={2}/>

        <TypeWriterComponent strings={[
          `Use the slider to the left to increase the rate.`,
          `This is the rate at which users should be allowed onto this domain, before they are required to wait in a waiting room.`,
          'A good rate to start with is 10.',
        ]}
      />





        

    </Grid>
)

}

export default ExplainRatePanel;