import React, {useState} from 'react';
import PaperClipIcon from '../icons/PaperClipIcon';

import {
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Backdrop,
  CircularProgress,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';



const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const TemplateUploadField = ({template, updateTemplate, maxFileSize}) => {


    let iconToUse = <PaperClipIcon/>;
    let idAtt = (template.id) ? `raised-button-file-${template.id}` : 'newTemplate-field';
    let buttonText = (template.id) ? `Update` : 'Select';

    let [templateId, setTemplateId] = useState((template.id) ? template.id : undefined);
    let [open, setOpen] = useState(false);
    let [file, setFile] = useState('');
    let [error, setError] = useState('');
    let [showLoader, setShowLoader] = useState(false);

    const classes = useStyles();


    const handleChange = (event) => {

      if (event.currentTarget.files[0]){

        const file = event.currentTarget.files[0]
        if(file.size > maxFileSize) {
          setError('File size exceeds limit');
          return false;
        } else {
          setError();
          setFile(file);
          setOpen(true);
          clearInputFile(document.getElementById(idAtt));

        }
        
      }
    }

    const handleOk = () => {

      if(file) {
        updateTemplate({templateId, file});
        setOpen(false);
        clearInputFile(document.getElementById(idAtt));
        setFile(undefined);
      }
    }

    const handleCancel = () => {
      setOpen(false);
      clearInputFile(document.getElementById(idAtt))
      setFile(undefined);
    };

    const clearInputFile = (f) => {
      if(f.value){
          try{
              f.value = ''; //for IE11, latest Chrome/Firefox/Opera...
          }catch(err){ }
          if(f.value){ //for IE5 ~ IE10
              var form = document.createElement('form'),
                  parentNode = f.parentNode, ref = f.nextSibling;
              form.appendChild(f);
              form.reset();
              parentNode.insertBefore(f,ref);
          }
      }
  }
  
    return (
      <>
        <label htmlFor={idAtt}>
          <Button fullWidth component="span" disableElevation size="small" variant="contained" color="secondary"
            endIcon={iconToUse}
          >
            {buttonText}
          </Button>

          {error ?  <Typography variant="subtitle2" color="error">{error}</Typography> : null}

          {file ? <Typography>{file.name}</Typography> : null}
        </label>
        <input accept="text/html"
        id={idAtt}
        type="file"
        onClick={(event)=>{setFile(undefined)}}
        onChange={(event)=>{handleChange(event)}}
        style={{ 'display': 'none' }}
        ></input>

<Dialog
      disableEscapeKeyDown
      maxWidth="xs"
      aria-labelledby="override-template-dialog-title"
      open={open}
      onClose={(event, reason) => {
        if (reason === 'backdropClick') {
          return;
        }
        setOpen(false);
      }}
    >
      <DialogTitle id="confirmation-dialog-title">Update Template?</DialogTitle>
      <DialogContent dividers>
        {file 
        ?
        <Typography>Update {template.name} with {file.name}?</Typography>
        :
        null
        }
       
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={handleOk} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>

    <Backdrop className={classes.backdrop} open={showLoader}>
        <CircularProgress color="inherit" />
    </Backdrop>

      </>
    )
  }

  export default TemplateUploadField;