import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { TableCell, TableHead, TableRow, TableSortLabel } from '@material-ui/core';

const useStyles = makeStyles({
    cell: {
      wordWrap: 'break-word',
      padding: '8px'
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  });

const EnhancedTableHead = (props) => {
  
    const { order, orderBy, onRequestSort, headCells } = {...props};

    const classes = useStyles();
  
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
  
    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={ headCell.align !== undefined ? headCell.align : headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
              width={headCell.width ? headCell.width : 'auto'}
              colSpan={headCell.colspan ? headCell.colspan : null}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    )
};

export default EnhancedTableHead;
