import React from 'react';
import { ReactComponent as CheckCircle } from './feather/check-circle.svg';

import { SvgIcon } from '@material-ui/core';


export default function CheckCircleIcon(props) {
    return (
        <SvgIcon {...props} component={CheckCircle} className="feather"/>
    );
  }