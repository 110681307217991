import React from "react";
import {
    Typography,
    Grid,
    Paper,
    Link,

} from "@material-ui/core";


const DomainWordPressOnboarding = () => {
    return (

        <Grid container spacing={4}  >
            <Grid item xs={12}>
                <Paper elevation={0}>
                    <Typography variant="body2" color="textSecondary" component="p">
                    To install the WordPress plugin check out our guide:<br/><Link target="_blank" href="https://www.crowdhandler.com/docs/80000903583-wordpress-plugin-getting-started">
                    WordPress Integration - Getting Started</Link>
                    </Typography>
                </Paper>
            </Grid>
        </Grid>
    )

}

export default DomainWordPressOnboarding;