import { useState, useEffect, useContext } from 'react';
import {AuthContext} from '../../providers/AuthProvider';

const useHasSession = () => {

    const chAuth = useContext(AuthContext);
    const [checked, setChecked] = useState(false);
    const [isOnline, setIsOnline] = useState(false);
    useEffect(() => {
        

        const hasSession = async () => {
            let response = await chAuth.currentSession();

            // check the session is valid
            if(response && response.session) {                
                setIsOnline(true)
            } else {
                setIsOnline(false)
            }
            
            setChecked(true)
        }

        hasSession();

    }, []);

    return {isOnline, checked};
  }

  export default useHasSession;