import React from 'react';
import PropTypes from 'prop-types';
import { GlobalHeader, GlobalFooter } from '../components';
import { Container } from '@material-ui/core';

const Main = props => {
    const { children, state } = props;


    return (
        <>
            <GlobalHeader />
            <Container spacing={0}>
                {children}
            </Container>
            <GlobalFooter align="flex-end" state={state}/>

        </>
    )

}

Main.propTypes = {
    children: PropTypes.node
};

export default Main;