import React from "react";
import {
    Typography,
    Grid,
    Paper,
    CardContent,
    CardHeader,
    CardActionArea,
    CardMedia,
    CardActions,
    Link,
    Button

} from "@material-ui/core";


const DomainCloudFrontOnboarding = () => {
    return (

        <Grid container spacing={4}  >
            <Grid item xs={12}>

            <Paper elevation={0}>
       
          <Typography variant="body2" color="textSecondary" component="p">
          To use AWS CloudFront as an integration check out or guide:<br/><Link target="_blank" href="https://www.crowdhandler.com/docs/80000833957-aws-cloudfront-integration-getting-started">
                        AWS CloudFront Integration - Getting Started
                    </Link>
          </Typography>
       
    </Paper>

            </Grid>
        </Grid>
    )

}

export default DomainCloudFrontOnboarding;