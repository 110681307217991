import React, { useState, useEffect, useContext, memo, useRef, useCallback } from "react";
import Config from '../../config'
import { AuthContext } from '../../providers/AuthProvider.js';
import { DateTime } from 'luxon';

import {
    IconButton,
    CircularProgress,
    Tooltip,
} from '@material-ui/core';

import ExternalLinkIcon from '../../icons/ExternalLinkIcon';



const RoomWRLink = memo((props) => {

    const chAuth = useContext(AuthContext);
    const [roomLive, setRoomLive] = useState(false);
    const [inTheFuture, setInTheFuture] = useState(false);

    let countdownTime = DateTime.fromISO(props.countDown);
    var now = DateTime.local();
    let diff = now.diff(countdownTime, ['minutes']);

    const color = props.color? props.color : 'inherit';


    const checking = useRef(false);

   
    const dispatchRoomState = useCallback(() => {
       
        if (props.dispatch && props.room) {

            props.dispatch({type:'UPDATE_ROOM', payload: {
                ...props.room,
                isPropagated: true
            }})
        }
        
    },[props]);


    useEffect(() => {

        const getWaitingRoomLive = async () => {

            const url = `${Config.CH_WR_ENDPOINT}${props.slug}`
            const { idToken } = await chAuth.getIDToken();
            const res = await props.api.getRoomLive(idToken.getJwtToken(), url);

            if (res.status === 200) {
                setRoomLive(true);
                dispatchRoomState();
                
            } else {
                let int_id = setInterval(async () => {
                    let int_res = await props.api.getRoomLive(idToken.getJwtToken(), url);
                    if (int_res.status === 200) {
                        setRoomLive(true);
                        clearInterval(int_id);
                    }
                }, 10000);
            }

        }

        if (props.room && props.room.isPropagated) {
            setRoomLive(true);
        } else if (diff.values.minutes < 0) {
            setInTheFuture(true);
        } else if (props.slug && props.slug !== '' && diff.values.minutes < 2 && !checking.current) {
            
            checking.current = true;
            getWaitingRoomLive();
        } else if (diff.values.minutes >= 2) {
            setRoomLive(true);
            dispatchRoomState();
        }

        return () => {

        };

    }, [chAuth, diff.values.minutes, props.api, props.slug, props.room, dispatchRoomState]);


    const returnButton = () => {

        if (props.room && props.room.patternType === 'disabled') {
            return (<Tooltip title="Room disabled" aria-label="Room disabled" >
                <span>
                    <IconButton disabled={true}>
                        <ExternalLinkIcon />
                    </IconButton>
                </span>
            </Tooltip>)
        }

        if (roomLive) {
            return (<Tooltip title="View Waiting Room" aria-label="View Waiting Room" className={ props.cssClasses? props.cssClasses : ''}>
                <IconButton onClick={props.onClick} component="a" href={`${Config.CH_WR_ENDPOINT}${props.slug}`} target="_blank">
                    <ExternalLinkIcon  color={color}/>
                </IconButton>
            </Tooltip>)
        }

        if (inTheFuture) {
            return (<Tooltip title="Room inactive" aria-label="Room inactive">
                <span>
                    <IconButton disabled={true}>
                        <ExternalLinkIcon />
                    </IconButton>
                </span>
            </Tooltip>)
        }



        return (<Tooltip title="View Waiting Room" aria-label="Propagating">
            <CircularProgress size={20} color="primary" />
        </Tooltip>)
    }


    return returnButton();



});

export default RoomWRLink;