import React from 'react';
import { ReactComponent as Globe } from './feather/globe.svg';

import { SvgIcon } from '@material-ui/core';


export default function GlobeIcon(props) {
    return (
        <SvgIcon {...props} component={Globe} className="feather"/>
    );
  }