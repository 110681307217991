import React from 'react';
import { Button } from '@material-ui/core';


const PrimaryButton = (props) => {
    return (
        <Button
        disableElevation
        color="secondary"
        size="small"
        variant="contained"
        {...props}
        ></Button>
    )
}

export default PrimaryButton;