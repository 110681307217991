import React from 'react';
import { ReactComponent as Download } from './feather/download.svg';

import { SvgIcon } from '@material-ui/core';


export default function DownloadIcon(props) {
    return (
        <SvgIcon {...props} component={Download} className="feather" />
    );
  }