
export default {
  root: {
    fontFamily: "'Inconsolata', monospace",
    '& .MuiCardHeader-content' : {
      '& .MuiTypography-h5' : {
        margin: 0
      }
    }
  },
};
