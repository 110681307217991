import React from 'react';
import { ReactComponent as PlusCircle } from './feather/plus-circle.svg';

import { SvgIcon } from '@material-ui/core';


export default function PlusCircleIcon(props) {
    return (
        <SvgIcon {...props} component={PlusCircle} className="feather"/>
    );
  }