import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { useLocation, withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { SessionsTableBasic, ErrorSnackBar, SessionFilters, SessionRequestTable, SessionAgentDialog } from '../../components';
import { DateTime } from 'luxon';
import {
  Typography,
  Container,
  Grid,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Button,
  Backdrop,
  OutlinedInput,
} from '@material-ui/core';
import isArray from 'lodash/isArray';

import buildSessionsQueryString from "lib/buildSessionsQueryString";


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(6),
  },
  paper: {
    padding: theme.spacing(2),
  },
  noUnderline: {
    '& .MuiInput-underline:before': {
      display: 'none'
    },
    '& .MuiInput-underline:after': {
      display: 'none'
    }
  },
  smallmargin: {
    marginBottom: theme.spacing(2),
  },
  withoutLabel: {
    marginTop: theme.spacing(6),
  },
  domainAdornment: {
    marginRight: 0
  },
  spinner: {
    marginLeft: 22,
    color: theme.palette.secondary
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.primary.main,
  },

}));


function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}


const RoomSessions = ({ state, computedMatch, api, dispatch, ...rest }) => {

  let { history } = { ...rest };

  let [openAgentDialog, setOpenAgentDialog] = useState(false);
  let [agentData, setAgentData] = useState(null);

  let hasAnomalyDetection = state.hasAnomalyDetection;

  let query = useQuery();
  const location = useLocation();

  const classes = useStyles();
  const [loadingData, setLoadingData] = React.useState(true);
  const [fetchingRequests, setFetchingRequests] = React.useState(false);
  const [requests, setRequests] = React.useState([]);
  const [requestToken, setRequestToken] = React.useState([]);
  const [requestSession, setRequestSession] = React.useState([]);

  const [ipIsInBlockList, setIpIsInBlockList] = React.useState(false);
  const [hasAutoblockData, setHasAutoblockData] = React.useState(null);

  let filtersSet = useRef(false);

  let mounted = useRef(true);


  let [message, setMessage] = useState('');
  let [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  let [alertBarStatus, setAlertBarStatus] = useState('success');
  const [open, setOpen] = React.useState(false);
  let [defaultOrder, setDefaultOrder] = useState({ value: 'position' });



  let [initialFilters, setinitialFilters] = useState();

  let [filters, setFilters] = useState();

  let [queryParams, setQueryParams] = useState();

  const buildQueryString = useCallback((data) => {
    const qs = buildSessionsQueryString(data, state.hasAnomalyDetection);
    return qs;
  }, [state.hasAnomalyDetection]);

  const setUpDefaultState = useCallback(() => {

    let domainID = 'all';
    let roomID;
    let archivedUsers;
    let includeActiveUsers;
    let search_query;
    let minScore;
    let page;
    let pageSize;
    let saved_data;
  
    if(!location.state) {
      // we've come in fresh
      // do we have saved data?
      
      try {
        saved_data = JSON.parse(window.sessionStorage.getItem('ch_session_filters'));
      } catch {
        saved_data = undefined;
      }

      if (saved_data) {
        roomID = (saved_data.roomID) ? saved_data.roomID : 'all';
        domainID = (saved_data.domainID) ? saved_data.domainID : 'all';
        archivedUsers = (saved_data.archivedUsers) ? parseInt(saved_data.archivedUsers, 10) : 0;
        includeActiveUsers = (saved_data.includeActiveUsers) ? parseInt(saved_data.includeActiveUsers, 10) : 1;
        search_query = (saved_data.search) ? saved_data.search : '';
        minScore = (saved_data.minScore) ? parseInt(saved_data.minScore, 10) : 0;
        page = (saved_data.page) ? parseInt(saved_data.page, 10) : 0;
        pageSize = (saved_data.pageSize) ? parseInt(saved_data.pageSize, 10) : 200;
      } else {
        roomID = 'all';
        domainID = 'all';
        archivedUsers = 0;
        includeActiveUsers = 1;
        search_query = '';
        minScore = 0;
        page = 0;
        pageSize = 200;
      }


      if (state.domains && state.domains.length === 1) {
        domainID = state.domains[0].id;
      }
  
      if (location.state && location.state.domainID) {
        domainID = location.state.domainID;
      }

      if (roomID !== 'all') {
  
        let found_room = state.rooms.find(room => room.id === roomID);
        if (found_room) {
          domainID = found_room.domainID;
        }
      }

    } else {
      roomID = (location.state && location.state.roomID) ? location.state.roomID : 'all';
      
  
      if (state.domains && state.domains.length === 1) {
        domainID = state.domains[0].id;
      }
  
      if (location.state && location.state.domainID) {
        domainID = location.state.domainID;
      }
  
      archivedUsers = (location.state && location.state.archivedUsers) ? parseInt(location.state.archivedUsers, 10) : 0;
      includeActiveUsers = (location.state &&  location.state.includeActiveUsers) ? parseInt(location.state.includeActiveUsers, 10) : 1;
      search_query = (location.state &&  location.state.search) ? location.state.search : '';
      minScore = (location.state &&  location.state.minScore) ? parseInt(location.state.minScore, 10) : 0;
      page = (location.state &&  location.state.page) ? parseInt(location.state.page, 10) : 0;
      pageSize = (location.state &&  location.state.pageSize) ? parseInt(location.state.pageSize, 10) : 200;

    }

    // do we have a room id? If yes then set the domain to the room domain
    if (roomID !== 'all') {
  
      let found_room = state.rooms.find(room => room.id === roomID);
      if (found_room) {
        domainID = found_room.domainID;
      }
    }

    if (archivedUsers === 1) {
      pageSize = 1000;
      includeActiveUsers = 2;
    }


    // finally check for queryparams
    let params = new URLSearchParams(location.search);

    roomID = params.get('roomID') ? params.get('roomID') : roomID;
    domainID = params.get('domainID') ? params.get('domainID') : domainID;
    includeActiveUsers = params.get('includeActiveUsers') ? params.get('includeActiveUsers') : includeActiveUsers;
    archivedUsers = params.get('archivedUsers') ? params.get('archivedUsers') : archivedUsers;
    search_query = params.get('search') ? params.get('search') : search_query;


    let formatted_data = {
      roomID: roomID,
      domainID: domainID,
      includeActiveUsers: includeActiveUsers,
      search: search_query,
      minScore: minScore,
      archivedUsers: archivedUsers,
      page: page,
      pageSize: pageSize,
    }

    let filters_data = {
      ...initialFilters,
      ...formatted_data,
      initialized: true
    }

    setinitialFilters({
      ...filters_data
    });


  }, [location, state.domains, state.rooms, initialFilters])



  useEffect(() => {


    const initialize = () => {
      // check that we have any query terms
      // minimum are roomId and domainID
      // so if we dont have a domain or a room
      // see if we have saved data in session storage

      setUpDefaultState();

    }
    
    if (state.rooms && state.domains && !filtersSet.current) {
      filtersSet.current = true;
      initialize()
    }

    return () => {
      mounted.current = false;
    }


  },[query, state.domains, location, state.rooms, initialFilters, setUpDefaultState]);



  useEffect(() => {
    if (location.state) {
      setQueryParams(() => {
        return location.state;
      });
    } else if (initialFilters && initialFilters.initialized) {
      setQueryParams((prev) => {
        return {
          ...prev,
          ...initialFilters
        };
      });
    }
    
  }, [location.state, initialFilters]);
  
  
  useEffect(() => {    
    if (initialFilters && initialFilters.initialized) {
      setFilters(() => {
        return initialFilters;
      });
    }
    
  }, [initialFilters]);
  
  useEffect(() => {

    // need a default otherwise the UI will complain
    const default_filters = {
      roomID: 'all',
      domainID: 'all',
      includeActiveUsers: 1,
      archivedUsers: 0,
      search: '',
      page: 0,
      pageSize: 200,
      minScore: 0,
      initialized: false
    }

    if (location.state && !initialFilters) {
      setFilters((prev) => {
       return {
        ...prev,
        ...default_filters,
        ...location.state
       }
      });
    } else if (!location.state && initialFilters) {
      setFilters((prev) => {
       return {
        ...prev,
        ...default_filters,
        ...initialFilters
       }
      });

    } else if (location.state && initialFilters) {
      setFilters((prev) => {
        return {
         ...prev,
         ...default_filters,
         ...initialFilters,
         ...location.state,
        }
       });
    }
  }, [location.state, initialFilters]);


  useEffect(() => {

    const getSessions = async () => {
      try {
        let queryString = `?${buildQueryString(queryParams)}`;

        if (parseInt(queryParams.archivedUsers, 10) === 1) {
          setDefaultOrder({value:'score'})
        }

         window.sessionStorage.setItem('ch_session_filters', JSON.stringify(queryParams));

        let sessions = await api.getSessions(queryString);
        
        let today = DateTime.local().startOf('day').toISO();
        const session_date_format = { month: 'short', day: 'numeric', hour: "numeric", minute: "numeric", second: 'numeric', hour12: true, }
  
        if (!sessions.error || sessions.length) {
          sessions = sessions.map((session, index) => {
            session.id = index;
            session.active = true;
            let joinedTime = DateTime.fromISO(session.joined, { zone: 'local' });
            let touchedTime = DateTime.fromISO(session.touched, { zone: 'local' });
  
            session.joinedTime = (joinedTime.startOf('day').toISO() !== today)
              ?
              `${joinedTime.toLocaleString(session_date_format)}`
              :
              `${joinedTime.toLocaleString(DateTime.TIME_WITH_SECONDS)}`
              ;
  
            session.touchedTime = (touchedTime.startOf('day').toISO() !== today)
              ?
              `${touchedTime.toLocaleString(session_date_format)}`
              :
              `${touchedTime.toLocaleString(DateTime.TIME_WITH_SECONDS)}`
              ;
  
            session.ip_data = undefined;
            return session;
          });
  
          dispatch({ type: 'SET_SESSIONS', payload: sessions });

          setLoadingData(false);
  
        } else {
          setOpenErrorSnackbar(true);
          if (sessions && sessions.error) {
            setMessage('Error updating sessions feed');
          } else {
            setMessage('Error retrieving sessions');
          }
          setAlertBarStatus('error');
          setLoadingData(false);
        }
        
      } catch (error) {
        setOpenErrorSnackbar(true);
        setMessage('Error retrieving sessions');
        setAlertBarStatus('error');
        setLoadingData(false);
      }
    }

    if(queryParams) {
      setLoadingData(true);
      getSessions()
    }
  }, [api, buildQueryString, dispatch, queryParams]);

  const handleFilterChange = (target, value) => {
    let updated_data = {
      [target]: value
    }

    if (target === 'domainID') { // domain has changed so we need to set the room too
      let rooms = state.rooms.filter((room) => {
        return room.domainID === value;
      });

      let { roomID } = { ...filters };
      let currentRoomExists = rooms.find((room) => {
        return room.id === roomID;
      })

      if (currentRoomExists) {
        roomID = currentRoomExists.id;
      }
      else if (!currentRoomExists && rooms.length) {
        roomID = 'all';
      }
      updated_data = {
        ...updated_data,
        roomID
      }
    }

    setFilters((prevFilters) => {
      return {
        ...prevFilters,
        ...updated_data
      }
    })
  }

  const filteredRooms = useMemo(() => {

    if (!state.rooms || !filters) {
      return []
    }
    return state.rooms.filter((room) => {
      return room.domainID === filters.domainID;
    });
  }, [filters, state.rooms]);

  // // Call getSessions when the filters.initialized property changes
  // useEffect(() => {

  //   const getSessions = async () => {

  //     let data = { ...queryParams };

  //     if (data.includeActiveUsers !== 1) {
  //       delete (data.includeActiveUsers)
  //     }

  //     if (data.archivedUsers === 0) {
  //       delete (data.archivedUsers)
  //     }
  //     // const qs = buildQueryString(data);
  //     // setQueryString((prevString) => {
  //     //   if (prevString !== `?${qs}`) {
  //     //     // history.push(`${history.location.pathname}?${qs}`);
  //     //   }
  //     //   return `?${qs}`;
  //     // });
  //   }

  //   if (queryParams) {
  //     getSessions()
  //   }


  // }, [api, buildQueryString, dispatch, queryParams]);


  const removeRoomSession = (token) => {
    let newList = state.sessions.reduce((acc, session) => {
      if (session.token === token) {

        session.priority = 0;
      }
      acc.push(session);
      return acc;
    }, []);
    dispatch({ type: 'SET_SESSIONS', payload: newList });
  }

  const setSessionActive = (token, isActive) => {
    let newList = state.sessions.map((session) => {
      if (session.token === token) {
        session.active = isActive;
      }
      return session;
    }, []);

    dispatch({ type: 'SET_SESSIONS', payload: newList });
  }

  const deleteSessionHandler = async (token) => {

    setSessionActive(token, false)

    let response = await api.deleteSession(token);
    if (response.error) {
      setMessage('There has been a problem removing the session');
      setSessionActive(token, true);
      setAlertBarStatus('error');
      setOpenErrorSnackbar(true);
    } else {
      removeRoomSession(token.token);
      setAlertBarStatus('success');
      setMessage('The session has been deleted');
      setOpenErrorSnackbar(true);
    }


  }

  const showRequests = async (event, params) => {
    let { token, ip } = params;
    setFetchingRequests(true);
    setRequests([]);
    setRequestToken(token);

    let anomolyData = {};
    if (hasAnomalyDetection) {

      anomolyData = await api.getRequestScore(token);
      if (isArray(anomolyData)) {
        anomolyData = {};
      }

    }


    let session = state.sessions.filter((session) => { return session.token === token });
    session[0].anomolyData = anomolyData;
    setRequestSession(session[0]);
    let requests = await api.getRoomSessionsRequests(token);

    let autoblocks;
    try {
      autoblocks = await api.getAutoblocks(ip);

      if (autoblocks.error) {
        setHasAutoblockData(undefined)
      } else if (isArray(autoblocks)) {
        if (autoblocks.length) {
          setHasAutoblockData(autoblocks[0])
        } else {
          setHasAutoblockData(undefined)
        }
      } else {
        setHasAutoblockData(autoblocks)
      }

    } catch (error) {
      setHasAutoblockData(undefined)
    }

    let ips = [];
    let ip_is_in_ip_list = false;

    try {
      ips = await api.passIP();
    } catch (error) {

    }

    if (ips) {
      for (let i = 0; i < ips.length; i++) {
        if (ips[i].address === ip) {
          ip_is_in_ip_list = {
            ip,
            list: ips[i].list
          };
        }
      }
    }
    setIpIsInBlockList(ip_is_in_ip_list);

    requests = requests.map((request) => {
      let requestedTime = DateTime.fromISO(request.requested, { zone: 'local' });
      request.requestedTime = `${requestedTime.toLocaleString(DateTime.TIME_WITH_SECONDS)}`
      return request;
    });



    setRequests(requests);
    setFetchingRequests(false);
    setOpen(true);
  }

  const promoteSessionHandler = async (token) => {
    setSessionActive(token, false)
    let response = await api.putPromoteSession(token);
    if (response.error) {
      setMessage('There has been a problem promoting the session');
      setOpenErrorSnackbar(true);
      setAlertBarStatus('error');
      setSessionActive(token, true)
    } else {
      removeRoomSession(token);
      setAlertBarStatus('success');
      setMessage('The session has been promoted');
      setOpenErrorSnackbar(true);
    }
  }


  const handleClose = () => {
    setOpen(false);
  };

  const closeErrorSnackbar = () => {
    setOpenErrorSnackbar(false)
  }


  const handleFilterClick = () => {

    let data = { ...filters };

    if (data.includeActiveUsers === 2) {
      data.archivedUsers = 1;
      data.pageSize = 1000;
    } else {
      data.archivedUsers = 0;
      data.pageSize = 200;
    }

    let cache_buster = new Date().getTime();

    data.page = 0;
    data.request_id=cache_buster;

    const new_data = {
      ...initialFilters,
      ...data
    };

    history.push(`${history.location.pathname}`, new_data);

  }


  const addIPToListHandler = async (address, type, domains) => {

    let message;
    let datetime = `${DateTime.local().toLocaleString(DateTime.DATE_SHORT)}`;

    let domain = domains[0];


    switch (type) {
      case 'block':
        message = `Blocked from Sessions Screen on ${datetime}`;

        setAddIPMessaging({
          title: 'Block IP',
          buttonText: 'Block',
          message: <Typography variant="subtitle1" component="p">Block <code>{address}</code> from <code>{domain.name}</code>?</Typography>
        });
        break;

      case 'ignore':
        message = `Ignored from Sessions Screen on ${DateTime.local().toLocaleString(DateTime.DATE_SHORT)}`;
        setAddIPMessaging({
          title: 'Ignore IP',
          buttonText: 'Ignore',
          message: <Typography variant="subtitle1" component="p">Ignore <code>{address}</code> on <code>{domain.name}</code>?</Typography>
        });
        break;
      default:
        break;
    }

    try {

      setAddToListIpAddress({
        address,
        note: message,
        list: type,
        domainID: domain.id
      });

      setOpenAddIpToListConfirmation(true);


    } catch (error) {
      setLoadingData(false);
    }
  }

  let [openAddIpToListConfirmation, setOpenAddIpToListConfirmation] = useState(false);
  let [addToListIpAddress, setAddToListIpAddress] = useState({
    address: undefined,
    note: '',
    list: '',
    domainID: undefined
  });
  let [addIPMessaging, setAddIPMessaging] = useState({
    title: '',
    message: ''
  })

  const handleCloseBlockIpConfirmation = () => {
    setOpenAddIpToListConfirmation(false);
    setAddToListIpAddress({
      address: undefined,
      note: '',
      list: '',
      domainID: undefined
    });
  }

  const handleAddIPToList = async () => {


    let response = await api.moveIPToList(addToListIpAddress);

    if (response.error) {
      let message = 'Error Adding IP Address';
      if (response.error === ' invalid IP address') {
        message = 'Invalid IP Address';
      }
      setMessage(message);
      setOpenErrorSnackbar(true);
      setAlertBarStatus('error')
    } else if (response) {
      setMessage('IP Address Added')
      setOpenErrorSnackbar(true);
      setAlertBarStatus('success')
    }

    handleCloseBlockIpConfirmation();
  }




  return (

    <div className={classes.root}>

      <Container
        maxWidth={false}
        spacing={4}
      >

        <Grid item xs={12} style={{ marginBottom: '12px' }}>
          {filters ?
            <SessionFilters
              hasAnomalyDetection={hasAnomalyDetection}
              filters={filters}
              setFilters={setFilters}
              handleFilterChange={handleFilterChange}
              filteredRooms={filteredRooms}
              domains={state.domains}
              handleFilterClick={handleFilterClick}
            />
            :
            null}

        </Grid>
        <Grid item xs={12}>
          <SessionsTableBasic
            api={api}
            sessions={state.sessions}
            showRequests={showRequests}
            deleteSessionHandler={deleteSessionHandler}
            promoteSessionHandler={promoteSessionHandler}
            loading={loadingData}
            hasAnomalyDetection={hasAnomalyDetection}
            hasFirewall={state.plan.allowFirewall===1}
            setAgentData={setAgentData}
            setOpenAgentDialog={setOpenAgentDialog}
            defaultOrder={defaultOrder}
          />
        </Grid>
      </Container>


      <SessionAgentDialog
        agentData={agentData}
        openAgentDialog={openAgentDialog}
        handleClose={() => {
          setAgentData(null);
          setOpenAgentDialog(false);
        }}
      ></SessionAgentDialog>

      <ErrorSnackBar message={message} open={openErrorSnackbar} status={alertBarStatus} closeErrorSnackbar={closeErrorSnackbar} />

      <Dialog
        maxWidth="xs"

        aria-labelledby="confirmation-dialog-title"
        open={openAddIpToListConfirmation}
        onClose={handleCloseBlockIpConfirmation}
      >
        <DialogTitle id="confirmation-dialog-title">{addIPMessaging.title}</DialogTitle>
        <DialogContent dividers>
          {addIPMessaging.message}

          <Typography>Note:</Typography>
          <OutlinedInput fullWidth value={addToListIpAddress.note} onChange={
            (event) => {
              setAddToListIpAddress({
                ...addToListIpAddress,
                note: event.target.value
              })
            }
          }>

          </OutlinedInput>

        </DialogContent>
        <DialogActions>
          <Button autoFocus disableElevation onClick={handleCloseBlockIpConfirmation} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddIPToList} color="secondary"
            disableElevation
            variant="contained">
            {addIPMessaging.buttonText}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullScreen={true}
        // maxWidth="lg"
        aria-labelledby="confirmation-dialog-title"
        open={open}
        onClose={handleClose}

      >

        <DialogTitle id="confirmation-dialog-title">
          <Grid container justifyContent="space-between" alignContent="center" spacing={2}>
            <Grid item>
              <Typography variant="h5">{requestToken}</Typography>
            </Grid>
            <Grid item>
              <Button autoFocus onClick={handleClose} color="primary">
                Close
              </Button>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>


          <SessionRequestTable
            requestToken={requestToken}
            sessions={state.sessions}
            requests={requests}
            loadingData={loadingData}
            hasAnomalyDetection={hasAnomalyDetection}
            setAgentData={setAgentData}
            setOpenAgentDialog={setOpenAgentDialog}
            state={state}
            promoteSessionHandler={promoteSessionHandler}
            deleteSessionHandler={deleteSessionHandler}
            addIPToListHandler={addIPToListHandler}
            requestSession={requestSession}
            hasFirewall={state.plan.allowFirewall}
            ipIsInBlockList={ipIsInBlockList}
            hasAutoblockData={hasAutoblockData}
            queryParams={queryParams}
            api={api}
          >

          </SessionRequestTable>

        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Close
          </Button>

        </DialogActions>
      </Dialog>

      <Backdrop className={classes.backdrop} open={fetchingRequests}>
        <Grid container justifyContent="center" alignItems="center" direction="column" spacing={4}>
          <Grid item>
            <CircularProgress color="inherit" />
          </Grid>
        </Grid>
      </Backdrop>



    </div>
  );
};

export default withRouter(RoomSessions);

