import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTheme, withStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';
import { SavingBar } from '..';
import { AlertTriangleIcon } from "icons";


import {
    Grid,
    Slider,
    Divider,
    Switch,
    FormGroup,
    FormControlLabel,
    Tooltip,
    Typography,
    Link
} from '@material-ui/core';

import './DashboardSettings.css';
import {HelpToolTip} from '../HelpToolTip';
import theme from "theme";

import { DashboardAutotuneConfirm } from "./DashboardAutotuneConfirm";


const AlertTriangleIconOrange = withStyles({
    root: {
        color: theme.palette.secondary.main,
        marginRight: 10
    },
})(AlertTriangleIcon);




const DashboardSettings = ({ settings, handleSettingsChange, updateSettings, rooms, classes, state, displayEmptyRoomImage, hasRedSegments }) => {

    const theme = useTheme();

    // individual settings for the sliders state
    const [rate, setRate] = useState(0);
    const [autotune, setAutotune] = useState(false);
    const [slowRequestThreshold, setSlowRequestThreshold] = useState(0);
    const [maxSlowRequestPercent, setMaxSlowRequestPercent] = useState(0);
    const [allowAutotune, setAllowAutotune] = useState(false);
    const [hasAnActiveWaitingRoom, setHasAnActiveWaitingRoom] = useState(false);
    const [isShopifyDomain, setIShopifyDomain] = useState(false);
    const [openATConfirmation, setOpenATConfirmation] = useState(false);

    useEffect(()=>{
        if(settings.rate > state.plan.maxDomainRate) {
            handleDomainUpdate({ 'rate': state.plan.maxDomainRate })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(()=>{

        const checkAllowAutotune = () => {

            if(settings.autotune === 1) {
                let allow = false;
                const domain_by_rooms = rooms.filter((room) => {
                    return room.domainID === state.currentDomain.id;
                });

                for (let i = 0; i < domain_by_rooms.length; i++) {
                    const room = domain_by_rooms[i];
                    if (room.patternType === 'all'){
                        allow = true;
                    }
                }
                setAllowAutotune(allow);
            } else {
                setAllowAutotune(true);
            }

        }

        if(rooms && rooms.length && state.currentDomain) {
            checkAllowAutotune();
        }


    }, [rooms, settings.autotune, state.currentDomain]);

    useEffect(() => {
        if (state.currentDomain && state.currentDomain.deployment === 'shopifyjs') {
            setIShopifyDomain(true)
        }
    }, [state.currentDomain]);

    useEffect(() => {

        let activeWaitingRoom = false;

        const checkHasAnActiveWaitingRoom = () => {
            const domain_by_rooms = rooms.filter((room) => {
                return room.domainID === state.currentDomain.id;
            });
    
            for (let i = 0; i < domain_by_rooms.length; i++) {
                const room = domain_by_rooms[i];
                if ( room.patternType !== 'disabled'){
                    activeWaitingRoom = true;
                }
            }

            setHasAnActiveWaitingRoom(activeWaitingRoom);
        }

        if(rooms && rooms.length) {
            checkHasAnActiveWaitingRoom();
        }
        

    }, [rooms, state.currentDomain]);


    useEffect(() => {

        const setSettings = () => {
           if(settings.rate !== undefined){
               setRate(settings.rate);
           }
           
               setAutotune((settings.autotune === 1) ? true : false);
           
           if (settings.slowRequestThreshold !== undefined) {
               setSlowRequestThreshold(settings.slowRequestThreshold);
           }
           if (settings.maxSlowRequestPercent !== undefined) {
               setMaxSlowRequestPercent(settings.maxSlowRequestPercent);
           }
        }

        setSettings();

       
    }, [settings.rate, settings.autotune, settings.slowRequestThreshold, settings.maxSlowRequestPercent, settings.id]);


    const handleDomainUpdate = (value) => {
        handleSettingsChange(value);
    }


    const maxRateValue = useMemo(() => {

            let rateLimit = state.plan.maxDomainRate;

            if (settings.oneInOneOut) {
                rateLimit = settings.maxTransacting < state.plan.maxDomainRate ? settings.maxTransacting : state.plan.maxDomainRate;
            }
            
            return rateLimit;

    }, [settings.oneInOneOut, settings.maxTransacting, state.plan.maxDomainRate]);

    const getAutotuneWarning = () => {
        if (state.plan.allowAutotune === 0) {
                return (
                    <Tooltip title={<Typography>Upgrade to use Autotune</Typography>}>
                            <Typography>Autotune</Typography>
                        </Tooltip>
    
                )
        }

        if (settings.deployment === 'shopifyjs') {
            return (
                <Tooltip title={<Typography>Disabled for Shopify integration</Typography>}>
                        <Typography>Autotune</Typography>
                    </Tooltip>

            )
    }

        return <Typography>Autotune</Typography>;
    }

    const [savedATValue, setSavedATValue] = useState()

    const handleAutotune = (value) => {
        if (hasRedSegments && rate === 0 && value === false) {
            setSavedATValue(value)
            setOpenATConfirmation(true)
        } else {
            setAutotune(value)
            handleDomainUpdate({ 'autotune': (value) ? 1 : 0 })
        }
    }

    const handleAutotuneClose = (value) => {
        if(value) {
            setAutotune(savedATValue)
            handleDomainUpdate({ 'autotune': (savedATValue) ? 1 : 0 })
        }
        setOpenATConfirmation(false);
    }

    return (

        <>
           
               

            
                <Grid container spacing={1} justifyContent='flex-end' alignItems='center'>
                    <Grid item>
            <Typography variant="subtitle2" component="p" className={classes.settingsTitle}>

                <RouterLink to={`domains/${settings.id}`}>Settings</RouterLink>
            </Typography>
                    </Grid>
                    <Grid item>
                {!allowAutotune ?
                    <AlertTriangleIconOrange/>
                : null}
                    </Grid>
                </Grid>


                <SavingBar saving={updateSettings}/>

                <Grid container className={classes.formGroup}>
                    <Grid item md={2}>
                        {
                            state.plan.allowAutotune === 0 || isShopifyDomain
                            ?
                            <>
                            <FormGroup row>
                                <FormControlLabel
                                labelPlacement='top'
                                    control={
                                        <Switch
                                            disabled={true}
                                            checked={false}
                                            name="autotune"
                                            color="primary"
                                        />
                                    }
                                    label={ getAutotuneWarning() }
                                />
                            </FormGroup>
                            </>
                            :
                            <FormGroup row>
                                <FormControlLabel
                                labelPlacement='top'
                                    control={
                                        <Switch
                                            checked={autotune}
                                            onChange={(event, value) => {
                                                handleAutotune(value);
                                                
                                            }}
                                            name="autotune"
                                            color="primary"
                                        />
                                    }
                                    label="Autotune"
                                />
                            </FormGroup>
                        }
                    </Grid>
                
                    <Grid item md={10}>
                        <FormGroup row>
                        <FormControlLabel
                                labelPlacement='top'
                                style={{ width: '100%' }}
                                control={
                                    <Slider
                                        style={{ paddingTop: '18px' }}
                                        value={rate}
                                        onChangeCommitted={(event, value) => handleDomainUpdate({ 'rate': value })}
                                        onChange={(event, value) => setRate(value)}
                                        aria-labelledby="discrete-slider-small-steps"
                                        step={(rate > 50) ? 5 : 1}
                                        min={0}
                                        max={ maxRateValue }
                                        valueLabelDisplay="auto"
                                        disabled={autotune}
                                    />
                                }
                                label={
                                    <HelpToolTip
                                    title={<p><Link target="_blank" href="https://support.crowdhandler.com/support/solutions/articles/80001016979-what-is-the-rate-exactly-">Number of new users who can access your site each minute</Link></p>}
                                    placement="top"
                                    aria_label="Number of new users who can access your site each minute">
                                        <Typography className={`${classes.help} ${rate < 1 && !displayEmptyRoomImage ? 'warning' : ''}`}>Rate: {rate}/minute</Typography>
                                    </HelpToolTip>
                                    
                                }
                        />
                        </FormGroup>
                    </Grid>
                    

                </Grid>


                <Divider style={{ marginBottom: theme.spacing(2) }}></Divider>


                <Grid container alignItems="flex-end" className={classes.formGroup}>
                    <Grid item xs={12}>
                        <FormGroup row>
                            <FormControlLabel
                                labelPlacement='top'
                                style={{ width: '100%' }}
                                control={
                                    <Slider
                                        value={slowRequestThreshold}
                                        onChangeCommitted={(event, value) => handleDomainUpdate({ 'slowRequestThreshold': value })}
                                        onChange={(event, value) => setSlowRequestThreshold(value)}
                                        aria-labelledby="discrete-slider-small-steps"
                                        step={1000}
                                        min={0}
                                        max={30000}
                                        valueLabelDisplay="auto"
                                        disabled={!autotune}
                                        valueLabelFormat={(x)=>{
                                            return `${(x/1000)}s`
                                        }}
                                        // ValueLabelComponent={ValueLabelComponent}
                                    />
                                }
                                label={`Slow request threshold:${(slowRequestThreshold) ? (slowRequestThreshold).toLocaleString() : '0' }ms`}
                            />
                        </FormGroup>
                    </Grid>
                </Grid>

                <Grid container alignItems="flex-end" className={classes.formGroup}>
                    <Grid item xs={12}>
                        <FormGroup row>
                            <FormControlLabel
                                labelPlacement='top'
                                style={{ width: '100%' }}
                                control={
                                    <Slider
                                        value={maxSlowRequestPercent}
                                        onChangeCommitted={(event, value) => handleDomainUpdate({ 'maxSlowRequestPercent': value })}
                                        onChange={(event, value) => setMaxSlowRequestPercent(value)}
                                        aria-labelledby="discrete-slider-small-steps"
                                        step={1}
                                        min={1}
                                        max={10}
                                        valueLabelDisplay="auto"
                                        disabled={!autotune}
                                    />
                                }
                                label={`Maximum slow requests: ${maxSlowRequestPercent}%`}
                            />
                        </FormGroup>
                    </Grid>
                </Grid>

                <DashboardAutotuneConfirm open={openATConfirmation} onClose={handleAutotuneClose}/>
        </>
    )

}

export default DashboardSettings;


function ValueLabelComponent(props) {
    const { children, open, value } = props;
  
    return (
      <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
        {children}
      </Tooltip>
    );
  }

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.number.isRequired,
};
