import React from 'react';
import { ReactComponent as ChevronsLeft } from './feather/chevrons-left.svg';

import { SvgIcon } from '@material-ui/core';


export default function ChevronsLeftIcon(props) {
    return (
        <SvgIcon {...props} component={ChevronsLeft} className="feather"/>
    );
  }