
import React, {useState} from 'react';
import * as Yup from 'yup';
import { withFormik } from 'formik';
import { Grid, Button, FormControl } from "@material-ui/core";
import { ChFormikTextField } from '.';



const formikEnhancer = withFormik({
    mapPropsToValues: (props) => {

        let taxIdArray = (props.state.accountInformation.get('customer').tax_ids.data) ? props.state.accountInformation.get('customer').tax_ids.data : []

        return {
            tax_number: taxIdArray.length ? taxIdArray[taxIdArray.length - 1].value : '',
            tax_id: 'eu_vat'
        }
    },
    validationSchema: Yup.object().shape({
        tax_number: Yup.string().required('This is a required field')
    }),
    handleSubmit: (values, { setSubmitting, props }) => {
        props.handleTaxUpdate(values);
    },

    displayName: 'TaxIdsForm', // helps with React DevTools
});

const TaxIdsFormInline = ({ tax_ids, state, values, handleReset, deleteTaxId, handleTaxUpdate, errors, touched, handleSubmit, placeholder }) => {


    /*
     * These props are available from the formik form
    */

    // const {
    //     values,
    //     touched,
    //     errors,
    //     dirty,
    //     isSubmitting,
    //     handleChange,
    //     handleBlur,
    //     handleSubmit,
    //     handleReset,
    //     disableEditing,
    //     setEditBillingAddress,
    //     address,
    //     CountryCodes
    // } = props;

    let hasTaxData = (tax_ids.data.length) ? true: false;

    const [editing, setEditing] = useState(false)

    return (
        <Grid component="form" container spacing={2} alignItems="flex-start" justifyContent="flex-start" direction="row" onSubmit={(event) => {
            event.preventDefault();
            setEditing(false);
            handleSubmit()
        }}>
            <input type="hidden" name="tax_id" value="eu_vat"/>

            <Grid item xs={4}>
                <FormControl margin="dense" variant="outlined" fullWidth>
                    <ChFormikTextField
                        type="text"
                        name="tax_number"
                        placeholder={placeholder}
                        disabled={hasTaxData && !editing}
                    >
                    </ChFormikTextField>
                </FormControl>
            </Grid>
            <Grid item>
            <FormControl margin="dense">


                {(hasTaxData) ?
                <Button disableElevation variant="contained" color={editing ? 'primary' : 'secondary'} onClick={() => {
                    setEditing(!editing);
                    handleReset()
                    // let taxIdArray = (state.accountInformation.get('customer').tax_ids.data) ? state.accountInformation.get('customer').tax_ids.data : []
                    // values.tax_number = taxIdArray.length ? taxIdArray[taxIdArray.length - 1].value : ''
                }}
                    style={{ "marginRight": "10px" }}>
                        {editing ? 'Cancel' : 'Edit'}
                    </Button>
                : null}

                    </FormControl>
            <FormControl margin="dense">


                {editing || !hasTaxData ?
                <Button disableElevation variant="contained" color="secondary" type="submit"
                style={{ "marginRight": "10px" }}>Save</Button>
            :null
            }
                    </FormControl>
            </Grid>
        </Grid>
    );
};

export default formikEnhancer(TaxIdsFormInline);