import React from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(6),
    },
  },
  shape: {
    backgroundColor: theme.palette.primary.main,
    width: 12,
    height: 12,
  },
  shapeCircle: {
    borderRadius: '50%',
  },
  isInActive: {
    backgroundColor: 'green',
  },
}));



const RoomActiveCircle = ({isActive}) => {

    const classes = useStyles();

    return (<div className={clsx({
        [classes.shape] : true,
        [classes.shapeCircle] : true,
        [classes.isInActive] : isActive
      })} />);

}


export default RoomActiveCircle;