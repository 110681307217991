import React from 'react';
import { ReactComponent as DragIndicator } from './material/drag_indicator-24px.svg';

import { SvgIcon } from '@material-ui/core';


export default function DragIndicatorIcon(props) {
    return (
        <SvgIcon {...props} component={DragIndicator} className="material"/>
    );
  }