import React from "react";
import {
    Typography,
    Grid,
    FormControl,
    InputLabel,
    OutlinedInput,
    Button,
    FormHelperText
} from "@material-ui/core";

const SetOrigin = ({ status, originHelperText, formik, classes }) => {
    return (
        <Grid container alignItems="center">
            <>
                <Grid container spacing={2} className={classes.proxyInfoHeader}>
                    <Grid item>
                    {status.distributionStatus.results.domainName
                    ?
                        <Typography variant="button">Update the origin</Typography>
                    :
                        <Typography variant="button">Set the origin</Typography>
                    }
                    </Grid>
                </Grid>
                <Grid container spacing={2} className={classes.proxyInfo}>
                    <Grid item>
                        <FormControl fullWidth margin="dense" variant="outlined">
                            <InputLabel htmlFor="title">Origin</InputLabel>
                            <OutlinedInput
                                labelWidth={50}
                                type="text"
                                placeholder="Origin Domain"
                                aria-label="Origin Domain"
                                aria-describedby="title"
                                {...formik.getFieldProps('originDomain')}
                            />
                            <FormHelperText>{originHelperText}</FormHelperText>
                            <FormHelperText>Not sure what your origin should be set to? Check out our documentation for more information on origins</FormHelperText>
                        </FormControl>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            disableElevation
                            disabled={(formik.errors.originDomain || !formik.values.originDomain) ? true : false}
                            onClick={formik.handleSubmit}
                        >
                            Create Distribution
                                        </Button>
                    </Grid>
                </Grid>
            </>
        </Grid>
    )
}

export default SetOrigin;