import React, {useMemo} from "react";

import {
    Dialog,
    DialogContent,
    Grid,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Typography
} from "@material-ui/core";


const IPDataDialog = ({handleDialogClose, openDialog, drawerInfo}) => {

  const titleMapping = {
    asn: {
      'asn' : 'asn',
      'domain' : 'Domain',
      'name' : 'Name',
      'route' : 'Route',
      'type' : 'Type',
    },
    threat: {
      is_anonymous: 'Anonymous',
      is_bogon: 'Bogon',
      is_datacenter: 'Datacenter',
      is_icloud_relay: 'Icloud relay',
      is_known_abuser: 'Known abuser',
      is_known_attacker: 'Know attacker',
      is_proxy: 'Proxy',
      is_tor: 'Tor',
    }
  }

    const formatAddress = (info) => {


        let address = [];
  
        if(info.city) {
          address.push(info.city);
        }
        if(info.region) {
          address.push(info.region);
        }
        if(info.country_name) {
          address.push(info.country_code);
        }

  
        return <Typography>{address.join(', ')}</Typography>;
  
      }

      const asnData = useMemo(() => {
  
        let data = [];
        if(drawerInfo && drawerInfo.asn) {
          let index = 0;
            for (const key in drawerInfo.asn) {
                if (Object.hasOwnProperty.call(drawerInfo.asn, key)) {
                    const element = drawerInfo.asn[key];
                    if(element && titleMapping.asn[key]){
                        data.push(<Typography key={`${key}_${index}`}>{`${titleMapping.asn[key]}: ${element}`}</Typography>);
                        index++;
                    }
                }
            }
        }
  
        return data;
  
    },[drawerInfo, titleMapping.asn]);

      const threatData = useMemo(() => {
  
        let data = [];
        if(drawerInfo && drawerInfo.threat) {
          let index = 0;
            for (const key in drawerInfo.threat) {
                if (Object.hasOwnProperty.call(drawerInfo.threat, key)) {
                    const element = drawerInfo.threat[key];
                    if(element && titleMapping.threat[key]){
                        data.push(<Typography key={`${key}_${index}`}>{`${titleMapping.threat[key]}`}</Typography>);
                        index++;
                    }
                }
            }
        }
  
        return data;
  
    },[drawerInfo, titleMapping.threat]);


    return (
        <Dialog open={openDialog} onClose={handleDialogClose} >
            <DialogContent>
            {drawerInfo ? 
              <Grid container spacing={0} style={{padding: '30px'}} justifyContent="center">

            <Grid item xs={12} style={{
              marginBottom: '16px'
            }}>
              {drawerInfo.range ?
              <Typography variant='h6'>{drawerInfo.range}</Typography>
              :
              <Typography variant='h6'>{drawerInfo.ip}</Typography>
              }
              </Grid>

                
                <TableContainer>
                  <Table aria-label="simple table">
                    <TableBody>
                    
                    <TableRow>

                    {drawerInfo.range ?
                    <>
                    <TableCell component="th">Range</TableCell>
                    <TableCell align="right">{drawerInfo.range}</TableCell>
                    </>
                    :
                    <>
                    <TableCell component="th">IP</TableCell>
                    <TableCell align="right">{drawerInfo.ip}</TableCell>
                    </>
                    }
                    
                    
                    </TableRow>

                    <TableRow>
                        <TableCell component="th">Location</TableCell>
                        <TableCell align="right">{
                        formatAddress(drawerInfo)
                        }</TableCell>
                      </TableRow>
                    
                      {drawerInfo.asn && drawerInfo.asn.name
                      ?
                      <TableRow>
                        <TableCell component="th">ISP</TableCell>
                        <TableCell align="right">
                          {asnData}
                          </TableCell>
                      </TableRow>
                      :
                      null
                      }

                      {drawerInfo.threat && drawerInfo.threat.is_threat

                      ?
                      <TableRow>
                        <TableCell component="th">Threat</TableCell>
                        <TableCell align="right">
                          {threatData}
                          </TableCell>
                      </TableRow>
                      :null

                      }
                    </TableBody>
                  </Table>
                </TableContainer>
                     
                    </Grid>
                    
                    : null}
        </DialogContent>
        </Dialog>
        );

}


export default IPDataDialog;