import React, {useState, useEffect} from "react"
import {
    Grid,
    Typography,
    Slider,
    FormControl
} from '@material-ui/core';

const AutoblockAnomalyControl = ({ formik, setProcessing, hasAnomalyDetection, autoblockProcessing }) => {

    let [maxScore, setMaxScore] = useState(!hasAnomalyDetection ? 0 : formik.values.maxScore);

    useEffect(() => {
        setMaxScore(!hasAnomalyDetection ? 0 : formik.values.maxScore)
    }, [hasAnomalyDetection, formik.values.maxScore]);

    const onSliderChangeCommitted = (event, value) => {

        setMaxScore(value)
        setProcessing(true)
        formik.setFieldValue('maxScore', value);
        formik.handleSubmit();
    }
    const onSliderChange = (event, value) => {
        setMaxScore(value);
    }


    let marks = [
        {
          value: 0,
          label: 'Off',
        },
        {
          value: 99,
          label: '99',
        },
    ];


    return (



            <Grid item md={4} xs={12}>
                <FormControl margin="dense" variant="outlined" fullWidth >

                    {maxScore > 0 ?
                        <Typography>Autoblock IPs with anomaly scores above {maxScore}</Typography>
                        
                    : <Typography>Autoblock IPs by anomaly score [Off]</Typography>}

                <Slider
                    onChangeCommitted={onSliderChangeCommitted}
                    value={maxScore}
                    onChange={onSliderChange}
                    defaultValue={50}
                    step={1}
                    name="maxScore"
                    min={0}
                    max={99}
                    disabled={!hasAnomalyDetection || autoblockProcessing}
                    marks={marks}
                    valueLabelDisplay="auto"
                    valueLabelFormat={(value) => {

                        if (value === 0) {
                            return 'Off';
                        }else {
                            return value;
                        }
                    }}
                />
                </FormControl>
            </Grid>

           
    )

}

export default AutoblockAnomalyControl;