import React from 'react';
import { ReactComponent as Menu } from './feather/menu.svg';

import { SvgIcon } from '@material-ui/core';


export default function MenuIcon(props) {
    return (
        <SvgIcon {...props} component={Menu} className="feather"/>
    );
  }