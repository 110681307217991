import { HSLToHex } from './';

import groupBy from 'lodash/groupBy';

const AssignRoomColors = (rooms) => {

    // group by domain to ensure greatest spread of colors
    // as we only view rooms by domain
    let groupedRooms = groupBy(rooms, 'domainID');
    let roomsWithColors = [];

    // loop through the grouped rooms
    // and assign a color to each room
    for (const key in groupedRooms) {
        if (Object.hasOwnProperty.call(groupedRooms, key)) {

            const hue_max = 360;
            let h_step = hue_max/groupedRooms[key].length;
            let h = 0;

            const rooms = groupedRooms[key].map((room) => {
                room.color = HSLToHex(h, 40, 50);
                h = h+h_step;
                return room;
            });
            roomsWithColors = roomsWithColors.concat(rooms)            
        }
    }
    return roomsWithColors

}

export default AssignRoomColors;