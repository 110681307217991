import React from 'react';

import {
    Button,
    DialogTitle,
    DialogContent,
    DialogActions,
    Dialog,
    Typography
} from '@material-ui/core';

export const DashboardAutotuneConfirm = ({ onClose, open, }) => {
  
    const handleCancel = () => {
      onClose();
    };
  
    const handleOk = () => {
      onClose(true);
    };
  
    return (
      <Dialog
        maxWidth="xs"
        aria-labelledby="confirmation-dialog-title"
        open={open}
      >
        <DialogTitle id="confirmation-dialog-title">Disable autotune?</DialogTitle>
        <DialogContent dividers>
          <Typography variant='subtitle1'>Are you sure?</Typography>
          <Typography>Your site is showing signs of overload and autotune is trying to protect it from getting worse</Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleOk} color="primary">
            Disable
          </Button>
        </DialogActions>
      </Dialog>
    );
  }