const { Map } = require('immutable');

const Config = {
    DEFAULT_STATE: {
        user: {
            attributes : {
                email: ''
            }
        },
        isLoading: true,
        isError: false,
        ready: false,
        rooms: undefined,
        plans: [],
        plan: {},
        currentPlanId: '',
        downgradePending: false,
        domains: undefined,
        currentDomain: {
            id: undefined
        },
        currentlySelectedDomain:undefined,
        templates: undefined,
        accountInformation:  Map({}),
        docs: [],
        docsPanel: [],
        ipGroups: [],
        paGroups: [],
        publicKey:'',
        privateKey:'',
        recaptchaIsSet: false,
        failedToInit: false,
        globalMessage: '',
        updating: false,
        client_secret: undefined,
    },
    DATE_FORMATS: {
        FULL_WITH_YEAR: 'LLL d y h:mma',
        FULL_NO_YEAR: 'LLL d y h:mma',
        FULL_NO_TIME: 'LLL d y',
        TIME_WITH_DAY: 'ccc h:mma',
        TIME_NO_DAY: 'h:mma',
        DATE_TIME_PICKER: 'yyyy/MM/dd HH:mm',
        READABLE_NO_TIME: 'MMMM dd, yyyy',
        READABLE_FULL: 'MMMM dd, yyyy hh:mm a'
    },
    CURRENCY_MAP: {
            'gbp' : '£',
            'usd' : '$',
            'eur' : '€'
    },
    MAX_TIER: 4,
    ROLES: [
        {"id":"1", "title": "Administrator"},
        {"id":"2", "title": "Contributor"},
    ],
    CH_PROXY_ENDPOINT: process.env.REACT_APP_CH_PROXY_ENDPOINT,
    CH_PROXY_ENDPOINT_APIKEY: process.env.REACT_APP_CH_PROXY_ENDPOINT_APIKEY,

    CH_WR_ENDPOINT: process.env.REACT_APP_CH_WR_ENDPOINT,
    CH_ENDPOINT: process.env.REACT_APP_CH_ENDPOINT,
    CH_IPDATA_ENDPOINT: process.env.REACT_APP_IPDATA_ENDPOINT,

    REACT_APP_ROOT: process.env.REACT_APP_ROOT,
    
    CH_STRIPE_ENDPOINT: {
        "us" : `${process.env.REACT_APP_PL_ENDPOINT}/`,
        "eu" : `${process.env.REACT_APP_PL_EU_ENDPOINT}/`,
    },
    // CH_STRIPE_APIKEY: process.env.REACT_APP_PL_KEY,
    CH_STRIPE_APIKEY: {
        "us" : process.env.REACT_APP_PL_KEY,
        "eu" : process.env.REACT_APP_PL_EU_KEY,
    },

    STRIPE_KEY: process.env.REACT_APP_STRIPE_PUBLIC_KEY,

    CH_COGNITO_API_KEY: process.env.REACT_APP_CH_COGNITO_API_KEY,
    CH_COGNITO_API: process.env.REACT_APP_CH_COGNITO_API,
    
    CH_DASHBOARD_CHECKS_API: process.env.REACT_APP_CH_DASHBOARD_CHECKS,
    CH_DASHBOARD_CHECKS_KEY: process.env.REACT_APP_CH_DASHBOARD_CHECKS_KEY,
    
    
    CH_SHOPIFY_LAMBDA: process.env.REACT_APP_SHOPIFY_LAMBDA,
    
    SESSION: {
        REPORT_LAST_SPECS: 'report_last_specs'
    },

    AMPLIFY: {
        Auth: {
            Cognito: {
                userPoolId: process.env.REACT_APP_USER_POOL_ID,
                userPoolClientId: process.env.REACT_APP_WEB_CLIENT_ID,
            }
        },
        Storage: {
            S3: {
                bucket: process.env.REACT_APP_CH_S3_TEMPLATE_NAME, //REQUIRED -  Amazon S3 bucket name
                region: 'us-east-1', //OPTIONAL -  Amazon service region
            }
        }
    },
    EU_UserPool: {
        Region: process.env.REACT_APP_EU_USERPOOL_REGION,
        IdentityPoolId: process.env.REACT_APP_EU_IDENTITY_POOL_ID,
        UserPoolId: process.env.REACT_APP_EU_USER_POOL_ID,
        ClientId: process.env.REACT_APP_EU_USER_POOL_CLIENT_ID
    },
    US_UserPool: {
        Region: process.env.REACT_APP_US_USERPOOL_REGION,
        IdentityPoolId: process.env.REACT_APP_US_IDENTITY_POOL_ID,
        UserPoolId: process.env.REACT_APP_US_USER_POOL_ID,
        ClientId: process.env.REACT_APP_US_USER_POOL_CLIENT_ID
    }

}

export default Config;