import React, { useEffect, useState, useRef } from "react";
import {
    Grid,
    Typography,
    Link,
    Button
} from '@material-ui/core';
import { CheckCircleIcon, AlertCircleIcon } from "icons";
import Skeleton from '@material-ui/lab/Skeleton';
import {
    ToolIcon
} from '../../../icons';
import { Link as RouterLink } from "react-router-dom";


const WordPressImplementationPanel = ({ api, rooms, domain, pkey }) => {

    let [configured, setConfigured] = useState();
    let [hasCatchAll, setHasCatchAll] = useState(false);
    let [wpState, setWpState] = useState();
    let [messaging, setMessaging] = useState({
        line1: undefined,
        line2: undefined,
    });
    let mounted = useRef(true);




    useEffect(() => {
        const CallTheWordPressDashCheck = async () => {

           let path = domain.name;

           let room = rooms.filter((room) => {
            return room.patternType === 'all'
           })

           if (room.length) {
            setHasCatchAll(true)
           }

           if (path) {
               let data = {
                   "url": path,
                   "key": pkey
               }
               let response = await api.checkWP(data);
               if (mounted.current) {
                   setWpState({ ...response });
               }
           }
        }
        CallTheWordPressDashCheck();

        return () => {
            mounted.current = false;
        }
    }, []);

    useEffect(() => {
        const formatWpStateMessages = () => {
            let messaging = {
                line1: '',
                line2: '',
                line3: '',
            };

            let configured = false;

            if (wpState.header_detected) {
                messaging.line1 = 'CrowdHandler has been detected on your site';
                configured = true;
                if (wpState.correct_key) {
                    messaging.line1 = messaging.line1 + ' and your key is correct.';
                    configured = true;
                    if (wpState.cookie_detected) {
                        messaging.line2 = 'The CrowdHandler session cookie has been detected';
                        configured = true;

                        if (wpState.cookie_cached) {
                            messaging.line2 = messaging.line2 + ' but the cookie is being cached';
                            configured = false;
                        } else {
                            configured = true;
                        }

                    } else {
                        messaging.line2 = 'The CrowdHandler session cookie has NOT been detected.';
                        if(!hasCatchAll){
                            messaging.line3 = 'As you are not protecting your entire domain we are unable to check for you.';
                        }
                        configured = false;
                    }
                } else {
                    messaging.line1 = messaging.line1 + ' but your key is incorrect.';
                    configured = false;
                }


            } else {
                messaging.line1 = 'CrowdHandler has not been detected on your site.';
                configured = false;
            }
            if(mounted.current){
                setConfigured(configured);
                setMessaging(messaging);
            }


        }
        if (wpState) {
            formatWpStateMessages();
        }
    }, [wpState]);


    return (
        <Grid container direction="column" spacing={2}>
            <Grid item>
            <Grid container >
                <Grid item>
                    {wpState && configured ? <CheckCircleIcon style={{ marginRight: '20px', color: 'green' }} /> : null}
                    {wpState && !configured ? <AlertCircleIcon style={{ marginRight: '20px', color: 'red' }} /> : null}
                </Grid>
                <Grid item>
                    <Typography variant="h6" component="p">Deployment: WordPress</Typography>
                </Grid>
                
            </Grid>
            </Grid>

            <Grid item>
            {wpState ?
                <>
                    {messaging.line1
                        ? <Typography style={{marginBottom:20}}>{messaging.line1}</Typography>
                        : null
                    }
                    {messaging.line2
                        ? <Typography>{messaging.line2}</Typography>
                        : null
                    }
                    {messaging.line3
                        ? <Typography>{messaging.line3}</Typography>
                        : null
                    }


                    <Typography><Link href="https://www.crowdhandler.com/docs/80000903583-wordpress-plugin-getting-started" target="_blank">Read our WordPress integration guide</Link></Typography>


                </>
                :

                <>
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                </>
            }
            </Grid>

            <Grid item xs={12}>
                <Button startIcon={<ToolIcon/>} to={`/deployment-settings/${domain.id}`} component={RouterLink} color="secondary" variant="contained" disableElevation>Deployment Settings</Button>
            </Grid>
        </Grid>
    )

}

export default WordPressImplementationPanel;