import React from "react";
import {
    Grid,
    Typography,
} from '@material-ui/core';
import '../../Styles.css';
import OnboardStepper from '../OnboardStepper';
import './ImplementationPanels.css';
import {TypeWriterComponent} from '../../';


const AddARoomPanel = ({ domain, api, dispatch, state }) => {
   
   

    return (
        <Grid>
            {domain.deployment === null ?
            <>
            <OnboardStepper step={0}/>
        
            <Grid container>
                <Grid item>

                <TypeWriterComponent strings={[
                `<strong>Let’s get you started</strong> with your first waiting room on ${domain.name}.`,
                `Click the <strong>Add Room</strong> button below to get it set up`,
                ]}
            />


                </Grid>
            </Grid>
            </>
            :
            <Grid container>
                <Grid item>
                <TypeWriterComponent strings={[
                `<strong>You have no room set up on ${domain.name}`,
                `Click the <strong>Add Room</strong> button below to get it set up`,
                ]}
            />

            </Grid>
            </Grid>
            
            }
        </Grid>
    )

}

export default AddARoomPanel;