import sortBy from 'lodash/sortBy';
import groupBy from 'lodash/groupBy';
import difference from 'lodash/difference';
import keys from 'lodash/keys';
import uniq from 'lodash/uniq';
import orderBy from 'lodash/orderBy';
import dashboardChartConfig from './dashboardChartConfig';
import { DateTime } from 'luxon';

export const reportChartBuilder = (response, rooms, theme, updating, chartConfig, chartStyle, period, zoom) => {

    let data = response || [];
    let config = (chartConfig) ? chartConfig : dashboardChartConfig;
    rooms = sortBy(rooms, 'id');
    
    let filteredrooms = rooms.map((room, i) => {
        return room;
    })

    if (!updating) {
        // config = dashboardChartConfig;
        config = {
            ...config,
            type: 'line',
            data: {
                labels: [], // fill this with the times
                datasets: [], // the data from each room
            }
        }
    } else {
        config = {
            ...config,
            type: 'line',
            data: {
                labels: [], // fill this with the times
                datasets: [], // the data from each room
            },
        }
    }

    let config_options = config.options;

    let options = {
        ...config_options,
        onClick: (event, elements, chart) => {
            zoom(event, elements, chart)
        },
    }

    config = {
        ...config,
        options
    }



    let roomsToFind = uniq(data.map((entry) => {
        return entry.room_id;
    }));

    const roomsIds = filteredrooms.reduce((acc, room) => {
        if (roomsToFind.indexOf(room.id) !== -1) {
            acc.push(room.id);
        }
        return acc;
    }, []);

    let roomLength = roomsIds.length;

    let ordered = formatTimeSlotEntries(data, roomLength, roomsIds, rooms);
    config.data.datasets = buildInitialDataSets(rooms, roomsIds, theme, chartStyle);
    config.data.waitTimes = {}
    config.data.datetimes = []

    for (let i = 0; i < roomsIds.length; i++) {
        const room_id = roomsIds[i];
        config.data.waitTimes[room_id] = [];
    }
    
    
    // if (period === 'hour') 
    //     date_format = DateTime.DATETIME_MED;
    // else if (period === 'minute')
    //     date_format = DateTime.TIME_SIMPLE;


    for (const key in ordered) {
        if (ordered.hasOwnProperty(key)) {
            const element = ordered[key];
            config.data.datetimes.push(key);
            // config.data.labels.push(DateTime.fromISO(key).toLocaleString(date_format));
            config.data.labels.push(DateTime.fromISO(key).toLocaleString({ month: 'short', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: true }));
            for (let index = 0; index < element.length; index++) {
                const time = element[index];

                for (let n = 0; n < config.data.datasets.length; n++) {
                    const dataset = config.data.datasets[n];
                    if (dataset.id === time.room_id) {

                        if (time.waiting !== null) {
                            dataset.data.push(time.waiting);
                        } else {
                            dataset.data.push('blank');
                        }
                        if (config.data.waitTimes[time.room_id]) {
                            config.data.waitTimes[time.room_id].push(time.wait)
                        }
                    }
                }
            }
        }
    }

    return config;
}

const formatTimeSlotEntries = (data, roomLength, roomsIds, rooms) => {

    if(roomLength === 0) {
        return {}
    }
    
    let parsedTimes = data.map((response) => {
        const room = rooms.filter((room) => {
            return room.id === response.room_id;
        })[0];
        
        if (room) {
            response.room_title = room.title;
            response.time = DateTime.fromISO(response.time, { zone: 'utc' }).toISO();
        }
        return response;
    });

    
    let timeByRooms = groupBy(parsedTimes, 'time');
    // let prevKey = undefined;
    for (const key in timeByRooms) {
        if (timeByRooms.hasOwnProperty(key)) {
            let rows = timeByRooms[key];


            // check to see if there are duplicates (too many rooms for the time slot)
            // if there are then grab the first one by grouping by the room_id and taking the first element
            if (rows.length > roomLength) {
                let rooms = groupBy(rows, 'room_id');
                let newRow = []
                for (const roomIDKey in rooms) {
                    if (rooms.hasOwnProperty(roomIDKey)) {
                        const element = rooms[roomIDKey];
                        newRow.push(element[0])
                    }
                }
                rows = newRow;
            }

            if (rows.length < roomLength) { // we're missing a room
                let existingRows = [];
                rows.forEach((row) => {
                    existingRows.push(row.room_id);
                });
                // find the difference compared with the room ids from the rooms array
                let diff = difference(roomsIds, existingRows);
                for (let index = 0; index < diff.length; index++) {
                    const roomToAdd = diff[index];
                    let room = {
                        time: key,
                        room_id: roomToAdd,
                        waiting: null, // used to spanGaps https://www.chartjs.org/docs/2.9.4/charts/line.html#line-styling CHANGE TO 0 to show line
                        transacting: 0
                    }
                    rows.push(room);
                }
            }

            timeByRooms[key] = sortBy(rows, 'room_id');

            let room = {
                time: key,
                room_id: 'transacting',
                waiting: rows.reduce((acc, room) => {
                    acc += room.transacting;
                    return acc;
                }, 0),
                transacting: 0
            }

            timeByRooms[key].push(room);

            // let time = DateTime.fromISO(key, { zone: 'utc' });
            // if (prevKey) {
            //     let secondsDiff = time - prevKey;
            //     if (secondsDiff > 60000) {

            //         let slots = (secondsDiff - 60000) / 60000;
            //         let previousRows = timeByRooms[prevKey.toISO()];

            //         for (let index = 0; index <= slots; index++) {
            //             let newKey = prevKey.plus({ minute: index });
            //             timeByRooms[newKey.toISO()] = previousRows;
            //         }
            //     }
            // }

            // prevKey = time
        }
    }

    for (const key in timeByRooms) {
        if (timeByRooms.hasOwnProperty(key)) {
            let rooms = timeByRooms[key];

            if (rooms) {
                for (let index = 0; index < rooms.length; index++) {
                    const element = rooms[index];
                    let time = DateTime.fromISO(key, { zone: 'utc' });
                    element.time = time;
                    element.timestamp = time.ts;
                }
            }
        }
    }

    var ordered = {};
    keys(timeByRooms).sort().forEach(key => {
        ordered[key] = timeByRooms[key];
    });

    return ordered;
}

const buildInitialDataSets = (rooms, roomsIds, theme, chartStyle) => {

    let roomsToUse = [];
    let labelData = [];

    rooms.forEach(room => {
        if (roomsIds.indexOf(room.id) !== -1) {
            roomsToUse.push(room);
        }
    });

    roomsToUse = orderBy(roomsToUse, ['id']);
    roomsToUse.forEach((room)=>{
        if (roomsIds.indexOf(room.id) !== -1) {
            // find position of room in the rooms collection to maintain the colour

            let d = {
                label: room.title,
                id: room.id,
                data: [],
                fill: false,
                borderColor: room.color,
                backgroundColor: room.color,
                borderWidth: (chartStyle && chartStyle.borderWidth !== undefined) ? chartStyle.borderWidth : 2,
                pointRadius: (chartStyle && chartStyle.pointRadius !== undefined) ? chartStyle.pointRadius : 2,
                pointHitRadius: (chartStyle && chartStyle.pointHitRadius !== undefined) ? chartStyle.pointHitRadius : 6,
                pointStyle: (chartStyle && chartStyle.pointStyle !== undefined) ? chartStyle.pointStyle : 'circle',
                roomID: room.id,
                spanGaps: true,
                // lineTension: 0
            }

            labelData.push(d);
        }
    })

    if(roomsToUse.length) {

        labelData.push({
            label: 'Active',
            data: [],
            fill: false,
            id: 'transacting',
            backgroundColor: theme.palette.secondary.main,
            borderColor: theme.palette.secondary.main,
            borderDash: (chartStyle && chartStyle.borderDash !== undefined) ? chartStyle.borderDash : [3, 6],
            borderWidth: (chartStyle && chartStyle.borderWidth !== undefined) ? chartStyle.borderWidth : 2,
            pointRadius: (chartStyle && chartStyle.pointRadius !== undefined) ? chartStyle.pointRadius : 2,
            pointHitRadius: (chartStyle && chartStyle.pointHitRadius !== undefined) ? chartStyle.pointHitRadius : 6,
            pointStyle: (chartStyle && chartStyle.pointStyle !== undefined) ? chartStyle.pointStyle : 'rectRot',
            // lineTension: 0
        });
    
    }


    return labelData;
}

export default { reportChartBuilder };