import React, {forwardRef} from 'react';
import {
    Grid,
    FormControl,
    OutlinedInput,
    FormHelperText,
    InputLabel,
    Link,
    Select,
    MenuItem,
    Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    regexField: {
      height: '16px'
    },
    regexFieldInput: {
      alignItems: 'flex-start'
    },
    patternMatchInput: {
      marginTop: 0,
      paddingTop: 0,
      '& .MuiFormControl-marginDense' : {
        marginTop: 0,
  
      }
    },
    helpertext_error : {
      color: 'red'
    
  }
}));
    

const RoomProtectUrls = forwardRef (({ formik, domain }, ref) => {
  const classes = useStyles();
    return (
        <Grid className="ch-room-form-group" ref={ref}>
            <FormControl fullWidth margin="normal" variant="outlined">
                <InputLabel  id="domain_label">Protect:</InputLabel>
                <Select required labelId="domain_label"
                    style={{background: '#fff'}}
                    id="patternType"
                    name="patternType"
                    label="Protect:"
                    {...formik.getFieldProps('patternType')}
                >
                    <MenuItem value='disabled'>Disabled</MenuItem>
                    <MenuItem value='all'>All of {(domain) ? domain.name : null}</MenuItem>
                    <MenuItem value='contains'>URLs containing</MenuItem>
                    <MenuItem value='regex'>URLs matching regular expression</MenuItem>
                    <MenuItem value='regex-not'>URLs not matching regular expression</MenuItem>

                </Select>
            </FormControl>

                  {
                  (formik.values.patternType === 'disabled')
                  ?
                  <>
                  <FormHelperText>This waiting room is not currently protecting any URLs.</FormHelperText>
                  </>
                  :
                  null
                  } 


            {
                  formik.values.patternType === 'contains' ||
                  formik.values.patternType === 'regex' ||
                  formik.values.patternType === 'regex-not'
                  ?
                  <Grid item xs={12} className={classes.patternMatchInput}>
                    <FormControl fullWidth margin="dense" variant="outlined">
                      <InputLabel htmlFor="title">{(formik.values.patternType === 'regex' || formik.values.patternType === 'regex-not') ? 'Regular Expression' : (formik.values.patternType === 'contains' || formik.values.patternType === 'contains-not') ? 'String' : 'All'}</InputLabel>
                      <OutlinedInput
                        style={{backgroundColor: '#fff'}}
                        className={clsx({
                          [classes.regexFieldInput]: (formik.values.patternType === 'regex' || formik.values.patternType === 'regex-not')
                        })}
                        {...formik.getFieldProps('urlPattern')}
                        multiline={(formik.values.patternType === 'regex' || formik.values.patternType === 'regex-not')}
                        rows={(formik.values.patternType === 'regex' || formik.values.patternType === 'regex-not') ? 4 : 1}
                        labelWidth={(formik.values.patternType === 'regex' || formik.values.patternType === 'regex-not') ? 120 : (formik.values.patternType === 'contains' || formik.values.patternType === 'contains-not') ? 40 : 20}
                        placeholder={(formik.values.patternType === 'regex' || formik.values.patternType === 'regex-not') ? 'RegEx Pattern' : (formik.values.patternType === 'contains' || formik.values.patternType === 'contains-not') ? 'String' : 'All'}
                        aria-label="URL Pattern"
                        disabled={formik.values.patternType === 'all' || formik.values.patternType === 'disabled'}

                      />

                        
                        {
                        (formik.values.patternType !== 'all')
                        ?
                        (formik.values.patternType === 'regex' || formik.values.patternType === 'regex-not')
                        ?
                        <FormHelperText>Required. Do not include your domain. Uses <Link href="https://www.crowdhandler.com/docs/80000599765-using-rexeg-for-url-pattern-matching" rel="noopener noreferrer" target="_blank">POSIX-Extended regular expression syntax</Link></FormHelperText>
                        :
                        <FormHelperText>Required. Do not include your domain.</FormHelperText>
                        :
                        null
                        } 

                        
                        {formik.touched.urlPattern && formik.errors.urlPattern ? <Typography variant="subtitle2" color="error">{formik.errors.urlPattern}</Typography> : null}

                      
                      </FormControl>
                  </Grid>
                  :
                  null
                }


        </Grid>
    )
})

export default RoomProtectUrls;