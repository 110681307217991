import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { withRouter, Link as RouterLink } from 'react-router-dom';
import AdminHeaderDomainSelector from '../components/AdminHeaderDomainSelector';
import { AdminSideBar, GlobalFooter } from '../components';
import MenuIcon from '../../icons/MenuIcon';

import {ErrorBoundary} from '../../components'

import { Grid, Typography, useMediaQuery, Link, Breadcrumbs, IconButton, Container } from '@material-ui/core';
import GlobalMessaging from '../../components/GlobalMessaging/GlobalMessaging'

const easeOutSpeed = '0.4s';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        minHeight: '100vh',
        position: 'relative',
        'transition': `all ${easeOutSpeed} ease-out`
    },
    shiftContent: {
        paddingLeft: 181,
        'transition': `all ${easeOutSpeed} ease-out !important`
    },
    shiftMinimizedContent: {
        paddingLeft: 54,
    },
    shiftMinimizedContentMobile : {
        paddingLeft: 0,
    },
    shiftOpenContentMobile : {
        paddingLeft: 181,
    },
    shiftOpenContentMobileNotMinimized: {
        paddingLeft: 181,
    },
    shiftOpenContentMobileMinimized: {
        paddingLeft: 54,
    },
    
    main: {
        width: '100%',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        transition: 'all 0.4s'
    },
    drawer: {
        width: 180,
        zIndex: 1000,
        transition: `all ${easeOutSpeed} ease-out !important`,
        [theme.breakpoints.up('lg')]: {
            height: '100%'
        },
        background: 'white',
    },
    drawerMinimized: {
        zIndex: 1000,
        transition: 'all 0.2s ease-in !important',
        width: 54,
        [theme.breakpoints.up('lg')]: {
            height: '100%'
        },
        background: 'white',
    },
    content: {
        flexGrow: 1,
        flexDirection: 'column',
        padding: 40
    },
    titlebar: {
        position: 'relative',
        color: 'white',
        padding: '0 16px',
        background: `linear-gradient(90deg, ${theme.palette.primary.dark} 0%, ${theme.palette.primary.light} 100%)`,
        minHeight: '48px'
    },
    menuButton: {
        margin: theme.spacing(0),
        [theme.breakpoints.up('lg')]: {
            display: 'none'
        },
      },
      domainSelector : {
          marginLeft: 'auto'
      },
}));

const Admin = ({ children, signOut, currentlySelectedDomain, domainsSwitcherdata, state, showDomainSelector, history, match, ...rest }) => {

    const isMountedRef = useRef(null);

    const classes = useStyles();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });

    useEffect(() => {
        return () => {
            isMountedRef.current = false;
        };
    }, []);

    
    let {dispatch} = rest;

    const [openSidebar, setOpenSidebar] = useState(false);
    const [minimized, setMinimized] = useState(false);

    const handleSidebarClose = () => {
        setOpenSidebar(false);
    };

    const handleMinimized = (minimizedValue) => {
        setMinimized(minimizedValue);

    }

    const shouldOpenSidebar = isDesktop ? true : openSidebar;

    const updateCurrentlySelectedDomainHandler = (domainId) => {
        // let updatedDomain = state.domains.filter((domain) => {
        //     return domain.id === domainId;

        // })

        // // console.log({domainId, updatedDomain: JSON.stringify(updatedDomain[0], null, 4)});
        // if (updatedDomain.length) {
        //     dispatch({ type: 'UPDATE_DOMAINS', payload: updatedDomain[0] });
        //     dispatch({ type: 'SET_CURRENT_DOMAIN', payload: updatedDomain[0] });
        // }

        dispatch({ type: 'UPDATE_SELECTED_DOMAIN', payload: domainId });
    }

    useEffect(() => {
        history.listen(() => {
            if (window.FreshworksWidget) {
                window.FreshworksWidget('close');
            }
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            
        <ErrorBoundary>

            <div className={clsx({
                [classes.root]: true,
                [classes.shiftContent]: isDesktop,
                [classes.shiftMinimizedContent]: minimized && isDesktop,
                [classes.shiftMinimizedContentMobile]: minimized && !isDesktop,
                [classes.shiftOpenContentMobile]: minimized && !isDesktop && shouldOpenSidebar,
                [classes.shiftOpenContentMobileNotMinimized]: !minimized && !isDesktop && shouldOpenSidebar,
                [classes.shiftOpenContentMobileMinimized]: minimized && !isDesktop && shouldOpenSidebar,
            })}>
                <AdminSideBar
                    className={clsx({
                        [classes.sidebar] : true,
                        [classes.drawer] : !minimized,
                        [classes.drawerMinimized] : minimized,
                    })}
                    path={children.props.path}
                    onClose={handleSidebarClose}
                    open={shouldOpenSidebar}
                    classes={{
                        paper: (!minimized) ? classes.drawer : classes.drawerMinimized
                    }}
                    signOut={signOut}
                    variant='persistent'
                    handleMinimized={handleMinimized}
                    minimized={minimized}
                    easeOutSpeed={easeOutSpeed}
                    state={state}
                    dispatch={dispatch}
                ></AdminSideBar>

                <main className={clsx({
                    [classes.main]: true,
                    [`${children.props.title.replace(' ', '-').toLowerCase()}`] : true
                })}>

                <GlobalMessaging downgradePending={state.downgradePending} customPlan={state.custom_plan} accountInformation={state.accountInformation} plan={state.plan} plans={state.plans} currentPlanId={state.currentPlanId} dispatch={dispatch} globalMessage={state.globalMessage}/>


                    {(children.props.title) ?
                        <Grid container component='div'
                        className={clsx({
                            [classes.titlebar]: true,
                            'titlebar' : true,
                            [`${children.props.title.replace(' ', '-').toLowerCase()}`] : true
                            })}
                        alignItems="center" justifyContent="flex-start">
                            <Grid item className={classes.menuButton}>
                                <IconButton edge="start"  color="inherit" aria-label="menu" onClick={()=>{
                                    setOpenSidebar(!openSidebar);
                                }}><MenuIcon/></IconButton>
                            </Grid>
                            {(match.path !== '/')
                            ?
                            <Grid item>
                                <RouterBreadcrumbs match={match} state={children.props.state} />
                            </Grid>
                        
                            :
                            null
                            }
                            {(state.domains && state.domains.length && state.currentDomain && showDomainSelector)
                                ?
                                <Grid item className={classes.domainSelector}>

                                <AdminHeaderDomainSelector
                                    onChange={updateCurrentlySelectedDomainHandler}
                                    domainId={state.currentDomain.id}
                                    domains={state.domains}
                                ></AdminHeaderDomainSelector>
                                </Grid>
                                :
                                null
                            }
                        </Grid>
                        :
                        null
                    }
                        <Container maxWidth={false} className={clsx({
                        [classes.content]: true,
                        [classes.root]: true,
                        [`${children.props.title.replace(' ', '-').toLowerCase()}`] : true
                        })}>
                                {children}
                    </Container>

                    <GlobalFooter align="flex-end" state={state} />

                </main>
            </div>
        </ErrorBoundary>


        </>
    )

}

Admin.propTypes = {
    children: PropTypes.node
};

export default withRouter(Admin);


const LinkRouter = (props) => <Link {...props} component={RouterLink} />;


const getPathMapper = (to, params, state) => {
    let label = '';

    if (to === '/rooms/sessions/:roomId' && state.rooms) {

        if (state.rooms) {
            let roomName = state.rooms.filter(room => {
                return room.id === params.roomId;
            });
            if(roomName.length) {
                label = `${roomName[0].title} Sessions`;
            }
        }
    }

    if (to === '/domains/ips/:domainId') {
        label = `Manage IP Adresses`
        if (state.domains) {

            let domain = state.domains.filter(domain => {
                return domain.id === params.domainId;
            });

            if (domain.length) {
                label = `Manage IP Adresses for ${domain[0].name}`
            } 
        }

    }

    if (to === '/rooms/add/:domainId') {

        if (state.domains) {

            let domain = state.domains.filter(domain => {
                return domain.id === params.domainId;
            });
            label = `Add Waiting Room to ${domain[0].name}`
        } else {
            label = `Add Waiting Room`

        }

    }

    // room.current = 
    let pathMapper = {
        '/dashboard': 'Dashboard',
        '/domains': 'Domains',
        '/domains/add': 'Add Domain',
        '/domains/:domainId': 'Edit Domain Settings',
        '/domains/ips/:domainId': label,
        '/rooms': 'Waiting Rooms',
        '/rooms/add/:domainId': label,
        '/rooms/:roomId': 'Edit Waiting Room',
        '/rooms/sessions/:roomId': label,
        '/templates': 'Templates',
        '/templates/add': 'Add Template',
        '/priority-access': 'Priority Access',
        '/priority-access/manage-codes/:groupId': 'Manage Priority Access Codes',
        '/account': 'Account',
        '/reports': 'Reports',
        '/captcha': 'ReCAPTCHA',
        '/email-me': 'Email Me',
    }

    return pathMapper[to];
}


const breadcrumbStyles = makeStyles(theme => ({
    link: {
        color: '#ffffff',
        textDecoration: 'underline'
    },
    deadlink: {
        color: '#ffffff'
    },
    breadcrumbs: {
        '& .MuiBreadcrumbs-separator': {
            color: '#ffffff'
        }
    }
}));

const RouterBreadcrumbs = (props) => {
    let { match } = props;
    let { path, params } = match;
    const pathnames = path.split('/').filter((x) => {
        return x;
    });

    let classes = breadcrumbStyles();

    const renderBreadcrumbs = () => {

        if(!pathnames) {
            return false;
        }


        let paths = pathnames.map((path, index) => {
            let to = `/${pathnames.slice(0, index + 1).join('/')}`;
            const last = index === pathnames.length - 1;
            return {
                to: to,
                label: getPathMapper(to, params, props.state),
                last: last
            }
        });

        let render = paths.map((path) => {
            if (!path.label) {
                return null;
            }

            if (path.last) {
                return <Typography color="secondary" key={path.to} className={classes.deadlink}>
                    {path.label}
                </Typography>
            }
            return <LinkRouter key={path.to} to={path.to} className={classes.link}>{path.label}</LinkRouter>;
        })

        return render;
    }

    return (
        <div>
            <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumbs}>
                {renderBreadcrumbs()}
            </Breadcrumbs>
        </div>
    );
}
