import React from "react";
import {
    Grid,
} from '@material-ui/core';
import '../../Styles.css';
import OnboardStepper from '../OnboardStepper';
import {TypeWriterComponent} from '../../';


const ActivateARoomPanel = ({dashboardState}) => {

return (
    <Grid>
      <OnboardStepper step={0}/>
      <TypeWriterComponent strings={[
                `<strong>All rooms on this domain are currently inactive.</strong>`,
                `You may have disabled your room, or it may be scheduled to activate in the future.`,
                `Edit your room by clicking on the <strong>Edit button</strong> in the <strong>Rooms Table</strong> below.`,
                ]}
            />

    </Grid>
)

}

export default ActivateARoomPanel;