import React, { useState, useEffect, useRef, useReducer } from "react";
import isArray from 'lodash/isArray';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { DashboardCharts } from '../../components';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import {DateTime} from 'luxon'
import {
    Typography,
    Grid,
    LinearProgress,
} from '@material-ui/core';

import AddDomainFromDashboardPanel from '../../components/Dashboard/AddDomainFromDashboardPanel'

import { AddRoomActiveToRoom } from "../../lib";


const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    dashboard: {
    },
    chartContainer: {
        position: 'relative'
    },
    downloadButton: {
        position: 'absolute',
        top: -30,
        right: 0,
        backgroundColor: '#ffffff',
        '&:hover': {
            backgroundColor: '#ffffff',
        }
    },
    chartSkeleton: {
        width: '960px',
        height: '300px',
        backgroundColor: '#cacaca'
    },
    noDataImageBlock : {
        color: theme.palette.grey[100],
        marginBottom: theme.spacing(4),
        backgroundSize: 'cover',
        backgroundPositionY: 'bottom',
        height: '320px',
        width: '100%',
    },
    noWaitingAll: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'inline-block'
        },
    },
    noWaitingNone: {
        display: 'inline-block',
        [theme.breakpoints.up('md')]: {
            display: 'none'
        },
    },
    dashboardContainer : {
        flex: '1 0 100%',
        display: 'flex',
    },
    dashboardContainerNoDomain : {
        flex: '1 0 100%',
        display: 'flex',
        'align-content': 'center',
        'justify-content': 'flex-start',
    },
    dashboardContainerLoading : {
        flex: '1 0 100%',
        display: 'flex',
        'align-content': 'center',
        'justify-content': 'center',
    }
}));


const Dashboard = props => {

    let { state, api, ...rest } = { ...props };
    const { dispatch } = rest;
    let classes = useStyles();
    const isMountedRef = useRef(true);
    // set firstRun to true to initialize page on load and when the domain changes
    const firstRun = useRef(true);

    let [graphData, setGraphData] = useState({
        data: [],
        updating: false,
        waiting: false,
        rooms: state.rooms ? state.rooms: []
    })

    const setDashReducer = (state, action) => {
        switch (action.type) {
            case 'UPDATE_DASH_STATE':
                let updated = action.payload;
                return {
                    ...state,
                    ...updated
                }
            case 'SET_WR_TEST_CLICKED':

                setGraphData((prev) => {
                    return {
                        ...prev,
                        waiting:true,
                       
                    }
                })

                return {
                    ...state,
                    show_graph: true,
                    display_empty_room_image: false,
                    has_graph_data: false,
                    has_graph_waiting: true,
                }
            default:
                return state;
        }
    }

    const [dashboardState, dispatchDash] = useReducer(setDashReducer, {
        checking_state: true,
        onboarding: false,
        show_graph: true,
        graph_updating: true,
        show_performance_hits: false,
        info_panel: {state: undefined},
        domain_rooms: [],
        display_empty_room_image: false,
        has_graph_data: true,
        current_domain: state.currentDomain ? state.currentDomain : undefined,
        error_getting_report_data: false,
        error_getting_url_data: false,
        error_getting_response_data: false,
        url_data: undefined,
        response_data: undefined,
        has_graph_waiting: false,
    });

    
  
    const [currentDomainId, setCurrentDomainId] = useState((state && state.currentDomain ? state.currentDomain.id : undefined))
    const [errorGettingURLData, setErrorGettingURLData] = useState(false);
    const [errorGettingData, setErrorGettingData] = useState(false);
    // Boolean to track when the settings are being updated
    const [updateSettings, setUpdateSettings] = useState(false);
    
    
    // dashboard chart settings
    const [showChart, setShowChart] = useState(false);
    const [chartLoading, setChartLoading] = useState(true);
    
    // url list data
    const [urlData, setUrlData] = useState([])
    const [urlDataCompleted, setUrlDataCompleted] = useState(false);


    const [hasReportData, setHasReportData] = useState(false)
    const [displayEmptyRoomImage, setDisplayEmptyRoomImage] = useState(false)
    
    
    useEffect(() => {

        const checkingDashboardState = () => {
            firstRun.current = true;
            dispatchDash({type:'UPDATE_DASH_STATE', payload:{checking_state: true}});
            setCurrentDomainId(state.currentlySelectedDomain);
            setGraphData((prev) => {
                return {
                    ...prev,
                    waiting: false,
                    updating: firstRun.current
                }
            })
        }

        if(state.currentlySelectedDomain && !firstRun.current) {
            checkingDashboardState();
        }

    }, [state.currentlySelectedDomain]);
    
    
    useEffect(() => {

        if (!chartLoading && urlDataCompleted) {
            firstRun.current = false;
        }

    }, [chartLoading, urlDataCompleted]);

    useEffect(() => {
        // the component is mounted
        isMountedRef.current = true;


        const getDomainsData = async () => {
            try {
                let result = await api.getDomainById(state.currentlySelectedDomain);
                if (result.error) {
                    return;
                }

                if(isMountedRef.current){
                    dispatch({type:'SET_CURRENT_DOMAIN', payload:result})
                    dispatch({type:'UPDATE_DOMAINS', payload:result})
                }

            } catch (error) {
               
            }
        }
        

        let intervalsID = setInterval( async() => {
            if (state.currentlySelectedDomain) {
                await getDomainsData(state.currentlySelectedDomain);
            }
        }, 60000);

        if (state.currentlySelectedDomain) {
            getDomainsData();
        } else {
            if (intervalsID) {
                clearInterval(intervalsID);
            }
        }

        return () => {
            // Clean up the subscription
            clearInterval(intervalsID);
            isMountedRef.current = false;
        };

    }, [api, dispatch, state.currentlySelectedDomain]);


    // get graph data
    useEffect(() => {
        // the component is mounted
        isMountedRef.current = true;

        let graph_state = {
            data: [],
            waiting: false,
            rooms: state.rooms,
            updating: true
        }

        // method to fetch the data
        const fetchReportData = async (updating) => {
            setChartLoading(true); // the chart is currently loading
            
            let result = undefined;
            if (isMountedRef.current && currentDomainId) {

                dispatchDash({type:'UPDATE_DASH_STATE', payload:{graph_updating: true}})

                try {
                    result = await api.getReport(currentDomainId);
                    if (result.error) {
                        dispatchDash({type:'UPDATE_DASH_STATE', payload:{onboarding: false}})
                        return;
                    }

                } catch (error) {
                    if (isMountedRef.current) {
                        setErrorGettingData(true);
                    }
                }

                if (isArray(result)) {

                    if (result.length) {
                        if (isMountedRef.current) {
                            setHasReportData(true);
                            // setDisplayEmptyRoomImage(false);

                            graph_state = {
                                data: result,
                                waiting: false,
                                rooms: state.rooms,
                                updating: false
                            }                            
                            dispatchDash({type:'UPDATE_DASH_STATE', payload:{display_empty_room_image: false, has_graph_data: true, graph_updating: false}})

                        }
                    } else {
                        if (isMountedRef.current) {
                            setHasReportData(false);
                            // setDisplayEmptyRoomImage(true);
                            graph_state = {
                                data: [],
                                rooms: state.rooms,
                                updating: false
                            }
                            
                            dispatchDash({type:'UPDATE_DASH_STATE', payload:{display_empty_room_image: true, has_graph_data: false, graph_updating: false}})

                          
                        }
                    }

                    if (result.length > 0 && state.rooms.length > 0) {
                        if (isMountedRef.current) {

                            graph_state = {
                                data: result,
                                rooms: state.rooms,
                                waiting: false,
                                updating: false
                            }

                           
                            dispatchDash({type:'UPDATE_DASH_STATE', payload:{display_empty_room_image: false, has_graph_data: true, graph_updating: false}});

                           
                        }
                    }
                }
            }
            setGraphData((prev) => {

                let res = {
                    ...prev,
                    ...graph_state
                }

                return res;
            })
            firstRun.current = false;

            setChartLoading(false); // the chart is currently loading
        };


        if (currentDomainId && state.rooms) {

            const initialCall = async () => {
                
                await fetchReportData();
                
            }
            initialCall();
        }

        let intervalsID = setInterval( async() => {
            if (currentDomainId && state.rooms) {
                await fetchReportData(true);
            }
        }, 60000);

        return () => {
            // Clean up the subscription
            clearInterval(intervalsID);
            isMountedRef.current = false;
        };

        //     initiallyRun.current = false;
    }, [api, currentDomainId, dispatch, state.rooms]);

    // get url data
    useEffect(() => {
        isMountedRef.current = true;
    
        const getDomainsURLs = async () => {
        setUrlDataCompleted(false);
          let result = undefined;
          if (isMountedRef.current && currentDomainId) {
            
                try {
                    result = await api.getUrls(currentDomainId);
                    if (result.error) {
                        dispatchDash({type:'UPDATE_DASH_STATE', payload:{onboarding:false}});
                      return;
                    }
                  } catch (error) {
                    if (isMountedRef.current) {
                        dispatchDash({type:'UPDATE_DASH_STATE', payload:{onboarding:false}});
                        setErrorGettingURLData(true);
                        setUrlData([]);
                    }
                }
    
                // make sure the result is formatted as an array
                if (isMountedRef.current && isArray(result)) {
                    let data = result.map((row) => {
                        row.count = parseInt(row.count, 10);
                        row.avg = parseInt(row.avg, 10);
                        return row;
                    })
                    if (data.length > 0) {
                        setUrlData(data);
                    } else {
                        setUrlData([]);
                    }

                } else if (isMountedRef.current) {
                    if (isMountedRef.current) {
                        setUrlData([]);
                    }
                }
            }
            if(firstRun.current) {
                setUrlDataCompleted(true);
            }
        }
    
    
        if (currentDomainId) {
            getDomainsURLs();
        }
    
        let intervalsID = setInterval( async() => {
          if (currentDomainId) {
              await getDomainsURLs();
          }
        }, 60000);
    
        return () => {
            // Clean up the subscription
            clearInterval(intervalsID);
            isMountedRef.current = false;
        };
    
    }, [api, currentDomainId]);

    // Get rooms if state.rooms is undefined
    useEffect(() => {
        const getRooms = async () => {
            try {
                let rooms = await api.getRooms();
                dispatch({ type: 'UPDATE_ROOMS', payload: rooms });
            } catch (error) {
                setErrorGettingData(true)
            }
        }
        if (state.rooms === undefined) {
            getRooms();
        }
        
    }, [api, dispatch, state.rooms])

    // Get domains if state.domains is undefined
    useEffect(() => {
        const getDomains = async () => {
            try {     
                let domains = await api.getDomains();
                dispatch({ type: 'ADD_DOMAINS', payload: domains });
            } catch (error) {
                setErrorGettingData(true)
            }
        }
        if (!state.domains) {
            getDomains();
        }

    }, [dispatch, api, state.domains]);

    // configureDashboardState 
    useEffect(() => {

        

        const configureDashboardState = async () => {
                        
            let dashboardState = {
                performance_hits: false,
                onboarding: false,
                show_graph: false,
                show_performance_hits: false,
                info_panel: {state: 'default'},
                domain_rooms: [],
                has_domains: true,
                show_dashboard_settings : true,
            }


            let has_domain = state.domains ? state.domains.length > 0: false;

            if(!has_domain) {
                dashboardState.onboarding = false;
                firstRun.current = false;
                dispatchDash({type:'UPDATE_DASH_STATE', payload:{
                    ...dashboardState,
                    show_dashboard_settings: false,
                    has_domains: false,
                    show_graph: false,
                    checking_state:false,
                    display_empty_room_image: false
                }})
                return false;
            }

            let domain_rooms = [];

            if (state.rooms && state.rooms.length) {
                domain_rooms = state.rooms.filter((room) => {
                    return room.domainID === currentDomainId;
                })
            }

            dashboardState.domain_rooms = domain_rooms;
            
            let room_is_active = false;

            if(urlData.length) {
                dashboardState = {
                    performance_hits: true,
                    onboarding: false,
                    show_performance_hits: true,
                    info_panel: {state: 'default'},
                    domain_rooms : domain_rooms,
                }
            } else {

                dashboardState.onboarding = true;

                let has_room = domain_rooms ? domain_rooms.length > 0: false;

                let currentDomain;
                if (state.domains.length === 1) {
                    currentDomain = state.domains;
                } else {
                    currentDomain = state.domains.filter((domain)=>{
                        return domain.id === currentDomainId;
                    });
                }

                let implementation;
                if( currentDomain.length) {
                    implementation = currentDomain[0].deployment;
                }

                if (has_room && implementation === null) {

                    let rooms = AddRoomActiveToRoom(domain_rooms);
                    rooms = rooms.filter((room) => {
                        return room.domainID === currentDomainId;
                    })

                    for (let i = 0; i < rooms.length; i++) {
                        const room = rooms[i];
                        if(room.queueActivatesIsActive && room.patternType !== 'disabled'){
                            room_is_active = true;
                        }
                    }

                    if (!room_is_active) {
                        dashboardState.show_graph = false;
                        dashboardState.info_panel.state = 'activate_a_room';
                    } else if (room_is_active && graphData.data.length > 0) {

                        dashboardState.has_graph_waiting = false;
                        dashboardState.show_graph = true;
                        if (currentDomain[0].rate < 1 && !currentDomain[0].deployment) {
                            dashboardState.info_panel.state = 'explain_rate';
                        } else if (currentDomain[0].rate > 1 && !currentDomain[0].deployment) {


                            let numberOfWaiting = graphData.data.reduce((acc, res) => {
                                return acc + res.waiting
                            },0);
                            numberOfWaiting = numberOfWaiting/graphData.data.length;
                            let numberOfTransacting = graphData.data.reduce((acc, res) => {
                                return acc + res.transacting
                            },0);

                            if (numberOfWaiting < numberOfTransacting) {
                                dashboardState.info_panel.state = 'select_implementation';
                            } else {
                                dashboardState.info_panel.state = 'wait_for_implementation';
                            }

                        } else if (currentDomain[0].deployment) {
                            dashboardState.info_panel.state = implementation;
                        }



                    } else if (room_is_active && !graphData.data.length) {
                        dashboardState.show_graph = false;
                        dashboardState.display_empty_room_image = true;
                        dashboardState.info_panel.state = 'join_a_room';
                    }
                } else if (has_room && implementation) {
                    dashboardState.info_panel.state = implementation;
                    firstRun.current = false;
                } else if (!has_room && implementation === null) {
                    dashboardState.info_panel.state = 'add_a_room';
                } else {
                    dashboardState.info_panel.state = 'add_a_room_with_implementation';

                }

            }

            dispatchDash({type:'UPDATE_DASH_STATE', payload:{...dashboardState, checking_state:false}})
                
        }
        
        configureDashboardState();
        

    }, [state.domains, state.rooms, urlData, currentDomainId, graphData.data])


    const handleSettingsChange = async (newValue) => {

        setUpdateSettings(true);
        newValue.id = state.currentDomain.id;

        try {
            let response = await api.putDomain(newValue, 'put');
            dispatch({ type: 'UPDATE_DOMAINS', payload: response });
            dispatch({ type: 'UPDATE_SELECTED_DOMAIN', payload: newValue.id });
        } catch (error) {
            setErrorGettingData(true)
        }
        setUpdateSettings(false);
    };

    



    return (
        <Grid container
        direction="column" className={firstRun.current ? classes.dashboardContainerLoading: classes.dashboardContainer}
       
        >

        {state.domains && state.domains.length === 0 ? 
        
       
            
        <Grid item xs={12}>
            
            <AddDomainFromDashboardPanel api={api} dispatch={dispatch}  />

        </Grid>
   
       
        : null}

           
        {state.currentDomain && state.domains && state.domains.length
            ?


            <DashboardCharts
                firstRun={firstRun.current}
                dashboardState={dashboardState}
                dispatchDash={dispatchDash}
                state={state}
                rooms={state.rooms}
                chartLoading={chartLoading}
                updateSettings={updateSettings}
                currentDomain={state.currentDomain}
                handleSettingsChange={handleSettingsChange}
                urlData={urlData}
                errorGettingURLData={errorGettingURLData}
                api={api}
                dispatch={dispatch}
                graphData={graphData}
            />
        :
        null
        }

        

            {errorGettingData ? 
            <Grid>
                <Typography>There has been an error getting the data</Typography>
            </Grid>
            : null}

        </Grid>

    );
};

export default Dashboard;

Dashboard.propTypes = {
    rooms: PropTypes.array,
    domains: PropTypes.array
}