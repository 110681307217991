import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import clsx from 'clsx';

import { DashboardIcon, DomainIcon, RoomsIcon, UsersIcon, TemplatesIcon, ChevronsRightIcon, ChevronsLeftIcon, AwardIcon, ShieldIcon, TrendingUpIcon, LayersIcon, MailIcon } from '../../icons';
import LogOutIcon from '../../icons/LogOutIcon';
import logo from '../../images/CH-Icon-circle.png';


import sortBy from 'lodash/sortBy';

import {
    Divider,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Drawer,
    IconButton,
    Badge
} from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    navListItemActive: {
        color: theme.palette.secondary.main,
        '& .feather': {
            'stroke-width': '1px',
            'color': theme.palette.secondary.main
        }
    },
    listItem: {
        'overflow': 'hidden',
        '&:hover svg': {
            color: theme.palette.secondary.main
        },
        '& svg': {
            fontSize: '20px',
            'stroke-width': '1px',
            'transition': 'all 0.2s ease-in'
        }
    },
    extraListItem: {
        paddingTop: 4,
        paddingBottom: 4,
        '& .MuiListItemText-root': {
            marginTop: 2,
            marginBottom: 2,

        },
        '& .MuiTypography-body1': {
            fontSize: '12px'
        }
    },
    navListItemIcon: {
        minWidth: '40px',
        'margin': '5px 0'
    },
    minimized: {
        width: '100%',
        justifyContent: 'center',
        '& .MuiListItemIcon-root': {
            justifyContent: 'center'
        }
    },
    minimizeButton: {
    },
    minimizeButtonContainer: {
        position: 'absolute',
        bottom: 10
    },
    chLogo: {
        maxWidth: '180px',
        padding: theme.spacing(2),
        '& img' : {
            width: '100%'
        }
    },
    betaBadge: {
        '& .MuiBadge-badge': {
            fontSize: '10px',
            backgroundColor: 'transparent',
            color: theme.palette.primary.main,
        }
    },
    betaBadgeActive: {
        '& .MuiBadge-badge': {
            color: theme.palette.secondary.main,
        }
    }
}));

const AdminSideBar = props => {
    const { open, variant, onClose, signOut, handleMinimized, state, dispatch } = props;

    let classes = useStyles();
    let history = useHistory();
    const handleMinimize = () => {
        let minimized = !props.minimized;
        handleMinimized(minimized);
    }

    let [navData, setNavData] = useState([
        
        
    ]);


    useEffect(() => {

        let nav = [
            { id: 0, beta: false, label: 'Dashboard', path: '/', icon: <DashboardIcon /> },
            { id: 20, beta: false, label: 'Waiting Rooms', path: '/rooms', icon: <RoomsIcon /> },
            { id: 10, beta: false, label: 'Domains', path: '/domains', icon: <DomainIcon /> },
            { id: 30, beta: false, label: 'Templates', path: '/templates', icon: <TemplatesIcon /> },
            { id: 40, beta: false, label: 'Priority Access', path: '/priority-access', icon: <AwardIcon /> },
            { id: 43, beta: false, label: 'Sessions', path: '/rooms/sessions', icon: <LayersIcon />},
            { id: 45, beta: false, label: 'ReCAPTCHA', path: '/recaptcha', icon: <ShieldIcon /> },
            { id: 46, beta: false, label: 'Email Me', path: '/email-me', icon: <MailIcon /> },
            { id: 47, beta: false, label: 'Reports', path: '/reports', icon: <TrendingUpIcon /> },
            { id: 50, beta: false, label: 'Account', path: '/account', icon: <UsersIcon /> }
        ]

        // using this to filter out nav element when testing or soft launching on prod
        let filteredNav = nav.reduce((acc, nav) => {

            acc.push(nav);
            
            return acc;

        }, []);


        setNavData(filteredNav);
        
       
    }, [state.plan]);


    

    const orderNavData = () => {
        return sortBy(navData, 'id')
    }

    return (
        <Drawer
            anchor="left"
            classes={props.classes}
            onClose={onClose}
            open={open}
            variant={variant}
        >
             <Grid item className={classes.chLogo}>

                 {props.minimized
                 ?
                    <img src={logo} alt="crowdhandler logo"/>
                 :
                    <RouterLink to="/"><img src="/crowdhandler-logo--strip-blue.svg" alt="CrowdHandler" /></RouterLink>
                 }

            </Grid>
            <List component="nav" aria-label="main navigation" >
                {orderNavData().map(item => {
                    return <AdminNav key={item.id} minimized={props.minimized} active={props.path === item.path} label={item.label} path={item.path} icon={item.icon} beta={item.beta} />
                })}

                <ListItem disableRipple={true}
                    disableGutters={props.minimized}
                    onClick={() => { signOut(history) }}
                    className={clsx({
                        'nav-icon': true,
                        'nav-icon-active': props.active,
                        [classes.navListItemActive]: props.active,
                        [classes.minimized]: props.minimized,
                        [classes.listItem]: true,
                        [classes.root]: true,
                    })}
                    button color="primary">
                    <ListItemIcon className={classes.navListItemIcon} >
                        <LogOutIcon />
                    </ListItemIcon>
                    {(!props.minimized) ?
                        <ListItemText primary='Logout' />
                        : null}
                </ListItem>


            </List>

            <Divider />

                <Grid container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    className={classes.minimizeButtonContainer}
                >

                    <IconButton
                        size="small"
                        variant="outlined"
                        onClick={handleMinimize}
                        className={classes.minimizeButton}
                        disableRipple
                    >
                        {props.minimized ?
                            <ChevronsRightIcon />
                            :
                            <ChevronsLeftIcon />
                        }
                    </IconButton>
                </Grid>
                <Divider />

        </Drawer>
    )
}

export default AdminSideBar;


const AdminNav = props => {
    let { label, path, icon, minimized, beta } = { ...props };
    const classes = useStyles();

    return (
       
      
           beta
           ? 
           <ListItem disableRipple={true}
               disableGutters={props.minimized}
               className={clsx({
                   'nav-icon': true,
                   'nav-icon-active': props.active,
                   [classes.navListItemActive]: props.active,
                   [classes.minimized]: props.minimized,
                   [classes.listItem]: true,
                   [classes.root]: true,
               })}
               button component={RouterLink} to={path} color="primary">
               <ListItemIcon className={classes.navListItemIcon}>
               {icon}
               </ListItemIcon>
               {(!minimized) ?
               <Badge 
               className={clsx({
                   [classes.betaBadge]: true,
                   [classes.betaBadgeActive]: props.active,
                })}
               anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }} badgeContent={`beta`} color={props.active ? `secondary` : `primary` }>
                   <ListItemText primary={label} />
                   </Badge>
                   : null}
           </ListItem>
           
           :
           <ListItem disableRipple={true}
               disableGutters={props.minimized}
               className={clsx({
                   'nav-icon': true,
                   'nav-icon-active': props.active,
                   [classes.navListItemActive]: props.active,
                   [classes.minimized]: props.minimized,
                   [classes.listItem]: true,
                   [classes.root]: true,
               })}
               button component={RouterLink} to={path} color="primary">
               <ListItemIcon className={classes.navListItemIcon}>
               {icon}
               </ListItemIcon>
               {(!minimized) ?
                   <ListItemText primary={label} />
                   : null}
           </ListItem>
       
    );
}
