import React, { useState, useEffect, useContext } from "react";
import {
    useHistory,
    Redirect
} from "react-router-dom";
import RequestVerificationLink from '../../components/RequestVerificationLink'
import {AuthContext} from '../../providers/AuthProvider';


import {
    Container,
    Grid,
    Button,
    FormControl,
    InputLabel,
    OutlinedInput,
    Divider,
    Typography
} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';


import ErrorSnackBar from '../../components/ErrorSnackBar';

const useStyles = makeStyles((theme) => ({
    verificationPanel: {
        marginBottom: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    resendCodePanel: {
        marginTop: theme.spacing(2),
    },
}))


export default function ResetPassword({ state, signIn, authorised, api, ...rest }) {
    const chAuth = useContext(AuthContext);
    let history = useHistory();
    const classes = useStyles();
    let [showGetNewVerificationLink, setShowGetNewVerificationLink] = useState(false);

    let [showVerificationSuccess, setShowVerificationSuccess] = useState(false);
    let [message, setMessage] = useState('');
    let [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
    let [alertBarStatus, setAlertBarStatus] = useState();
    let [showAlreadyConfirmed, setShowAlreadyConfirmed] = useState(false);
    
    
    let { dispatch } = rest;
    
    let [verificationComplete, setVerificationComplete] = useState(false);
    
    let [fields, setFields] = useState({
        username: (history.location.state && history.location.state.email) ? history.location.state.email : '',
        password: '',
        newPassword: '',
        code: ''
    });
    
    const verificationLinkMessage = 'Request a verification link and you will be able to change your password.';

    // Redirect to dashboard if already authorised
    useEffect(() => {
        if(authorised){
            history.replace('/');
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const closeErrorSnackbar = () => {
        setOpenErrorSnackbar(false)
    }


    const handleForgotPassword = async () => {
        dispatch({ type: 'SET_IS_LOADING', payload: true });
        if (fields.username) {
            try {
                let result = await api.forgotPassword(fields.username.trim());

                if(result.CodeDeliveryDetails) {
                    const location_success = {
                        pathname: '/new-password',
                        state: { fromResetPassword: true, email:fields.username.trim()  }
                        }
                    history.replace(location_success);
                } else {
                    dispatch({ type: 'SET_IS_LOADING', payload: false });
                    if (result.error && result.error === 'FORCE_CHANGE_PASSWORD') {
                        setShowGetNewVerificationLink(true);
                    }
                    setMessage('We were unable to reset your password')
                    setOpenErrorSnackbar(true);
                }
            } catch (error) {
                dispatch({ type: 'SET_IS_LOADING', payload: false });
                setMessage('We were unable to reset your password')
                setOpenErrorSnackbar(true)
            }
        }
    }


    const handleFields = (event) => {
        setFields({
            ...fields,
            [event.target.name]: event.target.value.trim()
        })
    }

    const resetTempPassword = async () => {

        let response = await api.resetTempPassword(fields.username.trim());

        if (response.code === 'UnsupportedUserStateException') {
            setMessage('Your account is already confirmed');
            setShowVerificationSuccess(false);
            // setLoginLinkExpired(false);
            sessionStorage.setItem('username', fields.username.trim())
            setShowAlreadyConfirmed(true);
            setAlertBarStatus('error');
            setOpenErrorSnackbar(true);
        } else if (response.code === 'UserConfirmedException') {
            setShowVerificationSuccess(false);
            setMessage('Your account is already confirmed')
            setAlertBarStatus('error');
            setOpenErrorSnackbar(true);
        } else if (response.error && response.error.code === 'UserNotFoundException') {
            setShowVerificationSuccess(false);
            setMessage('Please check your email address')
            setAlertBarStatus('error');
            setOpenErrorSnackbar(true);
        } else {

            console.log(response);
            // setShowVerificationSuccess(true);

            // setVerificationLinkMessage('Your verification link has been sent')
            // setMessage('Please check your email for a new verification link')
            // setAlertBarStatus('success');
            // setOpenErrorSnackbar(true);
            setVerificationComplete(true);
        }

    }


    return (
        <>
            {verificationComplete
            ?
            <Redirect to="/login" />
            :
            null
            }
         

            <Container maxWidth='sm'>
                {
                !showGetNewVerificationLink ?
                <>
                <Grid container component="form" className={classes.verificationPanel}>
                    <Grid item xs={12}>
                        <FormControl fullWidth margin="normal" variant="outlined">
                            <InputLabel htmlFor="username">Username</InputLabel>
                            <OutlinedInput
                                type="text"
                                id='username'
                                name='username'
                                value={fields.username}
                                onChange={(event) => handleFields(event)}
                                labelWidth={63}
                                placeholder="Username"
                                aria-label="Username"
                                aria-describedby="username"
                            ></OutlinedInput>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant="contained"
                            size="small"
                            disableElevation
                            disabled={(!fields.username) ? true : false}
                            color="secondary" onClick={() => { handleForgotPassword() }}>Reset Password</Button>
                    </Grid>
                </Grid>
                <Divider/>
                <Grid container component="form" className={classes.resendCodePanel}>
                    <Grid item xs={12}>
                        <Button
                        disableElevation
                        size="small"
                        variant="contained"
                        color="secondary"
                        component={RouterLink}
                        to={`/login`}
                        >
                            Sign In?
                        </Button>
                    </Grid>
                </Grid>
                </>
                :
                null
                }
                {showGetNewVerificationLink?
                <RequestVerificationLink
                    username={fields.username}
                    resetTempPassword={resetTempPassword}
                    handleFields={handleFields}
                    header={'It looks like you haven’t verified your account.'}
                    message={<Typography align="center">{verificationLinkMessage}</Typography>}
                />
                :null}

             

                <ErrorSnackBar status={alertBarStatus} message={message} open={openErrorSnackbar} closeErrorSnackbar={closeErrorSnackbar} />
            </Container>
        </>
  
    )
}