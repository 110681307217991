const defaultFilters = {
    roomID: 'all',
    domainID: 'all',
    includeActiveUsers: 1,
    archivedUsers: 0,
    search: '',
    page: 0,
    pageSize: 200,
    minScore: 0,
    initialized: false
  };

const buildSessionsQueryString = (data, hasAnomalyDetection) => {
    let merged_data = {
        ...defaultFilters,
        ...data
      }
  
      if (merged_data.archivedUsers === 0) {
        delete (merged_data.archivedUsers)
      }
      if (!merged_data.roomID) {
        delete (merged_data.roomID)
      }
      if (!merged_data.domainID) {
        delete (merged_data.roomID)
      }
      if (merged_data.domainID === 'all') {
        delete (merged_data.domainID)
      }
      if (merged_data.roomID === 'all') {
        delete (merged_data.roomID)
      }
      delete (merged_data.request_id)
  
      let params = [];
      for (const key in merged_data) {
        if (Object.hasOwnProperty.call(merged_data, key)) {
          if (key !== 'initialized') {
            params.push(`${key}=${encodeURIComponent(merged_data[key].toString())}`);
          }
        }
      }
      if (!hasAnomalyDetection) {
        params.splice(6, 1)
      }
  
      let queryString = params.join('&');
  
      return queryString;
}

export default buildSessionsQueryString;