import React from 'react';
import { ReactComponent as Cloud } from './feather/cloud.svg';

import { SvgIcon } from '@material-ui/core';


export default function DomainIcon(props) {
    return (
        <SvgIcon {...props} component={Cloud} className="feather" />
    );
  }