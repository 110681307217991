import React, {memo} from "react";
import { SearchIcon, RefreshCWIcon } from '../icons';

import {
  Select,
  MenuItem,
  OutlinedInput,
  InputAdornment,
  Button,
  Slider,
  Typography,
  Grid,
} from '@material-ui/core';


const SessionFilters = ({ filters, setFilters, handleFilterChange, domains, filteredRooms, handleFilterClick, hasAnomalyDetection }) => {

  const updateFilters = (event) => {
    handleFilterChange(event.target.name, event.target.value)
  }

  const handleMinScoreChange = (event, newValue) => {
    handleFilterChange('minScore', newValue)
  }

  return (
    <Grid container spacing={2} justifyContent="flex-start" alignItems="center">

      <Grid item>
        {domains.length && domains.length > 1 ? 
        <Select variant="outlined" margin="dense" name="domainID" value={filters.domainID} onChange={(event) => {
          updateFilters(event)
        }}>
          <MenuItem value='all'>All domains</MenuItem>
          {domains ?
            domains.map((domain) => {
              return <MenuItem key={domain.id} value={domain.id}>{domain.name}</MenuItem>
            })
            : null}

        </Select>

      : <Typography>{domains.length ? domains[0].name : null}</Typography>}
      </Grid>

      {filters.domainID !== 'all' ?
        <Grid item>
          {filteredRooms && filteredRooms.length > 1 ?
          <Select variant="outlined" margin="dense" value={filters.roomID} onChange={({ target }) => {
            handleFilterChange('roomID', target.value)
          }}>

            
            <MenuItem value='all'>All rooms</MenuItem>
            {
              filteredRooms.map((room) => {
                return <MenuItem key={room.id} value={room.id} >{room.title}</MenuItem>
              })
             }

          </Select>
          : <Typography>{filteredRooms.length ? filteredRooms[0].title : null}</Typography>}
        </Grid>
        : null
      }

      <Grid item>
        <Select variant="outlined" margin="dense" name="includeActiveUsers" value={filters.includeActiveUsers} onChange={(event) => updateFilters(event)}>
          <MenuItem value={0}>Waiting only</MenuItem>
          <MenuItem value={1}>Waiting and active</MenuItem>
          <MenuItem value={2}>Archived anomalies</MenuItem>
        </Select>
      </Grid>

      {hasAnomalyDetection ?
      <Grid item xs={12} sm={3} container spacing={2} justifyContent="flex-start" alignItems="center">
      
          <Grid item md={2}>
            <Typography>Risk</Typography>
          </Grid>
          <Grid item xs>
            <Slider
              style={{
                padding: '13px 0 10px'
              }}
              name="minScore"
              value={filters.minScore}
              defaultValue={filters.minScore}
              aria-labelledby="discrete-slider-small-steps"
              step={1}
              min={0}
              max={100}
              disabled={!hasAnomalyDetection}
              valueLabelDisplay="auto"
              onChange={(ev, b) => handleMinScoreChange(ev, b)}
            />
          </Grid>
        </Grid>
     
       : null}
      <Grid item xs sm={5} md={2}>
        <OutlinedInput
          margin="dense"
          name="search"
          id="sessionsearch"
          fullWidth
          type="text"
          placeholder="Agent, Token, IP…"
          onChange={(event) => updateFilters(event)}
          inputProps={{ 'aria-label': 'search' }}
          value={filters.search}
          endAdornment={

            filters.search === '' ?
            <InputAdornment position="end">
              <SearchIcon style={{
                width: '14px', height: '14px', opacity: '0.5'
              }}/>
            </InputAdornment>
            : null
          }
        />
      </Grid>
      <Grid item>
        <Button
          endIcon={<RefreshCWIcon>Update</RefreshCWIcon>}

          onClick={
            () => { handleFilterClick() }
          } variant="outlined">Update</Button>
      </Grid>
    </Grid>
  )
}

export default memo(SessionFilters);