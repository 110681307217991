import React from "react";
import {
    IconButton
} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import {ClipboardIcon} from '../icons';
import {CopyToClipboard} from 'react-copy-to-clipboard';

const useStyles = makeStyles((theme) => ({
    root: {
        color: 'inherit',
        '& .feather': {
            fontSize: 'inherit',
        }
    }
}));

const CopyButton = ({text, onCopy, ...rest}) => {

    let classes = useStyles();
    return (
        <>
        <CopyToClipboard {...rest} text={text} onCopy={onCopy}>
            <IconButton size="small" className={classes.root}>
            <ClipboardIcon />
            </IconButton>
    
        </CopyToClipboard>
        </>
    )
}

export default CopyButton;