import React, { useState, useEffect, useCallback, useContext} from "react";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { DateTime } from 'luxon';
import Config from '../../config';
import { useStripe } from '@stripe/react-stripe-js';
import { Link as RouterLink } from 'react-router-dom';
import {
    Typography,
    Grid,
    Card,
    CardContent,
    Chip,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Button,
    Dialog,
    DialogContent,
    Slide,
    Link,
    CircularProgress,
    Tooltip
} from '@material-ui/core';

import {AuthContext} from '../../providers/AuthProvider';

import Skeleton from '@material-ui/lab/Skeleton';


import { CheckIcon, AlertTriangleIcon, XIcon } from '../../icons';
import ErrorSnackBar from '../ErrorSnackBar';

import CheckoutForm from '../Stripe/CheckoutForm';

const dateFormat = Config.DATE_FORMATS.FULL_NO_TIME;


const useStyles = makeStyles((theme) => ({
    card: {
        marginBottom: theme.spacing(2),
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '100%'
    },
    cardContent: {
        position: 'relative'
    },
    chip: {
        position: 'absolute',
        top: theme.spacing(1),
        right: theme.spacing(1)
    },
    table: {
        '& td' : {
            borderRight: '1px solid #f3f3f3'
        },
        '& td:last-of-type' : {
            borderRight: 'none'
        }
    },
    tooltip: {
        color: theme.palette.primary.main,
        display: 'block',
        textDecoration:'line-through',
        '&:hover' : {
            cursor: 'pointer'
        }
    },
    vat : {
        fontSize: '10px',
        position: 'relative',
        top: '-7px'
    }
}));

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
      boxShadow: '0 2px 14px 0 rgba(0, 0, 0, 0.2)'
    },
  }))(Tooltip);

const CheckoutDialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(DialogContent);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const AccountPlans = ({
    setShowLoader,
    dispatch,
    currentCard,
    api,
    state,
    getInvoicePreview
}) => {
    const chAuth = useContext(AuthContext);

    const { accountInformation, plans, currentPlanId } = state;
    const stripe = useStripe();
    const [canChange, setCanChange] = useState();
    const [open, setOpen] = useState(false);
    const [openCDNWarning, setOpenCDNWarning] = useState(false);
    const [openPlanLimitExceeded, setOpenPlanLimitExceeded] = useState(false);
    const [planLimitExceededMessage, setPlanLimitExceededMessage] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState();
    const [selectedPrice, setSelectedPrice] = useState();
    const [paymentErrorMessage, setPaymentErrorMessage] = useState();
    let [preferredCard, setPreferredCard] = useState();
    const [currentPlan, setCurrentPlan] = useState(state.plan);
    const [priceList, setPriceList] = useState();

    let [step, setStep] = useState(0);
    let [message, setMessage] = useState('');
    let [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);

    const closeErrorSnackbar = () => {
        setOpenErrorSnackbar(false)
    }

    let classes = useStyles();

    const handleClose = useCallback(() => {
        setOpen(false);
        dispatch({
            type: 'UPDATE_ACCOUNT_INFORMATION', payload: {
                invoice_preview: undefined,
            }
        });
    }, [dispatch]);

    const checkTrialLimits = (selectedPlan, state) => {

        let changes = [];
        let number_of_domains = state.domains.length;
        let number_of_domains_on_selected_plan = parseInt(selectedPlan[0].metadata.maxDomainCount, 10);
        let number_of_rooms = state.rooms.length;
        let number_of_rooms_on_selected_plan = parseInt(selectedPlan[0].metadata.maxWaitingRoomCount, 10);

        let headerStyle = {
            fontSize: '1em',
            marginBottom: '12px'
        }

        if (number_of_domains > number_of_domains_on_selected_plan) {
            let diff_domains = number_of_domains - number_of_domains_on_selected_plan;
            let domain_plural = ''
            if (diff_domains !== 1) {
                domain_plural += `s`;
            }
            let domains_messaging = <Typography>Please remove <strong>{diff_domains}</strong> domain{domain_plural} in order to change your plan</Typography>;

            changes.push(
                {
                    value: <>
                    {domains_messaging}
                </>,
                }
            )
           
        }

        if (number_of_rooms > number_of_rooms_on_selected_plan) {
            let diff_rooms = number_of_rooms - number_of_rooms_on_selected_plan;
            let room_plural = ''
            if (diff_rooms !== 1) {
                room_plural = `s`;
            }
            let rooms_messaging = <Typography>Please remove <strong>{diff_rooms}</strong> room{room_plural} in order to change your plan</Typography>;

            changes.push(
                {
                    value: <>
                        {rooms_messaging}
                    </>
                }
            );
           
        }

        if (changes.length) {
            changes.reverse();
            changes.push(
                {
                    value: <Typography style={headerStyle}>{`Your current number of domains or rooms exceeds the limits for ${(selectedPlan[0]) ? `the ${selectedPlan[0].name} plan.` : `this plan.`}`}</Typography>
                }
                );
            changes.reverse();

            return {
                status: false,
                messages: changes
            };
        }

        return  {
            status: true,
        };

    }

    const handleSelectPlan = async (plan) => {

        setShowLoader(true);

        let selectedPlan = plans.filter((p) => {
            return p.id === plan;
        });

        let selected_price = selectedPlan[0].prices.reduce((acc, price) => {
            if(price.currency === accountInformation.get('customer').currency){
                acc = price;
            }
            return acc;
        }, '');

        setSelectedPrice(selected_price);
        

        // set the 30 Day trial prices to the lite products
        // needed for invoice preview
        if(state.currentPlanId === 'pln_30Day') {
            let lite_plan = state.plans.filter((plan) => {
                return plan.name === 'Lite';
            });
            setCurrentPlan({
                prices: lite_plan[0].prices,
                ...currentPlan
            })

            let canUpgradeFromTrial = checkTrialLimits(selectedPlan, state);
    
            if (!canUpgradeFromTrial.status) {
                setShowLoader(false);
                setPlanLimitExceededMessage(canUpgradeFromTrial.messages);
                setOpenPlanLimitExceeded(true);
               return false;
            }
        }

        if (state.plan.allowCDN && (parseInt(selectedPlan[0].metadata.cdnUsage, 10) < 1)) {

            let can_change = true;
            if (canChange !== undefined) {
                can_change = canChange;
            } else {

                let cdn_domains = state.domains.filter((domain) => {
                    return  domain.deployment === 'cdn'
                })
    
                if (cdn_domains.length) {
    
                    const stripProtocol = (domainName) => {
                        const regex = /http[s?]:\/\//gm;
                        return domainName.replace(regex, '');
                    }
    
                    let calls = [];
    
                    for (let i = 0; i < cdn_domains.length; i++) {
                        const domain = cdn_domains[i];
                        calls.push(await api.getTrafficStatus(stripProtocol(domain.name)));
                    }
    
                    let results = await Promise.all(calls);
                    
                    for (let j = 0; j < results.length; j++) {
                        const result = results[j];
                        if (result.status.toLowerCase() === 'deployed') {
                            can_change = false;
                        }                    
                    }
    
                    setCanChange(can_change);
    
                }
            }

            if (!can_change) {
                setOpenCDNWarning(true);
                setShowLoader(false);
            } else {

                try {
                    await getInvoicePreview(selected_price.id, accountInformation.get('priceId'))
                    setSelectedPlan(selectedPlan[0])
                    setStep(0);
                    setOpen(true);
                } catch (error) {
                    console.log(error);
                    setMessage('There has been a problem processing your request. Please contact support@crowdhandler.com');
                    setOpenErrorSnackbar(true);
                }
            }
        } else {
            try {

                await getInvoicePreview(selected_price.id, accountInformation.get('priceId'))
                setSelectedPlan(selectedPlan[0])
                setStep(0);
                setOpen(true);
            } catch (error) {

                console.log(error);
                setMessage('There has been a problem processing your request. Please contact support@crowdhandler.com');
                setOpenErrorSnackbar(true);
            }
        }

    }

    useEffect(() => {

        const getPrefCard = () => {
            let { default_payment_method } = accountInformation.get('customer').invoice_settings;
            let cards = accountInformation.get('cards');

            let pref_card = cards.reduce((acc, card) => {
                if (card.id === default_payment_method) {
                    acc = card;
                }
                return acc;
            }, {});

            setPreferredCard(pref_card);

        }

        if (accountInformation.get('customer').invoice_settings && accountInformation.get('customer').invoice_settings.default_payment_method && accountInformation.get('cards')) {
            getPrefCard();
        }


    }, [accountInformation])

    const handleCancelDowngrade = async () => {
        setShowLoader(true);

        let data = {
            priceId: accountInformation.get('priceId'),
            subscriptionId: accountInformation.get('subscription'),
            customerId: accountInformation.get('customer').id
        };
        
        const {idToken} = await chAuth.getIDToken();
        let result = await api.cancelDowngrade(data, idToken.getJwtToken(), false);

        if (result) {

            let payload = {
                // invoices: result.invoices,
                upcoming_invoice: result.upcominginvoice,
                subscription: result.subscription.released_subscription
            }

            if (result.subscription.status === 'released') {
                await dispatch({ type: 'UPDATE_ACCOUNT_INFORMATION', payload: payload });
                await dispatch({ type: 'SET_GLOBAL_MESSAGE', payload: '' })
                await dispatch({ type: 'SET_DOWNGRADE_PENDING', payload: false })
                setShowLoader(false);
                handleClose();
            }

        } else {
            let reason = 'There was an error updating your plan. Please try again';
            setMessage(reason);
            setOpenErrorSnackbar(true);
            setShowLoader(false);
            handleClose();
        }
    }

    const handleDowngradeResponse = async (result) => {

        let payload = {
            upcoming_invoice : result.upcominginvoice
        }
        await dispatch({ type: 'UPDATE_ACCOUNT_INFORMATION', payload: payload });

        if (payload.upcoming_invoice.subtotal < state.accountInformation.get('price')) {

            let nextLine = payload.upcoming_invoice.lines.data[0];

            let upcomingPlan = state.plans.filter((plan) => {
                return plan.id === nextLine.plan.product;
            });

            let period_end = DateTime.fromSeconds(payload.upcoming_invoice.period_end).toFormat(dateFormat);
            let to_plan = '';
            if (upcomingPlan[0]) {
                to_plan = `to ${upcomingPlan[0].name} `
            }
            dispatch({ type: 'SET_GLOBAL_MESSAGE', payload: `Note: your account will be downgraded ${to_plan}on ${period_end}.` })
            await dispatch({ type: 'SET_DOWNGRADE_PENDING', payload: true })
            setShowLoader(false);
            handleClose();
            setStep(0);

        } else {
            setShowLoader(false);
            handleClose();
            setStep(0);
        }
    }

    const handleUpgradeResponse = useCallback(async () => {
        setStep(2); 
        const checkPlan = async () => {
            let get_current_plan = await api.getPlan();

           if (get_current_plan.id !== state.currentPlanId) {

                // get the new subscription from stripe customer
                const {idToken} = await chAuth.getIDToken();
                let customer = await api.getStripeCustomer(idToken.getJwtToken());

                if (customer && customer.sub_object) {
                    await dispatch({
                        type: 'UPDATE_ACCOUNT_INFORMATION',
                        payload: {
                            subscription: customer.sub_object.id,
                            productId: customer.sub_object.plan.product,
                            priceId: customer.sub_object.plan.id,
                            price: customer.sub_object.plan.amount,
                            customer
                        }
                    });
                }

                await dispatch({ type: 'SET_CURRENT_PLAN', payload: get_current_plan });
                await dispatch({ type: 'SET_GLOBAL_MESSAGE', payload: '' })
                await dispatch({ type: 'SET_DOWNGRADE_PENDING', payload: false })
                setShowLoader(false);
                handleClose();

           } else {
                setTimeout(async () => {
                    await checkPlan();
                }, 5000);
           }
        }
        await checkPlan();
    }, [api, chAuth, dispatch, handleClose, setShowLoader, state.currentPlanId])

    const updateSubscription = async (data, isUpgrade) => {

        const {idToken} = await chAuth.getIDToken();
        data.isUpgrade = isUpgrade;
        data.ts_enc = state.accountInformation.get('invoice_preview').ts_enc;
        let result = await api.updateSubscription(data, idToken.getJwtToken());
        
        let payment_method = result.payment_method;
        let client_secret = result.client_secret;
        let paid = result.paid;
        let payment_error = result.payment_error;

        if (payment_error || result.status === 'error') {
            setStep(4);
            let message;
            if (payment_error.message) {
                message = payment_error.message;
            } else {
                message = 'There has been a error updating your account. Please refresh your browser and try again.';
            }
            setPaymentErrorMessage(message)
            return false;
        }

        if (isUpgrade && !paid) {
            dispatch({ type: 'SET_CLIENT_SECRET', payload: client_secret });
            
            let confirm_result = await stripe.confirmCardPayment(
                client_secret,
                {
                  payment_method: payment_method,
                  return_url:`${Config.REACT_APP_ROOT}/payment_confirm_response.html`
                },
                // Disable the default next action handling.
                {handleActions: false}
              )
    
              dispatch({ type: 'SET_CLIENT_SECRET', payload: confirm_result.paymentIntent.client_secret });
              var action = confirm_result.paymentIntent.next_action;
              if (action && action.type === 'redirect_to_url') {         
                      setStep(3);
                      let iframe = document.createElement('iframe');
                      iframe.src = action.redirect_to_url.url;
                      iframe.width = 600;
                      iframe.height = 400;
                      iframe.style.border = 'none';
                      
                      let container = document.getElementById('cardActionHolder');
                      container.appendChild(iframe);
              } else {
                  on3DSComplete()
              }
        } else if (isUpgrade && paid) {
            await handleUpgradeResponse();
        } else {
            await handleDowngradeResponse(result);
        }


    }

    const getStep = () => {

        let content;
        switch (step) {
            case 0:
                content = <CheckoutForm
                                state={state}
                                selectedPlan={selectedPlan}
                                selectedPrice={selectedPrice}
                                currentPlan={currentPlan}
                                handleClose={handleClose}
                                plans={plans}
                                preferredCard={preferredCard}
                                updateSubscription={updateSubscription}
                                setStep={setStep}
                                balance={accountInformation.get('customer').balance}
                            />
                
                break;

                case 1:
                    content = <Grid container spacing={2}>
                                <Card raised={false} elevation={0}>
                                <CardContent style={{textAlign: 'center'}}>
                                <CircularProgress/>
                                <Typography component="h2" variant="h5">Processing</Typography>
                                </CardContent>
                                </Card>
                            </Grid>

                break;
                case 2:
                    content = <Grid container spacing={2}>
                                <Card raised={false} elevation={0}>
                                <CardContent style={{textAlign: 'center'}}>
                                <CircularProgress/>
                                <Typography component="h2" variant="h5">Updating Plan</Typography>
                                </CardContent>
                                </Card>
                            </Grid>

                break;
                case 3:
                    content = <Grid container spacing={2}>
                                <Card raised={false} elevation={0}>
                                <CardContent style={{textAlign: 'center'}}>
                                <div id="cardActionHolder">
                                        
                                </div>
                                </CardContent>
                                </Card>
                            </Grid>

                break;
                case 4:
                    content = <Grid container spacing={2}>
                                <Card raised={false} elevation={0}>
                                <CardContent style={{textAlign: 'center'}}>
                                    <Typography><strong>Payment Failed</strong></Typography>
                                    {paymentErrorMessage ? <Typography>{paymentErrorMessage}</Typography> : null}
                                    <Typography>Please go to <RouterLink to="/account/billing" >billing</RouterLink> where you will be able to manually pay your invoice.</Typography>
                                </CardContent>
                                </Card>
                            </Grid>

                break;
            default:
                break;
        }

        return content;

    }

    const on3DSComplete = useCallback(async () => {

        if (state.client_secret) {

            let result = await stripe.retrievePaymentIntent(state.client_secret);
            if (result.error) {
            // PaymentIntent client secret was invalid
                console.log('PaymentIntent Error', result);

            } else {
                if (result.paymentIntent.status === 'succeeded') {
                    // Show your customer that the payment has succeeded
                    await handleUpgradeResponse();
                } else if (result.paymentIntent.status === 'requires_payment_method') {
                    setPaymentErrorMessage('There has been an issue with your payment')

                    setStep(4);
                    // Authentication failed, prompt the customer to enter another payment method
                }
            }

            dispatch({ type: 'SET_CLIENT_SECRET', payload: undefined });

        }

        
    },[dispatch, handleUpgradeResponse, state.client_secret, stripe])

    const handlePostMessage = useCallback((ev) => {
        if (ev.data === '3DS-authentication-complete') {
            on3DSComplete();
        }
    },[on3DSComplete])

    
    useEffect(() => {
        window.addEventListener('message', handlePostMessage, false);
        return () => {
            window.removeEventListener('message', handlePostMessage)
        }
    }, [handlePostMessage, state.client_secret]);

    useEffect(() => {
        let current_product_id = state.accountInformation.get('productId');
        let current_price = state.accountInformation.get('price');
        let current_price_id = state.accountInformation.get('priceId');
        let prices = [];
        const cur_markup = state.accountInformation.get('currency');

        for (let i = 0; i < state.plans.length; i++) {
            const product = state.plans[i];
            const product_prices = product.prices;
            const price_by_currency = product_prices.find((price) => {
                return price.currency === state.accountInformation.get('customer').currency;
            });

            
            let mark_up;
            if (!price_by_currency) {

                mark_up = <>
                <Typography></Typography>
                </>

            } else if (price_by_currency.unit_amount > 0) {
                if (product.id === current_product_id && price_by_currency.id !== current_price_id) { //check the customer is on a legacy plan
                    // price_by_currency.unit_amount = current_price;
                    mark_up = <>
                    <Typography>
                    <HtmlTooltip
                        placement="top"
                        title={<p>You are currently on a legacy price which will remain until you change your plan</p>}
                        aria-label="You are currently on a legacy price which will remain until you change your plan"
                    >    
                        <span className={classes.tooltip}>{cur_markup}{price_by_currency.unit_amount/100}</span></HtmlTooltip> {cur_markup}{current_price/100} per month</Typography>
                    {(state.accountInformation.get('customer').currency) === 'gbp' ? <small className={classes.vat}>+ VAT</small> : null}
                    </>
                } else {
                mark_up = <>
                <Typography>{cur_markup}{price_by_currency.unit_amount/100} per month</Typography>
                {(state.accountInformation.get('customer').currency) === 'gbp' ? <small className={classes.vat}>+ VAT</small> : null}
                </>

                }
            } else {
                mark_up = <>
                <Typography>FREE</Typography>
                </>
            }

            prices.push(mark_up);

            
        }

        setPriceList(prices);

        
    }, [classes.tooltip, classes.vat, state.accountInformation, state.plans]);

    const isDBCustomPlan = () =>  {
        const plan = state.currentPlanId;
        const plans = state.plans.map((plan) => {
            return plan.metadata.planID
        });

        return plans.indexOf(plan);

               
    }

    
    const getActionButton = (product, index) => {
        
        if (state.custom_plan || !state.accountInformation.get('customer').sub_object) {
            return null;
        }

        let label = 'upgrade';
        
        
        let plan_temp = state.plans.reduce((acc, plan) => {
            if (plan.metadata.planID === currentPlanId) {
                acc = plan;
            }
            
            return acc;
        }, {})
        
        let productId = (plan_temp && plan_temp.id) ? plan_temp.id : state.accountInformation.get('productId');

        let productIds = state.plans.map((product) => {
            return product.id;
        });

        let found_index = productIds.indexOf(productId);

        if(index < found_index) {
            label = 'downgrade';
        }

        if (product.id === productId) {
            return <Chip color="primary" label="Current Plan" />
        } else {

            if(
                accountInformation.get('user').attributes['custom:role'] === '1' &&
                ((state.plan.metadata && !state.plan.metadata.legacy) || currentPlanId === 'pln_30Day')
            ){
                return (
                    
                    <Button
                        disabled={
                            (state.downgradePending && currentPlanId !== 'pln_30Day') ||
                            !currentCard ||
                            (!state.accountInformation.get('latest_invoice_paid'))
                        }
                        size="small"
                        disableElevation
                        onClick={() => {
                            handleSelectPlan(product.id);
                        }}
                        variant='contained'
                        color='secondary'
                        fullWidth
                        type="button"
                        >
                        {label}
                    </Button>
                );
            } else {
                return null;
            }

        }
    }


    return (
        <>
            {!currentCard
                ?
                <Grid container spacing={2}
                    direction="row"
                    justifyContent="center"
                    alignItems="stretch">
                    <Card className={classes.card} >
                        <CardContent className={classes.cardContent}>
                        <Skeleton variant="text" height={40} />
                        <Skeleton variant="text" height={40} />
                        <Skeleton variant="text" height={40} />
                        <Skeleton variant="text" height={40} />
                        <Skeleton variant="text" height={40} />
                        <Skeleton variant="text" height={40} />
                        <Skeleton variant="text" height={40} />
                        <Skeleton variant="text" height={40} />
                        <Skeleton variant="text" height={40} />
                        </CardContent>
                    </Card>
                </Grid>
                :
                null
            }


            {state.plans && currentCard
            ?
            <Grid>
                <Card className={classes.card} >
                <CardContent className={classes.cardContent}>

                {(state.downgradePending && state.currentPlanId !== 'pln_30Day' && !state.temp_upgrade) && isDBCustomPlan() !== -1
                    ?
                    <Button
                        disabled={!currentCard || !state.accountInformation.get('latest_invoice_paid')}
                        size="small"
                        disableElevation
                        variant='contained'
                        color='secondary'
                        onClick={handleCancelDowngrade}>Cancel Upcoming Downgrade</Button>
                    :
                    null
                }
                
            <TableContainer>
            <Table className={classes.table} aria-label="Plan Details" size="small">
                <TableBody>

                    <TableRow>
                    <TableCell></TableCell>
                    {state.plans.map((product) => {
                        return (<TableCell key={product.id}>{product.name}</TableCell>)
                    })}         
                    </TableRow>

                    <TableRow>
                    <TableCell>Domains</TableCell>
                    {state.plans.map((product) => {
                        return (<TableCell key={product.id}>{parseInt(product.metadata.maxDomainCount, 10) > 1 ? 'Up to ' : ''}{product.metadata.maxDomainCount}</TableCell>)
                    })}         
                    </TableRow>
                    
                    <TableRow>
                    <TableCell>Ingress rate</TableCell>
                    {state.plans.map((product) => {
                        return (<TableCell  key={product.id}>{parseInt(product.metadata.maxDomainRate, 10) > 1 ? `Up to ${product.metadata.maxDomainRate}/min` : `${product.metadata.maxDomainRate}/min`}</TableCell>)
                    })}         
                    </TableRow>
                   
                    <TableRow>
                    <TableCell>Waiting Rooms</TableCell>
                    {state.plans.map((product) => {
                        return (<TableCell key={product.id}>{parseInt(product.metadata.maxWaitingRoomCount, 10) > 1 ? `Up to ${product.metadata.maxWaitingRoomCount}` : `${product.metadata.maxWaitingRoomCount}`}</TableCell>)
                    })}         
                    </TableRow>

                    <TableRow>
                    <TableCell>Waiting Room Capacity</TableCell>
                    {state.plans.map((product) => {
                        return (<TableCell key={product.id}>{parseInt(product.metadata.maxWaitingRoomCapacity, 10) > 1 ? `Up to ${product.metadata.maxWaitingRoomCapacity}` : `${product.metadata.maxWaitingRoomCount}`}</TableCell>)
                    })}         
                    </TableRow>

                    <TableRow>
                    <TableCell>Custom Templates</TableCell>
                    {state.plans.map((product) => {
                        return (<TableCell key={product.id}>{parseInt(product.metadata.allowCustomTemplates, 10) === 0 ? null : <CheckIcon />}</TableCell>)
                    })}         
                    </TableRow>

                    <TableRow>
                    <TableCell>ReCaptcha</TableCell>
                    {state.plans.map((product) => {
                        return (<TableCell key={product.id}>{parseInt(product.metadata.allowCaptcha, 10) < 1 ? null : <CheckIcon />}</TableCell>)
                    })}         
                    </TableRow>

                    <TableRow>
                    <TableCell>Reporting</TableCell>
                    {state.plans.map((product) => {
                        return (<TableCell key={product.id}>{parseInt(product.metadata.allowReports, 10) < 1 ? null : <CheckIcon />}</TableCell>)
                    })}         
                    </TableRow>

                    <TableRow>
                    <TableCell>Device Fingerprinting</TableCell>
                    {state.plans.map((product) => {
                        return (<TableCell key={product.id}>{parseInt(product.metadata.allowFingerprint, 10) < 1 ? null : <CheckIcon />}</TableCell>)
                    })}         
                    </TableRow>

                    <TableRow>
                    <TableCell>Autotune</TableCell>
                    {state.plans.map((product) => {
                        return (<TableCell key={product.id}>{parseInt(product.metadata.allowAutotune, 10) < 1 ? null : <CheckIcon />}</TableCell>)
                    })}         
                    </TableRow>

                    <TableRow>
                    <TableCell>Priority Access</TableCell>
                    {state.plans.map((product) => {
                        return (<TableCell key={product.id}>{parseInt(product.metadata.allowPriorityAccess, 10) < 1 ? null : <CheckIcon />}</TableCell>)
                    })}         
                    </TableRow>

                    <TableRow>
                    <TableCell>IP Control</TableCell>
                    {state.plans.map((product) => {
                        return (<TableCell key={product.id}>{parseInt(product.metadata.allowFirewall, 10) < 1 ? null : <CheckIcon />}</TableCell>)
                    })}         
                    </TableRow>
                    
                    <TableRow>
                    <TableCell>Full API</TableCell>
                    {state.plans.map((product) => {
                        return (<TableCell key={product.id}>{parseInt(product.metadata.apiRateLimit, 10) < 1 ? null : <CheckIcon />}</TableCell>)
                    })}         
                    </TableRow>
                    
                    <TableRow>
                    <TableCell>Email Me</TableCell>
                    {state.plans.map((product) => {
                        return (<TableCell key={product.id}>{parseInt(product.metadata.allowEmailNotifications, 10) < 1 ? null : <CheckIcon />}</TableCell>)
                    })}         
                    </TableRow>

                    <TableRow>
                    <TableCell>Anomaly Detection and Threat Blocking</TableCell>
                    {state.plans.map((product) => {
                        return (<TableCell key={product.id}>{parseInt(product.metadata.allowAnomaly, 10) < 1 ? null : <CheckIcon />}</TableCell>)
                    })}         
                    </TableRow>

                    <TableRow>
                    <TableCell>2FA</TableCell>
                    {state.plans.map((product) => {
                        return (<TableCell key={product.id}>{parseInt(product.metadata.allowMFA, 10) < 1 ? null : <CheckIcon />}</TableCell>)
                    })}          
                    </TableRow>
                    
                    <TableRow>
                    <TableCell>DNS Usage</TableCell>
                    {state.plans.map((product) => {
                        return (<TableCell key={product.id}>{parseInt(product.metadata.cdnUsage, 10) < 1 ? <XIcon /> : `${product.metadata.cdnUsage}TB`}</TableCell>)
                    })}         
                    </TableRow>

                    <TableRow>
                    <TableCell>Log retention</TableCell>
                    {state.plans.map((product) => {
                        return (<TableCell key={product.id}>{parseInt(product.metadata.maxLogRetentionDays, 10) > 1 ? `${product.metadata.maxLogRetentionDays} days` : `${product.metadata.maxLogRetentionDays} day`}</TableCell>)
                    })}         
                    </TableRow>
                    
                    <TableRow>
                    <TableCell>Seats</TableCell>
                    {state.plans.map((product) => {
                        return (<TableCell key={product.id}>{parseInt(product.metadata.maxUsers, 10) > 1 ? `Up to ${product.metadata.maxUsers}` : `${product.metadata.maxUsers}`}</TableCell>)
                    })}         
                    </TableRow>

                    <TableRow>
                    <TableCell>Support Response</TableCell>
                    {state.plans.map((product) => {
                        return (<TableCell key={product.id}>{product.metadata.sla}</TableCell>)
                    })}         
                    </TableRow>
                    
                    <TableRow>
                    <TableCell>Price</TableCell>
                    {priceList && priceList.map((product, index) => {
                        return (<TableCell key={index}>{product}</TableCell>)
                    })}         
                    </TableRow>

                    <TableRow>
                    <TableCell style={{borderBottom: 'none'}}></TableCell>
                    {state.plans.map((product, index) => {
                        return (<TableCell key={product.id} style={{borderBottom: 'none', textAlign:'center'}}>{getActionButton(product, index)}</TableCell>)
                    })}         
                    </TableRow>

                </TableBody>
                </Table>
            </TableContainer>
            </CardContent>
            </Card>
            </Grid>
            :
            null
            }

            {
            (state.accountInformation.get('latest_invoice_paid') && !state.custom_plan)
            ?
            <DefaultPlanFooter/>
            :
            null
            }

            {state.temp_upgrade && !state.custom_plan
                ?
                <Typography paragraph variant="h6" component="p" align="center">
                    You are on a {state.temp_upgrade ? `custom`: null }{(state.custom_plan && (state.plan.metadata && state.plan.metadata.legacy)) ? ` or `: null}{state.plan.metadata && state.plan.metadata.legacy ? `legacy` : null} plan. File us a support ticket if you wish to upgrade or downgrade
                    </Typography>
                : 
                null
            }
            {state.custom_plan || (state.plan.metadata && state.plan.metadata.legacy) 
                ?
                <Typography paragraph variant="h6" component="p" align="center">
                    You are on a {state.custom_plan ? `custom`: null }{(state.custom_plan && (state.plan.metadata && state.plan.metadata.legacy)) ? ` or `: null}{state.plan.metadata && state.plan.metadata.legacy ? `legacy` : null} plan. File us a support ticket if you wish to upgrade or downgrade
                    </Typography>
                : 
                null
            }
            {!state.accountInformation.get('latest_invoice_paid') && state.accountInformation.get('customer').sub_object ?
                <Typography paragraph variant="h6" component="p" align="center">
                    You have an open invoice. Please pay it at <RouterLink to="/account/billing">billing</RouterLink>.
                    </Typography>
                :
                null
            }
            {!state.accountInformation.get('customer').sub_object ?
                <Typography paragraph variant="h6" component="p" align="center">
                    No subscription found.
                    </Typography>
                :
                null
            }
    
            
            

            

<ErrorSnackBar message={message} open={openErrorSnackbar} closeErrorSnackbar={closeErrorSnackbar} />


<Dialog open={open} TransitionComponent={Transition}>
    <CheckoutDialogContent>
        {getStep()}
    </CheckoutDialogContent>
</Dialog>

<Dialog open={openCDNWarning} onClose={() => {
    setOpenCDNWarning(false)
}}>
    <DialogContent style={{padding: '30px'}}>
        <Grid container spacing={2}>
            <Grid item>
                    <AlertTriangleIcon/>
                    <Typography><strong>You currently have a DNS record pointed at CrowdHandler.</strong></Typography>
                    <Typography>You will need to remove this before downgrading to a plan that doesn't support the DNS Implementation.</Typography>
            </Grid>
        </Grid>
    </DialogContent>
</Dialog>

<Dialog maxWidth='lg' open={openPlanLimitExceeded} onClose={() => {
    setOpenPlanLimitExceeded(false)
}}>
    <DialogContent style={{padding: '30px'}}>
        <Grid container spacing={2}>
            <Grid item>
                    <AlertTriangleIcon/>
                    {planLimitExceededMessage.map((message, i) => {
                        return <Grid key={i} style={{marginBottom: '12px'}}>{message.value}</Grid>;
                    })}
            </Grid>
        </Grid>
    </DialogContent>
</Dialog>


        </>
    )
}


export default AccountPlans;


const DefaultPlanFooter = () => {
    return (
        <Typography variant="h6" component="p" align="center" style={{marginBottom: '24px'}}>
            All prices are rolling monthly and <Link href="https://www.crowdhandler.com/docs/80000137860-upgrading-or-downgrading-your-plan" target="_blank">your plan can be upgraded and downgraded at any time</Link>
            </Typography>
    )
}