import React from 'react';
import { ReactComponent as RefreshCW } from './feather/refresh-cw.svg';

import { SvgIcon } from '@material-ui/core';


export default function RefreshCWIcon(props) {
    return (
        <SvgIcon {...props} component={RefreshCW} className="feather"/>
    );
  }