import React from 'react';
import { ReactComponent as PaperClip } from './feather/paperclip.svg';

import { SvgIcon } from '@material-ui/core';


export default function PaperClipIcon(props) {
    return (
        <SvgIcon {...props} component={PaperClip} className="feather"/>
    );
  }