import React, { useState, useEffect, useReducer, useRef, useCallback } from "react";
import { DateTime } from 'luxon';
import { withRouter } from 'react-router-dom';
import {groupBy, sortBy} from 'lodash';
import clsx from 'clsx';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import EyeIcon from '../../icons/EyeIcon';

import { makeStyles } from '@material-ui/core/styles';
import RoomDateTimePicker from '../../components/rooms/RoomDateTimePicker';

import XCircleIcon from '../../icons/XCircleIcon';

import UpgradeCTAPanel from '../../components/UpgradeCTAPanel'
import Config from '../../config'
import CalculateCurrentCapacityLimit from '../../lib/CalculateCurrentCapacityLimit';

import { Link as RouterLink } from 'react-router-dom';

import RedirectDialog from '../../components/RedirectDialog';
import orderBy from 'lodash/orderBy';
import { CapacityWarningPanel, LinkWithoutNavigateProps } from '../../components';

import '../../components/Styles.css'
import Popper from '@material-ui/core/Popper';


import {
  Select,
  Container,
  Grid,
  FormControl,
  MenuItem,
  OutlinedInput,
  FormHelperText,
  InputLabel,
  Button,
  Snackbar,
  IconButton,
  Paper,
  InputAdornment,
  Input,
  FormGroup,
  Typography,
  Slider,
  Divider,
  FormControlLabel,
  Checkbox,
  Link,
  Backdrop,
  CircularProgress,
} from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(6),
  },
  paper: {
    padding: theme.spacing(2),
  },
  noUnderline: {
    '& .MuiInput-underline:before': {
      display: 'none'
    },
    '& .MuiInput-underline:after': {
      display: 'none'
    }
  },
  smallmargin: {
    marginBottom: theme.spacing(2),
  },
  withoutLabel: {
    marginTop: theme.spacing(6),
  },
  domainAdornment: {
    marginRight: 0
  },
  regexField: {
    height: '16px'
  },
  regexFieldInput: {
    alignItems: 'flex-start'
  },
  spinner: {
    marginLeft: 22,
    color: theme.palette.secondary
  },
  slugAdornment: {
    marginRight: '3px'
  },
  countdownStartsOn: {
    paddingRight: 0,
    [theme.breakpoints.up('lg')]: {
      paddingRight: 14,
    },
  },
  patternMatchInput: {
    marginTop: 0,
    paddingTop: 0,
    '& .MuiFormControl-marginDense' : {
      marginTop: 0,

    }
  },
  helpertext_error : {
    
    color: 'red'
  
},
  callout: {
    border: '1px solid',
    borderColor: theme.palette.secondary.main,
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(1),
  },
  capacitySlider: {
    margin: `${theme.spacing(4)}px 0`,
    '& .MuiSlider-markLabel' : {
      top:'16px',
      '&[data-index="0"]' : {
        left: '5px !important'
      },
      '&[data-index="1"]' : {
        left: 'calc(100% - 5px) !important'
      } 
    }
  },
  dateError: {
    backgroundColor: theme.palette.error.main,
    color: '#ffffff',
    borderRadius: '4px',
    padding: theme.spacing(1)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.primary.main,
    // backgroundColor: '#fff',
  },
}));


const AddRoom = ({ state, computedMatch, history, api, ...rest }) => {

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [canAddRoom] = useState(true);
  const { dispatch } = rest;
  const [domainId] = useState(computedMatch.params.domainId || false);
  const [initialCapacity] = useState(0);
  const [usedCapacity, setUsedCapacity] = useState(0);
  const [availableRoomCapacity, setAvailableRoomCapacity] = useState(0);
  const [, setMaxRoomCapacity] = useState(0);
  const [messageLength, setMessageLength] = useState(0);

  const [openRedirectDialog, setOpenRedirectDialog] = useState(false);
  const [followedRedirectUrl, setFollowedRedirectUrl] = useState();
  const [redirectType, setRedirectType] = useState(1);
  const [saveRoomValues, setSaveRoomValues] = useState();
  const [displayStockLevels, setDisplayStockLevels] = useState(true);
  const [savingRoom, setSavingRoom] = useState(false);

  const [location] = useState(history.location);

  let fromDashboardOnboarding;

  if (history.location && history.location.state) {
    if (history.location.state.from === 'dash' && history.location.state.action === 'add_room') {
      fromDashboardOnboarding = true;
    }
  }
/*
<Popper id={id} open={open} anchorEl={anchorEl}>
        <div className={classes.paper}>The content of the Popper.</div>
      </Popper>
      */  

  const [dateTimeError, setDateTimeError] = useState({
    message: ''
  })
  
  const dateFormat = Config.DATE_FORMATS.FULL_NO_TIME;
  const [, setDisplayCatchAllMessage] = useState(false);
  const [domain, setDomain] = useState();

  const [fields, setFields] = useState({
    countdownStartsOn: {
      date: DateTime.utc().startOf('minute')
    },
    queueActivatesOn: {
      date: DateTime.utc().startOf('minute'),
    }
  });


  function reducer(state, action) {
    switch (action.type) {
      case 'UPDATE_SETTINGS':
        let newSteps = {
          ...state,
          ...action.payload
        }
        return newSteps;
      default:
        throw new Error();
    }
  }

  const [settings, setSettings] = useReducer(reducer, {
    title: "Waiting Room",
    message: "",
    urlRedirect: "/",
    urlPattern: "",
    capacity: 2500,
    domainID: domainId,
    stock: null,
    stockLevel: 0,
    displayStock: false,
    patternType: 'disabled',
    templateID: '',
    method: 'post'
  });


  useEffect(() => {
    const getData =  async () => {
      let domains = await api.getDomains();
      dispatch({ type: 'ADD_DOMAINS', payload: domains });
      let rooms = await api.getRooms();
      dispatch({ type: 'UPDATE_ROOMS', payload: rooms });
    }

    let customTemplates = 0;
    if (state.plan) {
     customTemplates = state.plan.allowCustomTemplates;
    }

    if (customTemplates === 1) {
      setDisplayStockLevels(true);
    } else {
      setDisplayStockLevels(false);
    }

    getData();
 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {

    const callTemplates = async () => {
      let templates = await api.getTemplates();
      if (templates) {
        dispatch({ type: 'UPDATE_TEMPLATES', payload: templates });
      }
    }
    
    if (!state.templates) {
      callTemplates()
    } else {
      setSettings({ type: 'UPDATE_SETTINGS', payload: { templateID: state.templates[0].id } });
    }
  }, [api, state.templates, dispatch]);

  useEffect(() => {
    const getDomains = async () => {
      let domains = await api.getDomains();
      await dispatch({ type: 'ADD_DOMAINS', payload: domains });
    }
    if (state.domains === undefined) {
      getDomains();
    }

    if(domainId && state.domains) {
        let domain = state.domains.reduce((acc, domain) => {
          if(domain.id === domainId) {
            acc = domain;
          }
          return acc;
        }, {});

        setDomain(domain)
    }

  }, [api, state.domains, dispatch, domainId])

  const checkAutotune = async (result) => {
    let domainGroups = groupBy(result, 'domainID');

    for (const key in domainGroups) {
      if (domainGroups.hasOwnProperty(key)) {
        const rooms = domainGroups[key];
        let allowAutoTune = false;

        for (let i = 0; i < rooms.length; i++) {
          const room = rooms[i];
          if (room.patternType === 'all') {
            allowAutoTune = true;
          }
        }

        if (!allowAutoTune) {
          let data = {
            autotune: '0',
            id: key
          }
          let response = await api.putDomain(data, 'put');
          if (!response.error) {
            dispatch({ type: 'UPDATE_DOMAINS', payload: response, updateCurrentDomain: true });
          } else {
            // console.log(response.error);
          }
        }

      }
    }
  }

  let firstRun = useRef(false);
  const calculateCurrentCapacityLimit = () => {

    let max_capacity = state.plan.maxWaitingRoomCapacity;
    let available_room_capacity = CalculateCurrentCapacityLimit(state.rooms, max_capacity, 0);
    setUsedCapacity(state.plan.maxWaitingRoomCapacity - available_room_capacity);
    setAvailableRoomCapacity(available_room_capacity);
    setMaxRoomCapacity(available_room_capacity);
    
    if (!firstRun.current) {

      let target_value = Math.floor(available_room_capacity/2);
      formik.setFieldValue('capacity', target_value);
      setSettings({ type: 'UPDATE_SETTINGS', payload: { capacity: target_value } });
      firstRun.current = true;
    }
    
  }

  useEffect(() => {

    if (state.rooms) {
      calculateCurrentCapacityLimit();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.rooms]);

  useEffect(() => {
    const setDefaultTemplateId = () => {
      let templateIDs = state.templates.reduce((acc, template) => {
        if (template.custom === 0) {
          acc.push(template.id);
        }

        return acc;
      },[])
      setSettings({ type: 'UPDATE_SETTINGS', payload: { templateID: templateIDs[0] } });
    }

    if (state.templates) {
      setDefaultTemplateId();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.templates]);


  const handleRoomsCapacityChange = (event, newValue) => {
    setDisplayCatchAllMessage(false);
    if (newValue > (state.plan.maxWaitingRoomCapacity - usedCapacity + initialCapacity)) {
      newValue = state.plan.maxWaitingRoomCapacity - usedCapacity + initialCapacity
    }
    formik.setFieldValue('capacity', newValue);
    calculateCurrentCapacityLimit();
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  
  const upgradeMessage = 'You have filled your available room allocation. Upgrade to get extra rooms';

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    initialValues: {
      ...settings
    },
    validationSchema: Yup.object({
      title: Yup.string().required('Required'),
      message: Yup.string().max(512, 'Maximum number of characters met'),
      urlRedirect: Yup.string().test('test-slash', 'Redirect needs to start with a forward slash', 
        function(value) {
            return value && value[0].indexOf('/') !== -1;
        }).required('Required')
        .test('test-whitespace', 'Spaces are not allowed',
      function(value){
        var inValid = /\s/;
        var k = inValid.test(value);
        return !k;
      }),
        urlPattern: Yup.string().when("patternType", {
          is: (patternType) => {
            let validate = true;

            if (patternType === 'all') {
              validate = false;
            }
            if (patternType === 'disabled') {
              validate = false;
            }
            return validate;

          },
          then: Yup.string().test('contains-domain', 'Do not include your domain', 
            function(value) {
              const { path, createError } = this;
              if (!value) {
                return createError({path, message: 'This is required'});
              } 
              let hasDomainInValue = (value.indexOf(`${domain.name}`) === -1) ? false : true;
              if (hasDomainInValue) {
                return createError({path, message: 'Do not include your domain'});
              }
              return true;
            })
          
        })
    }),
    onSubmit: async (values) => {
      setSavingRoom(true);
      if (domain && domain.deployment === 'javascript') {
        let result = await checkRedirect(values.urlRedirect.trim());
        if (result.response === 'error') {
          setRedirectType(2);

          if (result.error.statusText === 'REDIRECTLOOP') {
            setRedirectType(3);
          }


          setSaveRoomValues(values);
          setFollowedRedirectUrl(values.urlRedirect.trim())
          setOpenRedirectDialog(true);
          setSavingRoom(false);
          return false;
        } else if (result.redirects.length > 1 && values.urlRedirect.trim() !== result.response.redirectUrl.trim()) {
          setFollowedRedirectUrl(result.response.redirectUrl.trim())
          setRedirectType(5)
          setSaveRoomValues(values);
          setOpenRedirectDialog(true);
          setSavingRoom(false);
          return false;
        }

        

      }

    
      saveRoom(values)

    }
  });

  const saveRoom = async (values) => {
    let domainID = computedMatch.params.domainId;
      let dataToSave = {
        queueActivatesOn: DateTime.fromISO(fields.queueActivatesOn.date, { zone: 'utc' }).toISO(),
        countdownStartsOn: DateTime.fromISO(fields.countdownStartsOn.date, { zone: 'utc' }).toISO(),
        domainID: values.domainID,
        title: values.title,
        urlRedirect: values.urlRedirect,
        urlPattern: values.urlPattern,
        message: values.message,
        templateID: values.templateID,
        patternType: values.patternType,
        capacity: values.capacity,
        id: domainID,
        stock: (values.displayStock) ? parseInt(values.stockLevel, 10) : null,
      };
      
      let extraFieldsForUpdate = {}

      let data = {
        ...dataToSave,
        ...extraFieldsForUpdate
      }
  
      let result = await api.addRoom(data, 'post');
      if (result.error) {
        
          if (result.error.indexOf('regular expression') !== -1) {
            const capitalized = result.error.trim().charAt(0).toUpperCase() + result.error.trim().slice(1);
            setErrorMessage(capitalized);
          } else {
            setErrorMessage('There has been a problem adding your room');
          }
        
        setOpen(true);
      } else {
        dispatch({ type: 'UPDATE_ROOMS', payload: result });
        await checkAutotune(result);

        if(location.state && location.state.from) {

          switch (location.state.from) {
            case 'dash':
              history.push('/');
              break;
          
            default:
              history.goBack();
              break;
          }
        } else {
          history.goBack();
        }
      }
  }

  useEffect(() => {

    if (state.rooms && formik.values.patternType) {
      calculateCurrentCapacityLimit();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.patternType]);


  const onDateChange = useCallback((date, field_name) => {

    setDateTimeError({
      message: ''
    });
    let valid = true;

    switch (field_name) {
      case 'countdownStartsOn':
        let queueActivatesOn = DateTime.fromISO(fields.queueActivatesOn.date).toMillis();
        if (queueActivatesOn < date.ts) {
          setDateTimeError({
            message: 'The countdown must be before the queue activates.'
          });
          valid = false;
        }

        // let diff = d1.toMillis() === d2.toMillis()
        break;
      case 'queueActivatesOn':
        let countdownStartsOn = DateTime.fromISO(fields.countdownStartsOn.date).toMillis();
        if (countdownStartsOn > date.ts) {
          setDateTimeError({
            message: 'The queue must activate after the countdown starts.'
          });
          valid = false;
        }
        // let diff = d1.toMillis() === d2.toMillis()
        break;

      default:
        break;
    }

    if (valid) {
      setFields((f) => {
        return {
          ...f,
          [field_name]: {
            date: date
          }
        }
      }
      );
    }
  },
    [fields.countdownStartsOn.date, fields.queueActivatesOn.date]
  );

  const [capacityStep, setCapacityStep] = useState(100);

  useEffect(() => {
    let step = Math.round((availableRoomCapacity/100) / 10) * 10;

    if (step < 1) {
      step = 1;
    }
    
    setCapacityStep(step)

  }, [availableRoomCapacity] );

  const handleRedirectDialogClose = (event, reason) => {

    if (reason === 'backdropClick') {
      return false;
    }
    setOpenRedirectDialog(false);
  }

  const handleRedirectDialogAgree = () => {
    setOpenRedirectDialog(false);
    saveRoom(saveRoomValues);
  }

  const handleRedirectDialogCancel = () => {
    setOpenRedirectDialog(false);
  }

  const checkRedirect = async (urlRedirect) => {


    let result = await api.checkRedirect(
      urlRedirect,
      domain.name
    );

    return result;
  }


  return (

    <div className={classes.root}>

      {state.domains || state.templates
        ?
        <Container
          maxWidth="md"
          spacing={4}
        >

          {
            (!canAddRoom)
              ?
              <UpgradeCTAPanel upgradeMessage={upgradeMessage} />
              :
              null
          }
         

          {
            (canAddRoom || domainId ) 
              ?
              <Grid container component={Paper} className={classes.paper} spacing={2}>
                <Grid item xs={12}>
                  <FormControl fullWidth margin="normal" variant="outlined">
                    <InputLabel htmlFor="title">Waiting Room</InputLabel>
                    <OutlinedInput
                      id="title"
                      labelWidth={90}
                      placeholder="Waiting Room"
                      aria-label="Waiting Room Title"
                      {...formik.getFieldProps('title')}
                    />
                    <FormHelperText>This name will be displayed to your users</FormHelperText>
                    {formik.touched.title && formik.errors.title ? <Typography variant="subtitle2" color="error">{formik.errors.title}</Typography> : null}
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <FormControl fullWidth margin="normal" variant="outlined">
                    <InputLabel htmlFor="title">Redirect users to</InputLabel>
                    <OutlinedInput
                      id="urlRedirect"
                      {...formik.getFieldProps('urlRedirect')}
                      labelWidth={106}
                      aria-label="URL Redirect"
                      required
                      startAdornment={<InputAdornment position="start" className={classes.slugAdornment}>{`${(domain) ? `${domain.name}` : ''}`}</InputAdornment>}
                    />
                    {formik.touched.urlRedirect && formik.errors.urlRedirect ? <Typography variant="subtitle2" color="error">{formik.errors.urlRedirect}</Typography> : null}
                    <FormHelperText>If you send users directly to the waiting room, they will be forwarded to this URL when leaving the queue. If the user is redirected from your website to a waiting room, the user will be sent back to the url that they were trying to access on your website.</FormHelperText>
                  </FormControl>
                </Grid>

           
                <Grid item xs={12}>
                  <FormControl fullWidth margin="normal" variant="outlined">
                    <InputLabel htmlFor="title">Message</InputLabel>
                    <OutlinedInput
                      id="message"
                      {...formik.getFieldProps('message')}
                      labelWidth={54}
                      placeholder="Message"
                      aria-label="Message"
                      multiline
                      rows={4}
                      inputProps={{ maxLength: 512 }}

                      onKeyUp={ function(event) {
                        setMessageLength(event.currentTarget.value.length);
                      } }
                    />
                    <FormHelperText>A message to be displayed to the users in the waiting room</FormHelperText>
                    {formik.touched.message && formik.errors.message ? <Typography variant="subtitle2" color="error">{formik.errors.message}</Typography> : null}
                      <FormHelperText>
                        Characters remaining  {512 - messageLength}
                      </FormHelperText>
                  </FormControl>
                </Grid>

                <>
                <Grid item xs={12}>
                <Divider />
                </Grid>
                <Grid item xs={12} md={3}>
                    <FormControlLabel
                        control={
                          <Checkbox
                            {...formik.getFieldProps('displayStock')}
                            checked={formik.values.displayStock}
                            name="displayStock"
                            disabled={!displayStockLevels}
                          />
                        }
                        label="Show Stock Availability"
                        />
                </Grid>
                
                <Grid item xs={12} md={4}>
                  <FormControl fullWidth margin="dense" variant="outlined">
                    <InputLabel htmlFor="title">Availability</InputLabel>
                    <OutlinedInput
                      disabled={!formik.values.displayStock || !displayStockLevels}
                      type="number"
                      id="stockLevel"
                      {...formik.getFieldProps('stockLevel')}
                      labelWidth={72}
                      aria-label="Stock Availability"
                      />
                  </FormControl>
                {
                !displayStockLevels ?
                <FormHelperText><RouterLink to="/account/plan">Upgrade</RouterLink> to use Stock Availability</FormHelperText>
                  :
                  null
                }
                </Grid>
                <Grid item xs={12}>
                <Divider />


                </Grid>
                </>


                <Grid item xs={12} className={classes.capacitySlider}>

                  <FormGroup>
                  <InputLabel htmlFor="capacity">Room Capacity</InputLabel>
                  <Grid container justifyContent="space-between" spacing={4}>
                    <FormControl fullWidth margin="normal" variant="outlined" component={Grid} xs={10} item>

                      <Slider
                      id="capacity"
                      step={capacityStep}
                      aria-label="Room Capacity"
                      onChange={(ev, b) => handleRoomsCapacityChange(ev, b)}
                      value={availableRoomCapacity > 0 ? formik.values.capacity: 0}
                      min={0}
                      max={availableRoomCapacity > 0 ? availableRoomCapacity : 100}
                      disabled={availableRoomCapacity > 0 ? false: true}
                      marks={

                        availableRoomCapacity > 0
                        ?
                        [
                        {
                          value: 0,
                          label: '0',
                        },
                        {
                          value: availableRoomCapacity,
                          label: `${availableRoomCapacity/1000}K`,
                        },
                      ]
                      :
                      [
                        {value: 0,
                          label: '0',},{value: 100,
                            label: '100',}
                      ]
                    
                    }
                      valueLabelDisplay="off"
                    />
                    
                    </FormControl>
                    <FormControl  component={Grid} xs={2} item>
                      <OutlinedInput
                        disabled={!availableRoomCapacity || availableRoomCapacity < 1}
                        type="number"
                        id="capacity"
                        {...formik.getFieldProps('capacity')}
                        labelWidth={72}
                        aria-label="Capacity"
                        inputProps={{
                          min:"0",
                          max:availableRoomCapacity
                        }}
                        notched={false}
                        />
                    </FormControl>
                    </Grid>

                  { formik.values.capacity >= 50 && availableRoomCapacity > 0 ?
                    <FormHelperText style={{zIndex: 10}}>The number of people this waiting room can hold before <Link target="_blank" href="https://support.crowdhandler.com/support/solutions/articles/80000602693-what-happens-when-a-waiting-room-is-full-">becoming full</Link></FormHelperText>

                  : null }

                  {availableRoomCapacity <= 0 ?
                    <Typography className={classes.helpertext_error} color="error">
                      You currently have no available capacity. Reduce capacity on an existing room in order to allocate capacity to this room.
                    </Typography>
                    :
                    null
                  }
                    {formik.values.capacity < 1 && availableRoomCapacity && availableRoomCapacity > 0 ?
                        
                        <CapacityWarningPanel color={'red'}>
                        <Typography>
                        This room will <Link target="_blank" href="https://support.crowdhandler.com/support/solutions/articles/80000602693-what-happens-when-a-waiting-room-is-full-">always be full</Link>, if you are trying to set the rate, use <RouterLink component={LinkWithoutNavigateProps} to={`/domains/${domain.id}`}>the domain settings</RouterLink>
                         </Typography>
                     </CapacityWarningPanel>

                      :
                      null
                    }
                    {formik.values.capacity < 50 && formik.values.capacity > 0 && availableRoomCapacity && availableRoomCapacity > 0 ?
                     <CapacityWarningPanel color={'#ff5722'}>
                     <Typography>
                     This is a very small waiting room, if you are trying to set the rate, use <RouterLink component={LinkWithoutNavigateProps} to={`/domains/${domain.id}`}>the domain settings</RouterLink>
                        </Typography>
                    </CapacityWarningPanel>
                      :
                      null
                    }

                  </FormGroup>

                </Grid>

                {state.templates
                  ?
                  <Grid item xs={12}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs>
                            {state.templates.length > 1
                            ?
                            <>
                          <FormControl fullWidth margin="dense" variant="standard">
                            <InputLabel id="template_id_label">Template</InputLabel>

                            <Select required labelId="template_id_label" id="templateID"
                            {...formik.getFieldProps('templateID')}>

                              {sortBy(state.templates, [t => t.name.toLowerCase()]).map((template) => {
                                
                                if (state.plan.allowCustomTemplates.toString() === '1') {
                                  return <MenuItem key={template.id} value={template.id}>{template.name}</MenuItem>;
                                } else {
                                  if (template.custom === 0) {
                                    return <MenuItem key={template.id} value={template.id}>{template.name}</MenuItem>;
                                  } else {
                                    return null;
                                  }
                                }
                              })}
                            </Select>                            
                            <FormHelperText>Select the template for this room</FormHelperText>
                          </FormControl>
                              </>
                              :
                              null
                              }
                      </Grid>
                    </Grid>
                  </Grid>
                  :
                  <Grid item xs={12}><FormHelperText>There was an error fetching the templates. Please refresh to try again</FormHelperText></Grid>
                }

                <Grid item md={6}>
                  <RoomDateTimePicker
                  
                    maxDate={fields.queueActivatesOn}
                    dateTime={fields.countdownStartsOn}
                    dateFormat={dateFormat}
                    label="Countdown Starts"
                    onDateChange={onDateChange}
                    fieldName={'countdownStartsOn'}
                  />
                  </Grid>
                  <Grid item md={6}>
                  <RoomDateTimePicker
                    minDate={fields.countdownStartsOn}
                    dateTime={fields.queueActivatesOn}
                    dateFormat={dateFormat}
                    label="Queue Activates"
                    onDateChange={onDateChange}
                    fieldName={'queueActivatesOn'}
                  />
                  </Grid>

                {dateTimeError.message
                ?
                <Grid item xs={12} className={classes.dateError}>
                  <Typography>{dateTimeError.message}</Typography>
                </Grid>
                :
                null
                }

                <Grid item xs={12}>
                  <FormControl fullWidth margin="normal" variant="standard">
                    <InputLabel id="domain_label">Protect URLs</InputLabel>
                    <Select required labelId="domain_label"
                      id="patternType"
                      name="patternType"
                      {...formik.getFieldProps('patternType')}
                    >
                      <MenuItem value='disabled'>Disabled</MenuItem>
                      <MenuItem value='all'>All of {(domain) ? domain.name : null}</MenuItem>
                      <MenuItem value='contains'>URL Contains</MenuItem>
                      <MenuItem value='regex'>URL matches regular expression</MenuItem>
                      <MenuItem value='regex-not'>URL does NOT match regular expression</MenuItem>

                    </Select>
                  </FormControl>
                </Grid>

                {
                  formik.values.patternType === 'contains' ||
                  formik.values.patternType === 'regex' ||
                  formik.values.patternType === 'regex-not'
                  ?
                  <Grid item xs={12} className={classes.patternMatchInput}>
                    <FormControl fullWidth margin="dense" variant="outlined">
                      <InputLabel htmlFor="title">{(formik.values.patternType === 'regex' || formik.values.patternType === 'regex-not') ? 'Regular Expression' : (formik.values.patternType === 'contains' || formik.values.patternType === 'contains-not') ? 'String' : 'All'}</InputLabel>
                      <OutlinedInput
                        className={clsx({
                          [classes.regexFieldInput]: (formik.values.patternType === 'regex' || formik.values.patternType === 'regex-not')
                        })}
                        {...formik.getFieldProps('urlPattern')}
                        multiline={(formik.values.patternType === 'regex' || formik.values.patternType === 'regex-not')}
                        rows={(formik.values.patternType === 'regex' || formik.values.patternType === 'regex-not') ? 4 : 1}
                        labelWidth={(formik.values.patternType === 'regex' || formik.values.patternType === 'regex-not') ? 120 : (formik.values.patternType === 'contains' || formik.values.patternType === 'contains-not') ? 40 : 20}
                        placeholder={(formik.values.patternType === 'regex' || formik.values.patternType === 'regex-not') ? 'RegEx Pattern' : (formik.values.patternType === 'contains' || formik.values.patternType === 'contains-not') ? 'String' : 'All'}
                        aria-label="URL Pattern"
                        disabled={formik.values.patternType === 'all' || formik.values.patternType === 'disabled'}

                      />

                        {
                        (formik.values.patternType !== 'all')
                        ?
                        <>
                        {(formik.touched.urlPattern && formik.errors.urlPattern) || formik.touched.patternType ? <Typography variant="subtitle2" color="error">{formik.errors.urlPattern}</Typography> : null}
                        <FormHelperText>Required. Do not include your domain.</FormHelperText>
                        </>
                        :
                        null
                        } 
                        {
                        (formik.values.patternType === 'regex')
                          ?
                          <FormHelperText>A pattern match for the URLs that should put users into the waiting room.</FormHelperText>
                          :
                          null
                        }
                        {
                        (formik.values.patternType === 'regex-not')
                          ?
                          <FormHelperText>A pattern match for the URLs that should NOT put users into the waiting room.</FormHelperText>
                          :
                          null
                      }

                        {(formik.values.patternType === 'regex' || formik.values.patternType === 'regex-not')
                        ?
                        <FormHelperText>The CrowdHandler pattern matching engine uses the POSIX-Extended regular expression syntax. <a href="https://support.crowdhandler.com/a/solutions/articles/80000599765" rel="noopener noreferrer" target="_blank">Using RexEg for URL pattern matching</a></FormHelperText>
                        :
                        null
                        }
                      
                      </FormControl>
                  </Grid>
                  :
                  null
                }

                {(computedMatch.params.domainId)
                  ?
                  <Input name="domainID" type="hidden" value={computedMatch.params.domainId} />
                  :
                  null
                }


                <Grid item xs={12} align="right">
                  <Button
                    disableElevation
                    color="primary"
                    onClick={history.goBack}>
                    Cancel</Button>
                  <Button
                    style={{ marginLeft: 8 }}
                    className={fromDashboardOnboarding ? 'button_anim' : ''}
                    disableElevation
                    variant="contained"
                    color="secondary"
                    disabled={!formik.isValid}
                    onClick={formik.handleSubmit}>
                    Save Room</Button>
                </Grid>
              </Grid>

              :
              null
          }
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            message={errorMessage}
            action={
              <>

                <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                  <XCircleIcon fontSize="small" />
                </IconButton>
              </>
            }
          />

          <Backdrop className={classes.backdrop} open={savingRoom}>
            <Grid container justifyContent="center" alignItems="center" direction="column" spacing={4}>
              <Grid item>
                <CircularProgress color="inherit" />
              </Grid>
            </Grid>
          </Backdrop>

          <RedirectDialog
          domain={domain}
          openRedirectDialog={openRedirectDialog}
          handleRedirectDialogClose={handleRedirectDialogClose}
          onClose={handleRedirectDialogClose}
          followedRedirectUrl={followedRedirectUrl}
          handleRedirectDialogAgree={handleRedirectDialogAgree}
          domainId={(domain) ? domain.id : null}
          redirectType={redirectType}
          handleRedirectDialogCancel={handleRedirectDialogCancel}

        
          />

        </Container>
        :
        null
      }
    </div>
  );
};

export default withRouter(AddRoom);

