import {
  CognitoUserPool,
  CognitoUser,
  CognitoUserSession,
  CognitoIdToken,
  CognitoAccessToken,
  CognitoRefreshToken,
} from 'amazon-cognito-identity-js';

import * as AWS from 'aws-sdk/global';



const handleCognitoSignIn = async ({ username, password }) => {

  var myHeaders = new Headers();
  myHeaders.append("x-api-key", process.env.REACT_APP_CH_COGNITO_API_KEY_EU);
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    "username": username,
    "password": password
  });

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  try {
    let response = await fetch(`${process.env.REACT_APP_CH_COGNITO_API_EU}signin`, requestOptions);
    response = await response.json();


    const {APIRegion} =  response;

    if (response) {

      if (response.ChallengeName) {
        return response;
      }

      if (response.name && response.message) {
        return {
          error: {
            name : response.name,
            message : response.message,
          }
        };
      }
      
      var poolData = {
        UserPoolId: response.userPoolId, // Your user pool id here
        ClientId: response.clientId, // Your client id here
      };

      var userPool = new CognitoUserPool(poolData);
      var userData = {
        Username: response.sub,
        Pool: userPool,
      };
      var cognitoUser = new CognitoUser(userData);

      const tokens = {
        IdToken: response.IdToken,
        AccessToken: response.AccessToken,
        RefreshToken: response.RefreshToken,
      }

      cognitoUser.setSignInUserSession(new CognitoUserSession({
        IdToken: new CognitoIdToken(tokens),
        AccessToken: new CognitoAccessToken(tokens),
        RefreshToken: new CognitoRefreshToken(tokens),
      }));

      const Logins = {
        [`cognito-idp.${response.region}.amazonaws.com/${response.userPoolId}`]: response.IdToken
      }

      const IdentityPoolId = response.identityPoolId;


      if ( AWS.config.credentials && AWS.config.credentials.clearCachedId ) {
        await AWS.config.credentials.clearCachedId()
      }

      const setAWSCredentials = () => {
        
        return new Promise(async (resolve, reject) => {

          try {
            AWS.config.region = response.region;
            AWS.config.credentials = new AWS.CognitoIdentityCredentials({
              IdentityPoolId, // your identity pool id here
              Logins
            });
      
            await AWS.config.credentials.refresh();
    
            resolve(true);
            
          } catch (error) {
            reject({error:'ERRORTYPE'})
          }
  
        })
      }

      await setAWSCredentials();


      


      return {cognitoUser, Region: APIRegion};
    }

    return false;
  } catch (error) {
    console.log(error);
    return false;
  }




}

export default handleCognitoSignIn;