import React from 'react';
import { ReactComponent as User } from './feather/user.svg';

import { SvgIcon } from '@material-ui/core';


export default function UserIcon(props) {
    return (
        <SvgIcon {...props} component={User} className="feather"/>
    );
  }