import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { DomainsTable, UpgradeCTAPanel } from '../../components';
import isArray from 'lodash/isArray';

import {
  Button,
  Container,
  Grid,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Typography,
  Backdrop,
  CircularProgress
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    marginBottom: theme.spacing(6),
  },
  buttonHeader: {
    padding: theme.spacing(0),
    paddingBottom: theme.spacing(2)
  },
  smallmargin: {
    marginBottom: theme.spacing(2),
  },
  withoutLabel: {
    marginTop: theme.spacing(6),
  },
  domainAdornment: {
    marginRight: 0
  },
  spinner: {
    marginLeft: 22,
    color: theme.palette.secondary
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.secondary.main,
  },


}));

const Domains = props => {

  const { api, state, ...rest } = { ...props };
  const classes = useStyles();

  const { dispatch } = rest;

  const [processing, setProcessing] = React.useState(false);

  const [openDeleteDomainDialog, setOpenDeleteDomainDialog] = useState(false);
  const [domainToDelete, setDomainToDelete] = useState({});

  let [domainsForTable, setDomainsForTable] = useState([]);
  let [gettingData, setGettingData] = useState(true);


  let [canAddDomains, setCanAddDomains] = useState(true);
  let [upgradeMessage] = useState('You have used your available domain allocation. Upgrade to get extra domains');
  let [displayUpgradeButton] = useState(true);


  const firstRun = useRef(true);
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    const getData =  async () => {
      if (isMounted.current && firstRun.current) {
        setGettingData(true);
      }
      
      let domains = await api.getDomains();
      firstRun.current = false;
      if(isMounted.current) {
        dispatch({ type: 'ADD_DOMAINS', payload: domains });
        setGettingData(false)
      }
    }
    getData();
    let intervalsID = setInterval( async() => {
      await getData();
    }, 60000);

    return () => {
      clearInterval(intervalsID);
      isMounted.current = false;
    }
 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {

    const setDomains = () => {


      let formattedDomains = state.domains.map((domain) => {

        if (domain.autotune === 1) {
          domain.autotuneRate = `Autotune ${domain.rate}`
        } else {
          domain.autotuneRate = domain.rate;
        }
        return domain;

      });

      setDomainsForTable(formattedDomains);
    }

    if (isArray(state.domains)) {
      setDomains();
    }


  }, [state.domains]);


  const deleteDomainHandler = (domain) => {
    setDomainToDelete(domain);
    setOpenDeleteDomainDialog(true);
  }

  const handleCancel = () => {
    setDomainToDelete({});
    setOpenDeleteDomainDialog(false)
  }

  const handleOk = async () => {

    setProcessing(true);
    setOpenDeleteDomainDialog(false);
    let result = api.deleteDomain(domainToDelete.id);
    setDomainToDelete({});
    result.then(async (response) => {

      let rooms = await api.getRooms();

      if (rooms) {
        dispatch({ type: 'UPDATE_ROOMS', payload: rooms });
      }
      dispatch({ type: 'DELETE_DOMAIN', payload: response });

      if(response.length) {
        if (state.currentDomain.id === domainToDelete.id) {
          dispatch({ type: 'SET_CURRENT_DOMAIN', payload: response[0] });
          dispatch({ type: 'UPDATE_SELECTED_DOMAIN', payload: response[0].id });
        }
      } else {
        dispatch({ type: 'SET_CURRENT_DOMAIN', payload: undefined });
      }

      // is the deleted domain the currect domain
      
      setProcessing(false);

    })
    .catch(function (error) {
        setProcessing(false);
      });
  }

  useEffect(() => {
    const canAddDomains = () => {
      let maxDomains = parseInt(state.plan.maxDomainCount, 10);
      let numOfDomains = parseInt(state.domains.length, 10);

      if (numOfDomains < maxDomains) {
        setCanAddDomains(true);
      } else {
        setCanAddDomains(false);
      }
    }

    if (state.domains && state.plan) {

      canAddDomains()

    }

  }, [state.plan, state.domains, state.plans]);


  return (

    <div className={classes.root}>
      {state.domains
        ?
        <Container
          maxWidth="lg"
          spacing={4}
        >

          <Grid container className={classes.buttonHeader}>
            <Grid item xs={12} align="right">
              {
                (!canAddDomains)
                  ?
                  <UpgradeCTAPanel upgradeMessage={upgradeMessage} displayUpgradeButton={displayUpgradeButton} />
                  :

                  null
              }
            </Grid>
          </Grid>


          <Grid component="section" className={classes.margin} >
            <DomainsTable canAddDomains={canAddDomains} api={api} dispatch={dispatch} gettingData={gettingData} state={state} component="section" domains={domainsForTable} deleteDomainHandler={deleteDomainHandler} />
          </Grid>


        </Container>
        :
        null
      }


      <Dialog
        disableEscapeKeyDown
        maxWidth="xs"
        aria-labelledby="confirmation-dialog-title"
        open={openDeleteDomainDialog}
        onClose={(event, reason) => {
          if (reason === 'backdropClick') {
            return;
          }
          setOpenDeleteDomainDialog(false);
        }}

      >
        <DialogTitle id="confirmation-dialog-title">Delete Domain?</DialogTitle>
        <DialogContent dividers>
          <Typography>{`Are you sure you want to delete the domain ${domainToDelete.name}?`}</Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCancel} color="primary">
            Cancel
        </Button>
          <Button onClick={handleOk} color="secondary">
            Ok
        </Button>
        </DialogActions>
      </Dialog>

      <Backdrop className={classes.backdrop} open={processing}>
        <CircularProgress color="inherit" disableShrink />
      </Backdrop>

    </div>
  );
};

export default Domains;