import React, { useState, useEffect, useRef, useContext } from "react";
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import sortBy from 'lodash/sortBy';
import AccountPlans from '../../components/Account/AccountPlans';
import ErrorBoundary from '../../components/ErrorBoundary';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { withRouter } from 'react-router-dom';
import AccountAPI from '../../components/Account/AccountAPI';
import AccountDetails from '../../components/Account/AccountDetails';
import AccountBilling from '../../components/Account/AccountBilling';
import AccountSecurity from '../../components/Account/AccountSecurity';
import clsx from 'clsx';
import { ChevronDownIcon, ChevronUpIcon } from '../../icons';
import useHasSession from '../../components/login/useHasSession';
import { HashLink } from 'react-router-hash-link';
import {AuthContext} from '../../providers/AuthProvider';
import ReloadPanel from '../../components/login/ReloadPanel';

import {
  List,
  ListItemText,
  ListItem,
  Typography,
  Grid,
  Tabs,
  Tab,
  Box,
  Collapse,
  Backdrop,
  CircularProgress,
} from '@material-ui/core';

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(2)
  },
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
  },
  accountMessage: {
    marginBottom: theme.spacing(4),
  },
  accountMessageBG: {
    color: '#fff',
    backgroundColor: theme.palette.primary.main,
  },
  tabs: {
    //borderRight: `1px solid ${theme.palette.divider}`,
  },
  tabsHorizontal: {
    borderRight: `none`,
  },
  tabPanel: {
    
  },
  apiListItem: {

    color: theme.palette.grey[600],
    paddingTop: 4,
    paddingBottom: 4,
    textTransform: 'uppercase',
    '& .MuiListItemText-root': {
      marginTop: 2,
      marginBottom: 2,

    },
    '& div span': {
      fontSize: '0.65rem',
      color: theme.palette.grey[600]
    },
    '& .feather': {
      width: '20px,',
      height: '20px,'
    }
  },
  apiListItemSecondary: {
    paddingLeft: '40px'
  },
  apiTitle: {
    '& .idMarker': {
      fontFamily: 'monospace',
      display: 'inline-block',
      fontSize: '1em',
      fontWeight: 200,
      '&:before': {
        content: `"${String.fromCharCode(60)}"`,
        display: 'inline-block'
      },
      '&:after': {
        content: `"${String.fromCharCode(62)}"`,
        display: 'inline-block'
      }
    }
  },
  apiContent: {
    '& pre': {
      whiteSpace: 'normal',
      margin: 0
    },
    '& table': {
      textAlign: 'left',
      '& th': {
        padding: '0.2em 0.4em'
      },
      '& td': {
        padding: '0.2em 0.4em'
      },
    },
  },
  apiCode: {
    '& pre code': {
      whiteSpace: 'normal',
      color: 'white',
      background: theme.palette.grey[700],
      lineHeight: 1.5,
      display: 'inline-block',
      padding: '1em',
    }
  },
  stickyNav: {
    'position': '-webkit-sticky',
    // eslint-disable-next-line no-dupe-keys
    'position': 'sticky',
    top: 80,
    zIndex: 5,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const Account = ({ computedMatch, history, state, api, ...rest }) => {
  
  const chAuth = useContext(AuthContext);

  const classes = useStyles();
  let { dispatch } = rest;
  let tabValue = 0;
  let [customerPaymentMethod, setCustomerPaymentmethod] = useState(null);
  let [currentCard, setCurrentCard] = useState(null);
  let [, setPlansWithPrices] = useState([]);
  let [docsMenu, setDocsMenu] = useState([]);
  // const gettingDocs = useRef(false);

  // let [showPrivate] = useState((state.plan.apiRateLimit > 0) ? true : false);
  let [docsPanel] = useState([]);

  let [showLoader, setShowLoader] = useState(false);
  let [session, setSession] = useState();
  let [user, setUser] = useState();
  const [openLoginAgain, setOpenLoginAgain] = useState(false);

  const matches = useMediaQuery('(min-width:1170px)');
  const gettingPrices = useRef(false);
  const gettingPaymentInfo = useRef(false);
  const getUsersError = useRef(false);
  const [sessionChecked, setSessionChecked] = useState(false)
  const [showLoggedOutPage, setShowLoggedOutPage] = useState(false);

  const {isOnline, checked} = useHasSession();
 

  

  useEffect(() => {

    if (!isOnline && checked) {
      console.log('SHOULD SHOW RELOAD PAGE');
      setShowLoggedOutPage(true);
    }

    if (isOnline && checked) {
      setSessionChecked(true)
    }

    if(checked) {
      setSessionChecked(true)
    }

  }, [checked, isOnline]);

  useEffect(() => {
    const getData = async () => {

      if (!session) {
        let res = await chAuth.currentSession();

        if (res && res.session) {
          const { jwtToken } = await chAuth.getJwtToken() ?? {};

          try {

            let results = await Promise.all([
              api.getStripeCustomer(jwtToken),
              api.getPlan()
            ]);

            dispatch({ type: 'SET_HAS_CUSTOM_PLAN', payload: results[0].custom_plan })
            dispatch({ type: 'SET_CURRENT_PLAN', payload: results[1] })

          } catch (error) {

          }
        }

      }



    }
    getData();
 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    const getData =  async () => {
      let rooms = await api.getRooms();
      dispatch({ type: 'UPDATE_ROOMS', payload: rooms });
    }

    if(!state.rooms) {
      getData();
    }
 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.rooms]);

  useEffect(() => {
    
    const getLatestInvoice = async () => {

      const { jwtToken } = await chAuth.getJwtToken() ?? {};

      let invoice = await api.getLatestInvoice(jwtToken);
      dispatch({ type: 'UPDATE_ACCOUNT_INFORMATION', payload: { latest_invoice: invoice } });
      if (invoice.collection_method && invoice.collection_method !== "send_invoice") {
        dispatch({ type: 'UPDATE_ACCOUNT_INFORMATION', payload: { latest_invoice_paid: invoice.paid } })
      } else {
        dispatch({ type: 'UPDATE_ACCOUNT_INFORMATION', payload: { latest_invoice_paid: true } })
      }
    }

    getLatestInvoice()
  },[api, chAuth, dispatch]);
  

  useEffect(() => {

    const getAPIKEY = async () => {
      const { idToken } = await chAuth.getIDToken() ?? {};

      if (idToken && idToken.payload.keyPrivate) {
        dispatch({type: 'SET_PRIVATE_KEY', payload: idToken.payload.keyPrivate});
      }

      if (idToken && idToken.payload.keyPublic) {
        dispatch({type: 'SET_PUBLIC_KEY', payload: idToken.payload.keyPublic});
      }

    }
  
    getAPIKEY();
   
  }, [chAuth, dispatch]);



  useEffect(() => {
    const getUsers = async () => {

      getUsersError.current = true;

      const { jwtToken } = await chAuth.getJwtToken() ?? {};
      const region = await chAuth.getRegion();
      
      let result = await api.getGroupUsers(state.accountInformation.get('customer').id, jwtToken, region);
      if (result) {
        dispatch({ type: 'UPDATE_ACCOUNT_INFORMATION', payload: { users: result } })
      } else {
        dispatch({ type: 'UPDATE_ACCOUNT_INFORMATION', payload: { users: state.accountInformation.get('user') } })
      }

    }
    if (!state.accountInformation.get('users').length && getUsersError.current === false) {
      getUsers();
    }
  }, [api, chAuth, dispatch, state.accountInformation]);


  useEffect(() => {

    const getPlans = async () => {

      if (gettingPrices.current) {
        setPlansWithPrices(sortBy(state.plans, 'price'));
        gettingPrices.current = false;
      }
    }

    if (state.plans.length < 1 && !gettingPrices.current) {
      getPlans();
    }

    return () => {
      // Clean up the subscription
      gettingPrices.current = false;
    };

  }, [api, state.plans]);


  useEffect(() => {
    const fetchData = async () => {
      gettingPaymentInfo.current = true;

      const { jwtToken } = await chAuth.getJwtToken() ?? {};

      try {

        const paymentMethodResponse = await api.retrieveCustomerPaymentMethod(
          state.accountInformation.get('customer').invoice_settings.default_payment_method,
          jwtToken
        );

        const paymentMethods = await api.retrieveCustomerPaymentMethods(jwtToken);

        if (gettingPaymentInfo.current) {
          dispatch({
            type: 'UPDATE_ACCOUNT_INFORMATION', payload: {
              cards: paymentMethods,
              paymentMethod: paymentMethodResponse,
            }
          });

          if (paymentMethodResponse) {
            setCurrentCard(paymentMethodResponse);
          } else if (paymentMethods) {
            setCurrentCard(paymentMethods[0]);
          }

          gettingPaymentInfo.current = false;
        }
      } catch (error) {

      }
    }

    fetchData();

    return () => {
      // Clean up the subscription
      gettingPaymentInfo.current = false;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (computedMatch.params.tabID) {
    switch (computedMatch.params.tabID) {
      case 'plan':
        tabValue = 2
        break;
      case 'upgrade':
        tabValue = 2
        break;
      case 'api':
        tabValue = 3
        break;
      case 'billing':
        tabValue = 1
        break;
      case 'security':
        tabValue = 4
        break;
      default:
        tabValue = 0
        break;
    }
  }
  const [value, setValue] = React.useState(tabValue);


  const handleChange = (event, newValue) => {


    setValue(newValue);
  };

  useEffect(() => {
    const navigate = () => {

      let tabValue;

      switch (computedMatch.params.tabID) {
        case 'plan':
          tabValue = 2
          break;
        case 'upgrade':
          tabValue = 2
          break;
        case 'api':
          tabValue = 3
          break;
        case 'billing':
          tabValue = 1
          break;
        case 'security':
          tabValue = 4
          break;
        default:
          tabValue = 0
          break;
      }

      setValue(tabValue)

    }

    navigate();
   
  }, [computedMatch.params.tabID]);

  const getInvoicePreview = async (newPriceId, currentPriceId) => {

    setShowLoader(true);

    const { jwtToken } = await chAuth.getJwtToken() ?? {};
    
      let upcomingInvoice = await api.retrieveUpcomingInvoicePreview({
        customerId: state.accountInformation.get('customer').id,
        subscriptionId: state.accountInformation.get('subscription'),
        newPriceId: newPriceId,
        currentPriceId: currentPriceId,
      }, jwtToken);
  
      dispatch({
        type: 'UPDATE_ACCOUNT_INFORMATION', payload: {
          invoice_preview: upcomingInvoice,
        }
      });
  
      setShowLoader(false);
  
  
      return { upcomingInvoice };
    

  }

 

  return (
    <>
    {sessionChecked ?
    <Grid container justifyContent="flex-start">
      <Grid container
        direction="row"
        justifyContent="flex-start"
        alignItems="stretch"
        >
        <Grid item xs={(matches) ? 2 : 12}>
          {matches
          ?
          <Tabs
          orientation="vertical"
         
            variant="scrollable"
            value={value}
            onChange={handleChange}
            className={classes.tabs}
          >
            <Tab wrapped label="Account Details" {...a11yProps(0)} 
            
                  component="a"
                  onClick={(event) => {
                    event.preventDefault();
                    history.push('/account');
                  }}
                  
                  />


              {state.accountInformation.get('user').attributes['custom:role'] === '1' ?
                <Tab wrapped label="Billing" {...a11yProps(1)}

                  component="a"
                  onClick={(event) => {
                    event.preventDefault();
                    history.push('/account/billing');
                  }}

                />
                : null
              }
            <Tab wrapped label="Plan" {...a11yProps(2)}
            
                component="a"
                  onClick={(event) => {
                    event.preventDefault();
                    history.push('/account/plan');
                  }}
                  />
            <Tab wrapped label="API" {...a11yProps(3)}
            
            component="a"
                  onClick={(event) => {
                    event.preventDefault();
                    history.push('/account/api');
                  }}
                  
                  />
                   {state.plan.allowMFA === 1 || state.accountInformation.get('user').mfa_prefs === 'SOFTWARE_TOKEN_MFA'
                  ?
                  <Tab wrapped label="Security" {...a11yProps(4)} component="a"
                  onClick={(event) => {
                    event.preventDefault();
                    history.push('/account/security');
                  }}/>
                  :null}
          </Tabs>
          :
          <Tabs
            orientation="horizontal"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            className={classes.tabsHorizontal}
          >
            <Tab wrapped label="Account Details" {...a11yProps(0)}
            onClick={(event) => {
              event.preventDefault();
              history.push('/account');
            }}/>
            <Tab wrapped label="Billing" {...a11yProps(1)}
            onClick={(event) => {
              event.preventDefault();
              history.push('/account/billing');
            }}/>
            <Tab wrapped label="Plan" {...a11yProps(2)}
            onClick={(event) => {
              event.preventDefault();
              history.push('/account/plan');
            }}/>
            <Tab wrapped label="API" {...a11yProps(3)}
            onClick={(event) => {
              event.preventDefault();
              history.push('/account/api');
            }}/>
            {state.plan.allowMFA === 1 || state.accountInformation.get('user').mfa_prefs === 'SOFTWARE_TOKEN_MFA'
            ?
            <Tab wrapped label="Security" {...a11yProps(4)}
            onClick={(event) => {
              event.preventDefault();
              history.push('/account/security');
            }}/>
            : null}
          </Tabs>
          }

          {(value === 3) ?
            <List component="nav" className={classes.stickyNav}>

              {state.docs
                ?
                state.docs.map((element, index) => {
                  return (
                    <ApiNav element={element} key={element.root} />
                  )
                })
                :
                null}

            </List>
            : null}



        </Grid>
        <Grid item xs={(matches) ? 10 : 12}>
          {/* <Grid item xs={11}> */}
          <TabPanel className={classes.tabPanel} value={value} index={0}>
            {state.accountInformation
              ?
              <AccountDetails
                state={state}
                api={api}
                dispatch={dispatch}
                accountInformation={state.accountInformation}
                customerPaymentMethod={customerPaymentMethod}
                setShowLoader={setShowLoader}
                setValue={setValue}
                rest={rest}
              />
              :
              null
            }
            
          </TabPanel>

          <TabPanel className={classes.tabPanel} value={value} index={1}>
            <AccountBilling
              dispatch={dispatch}
              currentCard={currentCard}
              setCurrentCard={setCurrentCard}
              setCustomerPaymentmethod={setCustomerPaymentmethod}
              state={state}
              api={api}
              accountInformation={state.accountInformation}
              customerPaymentMethod={customerPaymentMethod}
              setShowLoader={setShowLoader}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <ErrorBoundary fallback={<Grid><Typography variant="h5">There was an error</Typography></Grid>}>
              <AccountPlans
                setShowLoader={setShowLoader}
                dispatch={dispatch}
                currentCard={currentCard}
                api={api}
                state={state}
                getInvoicePreview={getInvoicePreview} />
            </ErrorBoundary>
          </TabPanel>
          <TabPanel value={value} index={3}>

            <>
              <AccountAPI
                state={state}
                api={api}
                dispatch={dispatch}
                setDocsMenu={setDocsMenu}
                docsMenu={docsMenu}
                docsPanel={docsPanel}

              />



            </>
          </TabPanel>
          {state.plan.allowMFA === 1 || state.accountInformation.get('user').mfa_prefs === 'SOFTWARE_TOKEN_MFA'
          ?
          <TabPanel value={value} index={4}>
            <>
              <AccountSecurity
                state={state}
                api={api}
                dispatch={dispatch}
                setShowLoader={setShowLoader}

              />
            </>
          </TabPanel>
          :null
          }
          {/* </Grid> */}

          <Backdrop className={classes.backdrop} open={showLoader}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </Grid>
      </Grid>
    <ReloadPanel open={showLoggedOutPage}/>
    </Grid>
    : null}
    
    </>
    
  );
};

export default withRouter(Account);


const ApiNav = ({ element }) => {

  let [open, setOpen] = useState(false);
  const classes = useStyles();


  return (
    <>
      <ListItem button className={classes.apiListItem} key={element.root} onClick={() => { setOpen(!open) }}>
        <ListItemText variant="subtitle2" primary={element.root} />
        {open ? <ChevronUpIcon /> : <ChevronDownIcon />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>

        {element.groups.map((path, index) => {

          let href = path.groups[0].id


          return (
            <ListItem button key={path.root} className={clsx({
              [classes.apiListItem]: true,
              [classes.apiListItemSecondary]: true,
            })} component={HashLink} to={`#${href}`}>
              <ListItemText primary={path.root}>
              </ListItemText>
            </ListItem>
          )
        })}
      </Collapse>
    </>
  )

}




function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

