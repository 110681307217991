import React, {useMemo} from "react";
import {
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    Button,
    Grid
} from "@material-ui/core";

const SessionAgentDialog = ({handleClose, openAgentDialog, agentData}) => {

    const titleMapping = {
      browserData: {
        'name' : 'Name',
        'version' : 'Version',
        'version_major' : 'Major Version',
        'engine' : 'Engine',
      },
      osData: {
        'name' : 'Name',
        'family' : 'Family',
        'family_vendor' : 'Vendor',
      },
      device: {
        'type' : 'Type',
        'brand' : 'Brand',
        'name' : 'Name',
      }
  
    }
  
    const agentString = useMemo(() => {
  
        if(agentData && agentData.ua) {
          return <Typography >{`${agentData.ua}`}</Typography>
        }
  
        return null;
  
    },[agentData]);
  
    const browserData = useMemo(() => {
  
        let data = [];
        if(agentData && agentData.browser) {
          let index = 0;
            for (const key in agentData.browser) {
                if (Object.hasOwnProperty.call(agentData.browser, key)) {
                    const element = agentData.browser[key];
                    if(element && titleMapping.browserData[key]){
                        data.push(<Typography key={`${key}_${index}`}>{`${titleMapping.browserData[key]}: ${element}`}</Typography>);
                        index++;
                    }
                }
            }
        }
  
        return data;
  
    },[agentData, titleMapping.browserData]);
    
    const osData = useMemo(() => {
  
        if(agentData && agentData.os) {
            let data = [];
  
            if (!agentData.os.code) {
                return null;
            }
            let index = 0;
            for (const key in agentData.os) {
                if (Object.hasOwnProperty.call(agentData.os, key)) {
                    const element = agentData.os[key];
                    if(element && titleMapping.osData[key]){
                      data.push(<Typography key={index}>{`${titleMapping.osData[key]}: ${element}`}</Typography>);
                      index++;
                    }
                }
            }
            return data;
        }
        return null;
  
    },[agentData, titleMapping.osData]);
  
    const device = useMemo(() => {
  
        if(agentData && agentData.device) {
            let data = [];
  
            if (!agentData.device.brand_code) {
                return null;
            }
            let index = 0;
            for (const key in agentData.device) {
                if (Object.hasOwnProperty.call(agentData.device, key)) {
                    const element = agentData.device[key];
                    if(element && titleMapping.device[key]){
                        data.push(<Typography key={index}>{`${titleMapping.device[key]}: ${element}`}</Typography>);
                        index++;
                    }
                }
            }
            return data;
        }
        return null;
  
    },[agentData, titleMapping.device]);
  
    return (
        <Dialog
        // fullScreen={true}
        maxWidth="lg"
        aria-labelledby="confirmation-dialog-title"
        open={openAgentDialog}
        onClose={handleClose}
  
      >
  
        <DialogTitle id="confirmation-dialog-title">
          <Grid container justifyContent="space-between" alignContent="center" spacing={2}>
            <Grid item>
              <Typography variant="h4">Agent</Typography>
            </Grid>
            <Grid item>
              <Button autoFocus onClick={handleClose} color="primary">
                Close
              </Button>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
  
         
          <Grid container style={{marginBottom: '12px'}}>
  
            {agentString ?
            <Grid item xs={12} style={{
              marginBottom: '16px'
            }}>
                {agentString}
            </Grid>
            : null}
  
            {browserData && browserData.length ?
            <Grid item xs={12} style={{
              marginBottom: '16px'
            }}>
                <Typography variant='h6'>Browser</Typography>
                {browserData}
            </Grid>
            : null}
  
            {osData && osData.length ?
            <Grid item xs={12} style={{
              marginBottom: '16px'
            }}>
                <Typography variant='h6'>OS</Typography>
                {osData}
            </Grid>
            : null}
  
            {device && device.length ?
            <Grid item xs={12} style={{
              marginBottom: '16px'
            }}>
                <Typography variant='h6'>Device</Typography>
                {device}
            </Grid>
            : null}
          </Grid>
         
  
        </DialogContent>
      </Dialog>
    )
  
  
  }

  export default SessionAgentDialog;