import React, { useState, useEffect, useRef } from "react";
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { makeStyles } from '@material-ui/core/styles';
import { PAGroupsTable, ErrorSnackBar } from '../../components';
import {generateSingleCode} from '../../lib'

import { Container, Grid, Button } from '@material-ui/core';
import { UpgradeCTAPanel } from '../../components';

import sortBy from 'lodash/sortBy';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(6),
  },
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  buttonHeader: {
    padding: theme.spacing(0),
    marginBottom: theme.spacing(2),
  },

}));

const QueueJump = ({api, state, ...rest}) => {

  const {dispatch} = rest;

  useEffect(() => {
    dispatch({ type: 'UPDATE_BACKGROUND', payload: {bg:'bg.jpg'} });
  }, [dispatch]);

  const isMountedRef = useRef(null);
  // used to control show all / close all functionality of panels
  let [expanded, setExpanded] = useState(false);
  // store the rooms
  let [rooms, setRooms] = useState([]);

  //control the snackbar state
  // use to tell the user the result of their action
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  let [alertBarStatus, setAlertBarStatus] = useState('success');
  const [message, setMessage] = useState('');

  const [canUsePA, setCanUsePA] = useState(false);

  const classes = useStyles();

  const roomsSet = useRef(false);
  const isMounted = useRef(false);

  useEffect(  ()=>{
    isMounted.current = true;
    const getRooms = async () => {
      let rooms = await api.getRooms();
      if ( isMounted.current) {
        dispatch({ type: 'UPDATE_ROOMS', payload: rooms });
      }
      if ( isMounted.current && rooms) {
        let new_rooms = rooms.map((room) => {
          room.open = false;
          return room;
        })
        new_rooms = sortBy(new_rooms, 'domainID');
        //sortBy
        setRooms(new_rooms);
        roomsSet.current = true;
      }

    }
    getRooms();

    return () => {
      isMounted.current = false;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);


  useEffect(  ()=>{
    const canUsePriorityAccess = async () => {
      if (parseInt(state.plan.allowPriorityAccess, 10) === 1) {
        setCanUsePA(true);
      } else {
        setCanUsePA(false);
      }
    }
    if(state.plan) {
      canUsePriorityAccess();
    }
  },[state.plan]);

  // set the initial state of the rooms based on the rooms
  // that are sent as props in props.state
  // Updates when state.rooms changes
  useEffect(() => {
   
    const setinitialRooms = async () => {

      // try {
      //   setRooms(state.rooms.map((room) => {
      //     room.open = false;
      //     return room;
      //   }));

      //   roomsSet.current = true;
      // } catch (error) {
      //   console.warn('Rooms not set');
      //   roomsSet.current = true;
      // }
    }

    if(state.rooms && !roomsSet.current){
      setinitialRooms();
    }
    
  }, [state.rooms]);


  useEffect(() => {
    isMountedRef.current = true;
    const groups = async() => {
      let response = await api.getGroups();
      
      if(isMountedRef.current){
        dispatch({type:'SET_PRIORITY_ACCESS_GROUPS', payload: response})
      }
    }
    groups();
    
    return () => {
      isMountedRef.current = false;
    }
   
  }, [api, dispatch]);

  /**
   * Handler to close all of the room panels
   */
  const closeAll = () => {
    let newRooms = rooms.map((room) => {
      room.open = false;
      return room;
    })
    setExpanded(false);
    setRooms(newRooms);
  }
  const openAll = () => {
    let newRooms = rooms.map((room) => {
      room.open = true;
      return room;
    })
    setExpanded(true);
    setRooms(newRooms);
  }


  // Grouping all groups by their roomID
  // Called whenever an api call is made that returns a new list of groups
  const updateGroups = (response) => {
    // let rawgroups = groupBy(response, 'roomID');
    // setGroups(rawgroups);
  }

  

  /**
   * Adds a group using POST
   * Passed as a prop to `<PAGroupsTable/>`
   * @param {Object} group The group data 
   */

  const addGroup = async (group) => {

    let groups = state.paGroups.get(group.roomID);
  
    if (!groups) {
      group.priority = 1
    } else {
      group.priority = groups.length + 1;
    }

    if (group.type === 'multi-use') {
      group.code = generateSingleCode();
    }
    let response = await api.postGroups(group);
    if(isMountedRef.current && response){

      if (response.error) {
        setMessage('There was a problem adding your Priority Group');
        setAlertBarStatus('error');
        setOpenErrorSnackbar(true);
      } else {
        dispatch({ type: 'SET_PRIORITY_ACCESS_GROUPS', payload: response })
        setMessage('Priority Group Added');
        setAlertBarStatus('success');
        setOpenErrorSnackbar(true);
      }
      return true;
    } else {
      setMessage('There was a problem adding your Priority Group');
      setAlertBarStatus('error');
      setOpenErrorSnackbar(true);
      return false;
    }
  }

  /**
   * Adds a group using POST
   * Passed as a prop to `<PAGroupsTable/>`
   * @param {Object} group The group data 
   */
  const deleteGroup = async(group) => {

    let response = await api.deleteGroup(group.id);

    
    if(isMountedRef.current){

      if (response.error) {
        setMessage('One or more live sessions are using this priority group, please try again later');
        setAlertBarStatus('error');
        setOpenErrorSnackbar(true);
      } else {
        setMessage('Priority Group Deleted');
        setAlertBarStatus('success');
        setOpenErrorSnackbar(true);
        dispatch({ type: 'SET_PRIORITY_ACCESS_GROUPS', payload: response })

      }


    }
  }

  const closeErrorSnackbar = () => {
    setOpenErrorSnackbar(false)
}


  return (
    canUsePA
    ?
    <DndProvider backend={HTML5Backend}>
    <div>
      <Container maxWidth="lg" spacing={4} >
        <Grid container className={classes.buttonHeader}>
          <Grid item xs={12} align="right">
            
              {(expanded) ?
              <Button onClick={()=>{closeAll()}}>Close All</Button>
              :
              <Button onClick={()=>{openAll()}}>Open All</Button>
              }
          </Grid>
        </Grid>
        {
          rooms.map((room) => {
            return (
              <PAGroupsTable
                key={room.id}
                deleteGroup={deleteGroup}
                addGroup={addGroup}
                updateGroups={updateGroups}
                api={api}
                state={state}
                room={room}
                groups={(state.paGroups.get(room.id)) ? state.paGroups.get(room.id) : []}
                name={room.title}
                dispatch={dispatch}
                setSnackbarMessage={setMessage}
                setSnackbarOpen={setOpenErrorSnackbar}
                setAlertBarStatus={setAlertBarStatus}
                />
            )
          })
        }
        
        <ErrorSnackBar message={message} open={openErrorSnackbar} closeErrorSnackbar={closeErrorSnackbar} status={alertBarStatus}/>


      </Container>
    </div>
    </DndProvider>
    :
    <UpgradeCTAPanel upgradeMessage="Upgrade to use Priority Access Codes" displayUpgradeButton={true} />

  
  );
};

export default QueueJump;