import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import MenuIcon from '../../icons/MenuIcon';

import { Grid, IconButton, Typography } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(1),
        [theme.breakpoints.up('lg')]: {
            padding: theme.spacing(2),
        },
        backgroundColor: theme.palette.background.paper,
        position: "relative",
        [theme.breakpoints.up('lg')]: {
            position: 'fixed',
        },
        zIndex: 1010
    },
    menuButton: {
        margin: theme.spacing(0),
        [theme.breakpoints.up('lg')]: {
            display: 'none'
        },
      },
    margin: {
        margin: theme.spacing(0),
        marginLeft: 'auto',
        '& .feather': {
            marginLeft: '10px'
        }
    },
    formControl: {
        marginLeft: 20
    },
    headerPaper: {
        width: '100%',
        borderRadius: 0,
        padding: theme.spacing(2),
        marginBottom: '20px'
    },
    
}));


const AdminHeader = ({setOpenSidebar, openSidebar, state}) => {

    
    const classes = useStyles();
  
    return (

        (state.ready) ?

            <Grid
            className={classes.root}
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
            >
                <Grid item>
                <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={()=>{
                    setOpenSidebar(!openSidebar);
                }}><MenuIcon/></IconButton>
                </Grid>
                
                <Grid item className={classes.chLogo}>
                    <RouterLink to="/"><img src="/crowdhandler-logo--strip-blue.svg" alt="CrowdHandler" /></RouterLink>
                </Grid>
                {state.globalMessage
                ?
                <Grid item>
                    <Grid container className="message">
                        <Grid item>
                            <Typography variant="body2">
                            {state.globalMessage}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                :
                null
                }
      
            
            
        </Grid>
            
            : null

        
    )
}
export default AdminHeader;