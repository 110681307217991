import React from "react";
import {
    Typography,
    Container,
    Grid,
    Paper,
    Card,
    CardContent,
    ListItemIcon,
    Button

} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DashboardIcon, DomainIcon, RoomsIcon, UsersIcon, TemplatesIcon, AwardIcon } from '../../icons';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    someLinks: {
      marginBottom: theme.spacing(2)
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'left',
      color: theme.palette.text.secondary,
    },
    paperMessage: {
      padding: theme.spacing(6),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  }));

export default function NotFound() {
    const classes = useStyles();
    const navData = [
        { id: 0, label: 'Dashboard', path: '/', icon: <DashboardIcon /> },
        { id: 20, label: 'Waiting Rooms', path: '/rooms', icon: <RoomsIcon /> },
        { id: 10, label: 'Domains', path: '/domains', icon: <DomainIcon /> },
        { id: 30, label: 'Templates', path: '/templates', icon: <TemplatesIcon /> },
        { id: 40, label: 'Priority Access', path: '/priority-access', icon: <AwardIcon /> },
        { id: 50, label: 'Account', path: '/account', icon: <UsersIcon /> },
    ]

    return (

       

<Container
maxWidth="sm"
>
<Grid 
  container
  direction="column"
  spacing={3}
>

        <Grid item xs={12}>
          <Paper className={classes.paperMessage}>
          <Typography align="center" component="h2" variant="h2">Sorry, that page doesn’t exist!</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Card className={classes.paper}>
              <CardContent>

          <Grid container direction="column" >
              <Typography className={classes.someLinks}>Here are some useful links instead.</Typography>
                            {navData.map((item) => {
                                return <Grid  key={item.id}>
                                <Button
                                    disableRipple={true}
                                    component={RouterLink} to={item.path} color="primary">
                                    <ListItemIcon>
                                        {item.icon}
                                    </ListItemIcon>
                                    <Typography>{item.label}</Typography>
                                </Button>
                                    </Grid>
                            })}

                        </Grid>
              </CardContent>
          </Card>
        </Grid>
        </Grid>
       
      </Container>

                
          
    );
};