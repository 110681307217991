
import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { PlusCircleIcon, PaperClipIcon, MinusCircleIcon } from '../icons';
import Config from '../config';
import * as AWS from 'aws-sdk/global';
import { FetchHttpHandler } from "@smithy/fetch-http-handler";
import { PutObjectCommand, S3Client } from "@aws-sdk/client-s3";

import {
    Grid,
    Card,
    CardContent,
    Button,
    Typography,
    FormControl,
    FormHelperText,
    CardHeader,
    ButtonGroup,
    Backdrop,
    CircularProgress
} from '@material-ui/core';
import { AuthContext } from '../providers/AuthProvider';

import { grey } from '@material-ui/core/colors';


const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
      },
}));


const AddLogoTable = ({ api, state, dispatch, allowCustomTemplates }) => {
    const classes = useStyles();
    const chAuth = useContext(AuthContext);

    let [file, setFile] = useState();
    let [fileTooBig, setFileTooBig] = useState(false);
    let [showLoader, setShowLoader] = useState(false);

    const uploadLogo = async (event) => {

        let Key = `public/${state.accountInformation.get('customer').id}/${file.name}`;
        setShowLoader(true)

        const { session } = await chAuth.handleCurrentSession();
        if (session && session.isValid()) {
            AWS.config.credentials.get(error => {
                if (error) {
                    console.error(error);
                    setShowLoader(false)
                } else {
                    // Instantiate aws sdk service objects now that the credentials have been updated.

                    const AccessKeyId = AWS.config.credentials.data.Credentials.AccessKeyId,
                        SecretKey = AWS.config.credentials.data.Credentials.SecretKey,
                        SessionToken = AWS.config.credentials.data.Credentials.SessionToken;

                    const creds = {
                        accessKeyId: AccessKeyId,
                        secretAccessKey: SecretKey,
                        sessionToken: SessionToken
                    };

                    const s3Client = new S3Client({
                        region: 'us-east-1',
                        credentials: creds,
                        requestHandler: new FetchHttpHandler({ keepAlive: false }),
                    });

                    const callPut = async () => {
                        const command = new PutObjectCommand({
                            Bucket: Config.AMPLIFY.Storage.S3.bucket,
                            Key,
                            Body: file,
                        });

                        try {
                            const response = await s3Client.send(command);

                            if (response && response.$metadata.requestId) {

                                let data = {
                                    logoURL: `https://${Config.AMPLIFY.Storage.S3.bucket}.s3.amazonaws.com/${Key}`,
                                }
                                const updateLogoData = async (data) => {
                                    let response = await api.updateAccount(data);
                                    if (response) {
                                        dispatch({ type: 'UPDATE_ACCOUNT_INFORMATION', payload: { account: response } });
                                    }
                                }
                                updateLogoData(data);
                                clearInputFile(document.getElementById('logo-upload'))
                                setFile(undefined);
                                setShowLoader(false)
                            }
                        } catch (error) {
                            console.log(error);
                            setShowLoader(false)
                        }
                    }

                    callPut();


                }
            });
        }

    }
    const maxAllowedSize = 500000; // 500kb

    const handleFileChange = async (file) => {
        setFileTooBig(false)
        /*Maximum allowed size in bytes
        500kb Example
        */        
        if (file && file.size > maxAllowedSize) {
            // Here you can ask your users to load correct file
            file = undefined;
            setFileTooBig(true)
        }

        setFile(file);
    }

    const removeLogo = async () => {
        setShowLoader(true)
        let response = await api.updateAccount({
            'logoURL': ''
        });

        
        clearInputFile(document.getElementById('logo-upload'))
        setFile(undefined);

        if (response) {
            dispatch({ type: 'UPDATE_ACCOUNT_INFORMATION', payload: { account: response } });
        }

        setShowLoader(false)

    }


    const clearInputFile = (f) => {
        if(f.value){
            try{
                f.value = ''; //for IE11, latest Chrome/Firefox/Opera...
            }catch(err){ }
            if(f.value){ //for IE5 ~ IE10
                var form = document.createElement('form'),
                    parentNode = f.parentNode, ref = f.nextSibling;
                form.appendChild(f);
                form.reset();
                parentNode.insertBefore(f,ref);
            }
        }
    }


    return (

        <Card className={classes.paper} >
            <CardHeader title="Logo"></CardHeader>

            <CardContent>
                <Grid container spacing={4} alignItems='flex-start' justifyContent='flex-start'>
                    
                    {/* logo panel */}
                    <Grid item  xs={12} md={3} lg={3}>

                    {state.accountInformation.get('account').logoURL ?
                        <Grid item xs={12} style={{ margin: "0 auto 12px", padding: 12, borderColor: grey[300] , borderWidth: 1, borderStyle: 'solid' }}>
                            <Grid justifyContent='center' alignItems='center' container >
                                <Grid item >
                                <img src={state.accountInformation.get('account').logoURL} alt="Logo used for account" style={{maxWidth:'300px'}} width="100%" />
                                </Grid>
                            </Grid>
                        </Grid>
                        :
                        <Grid item xs={12}>
                                <img width="100%" src="https://wait.crowdhandler.com/img/crowdhandler-logo--strip.svg" alt="Logo used for account" />
                        </Grid>
                        }

                        {state.accountInformation.get('account').logoURL ?
                        <Grid item>
                            <Button component="span" disableElevation size="small" variant="contained" color="secondary"
                                onClick={(event) => removeLogo(event)}
                                endIcon={<MinusCircleIcon />}
                            >
                                Remove
                            </Button>
                        </Grid>
                        :
                        null
                        }


                    </Grid>
                    {/* buttons */}
                    <Grid item  xs={12} md="auto">
                    <Grid container spacing={1} direction="column">
                                    <Grid item xs={12}>
                                        <FormControl fullWidth >
                                            <label htmlFor="logo-upload">
                                                <Button fullWidth component="span" disableElevation size="small" variant="contained" color="primary"
                                                    endIcon={<PaperClipIcon />}
                                                >
                                                    Select Logo
                                                </Button>
                                            </label>

                                            <input accept="image/gif, image/jpg, image/png"
                                                id='logo-upload'
                                                type="file"
                                                onChange={(event) => {
                                                    handleFileChange(event.currentTarget.files[0])
                                                }}
                                                name="newTemplate"
                                                style={{ 'display': 'none' }}
                                            ></input>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                  
                                    <ButtonGroup variant="contained" size="small" disableElevation color="secondary" fullWidth>

                                        <Button component="span" 
                                            disabled={!file}
                                            fullWidth
                                            onClick={(event) => uploadLogo(event)}
                                            endIcon={<PlusCircleIcon />}
                                            >
                                            Add
                                        </Button>
                                        <Button component="span"   
                                            color="default"
                                            disabled={!file}
                                            fullWidth
                                            onClick={(event) => {
                                                clearInputFile(document.getElementById('logo-upload'))
                                                setFile(undefined)
                                            }}
                                            >
                                            Cancel
                                        </Button>
                                    </ButtonGroup>
                                            {fileTooBig
                                                ?
                                                <FormHelperText color="error">The file selected is too large</FormHelperText>
                                                :
                                               null
                                            }
                                    </Grid>
                                    
                                </Grid>
                    </Grid>
                    {/* instructions */}
                    <Grid item>
                    <Grid container spacing={2}>
                                <Grid item>
                                    <Grid container justifyContent='space-between' spacing={2}>
                                        <Grid item>
                                        {file ? <Typography>Click ADD to use <strong>{file.name}</strong>.</Typography> : null}
                                        <Typography paragraph>{!file ? `Add your logo.` : null} It will replace the default CrowdHandler logo at the top of the template.</Typography>
                                        <Typography>Suggested size <strong>300</strong> &#215; <strong>300</strong> pixels</Typography>
                                        <Typography>Maximum file size: {maxAllowedSize / 1000}KB</Typography>
                                        {allowCustomTemplates
                                            ?
                                            <Typography>Click the eye icon next to a template below to preview the logo</Typography>
                                            :
                                            <Typography>Click the eye icon next to the Standard Template below to preview the logo</Typography>
                                        }

                                        </Grid>
                                    </Grid>
                                </Grid>
                                
                            </Grid>
                    </Grid>

                </Grid>
            </CardContent>


            <Backdrop className={classes.backdrop} open={showLoader}>
                <CircularProgress color="inherit" />
            </Backdrop>
            
        </Card>

    )
}

export default AddLogoTable;