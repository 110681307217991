import React, { useState, useEffect, useRef } from "react";
import Chart from 'chart.js/auto'
import red from '@material-ui/core/colors/red';
import amber from '@material-ui/core/colors/amber';
import green from '@material-ui/core/colors/green';
import lime from '@material-ui/core/colors/lime';
import forEach from 'lodash/forEach';
import isArray from 'lodash/isArray';
import { Typography } from '@material-ui/core';

import {generateColorSteps} from '../../lib';

Chart.defaults.font.family = `'Lato',sans-serif`;

let config = {
    type: 'doughnut',
    data: {
        datasets: [{
            data: [],
            backgroundColor: [],
            label: 'Response Times'
        }],
        labels: []
    },
    chart: {

    },
    options: {
        responsive: true,
        borderWidth: 0,
        plugins : {
            title: {
                display: true,
                text: ["RESPONSE TIMES"],
                position: 'bottom',
                fontColor: "black"
            },
            legend: {
                labels: {
                    boxWidth: 10,
                    usePointStyle: true,
                    doughnutMode: true,
                },
                display: false,
                position: 'right',
            },
            tooltip: {
                boxPadding: 5,
                callbacks: {
                    title: () => {
                        return false;
                    },
                    label(context) {
                      
                        if (context.dataIndex > -1) {
                            var count = 0;
                            for (var i = context.dataset.data.length; i--;) {
                                count += context.dataset.data[i];
                            }
                            let percentage = ((context.raw/count) * 100).toFixed(2);
                           
                            return `${context.label}: ${context.raw} (${percentage}%)`;
                        }
                    }

                }
            },
        },
        
        animation: {
            animateScale: false,
            animateRotate: true,
        }
    }
};


const DashboardResponseDonut = ({currentDomainId, api, currentDomain, setHasRedSegments}) => {
    
    const [dataForResponse, setDataForResponse] = useState(null);
    const [hasRequestData, setHasRequestData] = useState(null);
    const [errorGettingData, setErrorGettingData] = useState(false);
    const isMountedRef = useRef(true);

    let chart = useRef(null);

    // useEffect(() => {

    //     const updateChartOnVisibility = () => {
    //         if(document.visibilityState === 'visible' && chart.current) {
    //             chart.current.update();
    //         }
    //     }
    //     document.addEventListener("visibilitychange", updateChartOnVisibility);
        
    //     return () => {
    //         document.removeEventListener("visibilitychange", updateChartOnVisibility);
    //     }

    // }, []);

    // useEffect(()=>{
    //     let element = document.getElementById('donut-chart-area');
    //     if(chart.current) {
    //         chart.current.destroy();
    //     }
    //     if (element) {
    //         let ctx = element.getContext('2d');
    //         if (ctx) {
    //             chart.current = new Chart(ctx, config);
    //         }
    //     }

    // },[]);

    useEffect(() => {

        isMountedRef.current = true;
        const getRequestData = async () => {
            let result = undefined;
            
            if (currentDomainId) {
                try {
                    result = await api.getRequests(currentDomainId);
                    if (result.error) {
                        setHasRequestData(false);
                        return;
                    }
                } catch (error) {
                    setErrorGettingData(true);
                }

                if (isArray(result)) {
                    if (result.length > 0) {
                        if(isMountedRef.current){
                            setDataForResponse(result);
                            setHasRequestData(true);
                        } 
                    } else {
                        if(isMountedRef.current){
                            setHasRequestData(false);
                            setDataForResponse(null);
                        }
                    }
                } else if (isMountedRef.current) {
                    setHasRequestData(false);
                    setDataForResponse(null);
                }
            }
        };

        if(currentDomainId) {

            const getInitialData = async () => {
                await getRequestData();
            }

            getInitialData();
        }
        
        let intervalsID = setInterval( async() => {
            if (currentDomainId) {
                await getRequestData();
            }
        }, 60000);

        return () => {
            // Clean up the subscription
            clearInterval(intervalsID);
            isMountedRef.current = false;
        };

    }, [api, currentDomainId]);

    useEffect(()=>{

        const formatDataForDonut = () => {

            let threshold = currentDomain.slowRequestThreshold;

            if (threshold < 1) {
                threshold = 1;
            }

            if (dataForResponse){

                let newData = dataForResponse.reduce((acc, datum) => {

                    let requestValue = parseInt(datum.requests, 10);

                    if ( parseInt(datum.seconds, 10) > threshold ) {
                        requestValue = requestValue * 2.5;
                    }
                    acc.push(requestValue);
                    return acc;
                }, [])
    
                let thresholdInSeconds = threshold/1000;
                let greenSegments = [];
                let yellowSegments = [];
                let redSegments = [];

               
    
                forEach(dataForResponse, (datum)=>{
                    let seconds = parseInt(datum.seconds, 10);

                    let segment = {
                        requests: parseInt(datum.requests, 10),
                        seconds: seconds
                    }

                    if (seconds > thresholdInSeconds) {
                        redSegments.push(segment);
                    } else if (seconds > (thresholdInSeconds/1.5)) {
                        yellowSegments.push(segment)
                    } else {
                        greenSegments.push(segment)
                    }
    
                });
    
                let greenSteps = greenSegments.length;
                let colors = generateColorSteps(green[500], lime[500], greenSteps);
                
                let yellowSteps = yellowSegments.length;
                colors = [].concat(colors, generateColorSteps(lime[500], amber[900], yellowSteps));
    
                let redSteps = redSegments.length;
                colors = [].concat(colors, generateColorSteps(red[500], red[800], redSteps));

                let segmentColors = colors.map((color)=>{
                    return `#${color}`;
                });
    
                colors = colors.map((color)=>{
                    return `#${color}`;
                });
                
                let newLabels = dataForResponse.reduce((acc, datum) => {
                    acc.push(`${datum.seconds}s`);
                    return acc;
                }, []);

                if (redSteps > 0) {
                    setHasRedSegments(true);
                } else {
                    setHasRedSegments(false);
                }

                const datasets = [{
                    data: newData,
                    backgroundColor: segmentColors,
                    borderWidth: 0,
                    borderColor: segmentColors,
                    pointStyle: 'rectRot',
                    pointRadius: 5,
                }];

                if (chart && chart.current) {
                    chart.current.data.datasets = datasets;
                    chart.current.data.labels = newLabels;
                    chart.current.update();

                } else {
                    let element = document.getElementById('donut-chart-area');
                    if(chart.current) {
                        chart.current.destroy();
                    }
                    if (element) {
                        let ctx = element.getContext('2d');
                        if (ctx) {
                            config.data.datasets = datasets;
                            config.data.labels = newLabels;
                            chart.current = new Chart(ctx, config);
                        }
                    }
                }
            }
        }

        if (dataForResponse && hasRequestData) {
            formatDataForDonut();
        }
        
    },[dataForResponse, chart, hasRequestData, currentDomain.slowRequestThreshold, setHasRedSegments]);
    
    return (
        
        
        errorGettingData
        ?
            <Typography variant="h6" color="primary">Error getting response data</Typography>
        :
        
               
                    <canvas id="donut-chart-area" width="300" height="300" style={{
                        'display': 'block',
                        'boxSizing': 'border-box'
                    }}></canvas>
                
       
        
    )

}


export default DashboardResponseDonut;

