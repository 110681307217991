import React, { useEffect, useState, useRef, useContext } from "react";
import {
    Grid,
    Dialog,
    DialogContent,
    Button,
    Typography,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Card,
    CardContent,
    Chip,
    LinearProgress
} from "@material-ui/core";
import Skeleton from '@material-ui/lab/Skeleton';
import PropTypes from 'prop-types';
import AccountCards from './AccountCards';
import ErrorSnackBar from '../ErrorSnackBar.js';
import ErrorBoundary from '../ErrorBoundary.js';
import { makeStyles } from '@material-ui/core/styles';

import {
    AccountDetailsAddressForm,
    TaxIdsFormInline,
} from '../index.js';
import CountryCodes from '../../lib/CountryCodes.js';
import { DownloadIcon } from '../../icons/index.js';
import Config from '../../config.js';
import { DateTime } from 'luxon';


import {AuthContext} from '../../providers/AuthProvider.js';

const dateFormat = Config.DATE_FORMATS.FULL_NO_TIME;


const useStyles = makeStyles((theme) => ({
    upcomingInvoicePanel: {
        marginBottom: theme.spacing(2)
    }
}));

// const Transition = React.forwardRef(function Transition(props, ref) {
//     return <Slide direction="up" ref={ref} {...props} />;
// });

const AccountBilling = ({ state, api, accountInformation, customerPaymentMethod, setCustomerPaymentmethod, currentCard, setCurrentCard, dispatch, setShowLoader }) => {

    const chAuth = useContext(AuthContext);

    const [editBillingAddress, setEditBillingAddress] = useState(false);
    const [, setEditTaxInformation] = useState(false);
    const [formattedAddress, setFormattedAddress] = useState(false);
    const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
    let [alertBarStatus, setAlertBarStatus] = useState('success');

    const [message, setMessage] = useState('');
    const [gettingInvoices, setGettingInvoices] = useState(false);

    const classes = useStyles();

    const isMountedRef = useRef(true);

    useEffect(() => {
        

        const updateCustomer = async () => {

            try {
                const { idToken } = await chAuth.getIDToken();
                let customer = await api.getStripeCustomer(idToken.getJwtToken());
    
                if (customer && customer.sub_object) {
                    await dispatch({
                        type: 'UPDATE_ACCOUNT_INFORMATION',
                        payload: {
                            subscription: customer.sub_object.id,
                            productId: customer.sub_object.plan.product,
                            priceId: customer.sub_object.plan.id,
                            price: customer.sub_object.plan.amount,
                            customer
                        }
                    });
                }
                
            } catch (error) {}

        }

        updateCustomer();
    }, []);


    useEffect(() => {
        const setInitialValue = () => {
            if (accountInformation.cards.length) {
                setCustomerPaymentmethod(accountInformation.cards[0].id);
            }
        }

        if (!customerPaymentMethod && accountInformation.cards) {
            setInitialValue();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerPaymentMethod]);

    useEffect(() => {
        
        isMountedRef.current = true;
        if (isMountedRef.current) {
            setGettingInvoices(true);
        }
        const getInvoices = async () => {
            const { jwtToken } = await chAuth.getJwtToken() ?? {};

            if (jwtToken) {
                let result = await api.getInvoices(jwtToken);
    
                try {
                    let upcomingInvoice = await api.retrieveUpcomingInvoice(jwtToken);
                    if (isMountedRef.current) {
                        dispatch({
                            type: 'UPDATE_ACCOUNT_INFORMATION', payload: {
                                upcoming_invoice: upcomingInvoice,
                                invoices: result.invoices,
                                canceled_subs: result.subs,
                            }
                        })
                        setGettingInvoices(false);
                    }
    
                } catch (error) {
                    if (isMountedRef.current) {
                        setGettingInvoices(false);
                    }
                }
            } 


        }

        getInvoices();

        return () => {
            // Clean up the subscription
            isMountedRef.current = false;
        };
    }, []);

    const closeErrorSnackbar = () => {
        setOpenErrorSnackbar(false)
    }


    const handleUpdatePaymentMethod = async (values) => {
        setShowLoader(true);
        const { idToken } = await chAuth.getJwtToken() ?? {};
        let result = await api.updatePaymentMethods(values, idToken);

        let newCards = state.accountInformation.get('cards').reduce((acc, card) => {

            if (card.id !== result.id) {
                acc.push(card)
            } else {
                acc.push(result)
            }
            return acc;
        }, []);

        dispatch({
            type: 'UPDATE_ACCOUNT_INFORMATION', payload: {
                cards: newCards
            }
        });

        setShowLoader(false);

    }


    const handleUpdateBillingAddress = async (customerData) => {
        setEditBillingAddress(false);
        setShowLoader(true);
        const { jwtToken } = await chAuth.getJwtToken() ?? {};
        let response = await api.updateStripeCustomer(customerData, jwtToken);

        if (response.email) {
            dispatch({
                type: 'UPDATE_ACCOUNT_INFORMATION', payload: {
                    customer: response
                }
            });
            setMessage('Address updated');
            setAlertBarStatus('success');
            setOpenErrorSnackbar(true)
            setShowLoader(false);
        } else {
            setMessage('There was a problem updating your address');
            setAlertBarStatus('error');
            setOpenErrorSnackbar(true)
            setShowLoader(false);
        }
    }

    const handleAddressClose = () => {
        setEditBillingAddress(false);
    }




    const handleTaxUpdate = async (values) => {
        const { jwtToken } = await chAuth.getJwtToken() ?? {};
        let taxId = await api.createTaxId(
            { type: values.tax_id, value: values.tax_number },
            jwtToken
        );

        if (taxId.error) {
            if (taxId.error.code === "resource_already_exists") {
                setMessage('This tax code already exists');
                setAlertBarStatus('error');
            } else {
                setMessage('There was an error saving your Tax Number. Please check that it is correct and try again');
                setAlertBarStatus('error');
            }
            setOpenErrorSnackbar(true)

        } else {

            dispatch({
                type: 'ADD_TAX_ID', payload: {
                    ...taxId
                }
            });

            setMessage('Your Tax Number has been saved');
            setAlertBarStatus('success');
            setOpenErrorSnackbar(true)
        }



    }


    const deleteTaxId = async (tax_id) => {
        const { jwtToken } = await chAuth.getJwtToken() ?? {};

        let taxId = await api.deleteTaxId(
            tax_id.id,
            jwtToken
        );

        if (taxId.deleted) {
            dispatch({
                type: 'DELETE_TAX_ID', payload: {
                    id: taxId.id
                }
            });

        }
        setEditTaxInformation(false)

    }

    useEffect(() => {

        const formatAddress = () => {
            let addressArray = [];

            if (accountInformation.get('customer').address.line2) { addressArray.push(accountInformation.get('customer').address.line2) }
            if (accountInformation.get('customer').address.city) { addressArray.push(accountInformation.get('customer').address.city) }
            if (accountInformation.get('customer').address.state) { addressArray.push(accountInformation.get('customer').address.state) }
            if (accountInformation.get('customer').address.country) { addressArray.push(accountInformation.get('customer').address.country) }
            if (accountInformation.get('customer').address.postal_code) { addressArray.push(accountInformation.get('customer').address.postal_code) }

            let address = addressArray.join(", ");

            setFormattedAddress(address);
        }

        if (accountInformation.get('customer').address) {
            formatAddress()
        }

    }, [accountInformation])


    return (
        <Grid container spacing={2}>

            <Grid item xs={12}>

                {accountInformation.get('customer').address || state.accountInformation.get('user').attributes['custom:role'] === '1'
                    ?
                    <Card>
                        <CardContent>

                            <Typography variant="h5">Billing Address</Typography>

                            <Grid container direction="row">
                                <Grid item xs={12}>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="flex-start"
                                        spacing={2}
                                    >
                                        <Grid item>
                                            <Typography variant="body2" component="p"><strong>{accountInformation.get('customer').name}</strong></Typography>
                                            {formattedAddress
                                                ?
                                                <>
                                                    <Typography variant="body2" component="p">{formattedAddress}</Typography>

                                                </>
                                                :
                                                null
                                            }
                                        </Grid>
                                        <Grid item xs={12} md={4} align="flex-end">
                                            {state.accountInformation.get('user').attributes['custom:role'] === '1'
                                                ?
                                                <Grid container justifyContent="flex-end">

                                                    <Button
                                                        onClick={() => { setEditBillingAddress(!editBillingAddress) }}
                                                        color="secondary"
                                                        variant="contained"
                                                        disableElevation
                                                        size="small"
                                                    >Edit Billing Details</Button>
                                                </Grid>
                                                :
                                                null
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>

                            </Grid>



                            <Dialog open={editBillingAddress} onClose={handleAddressClose}>
                                <DialogContent style={{padding:'24px'}}>
                                    <AccountDetailsAddressForm CountryCodes={CountryCodes} setEditBillingAddress={setEditBillingAddress} handleAddressUpdate={handleUpdateBillingAddress} address={accountInformation.get('customer').address} name={accountInformation.get('customer').name} />
                                </DialogContent>
                            </Dialog>
                            {/* <Dialog open={editTaxInformation} onClose={handleTaxClose}>
                                <DialogContent>
                                    <TaxStatusForm setEditTaxInformation={setEditTaxInformation} handleTaxStatusUpdate={handleTaxStatusUpdate}  tax_exempt={accountInformation.get('customer').tax_exempt}/>
                                    <TaxIdsForm setEditTaxInformation={setEditTaxInformation} handleTaxUpdate={handleTaxUpdate} deleteTaxId={deleteTaxId} tax_ids={accountInformation.get('customer').tax_ids} />
                                </DialogContent>
                            </Dialog> */}

                        </CardContent>
                    </Card>
                    :
                    null
                }
            </Grid>


            {accountInformation.get('customer') && accountInformation.get('customer').currency
                ?
                (accountInformation.get('customer').currency === 'gbp' || accountInformation.get('customer').currency === 'eur')
                    ?
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>

                                <Typography variant="h5">Tax Details</Typography>
                                <Typography>We need to collect this information so that we can submit accurate tax reports.</Typography>
                                <ErrorBoundary fallback={<TableRow><TableCell>Error getting tax information</TableCell></TableRow>}>
                                    <TaxIdsFormInline
                                        state={state}
                                        placeholder={(accountInformation.get('customer').currency === 'gbp') ? 'GB12345678' : 'Provide a valid EU VAT tax number, e.g. DE123456789'}
                                        setEditTaxInformation={setEditTaxInformation}
                                        handleTaxUpdate={handleTaxUpdate}
                                        deleteTaxId={deleteTaxId}
                                        tax_ids={accountInformation.get('customer').tax_ids}
                                    />
                                </ErrorBoundary>
                                {/* <TaxStatusForm setEditTaxInformation={setEditTaxInformation} handleTaxStatusUpdate={handleTaxStatusUpdate}  tax_exempt={accountInformation.get('customer').tax_exempt}/> */}
                            </CardContent>
                        </Card>
                    </Grid>
                    : null
                :
                null
            }
            <Grid item md={
                (accountInformation.get('customer') && accountInformation.get('customer').balance < 0) ? 8 : 12
            } xs={12}>
                <AccountCards
                    setShowLoader={setShowLoader}
                    dispatch={dispatch}
                    currentCard={currentCard}
                    state={state}
                    api={api}
                    accountInformation={accountInformation}
                    handleUpdatePaymentMethod={handleUpdatePaymentMethod}
                    setMessage={setMessage}
                    setOpenErrorSnackbar={setOpenErrorSnackbar}
                    setAlertBarStatus={setAlertBarStatus}
                />
            </Grid>
            <Grid item md={4} xs={12}>
                <InCredit accountInformation={accountInformation} classes={classes} />
            </Grid>


            {state.accountInformation.get('user').attributes['custom:role'] === '1'
            ?
            <Grid item xs={12}>
                        {gettingInvoices ?
                            <>
                                <LinearProgress color="secondary" />
                            </>
                            :
                            null
                        }
                <Card>
                    <CardContent>
                        
                        <Typography variant="h5">Invoices</Typography>

                        {accountInformation.get('upcoming_invoice')
                            ?
                            <>
                                <UpcomingInvoice accountInformation={accountInformation} classes={classes} />
                            </>

                            :
                            null
                        }

                        {accountInformation.get('invoices')
                            ?


                            <Grid container>


                                <Grid item xs={12}>
                                    { accountInformation.get('invoices').length
                                    ?
                                    <Typography variant="subtitle1">Invoices</Typography>
                                    :
                                    <Typography variant="subtitle1">No invoices</Typography>
                                    }
                                    <TableContainer>
                                        <Table>
                                            <TableBody>
                                                {accountInformation.get('invoices').map((invoice) => {

                                                    return (

                                                        invoice.lines.data[0]
                                                            ?
                                                            <TableRow key={invoice.id}>
                                                                <TableCell>{invoice.number}</TableCell>
                                                                <TableCell><Chip color={(invoice.status === 'paid') ? 'primary' : 'secondary'}label={invoice.status} /></TableCell>

                                                                <TableCell>
                                                                    {DateTime.fromSeconds(invoice.lines.data[0].period.start).toFormat(dateFormat)} - {DateTime.fromSeconds(invoice.lines.data[0].period.end).toFormat(dateFormat)}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {invoice.lines.data[0].description}
                                                                </TableCell>
                                                                <TableCell>


                                                                    {(invoice.status === 'open' && invoice.collection_method !== "send_invoice") ?
                                                                        <Button variant="contained" size="small" color="secondary" href={invoice.hosted_invoice_url} disableElevation>
                                                                            Pay Now 
                                                                        </Button>
                                                                        : null}

                                                                        {invoice.collection_method !== "send_invoice" && invoice.status === 'paid' ?
                                                                        <Button
                                                                            variant="outlined"
                                                                            color="secondary"
                                                                            size="small"
                                                                            target="blank"
                                                                            startIcon={<DownloadIcon />}
                                                                            href={`${invoice.hosted_invoice_url}`}
                                                                        >Invoice / Receipt</Button>
                                                                        :null
                                                                    }

                                                                    {invoice.collection_method === "send_invoice" ?
                                                                        <Button
                                                                            variant="outlined"
                                                                            color="secondary"
                                                                            size="small"
                                                                            target="blank"
                                                                            startIcon={<DownloadIcon />}
                                                                            href={`${invoice.invoice_pdf}`}
                                                                        >Invoice</Button>
                                                                        :null
                                                                    }

                                                                </TableCell>
                                                            </TableRow>
                                                            :
                                                            null

                                                    )



                                                })}


                                                {gettingInvoices ?
                                                    [...Array(10).keys()].map((i) => {
                                                        return (
                                                            <TableRow key={i}>
                                                                 <TableCell><Skeleton variant="text" /></TableCell>
                                                                 <TableCell><Skeleton><Chip/></Skeleton></TableCell>
                                                                 <TableCell><Skeleton variant="text" /></TableCell>
                                                                 <TableCell><Skeleton variant="text" /></TableCell>
                                                                 <TableCell><Skeleton variant="text" /></TableCell>
                                                            </TableRow>
                                                        )
                                                        
                                                        
                                                        // <Skeleton variant="text" />

                                                    })


                                                    : null}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>

                            :
                            null
                        }
                    </CardContent>
                </Card>




            </Grid>
            :
            null
            }
            <ErrorSnackBar message={message} open={openErrorSnackbar} closeErrorSnackbar={closeErrorSnackbar} status={alertBarStatus} />

        </Grid>
    )
}

const InCredit = ({ accountInformation, classes }) => {
    return (
        accountInformation.get('customer') && accountInformation.get('customer').balance < 0
            ?
            <Card style={{ height: '100%' }}>
                <CardContent>
                    <Typography variant="h5">Credit</Typography>
                    <Typography>Current Credit</Typography>
                    <Typography variant="h5"> {accountInformation.get('currency')}{((accountInformation.get('customer').balance * -1) / 100).toFixed(2)}</Typography>
                </CardContent>
            </Card>
            :
            null
    )
}




const UpcomingInvoice = ({ accountInformation, classes }) => {
    return (
        accountInformation.get('upcoming_invoice') && accountInformation.get('upcoming_invoice').lines
            ?
            <Grid container className={classes.upcomingInvoicePanel}>
                <Grid item xs={12}>
                    <Typography variant="subtitle1">Upcoming Invoice</Typography>
                    <TableContainer>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        {DateTime.fromSeconds(accountInformation.get('upcoming_invoice').lines.data[0].period.start).toFormat(dateFormat)} - {DateTime.fromSeconds(accountInformation.get('upcoming_invoice').lines.data[0].period.end).toFormat(dateFormat)}
                                    </TableCell>
                                    <TableCell>
                                        {accountInformation.get('upcoming_invoice').lines.data[0].description}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
            :
            <Grid container className={classes.upcomingInvoicePanel}>
                <Grid item xs={12}>

                    {accountInformation.get('upcoming_invoice') && accountInformation.get('upcoming_invoice') === 'invoice_upcoming_none'
                    
                    ?
                    null
                
                    :
                    <Typography variant="subtitle1">There has been an error retrieving your upcoming invoice</Typography>
                
                }
                </Grid>
            </Grid>


    )
}

UpcomingInvoice.propTypes = {
    accountInformation: PropTypes.object.isRequired
};


export default AccountBilling;
