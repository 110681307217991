import React, {useState, useEffect} from "react";
import { DateTime } from 'luxon';
import { DateTimePicker } from '@material-ui/pickers/';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  ButtonGroup,
  Button
} from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  datePicker: {

  },
  dateGroup: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.between("xs", 736)]: {
      justifyContent: 'space-between',
      width:'100%'
    },
    '& .MuiButtonGroup-root' : {
      [theme.breakpoints.between("xs", 400)]: {
        display: 'flex',

        justifyContent: 'space-between',
        marginBottom: '5px',
        '& button' : {
          width: '100%'
        }
      },

  
    }
    
  }
}));

const ReportDateTimePicker = ({ downloadTime, dateFormat, onDateChange, minDate, maxDate, periodRange, ...props }) => {

  const classes = useStyles();

  const [fromTime, setFromTime] = useState();
  const [toTime, setToTime] = useState();
  const [isFromOpen, setIsFromOpen] = useState(false);
  const [isToOpen, setIsToOpen] = useState(false);

  useEffect(() => {

    const updateTimes = () => {

      if (downloadTime.from) {
        setFromTime(DateTime.fromISO(downloadTime.from.toISO(), { zone: 'local' }).toFormat(dateFormat));
      }
      if (downloadTime.to) {
        setToTime(DateTime.fromISO(downloadTime.to.toISO(), { zone: 'local' }).toFormat(dateFormat))
      }
    }

    updateTimes();
  }, [downloadTime, dateFormat]);

  return (

    <Grid className={classes.dateGroup} {...props}>

      <ButtonGroup color="primary" aria-label="Date selection button group">
        <Button  onClick={() => {
        setIsFromOpen(true)
        }}>{fromTime}</Button>
        <Button   onClick={() => {
        setIsToOpen(true)
        }}>{toTime}</Button>
      </ButtonGroup>

      <DateTimePicker
          style={{display:'none'}}
          minDate={(minDate) ? DateTime.fromISO(minDate.toISO(), { zone: 'local' }) : DateTime.fromISO("1900-01-01")}
          className={classes.datePicker}
          inputVariant="outlined"
          onChange={(date, value) => {
            onDateChange(date, 'from');
          }}
          open={isFromOpen}
          onOpen={() => setIsFromOpen(true)}
          onClose={() => setIsFromOpen(false)}
          value={fromTime}
          format={dateFormat}
        />
      <DateTimePicker
          style={{display:'none'}}
          minDate={(minDate) ? DateTime.fromISO(minDate.toISO(), { zone: 'local' }) : DateTime.fromISO("1900-01-01")}
          className={classes.datePicker}
          inputVariant="outlined"
          onChange={(date, value) => {
            onDateChange(date, 'to');
          }}
          open={isToOpen}
          onOpen={() => setIsToOpen(true)}
          onClose={() => setIsToOpen(false)}
          value={toTime}
          format={dateFormat}
        />
    </Grid>
  )

}

export default ReportDateTimePicker;