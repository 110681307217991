import React, { useState, useEffect, useReducer, useRef, useCallback } from "react";
import { DateTime } from 'luxon';
import { withRouter } from 'react-router-dom';
import {groupBy, sortBy} from 'lodash';
import clsx from 'clsx';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { makeStyles } from '@material-ui/core/styles';

import ExternalLinkIcon from '../../icons/ExternalLinkIcon';
import RoomDateTimePicker from '../../components/rooms/RoomDateTimePicker';
import RedirectDialog from '../../components/RedirectDialog';
import ErrorSnackBar from '../../components/ErrorSnackBar';
import LinkWithoutNavigateProps from '../../components/LinkWithoutNavigateProps';

import UpgradeCTAPanel from '../../components/UpgradeCTAPanel'
import Config from '../../config'

import CalculateCurrentCapacityLimit from '../../lib/CalculateCurrentCapacityLimit';
import { Link as RouterLink } from 'react-router-dom';
import EyeIcon from '../../icons/EyeIcon';
import '../../components/Styles.css'
import XIcon from '../../icons/XIcon';

import {
  Select,
  Container,
  Grid,
  FormControl,
  MenuItem,
  OutlinedInput,
  FormHelperText,
  InputLabel,
  Button,
  IconButton,
  Paper,
  InputAdornment,
  FormGroup,
  Typography,
  Slider,
  DialogActions,
  Dialog,
  DialogContent,
  DialogTitle,
  Backdrop,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  Divider,
  Link
} from '@material-ui/core';
import { CapacityWarningPanel } from '../../components';
import { HelpPopper } from '../../components';



const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(6),
  },
  paper: {
    padding: theme.spacing(2),
  },
  noUnderline: {
    '& .MuiInput-underline:before': {
      display: 'none'
    },
    '& .MuiInput-underline:after': {
      display: 'none'
    }
  },
  smallmargin: {
    marginBottom: theme.spacing(2),
  },
  withoutLabel: {
    marginTop: theme.spacing(6),
  },
  domainAdornment: {
    marginRight: 0
  },
  regexField: {
    height: '16px'
  },
  regexFieldInput: {
    alignItems: 'flex-start'
  },
  spinner: {
    marginLeft: 22,
    color: theme.palette.secondary
  },
  slugAdornment: {
    marginRight: '3px'
  },
  patternMatchInput: {
    marginTop: 0,
    paddingTop: 0,
    '& .MuiFormControl-marginDense': {
      marginTop: 0,

    }
  },
  helpertext_error: {

    color: 'red'

  },
  callout: {
    border: '1px solid',
    borderColor: theme.palette.secondary.main,
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(1),
  },
  capacitySlider: {
    margin: `${theme.spacing(4)}px 0`,
    '& .MuiSlider-markLabel': {
      top: '16px',
      '&[data-index="0"]': {
        left: '5px !important'
      },
      '&[data-index="1"]': {
        left: 'calc(100% - 5px) !important'
      }
    }
  },
  dateError: {
    backgroundColor: theme.palette.error.main,
    color: '#ffffff',
    borderRadius: '4px',
    padding: theme.spacing(1)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.primary.main,
    // backgroundColor: '#fff',
  },

}));


const EditRoom = ({ state, computedMatch, history, api, ...rest }) => {

  
  const classes = useStyles();
  const [location] = useState(history.location);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [alertBarStatus, setAlertBarStatus] = useState('success');
  const [errorMessage, setErrorMessage] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('Loading Room Data');
  const [canAddRoom] = useState(true);
  const { dispatch } = rest;
  const [domainId] = useState(computedMatch.params.domainId || false);
  const [usedCapacity, setUsedCapacity] = useState(0);
  const [, setSpareCapacity] = useState(0);
  const [isEditing] = useState(computedMatch.params.roomId || false);
  const [roomFoundForEditing, setRoomFoundForEditing] = useState(false);
  const [initialCapacity, setInitialCapacity] = useState(0);
  const [availableRoomCapacity, setAvailableRoomCapacity] = useState(0);
  const [, setDisplayCatchAllMessage] = useState(false);
  const [, setMaxRoomCapacity] = useState(0);
  const [dateTimeError, setDateTimeError] = useState({
    message: ''
  });
  const [messageLength, setMessageLength] = useState(0);
  const [openRedirectDialog, setOpenRedirectDialog] = useState(false);
  const [followedRedirectUrl, setFollowedRedirectUrl] = useState();
  const [redirectType, setRedirectType] = useState(1);
  const [saveRoomValues, setSaveRoomValues] = useState();
  const [numberOfUsersInRoom, setNumberOfUsersInRoom] = useState(0);
  const [openSaveRoomDialog, setOpenSaveRoomDialog] = useState(false);
  const [savingRoom, setSavingRoom] = useState(false);
  const [displayStockLevels, setDisplayStockLevels] = useState(true);
  const [displayCapacitySlider, setDisplayCapacitySlider] = useState(false);
  
  const [showPopper, setShowPopper] = useState(true);

  let fromDashboardOnboarding;

  if (history.location && history.location.state) {
    if (history.location.state.from === 'dash' && history.location.state.action === 'activate_a_room') {
      fromDashboardOnboarding = true;
    }
  }

  let popperEl=React.useRef(null);
  const dateFormat = Config.DATE_FORMATS.FULL_NO_TIME;
  const now = DateTime.local();
  const [domain, setDomain] = useState();
  const [roomData, setRoomData] = useState();

  const [fields, setFields] = useState({
    countdownStartsOn: {
      date: undefined,
      value: undefined
    },
    queueActivatesOn: {
      date: undefined,
      value: undefined
    },
    countdownStartsOnHour: {
      date: undefined,
      value: undefined
    },
    countdownStartsOnMinute: {
      date: undefined,
      value: undefined
    },
    countdownStartsOnAMPM: {
      value: 'AM'
    }
  });

  function reducer(state, action) {
    switch (action.type) {
      case 'UPDATE_SETTINGS':
        let newSteps = {
          ...state,
          ...action.payload
        }
        return newSteps;
      default:
        throw new Error();
    }
  }

  const [settings, setSettings] = useReducer(reducer, {
    title: "Waiting Room",
    message: "",
    urlRedirect: "",
    urlPattern: "",
    capacity: 0,
    stock: null,
    stockLevel: 0,
    displayStock: false,
    domainID: domainId,
    patternType: 'all',
    templateID: '',
    method: 'post',
    slug: '',
    captchaRequired: 0
  });




  const checkRedirect = async (urlRedirect) => {
    let result = await api.checkRedirect(
      urlRedirect,
      domain.name
    );
    return result;
  }

  const handleRedirectDialogClose = (event, reason) => {

    if (reason === 'backdropClick') {
      return false;
    }
    setOpenRedirectDialog(false);
    setSavingRoom(false);
  }

  useEffect(() => {
    const getData = async () => {
      if (state.rooms === undefined && state.templates === undefined) {
        let rooms = await api.getRooms();
        let templates = await api.getTemplates();
        await dispatch({ type: 'UPDATE_ROOMS', payload: rooms });

        if (templates) {
          await dispatch({ type: 'UPDATE_TEMPLATES', payload: templates });
        }
        populateSettings();
      }
    }

    const getRooms = async () => {
      let rooms = await api.getRooms();
      await dispatch({ type: 'UPDATE_ROOMS', payload: rooms });
      populateSettings();
    }

    const getTemplates = async () => {
      let templates = await api.getTemplates();
      await dispatch({ type: 'UPDATE_TEMPLATES', payload: templates });
      populateSettings();
    }

    const populateSettings = async () => {

      let room_id = computedMatch.params.roomId;
      if (state.rooms && state.templates && state.domains) {

        let room_data = state.rooms.reduce((acc, room) => {
          if (room.id === room_id) {
            acc = room;
          }
          return acc;
        }, {});

        if (room_data) {

          let domain = state.domains.reduce((acc, domain) => {
            if (domain.id === room_data.domainID) {
              acc = domain;
            }

            return acc;
          }, {});

          if (room_data.stock === null) {
            room_data.displayStock = false;
            room_data.stockLevel = 0;
          } else {
            room_data.stockLevel = room_data.stock;
            room_data.displayStock = true;
          }
          
          let customTemplates = 0;
          if (state.plan) {
            customTemplates = state.plan.allowCustomTemplates;
          }

          if (customTemplates === 1) {
            setDisplayStockLevels(true);
          } else {
            setDisplayStockLevels(false);
            room_data.displayStock = false;
            room_data.stockLevel = 0;
          }

          if (domain) {
            setDomain(domain);
          }

          setFields({
            countdownStartsOn: {
              date: DateTime.fromISO(room_data.countdownStartsOn, { zone: 'utc' })
            },
            queueActivatesOn: {
              date: DateTime.fromISO(room_data.queueActivatesOn, { zone: 'utc' })
            },
          });

          setInitialCapacity(room_data.capacity);

          setRoomData(room_data);

          if (state.rooms.length > 1 || (state.rooms.length === 1 && room_data.capacity < availableRoomCapacity)) {
            setDisplayCapacitySlider(true);
          }

          let message_length = (room_data.message) ? room_data.message.length: 0;
          setMessageLength(message_length)

          setSettings({ type: 'UPDATE_SETTINGS', payload: room_data });
          setRoomFoundForEditing(true);
        } else {
          setRoomFoundForEditing(false);
          setLoadingMessage('The Room you are attempting to edit doesn’t exist');
        }
      }

    };

    if (state.rooms === undefined && state.templates === undefined) {
      getData();
    } else if (state.rooms === undefined) {
      getRooms();
    } else if (state.templates === undefined) {
      getTemplates()
    } else {
      populateSettings();
    }

  }, [api, state.rooms, state.domains, state.templates, state.plan, dispatch, computedMatch.params.roomID, computedMatch.params.roomId, dateFormat, availableRoomCapacity]);


  const handleRoomsCapacityChange = (event, newValue) => {

    setDisplayCatchAllMessage(false);
    if (newValue > (state.plan.maxWaitingRoomCapacity - usedCapacity + initialCapacity)) {
      newValue = state.plan.maxWaitingRoomCapacity - usedCapacity + initialCapacity
    }
    formik.setFieldValue('capacity', newValue);
    calculateCurrentCapacityLimit();
  }



  const calculateCurrentCapacityLimit = () => {

    if (!roomData) {
      return false;
    };

    let max_capacity = state.plan.maxWaitingRoomCapacity;
    let available_room_capacity = CalculateCurrentCapacityLimit(state.rooms, max_capacity, roomData.capacity);

    setUsedCapacity(state.plan.maxWaitingRoomCapacity - available_room_capacity);
    setAvailableRoomCapacity(available_room_capacity);
    setMaxRoomCapacity(available_room_capacity);

  }

  useEffect(() => {

    if (state.rooms && roomData) {
      calculateCurrentCapacityLimit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.rooms, roomData]);


  const closeErrorSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenErrorSnackbar(false);
  };


  const checkAutotune = async (result) => {
    let domainGroups = groupBy(result, 'domainID');

    for (const key in domainGroups) {
      if (domainGroups.hasOwnProperty(key)) {
        const rooms = domainGroups[key];
        let allowAutoTune = false;

        for (let i = 0; i < rooms.length; i++) {
          const room = rooms[i];
          if (room.patternType === 'all') {
            allowAutoTune = true;
          }
        }

        if (!allowAutoTune) {
          let data = {
            autotune: '0',
            id: key
          }
          let response = await api.putDomain(data, 'put');
          if (!response.error) {
            dispatch({ type: 'UPDATE_DOMAINS', payload: response, updateCurrentDomain: true });
          } else {
            // console.log(response.error);
          }
        }

      }
    }
  }


  const upgradeMessage = 'You have filled your available room allocation. Upgrade to get extra rooms';

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...settings
    },
    validationSchema: Yup.object({
      title: Yup.string().required('Required'),
      slug: Yup.string().nullable().required('Required')
      .test(
        {
          name: 'slug-spaces',
          exclusive: true,
          message: 'Spaces are not allowed',
          test: (value) => {

            if(value) {
              var inValid = /\s/;
              var k = inValid.test(value);
              return !k;
            }
          },
        })
        .test(
          {
            name: 'slug-slashes',
            exclusive: true,
            message: 'Forward slashes are not allowed',
            test: (value) => {

              if(value) {
                let res = value.indexOf('/');
                if (res > -1) {
                  return false;
                } else {
                  return true;
                }
              }
            },
          }),
      capacity: Yup.number().test('check-capacity', 'Capacity is above maximum available capacity',
      function(value){
        if(value > availableRoomCapacity) {
          formik.setFieldValue("capacity", availableRoomCapacity)
          //formik.setValues('capacity', availableRoomCapacity);
          return false;
        } else {
          return true;
        }
      }),
      message: Yup.string().max(512, 'Maximum number of characters met'),
      urlRedirect: Yup.string().test('test-slash', 'Redirect needs to start with a forward slash',
        function (value) {
          return value && value[0].indexOf('/') !== -1;
        }).required('Required')
        .test('test-urldirect', 'Spaces are not allowed',
          function (value) {
            var inValid = /\s/;
            var k = inValid.test(value);
            return !k;
          }),
      urlPattern: Yup.string().when("patternType", {
        is: (patternType) => {

          let validate = true;

          if (patternType === 'all') {
            validate = false;
          }
          if (patternType === 'disabled') {
            validate = false;
          }
          return validate;
        },
        then: Yup.string().test('contains-domain', 'Do not include your domain',
          function (value) {
            const { path, createError } = this;
            if (!value) {
              return createError({ path, message: 'This is required' });
            }
            let hasDomainInValue = (value.indexOf(`${domain.name}`) === -1) ? false : true;
            if (hasDomainInValue) {
              return createError({ path, message: 'Do not include your domain' });
            }
            return true;
          })
      })
    }),
    onSubmit: async (values) => {
      setSavingRoom(true);
      values = { ...values, slug: values.slug.replace(/\//g, '-') };
      setSaveRoomValues(values);
      
      if (domain && domain.deployment === 'javascript') {
        let result = await checkRedirect(values.urlRedirect.trim());

        if (result.response === 'error') {

          setRedirectType(2);
          
          if (result.error.statusText === 'REDIRECTLOOP') {
            setRedirectType(3);
          }

          if (result.error.statusText === 'TASK_ERROR') {
            setRedirectType(3);
          }
          
          if (result.error.statusText === 'TIMEOUT_ERROR') {
            setRedirectType(4);
          }
          setSaveRoomValues(values);
          setFollowedRedirectUrl(values.urlRedirect.trim())
          setOpenRedirectDialog(true);
          return false;
        } else if (result.redirects.length > 1 && values.urlRedirect.trim() !== result.response.redirectUrl.trim()) {

          setRedirectType(5)
          setSaveRoomValues(values);
          setFollowedRedirectUrl(result.response.redirectUrl)
          setOpenRedirectDialog(true);
          return false;
        }

        

      }

      saveRoom(values);

    }
  });

  const handleRedirectDialogAgree = () => {
    confirmSave(saveRoomValues);
    setOpenRedirectDialog(false);
  }
  const handleRedirectDialogCancel = () => {
    setOpenRedirectDialog(false);
    setSavingRoom(false);
  }

  const checkRoomQueue = async () => {
    let report = await api.getRoomById(computedMatch.params.roomId);
    
    if (report && report.sessions) {
      return {sessions: report.sessions, rate: domain.rate};
    } 

    return false;
  }

  const saveRoom = async (values) => {


    let domainID = computedMatch.params.domainId;

    let roomId = computedMatch.params.roomId || undefined;
    let method = 'post';


    if (roomId) {
      method = 'put';
    }

    let dataToSave = {
      queueActivatesOn: DateTime.fromISO(fields.queueActivatesOn.date, { zone: 'utc' }).toISO(),
      countdownStartsOn: DateTime.fromISO(fields.countdownStartsOn.date, { zone: 'utc' }).toISO(),
      domainID: values.domainID,
      title: values.title,
      urlRedirect: values.urlRedirect.trim(),
      urlPattern: values.urlPattern,
      message: values.message,
      stock: (values.displayStock) ? parseInt(values.stockLevel, 10) : null,
      templateID: values.templateID,
      patternType: values.patternType,
      slug: values.slug.trim(),
      capacity: values.capacity,
      captchaRequired: values.captchaRequired,
      id: domainID
    };

    let extraFieldsForUpdate = {}

    if (method === 'put') {
      extraFieldsForUpdate = {
        id: roomId,
        method: 'put'
      }
    }

    let data = {
      ...dataToSave,
      ...extraFieldsForUpdate
    }

    if (data.patternType === 'disabled') {
      let checkRoomQueueResult = await checkRoomQueue();
      setNumberOfUsersInRoom(checkRoomQueueResult.sessions);


      if (checkRoomQueueResult.sessions > checkRoomQueueResult.rate) {
        setOpenSaveRoomDialog(true)
      } else {
        confirmSave(data);
      }
    } else {
      confirmSave(data);
    }

    
  }

  const handleCancel = () => {
    setOpenSaveRoomDialog(false)
    setSavingRoom(false)
  }

  const handleOk = () => {
    confirmSave(saveRoomValues);
    setOpenSaveRoomDialog(false)
    setSavingRoom(false)

  }


  const confirmSave = async (data) => {
    let result = await api.addRoom(data, 'put');
    if (result.error) {
      if (result.error.indexOf('regular expressi') !== -1) {
        const capitalized = result.error.trim().charAt(0).toUpperCase() + result.error.trim().slice(1);
        setErrorMessage(capitalized);
      } else {
        setErrorMessage('There has been a problem adding your room');
      }
      setAlertBarStatus('error');
      setOpenErrorSnackbar(true);
      setSavingRoom(false);
    } else {
      dispatch({ type: 'UPDATE_ROOMS', payload: result });
      await checkAutotune(result);

      if(location.state && location.state.from) {
        history.goBack();
      } else {
        setAlertBarStatus('success');
        setErrorMessage('Your room has been updated');
        setOpenErrorSnackbar(true);
        setSavingRoom(false);
      }

      
    }
  }

  const settingsUpdate = useRef(false);

  useEffect(() => {
    const calcCapacity = async () => {
      let capacity = state.rooms.reduce((acc, room) => {
        acc = acc + parseInt(room.capacity, 10);
        return acc;
      }, 0);
      setUsedCapacity(capacity);
      setSpareCapacity(state.plan.maxWaitingRoomCapacity - capacity + formik.values.capacity);

      if (settingsUpdate.current === false) {
        settingsUpdate.current = true;

        if (!isEditing) {
          let temp_capacity = state.plan.maxWaitingRoomCapacity - capacity + formik.values.capacity;
          temp_capacity = Math.floor(temp_capacity / 2)
          setSettings({ type: 'UPDATE_SETTINGS', payload: { capacity: temp_capacity } });
        }

      }
    }

    if (state.rooms !== undefined) {
      calcCapacity();
    }
  }, [formik.values.capacity, isEditing, state.plan.maxWaitingRoomCapacity, state.rooms]);


  const [slugChangeMessage, setSlugChangeMessage] = useState();

  useEffect(() => {

    const checkSlugChange = () => {
      let room_data = roomData;

      if (room_data.slug !== formik.values.slug) {

        setSlugChangeMessage('Changing the URL when a room is active will break status updates for anyone in that room')
      } else {
        setSlugChangeMessage('')
      }

    }

    if (roomData && formik.values.slug) {
      checkSlugChange();
    }


  }, [formik.values.slug, roomData]);


  const onDateChange = useCallback((date, field_name) => {

    setDateTimeError({
      message: ''
    });
    let valid = true;

    switch (field_name) {
      case 'countdownStartsOn':
        let queueActivatesOn = DateTime.fromISO(fields.queueActivatesOn.date).toMillis();
        if (queueActivatesOn < date.ts) {
          setDateTimeError({
            message: 'The countdown must be before the queue activates.'
          });
          valid = false;
        }
        break;
      case 'queueActivatesOn':
        let countdownStartsOn = DateTime.fromISO(fields.countdownStartsOn.date).toMillis();
        if (countdownStartsOn > date.ts) {
          setDateTimeError({
            message: 'The queue must activate after the countdown starts.'
          });
          valid = false;
        }
        break;

      default:
        break;
    }

    if (valid) {
      setFields((f) => {
        return {
          ...f,
          [field_name]: {
            date: date
          }
        }
      }
      );
    }
  },
    [fields.countdownStartsOn.date, fields.queueActivatesOn.date]
  );

  useEffect(() => {
    const handlePatternTypeChange = () => {
      calculateCurrentCapacityLimit();
    }
    if (formik.values.patternType) {
      handlePatternTypeChange();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.patternType]);

  const [capacityStep, setCapacityStep] = useState(100);

  useEffect(() => {

    let step = Math.round((availableRoomCapacity / 100) / 10) * 10;

    if (step < 1) {
      step = 1;
    }

    setCapacityStep(step)

  }, [availableRoomCapacity]);

  return (

    <div className={classes.root}>

      {state.domains || state.templates
        ?
        <Container
          maxWidth="md"
          spacing={4}
        >

          {
            (!canAddRoom)
              ?
              <UpgradeCTAPanel upgradeMessage={upgradeMessage} />
              :
              null
          }

          {!roomFoundForEditing && isEditing ?
            <Grid container component={Paper} className={classes.paper}>
              <Grid item xs={12}>
                <Typography>{loadingMessage}</Typography>
              </Grid>
            </Grid>
            : null}



          {
            (canAddRoom && roomFoundForEditing && isEditing)
              ?
              <Grid container component={Paper} className={classes.paper} spacing={2}>
                <Grid item xs={12}>
                  <FormControl fullWidth margin="normal" variant="outlined">
                    <InputLabel htmlFor="title">Waiting Room</InputLabel>
                    <OutlinedInput
                      id="title"
                      labelWidth={90}
                      placeholder="Waiting Room"
                      aria-label="Waiting Room Title"
                      {...formik.getFieldProps('title')}
                    />
                    <FormHelperText>This name will be displayed to your users</FormHelperText>
                    {formik.touched.title && formik.errors.title ? <Typography variant="subtitle2" color="error">{formik.errors.title}</Typography> : null}
                  </FormControl>
                </Grid>


                <Grid item xs={12}>

                {popperEl.current && fromDashboardOnboarding?

                <HelpPopper showPopper={showPopper} anchorEl={popperEl.current} setShowPopper={setShowPopper}/>
                  //   <Popper id="tooltip" open={showPopper} anchorEl={popperEl.current} placement="bottom" disablePortal={false}
                  //   modifiers={{
                  //     flip: {
                  //       enabled: true,
                  //     },
                  //     preventOverflow: {
                  //       enabled: true,
                  //       boundariesElement: 'window',
                  //     },
                  //     arrow: {
                  //       enabled: true,
                  //       element: '#arrow',
                  //     },
                  //     offset: {
                  //       offset: '0, 10',

                  //     }
                  //   }}>
                  //     <div id="arrow" x-arrow style={{
                  //       background: '#ff5722',
                  //     }}></div>
                  //     <Grid container>
                  //   <Grid style={{
                  //     backgroundColor: '#ff5722',
                  //     color: 'white',
                  //     padding: '16px',
                  //     borderRadius: '6px'
                  //   }}>
                  //     <div className="ch-popper-close"

                  //       onClick={() => {
                  //         setShowPopper(false)
                  //       }}>
                  //         <XIcon style={{width: '18px', height: '18px'}}/>
                  //     </div>
                  //     <Typography>Can add a message here to tell user what to do!!</Typography>
                      
                  //   </Grid>
                  //   </Grid>
                  // </Popper>
                    :null}

                  <FormControl ref={popperEl} fullWidth margin="normal" variant="outlined">
                    <InputLabel htmlFor="title">Waiting room slug EDIT ROOM</InputLabel>
                    <OutlinedInput
                      id="slug"
                      {...formik.getFieldProps('slug')}
                      labelWidth={110}
                      placeholder="<URL>"
                      aria-label="URL"
                      startAdornment={<InputAdornment position="start" className={classes.slugAdornment}>{`${Config.CH_WR_ENDPOINT}`}</InputAdornment>}
                      endAdornment={

                        (
                          fields.queueActivatesOn.date < now || fields.countdownStartsOn.date < now
                        )
                          ?
                          <InputAdornment position="end" >
                            <IconButton component="a" href={`${Config.CH_WR_ENDPOINT}${formik.values.slug}`} target="_blank">
                              <ExternalLinkIcon />
                            </IconButton>
                          </InputAdornment>
                          :
                          null


                      }
                    />
                    {formik.touched.slug && formik.errors.slug ? <Typography variant="subtitle2" color="error">{formik.errors.slug}</Typography> : null}
                  </FormControl>

                  {slugChangeMessage
                    ?
                    <Typography>{slugChangeMessage}</Typography>
                    :
                    null}
                </Grid>


                <Grid item xs={12} >
                  <FormControl fullWidth margin="normal" variant="outlined">
                    <InputLabel htmlFor="title">Redirect users to</InputLabel>
                    <OutlinedInput
                      id="urlRedirect"
                      {...formik.getFieldProps('urlRedirect')}
                      labelWidth={106}
                      placeholder="/"
                      aria-label="URL Redirect"
                      required
                      startAdornment={<InputAdornment position="start" className={classes.slugAdornment}>{`${(domain) ? domain.name : ''}`}</InputAdornment>}
                    />
                    {formik.touched.urlRedirect && formik.errors.urlRedirect ? <Typography variant="subtitle2" color="error">{formik.errors.urlRedirect}</Typography> : null}
                    <FormHelperText>If you send users directly to the waiting room, they will be forwarded to this URL when leaving the queue. If the user is redirected from your website to a waiting room, the user will be sent back to the url that they were trying to access on your website.</FormHelperText>
                  </FormControl>


                </Grid>



                <Grid item xs={12}>
                  <FormControl fullWidth margin="normal" variant="outlined">
                    <InputLabel htmlFor="title">Message</InputLabel>
                    <OutlinedInput
                      id="message"
                      {...formik.getFieldProps('message')}
                      labelWidth={54}
                      placeholder="Message"
                      aria-label="Message"
                      multiline
                      rows={4}
                      inputProps={{ maxLength: 512 }}

                      onKeyUp={function (event) {
                        setMessageLength(event.currentTarget.value.length);
                      }}
                    />
                    <FormHelperText>A message to be displayed to the users in the waiting room</FormHelperText>
                    <FormHelperText>
                      Characters remaining  {512 - messageLength}
                    </FormHelperText>
                  </FormControl>
                </Grid>
            
                <>
                <Grid item xs={12}>
                <Divider />
                </Grid>
                <Grid item xs={12} md={3}>
                    <FormControlLabel
                        control={
                          <Checkbox
                            {...formik.getFieldProps('displayStock')}
                            checked={formik.values.displayStock}
                            name="displayStock"
                            disabled={!displayStockLevels}
                          />
                        }
                        label="Show Stock Availability"
                        />
                </Grid>
                
                <Grid item xs={12} md={4}>
                  <FormControl fullWidth margin="dense" variant="outlined">
                    <InputLabel htmlFor="title">Availability</InputLabel>
                    <OutlinedInput
                      disabled={!formik.values.displayStock || !displayStockLevels}
                      type="number"
                      id="stockLevel"
                      {...formik.getFieldProps('stockLevel')}
                      labelWidth={72}
                      aria-label="Stock Availability"
                      />
                  </FormControl>
                {
                !displayStockLevels ?
                <FormHelperText><RouterLink to="/account/plan">Upgrade</RouterLink> to use Stock Availability</FormHelperText>
                  :
                  null
                }
                </Grid>
                <Grid item xs={12}>
                <Divider />


                </Grid>
                </>

                { displayCapacitySlider
                
                ?
              
                
                <Grid item xs={12} className={classes.capacitySlider}>
                  <FormGroup>
                    <InputLabel htmlFor="capacity">Room Capacity</InputLabel>
                      {availableRoomCapacity
                        ?
                       
                    <Grid container justifyContent="space-between" spacing={4}>

                   
                    <FormControl component={Grid} xs={10} item>
                        <Slider
                          id="capacity"
                          step={capacityStep}
                          aria-label="Room Capacity"
                          onChange={(ev, b) => handleRoomsCapacityChange(ev, b)}
                          value={formik.values.capacity}
                          // valueLabelFormat={valueLabelFormat}
                          marks={[
                            {
                              value: 0,
                              label: '0',
                            },
                            {
                              value: availableRoomCapacity,
                              label: `${availableRoomCapacity / 1000}K`,
                            },
                          ]}
                          min={0}
                          max={availableRoomCapacity}
                          valueLabelDisplay="off"
                        />
                    </FormControl>
                    <FormControl  component={Grid} xs={2} item>
                      <OutlinedInput
                        disabled={!availableRoomCapacity || availableRoomCapacity < 1}
                        type="number"
                        id="capacity"
                        {...formik.getFieldProps('capacity')}
                        labelWidth={72}
                        aria-label="Capacity"
                        inputProps={{
                          min:"0",
                          max:availableRoomCapacity
                        }}
                        notched={false}
                        />
                    </FormControl>
                    </Grid>
                   
                        : null
                      }

                  { formik.values.capacity >= 50 ?
                  <FormHelperText style={{zIndex: 10}}>The number of people this waiting room can hold before <Link target="_blank" href="https://support.crowdhandler.com/support/solutions/articles/80000602693-what-happens-when-a-waiting-room-is-full-">becoming full</Link></FormHelperText>
                  : null }

                  {availableRoomCapacity && availableRoomCapacity <= 0 ?
                    <Typography className={classes.helpertext_error} color="error">
                      You currently have no available capacity. Reduce capacity on an existing room in order to allocate capacity to this room.
                    </Typography>
                    :
                    null
                  }
                    {formik.values.capacity < 1 && availableRoomCapacity && availableRoomCapacity > 0 ?
                        
                        <CapacityWarningPanel color={'red'}>
                        <Typography>
                        This room will <Link target="_blank" href="https://support.crowdhandler.com/support/solutions/articles/80000602693-what-happens-when-a-waiting-room-is-full-">always be full</Link>, if you are trying to set the rate, use <RouterLink component={LinkWithoutNavigateProps} to={`/domains/${domain.id}`}>the domain settings</RouterLink>
                         </Typography>
                     </CapacityWarningPanel>
                      :
                      null
                    }
                    {formik.values.capacity < 50 && formik.values.capacity > 0 && availableRoomCapacity && availableRoomCapacity > 0 ?
                     <CapacityWarningPanel color={'#ff5722'}>
                     <Typography>
                     This is a very small waiting room, if you are trying to set the rate, use <RouterLink component={LinkWithoutNavigateProps} to={`/domains/${domain.id}`}>the domain settings</RouterLink>
                        </Typography>
                    </CapacityWarningPanel>
                      :
                      null
                    }


                  </FormGroup>

                </Grid>

                : null
                }

                {state.templates
                  ?
                  <Grid item xs={12}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs>
                          <FormControl fullWidth margin="dense" variant="standard">
                            <InputLabel id="template_id_label">Template</InputLabel>
                            <Select required labelId="template_id_label" id="templateID"
                              {...formik.getFieldProps('templateID')}>
                              {sortBy(state.templates, [t => t.name.toLowerCase()] ).map((template) => {

                                if (state.plan.allowCustomTemplates.toString() === '1') {
                                  return <MenuItem key={template.id} value={template.id}>{template.name}</MenuItem>;
                                } else {
                                  if (template.custom === 0) {
                                    return <MenuItem key={template.id} value={template.id}>{template.name}</MenuItem>;
                                  } else {
                                    return null;
                                  }
                                }
                              })}
                            </Select>                            
                            <FormHelperText>Select the template for this room</FormHelperText>
                          </FormControl>
                      </Grid>
                      <Grid item xs={2}>

                      <IconButton target="_blank" rel="noopener noreferrer" href={`/template-preview/${formik.values.templateID}/${computedMatch.params.roomId}`} >
                      <EyeIcon></EyeIcon>
                    </IconButton>
                    
                      </Grid>
                    </Grid>
                  </Grid>
                  :
                  <Grid item xs={12}><FormHelperText>There was an error fetching the templates. Please refresh to try again</FormHelperText></Grid>
                }

                

       

                {fields.countdownStartsOn
                  ?
                  <Grid item md={6}>
                    <RoomDateTimePicker
                      dateTime={fields.countdownStartsOn}
                      dateFormat={dateFormat}
                      label="Countdown Starts"
                      onDateChange={onDateChange}
                      fieldName={'countdownStartsOn'}
                    />
                  </Grid>
                  :
                  null}
                {fields.queueActivatesOn
                  ?
                  <Grid item md={6}>
                    <RoomDateTimePicker
                      dateTime={fields.queueActivatesOn}
                      minDate={fields.countdownStartsOn}
                      dateFormat={dateFormat}
                      label="Queue Activates"
                      onDateChange={onDateChange}
                      fieldName={'queueActivatesOn'}
                    />
                  </Grid>
                  :
                  null}


                {dateTimeError.message
                  ?
                  <Grid item xs={12} className={classes.dateError}>
                    <Typography>{dateTimeError.message}</Typography>
                  </Grid>
                  :
                  null
                }


                <Grid item xs={12}>
                  <FormControl fullWidth margin="normal" variant="standard">
                    <InputLabel id="domain_label"
                    color="secondary"
                    >Protect URLs</InputLabel>
                    <Select required labelId="domain_label"
                      id="patternType"
                      name="patternType"
                      {...formik.getFieldProps('patternType')}
                    >
                      <MenuItem value='disabled'>Disabled</MenuItem>
                      <MenuItem value='all'>All of {(domain) ? domain.name : null}</MenuItem>
                      <MenuItem value='contains'>URL Contains</MenuItem>
                      <MenuItem value='regex'>URL matches regular expression</MenuItem>
                      <MenuItem value='regex-not'>URL does NOT match regular expression</MenuItem>

                    </Select>
                  </FormControl>
                </Grid>

                
                { 
                  formik.values.patternType === 'contains' ||
                  formik.values.patternType === 'regex' ||
                  formik.values.patternType === 'regex-not'
                  ?
                  <Grid item xs={12} className={classes.patternMatchInput}>
                    <FormControl fullWidth margin="dense" variant="outlined">

                      <InputLabel htmlFor="title">
                        {
                        (formik.values.patternType === 'regex' || formik.values.patternType === 'regex-not')
                        ?
                        'Regular Expression'
                        :
                        (formik.values.patternType === 'contains' || formik.values.patternType === 'contains-not') ? 'String' : 'All'}
                      </InputLabel>


                      <OutlinedInput
                        className={clsx({
                          [classes.regexFieldInput]: formik.values.patternType === 'regex' || formik.values.patternType === 'regex-not'
                        })}
                        {...formik.getFieldProps('urlPattern')}
                        multiline={(formik.values.patternType === 'regex' || formik.values.patternType === 'regex-not')}
                        rows={(formik.values.patternType === 'regex' || formik.values.patternType === 'regex-not') ? 4 : 1}
                        labelWidth={(formik.values.patternType === 'regex' || formik.values.patternType === 'regex-not') ? 120 : (formik.values.patternType === 'contains' || formik.values.patternType === 'contains-not') ? 40 : 20}
                        placeholder={(formik.values.patternType === 'regex' || formik.values.patternType === 'regex-not') ? 'RegEx Pattern' : (formik.values.patternType === 'contains' || formik.values.patternType === 'contains-not') ? 'String' : 'All'}
                        aria-label="URL Pattern"
                        disabled={formik.values.patternType === 'all' || formik.values.patternType === 'disabled'}

                      />
                      {
                        (formik.values.patternType !== 'all')
                          ?
                          <>
                            {formik.touched.urlPattern && formik.errors.urlPattern ? <Typography variant="subtitle2" color="error">{formik.errors.urlPattern}</Typography> : null}
                            <FormHelperText>Required. Do not include your domain.</FormHelperText>
                          </>
                          :
                          null
                      }

                      {
                        (formik.values.patternType === 'regex')
                          ?
                          <FormHelperText>A pattern match for the URLs that should put users into the waiting room.</FormHelperText>
                          :
                          null
                      }
                      {
                        (formik.values.patternType === 'regex-not')
                          ?
                          <FormHelperText>A pattern match for the URLs that should NOT put users into the waiting room.</FormHelperText>
                          :
                          null
                      }
                      {(formik.values.patternType === 'regex' || formik.values.patternType === 'regx-not')
                        ?
                        <FormHelperText>The CrowdHandler pattern matching engine uses the POSIX-Extended regular expression syntax. <a href="https://support.crowdhandler.com/a/solutions/articles/80000599765" rel="noopener noreferrer" target="_blank">Using RexEg for URL pattern matching</a></FormHelperText>
                        :
                        null
                      }
                    </FormControl>
                  </Grid>
                  :
                  null
                }

                <Grid item xs={12} align="right" >
                  <Button
                    disableElevation
                    color="primary"
                    onClick={history.goBack}>
                    Cancel</Button>
                  <Button
                    style={{ marginLeft: 8 }}
                    disableElevation
                    disabled={!formik.isValid}
                    variant="contained"
                    color="secondary"
                    onClick={formik.handleSubmit}>
                    Save Room</Button>
                </Grid>
              </Grid>

              :
              null
          }
          
          <ErrorSnackBar message={errorMessage} open={openErrorSnackbar} closeErrorSnackbar={closeErrorSnackbar} status={alertBarStatus} />



          <RedirectDialog
          domain={domain}
          openRedirectDialog={openRedirectDialog}
          handleRedirectDialogClose={handleRedirectDialogClose}
          followedRedirectUrl={followedRedirectUrl}
          handleRedirectDialogAgree={handleRedirectDialogAgree}
          handleRedirectDialogCancel={handleRedirectDialogCancel}
          domainId={(domain) ? domain.id : null}
          redirectType={redirectType}
          disableEscapeKeyDown={true}
          onClose={handleRedirectDialogClose}
          />



        </Container>
        :
        null
      }


      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        aria-labelledby="confirmation-dialog-title"
        open={openSaveRoomDialog}

      >
        <DialogTitle id="confirmation-dialog-title">Room in use</DialogTitle>
        <DialogContent dividers>
          <Typography>{`Disabling this room while there are ${numberOfUsersInRoom} active users will immediately put ${numberOfUsersInRoom} onto your site`}</Typography>
          <Typography>Are you sure you want to do this?</Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCancel} color="primary">
            No
        </Button>
          <Button onClick={handleOk} color="secondary">
            Yes
        </Button>
        </DialogActions>
      </Dialog>

      <Backdrop className={classes.backdrop} open={savingRoom}>
        <Grid container justifyContent="center" alignItems="center" direction="column" spacing={4}>
          <Grid item>
            <CircularProgress color="inherit" />
          </Grid>
        </Grid>
      </Backdrop>


    </div>
  );
};

export default withRouter(EditRoom);
