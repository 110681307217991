import React, { useState, useEffect, useReducer, useRef, useContext } from 'react';
import Routes from "./Routes";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import LuxonUtils from '@date-io/luxon';
import { BrowserRouter as Router } from 'react-router-dom';

import ScrollToTop from './lib/ScrollToTop';
import stateReducer from './lib/stateReducer';
import { API } from './API/API';
import Config from './config';
import { CircularProgress, Backdrop, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import './App.css';
import {AuthContext, AuthDispatchContext} from './providers/AuthProvider';
import * as AWS from 'aws-sdk/global';
import { datadogRum } from '@datadog/browser-rum';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.primary.main,
    backgroundColor: '#fff',
  },
}));


const App = () => {
  const [state, dispatch] = useReducer(stateReducer, Config.DEFAULT_STATE);
  const [user, setUser] = useState();
  const [authorised, setAuthorised] = useState(false);
  const [loginReturnPath] = useState();
  const [api] = useState(new API());
  const [, setErrorLoading] = useState(false);
  const classes = useStyles();
  
  const chAuth = useContext(AuthContext);
  const chAuthDispatch = useContext(AuthDispatchContext);


useEffect(() => {
  const IsUserAuthenticated = async () => {

    try {
      let currentSession = await chAuth.currentSession();

      if(currentSession) {
        let {session, cognitoUser} = currentSession;

        if(session && !session.isValid()) {
          chAuth.signOut();
        }

        if (session && cognitoUser) {

          let { idToken } = session;  
          const user_data = await new Promise((resolve, reject) => {
            cognitoUser.getUserData((err, data) => {
              resolve(data);
            })
          });
          const region = await chAuth.getRegion();
  
          if (region === 'us-east-1') {
            api.setRegion('us');
          } else {
            api.setRegion('eu');
          }
  
          let result = await api.initialize(idToken);
          result.push(session.getIdToken().payload);

          const attributes = {};
          user_data.UserAttributes.forEach((att) => {
            attributes[att.Name] = att.Value;
          })

          dispatch({
            type: 'INIT', payload: result, user: {
              attributes,
              username: user_data.Username,
              mfa_prefs: user_data.UserMFASettingList ? user_data.UserMFASettingList[0] : 'NOMFA',
              cognitoUser
            }
          });
    
          setAuthorised(true);
          dispatch({ type: 'SET_IS_LOADING', payload: false });
          dispatch({ type: 'SET_READY_TRUE' });
  
        } else {
          setAuthorised(false);
          dispatch({ type: 'SET_IS_LOADING', payload: false });
          dispatch({ type: 'SET_READY_TRUE' });
        }
      } else {
        setAuthorised(false);
        dispatch({ type: 'SET_IS_LOADING', payload: false });
        dispatch({ type: 'SET_READY_TRUE' });
      }

      
    } catch (error) {
      console.log(error);
    }
    

  }
  IsUserAuthenticated();
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [])



  const signOut = async (history) => {
    try {
      await chAuth.signOut();
      setUser(false);
      localStorage.removeItem('CurrentlySelectedDomain');
      setAuthorised(false);
      dispatch({ type: 'RESET_APP', payload: true });
      setTimeout(() => {
        if (history) {
          history.replace('/login');
        }
      }, 500);
    } catch (error) {
      console.log(error);
    }
  }


  useEffect(() => {

    if(state.failedToInit) {
      chAuth.signOut();
      setUser(false);
    }
    
    return () => {
      
    };
  }, [chAuth, state.failedToInit]);

  const initialize = async (data) => {
      const {path, history, reason} = data;

      try {
        const { idToken } = await chAuth.getIDToken();

        const region = await chAuth.getRegion();
        if (region === 'us-east-1') {
          api.setRegion('us');
        } else {
          api.setRegion('eu');
        }

        let result = await api.initialize(idToken);
        result.push(idToken.payload);
        chAuthDispatch.setIsLoggedIn(true);

        const {UserAttributes, UserMFASettingList} = await chAuth.getAttributes();

        const attributes = {};
        UserAttributes.forEach((att) => {
          attributes[att.Name] = att.Value;
        });


        if (reason === 'NEW_USER') {
          await api.newUserSetup(
            {
              org: result[0].address.line1,
              planName: result[3].id,
              email: attributes.email,
              customerId:  result[0].id
            },
            idToken
          );
        }

        dispatch({ type: 'INIT', payload: result, user: {
          attributes,
          mfa_prefs: UserMFASettingList && UserMFASettingList.length ? UserMFASettingList[0] : 'NOMFA'
        } });
        setAuthorised(true);

        setTimeout(() => {
          if (path && history) {
            history.replace(path);
          } else if (history) {
            history.replace('/');
          }
          dispatch({ type: 'SET_IS_LOADING', payload: false });
          dispatch({ type: 'SET_READY_TRUE' });
        }, 300);

      } catch (error) {
        console.log(error);
        setErrorLoading(true);
      }
    
  }


  const signIn = async ({cognitoUser, history, from, Region, reason}) => {
    await initialize({path:from, history, reason, Region});
    dispatch({ type: 'SET_IS_LOADING', payload: false });
  }

  // initialise the FreshDesk widget
  useEffect(() => {

    const initialiseFDWidget = () => {
      window.FreshworksWidget('show');
    }

    if (window.FreshworksWidget) {
      initialiseFDWidget();
    }
  }, [state.accountInformation]);


  useEffect(() => {
    if(window.FreshworksWidget) {
      window.FreshworksWidget('hide', 'ticketForm', 
          ['custom_fields.cf_cust_id', 'custom_fields.cf_plan', 'custom_fields.cf_public_key', 'custom_fields.cf_current_domain']
        );
    }
  }, []);

  useEffect(() => {

    const populateFDWidget = () => {
      if (datadogRum){
          datadogRum.setUser({
          id: (state.accountInformation.get('customer').id) ? state.accountInformation.get('customer').id : ''
        })
      }

      if (window.FreshworksWidget) {

        let custom_fields = {}
        if (state.currentPlanId) {
          custom_fields.cf_plan = state.currentPlanId.slice(4);
        }
        if (state.accountInformation.get('customer').id) {
          custom_fields.cf_cust_id = state.accountInformation.get('customer').id;
        }
        if (state.publicKey) {
          custom_fields.cf_public_key = state.publicKey;
        }

        if (state.currentDomain && state.currentDomain.id) {
          custom_fields.cf_current_domain = state.currentDomain.id;
        }

        window.FreshworksWidget('identify', 'ticketForm', {
          email: state.user.attributes.email ? state.user.attributes.email : ''
        });
        window.FreshworksWidget('prefill', 'ticketForm', {
          custom_fields
        });

        window.FreshworksWidget('hide', 'ticketForm', 
          ['custom_fields.cf_cust_id', 'custom_fields.cf_plan', 'custom_fields.cf_public_key', 'custom_fields.cf_current_domain']
        );

      }
    }
      if (state.currentPlanId && state.user && state.user.attributes.email){
        populateFDWidget();
      }
  }, [state.accountInformation, state.currentDomain, state.currentPlanId, state.publicKey, state.user]);

  return (
    <>

      {state.ready && !state.failedToInit
        ?
        <MuiPickersUtilsProvider utils={LuxonUtils}>
          <Router>
            <ScrollToTop>

              <Routes
                state={state}
                api={api}
                user={user}
                signIn={signIn}
                signOut={signOut}
                loginReturnPath={loginReturnPath}
                authorised={authorised}
                dispatch={dispatch}
                />
            </ScrollToTop>
          </Router>
        </MuiPickersUtilsProvider>
        :
        null}


      <Backdrop className={classes.backdrop} open={state.failedToInit}>
        <Grid container justifyContent="center" alignItems="center" direction="column" spacing={4}>
          <Grid item>
            <img src="/crowdhandler-logo--strip-blue.svg" alt="CrowdHandler" />
          </Grid>
          <Grid item>
           <Typography variant="h5">There has been an issue.</Typography>
           <Typography>This page isn’t loading correctly. We’re aware of the issue and we’re working to fix it.</Typography>
          </Grid>
        </Grid>
      </Backdrop>

      <Backdrop className={classes.backdrop} open={state.isLoading}>
        <Grid container justifyContent="center" alignItems="center" direction="column" spacing={4}>
          <Grid item>
            <img src="/crowdhandler-logo--strip-blue.svg" alt="CrowdHandler" />
          </Grid>
          <Grid item>
            <CircularProgress color="inherit" />
          </Grid>
        </Grid>
      </Backdrop>
    </>
  )
}

export default App;
