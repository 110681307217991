import React from 'react';
import { ReactComponent as XCircle } from './feather/x-circle.svg';

import { SvgIcon } from '@material-ui/core';


export default function XCircleIcon(props) {
    return (
        <SvgIcon {...props} component={XCircle} className="feather"/>
    );
  }