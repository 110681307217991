import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';

const formatResult = (response, type) => {    

    let result = [];

    if(!isEmpty(response)){
        if (!isArray(response)){
            result = [response]
        } else {
            result = response;
        }
    }

    return response;
}

export default formatResult;