import React, { useState, useEffect, useRef } from "react";
import {
    Grid,
    DialogTitle,
    DialogContent,
    DialogActions,
    Dialog,
    FormLabel,
    FormControl,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Button,
    Tooltip,
    IconButton
} from '@material-ui/core';
import Chart from 'chart.js/auto'

import { DateTimePicker } from '@material-ui/pickers/'

import { DownloadIcon } from '../icons/';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { DateTime } from 'luxon';
import {getMultipleRoomsReportData} from '../lib/generateChartDownloadData';
import {chartBuilder} from '../lib/chartBuilder'
import Config from '../config';


const dateFormat = Config.DATE_FORMATS.FULL_WITH_YEAR;

const useStyles = makeStyles((theme) => ({

    downloadButton: {
        backgroundColor: '#ffffff',
        '&:hover': {
            backgroundColor: '#ffffff',
        },
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        zIndex: 1000
    }
}));


const ReportDownload = ({ state, api, rooms }) => {
    const classes = useStyles();
    const theme = useTheme();
    const [currentRooms, setCurrentRooms] = useState([])
    const [openDownloadDialog, setOpenDownloadDialog] = useState(false);
    const [downloadRooms, setDownloadRooms] = useState([]);

    let chart = useRef(null);
    let ctx = useRef(null);
    let updatedChartData = useRef(null);

    const [downloadTime, setDownloadTime] = React.useState({
        from: {
            date: DateTime.local().toUTC().minus({ minutes: 60 }).startOf('minute'),
            value: DateTime.local().toUTC().minus({ minutes: 60 }).startOf('minute').toFormat('yyyy/MM/dd HH:mm')
        },
        to: {
            date: DateTime.local().toUTC().startOf('minute'),
            value: DateTime.local().toUTC().startOf('minute').toFormat('yyyy/MM/dd HH:mm')
        },
    });

    const downloadDataHandler = () => {
        setOpenDownloadDialog(true);
    }

    const handleDownloadOk = async () => {
        let response = await getMultipleRoomsReportData(state.rooms, downloadRooms, downloadTime, api);

        let results = response.reduce((acc, res) => {
            acc = acc.concat(res.data.result);
            return acc;
        }, []);

        let chartdata = chartBuilder(results, rooms, theme, true);
        if (chart.current === null) {
            ctx.current = document.getElementById('report_chart');

            if(ctx.current) {
                chart.current = new Chart(ctx.current, chartdata);
            }
        } else {

            updatedChartData.current = chartBuilder(results, rooms, theme, true);

            chart.current.options.animation.duration = 1000;
                    chart.current.config.data.labels = updatedChartData.current.data.labels;

                    // update current datasets
                    let currentDataIds = [];

                    for (let n = 0; n < chart.current.config.data.datasets.length; n++) {
                        const dataset = chart.current.config.data.datasets[n];
                        currentDataIds.push(dataset.id)
                        for (let k = 0; k < updatedChartData.current.data.datasets.length; k++) {
                            const updatedDataSet = updatedChartData.current.data.datasets.filter((newDataset) => {
                                return newDataset.id === dataset.id;
                            });

                            if (updatedDataSet.length) {
                                // just update the data otherwise it reinitialises the graph
                                chart.current.config.data.datasets[n].data = updatedDataSet[0].data;
                            }
                        }

                    }

                    // find any new datasets to add
                    let remainingData = updatedChartData.current.data.datasets.reduce((acc, dataset) => {
                        if (currentDataIds.indexOf(dataset.id) === -1) {
                            acc.push(dataset);
                        }
                        return acc;
                    }, []);

                    if (remainingData.length) {
                        for (let k = 0; k < remainingData.length; k++) {
                            const dataset = remainingData[k];
                            chart.current.config.data.datasets.push(dataset);
                        }
                    }

                    // now find any existing datasets that are now no longer active and remove them
                    let updatedIds = updatedChartData.current.data.datasets.reduce((acc, dataset) => {
                        acc.push(dataset.id);
                        return acc;
                    }, []);
                    for (let i = 0; i < chart.current.config.data.datasets.length; i++) {
                        const dataset = chart.current.config.data.datasets[i];

                        if (updatedIds.indexOf(dataset.id) === -1) {
                            let ids = chart.current.config.data.datasets.map((dataset) => {
                                return dataset.id;
                            });

                            let position = ids.indexOf(dataset.id);

                            chart.current.config.data.datasets.splice(position, 1);
                        }
                    }

                    // updte the chart
                    chart.current.update();

        }
        setOpenDownloadDialog(false)
    }
    

    useEffect(() => {
        const getCurrentRooms = () => {
            let currentRooms = rooms.filter((room) => {
                return room.domainID === state.currentDomain.id;
            });
            setCurrentRooms(currentRooms);
    
            let roomsChecked = currentRooms.reduce((acc, room) => {
                acc[room.id] = true;
                return acc;
            }, {});
            setDownloadRooms(roomsChecked);
        }

        if (rooms) {
            getCurrentRooms();
        } 
      
    }, [rooms, state.currentDomain.id]);


    const handleDownloadCancel = () => {
        setOpenDownloadDialog(false)
    }

    const onDateChange = (date, value, field_name) => {
        setDownloadTime({
          ...downloadTime,
          [field_name] : {
            date: date,
            value: value
          }
        })
      
      };
      
    const handleChange = (event) => {
        setDownloadRooms(
            {
                ...downloadRooms,
                [event.target.name]: event.target.checked
            }
        )
    };

    return (
        <Grid container>
        <Grid item xs={4}>


                        <FormControl component="fieldset" className={classes.formControl}>
                            <FormLabel component="legend">Select Data To Download</FormLabel>
                            <FormGroup>
                                {
                                    currentRooms.map((room) => {
                                        return <FormControlLabel
                                            key={room.id}
                                            control={<Checkbox checked={downloadRooms[room.id]} onChange={handleChange} name={room.id.toString()} />}
                                            label={room.title}
                                        />
                                    })
                                }
                            </FormGroup>
                        </FormControl>

                    </Grid>

                    <Grid item xs={3}>
                        <FormControl fullWidth margin="normal" variant="outlined" >
                        <DateTimePicker
                            ampm={true}
                            inputVariant="outlined"
                            onChange={(date, value) => {
                                onDateChange(date, value, 'from')
                            }}
                            inputValue={downloadTime.from.value}
                            value={downloadTime.from.date}
                            label="From"
                            format={dateFormat}
                            />
                        {/* <KeyboardDateTimePicker
                                ampm={true}
                                variant="inline"
                                onChange={(date, value) => {
                                    onDateChange(date, value, 'from')
                                }}
                                inputValue={downloadTime.from.value}
                                value={downloadTime.from.date}
                                label="From"
                                format="yyyy/MM/dd HH:mm"

                            /> */}
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth margin="normal" variant="outlined" >
                        <DateTimePicker
                            ampm={true}
                            inputVariant="outlined"
                            onChange={(date, value) => {
                                onDateChange(date, value, 'to')
                            }}
                            inputValue={downloadTime.to.value}
                            value={downloadTime.to.date}
                            label="To"
                            format={dateFormat}
                            />

                            {/* <KeyboardDateTimePicker
                                ampm={true}
                                variant="inline"
                                onChange={(date, value) => {
                                    onDateChange(date, value, 'to')
                                }}
                                inputValue={downloadTime.to.value}
                                value={downloadTime.to.date}
                                label="To"
                                format="yyyy/MM/dd HH:mm"

                            /> */}
                        </FormControl>
                    </Grid>

                    <Grid item xs={2}>
                    <Button variant="contained" disableElevation onClick={handleDownloadOk} color="secondary">
                        Generate
                        </Button>
                    </Grid>
                    </Grid>
    )

}

export default ReportDownload;