import formatResult from './formatResult';
import Config from '../config';
import sortby from 'lodash/sortBy';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import indexOf from 'lodash/indexOf';
import groupBy from 'lodash/groupBy';
import { AssignRoomColors } from './';
import { DateTime } from 'luxon';


import {uniqBy} from 'lodash'
const plansAndPrices = require(`./plans_prices_${process.env.REACT_APP_ENV}.json`);

const { Map } = require('immutable');

const setCurrentDomain = (domains) => {

  if (!domains || !domains.length) {
    return {}
  }

  let currentDomain, filteredDomain;
  let currentDomainId;
  document.cookie.split(';').forEach((item)=>{
    if (item.trim().startsWith('selected_ch_domain_id=')){
      try {
        let parts = item.split('=')
        currentDomainId=parts[1];        
      } catch (error) {
        
      }
    }
  })
  if (currentDomainId) {
    filteredDomain = domains.filter((domain) => {
      return domain.id === currentDomainId;
    });
  }
  if (filteredDomain && filteredDomain.length) {
    currentDomain = filteredDomain[0];
  }
  if (isEmpty(currentDomain)) {
    currentDomain = domains[0];
  }

  return currentDomain;
}

const stateReducer = (state, action) => {


  switch (action.type) {
    case 'INIT':

      if (action.payload.length < 1) {
        return {
          ...state,
          failedToInit: true
        }
      }
      let failedToInit = false;
      let customer = action.payload[0];
      let plans = plansAndPrices;
      let domains = formatResult(action.payload[1]);
      let account = action.payload[2];
      let plan = action.payload[3];
      let upcomingInvoice = action.payload[4];
      let latestInvoice = action.payload[5];
      let idToken = action.payload[action.payload.length-1]; // always the last one
      let keyPublic = (idToken.keyPublic) ? idToken.keyPublic : '';
      let keyPrivate = (idToken.keyPrivate || idToken.chkey ) ? idToken.keyPrivate || idToken.chkey : '';


      
      if ((domains && domains.error) || (account && account.error) || (plan && plan.error)) {
        // bail;
        // signOut({ global: true });
        return {
          ...state,
          failedToInit: true
        }
      }

      let the_plan;
      let currentPlan;
      if (plan.id === 'pln_30Day') {
        currentPlan = plan;
      } else {
        currentPlan = plans.reduce((acc, s_plan) => {
          if (s_plan.metadata.planID === plan.id) {
            acc = s_plan;
          }
          return acc;
        }, {});
      }
       

      if (currentPlan && plan) {
        the_plan = {
          s_id: currentPlan.id,
          ...currentPlan,
          ...plan,
        }
      }

      let custom_plan = customer.custom_plan;

      let currentPlanId = (the_plan && the_plan.id) ? the_plan.id : undefined;
      let initialCurrentDomain = setCurrentDomain(domains);

      if (initialCurrentDomain && initialCurrentDomain.status > 200) {
        failedToInit = true;
      }

      if (!plan || plan.status > 200) {
        failedToInit = true;
      }
      
      if (!account || account.status > 200) {
        failedToInit = true;
      }

      let accountInformation = Map({
        subscription: (customer && customer.sub_object) ? customer.sub_object.id : false,
        priceId: (customer && customer.sub_object) ? customer.sub_object.plan.id : false,
        productId: (customer && customer.sub_object) ? customer.sub_object.plan.product : false,
        price: (customer && customer.sub_object) ? customer.sub_object.plan.amount : false,
        paymentMethod: null,
        customer: customer,
        currency: (customer) ? Config.CURRENCY_MAP[customer.currency] : false,
        cust_currency: customer.currency,
        user: action.user,
        cards: [],
        account,
        users: [],
        invoices: [],
        latest_invoice_paid: true,
        upcoming_invoice: upcomingInvoice,
        latest_invoice: latestInvoice,
      })
      
      let hasAnomalyDetection;
      let hasFingerprinting;
      if (the_plan) {
        hasAnomalyDetection = (the_plan.allowAnomolyDetection === 1) ? true : false;
        hasFingerprinting = (the_plan.allowFingerprint === 1) ? true : false;
      }

      let data = {
        ...state,
        user: action.user,
        plans: plans,
        plan: the_plan,
        currentPlanId,
        ready: false,
        bg: {
          bg: 'bg.jpg',
          bgColor: '#fff'
        },
        domains: domains,
        accountInformation: accountInformation,
        currentDomain: initialCurrentDomain,
        currentlySelectedDomain: (initialCurrentDomain) ? initialCurrentDomain.id : undefined,
        paGroups: Map([]),
        publicKey: keyPublic,
        privateKey: keyPrivate,
        recaptchaIsSet: (account.captchaKeyPublic && account.captchaKeyPrivate) ? true : false,
        failedToInit,
        custom_plan, 
        user_agents: [],
        sessions: [],
        hasAnomalyDetection,
        hasFingerprinting
      };
      return data;

    case 'SET_CURRENT_PLAN':

      let plan_to_update = action.payload;
      let the_new_plan;

      let temp_plan = state.plans.reduce((acc, s_plan) => {
        if (s_plan.metadata.planID === plan_to_update.id) {
          acc = s_plan;
        }
        return acc;
      }, {});

      if (temp_plan && plan_to_update) {
        the_new_plan = {
          s_id: temp_plan.id,
          ...temp_plan,
          ...plan_to_update,
        }
      }

      return {
        ...state,
        plan: the_new_plan,
        currentPlanId: the_new_plan.id
      };
    case 'UPDATE_ROOMS':
      let rooms_payload = action.payload;

      if (rooms_payload.status && rooms_payload.status !== 200) {
        rooms_payload = []
      }

      
      
      
      let updatedrooms = rooms_payload.map((room) => {
        var now = DateTime.local();
        let countdownTime = DateTime.fromISO(room.countdownStartsOn);
        let diff = now.diff(countdownTime, ['minutes']);
        room.isPropagated = diff.values.minutes >= 2 ? true: false;

        return room;
      });

      updatedrooms = AssignRoomColors(updatedrooms);
      
      return {
        ...state,
        rooms: updatedrooms
      };
    case 'UPDATE_ROOM':
      let room_payload = action.payload;
      
      let rooms = state.rooms.map((room) => {

        if (room_payload.id === room.id) {
          room = room_payload;
        }
        return room;
      });
      
      return {
        ...state,
        rooms: rooms
      };
    case 'UPDATE_ACCOUNT':
      state.accountInformation.set('account', action.payload);
      return state;
    case 'SET_TEMP_UPGRADE':
      return {
        temp_upgrade: action.payload,
        ...state
      };
    case 'ADD_TAX_ID':
      let taxIds = state.accountInformation.get('customer').tax_ids.data.map((taxid) => {
        return taxid;
      });

      taxIds.push(action.payload);

      state.accountInformation.get('customer').tax_ids.data = taxIds;
      return {
        ...state,
      };
    case 'DELETE_TAX_ID':
      let remainingTaxIds = state.accountInformation.get('customer').tax_ids.data.reduce((acc, taxid) => {
        if (taxid.id !== action.payload.id) {
          acc.push(taxid);
        }
        return acc;
      }, []);

      state.accountInformation.get('customer').tax_ids.data = remainingTaxIds;
      return {
        ...state,
      };
    case 'UPDATE_DOMAINS':
      let updateddomain = action.payload;
      let newDomains = state.domains.reduce((acc, domain) => {
        if (domain.id !== updateddomain.id) {
          acc.push(domain);
        }
        return acc;
      }, []);
      newDomains.push(updateddomain);


      let sorted = sortby(newDomains, 'id');


      return {
        ...state,
        domains: sorted
      };
      
    case 'DELETE_DOMAIN':
      let newddomains = formatResult(action.payload);

      if (newddomains.lenth < 1) {

      } 
      return {
        ...state,
        domains: newddomains
      };
    case 'SET_CURRENT_DOMAIN':
      return {
        ...state,
        currentDomain: action.payload
      };
    case 'ADD_DOMAINS':
      let adddomains = action.payload;
      return {
        ...state,
        domains: adddomains
      };
    case 'UPDATE_TEMPLATES':
      let payload = action.payload;

      if (payload.status === 500) {
        return {
          state
        }
      } else {
        let updatedTemplates = payload.map((template) => {
          return template;
        });
        return {
          ...state,
          templates: updatedTemplates
        };
      }
    case 'SET_UPDATING' :
      return {
        ...state,
        updating: action.payload
      };
    case 'UPDATE_SELECTED_DOMAIN':

      if(action.payload){
        document.cookie = `selected_ch_domain_id=${action.payload}; max-age=${60*60*24*365}; Secure`;
        let updatedDomain = state.domains.filter((domain) => {
          return domain.id === action.payload;
        });
        return {
          ...state,
          currentDomain: updatedDomain[0],
          currentlySelectedDomain: action.payload
        };
      } else {
        return {
          ...state,
          currentDomain: undefined,
          currentlySelectedDomain: undefined
        };
      }

    case 'SET_PLANS':
      return {
        ...state,
        plans: formatResult(action.payload)
      };
    case 'SET_READY_TRUE':
      return {
        ...state,
        ready: true,
      };
    case 'UPDATE_RECAPTCHA_IS_SET':
      return {
        ...state,
        recaptchaIsSet: action.payload,
      };
    case 'SET_IS_LOADING':
      let is_loading_payload = action.payload;

      return {
        ...state,
        isLoading: is_loading_payload
      };
    case 'SET_PRIORITY_ACCESS_GROUPS':

      let pagroups = formatResult(action.payload)

      let paGroups1 = groupBy(pagroups, 'roomID');
      for (const key in paGroups1) {
        if (paGroups1.hasOwnProperty(key)) {
          let group = paGroups1[key];
          paGroups1[key] = group;
        }
      }
      paGroups1 = Map(paGroups1);
   

      let testGroups = groupBy(pagroups, 'roomID');
      let roomsMap = Map(testGroups);


      return {
        ...state,
        paGroups: roomsMap,
      };

    case 'UPDATE_ACCOUNT_INFORMATION':

      let map1 = Map(action.payload);
      let map2 = state.accountInformation;

      let result;
      if (map1.size && map2.size) {
        result = map2.merge(map1);
      }

      if (result) {
        return {
          ...state,
          accountInformation: result,
        };
      } else {
        return {
          ...state
        }
      }

    case 'RESET_APP':

      let resetstate = Config.DEFAULT_STATE;
      // localStorage.removeItem('currentDomainId');

      return {
        ...resetstate,
        isLoading: false,
        ready: true
      }

    case 'SET_DOCS':
      let docs = action.payload;
      return {
        ...state,
        docs: docs
      }
    case 'SET_DOCS_PANEL':
      return {
        ...state,
        docsPanel: action.payload
      }
    case 'UPDATE_BACKGROUND':
      return {
        ...state,
        bg: action.payload
      }
    case 'SET_GLOBAL_MESSAGE':
      return {
        ...state,
        globalMessage: action.payload
      }
    case 'SET_PRIVATE_KEY':
      return {
        ...state,
        privateKey: action.payload
      }
    case 'SET_PUBLIC_KEY':
      return {
        ...state,
        publicKey: action.payload
      }
    case 'SET_DOWNGRADE_PENDING':
      return {
        ...state,
        downgradePending: action.payload
      }
    case 'SET_CLIENT_SECRET':
      return {
        ...state,
        client_secret: action.payload
      }
    case 'UPDATE_USER_AGENT_DATA':
      return {
        ...state,
        user_agents: uniqBy(state.user_agents.concat(action.payload), 'ua')
      }
    case 'SET_SESSIONS':
      return {
        ...state,
        sessions: action.payload
      }
    case 'UPDATE_SESSION':

    var upsert =  (arr, key, newval) => {

        var match = find(arr, key);

        if(match){
            var index = indexOf(arr, find(arr, key));
            arr.splice(index, 1, newval);
        }

        return arr;
    };
    let new_array = upsert([...state.sessions], {id:action.payload.id}, action.payload)
    

      return {
        ...state,
        sessions: new_array
      }
    case 'SET_SESSIONS_IPDATA':
      console.log(action.payload);
    // let ipdata_sessions = state.session.map((session, index) => {
    //     let match = find(action.payload, {ip});
    // })
      return {
        ...state,
        
      }
    case 'SET_HAS_ANOMOLY_DETECTION':
      return {
        ...state,
        hasAnomalyDetection: action.payload
      }
    case 'SET_HAS_CUSTOM_PLAN':
      return {
        ...state,
        custom_plan: action.payload
      }
    default:
      throw new Error();
  }
};

export default stateReducer;