import React from "react"
import { HelpCircleIcon } from "icons";
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
    toolTipHelp: {
        display: 'flex',
        '& .MuiSvgIcon-root': {
            marginLeft: '0.2rem',
            color: theme.palette.primary.main,
            width: '1rem',
            height: '1rem',
        }
    }
}));

const ToolTipHelp = ({ children, helpTitle }) => {
    const classes = useStyles();

    return (
        <div className={classes.toolTipHelp}>
            {children}
            <Tooltip title={helpTitle}>
                <div>
                    <HelpCircleIcon />
                </div>
            </Tooltip>
        </div>
    )
}


export default ToolTipHelp;
