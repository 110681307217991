import React from "react";
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import { Grid, Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
   
    margin: {
        margin: theme.spacing(0),
        marginLeft: 'auto',
        '& .feather': {
            marginLeft: '10px'
        }
    }
}));


export default function Home() {

    const classes = useStyles();
   

    return (
        <>
        <Grid>
        <Button to="/login" size="small" variant="contained" component={RouterLink} color="primary" aria-label="logout" className={classes.margin}>
            Login
        </Button>
        
        </Grid>
        </>
    );
}