import React from "react";
import {
    Grid,
} from '@material-ui/core';
import OnboardStepper from '../OnboardStepper';
import {TypeWriterComponent} from '../../';

const WaitForImplementation =  () => {
   

    return (
      <Grid>


      <OnboardStepper step={2}/>

      <TypeWriterComponent strings={[
          `Watch your session go through.`,
          `Your session will convert from ‘waiting’ to ‘active’ as you are put through the waiting room.`,
          'Watch the graph to see the change. (This will take up to one minute)',
        ]}
      />




      

  </Grid>

)

}

export default WaitForImplementation;