const handleSignOut = () => {
  const items = { ...localStorage };
  const keys = [
    'identity-providers',
    'identity-id',
    'CognitoIdentityServiceProvider'
  ];
  for (const key in items) {
    if (Object.hasOwnProperty.call(items, key)) {

      let remove = false;
      for (let i = 0; i < keys.length; i++) {
        const ls_key = keys[i];
        if (key.indexOf(ls_key) !== -1) {
          remove = true;
        }
      }
      if (remove) {
        localStorage.removeItem(key);
      }
    }
  }
}

export default handleSignOut;