import React from "react";
import {
    Typography,
    Grid,
    Paper,
    CardContent,
    CardHeader,
    CardActionArea,
    CardMedia,
    CardActions,
    Link,
    Button

} from "@material-ui/core";


const DomainCustomOnboarding = () => {
    return (

        <Grid container spacing={4}  >
            <Grid item xs={12}>

            <Paper elevation={0}>
       
          <Typography variant="body2" color="textSecondary" component="p">
          To begin integrating a custom solution using the CrowdHandler API check out our guide:<br/><Link target="_blank" href="https://www.crowdhandler.com/docs/80000180240-stepping-through-a-basic-server-side-api-integration">
          Stepping through a basic server-side API integration
                    </Link>
          </Typography>
       
    </Paper>

            </Grid>
        </Grid>
    )

}

export default DomainCustomOnboarding;