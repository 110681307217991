import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import { LinearProgress } from '@material-ui/core';

const BorderLinearProgress = withStyles((theme) => ({
    root: {
        position:'absolute',
        top: 0,
        left: 0,
        width: '100%',
    },
    colorPrimary: {
      backgroundColor: 'rgb(186, 214, 228)',
    },
    bar: {
        backgroundColor: 'rgb(213, 228, 236)',
    },
   
  }))(LinearProgress);


const SavingBar = ({saving, ...rest}) => {


    return (
        <>
        {(saving)
            ?
            <BorderLinearProgress {...rest}/>
            :
            null
        }
        </>
    )

}

export default SavingBar;