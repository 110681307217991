import React, { useState, useEffect, useCallback, useRef } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import update from 'immutability-helper'
import { PAGroup, ToolTipHelp } from './'
import PlusCircleIcon from '../icons/PlusCircleIcon';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import SavingBar from './SavingBar';


import { ArrowUpCircleIcon, ArrowDownCircleIcon } from '../icons';
import {
  Typography,
  Grid,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Card,
  CardHeader,
  CardContent,
  FormControl,
  MenuItem,
  OutlinedInput,
  FormHelperText,
  InputLabel,
  Select,
  Checkbox,
  Switch,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  table: {
    // maxWidth: 440,
  },
  paper: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(2),
    position: 'relative'
  },
  cell: {
    wordWrap: 'break-word',
    padding: '8px'
  },
  cellFirst: {
    wordWrap: 'break-word',
    padding: '8px',
    paddingLeft: 0
  },
  createGroupButton: {
    marginTop: theme.spacing(1)
  },
  cancelGroupButton: {
    marginRight: theme.spacing(1)
  },
  addRowForm: {
    marginTop: 8,
    '& .MuiGrid-item': {
      paddingTop: 0,
      paddingBottom: 0,
    }
  },
  cardHeader: {
    paddingTop: 0,
    paddingBottom: 0,
    '& .MuiCardHeader-action': {
      marginTop: 0
    }
  }
}));

export default function PAGroupsTable(props) {
  let { groups, name, room, addGroup, deleteGroup, api, dispatch, setSnackbarMessage, setSnackbarOpen, setAlertBarStatus, state } = { ...props };

  const classes = useStyles();
  const theme = useTheme();

  // Control state for open/close panel
  const [open, setOpen] = useState(room.open);

  let [isSaving, setIsSaving] = useState(false)


  // Priority Access Groups fo this table
  const [pagroups, setPaGroups] = useState(groups)

  // Default values when creating the Add Group form
  let defaultValues = {
    groupType: 'single-use',
    groupName: '',
    preActivationAccess: 0
  };
  
    useEffect(() => {
        setOpen(room.open);
    }, [room.open]);

  /**
 * Opens the panels when the number of groups changes.
 */
  useEffect(() => {
    if (pagroups.length > 0) {
      setOpen(true);
    }
  }, [pagroups.length]);

  useEffect(() => {
    const clearDefaultValues = () => {
      defaultValues.groupName = '';
    }
    clearDefaultValues();
  }, [defaultValues.groupName, groups]);

  /**
   * Updates a group using PUT.
   * Passed as a prop to `<PAGroupsTable/>`
   * @param {Object} group The group data 
   */
  const updateGroup = async (group) => {

    if(isMountedRef.current){
      setIsSaving(true);
      let response = await api.updateGroup(group);

      if (response.error) {
        setIsSaving(false);
        setSnackbarMessage(`${response.error}`);
        setAlertBarStatus('error')
        setSnackbarOpen(true);
      } else {
        dispatch({ type: 'SET_PRIORITY_ACCESS_GROUPS', payload: response })
        setIsSaving(false);
        setSnackbarMessage(`${group.name} Priority Group Updated`);
        setAlertBarStatus('success')
        setSnackbarOpen(true);
      }
    }
  }



  /**
   * Setting up the Formik validation and submit handler
   */
  const formik =  useFormik({
    initialValues: defaultValues,
    validationSchema: Yup.object({
      groupName: Yup.string().required('Required'),
      groupType: Yup.string().required('Required')
    }),
    onSubmit: async (values) => {

      let formattedData = {
        roomID: room.id,
        name: values.groupName,
        type: values.groupType,
        priority: pagroups.length,
        preActivationAccess: values.preActivationAccess
      }

      let result = addGroup(formattedData);
      if (result) {
        formik.resetForm();
      }
    },
  });

  /**
   * Event handler for opening/closing the panel
   */
  const handleOpen = () => {
    setOpen(!open);
  }



  /**
   * Store the groups in state
   */
  useEffect(() => {
    setPaGroups(groups);
  }, [groups]);

  let isMountedRef = useRef(true);

  const updatePriorityOrderHandler = (item) => {
    const callUpdate = async () => {
      if(isMountedRef.current){
        setIsSaving(true);
        let result = await api.updateGroupsOrder(pagroups);
        dispatch({ type: 'SET_PRIORITY_ACCESS_GROUPS', payload: result[result.length - 1].data.result })
        setIsSaving(false);
      }
    }

    callUpdate();

    return (() => {
      isMountedRef.current= false;
    })

  }


  /**
   * Handle the position of the PA Groups when dragged
   * Dragging controls are set on the PAGroup
   */
  const movePAGroup = useCallback(
    (dragIndex, hoverIndex) => {
      // get the PAGroup that is being dragged
      const dragPAGroup = pagroups[dragIndex];

    /**
     * https://github.com/kolodny/immutability-helper
     * {$splice: array of arrays} for each item in arrays call splice() on the target with the parameters provided by the item.
     * Note: The items in the array are applied sequentially, so the order matters.
     * The indices of the target may change during the operation.
     */
    let groups = update(pagroups, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, dragPAGroup],
      ],
    });

    // Set the Priority value of the PAGroups
    groups = groups.map((group, index) => {
      if (group) {
        group.priority = index + 1;
      }
      return group;
    })
    setPaGroups(groups);
    },
    [pagroups],
  )


  /**
   * Method to render the PAGroup from the `map()` in the JSX return
   * @param {{id: number}} group 
   * @param {number} index 
   */
  const renderPAGroup = (group, index) => {
    return (
      <PAGroup
        key={group.id}
        index={index}
        id={group.id}
        group={group}
        name={group.name}
        code={group.code}
        roomID={room.id}
        movePAGroup={movePAGroup}
        deleteGroup={deleteGroup}
        classes={classes}
        updateGroup={updateGroup}
        updatePriorityOrderHandler={updatePriorityOrderHandler}
        setIsSaving={setIsSaving}
      />
    )
  }

  const getDomanTitle = (room) => {

    let domain = state.domains.filter((domain) => {
      return domain.id === room.domainID
    });

    if (domain.length) {
      return <Typography style={{
        fontSize: '12px',
        display: 'inline-block',
        marginBottom: '6px',
        color: theme.palette.grey[500]
      }}>{domain[0].name}</Typography>;
    } else {
      return '';
    }

  }

  const getCardHeader = () => {

    if (!open) {
      return (
        <>
          <Grid container justifyContent="space-between" alignItems="center"  style={{padding: '6px 0 12px'}}>
            <Grid item>
              {getDomanTitle(room)}
              <Typography variant="h6">{name}</Typography>
            </Grid>
            <Grid item style={{ marginRight: 10 }}>
              <Typography variant="subtitle2">{pagroups.length} group{(pagroups.length === 1) ? '' : 's'}</Typography>
            </Grid>

          </Grid>
        </>
      )
    }
    return (<>
      {getDomanTitle(room)}
      <Typography variant="h6">{name}</Typography>
    </>);
  }

  const addCodeStyle = {paddingTop: '24px'}
  

  return (
    <>
      <Grid container component={Card} className={classes.paper}>
        <SavingBar saving={isSaving} />
        <Grid item xs={12}>
          <CardHeader className={classes.cardHeader} disableTypography title={getCardHeader()} onClick={handleOpen} action=
            {
              <IconButton aria-label="Open and close the panel">
                {
                  (open) ? <ArrowUpCircleIcon /> : <ArrowDownCircleIcon />
                }
              </IconButton>
            }
          />
          <Collapse in={open} timeout="auto" unmountOnExit>
          <SavingBar saving={isSaving} />
            <CardContent>

              
                  <Typography variant="body1" color="primary">Groups will be prioritised based on the order in this table. Drag the rows to alter the priority order.</Typography>
                  <TableContainer component='div'>
                    <Table className={classes.table} aria-label={`List of priority access codes for ${name}`} >
                      <TableHead>
                        <TableRow>
                          <TableCell className={classes.cellFirst} component="th" scope="row">Name</TableCell>
                          <TableCell className={classes.cell}>
                            <ToolTipHelp helpTitle="Allow priority codes to be used before the queue is active">
                              Pre Activation
                            </ToolTipHelp>
                            </TableCell>
                          <TableCell className={classes.cell}>Type</TableCell>
                          <TableCell className={classes.cell}>Code</TableCell>
                          <TableCell align="center" className={classes.cell}>Delete</TableCell>
                          <TableCell align="right" className={classes.cell}></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody >
                      {(pagroups.length)
                        ?
                       
                        pagroups.map((group, i) => renderPAGroup(group, i))
                        
                        :
                        null
                      }


                        <TableRow>
                          <TableCell className={classes.cellFirst} component="th" scope="row" style={addCodeStyle}>
                            <FormControl fullWidth margin="dense" variant="outlined">
                              <InputLabel required htmlFor={`groupName_${room.id})`}>Name</InputLabel>
                              <OutlinedInput
                                id={`groupName_${room.id})`}
                                name="groupName"
                                {...formik.getFieldProps('groupName')}
                                labelWidth={44}
                                placeholder="Name"
                                aria-label="name"
                                aria-describedby="name"
                              />
                              {formik.touched.groupName && formik.errors.groupName ? <Typography variant="subtitle2" color="error">{formik.errors.groupName}</Typography> : null}
                              <FormHelperText>Name of the Priority Access Group</FormHelperText>
                            </FormControl>
                          </TableCell>
                          <TableCell className={classes.cell} style={addCodeStyle}>
                            <FormControl margin="dense" variant="standard">
                              <Switch
                                  checked={formik.values.preActivationAccess === 1 ? true : false}
                                  onChange={(e) => {
                                    formik.setFieldValue('preActivationAccess', e.target.checked ? 1 : 0);
                                  }}
                                  name="preActivationAccess"
                                  inputProps={{ 'aria-label': 'Pre Activation' }}
                              />
                            </FormControl>
                          </TableCell>
                          <TableCell className={classes.cell} colSpan={3} style={addCodeStyle}>
                            <FormControl fullWidth margin="dense" variant="standard">
                              <InputLabel htmlFor={`groupType_${room.id})`}>Code Type</InputLabel>
                              <Select
                                name="groupType"
                                value={formik.values.groupType}
                                {...formik.getFieldProps('groupType')}
                              >
                                <MenuItem value='single-use'>Single Use</MenuItem>
                                <MenuItem value='multi-use'>Multi Use</MenuItem>
                              </Select>
                              {formik.errors.groupType ? <Typography color="error" variant="subtitle2">{formik.errors.groupType}</Typography> : null}
                              {(formik.values.groupType === 'single-use')
                                ?
                                <FormHelperText>Use this type to create a number of codes that have a single use.</FormHelperText>
                                :
                                <FormHelperText>Use this type to create a single code that has mutiple uses.</FormHelperText>
                              }
                            </FormControl>
                          </TableCell>
                          
                          <TableCell align="right" className={classes.cell} style={addCodeStyle}>
                            <IconButton type="submit" onClick={formik.handleSubmit}>
                              <PlusCircleIcon style={{ strokeWidth: 2, color: theme.palette.success.main }} />
                            </IconButton>
                          </TableCell>
                        </TableRow>



                      </TableBody>
                    </Table>
                  </TableContainer>
               
            </CardContent>
          </Collapse>
        </Grid>
      </Grid>
    </>
  );
}