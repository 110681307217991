import React, { useState, useEffect } from "react";
import {
    Grid,
    TableContainer,
    Table,
    TableRow,
    TableCell,
    TableBody,
    Button
} from '@material-ui/core';
import { DateTime } from "luxon";
import { Link as RouterLink } from 'react-router-dom';

import IPDataDialog from '../IPDataDialog';
import {IPData} from '../';

import buildSessionsQueryString from "lib/buildSessionsQueryString";


import EnhancedTableHead from '../EnhancedTableHead';
import {
    getComparator,
    stableSort
} from '../../lib/tableComparators'


const IPRecommendations = ({ recommendations, handleRecommendedAction, state }) => {

    const [drawerInfo, setDrawerInfo] = useState();
    const [openDialog, setOpenDialog] = useState(false);
    const [processedRecommendations, setProcessedRecommendations] = useState([]);

    const handleDialogClose = (event, reason) => {
        setOpenDialog(false);
        setTimeout(() => {
            setDrawerInfo(null);
        }, 500);
      }

    
    const [processed, setProcessed] = useState(true);
    const [orderBy, setOrderBy] = useState('sessions');
    const [order, setOrder] = useState('desc');
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const getDateNow = () => {
        return DateTime.fromISO(DateTime.now()).toLocaleString({ month: 'short', day: 'numeric', year: 'numeric' });
    }

    const blockIPAddress = (block) => {
        addToList(block, 'block');
    }
    const ignoreIPAddress = (block) => {
        addToList(block, 'ignore');
    }

    const addToList = async (block, list) => {
        // setProcessed(false);
        const values = {
            address: block.address,
            domainID: block.domainID,
            list: list,
            note: `${block.note} - Recommended ${getDateNow()}`
        }
        let processed = await handleRecommendedAction(values);

        // console.log({processed});
        setProcessed(processed)
    }


    const headCells = [
        { id: 'address', numeric: false, disablePadding: false, label: 'Range' },
        { id: 'sessions', numeric: false, disablePadding: false, label: 'Sessions' },
        { id: 'note', numeric: false, disablePadding: false, label: 'Note' },
        { id: 'started', numeric: false, disablePadding: false, label: 'Started' },
        { id: 'latest', numeric: false, disablePadding: false, label: 'Latest' },
        { id: 'recommendation', numeric: false, disablePadding: false, label: 'Recommendation' },
        { id: 'action', numeric: false, disablePadding: false, label: '' },
    ];

    useEffect(() => {

        let processed = recommendations.map((block) => {
            if (state.hasAnomalyDetection) {

                let address_parts = block.address.split('.');
                let address = [];
                for (let i = 0; i < 3; i++) {
                    address.push(address_parts[i]);
                }

                const query_state = {
                    search: address.join('.'),
                    domainID: block.domainID,
                    archivedUsers: 1,
                    includeActiveUsers: 2,
                    pageSize: 1000
                }

                block.link = {
                    path : `/rooms/sessions`,
                    query_state
                }
            }

            return block;
        })

        setProcessedRecommendations(processed);
    }, [recommendations, state.hasAnomalyDetection]);

    
    
   
    return (
        <Grid container spacing={0}>
            <Grid container item>
                <Grid container direction="column" justifyContent="center">
                    <TableContainer>
                        <Table>
                            <EnhancedTableHead
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                                headCells={headCells}
                            />

                            <TableBody>

                                {stableSort(processedRecommendations, getComparator(order, orderBy))
                                    .map((block, index) => (

                                        <TableRow key={index}>
                                            <TableCell>
                                                    <Grid container spacing={2} alignItems="center">
                                                        
                                                    <Grid item>
                                                        {block.address} 

                                                    </Grid>
                                                    <Grid item onClick={()=>{

                                                        let info = {
                                                            ...block.ip_data,
                                                            range: block.address,
                                                        }
                                                            setDrawerInfo(info);
                                                            setOpenDialog(true);
                                                        }}>
                                                        <IPData ip_data={block.ip_data} />
                                                    </Grid>
                                                    </Grid>
                                                
                                                


                                            </TableCell>
                                            <TableCell>

                                                {state.hasAnomalyDetection && block.link
                                                ?
                                                <RouterLink 
                                                to={{ 
                                                    pathname: block.link.path, 
                                                    state: block.link.query_state 
                                                  }}>{block.sessions}</RouterLink>
                                                :
                                                    block.sessions
                                                }
                                            </TableCell>
                                            <TableCell>
                                                {block.note}
                                            </TableCell>
                                            <TableCell>{DateTime.fromISO(block.started).toLocaleString({ month: 'short', day: 'numeric', hour: "numeric", minute: "numeric", second: 'numeric', hour12: true, })}</TableCell>
                                            <TableCell>{DateTime.fromISO(block.latest).toLocaleString({ month: 'short', day: 'numeric', hour: "numeric", minute: "numeric", second: 'numeric', hour12: true, })}</TableCell>
                                            <TableCell>
                                                <Button disableElevation color="secondary" variant="contained" size="small"
                                                    disabled={!processed}
                                                    onClick={() => {
                                                        setProcessed(false);
                                                        blockIPAddress(block);
                                                    }}
                                                    >
                                                    Block</Button>
                                            </TableCell>
                                            <TableCell>
                                                <Button disableElevation variant="contained" size="small"
                                                    disabled={!processed}
                                                    onClick={() => {
                                                        ignoreIPAddress(block);
                                                    }}
                                                >Ignore</Button>
                                            </TableCell>
                                        </TableRow>

                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                </Grid>
            </Grid>

            <IPDataDialog handleDialogClose={handleDialogClose} openDialog={openDialog} drawerInfo={drawerInfo}/>

        </Grid>
    )
}


export default IPRecommendations;