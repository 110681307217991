import React, {useEffect, useState, useRef} from "react";

import CreatePreviewUrl from '../../lib/CreatePreviewUrl';

import './TemplatePreview.css';

const TemplatePreview = ({ computedMatch, dispatch, api, state, ...rest }) => {


    const mounted  = useRef(true);
    const gettingTemplates  = useRef(false);
    const gettingRooms  = useRef(false);

    let [templateURL, setTemplateURL] = useState();
    let [iframeSrc, setIframeSrc] = useState();


    useEffect(() => {
        const getParams = () => {
            if(!state.templates) {
                const getTemplates = async () => {
                    if(mounted.current && !gettingTemplates.current){
                        gettingTemplates.current = true;
                        let templates = await api.getTemplates();
                        dispatch({ type: 'UPDATE_TEMPLATES', payload: templates });
                    }
                }
                getTemplates();
                return;
            }

            if(!state.rooms) {
                const getRooms = async () => {
                    if(mounted.current && !gettingRooms.current){
                        gettingRooms.current = true;
                        let rooms = await api.getRooms();
                        dispatch({ type: 'UPDATE_ROOMS', payload: rooms });
                    }
                }
                getRooms();
                return;
            }

            let roomId = computedMatch.params.roomId;
            let templateId = computedMatch.params.templateId;

            let template = state.templates.filter((template) => {
                return template.id === templateId;
            })
            let room_array = state.rooms.filter((room) => {
                return room.id === roomId;
            })

            if (template[0] && room_array[0]) {
                setTemplateURL(template[0].url);
                let room_data = CreatePreviewUrl(room_array[0], state.accountInformation.get('account').logoURL);
                setIframeSrc((preview) => {
                    return `${templateURL}?preview=1&room-data=${room_data}`;
                });
            } 

        }
        if(computedMatch.params){
            getParams();
        }


    }, [state.templates, state.rooms, computedMatch.params, api, dispatch, state.accountInformation, templateURL]);

    return (
        <>
                {iframeSrc
                ?
                <iframe id="Iframe" title="preview" scrolling="yes"
                style={{}} /* need this to add to on resize */
                src={iframeSrc}
                />
                :
                null
                }
        </>
    )

};


export default TemplatePreview;