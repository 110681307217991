import React, { useState, useEffect } from "react";
import { createHash } from 'crypto-browserify';
import {
  Typography,
  Grid,
  Paper,
  FormControl,
  Link,
  OutlinedInput,
  InputAdornment,

} from "@material-ui/core";

import { ErrorSnackBar, CopyButton } from './';


const DomainAkamaiOnboarding = ({ privatekey }) => {



  let [key, setKey] = useState('');

  const [open, setOpen] = React.useState(false);

  const [alertBarStatus, setAlertBarStatus] = useState('success')

  const [message, setMessage] = useState('');
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const closeErrorSnackbar = () => {
    setOpenErrorSnackbar(false)
  }


  useEffect(() => {
    const createKey = async () => {

      let result = createHash('sha256').update(privatekey).digest('hex');
      setKey(result);

    }

    createKey()

  }, [privatekey]);

  const [snackPack, setSnackPack] = useState([]);
  const [messageInfo, setMessageInfo] = useState(undefined);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  useEffect(() => {
    if (snackPack.length && !messageInfo) {
      // Set a new snack when we don't have an active one
      setMessageInfo({ ...snackPack[0] });
      setSnackPack((prev) => prev.slice(1));
      setOpenSnackBar(true);
    } else if (snackPack.length && messageInfo && open) {
      // Close an active snack when a new one is added
      setOpenSnackBar(false);
    }
  }, [snackPack, messageInfo, open]);

  const handleSnackBarClick = (message) => {
    setSnackPack((prev) => [...prev, { message, key: new Date().getTime() }]);
  };

  const handleSnackBarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackBar(false);
  };


  return (

    <Grid container spacing={4}  >

      <Grid item xs={12}>

        <Typography variant="body2" color="textSecondary" component="p">
          To install the Akamai integration check out our guide:<br /><Link target="_blank" href="https://www.crowdhandler.com/docs/80001022132-akamai-integration-getting-started">
            Akamai Integration - Getting Started
          </Link>
        </Typography>

        </Grid>
        <Grid item xs={12}>


        <FormControl fullWidth margin="dense" variant="outlined">


          <Typography gutterBottom>CrowdHandler Private Key for Akamai</Typography>
          <OutlinedInput value={key} readOnly
            endAdornment={
              <InputAdornment position="end">
                <CopyButton
                  color="primary"
                  text={key}
                  onCopy={() => {
                    handleSnackBarClick(`Akamai Private Key copied`);
                  }}
                />
              </InputAdornment>
            }
          ></OutlinedInput>
        </FormControl>


      </Grid>



      <ErrorSnackBar
        status="success"
        open={openSnackBar}
        key={messageInfo ? messageInfo.key : undefined}
        autoHideDuration={2000}
        onClose={handleSnackBarClose}
        message={messageInfo ? messageInfo.message : undefined}
      />
    </Grid>
  )

}

export default DomainAkamaiOnboarding;