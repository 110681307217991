import React, { useCallback } from "react";
import {
    TableCell,
    TableRow,
  } from '@material-ui/core';

  import ReCaptchaSwitch from './ReCaptchaSwitch';


const FraudSliderRow = ({room, saving, state, setSaving, api, dispatch}) => {

    const update = useCallback(async (values) => {

      let payload = {
        ...room,
        ...values
      }
      setSaving(true);
      let result = await api.addRoom(payload, 'put');
      if (result) {
        dispatch({'type':'UPDATE_ROOMS', payload: result})
      }

    }, [api, dispatch, room, setSaving]);


    return (
      <TableRow>
        <TableCell>{room.title}</TableCell>
        <TableCell style={{width: '20px'}}>
            <ReCaptchaSwitch saving={saving} update={update} room={room} recaptchaIsSet={state.recaptchaIsSet}/>
            </TableCell>
        {/* <TableCell><FraudSlider room={room.id} name="pause" update={update}/></TableCell>
        <TableCell><FraudSlider room={room.id} name="delete" update={update}/></TableCell> */}
        {/* <TableCell><Button onClick={() => {saveRoom(room)}}>Save</Button></TableCell> */}
      </TableRow>
    );

}


export default FraudSliderRow;