import React, { useState, useRef, useEffect, useContext } from "react";
import {AuthContext} from '../../providers/AuthProvider';

import queryString from 'query-string';
import {
    useHistory,
    useLocation
} from "react-router-dom";
import {
    Container,
    Grid,
    Button,
    FormControl,
    InputLabel,
    OutlinedInput,
    Typography,
    FormHelperText,
    InputAdornment,
    IconButton,
    CircularProgress,
    Backdrop
} from "@material-ui/core";
import { datadogRum } from '@datadog/browser-rum';

import { makeStyles } from '@material-ui/core/styles';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
// import PropTypes from 'prop-types';
import {EyeIcon, EyeOffIcon} from '../../icons';
import ErrorSnackBar from '../../components/ErrorSnackBar';

import RequestVerificationLink from '../../components/RequestVerificationLink'

var Buffer = require('buffer/').Buffer;

const useStyles = makeStyles((theme) => ({
    verificationPanel: {
        marginBottom: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    resendCodePanel: {
        marginTop: theme.spacing(2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: theme.palette.primary.main,
        backgroundColor: '#fff',
      },
}));


function Verify({ state, signIn, signOut, authorised, api, dispatch, ...rest }) {


    // check that there a logged in user and if there is then first log them out;
    const chAuth = useContext(AuthContext);

    const history = useHistory();
    const location = useLocation();
    const classes = useStyles();

    // Store a ref so we can clear up when the component is unmounted
    const isMountedRef = useRef(null);

    let local_username = sessionStorage.getItem('username');

    let [displayRefreshMessage, setDisplayRefreshMessage] = useState(false);

    let [showNewPassword, setShowNewPassword] = useState(false);
    let [showVerification, setShowVerification] = useState(false);
    let [message, setMessage] = useState('');
    let [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
    let [alertBarStatus, setAlertBarStatus] = useState();
    let [showAlreadyConfirmed, setShowAlreadyConfirmed] = useState(false);

    let [authenticating, setAuthenticating] = useState(false);
    let { from } = location.state || "/" ;
    let [fields, setFields] = useState({
        username: (local_username) ? local_username : '',
        password: '',
        newPassword: '',
        code: ''
    });

    let [showPassword, setShowPassword] = useState(false);

    // get any params so we can attempt an auto login
    let params = queryString.parse(location.search)

    let [checkingNewUser, setCheckingNewUser] = useState(true);
    let [loginLinkExpired, setLoginLinkExpired] = useState(false);

    let [processing, setProcessing] = useState(false);


    // Redirect to dashboard if already authorised
    useEffect(() => {
       
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {

        if(authorised){
            history.replace('/');
        } else {

            setAuthenticating(true);
            if (params.u && params.p) { // attempt to auto login the user
    
                if (!params.r) {
                    params.r = 'us';
                }
                
                let auto_login = async (new_username, new_password, region) => {
                    try {
    
                        dispatch({ type: 'SET_IS_LOADING', payload: true });
                        let {ChallengeName, error} = await chAuth.authenticateUser({
                            username : new_username,
                            password : new_password,
                            region
                        });
    
                        if (error) {
    
                            if(error.name === 'UserLambdaValidationException') {
    
                                setMessage('There has been an error. Please contact support@crowdhandler.com')
                                setAlertBarStatus('error');
                                setOpenErrorSnackbar(true);
                                dispatch({ type: 'SET_IS_LOADING', payload: false });
                                setCheckingNewUser(false)
                                datadogRum.addError(error, {new_username, region});
    
                            }
                            if (error.name === 'NotAuthorizedException') {
                                if (error.message === 'Password attempts exceeded') {
    
                                    setMessage('Verification attempts exceeded. Please try again later')
                                    setAlertBarStatus('error');
                                    setOpenErrorSnackbar(true);
                                    dispatch({ type: 'SET_IS_LOADING', payload: false });
                                    setCheckingNewUser(false)
                                    setShowVerification(true)
                                }
                                if (error.message === 'Incorrect username or password.') {
    
                                    setMessage('Your account has already been verified')
                                    setAlertBarStatus('error');
                                    setOpenErrorSnackbar(true);
                                    dispatch({ type: 'SET_IS_LOADING', payload: false });
                                    setCheckingNewUser(false)
                                    setShowAlreadyConfirmed(true)
                                    setShowVerification(false)
                                }
                                if (error.message === 'Invalid session for the user, session is expired.') {
    
                                    setMessage('Your session has expired')
                                    setAlertBarStatus('error');
                                    setOpenErrorSnackbar(true);
                                    dispatch({ type: 'SET_IS_LOADING', payload: false });
                                    setCheckingNewUser(false)
                                    setShowAlreadyConfirmed(true)
                                    setShowVerification(false)
                                }
                            }
                        }
    
    
                        if (ChallengeName) {
                            if (ChallengeName === 'NEW_PASSWORD_REQUIRED') {
                                    setAuthenticating(false);
                                    setCheckingNewUser(false)
                                    setShowNewPassword(true);
                                    dispatch({ type: 'SET_IS_LOADING', payload: false });
                                    dispatch({ type: 'SET_READY_TRUE' });
                            }
                        }
    
                    } catch (error) {
                        datadogRum.addError(new Error('Auto Login Error'), {
                            ...error
                        });
    
                        if (error.code === 'NotAuthorizedException') {
    
                            if (error.message === 'Password attempts exceeded') {
    
                                setMessage('Password attempts exceeded. Please try again later')
                                setAlertBarStatus('error');
                                setOpenErrorSnackbar(true);
    
                            }
                            if (error.message === 'Incorrect username or password.') {
                                setMessage('Incorrect username or password.')
                                setAlertBarStatus('error');
                                setOpenErrorSnackbar(true);
                            }
    
                            setLoginLinkExpired(true);
                            dispatch({ type: 'SET_IS_LOADING', payload: false });
                            setCheckingNewUser(false)
                        }
                    }
                }
                // decode the base64 strings
                let new_username = Buffer.from(params.u, 'base64');
                let new_password = Buffer.from(params.p, 'base64');
                new_username = new_username.toString('ascii');
                new_password = new_password.toString('ascii');
    
                setFields({
                    ...fields,
                    username: new_username,
                    password: new_password
                })
    
                auto_login(new_username, new_password, params.r);
                
            } else {
                dispatch({ type: 'SET_IS_LOADING', payload: false });
                setCheckingNewUser(false)
                setAuthenticating(false);
                history.replace('/');
            }
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
      };
    
      const handleMouseDownPassword = (event) => {
        event.preventDefault();
      };

    const formik = useFormik({
        enableReinitialize: true,
        isInitialValid: false,
        initialValues: {
            username: fields.username,
            newPassword: '',
        },
        validationSchema: Yup.object({
            newPassword: Yup.string()
                .required('A password is required')
                .min(8, "Password requires a minimum of 8 characters")
                .matches(/\d+/, { message: 'Password must contain a number' })
                .matches(/[a-z]+/, { message: 'Password must contain lowercase letters' })
                .matches(/[A-Z]+/, { message: 'Password must contain uppercase letters' })
                .matches(/[=+\-^$*.[\]{}()?"!@#%&/\\,><':;|_~`]/, { message: 'Password must contain a special character' }),
        }),      
        onSubmit: async (values) => {
            setProcessing(true)
            dispatch({ type: 'SET_IS_LOADING', payload: true });
            const signin_response = await chAuth.handleNewVerificationPassword(values.newPassword);
            let {cognitoUser, ChallengeName, Region, Session, error} = signin_response;
            if (cognitoUser) {
                let atts = await chAuth.getAttributes();
                signIn({history, from, reason: 'NEW_USER'});
            }
            setProcessing(false)
        }
    })



    const closeErrorSnackbar = () => {
        setOpenErrorSnackbar(false)
    }
   
    const handleFields = (event) => {
        if (isMountedRef.current) {
            setFields({
                ...fields,
                [event.target.name]: event.target.value.trim()
            })
        }
    }

   

    const resetTempPassword = async () => {

        let response = await api.resetTempPassword(fields.username.trim());

        if (response.code === 'UnsupportedUserStateException') {
            setMessage('Your account is already confirmed');
            setShowVerification(false);
            setLoginLinkExpired(false);
            sessionStorage.setItem('username', fields.username.trim())
            setShowAlreadyConfirmed(true);
            setAlertBarStatus('error');
            setOpenErrorSnackbar(true);
        }
        if (response.code === 'UserConfirmedException') {
            setMessage('Your account is already confirmed')
            setAlertBarStatus('error');
            setOpenErrorSnackbar(true);
        }
        if (response.error && response.error.code === 'UserNotFoundException') {
            setMessage('Please check your email address')
            setAlertBarStatus('error');
            setOpenErrorSnackbar(true);
        }

    }

    return (

            <Container maxWidth='sm'>
                

                {loginLinkExpired
                ?

                <RequestVerificationLink
                    username={fields.username}
                    resetTempPassword={resetTempPassword}
                    handleFields={handleFields}
                />
                : 
                null
                }

                {showAlreadyConfirmed
                ?
                <Grid container className={classes.verificationPanel}>
                    <Grid item xs={12}>
                        <Typography variant="h6" align="center">
                            Your account has already been verified.
                        </Typography>
                        <Typography align="center" style={{marginTop: '20px'}}>
                            <RouterLink to="/login">Login</RouterLink> to your account
                        </Typography>
                    </Grid>
                </Grid>
                : 
                null
                }
                {showVerification
                ?
                <Grid container className={classes.verificationPanel}>
                    <Grid item xs={12}>
                        <Typography variant="h6" align="center">
                            Verification attempts have been exceeded. Please try again later.
                        </Typography>
                    </Grid>
                </Grid>
                : 
                null
                }


                {showNewPassword
                ?
                <Grid container component="form" className={classes.verificationPanel} onSubmit={formik.handleSubmit}>
                    <Grid item xs={12}>
                    <FormControl fullWidth margin="normal" variant="outlined">
                            <InputLabel htmlFor="newPassword">New Password</InputLabel>
                            <OutlinedInput
                                type={showPassword ? 'text' : 'password'}
                                id='newPassword'
                                {...formik.getFieldProps('newPassword')}
                                labelWidth={90}
                                placeholder="New Password"
                                aria-label="New Password"
                                aria-describedby="newPassword"
                                endAdornment={
                                    <InputAdornment position="end">
                                      <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                      >
                                        {showPassword ? <EyeOffIcon /> : <EyeIcon /> }
                                      </IconButton>
                                    </InputAdornment>
                                  }
                            ></OutlinedInput>
                        </FormControl>
                        
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            disabled={!formik.isValid || processing}
                            size="small"
                            disableElevation
                            color="secondary" onClick={formik.handleSubmit}>Create New Password</Button>
                    </Grid>
                    <Grid item xs={12}>
                    <FormHelperText>Passwords require at least 8 characters and must include letters, numbers and at least one special character from this set<br/><strong>{`= + - ^ $ * . [ ] { } ( ) ? " ! @ # % & / \\ , > < ' : ; | _ ~ \` `}</strong></FormHelperText>
                        {formik.touched.newPassword && formik.errors.newPassword ? <Typography variant="subtitle2" color="error">{formik.errors.newPassword}</Typography> : null}
                    </Grid>
                </Grid>
                :
                null
                }

                {displayRefreshMessage
                ?
                <Grid container className={classes.verificationPanel}>
                    <Grid item xs={12}>
                        <Typography variant="h6" align="center">
                            Your session has expired. Please refresh your browser and try again.
                        </Typography>
                    </Grid>
                </Grid>
                :
                null
                }

                <Backdrop className={classes.backdrop} open={checkingNewUser}>
                <Grid container justifyContent="center" alignItems="center" direction="column" spacing={4}>
                    <Grid item>
                    <CircularProgress color="inherit" />
                    </Grid>
                </Grid>
                </Backdrop>

                <ErrorSnackBar message={message} open={openErrorSnackbar} status={alertBarStatus} closeErrorSnackbar={closeErrorSnackbar} />
            
            </Container>
    )
}

export default withRouter(Verify);
