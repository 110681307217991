import React, { useEffect, useRef, useCallback, memo } from "react";
import Chart from 'chart.js/auto';
import SavingBar from '../SavingBar';
import { chartBuilder } from '../../lib/chartBuilder';
import { withRouter } from 'react-router-dom';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import {
    Paper,
    Grid,
    Typography,
    CircularProgress
} from '@material-ui/core';

Chart.defaults.font.family = `'Lato',sans-serif`;

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        [theme.breakpoints.down(815)]: {
            padding: 0,
        },
        marginBottom: theme.spacing(2),
        position: 'relative'
    },
    paperNoTopPadding: {
        paddingTop: 0,
    },
    formGroup: {
        marginBottom: theme.spacing(2),
    },
    settingsTitle: {
        marginBottom: theme.spacing(2),
    },
    allQuiet: {
        position: 'absolute',
        color: '#fff',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',


        '& p': {
            fontSize: '50px',
            fontWeight: 200,
            wordBreak: 'break-word',
            whiteSpace: 'pre-line',
            lineHeight: 1,
            [theme.breakpoints.down(815)]: {
                fontSize: ' 30px'
            },
        },
        '& .hide': {
            [theme.breakpoints.down(815)]: {
                display: 'none'
            },
        }
    },
    allQuiet_container: {
        padding: theme.spacing(8),
        textAlign: 'center'
    },
    paperMinHeight: {
        minHeight: '300px',
        backgroundImage: 'url(/bg-images/ch-no-waiting.jpg)',
        backgroundSize: 'cover',
        backgroundPositionY: 'bottom',
        backgroundRepeat: 'no-repeat'
    }
}));






const DashboardChart = memo(({ firstRun, graphData, loading, history, dashboardState, currentDomain }) => {
    const theme = useTheme();
    const classes = useStyles();

    // hold a ref of the chart so we can reuse it
    let chart = useRef(null);
    let ctx = useRef(null);
    let waiting_ctx = useRef(null);
    let updatedChartData = useRef(null);

    useEffect(() => {

        const updateChartOnVisibility = () => {
            if (document.visibilityState === 'visible' && chart.current) {
                chart.current.update();
            }
        }

        document.addEventListener("visibilitychange", updateChartOnVisibility);

        return () => {
            document.removeEventListener("visibilitychange", updateChartOnVisibility);
        }

    }, []);


    const formatUpdatedGraphData = () => {

        chart.current.options.animation.duration = 1000;
        chart.current.config.data.labels = updatedChartData.current.data.labels;

        // update current datasets
        let currentDataIds = [];

        for (let n = 0; n < chart.current.config.data.datasets.length; n++) {
            const dataset = chart.current.config.data.datasets[n];
            currentDataIds.push(dataset.id)
            for (let k = 0; k < updatedChartData.current.data.datasets.length; k++) {
                const updatedDataSet = updatedChartData.current.data.datasets.filter((newDataset) => {
                    return newDataset.id === dataset.id;
                });

                if (updatedDataSet.length) {
                    // just update the data otherwise it reinitialises the graph
                    chart.current.config.data.datasets[n].data = updatedDataSet[0].data;
                }
            }

        }

        // find any new datasets to add
        let remainingData = updatedChartData.current.data.datasets.reduce((acc, dataset) => {
            if (currentDataIds.indexOf(dataset.id) === -1) {
                acc.push(dataset);
            }
            return acc;
        }, []);

        if (remainingData.length) {
            for (let k = 0; k < remainingData.length; k++) {
                const dataset = remainingData[k];
                chart.current.config.data.datasets.push(dataset);
            }
        }

        // now find any existing datasets that are now no longer active and remove them
        let updatedIds = updatedChartData.current.data.datasets.reduce((acc, dataset) => {
            acc.push(dataset.id);
            return acc;
        }, []);
        for (let i = 0; i < chart.current.config.data.datasets.length; i++) {
            const dataset = chart.current.config.data.datasets[i];

            if (updatedIds.indexOf(dataset.id) === -1) {
                let ids = chart.current.config.data.datasets.map((dataset) => {
                    return dataset.id;
                });

                let position = ids.indexOf(dataset.id);

                chart.current.config.data.datasets.splice(position, 1);
            }
        }


    }

    const addGraphClickEvents = useCallback(() => {


        chart.current.config.options.onClick = (e, elements, chart) => {

            if (elements.length) {
                // get the first element
                let ele = elements[0];
                let dataset = chart.data.datasets[ele.datasetIndex];
                const roomId = dataset.roomID;
                const domain = chart.data.datasets[0].domain;

                if (roomId) {
                    let path = `/rooms/sessions`;
                    history.push(path, {
                        roomID: roomId,
                        includeActiveUsers: 0,
                        domainID: domain,
                    });
                } else {
                    history.push(`/rooms/sessions`, {
                        domainID: domain,
                        includeActiveUsers: 1,
                    });
                }

            }

        }

    }, [history]);

    const displayChart = useCallback((graphData) => {


        if (ctx.current === null) {
            ctx.current = document.getElementById('dashboard_chart');
        }

        if (graphData && graphData.data && graphData.data.length > 0 && dashboardState.has_graph_data) {

            if (waiting_ctx.current) {
                waiting_ctx.current = null; // remove the reference
            }


            if (ctx.current === null) {
                ctx.current = document.getElementById('dashboard_chart');
            }

            if (graphData.updating) {
                if (chart.current) {
                    chart.current = chart.current.destroy();
                    chart.current = null
                }
            } else {

                let chartdata = chartBuilder(graphData.data, graphData.rooms, theme);
                // if we have no chart then create one
                if (chart.current === null && ctx.current) {
                    chart.current = new Chart(ctx.current, chartdata);
                    addGraphClickEvents()
                } else {
                    updatedChartData.current = chartBuilder(graphData.data, graphData.rooms, theme);


                    formatUpdatedGraphData();
                    // update the chart
                    setTimeout(() => {
                        chart.current.update();
                    }, 500);
                }
            }
        }

    }, [addGraphClickEvents, dashboardState.has_graph_data, theme]);

    useEffect(() => {
        if (graphData && graphData.data.length && dashboardState.has_graph_data) {
            displayChart(graphData)
        }
    }, [graphData, displayChart, dashboardState.has_graph_data]);


    return (
        <>
            <Paper elevation={10} className={clsx({
                [classes.paper]: true,
                [classes.paperNoTopPadding]: false,
                [classes.paperMinHeight]: dashboardState.display_empty_room_image || dashboardState.has_graph_waiting,
            })}>

                <SavingBar saving={loading || graphData.updating} />

                {dashboardState.display_empty_room_image && !dashboardState.has_graph_waiting ?
                    <Grid container className={classes.allQuiet} direction="column" alignContent="center">
                        <Grid className={classes.allQuiet_container}>
                            <Typography >
                                All quiet <span className="hide">on</span>
                            </Typography>
                            <Typography className="hide">
                                {currentDomain.name}
                            </Typography>
                        </Grid>
                    </Grid>
                    : null}
                {dashboardState.has_graph_waiting ?
                    <Grid container className={classes.allQuiet} direction="column" alignContent="center">
                        <Grid className={classes.allQuiet_container}>
                            <CircularProgress thickness={6} />
                            <Typography >
                                Waiting for data
                            </Typography>
                        </Grid>
                    </Grid>
                    : null}
                <div style={{
                    position: 'relative',
                    margin: 'auto',
                    height: '40vh',
                    width: '100%',
                }}>
                    <canvas id="dashboard_chart" width="960" height="300" style={{
                        width: '100%', verticalAlign: 'bottom',
                        display: (dashboardState.has_graph_data && !dashboardState.has_graph_waiting) ? 'block' : 'none'
                    }}></canvas>
                </div>
            </Paper>
        </>
    )
})

export default withRouter(DashboardChart);

