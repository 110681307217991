import React, { useEffect, useRef, useState } from "react";
import Chart from 'chart.js/auto'
import { reportChartBuilder } from '../lib/reportChartBuilder';
import { withRouter } from 'react-router-dom';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import {
    Grid,
    Paper,
    CircularProgress,
    Tooltip,
    IconButton,
    DialogTitle,
    DialogContent,
    DialogContentText,
    Dialog,
    DialogActions,
    Button,
    Typography
} from '@material-ui/core';
import clsx from 'clsx';

import ReportHeader from './Reports/ReportHeader';
import ReportsMainTable from './ReportsMainTable'
import Config from '../config';
import { DateTime } from 'luxon';
import { reportData } from '../lib/generateChartDownloadData'
import mainReportChartConfig from '../lib/mainReportChartConfig';
import styled from '@emotion/styled';
import { ZoomOutIcon } from '../icons';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
        position: 'relative'
    },
    paperNoTopPadding: {
        paddingTop: 0,
    },
    formGroup: {
        marginBottom: theme.spacing(2),
    },
    canvasResp: {
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        zIndex: -1,
    },
    settingsTitle: {
        marginBottom: theme.spacing(2),
    },
    loader: {
        zIndex: theme.zIndex.drawer + 1,
        position: 'absolute',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    zoomIcon: {
        color: theme.palette.primary.main
    }
}));

const ZoomOutBtn = styled(IconButton)`
    background-color: #fff;
    width: 40px;
    height: 40px;
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    padding: 15px;
    
`;

const ReportMainChart = ({ api, history, loading, state }) => {
    const theme = useTheme();
    const classes = useStyles();
    const [domainId, setDomainId] = useState(state.currentDomain.id);
    const domainRef = useRef(null);
    const periodRangeRef = useRef('day');
    const [currentRooms, setCurrentRooms] = useState([]);
    const [chartData, setChartData] = useState([]);
    const [responses, setResponses] = useState([]);
    const [initialData, setInitialData] = useState([]);
    const [displayGraph, setDisplayGraph] = useState(false);
    const [periodRange, setPeriodRange] = useState('hour');
    const [updating, setUpdating] = useState(true);
    const [reportRows, setReportRows] = useState([]);
    const [checkboxes, setCheckboxes] = useState([]);

    const chartStyle = {
        borderWidth: 1,
        pointRadius: 1,
        pointHitRadius: 2,
    }

    // hold a ref of the chart so we can reuse it
    let chart = useRef(null);
    let ctx = useRef(null);

    const defaultDlTime = {
        from: DateTime.utc().startOf('minute').minus({ days: 1 }),
        to: DateTime.utc().startOf('minute'),
    };


    let [downloadTime, setDownloadTime] = useState({ ...defaultDlTime });
    // const dateStack = useRef([]);
    const downloadTimeRef = useRef({ ...defaultDlTime });
    const previousDlTimeRef = useRef({ ...defaultDlTime });

    // get the initial data
    useEffect(() => {
        const storedReportSpecs = sessionStorage.getItem(Config.SESSION.REPORT_LAST_SPECS);
        //console.log({storedReportSpecs});
        const reportSpecs = JSON.parse(storedReportSpecs);

        //console.log({reportSpecs});

        // if (reportSpecs?.dateStack) {
        //     dateStack.current = [...reportSpecs?.dateStack.map(({ from, to }) => ({ from: DateTime.fromISO(from), to: DateTime.fromISO(to)}))];
        // }

        const dlTime = reportSpecs?.downloadTime ?? downloadTime;


        ctx.current = document.getElementById('report_main_chart');

        const theDomain = reportSpecs?.domain ?? state.currentDomain.id;

        const getData = async () => {

            let rooms = state.rooms.reduce((acc, room) => {
                if (room.domainID === theDomain) {
                    room.checked = true;
                    acc.push(room);
                }
                return acc;
            }, [])
            setCurrentRooms(rooms);
            let period = reportSpecs?.period ?? periodRangeRef.current;

            if (reportSpecs?.downloadTime) {
                downloadTimeRef.current = {
                    from: DateTime.fromISO(dlTime.from),
                    to: DateTime.fromISO(dlTime.to),
                }
                setDownloadTime({
                    from: DateTime.fromISO(dlTime.from),
                    to: DateTime.fromISO(dlTime.to),
                });
            }

            if (reportSpecs?.period) {
                periodRangeRef.current = reportSpecs.period;
                setPeriodRange(reportSpecs.period)
            }

            if (reportSpecs?.domain) {
                domainRef.current = reportSpecs.domain;
                setDomainId(reportSpecs.domain);
            }

            let response = await api.getDomainReport(theDomain, dlTime, period);
            let results = response;

            if (results.length === 0) {
                setOpen(true);
                setUpdating(false);
                setDownloadTime({
                    'from': previousDlTimeRef.current.from,
                    'to': previousDlTimeRef.current.to
                }
                );
            } else {
                previousDlTimeRef.current = downloadTimeRef.current;
                let chartdata = reportChartBuilder(results, rooms, theme, true, mainReportChartConfig, chartStyle, reportSpecs?.period ?? 'day', handleZoomIn);
                setChartData(chartdata);
                setResponses(results);
                renderGraph(chartdata);
                calculatePeak(chartdata);
            }
            
            sessionStorage.setItem(Config.SESSION.REPORT_LAST_SPECS, JSON.stringify({
                // ...downloadTime,
                downloadTime : {
                    'from': downloadTimeRef.current.from.toString(),
                    'to':downloadTimeRef.current.to.toString(),
                },
                domain: theDomain
            }));


            // setResponses(response);
            // setInitialData(response);
            // let results = response;

            // let chartdata = reportChartBuilder(results, rooms, theme, true, mainReportChartConfig, chartStyle, reportSpecs?.period ?? 'day', handleZoomIn);

            // setChartData(chartdata);
            // renderGraph(chartdata);
            // calculatePeak(chartdata);

        }

        if (state.currentDomain.id) {
            getData();
        }
    }, []);

    const getCurrentSessionReportsData = () => JSON.parse(sessionStorage.getItem(Config.SESSION.REPORT_LAST_SPECS)) ?? {};

    const handleZoomIn = (event, elements, chart) => {


        if (elements.length) {
            const index = elements[0].index;
            const timestamp = chart.data.datetimes[index];
            let pR = periodRangeRef.current;
            let timeDiff = pR === 'day' ? { days: 1 } : pR === 'hour' ? { hours: 1 } : { minutes: 60 };

            switch (periodRangeRef.current) {
                case 'day':
                    timeDiff = { days: 1 }
                    break;
                case '2-hour':
                    timeDiff = { hours: 1 }
                    break;
                case 'hour':
                case 'minute':
                    timeDiff = { minutes: 30 }
                    break;

                default:
                    break;
            }

            let new_from = DateTime.fromISO(timestamp, { zone: 'local' }).minus(timeDiff);
            let new_to = DateTime.fromISO(timestamp, { zone: 'local' }).plus(timeDiff);

            setDownloadTime(
                {
                    from: new_from,
                    to: new_to
                }
            );
            downloadTimeRef.current = {
                from: new_from,
                to: new_to
            };

                setTimeout(() => {
                handleUpdateReport({
                    from: new_from,
                    to: new_to
                })();
            }, 500)



        }
        // if (elements.length) {
        //     let idx = elements[0]._index;
        //     let timestamp = chart.current.config.data.datetimes[idx];

        //     let pR = periodRangeRef.current;

        //     let timeDiff = pR == 'day' ? { days: 1 } : pR == 'hour' ? { hours: 1 } : { minutes: 60 };

        //     switch (periodRangeRef.current) {
        //         case 'day':
        //             timeDiff = { days: 1 }
        //             break;
        //         case '2-hour':
        //             timeDiff = { hours: 1 }
        //             break;
        //         case 'hour':
        //         case 'minute':
        //             timeDiff = { minutes: 30 }
        //             break;

        //         default:
        //             break;
        //     }

        //     let new_from = DateTime.fromISO(timestamp, { zone: 'local' }).minus(timeDiff);
        //     let new_to = DateTime.fromISO(timestamp, { zone: 'local' }).plus(timeDiff);

        //     // dateStack.current.push({
        //     //     ...downloadTimeRef.current
        //     // });
        //     setDownloadTime(
        //         {
        //             from: new_from,
        //             to: new_to
        //         }
        //     );
        //     downloadTimeRef.current = {
        //         from: new_from,
        //         to: new_to
        //     };
        //     // Save to Session Storage
        //     // sessionStorage.setItem(Config.AMPLIFY.Storage.SESSION.REPORT_LAST_SPECS, JSON.stringify({
        //     //     ...getCurrentSessionReportsData(),
        //     //     downloadTime: {
        //     //         from: new_from,
        //     //         to: new_to
        //     //     },
        //     //     // dateStack: [...dateStack.current]
        //     // }));

        //     setTimeout(() => {
        //         handleUpdateReport({
        //             from: new_from,
        //             to: new_to
        //         })();
        //     }, 500)

        // }
    }

    const handleZoomOut = () => {
        const from = downloadTime.from;
        const to = downloadTime.to;
        let timeDiff;

        switch (periodRangeRef.current) {
            case 'minute':
                timeDiff = { minutes: 30 }
                break;
            case 'hour':
                timeDiff = { days: 1 }
                break;
            case '2-hour':
                timeDiff = { days: 15 }
                break;
            case 'day':
                timeDiff = { days: 15 }
                break;
            default:
                break;
        }

        let new_from = from.minus(timeDiff);
        let new_to = to.plus(timeDiff);


        const now = DateTime.utc().startOf('minute');
        if (new_to > now) {
            new_to = now;
        }

        // if (periodRangeRef.current === 'minute') {
        //     new_from = new_from.startOf('hour')
        //     new_to = to.startOf('hour');
        // }


        setDownloadTime(
            {
                from: new_from,
                to: new_to
            }
        );
        // dateStack.current.push({ ...downloadTime });
        setTimeout(() => {
            handleUpdateReport({
                from: new_from,
                to: new_to
            })();
        }, 500);

        // // Save to Session Storage
        // sessionStorage.setItem(Config.AMPLIFY.Storage.SESSION.REPORT_LAST_SPECS, JSON.stringify({
        //     ...getCurrentSessionReportsData(),
        //     downloadTime: {
        //         from: new_from,
        //         to: new_to
        //     },
        //     // dateStack: [...dateStack.current]
        // }));
    };

    const calculateTimePeriod = () => {
        return 'hour'
    }

    const updateChart = (checkboxes) => {
        chart.current.data.datasets = chart.current.data.datasets.map((e) => {
            e.hidden = (!checkboxes[e.id]);
            return e;
        });
        setCheckboxes(checkboxes);
        chart.current.update();
    }


    const renderGraph = (chartData) => {


        let hasData = chartData.data.datasets.map(element => {
            return (element);
        });

        if (chart.current) {
            chart.current.destroy();
        }

        chart.current = new Chart(ctx.current, chartData);

        setDisplayGraph(true);
        setUpdating(false);

        // if (hasData.length) {
        //     if (chart.current) {
        //         chart.current.destroy();
        //     }

        //     chart.current = new Chart(ctx.current, chartData);

        //     setDisplayGraph(true);
        //     setUpdating(false);
        // }
        // else {
        //     var canvas = document.getElementById('report_main_chart');

        //     if (canvas && canvas.getContext) {

        //         if (chart.current) {
        //             chart.current.destroy();
        //         }

        //         var ctx_context = canvas.getContext('2d');

        //         ctx_context.clearRect(0, 0, canvas.width, canvas.height);

        //         ctx_context.fillStyle = 'rgb(32, 109, 156)';
        //         ctx_context.font = '18px Lato,sans-serif';
        //         ctx_context.textBaseline = 'middle';
        //         ctx_context.textAlign = 'center';
        //         ctx_context.fillText('No data found', 960 / 2, 300 / 2);
        //     }

        //     setUpdating(false);
        // }
    }


    const onDateChange = (value, name) => {
        setDownloadTime(
            {
                ...downloadTime,
                [name]: value
            }
        );
        downloadTimeRef.current = {
            ...downloadTime,
            [name]: value
        };


        // // Save to Session Storage
        // sessionStorage.setItem(Config.AMPLIFY.Storage.SESSION.REPORT_LAST_SPECS, JSON.stringify({
        //     ...getCurrentSessionReportsData(),
        //     downloadTime: {
        //         ...downloadTime,
        //         [name]: value
        //     }
        // }));
    }

    const handleUpdateReport = (dateRange) => () => {
        updateReportData(domainRef.current, false, false, dateRange);
        // Save to Session Storage - Reset the dateStack
        // sessionStorage.setItem(Config.AMPLIFY.Storage.SESSION.REPORT_LAST_SPECS, JSON.stringify({
        //     ...getCurrentSessionReportsData(),
        //     dateStack: []
        // }));
    }

    const getRooms = (id) => {
        let rooms = state.rooms.reduce((acc, room) => {
            if (room.domainID === id) {
                room.checked = true;
                acc.push(room);
            }
            return acc;
        }, [])
        setCurrentRooms(rooms);
        return rooms;
    }

    const downloadData = async () => {
        let checkedRooms = [];
        for (const key in checkboxes) {
            if (Object.hasOwnProperty.call(checkboxes, key)) {
                if (checkboxes[key]) {
                    checkedRooms.push(key);
                }
            }
        }
        let filteredResults = responses.reduce((acc, row) => {
            if (checkedRooms.indexOf(row.room_id) !== -1) {
                acc.push(row)
            }
            return acc;
        }, []);

        let domain_name = state.domains.filter((domain) => {
            return domain.id === domainId;
        })

        reportData(filteredResults, domain_name, downloadTime);
    }

    /*
    useEffect(() => {
        const setTimeFormatter = () => {


            let to = downloadTime.to;
            let from = downloadTime.from;

            // if (periodRange === 'day') {
            //     to = to.startOf('day');
            //     from = from.startOf('day');
            // }
            // if (periodRange === 'minute') {
            //     from = from.startOf('day');
            //     to = to.startOf('day');
            // }
            // if (periodRange === 'hour') {
            //     to = to.startOf('hour');
            //     from = from.startOf('hour');
            // }

            // setDownloadTime(
            //     {
            //         ...downloadTime,
            //         'from': from,
            //         'to': to
            //     }
            // );
            downloadTimeRef.current = {
                ...downloadTime,
                'from': from,
                'to': to
            };

            // let new_save_data = {
            //     ...getCurrentSessionReportsData(),
            //     downloadTime: {
            //         ...downloadTime,
            //         'from': from,
            //         'to': to
            //     }
            // }

            // Save to Session Storage
            // sessionStorage.setItem(Config.AMPLIFY.Storage.SESSION.REPORT_LAST_SPECS, JSON.stringify(new_save_data));

            // console.log(new_save_data);


            // if (periodRange !== 'hour') {

            //     //DateTime.utc().startOf('minute').minus({ hours: 1 })
            //     setDownloadTime(
            //         {
            //             ...downloadTime,
            //             'from': downloadTime.to.startOf('hour'),
            //             'to': downloadTime.to.startOf('hour')
            //         }
            //     );
            //     downloadTimeRef.current = {
            //         ...downloadTime,
            //         'from': downloadTime.to.startOf('hour'),
            //         'to': downloadTime.to.startOf('hour')
            //     };

            //     let new_save_data = {
            //         ...getCurrentSessionReportsData(),
            //         downloadTime: {
            //             ...downloadTime,

            //             'to': downloadTime.to.startOf('hour')
            //         }
            //     }

        }

        setTimeFormatter()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [periodRange]);
    */

    const resetReportChart = () => {

        if (chart.current) {
            chart.current.destroy();
            chart.current = null;
        }
    }

    const updateReportData = async (domain, rooms, resetChart, dateRange) => {
        setUpdating(true);

        if (resetChart) {
            resetReportChart();
        }
        let period;

        const HOUR = 3600000;
        const DAY = HOUR * 24;

        const dlTime = dateRange ? dateRange : downloadTime;

        let diff = dlTime.to.diff(dlTime.from, ['milliseconds']);

        let interval_step = 'minute';


        let diff_ms = diff.toObject().milliseconds;
        if (diff_ms <= HOUR) {
            interval_step = 'minute';
            period = 'minute';
        }
        else if (diff_ms <= HOUR * 2) {
            interval_step = 'hour';
            period = 'minute';
        }
        else if (diff_ms <= DAY * 3) {
            period = 'hour';
            interval_step = '2-hour';
        } else if (diff_ms > DAY * 2) {
            period = 'day';
            interval_step = 'day';
        } else {
            period = 'day';
            interval_step = 'day';
        }

        setPeriodRange(period);
        periodRangeRef.current = interval_step;


        let new_save_data = {
            ...getCurrentSessionReportsData(),
            downloadTime: {
                ...downloadTime,

                'to': dlTime.to.toISO(),
                'from': dlTime.from.toISO(),
            },
        }
        


        let domain_id = (domain) ? domain : domainId;
        let current_rooms = (rooms) ? rooms : getRooms(domain_id);

        let response = await api.getDomainReport(domain_id, dlTime, period);

        let results = response;

        if (results.length === 0) {
            setOpen(true);
            setUpdating(false);
            setReportRows([]);
            // setDownloadTime(
            //     {
            //         ...downloadTime,
            //         'from': DateTime.fromISO(previousDlTimeRef.current.from),
            //         'to': DateTime.fromISO(previousDlTimeRef.current.to),
            //     }
            // );
        } else {
            previousDlTimeRef.current = new_save_data.downloadTime;
            let chartdata = reportChartBuilder(results, current_rooms, theme, true, mainReportChartConfig, chartStyle, period, handleZoomIn);
            setChartData(chartdata);
            setResponses(results);
            renderGraph(chartdata);
            calculatePeak(chartdata);

            sessionStorage.setItem(Config.SESSION.REPORT_LAST_SPECS, JSON.stringify({
                // ...downloadTime,
                downloadTime : {
                    'from': dlTime.from.toString(),
                    'to': dlTime.to.toString(),
                },
                domain: domain_id
            }));
            // sessionStorage.setItem(Config.SESSION.REPORT_LAST_SPECS, JSON.stringify({
            //     ...new_save_data,
            //     domain: domain_id
            // }));
        }

    }

    const calculatePeak = (chartdata) => {

        let rows = [];

        const getIndices = (dataset, value, labels) => {
            let indices = [];
            let idx = dataset.indexOf(value);
            while (idx !== -1) {
                indices.push(labels[idx]);
                idx = dataset.indexOf(value, idx + 1);
            }
            return indices;
        }

        function arrayMin(arr) {
            var len = arr.length, min = Infinity;
            while (len--) {
                if (arr[len] < min) {
                    min = arr[len];
                }
            }
            return min;
        };

        function arrayMax(arr) {
            var len = arr.length, max = -Infinity;
            while (len--) {
                if (arr[len] > max) {
                    max = arr[len];
                }
            }
            return max;
        };


        let data = [...chartdata.data.datasets].reduce((acc, room) => {
            acc.push({
                id: room.id,
                data: room.data,
                label: room.label,
                backgroundColor: room.backgroundColor

            })
            return acc;
        }, [])
        

        for (let i = 0; i < data.length; i++) {
            let dataset = data[i];


            dataset.data = dataset.data.map((datum) => {
                if (isNaN(datum)) {
                    return 0;
                } else {
                    return datum;
                }
            });

            let valuesMax = arrayMax(dataset.data);
            let valuesMin = arrayMin(dataset.data);

            let dataTotal = (dataset.data.reduce((a, b) => a + b, 0));
            let avgValues = Math.ceil(dataTotal / dataset.data.length);

            if (dataset.id) {

                let wait_time_data = chartdata.data.waitTimes[dataset.id];
                let wait_times;
                let max_wait_time;
                let avg_wait_time;
                if (wait_time_data) {
                    wait_times = wait_time_data.map((time) => {
                        if (time) {
                            return time;
                        } else {
                            return 0;
                        }
                    });


                    max_wait_time = arrayMax(wait_times);

                    let total = (wait_times.reduce((a, b) => a + b, 0));

                    let length = wait_times.length;

                    avg_wait_time = Math.ceil(total / length);
                }
                let room = {
                    title: dataset.label,
                    color: dataset.backgroundColor,
                    id: dataset.id,
                    max: {
                        value: valuesMax,
                        indices: getIndices(dataset.data, valuesMax, chartdata.data.labels)
                    },
                    min: {
                        value: valuesMin,
                        indices: getIndices(dataset.data, valuesMin, chartdata.data.labels)
                    },

                    avg: avgValues,
                    avg_waiting: avg_wait_time,
                    max_wait_time: max_wait_time
                }


                rows.push(room)
            }

        }

        setReportRows(rows);

        let checkboxes = {}

        if (rows) {
            rows.forEach(row => {
                checkboxes[row.id] = true
            });
        }

        setCheckboxes(checkboxes);
    }

    const updateCheckboxes = (checkboxes) => {
        setCheckboxes(checkboxes);
        updateChart(checkboxes)
    }

    const handleChangeDomain = (value) => {
        setDomainId(value);
        domainRef.current = value;
        // Save to Session Storage
        // sessionStorage.setItem(Config.AMPLIFY.Storage.SESSION.REPORT_LAST_SPECS, JSON.stringify({
        //     ...getCurrentSessionReportsData(),
        //     domain: value,
        // }));

        let rooms = getRooms(value)
        setTimeout(() => {
            updateReportData(value, rooms, true);
        }, 500);
    }

    // const handleBack = () => {
    //     if  (dateStack.current.length) {
    //         const previousDlTime = dateStack.current.pop();
    //         console.log(previousDlTime.from.toLocaleString());
    //         setDownloadTime({...previousDlTime});
    //         downloadTimeRef.current = { ...previousDlTime };
    //         // Save to Session Storage
    //         sessionStorage.setItem(Config.AMPLIFY.Storage.SESSION.REPORT_LAST_SPECS, JSON.stringify({
    //             ...getCurrentSessionReportsData(),
    //             downloadTime: { ...previousDlTime },
    //             dateStack: [...dateStack.current]
    //         }));
    //         updateReportData(false,false,false,{...previousDlTime});
    //     }
    // }

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value) => {
        setOpen(false);
    };


    return (
        <>
            <Paper elevation={1} className={clsx({
                [classes.paper]: true,
                [classes.paperNoTopPadding]: false,
            })}>
                <ReportHeader
                    globalState={{
                        currentDomain: domainRef.current ?? state.currentDomain.id,
                        updating: state.updating,
                        domains: [...state.domains],
                    }}
                    downloadTime={downloadTime}
                    periodRange={periodRange}
                    // undoProps={{
                    //     onUndo: handleBack,
                    //     disabled: dateStack.current.length === 0
                    // }}
                    onUpdate={handleUpdateReport()}
                    onDownload={downloadData}
                    onDomainChange={handleChangeDomain}
                    onDateChange={onDateChange}
                />
            </Paper>

            <Paper elevation={10} className={clsx({
                [classes.paper]: true,
                [classes.paperNoTopPadding]: false,
            })} style={{ position: 'relative' }}>
                {
                    updating

                        ?

                        <div className={classes.loader}>
                            <CircularProgress color="primary" />
                        </div>
                        :

                        null
                }
                 <div style={{
                position: 'relative',
                margin: 'auto',
                height: '38vh',
                width: '100%',
            }}>
                <canvas id="report_main_chart" width="960" height="300" style={{ opacity: (updating) ? 0.3 : 1, width: '100%' }}></canvas>
                </div>
                {
                    <Tooltip title="Zoom Out">
                        <ZoomOutBtn className={classes.zoomIcon}
                            onClick={handleZoomOut}>
                            <ZoomOutIcon width={20} height={20} />
                        </ZoomOutBtn>
                    </Tooltip>
                }
            </Paper>

            <Grid container spacing={4}>
                <Grid item xs={12} md={12}>
                    <ReportsMainTable rows={reportRows} updateChart={updateChart} updateCheckboxes={updateCheckboxes} checkboxes={checkboxes} />
                </Grid>
            </Grid>


            <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
                <DialogTitle id="alert-dialog-title">{"No data found"}</DialogTitle>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

        </>
    )
}

export default withRouter(ReportMainChart);
