import React from 'react';
import XCircleIcon from '../icons/XCircleIcon';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';

import { Snackbar, IconButton } from '@material-ui/core';
import green from '@material-ui/core/colors/green';



export default function ErrorSnackBar(props) {
    let { message, open, closeErrorSnackbar, status, ...rest } = props;

    const theme = useTheme();
    let bg_color;
    switch (status) {

        case 'error':
            bg_color = theme.palette.error.main;
            break;
        case 'success':
            bg_color = green[500]
            break;
    
        default:
            bg_color = theme.palette.secondary.main;
            break;
    }

    const useStyles = makeStyles((theme) => ({
        root: {
            '& .MuiSnackbarContent-root' : {
                backgroundColor: bg_color
            }
        },
    }));

    const classes = useStyles();

    return (


        <Snackbar
        className={classes.root}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }}
        open={open}
        autoHideDuration={6000}
        onClose={closeErrorSnackbar}
        message={message}
        action={
            <>
                    <IconButton size="small" aria-label="close" color="inherit" onClick={closeErrorSnackbar}>
                        <XCircleIcon fontSize="small" style={{strokeWidth: 2}}/>
                    </IconButton>
                </>
            }
        {...rest}
        />
    )



}
