import React, {useEffect, useState, useRef} from "react";
import Typewriter from 'typewriter-effect';
import {Typography} from '@material-ui/core';
const TypeWriterComponent = ({strings}) => {


    // let typeWriterData = useRef([])
    let [indexCurrent, setIndexCurrent] = useState(0)
    let index = useRef(0)
    let typewriters = useRef([])
    let [typeWriterData, setTypeWriterData] = useState([])
    const options = {
        cursor: '',
        delay: 15,
        autostart: false
    }
  
  
    useEffect(() => {
  
      let elements = []
      for (let i = 0; i < strings.length; i++) {
        const str = strings[i];
          let tw = <Typewriter
                      key={i}
                      component={Typography}
                      options={options}
                      onInit={(typewriter) => {
                        index.current = i;
                        setIndexCurrent((prev)=>{
                          return i;
                        })
                        typewriter.ch_index = i;
                        typewriter.next_index = i+1;
                        typewriter.typeString(str);
                        typewriters.current.push(typewriter);
                      }}
                    />
  
        elements.push({tw,str});
  
        
      }
      setTypeWriterData(elements);
  
    },[]);
  
    useEffect(() => {
  
  
      if(indexCurrent === strings.length-1) {  
        for (let i = 0; i < typewriters.current.length; i++) {
          const element = typewriters.current[i];
  
          element
            .callFunction(() => {
  
              try {
                typewriters.current[i+1].start();
                
              } catch (error) {
              
              }
            });
  
          if (i === 0) {
            element.start()
          }
          
        }
      }
    }, [indexCurrent, strings.length]);
  
    return (
      <>
      {typeWriterData.map((tw) => {
        return tw.tw;
      })}
      </>
      
      )
  
  
  }

  export default TypeWriterComponent;
