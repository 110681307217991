import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';

import {
  Container,
  Grid,
  Slider,
  FormGroup,
  FormControlLabel,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  buttonHeader: {
    padding: theme.spacing(0),
    marginBottom: theme.spacing(2),
  },
  margin: {
    marginBottom: theme.spacing(6),
  },
  smallmargin: {
    marginBottom: theme.spacing(2),
  },
  withoutLabel: {
    marginTop: theme.spacing(6),
  },
  domainAdornment: {
    marginRight: 0
  },
  spinner: {
    marginLeft: 22,
    color: theme.palette.secondary
  },

}));


const RoomCapacity = ({ api, state, ...rest }) => {
  const classes = useStyles();
  const [sliders, setSliders] = useState([])
  const [total, setTotal] = useState(100)

  useEffect( () => {

    const setSliderValues = async () => {

      let integ = state.plan.maxWaitingRoomCapacity / state.rooms.length;

      let sliders = state.rooms.reduce((acc, room) => {
          room.percentage = parseInt((integ/state.plan.maxWaitingRoomCapacity) * 100, 10);
          acc.push(room);
          return acc;
      }, []);

      setSliders(sliders)
      
    }

    if(state.rooms && state.plan.maxWaitingRoomCapacity) {
      setSliderValues()
    }

  
  }, [state.rooms, state.plan.maxWaitingRoomCapacity]);

  const handleRoomsSizeChange = (a ,newValue, newRoom) => {

    let left = 100 - newValue;
    let each = left / (sliders.length - 1);

    let newSliders = sliders.reduce((acc, slider) => {

        if (slider.id === newRoom.id){
          slider.percentage = newValue;
        } else {
          slider.percentage = each;
        }
        acc.push(slider);
        alert('changed)');
      return acc;
    },[])


    let local_total = newSliders.reduce((acc, room) => {

      acc = acc + room.percentage;

      return acc;
    }, 0);


    setSliders(newSliders);
    setTotal(local_total)

    
  }



  return (

    <div className={classes.root}>

<Container
        maxWidth="lg"
       
      >

        <Typography>ROOM CAPACITY SLIDERS</Typography>
        <Typography>Max Capacity { state.plan.maxWaitingRoomCapacity} </Typography>
        <Typography>Total Percent { total } %</Typography>

        { state.rooms && sliders.map((room) => {
          return (

            <Grid container   spacing={4} key={room.id}>
             
              <Grid item xs={12}>
              <FormGroup row>
                          
                            <FormControlLabel
                                labelPlacement='top'
                                style={{ width: '100%' }}
                                control={
                                    <Slider
                                        style={{ paddingTop: '18px' }}
                                        valueLabelDisplay="off"
                                        value={room.percentage}
                                        min={0}
                                        max={100}
                                        onChangeCommitted={(ev, b) => handleRoomsSizeChange(ev, b, room)}
                                    />
                                }
                            />
                            
                            
                        </FormGroup>
              </Grid>
              <Grid item xs={6}><Typography>{room.title}</Typography></Grid>
              <Grid item xs={6}><Typography>{room.percentage}%</Typography></Grid>
            </Grid>
           
          )
        })}

        </Container>
       

    </div>
  );
};

export default RoomCapacity;