import React, { useState } from "react";
import { makeStyles } from '@material-ui/core/styles';

import {
    Grid,
    Button,
    Typography,
    Backdrop,
    CircularProgress
} from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import ErrorSnackBar from '../../ErrorSnackBar';
import OnboardStepper from '../OnboardStepper';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: theme.palette.secondary.main,
    }
}));


const SelectImplementationPanel = ({ domain, api, dispatch }) => {

    const classes = useStyles();
    const [value, setValue] = useState('javascript');
    const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
    const [alertBarStatus, setAlertBarStatus] = useState();
    const [message, setMessage] = useState();
    const [processing, setProcessing] = useState(false);

    const closeErrorSnackbar = () => {
        setOpenErrorSnackbar(false)
    }

    const handleSelect = () => {
        setProcessing(true);
        saveDomain({
            id:domain.id,
            deployment: value,
        });
    }

    const handleChange = (event) => {
        setValue(event.target.value);
    };


    const saveDomain = async (data, method) => {

        let result = await api.putDomain(data, 'put');
        
        if (result.error) {
            setMessage('Error updating domain');
            setAlertBarStatus('error');
            setOpenErrorSnackbar(true);
            setProcessing(false);
            
        } else {
            console.log(result);
            dispatch({ type: 'SET_CURRENT_DOMAIN', payload: result });
            dispatch({ type: 'UPDATE_DOMAINS', payload: result });
        }

    }


    let panelInfo = [
        {
            value: 'javascript',
            label: 'JavaScript',
            info: <Typography>Easy and quick. Use this for going live quickly.</Typography>
        },
        {
            value: 'dns',
            label: 'DNS',
            info: <Typography>Cannot be bypassed, some technical know how with DNS required</Typography>
        },
        {
            value: 'wordpress',
            label: 'WordPress',
            info: <Typography>If you have installed our WordPress Plugin</Typography>
        },
        {
            value: 'cloudflare',
            label: 'CloudFlare',
            info: <Typography>If your site is protected by Cloudflare</Typography>
        },
        {
            value: 'cloudfront',
            label: 'AWS CloudFront',
            info: <Typography>If you host on AWS</Typography>
        },
        {
            value: 'akamai',
            label: 'Akamai EdgeWorker',
            info: <Typography>If you use the Akamai CDN</Typography>
        },
        {
            value: 'shopifyjs',
            label: 'Shopify',
            info: <Typography>If you use Shopify</Typography>
        },
        {
            value: '.net',
            label: '.NET',
            info: <Typography>If you use the .NET</Typography>
        },
        {
            value: 'api',
            label: 'Custom',
            info: <Typography>If you will use a custom API integration</Typography>
        },
    ]

    return (
        <Grid container direction="column">
            <OnboardStepper step={3} />
            <Grid item>
                <Typography>Pick your implementation:</Typography>
            </Grid>
            <Grid item>
                <FormControl fullWidth>
                    <RadioGroup aria-label="Implementation" name="implementation" value={value} onChange={handleChange}>

                        {panelInfo.map((panel) => {
                            return <FormControlLabel style={{ paddingBottom: 10, borderBottom: '1px solid #cacaca', margin: '10px 0' }} key={panel.value} value={panel.value} control={<Radio />} label={<div>
                                <Typography component="p"><strong>{panel.label}</strong></Typography>
                                {panel.info}
                            </div>} />

                        })}

                    </RadioGroup>
                </FormControl>

            </Grid>
            <Grid item>


                <Button
                    onClick={() => {
                        handleSelect();
                    }}
                    color="secondary" variant="contained" disableElevation>Select</Button>
            </Grid>


            {/* <List >
                {panelInfo.map((panel) => {
                    return (
                        <ListItem key={panel.value} button onClick={(e) => {
                            handleChange(panel.value);
                        }}>
                            <ListItemText primary={<Typography>{panel.label}</Typography>} secondary={panel.info} />
                        </ListItem>
                        )
                    })}
            </List> */}
            <Backdrop className={classes.backdrop} open={processing}>
                <CircularProgress color="inherit" disableShrink />
            </Backdrop>
            <ErrorSnackBar message={message} open={openErrorSnackbar} status={alertBarStatus} closeErrorSnackbar={closeErrorSnackbar} />
        </Grid>
    )

}

export default SelectImplementationPanel;

