const dashboardChartConfig = {
    type: 'line',
    data: {
        labels: [], // fill this with the times
        datasets: [], // the data from each room
    },
    options: {
        responsive: true,
        maintainAspectRatio: false,
        animations: {
            tension: {
              duration: 1000,
              easing: 'linear',
              from: 0.5,
              to: 0.1,
              loop: false
            }
          },
        interaction: {
            mode: 'nearest',
            intersect: true,
            // axis: 'x'
        },
        scales: {
            x: {
                autoSkip: true,
                weight: 1400,
                grid: {
                    // display: false,
                    // drawBorder: true,
                    // drawOnChartArea: true,
                },
            },
            y: {
                min: 0,
                weight: 1000,
                grid: {
                    display: false,
                    // display: true,
                    // drawBorder: true,
                    // drawOnChartArea: true,
                },
                ticks: {
                    beginAtZero: true,
                    stepSize: 100,
                    callback: function(value, index, ticks) {
                        return value;
                    }
                }
            }
        },
        plugins : {
            legend: {
                position: 'bottom',
                labels: {
                    boxWidth: 6,
                    boxHeight: 6,
                    usePointStyle: true,
                    
                    doughnutMode: false,
                    padding: 16,
                    filter: (item, data) => {
                        if (item.text === 'Transacting') {
                            item.pointStyle = 'rect';
                        }
                        return item;
                    }
                },
            },
            tooltip : {
                
                axis: 'x',
                usePointStyle: true,
                boxPadding: 0,
                callbacks: {
                    label(context) {
                        let diff;
                        if (context.dataIndex > -1) {
                            let label = `${context.dataset.label} ${context.raw}`;
                            diff = context.raw - context.dataset.data[context.dataIndex-1];
                            if (diff !== undefined && !isNaN(diff)) {
                                let value = (diff > 0) ? `+${diff}` : `${diff}`;
                                return `${label} (${[value]})`;
                            }
                            return label;
                        }
                        return `${context.dataset.label} ${context.raw}`;
                    }
                },
            },
        },
        title: {
            display: false,
        },
        
    }
}

export default dashboardChartConfig;