import React, { useState, useEffect, useMemo } from "react";
import { DataGrid } from '@mui/x-data-grid';
import {IPData, SessionAgent} from './';
import IPDataDialog from './IPDataDialog';
import { AlertTriangleIcon } from 'icons';
import {DateTime} from 'luxon';
import {
    Grid,
    Typography,
    Button,
    Card,
    CardContent,
    Link,
 } from  "@material-ui/core";
import uniq from 'lodash/uniq';
import useMediaQuery from '@material-ui/core/useMediaQuery';


const session_date_format = { month: 'short', day: 'numeric', hour: "numeric", minute: "numeric", second: 'numeric', hour12: true, }

const SessionRequestTable = (
    {requestToken,
    sessions,
    loadingData,
    requests,
    hasAnomalyDetection,
    setAgentData,
    setOpenAgentDialog,
    state,
    promoteSessionHandler,
    deleteSessionHandler,
    addIPToListHandler,
    requestSession,
    hasFirewall,
    ipIsInBlockList,
    hasAutoblockData,
    queryParams,
    api
}) => {
  
  const matches_800 = useMediaQuery(`@media (min-width:800px)`);

  const session = useMemo(() => {

      if (!requestToken) {
        return []
      }
      return sessions.find((session) => {
          return session.token === requestToken;
      });
    },[requestToken, sessions]);


let [domainList, setDomainList] = useState([]);
let [rows, setRows] = useState([]);
let [openIPDataDialog, setOpenIPDataDialog] = useState(false);
let [drawerInfo, setDrawerInfo] = useState();

const handleDialogClose = (event, reason) => {
  setDrawerInfo(null);
  setOpenIPDataDialog(false);
}


let columns = [];
columns.push(
  {
    field: 'agent', headerName: 'Agent', editable: false, width: 100, sortable: false,
    renderCell: (params) => {
        return <SessionAgent {...params}/>
    }
  },
  { field: 'ip', headerName: 'IP', editable: false, width: 140, sortable: false},
);

if(hasFirewall) {
  columns.push(
    {
      field: 'ip_data',
      headerName: 'IP Info',
      editable: false,
      width: 110,
      renderCell: (params) => {
        return <IPData ip_data={params.value}/>
      }
  },
  )
}

columns.push(
  
  { field: 'lang', headerName: 'Lang', editable: false, width: 62, sortable: false},
  { field: 'position', headerName: 'Position', editable: false, width: 84, sortable: false, type:'number'},
  { field: 'requested', headerName: 'Requested', editable: false, width: 100, sortable: false},
  { field: 'url', headerName: 'URL', editable: false, width: matches_800 ? null : 620, flex: matches_800 ? 1 : null ,  sortable: false,
  renderCell: (params) => {
    return (
      <Link
        href={params.value}
        target="_blank"
      >
      {params.value}
      </Link>

    )
  }},
  { field: 'waiting', headerName: 'Waiting', editable: false, width: 90, sortable: false,
  renderCell: (params) => {
      return (params.value !== null && parseInt(params.value, 10) === 0 ) ? "Active" : "Waiting";
  }}
);





    useEffect(() => {


      const formatRows = async () => {

        let uniq_agents = uniq(requests.map((request, index) => { 
          return request.agent;
        }));

        let agents = uniq_agents.map((agent) => { 
          return api.getUserAgents(agent);
        });

        let agent_values = await Promise.all(agents);
        
        let rows = requests.map((request, index) => {  
          let row_data = {};
          row_data.id = index;

          let found_agent = agent_values.filter((agent_value) => {
            return agent_value.ua === request.agent;
          })
          
          row_data.agent = found_agent.length ? found_agent[0] : {}
          row_data.ip = request.ip;

          if (hasFirewall) {
            if(session.ip_data) {
              row_data.ip_data = session.ip_data
            } else {
             row_data.ip_data = undefined;
            }
          }

          row_data = {
            lang: request.lang,
            position: request.position,
            url: request.url,
            requested: request.requestedTime,
            waiting: request.waiting,
            ...row_data
          }
          return row_data;


        });
        setRows((prevState) => {
            return [...rows];
        })
      }

      formatRows();
        
    }, [requests, session.agent_data, session.ip_data, state.rooms, state.domains, hasFirewall, api]);


    useEffect(() => {

        let roomIds = uniq(requests.map((req) => {
            return req.room_id;
        }));
       
        let domainIds, domains, rooms;
        if (roomIds.length){
            rooms = state.rooms.reduce((acc, room) => {
                if(roomIds.indexOf(room.id) !== -1){
                  acc.push(room);
                } ;
                return acc;
            },[])
        }

        if (rooms.length) {
          domainIds = rooms.map((room) => {
            return room.domainID;
          })
        }

        if(domainIds.length) {
          domains = state.domains.reduce((acc, domain)=> {
            if(domainIds.indexOf(domain.id) !== -1){
              acc.push(domain);
            }
            return acc;
          },[])
        }

        setDomainList(domains)

    }, [state.domains, state.rooms, requests]);


    const AnomalyScore= useMemo(() => {

      let colors = {
        'green': '#00bf0c',
        'amber': '#ffa900',
        'red': '#FF0000',
      }

      
      let color = 'green';
      if (requestSession.score > 60) {
        color = 'red';
      } else if (requestSession.score > 29) {
        color = 'amber';
      } else {
        color = 'green';
      }

      return (
  
        <Typography variant="h6" style={{
          marginBottom: '12px'
        }}>Anomaly Score: <span style={{
          color: '#fff',
          fontWeight: 'bold',
          backgroundColor: colors[color],
          borderRadius: '6px',
          padding: '4px',
          minWidth: '24px',
          display: 'inline-block',
          textAlign: 'center',
        }}>{requestSession.score}</span></Typography>

      )

    }, [requestSession.score]);


    
    return (

        <>

          <Grid container style={{marginBottom: '12px'}}>

            
            <Grid item xs={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Button
                        size="small"
                        disabled={parseInt(queryParams.archivedUsers, 10)===1}
                        disableElevation
                        variant="contained"
                        color="secondary"
                        onClick={
                          (event) => {
                            promoteSessionHandler(requestToken)
                          }
                        }
                        >Promote Token</Button>
                  <Button style={{ marginLeft: 8 }}
                        size="small"
                        disableElevation
                        disabled={parseInt(queryParams.archivedUsers, 10)===1}
                        variant="contained"
                        color="secondary"
                        onClick={
                          (event) => {
                            deleteSessionHandler(requestToken)
                          }
                        }
                        >Delete Token</Button>

                  {domainList.length && state.plan.allowFirewall?
                  <Button style={{ marginLeft: 8 }}
                        size="small"
                        disableElevation
                        variant="contained"
                        color="secondary"
                        onClick={
                          () => {
                            addIPToListHandler(requestSession.ip, 'block', domainList)
                          }
                        }
                        >Block IP</Button>

                    : null }
                  {domainList.length && state.plan.allowFirewall ?
                  <Button style={{ marginLeft: 8 }}
                        size="small"
                        disableElevation
                        variant="contained"
                        color="secondary"
                        onClick={
                          () => {
                            addIPToListHandler(requestSession.ip, 'ignore', domainList)
                          }
                        }
                        >Ignore IP</Button>

                    : null }
                </Grid>

                {(ipIsInBlockList && (ipIsInBlockList.list=== 'block' || ipIsInBlockList.list === 'pass')) || (hasAutoblockData && hasAutoblockData.action === 'block')
                
                ?
                <Grid item>
                  <Card>
                    <CardContent style={{padding: '16px'}}>

                      <Grid container justifyContent="center" alignItems="center" spacing={2}>

                        <Grid item><AlertTriangleIcon/></Grid>
                        <Grid item>
                            {ipIsInBlockList && ipIsInBlockList.ip && ipIsInBlockList.list === 'block' ?
                              <Typography>{ipIsInBlockList.ip} is currently blocked</Typography>
                            :
                            null
                            }
                            {ipIsInBlockList && ipIsInBlockList.ip && ipIsInBlockList.list === 'pass' ?
                              <Typography>{ipIsInBlockList.ip} is currently bypassed</Typography>
                            :
                            null
                            }
                            {hasAutoblockData && hasAutoblockData.action === 'block'
                            ?
                            <Typography>{hasAutoblockData.address} was autoblocked on {DateTime.fromISO(hasAutoblockData.time).toLocaleString(session_date_format)}</Typography>
                            :
                            null
                            }

                        </Grid>
                      </Grid>

                      

                    </CardContent>
                  </Card>
                </Grid>
              :
              null
              }
              </Grid>

            </Grid>

            {hasAnomalyDetection?
            <Grid item xs={6}>
              <Card>
                <CardContent>

                  {AnomalyScore}
                  
                  {requestSession.anomolyData && requestSession.anomolyData.explanation ?
                    <>
                    {requestSession.anomolyData.explanation.map((anomoly, index) => {
                      if (anomoly) {
                        return <Typography key={index}>{anomoly}</Typography>
                      } else {
                        return null;
                      }
                    })}
                    </>
                  : null
                  }
                </CardContent>
              </Card>
            </Grid>
          : null 
          }
          </Grid>

        <DataGrid
          loading={loadingData}
          isCellEditable={() => {
            return false
          }}
          style={{
            fontFamily: 'Lato,sans-serif'
          }}
          // rowHeight={85} 
          disableSelectionOnClick={true}
          disableColumnFilter={true}
          disableColumnMenu={true}
          autoHeight={rows.length > 0}
          rows={rows}
          columns={columns}
          onCellClick={(params, event) => {
            if (params.field === 'agent') {
              setAgentData(params.value);
              setOpenAgentDialog(true);
            }

            if (params.field === 'ip_data' && hasFirewall) {

              if (params.value && params.value.ip) {
                setDrawerInfo(params.value);
                setOpenIPDataDialog(true);
              }

            }

          }}
         
          />

          <IPDataDialog openDialog={openIPDataDialog} handleDialogClose={handleDialogClose} drawerInfo={drawerInfo} />


        </>


    )

}


export default SessionRequestTable;