import React, {useState, useEffect} from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import EditIcon from '../../icons/EditIcon';
import { Link as RouterLink } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import { RoomsStatus } from "../rooms";

import { DateTime } from 'luxon';
import { ExternalLinkIcon } from '../../icons';

import Config from '../../config'
import { AddRoomActiveToRoom } from "../../lib"
import RoomWRLink from '../rooms/RoomWRLink';


import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Tooltip,
  Card,
  CardContent,
  Link,
  Button
} from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  table: {
    // maxWidth: 440,
  },
  paper: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  cell: {
    wordWrap: 'break-word',
    padding: '8px'
  },
  addButton: {
    marginTop: theme.spacing(1)
  }
}));

const StyledTableRow = withStyles((theme) => ({
  root: {
    '& th:nth-of-type(even)': {
      backgroundColor: theme.palette.grey[200],
    },
    '& td:nth-of-type(odd)': {
      backgroundColor: theme.palette.grey[200],
    }
  },
}))(TableRow);

export default function DashboardRoomsTable({ id, rooms, dashboardState, dispatchDash, api, dispatch }) {
  const classes = useStyles();
  let history = useHistory();



  let formattedRooms = rooms.reduce((acc, room) => {

    if (room.domainID === id) {
      let activateTime = DateTime.fromISO(room.queueActivatesOn);
      let countdownTime = DateTime.fromISO(room.countdownStartsOn);
      var now = DateTime.local();
  
      if (countdownTime < now) {
        room.countdownStartsOnFormatted = "Active";
      } else {
        room.countdownStartsOnFormatted = `${countdownTime.toFormat(Config.DATE_FORMATS.FULL_NO_TIME)} ${countdownTime.toFormat(Config.DATE_FORMATS.TIME_NO_DAY)}`
      }
  
  
      if (activateTime < now) {
        room.queueActivatesOnFormatted = "Active";
      } else {
        room.queueActivatesOnFormatted = `${activateTime.toFormat(Config.DATE_FORMATS.FULL_NO_TIME)} ${activateTime.toFormat(Config.DATE_FORMATS.TIME_NO_DAY)}`
      }
      acc.push(room)
    }

    return acc;
  },[]);

  let [viewWaitingRoomClicked, setViewWaitingRoomClicked] = useState(false);
  let [animClass, setAnimClass] = useState('icon_button_anim');

  useEffect(() => {
    if(dashboardState.info_panel.state === 'join_a_room' && !viewWaitingRoomClicked) {
      setAnimClass('icon_button_anim');
    } else {
      setAnimClass('')
    }
  }, [dashboardState.info_panel.state, viewWaitingRoomClicked]);

  const getRoomStatus = (room) => {

    return {
      queueActivatesIsActive: room.queueActivatesIsActive,
      countdownIsActive: room.countdownIsActive,
      protectingURLs: room.patternType !== 'disabled'
    }
  }


  return (
    <>


        <Grid item xs={12}>
          <Card>
            <CardContent>
              <TableContainer component='div'>
                <Table className={classes.table} aria-label="List of rooms for" >
                  <TableHead>
                    <TableRow>
                      <TableCell component="th" scope="row">Title</TableCell>
                      <TableCell>URL Pattern</TableCell>
                      <TableCell>Countdown Starts</TableCell>
                      <TableCell>Queue Activates</TableCell>
                      <TableCell>Join</TableCell>
                      <TableCell>Edit</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody >
                    {AddRoomActiveToRoom(formattedRooms).map((room, rowIndex) => (

                      <StyledTableRow key={room.id}>
                        <TableCell component="th" scope="row" style={{ width: '30%' }} className={classes.cell}>
                          <Link
                           href={`${Config.CH_WR_ENDPOINT}${room.slug}`}
                           target="_blank"
                         >
                          {room.title}
                          </Link>
                          </TableCell>
                        
                        <TableCell align="center" style={{ width: '20%' }} className={classes.cell} >
                          {(room.patternType === 'all') ? 'ALL' : null}
                          {(room.patternType === 'disabled') ? 'Disabled' : null}
                          {(room.patternType === 'contains') ? room.urlPattern : null}
                          {(room.patternType === 'contains-not') ? `NOT ${room.urlPattern}` : null}
                          {(room.patternType === 'regex') ? 'RegEx' : null}
                          {(room.patternType === 'regex-not') ? 'RegEx NOT' : null}
                        </TableCell>

                        <TableCell align="center" style={{ width: '20%' }} className={classes.cell} >{room.countdownStartsOnFormatted}</TableCell>
                        <TableCell align="center" style={{ width: '20%' }} className={classes.cell} >{room.queueActivatesOnFormatted}</TableCell>

                        <TableCell align="center">
                            <RoomWRLink color="secondary" cssClasses={
                              dashboardState.info_panel.state === 'join_a_room' ? "icon_button_anim" : ''
                            } api={api} slug={room.slug} countDown={room.countdownStartsOn} room={room} dispatch={dispatch}
                            onClick={
                              () => {

                                if(dashboardState.info_panel.state === 'join_a_room') {
                                  dispatchDash({type:'SET_WR_TEST_CLICKED'})
                                  setViewWaitingRoomClicked(true)
                                }
                              }
                            }/>
                           
                        </TableCell>
                        <TableCell align="center">

                          <Tooltip title="Edit">
                          <IconButton
                            className={dashboardState.info_panel.state === 'activate_a_room' ? "icon_button_anim" : ''}
                            onClick={() => {
                              history.push(`/rooms/edit/${room.id}`, {from:'dash', action: dashboardState.info_panel.state ? dashboardState.info_panel.state : null});
                            }}
                            >
                            <EditIcon color="secondary"/>
                          </IconButton>
                          </Tooltip>

                          
                        </TableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
            </Card>
        </Grid>
    </>
  );
}