import React, {useState, useRef} from 'react';

import {
    Grid,
    TableContainer,
    TablePagination,
    Table,
    TableRow,
    TableCell,
    TableBody,
    TableHead,
    Button,
    IconButton,
    
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import EnhancedTableHead from '../EnhancedTableHead';
import { Link as RouterLink } from 'react-router-dom';
import Skeleton from '@material-ui/lab/Skeleton';
import { DateTime } from "luxon";
import IPDataDialog from '../IPDataDialog';
import {IPData} from '../';
import { ChevronsLeftIcon, ChevronLeftIcon, ChevronsRightIcon, ChevronRightIcon } from '../../icons';


const headCells = [
    { id: 'address', numeric: false, disablePadding: false, label: 'Address' },
    { id: 'id_data', numeric: false, disablePadding: false, label: 'IP Info' },
    { id: 'reasons', numeric: false, disablePadding: false, label: 'Reasons' },
    { id: 'time', numeric: false, disablePadding: false, label: 'Date' },
    { id: 'action', numeric: false, disablePadding: false, label: 'Status' },
    { id: 'action_to_take', numeric: true, disablePadding: false, label: 'Action', width: 120 },
];
const AutoBlockTable = ({
    filteredBlocks,
    loading,
    handleFalsePositive,
    order,
    orderBy,
    handleRequestSort,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    count,
    ipGroups,
    processing
}) => {

    let [openDialog, setOpenDialog] = useState(false);
    let [drawerInfo, setDrawerInfo] = useState();

    const handleDialogClose = (event, reason) => {
        setDrawerInfo(null);
        setOpenDialog(false);
    }


    const saving = useRef(false);

    const FalsePositiveButton = ({block, falsePositive}) => {
        const handleFalsePositiveAction = async (block, falsePositive) => {

            saving.current = true;
            await handleFalsePositive(block, falsePositive);
            saving.current = false;
        }

        return <Button
            disabled={saving.current}
            size="small"
            variant="contained"
            disableElevation
            fullWidth
            color={falsePositive === 1 ? 'secondary' : 'default'} onClick={() => {
            handleFalsePositiveAction(block, falsePositive)
        }}>{falsePositive === 0 ? `Ignore` : `Block`}</Button>

    }

    return (
        <>
        <Grid container>
            <Grid item xs={12}>
                <TableContainer>
                    <Table>
                        {loading
                            ?
                            <>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Address</TableCell>
                                        <TableCell>IP Data</TableCell>
                                        <TableCell>Reasons</TableCell>
                                        <TableCell>Date</TableCell>
                                        <TableCell>Status</TableCell>
                                        <TableCell>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell><Skeleton variant="text" /></TableCell>
                                        <TableCell><Skeleton variant="text" /></TableCell>
                                        <TableCell><Skeleton variant="text" /></TableCell>
                                        <TableCell><Skeleton variant="text" /></TableCell>
                                        <TableCell><Skeleton variant="text" /></TableCell>
                                        <TableCell><Skeleton variant="text" /></TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><Skeleton variant="text" /></TableCell>
                                        <TableCell><Skeleton variant="text" /></TableCell>
                                        <TableCell><Skeleton variant="text" /></TableCell>
                                        <TableCell><Skeleton variant="text" /></TableCell>
                                        <TableCell><Skeleton variant="text" /></TableCell>
                                        <TableCell><Skeleton variant="text" /></TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><Skeleton variant="text" /></TableCell>
                                        <TableCell><Skeleton variant="text" /></TableCell>
                                        <TableCell><Skeleton variant="text" /></TableCell>
                                        <TableCell><Skeleton variant="text" /></TableCell>
                                        <TableCell><Skeleton variant="text" /></TableCell>
                                        <TableCell><Skeleton variant="text" /></TableCell>
                                    </TableRow>
                                </TableBody>
                            </>
                            :
                            null}

                        {!loading ?
                            <>
                                <EnhancedTableHead
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                    headCells={headCells}
                                />

                                <TableBody>

                                    {filteredBlocks.map((block, index) => (

                                            <TableRow key={index}>
                                                <TableCell>
                                                    {block.link ?
                                                        <RouterLink to={block.link}>{block.address}</RouterLink>
                                                        :
                                                        `${block.address}`
                                                    }
                                                </TableCell>
                                                <TableCell>{(block.ip_data) ? 
                                                <Grid  onClick={(event) => {
                                                    setDrawerInfo(block.ip_data);
                                                    setOpenDialog(true);
                                                  }}>
                                                      <IPData ip_data={block.ip_data}/>
                                                    </Grid>
                                                : ''}</TableCell>
                                                <TableCell>{block.reasons}</TableCell>
                                                <TableCell>{DateTime.fromISO(block.time).toLocaleString({ month: 'short', day: 'numeric', hour: "numeric", minute: "numeric", second: 'numeric', hour12: true, })}</TableCell>
                                                <TableCell>{block.action === 'block' ? 'Blocked' : 'Warning' }</TableCell>
                                                <TableCell>
                                                    
                                                <FalsePositiveButton falsePositive={block.falsePositive} block={block} processing={processing} />

                                                </TableCell>
                                            </TableRow>

                                        ))}
                                </TableBody>
                            </>

                            :
                            null
                        }

                    </Table>
                </TableContainer>

                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    component="div"
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                />
            </Grid>
        </Grid>
        <IPDataDialog handleDialogClose={handleDialogClose} openDialog={openDialog} drawerInfo={drawerInfo}/>
        </>

    )

}

const useStyles1 = makeStyles((theme) => ({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
  }));

export default AutoBlockTable;

function TablePaginationActions(props) {
    const classes = useStyles1();
    const { count, page, rowsPerPage, onPageChange } = props;
  
    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };
  
    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };
  
    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };
  
    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    return (
      <div className={classes.root}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
            <ChevronsLeftIcon/>
        </IconButton>
        <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
            <ChevronLeftIcon/>
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          <ChevronRightIcon/>
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          <ChevronsRightIcon/>
        </IconButton>
      </div>
    );
  }