import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from '@material-ui/core/styles';
import TemplatesTable from '../../components/TemplatesTable';
import CustomTemplatesTable from '../../components/CustomTemplatesTable';
import AddLogoTable from '../../components/AddLogoTable';
import groupBy from 'lodash/groupBy';
import Config from '../../config';
import { UpgradeCTAPanel } from '../../components';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import axios from 'axios';
import ErrorSnackBar from '../../components/ErrorSnackBar';
import { Link as RouterLink } from 'react-router-dom';

import {orderBy} from 'lodash';


import {
  Grid,
  Button,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Select,
  MenuItem,
  ListSubheader,
  Backdrop,
  CircularProgress,
  Link
} from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(6),
  },
  paper: {
    padding: theme.spacing(2),
  },
  buttonHeader: {
    padding: theme.spacing(0),
    marginBottom: theme.spacing(2),
  },
  templateDownloadButton: {
    lineHeight: 1.3,
    paddingTop: '12px',
    paddingBottom: '12px',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },

}));

const Templates = ({ api, state, ...rest }) => {

  const {dispatch} = rest;
  const classes = useStyles();
  const [openDeleteTemplateDialog, setOpenDeleteTemplateDialog] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [templateToDelete, setTemplateToDelete] = useState({});
  const allowCustomTemplates = state.plan.allowCustomTemplates;
  const [groupTemplates, setGroupTemplates] = useState({
    chTemplates: [],
    customTemplates: []
  });


  const maxFileSize = 500000; // 500kb

  let [message, setMessage] = useState('');
  let [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);

  const [templatePreviewDialogOpen, setTemplatePreviewDialogOpen] = useState(false);
  const [selectedRoom, setSelectedRoom] = useState();
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [roomsMarkup, setRoomsMarkup] = useState();

  const closeErrorSnackbar = () => {
      setOpenErrorSnackbar(false)
  }

  useEffect(() => {

    const setRooms = async () => {
      let rooms = await api.getRooms();
      dispatch({ type: 'UPDATE_ROOMS', payload: rooms });
    }

    if (!state.rooms) {
      setRooms();
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {

    const getPlans = async () => {
      let plan = await api.getPlan();
      dispatch({ type: 'SET_CURRENT_PLAN', payload: plan });
    }

    if (state.plans) {
      getPlans();
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const pageMounted = useRef(true);
  useEffect(() => {
      const callTemplates = async () => {
        let templates = await api.getTemplates();

        if(pageMounted.current) {
          dispatch({ type: 'UPDATE_TEMPLATES', payload: templates });
        }
      }
        callTemplates()
      
  },[api, dispatch])


  useEffect(() => {
    const formatCustomTemplates = () => {
      let newtemplates = groupBy(state.templates, 'custom');
      let groupTemplates = {
        chTemplates: [],
        customTemplates: []
      }

      for (const key in newtemplates) {
        if (newtemplates.hasOwnProperty(key)) {
          let container;
          if (parseInt(key) === 0) {
            container = groupTemplates.chTemplates;
          } else {
            container = groupTemplates.customTemplates;
          }
    
          for (let i = 0; i < newtemplates[key].length; i++) {
            container.push(newtemplates[key][i]);
          }
        }
      }

      let path = `https://${Config.AMPLIFY.Storage.S3.bucket}.s3.amazonaws.com/public/${state.accountInformation.get('customer').id}`;

      groupTemplates.customTemplates = groupTemplates.customTemplates.reduce((acc, item)=>{

        if(item.url) {
          if (item.url.indexOf(path) !== -1) {
            item.isFile = true;
          } else {
            item.isFile = false;
          }

        }
        acc.push(item);
        return acc;
      },[]);

      groupTemplates.customTemplates = orderBy( groupTemplates.customTemplates, [t => t.name.toLowerCase()], ['desc']);

      groupTemplates.customTemplates = groupTemplates.customTemplates.reverse();
      setGroupTemplates(groupTemplates)
    }

    if(state.templates){
      formatCustomTemplates();
    }

  }, [state.accountInformation, state.templates, state.user.attributes]);


  const deleteTemplateHandler = (room) => {
    setTemplateToDelete(room);
    setOpenDeleteTemplateDialog(true);
  }

  const handleCancel = () => {
    setTemplateToDelete({});
    setOpenDeleteTemplateDialog(false)
  }

  const handleOk = () => {
    const temp_copy = {...templateToDelete}
    let result = api.deleteTemplate(templateToDelete.id);
    
    setOpenDeleteTemplateDialog(false)
    setShowLoader(true);

   

    result.then((response) => {
      if (response.error) {

        if(response.error.trim() === "Cannot delete this template, it is being used") {
          const rooms_template_is_used_on = state.rooms.filter((room) => {
            return temp_copy.id === room.templateID;
          })

          setMessage(<Typography>{response.error} - {rooms_template_is_used_on.map((room) => {
            return  <Link target="_blank" style={{marginRight: 4, marginLeft: 4, color: '#fff', textDecoration:'underline'}} key={room.id} href={`/rooms/edit/${room.id}`}>
            {room.title}
           </Link>
          })}</Typography>);
        } else {

          setMessage(`${response.error}`);
        }


        setShowLoader(false);

        setOpenErrorSnackbar(true);
      } else {
        dispatch({ type: 'UPDATE_TEMPLATES', payload: response });
        setShowLoader(false);
      }

      setTemplateToDelete({});
    })
      .catch(function (error) {
        setMessage('Error deleting template');
        setOpenErrorSnackbar(true);
        setShowLoader(false);
        setTemplateToDelete({});
      });
  }

  const handleDownloadTemplate = async (url, title) => {
    const regex = /(\.html$|\.htm$)/gm;
    let filename = title.replace(regex, '');

    const headers = {
      'Cache-Control': 'no-cache',
    };

    const doc = await axios({
      method: 'get',
      url,
      headers
    });

    var zip = new JSZip();
    zip.file(`${filename}.html`, doc.data)
    zip.generateAsync({type:"blob"})
    .then((content) => {
        saveAs(content, `${filename}.zip`);
    });

  }

  const handleTemplatePreviewDialogClose = () => {
    setTemplatePreviewDialogOpen(false);
  }

  const handleSelectedRoom = (roomID) => {
    setSelectedRoom(roomID);
  }


  useEffect(() => {
    const makeRoomsMarkup = () => {

      let domain_group = groupBy(state.rooms, 'domainID');

      let markup_groups = [];

      for (const key in domain_group) {
        if (Object.hasOwnProperty.call(domain_group, key)) {
          const element = domain_group[key];
          let domain = state.domains.find(d => d.id === key);

          markup_groups.push(<ListSubheader key={domain.id}>{domain.name}</ListSubheader>);
          element.forEach(room => {
            markup_groups.push(
              <MenuItem value={room.id} key={room.id}>{room.title}</MenuItem>
            )
            });
        }
      }
      setRoomsMarkup(markup_groups);
    }
    if(state.rooms && state.rooms.length && state.domains) {
      makeRoomsMarkup();
    }
  }, [state.domains, state.rooms]);

  const handleSetSelectedTemplate = (template) => {
    setSelectedTemplate(template);
    setTemplatePreviewDialogOpen(true);
  }

  useEffect(() => {
    const setTheSelectedRoom = () => {
      setSelectedRoom(state.rooms[0].id);
    }

    if(state.rooms && state.rooms.length) {
      setTheSelectedRoom();
    }
    
  }, [state.rooms]);

  return (

    <div className={classes.root}>
      <Grid container spacing={0} direction="column" wrap="nowrap">
        <Grid style={{marginBottom: 18}}>
          <AddLogoTable state={state} api={api} dispatch={dispatch} allowCustomTemplates={allowCustomTemplates}/>
        </Grid>

        <Grid>
        <TemplatesTable
          logo={state.accountInformation.get('account').logoURL}
          templates={groupTemplates.chTemplates}
          name={'Templates'}
          handleDownloadTemplate={handleDownloadTemplate}
          allowCustomTemplates={allowCustomTemplates}
          roomsMarkup={roomsMarkup}
          handleSetSelectedTemplate={handleSetSelectedTemplate}
          rooms={(state.rooms)? state.rooms : []}
        />

        {allowCustomTemplates
        ?
        <CustomTemplatesTable
          maxFileSize={maxFileSize}
          logo={state.accountInformation.get('account').logoURL}
          customer={state.accountInformation.get('customer')}
          api={api}
          handleDownloadTemplate={handleDownloadTemplate}
          templates={groupTemplates.customTemplates}
          name={'Custom Templates'}
          isCustom={true}
          deleteTemplateHandler={deleteTemplateHandler}
          setMessage={setMessage}
          setOpenErrorSnackbar={setOpenErrorSnackbar}
          rooms={(state.rooms)? state.rooms : []}
          domains={state.domains}
          roomsMarkup={roomsMarkup}
          handleSetSelectedTemplate={handleSetSelectedTemplate}
          {...rest}
          />
        :
        <UpgradeCTAPanel upgradeMessage="Upgrade to create and use custom templates" displayUpgradeButton={true} />
        }
        </Grid>
        
      </Grid>

      <Dialog
        disableEscapeKeyDown
        maxWidth="xs"
        aria-labelledby="confirmation-dialog-title"
        open={openDeleteTemplateDialog}
        onClose={(event, reason) => {
          if (reason === 'backdropClick') {
            return;
          }
          setOpenDeleteTemplateDialog(false);
        }}
      >
        <DialogTitle id="confirmation-dialog-title">Delete Template?</DialogTitle>
        <DialogContent dividers>
          <Typography>{`Are you sure you want to delete the template ${templateToDelete.name}?`}</Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCancel} color="primary">Cancel</Button>
          <Button onClick={handleOk} color="secondary">Ok</Button>
        </DialogActions>
      </Dialog>

      <ErrorSnackBar message={message} open={openErrorSnackbar} closeErrorSnackbar={closeErrorSnackbar} />

      <Backdrop className={classes.backdrop} open={showLoader}>
          <CircularProgress color="inherit" />
      </Backdrop>


      <Dialog maxWidth='sm' fullWidth open={templatePreviewDialogOpen} onClose={handleTemplatePreviewDialogClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Select Room</DialogTitle>
        <DialogContent>
          <Select
            value={selectedRoom}
            onChange={(e) => {
              handleSelectedRoom(e.target.value);
            }}
          >
            {

            state.rooms ?
            
            
            roomsMarkup

            :
            null
            
            }
          </Select>

        </DialogContent>
        <DialogActions>

        <Button
        component={RouterLink}
        disableElevation color="secondary" variant="contained"
        to={`/template-preview/${selectedTemplate.id}/${selectedRoom}`}
       
      > Preview</Button>

          <Button color="primary" disableElevation onClick={() => {
            setTemplatePreviewDialogOpen(false)
          }}>Cancel</Button>


        </DialogActions>
      </Dialog>


    </div>
  );
};

export default Templates;