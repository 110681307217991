import React from 'react';
import { ReactComponent as Box } from './feather/box.svg';

import { SvgIcon } from '@material-ui/core';


export default function RoomsIcon(props) {
    return (
        <SvgIcon {...props} component={Box} className="feather"/>
    );
  }