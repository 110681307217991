import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    Chip,
    Divider,
    Card,
    CardContent,
    Typography,
} from '@material-ui/core';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import {AuthContext} from '../../providers/AuthProvider';


import CreditCardIcon from '../../icons/CreditCardIcon';
import PrimaryButton from '../PrimaryButton';

const CARD_ELEMENT_OPTIONS = {
    hidePostalCode: true,
    style: {
        base: {
            color: "#32325d",
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
                color: "#aab7c4",
            }
        },
        invalid: {
            color: "#fa755a",
            iconColor: "#fa755a",
        },
       
    },
};


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    stripeCard: {
        minWidth: '640px'
    }
}));
export default function AccountCards({ accountInformation, handleUpdatePaymentMethod, api, state, dispatch, setShowLoader, setMessage, setOpenErrorSnackbar, setAlertBarStatus }) {
    const chAuth = useContext(AuthContext);
    const classes = useStyles();
    const stripe = useStripe();
    const elements = useElements();
    let [cardOpen, setCardOpen] = useState(false);

    const handleAddCardClose = () => {

    }

    const updateDefaultPaymentMethod = async (paymentId) => {
        setShowLoader(true)
        const { jwtToken } = await chAuth.getJwtToken() ?? {};
        let customer = await api.updateDefaultPaymentMethod(paymentId, jwtToken);
        dispatch( {type: 'UPDATE_ACCOUNT_INFORMATION', payload: {
            customer
        }
    });
        setShowLoader(false)
        // setOpen(false)
    }

    const handlerAddCard = async (event) => {

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            setShowLoader(false)
            return;
        }
        const card_element = elements.getElement(CardElement);
        
        setShowLoader(true)

        if(state.accountInformation.get('user').attributes['custom:role'] !== '1'){
            return;
        }
        const { jwtToken } = await chAuth.getJwtToken() ?? {};
        let result = await api.addCard(undefined, jwtToken);

        setCardOpen(false);
        if (result) {
            const setupResult = await stripe.confirmCardSetup(result.client_secret, {
                payment_method: {
                    card: card_element
                }
            });

            if (setupResult.error) {
                // Display result.error.message in your UI.
                setShowLoader(false);
                setMessage(setupResult.error.message);
                setAlertBarStatus('error');
                setOpenErrorSnackbar(true);
            } else {
                // The setup has succeeded. Display a success message and send
                // result.setupIntent.payment_method to your server to save the
                // card to a Customer
                // let paymentAttachedResult = await api.addCard(setupResult.setupIntent.payment_method);
                let newPaymentMethod = await api.attachPaymentMethodToCustomer(setupResult.setupIntent.payment_method, jwtToken);

                let card = newPaymentMethod.card;
                card.id = newPaymentMethod.id;
                card.billing_details = newPaymentMethod.billing_details
                let cards = [card];
                for (let i = 0; i < state.accountInformation.get('cards').length; i++) {
                    const element = state.accountInformation.get('cards')[i];
                    cards.push(element);
                }
                
                updateDefaultPaymentMethod(setupResult.setupIntent.payment_method);
                
                setShowLoader(false);
                dispatch({type:'UPDATE_ACCOUNT_INFORMATION', payload:{
                    cards: cards,
                    paymentMethod: newPaymentMethod
                }})

                setMessage('Card added');
                setAlertBarStatus('success');
                setOpenErrorSnackbar(true);
            }
        } else {
            setShowLoader(false)
        }


    }

    return (
        <>
            <Card>
            <CardContent>
            <Typography variant="h5">Cards</Typography>
            <List
                component="nav"
                aria-labelledby="nested-list-subheader"
               
                className={classes.root}
            >

                {accountInformation.get('cards')
                    ?

                    accountInformation.get('cards').map((card, index) => {

                        return (
                            <CardRow
                            key={index}
                            state={state}
                            updateDefaultPaymentMethod={updateDefaultPaymentMethod}
                            dispatch={dispatch}
                            card={card}
                            api={api}
                            currentPaymentMethod={accountInformation.get('customer').invoice_settings.default_payment_method}
                            setShowLoader={setShowLoader}
                            setMessage={setMessage}
                            setAlertBarStatus={setAlertBarStatus}
                            setOpenErrorSnackbar={setOpenErrorSnackbar}
                            />
                        )
                        // return <FormControlLabel key={card.id} value={`${card.id}`} control={<Radio />} label={`${card.brand.toUpperCase()} ending in ${card.last4} Expires ${card.exp_month}/${card.exp_year} `} />
                    })

                    :
                    null
                }

                {
                    (state.accountInformation.get('user').attributes['custom:role'] === '1' )
                    ?

                    <ListItem>
                        <Button variant="contained" disableElevation color="secondary" size="small" onClick={() => { setCardOpen(true) }}>Add Card</Button>
                    </ListItem>

                    :
                    null
                }


            </List>
            </CardContent>
            </Card>


            <Dialog maxWidth='sm' fullWidth open={cardOpen} onClose={handleAddCardClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Add card</DialogTitle>
        <DialogContent>

          <CardElement options={CARD_ELEMENT_OPTIONS} />
        </DialogContent>
        <DialogActions>
           
                <Button type="submit" disableElevation color="secondary" variant="contained" disabled={!stripe} onClick={(event)=>{
                    handlerAddCard(event)
                }}>Save Card</Button>
                <Button color="primary" disableElevation onClick={() => {
                    setCardOpen(false)
                }}>Cancel</Button>

          
        </DialogActions>
      </Dialog>

        </>

    );
}

const CardRow = ({ card, currentPaymentMethod, state, api, dispatch, setShowLoader, updateDefaultPaymentMethod, setMessage, setAlertBarStatus, setOpenErrorSnackbar }) => {
    const chAuth = useContext(AuthContext);
    const removeCard = async (paymentId) => {
        setShowLoader(true)
        const { jwtToken } = await chAuth.getJwtToken() ?? {};
        let result = await api.removePaymentPlan(paymentId, jwtToken);

        let cards = state.accountInformation.get('cards').reduce((acc, card) => {
            if (card.id !== result.id) {
                acc.push(card);
            }
            return acc;
        },[]);
        
        dispatch({type:'UPDATE_ACCOUNT_INFORMATION', payload:{
            cards: cards,
        }});
        
        setMessage('Card removed');
        setAlertBarStatus('success');
        setOpenErrorSnackbar(true);
        setShowLoader(false);

    }

    return (
        <>
            {/* <ListItem button onClick={handleClick}> */}
            <ListItem>
                {currentPaymentMethod !== card.id
                ?
                <Button onClick={() => {removeCard(card.id)}}>X</Button>
                :
                null
                }
                <ListItemIcon>
                    <CreditCardIcon />
                </ListItemIcon>
                <ListItemText primary={
                    `${card.brand.toUpperCase()} ending in ${card.last4}`
                } />
                {(currentPaymentMethod !== card.id) ? <PrimaryButton
                            onClick={()=>{updateDefaultPaymentMethod(card.id)}}
                            >Make Prefered</PrimaryButton> : null }
                {(currentPaymentMethod === card.id) ? <Chip color="primary" label="Preferred Payment Method" /> : null }
                {/* {open ? <ChevronUpIcon /> : <ChevronDownIcon />} */}
                
            </ListItem>
            <Divider />
           
        </>
    )

}
