import React, { useState, useEffect, useReducer, useContext, useRef } from "react";

import {AuthContext} from '../../providers/AuthProvider';

import { makeStyles } from '@material-ui/core/styles';
import {
    Grid,
    List,
    ListItem,
    Button,
    FormControl,
    InputLabel,
    OutlinedInput,
    Typography,
    Dialog,
    Switch,
    FormControlLabel,
    DialogTitle,
    DialogContent,
    DialogActions,
    Select,
    MenuItem,
    Divider,
} from "@material-ui/core";
import ErrorSnackBar from '../ErrorSnackBar';
import AccountAddUser from './AccountAddUser';
import AccountUserRow from './AccountUserRow';
import UpgradeCTAPanel from '../UpgradeCTAPanel';
import { MailIcon } from "icons";

import sortBy from 'lodash/sortBy';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        width: '100%'
    },
    accountDetailsList: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    accountDetailsListItem: {

    }
}));

const AccountDetails = ({ state, api, dispatch, setShowLoader, setValue, rest }) => {

    const chAuth = useContext(AuthContext);
    const classes = useStyles();
    const {signOut} = rest;

    let [message, setMessage] = useState('');
    let [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
    let [alertBarStatus, setAlertBarStatus] = useState('success');

    const [emailPrefsUpdating, setEmailPrefsUpdating] = useState(true);
    const initialState = {
        all_users: [],
        logged_in_user : [],
        account_user: []
      };

    const reducer = (state, action) => {
        switch (action.type) {
            case 'UPDATE_ALL_USERS':
            return {
                ...state,
                all_users: action.params,
            };
            case 'UPDATE_ACCOUNT_USER':
            return {
                ...state,
                account_user: action.params,
            };
            default:
            return state;
        }
    };

    const isMounted = useRef(true)

    const [account_state, account_dispatch] = useReducer(
    reducer,
    initialState
    );

    useEffect(() => {


        let users = [];

        if (state.accountInformation.get('customer') && state.accountInformation.get('users').length) {

            users = state.accountInformation.get('users').map((user) => {
                if (user.email === state.accountInformation.get('customer').email) {
                    user.accountHolder = true;
                    setSelectedAccountHolder(user.username)
                    setPrimaryAccountHolder(user.username)
                } else {
                    user.accountHolder = false;
                }
                return user;
            })


            users = sortBy(users, (user) => {
                if (user.accountHolder) {
                    return -1;
                }
            })
        }
        
        account_dispatch({
            type: 'UPDATE_ALL_USERS', params: users
        })

        account_dispatch({
            type: 'UPDATE_CUSTOMER', params: state.accountInformation.get('customer')
        })
    }, [state.accountInformation]);


    const [canAddUser, setCanAddUser] = useState(false);
    const [displayUsers, setDisplayUsers] = useState(true);

    const addUserUpgradeMessage = 'Your current plan doesn’t allow for more users to be added';

    let [openPassword, setOpenPassword] = useState(false);
    let [passwordFields, setPasswordFields] = useState({
        newPassword: '',
        oldPassword: ''
    });

    const handlePasswordFields = (event) => {
        setPasswordFields({
            ...passwordFields,
            [event.target.name]: event.target.value
        })
    }

    const handleCompleteNewPassword = async () => {

        let {cognitoUser} = await chAuth.currentSession()

        cognitoUser.changePassword(passwordFields.oldPassword, passwordFields.newPassword,  (err, result) => {
            if (err) {
                let message = 'There has been a problem updating your password';

                switch (err.code) {
                    case 'LimitExceededException':
                        message = 'You have tried too many times. Please try again later'
                        break;
                    case 'InvalidPasswordException':
                        message = err.message;
                        break;

                    default:
                        break;
                }
                setAlertBarStatus('error')
                setMessage(message);
                setOpenErrorSnackbar(true);
            } else {
                setAlertBarStatus('success')
                setMessage('Password changed');
                setOpenErrorSnackbar(true);
                setOpenPassword(false);
            }
      
          });
    }


    let [primaryAccountHolder, setPrimaryAccountHolder] = useState('');
    let [selectedAccountHolder, setSelectedAccountHolder] = useState();
    let [openPrimaryAccountHolderDialog, setOpenPrimaryAccountHolderDialog] = useState(false);

    const accountHolderChangeHandler = (user) => {
        setSelectedAccountHolder(user)
    }


    const closeErrorSnackbar = () => {
        setOpenErrorSnackbar(false)
    }

    const [emailPrefs, setEmailPrefs] = React.useState({
        subscribed: false,
      });

    useEffect(() => {
        const getMailPreferences = async() => {
            setEmailPrefsUpdating(true);
            const {jwtToken}  = await chAuth.getJwtToken();
            const {UserAttributes} = await chAuth.getAttributes();

            try {
                
                const email = UserAttributes.filter((att) => {
                    return att.Name === 'email'
                });
                let res = await api.getMailPreferences(email[0].Value, jwtToken);
    
                if (isMounted.current) {
                    if (res === 'subscribed') {
                        setEmailPrefs({
                            ...emailPrefs,
                            subscribed: true,
                        });
                        setEmailPrefsUpdating(false);
                      
                    } else {
                        setEmailPrefs({
                            ...emailPrefs,
                            subscribed: false,
                        });
                        setEmailPrefsUpdating(false);
                        
                    }
                }
            } catch (error) {
                console.warn(error)
            }   


        }
       
        getMailPreferences();

        return () => {
            isMounted.current = false;
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {

        const setUserLimits = () => {
            let numberOfUsers = state.accountInformation.get('users').length || state.accountInformation.get('users').length === 0;
            let maxUsers = state.plan.maxUsers;
            if (numberOfUsers < maxUsers) {
                setCanAddUser(true);
            } else {
                setCanAddUser(false);
            }

            if (numberOfUsers > 1) {
                setDisplayUsers(true)
            }
        }
        if (state.plan) {
            setUserLimits()
        }
       
    }, [state.plan, state.accountInformation]);


    const handleAddUser = async (values) => {

        const {jwtToken}  = await chAuth.getJwtToken();

            setShowLoader(true);

            let roleId;
            switch (values.role) {
                case true:
                    roleId = 1;
                    break;

                default:
                    roleId = 2;
                    break;
            }

            let customer = state.accountInformation.get('customer').name;

            // get the region. If any of the users are eu users then add them to the eu region
            let is_eu = false;
            state.accountInformation.get('users').forEach(user => {
                if (user.region && user.region === 'eu') {
                    is_eu = true;
                }
            });

            let ClientMetadata =  { 
                'cus' : customer,
                'reason' : 'invited',
                'region' : is_eu ? 'eu' : 'us'
            };

            let result = await api.addUser(state.accountInformation.get('customer').id, values.emailAddress, roleId, jwtToken, ClientMetadata);
            
            if (result.error) {
                setMessage(result.error.message);
                setAlertBarStatus('error');
                setOpenErrorSnackbar(true);
                setShowLoader(false);

                return false;
            } else {
                
                setShowLoader(false);
                setMessage('User added.');
                setAlertBarStatus('success');
                setOpenErrorSnackbar(true);
                let users = state.accountInformation.get('users').map((user) => {
                    return user;
                });
                result.accountHolder = false;
                result.region = ClientMetadata.region;
                users.push(result);
                dispatch({
                    type: 'UPDATE_ACCOUNT_INFORMATION', payload: {
                        users: users,
                    }
                });
                return true;

            }

    }

    const handleEmailPrefs = async (name, value) => {

            setEmailPrefsUpdating(true);
            
            setShowLoader(true);        
            const {jwtToken}  = await chAuth.getJwtToken();
            const {UserAttributes} = await chAuth.getAttributes();
            const email = UserAttributes.filter((att) => {
                return att.Name === 'email'
            });
            let result = await api.setMailPreferences(email[0].Value, (value) ? 'subscribed' : 'unsubscribed', jwtToken);
            if (result.error || (result.response && result.response !== 200)) {
                setMessage('Unable to update your email preferences. Please try again later.');
                setAlertBarStatus('error');
                setOpenErrorSnackbar(true);
                setShowLoader(false);
            } else {
                setMessage('Email preferences updated');
                setAlertBarStatus('success');
                setOpenErrorSnackbar(true);
                setShowLoader(false);
                setEmailPrefs({ ...emailPrefs, subscribed: (result === 'subscribed') ? true : false });
            }

            setEmailPrefsUpdating(false);
    
    }

    const handlerUpdatePrimaryAccount = async () => {
        const {jwtToken}  = await chAuth.getJwtToken();

        let user = account_state.all_users.find((user) => {
            return user.username === selectedAccountHolder;
        });

        setShowLoader(true);

        try {
            
            let response = await api.updatePrimaryAccount(user.email, jwtToken);
            if (response.result === 'success') {
                dispatch({type:'UPDATE_ACCOUNT_INFORMATION', payload:{
                    customer : response.customer
                }});
                setPrimaryAccountHolder(selectedAccountHolder);
                setMessage('Primary account holder updated');
                setAlertBarStatus('success');
                setOpenErrorSnackbar(true);
    
            } else {
                setMessage(response.error);
                setAlertBarStatus('error');
                setOpenErrorSnackbar(true);
            }
        } catch (error) {
            setMessage('Unable to update primary account holder');
            setAlertBarStatus('error');
            setOpenErrorSnackbar(true);
            setShowLoader(false);
            
        }


        setShowLoader(false);

    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>

                {state.accountInformation.get('user') && state.accountInformation.get('user').attributes
                ? 
                <>
               <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-start"
                    spacing={2}
                >
                    <Grid item>
                        <Typography variant="h6">{state.accountInformation.get('user').attributes.email}</Typography>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Button size="small" color="secondary" variant="contained" disableElevation  onClick={() => {
                            setOpenPassword(!openPassword);
                            setPasswordFields({
                                newPassword: '',
                                oldPassword: ''
                            });
                        }}
                        >{
                            `Change Password`
                        }</Button>
                    </Grid>
                </Grid>

                <Grid container
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-start"
                    spacing={2}>
                <Grid item xs={12}>
                
                <Grid container alignItems="center">
                        {
                            state.accountInformation
                                ?
                                <>
                                    <MailIcon style={{marginRight: '10px'}} onClick={() => {

                                        let value = !emailPrefs.subscribed;
                                        handleEmailPrefs('subscribed', value)
                                        
                                    }}/>
                                    <FormControlLabel
                                        control={<Switch
                                        disabled={emailPrefsUpdating}
                                        checked={emailPrefs.subscribed}
                                        onChange={(event) => {
                                            handleEmailPrefs(event.target.name, event.target.checked)
                                        }}
                                        name="subscribed"
                                        inputProps={{ 'aria-label': 'Email me' }} />}
                                        label={`Receive email updates`}
                                />
                                </>

                                
                                :
                                null
                        }

                </Grid>
                <Divider/>
                </Grid>
                
                </Grid>
                </>
                : null
                }
                </Grid>
                <Grid item xs={12}>

                    <List className={classes.accountDetailsList} component="div">

                        {
                            displayUsers

                                ?
                                <>
                                <ListItem component="div">
                                    <Typography variant="subtitle1">{state.accountInformation.get('user').attributes['custom:role'] === '1' ? `Manage ` : null } Users</Typography>
                                </ListItem>
                                {account_state.all_users.map((user, i) => {
                                    return (

                                      <AccountUserRow
                                            key={user.username}
                                            user={user}
                                            api={api}
                                            state={state}
                                            dispatch={dispatch}
                                            setShowLoader={setShowLoader}
                                            canAddUser={canAddUser}
                                            accountHolderChangeHandler={accountHolderChangeHandler}
                                            selectedAccountHolder={selectedAccountHolder}
                                            openPassword={openPassword}
                                            setOpenPassword={setOpenPassword}
                                            setPasswordFields={setPasswordFields}
                                            displayAccountHolderButton={account_state.all_users.length > 1}
                                            handlerUpdatePrimaryAccount={handlerUpdatePrimaryAccount}
                                            signOut={signOut}
                                            >
                                    </AccountUserRow>


                                    )
                                })}
                                {state.accountInformation.get('user').attributes['custom:role'] === '1' && account_state.all_users.length > 1 ? 
                                    <ListItem component="div">                                    
                                        <Button color="secondary" variant="contained" disableElevation size="small" onClick={() => {
                                            setOpenPrimaryAccountHolderDialog(true)
                                        }}>
                                            Change primary account holder
                                        </Button>
                                    </ListItem>
                                : null }

                                <ListItem component="div">                                    
                                    <Typography variant="subtitle2">administrators can manage users and access billing information</Typography>
                                </ListItem>
                                </>
                                :

                                null


                        }



                    </List>
                </Grid>

                {canAddUser
                ?
                <Grid item xs={12}>
                    {(state.accountInformation.get('user').attributes['custom:role'] === '1' || state.accountInformation.get('user').attributes['custom:role'] === '0')
                        ?
                        <>
                        <AccountAddUser handleAddUser={handleAddUser} />
                        </>
                        :
                        null
                    }
                </Grid>
                :
                <UpgradeCTAPanel upgradeMessage={addUserUpgradeMessage} displayUpgradeButton={false}/>
                }

            </Grid>

            <Dialog open={openPrimaryAccountHolderDialog} onClose={() => {
                    setOpenPrimaryAccountHolderDialog(false)
                    }}>
                         <DialogTitle>Change Primary Account Holder</DialogTitle>
                        <DialogContent>
                        <FormControl fullWidth margin="dense" variant="outlined">
                    <Select
                        size="small"
                        type="text"
                        name="user"
                        label="User"
                        notched={false}
                        value={selectedAccountHolder}
                        onChange={(e) => {
                            setSelectedAccountHolder(e.target.value)
                        }}
                    >


                        {account_state.all_users.map((user) => {
                            return <MenuItem key={user.username} value={user.username}>{user.email}</MenuItem>
                        })}



                    </Select>
                </FormControl>
                        </DialogContent>

                        <DialogActions>
                            <Button
                                size="small"
                                disableElevation
                                color="primary" onClick={() => {
                                    setSelectedAccountHolder(primaryAccountHolder)
                                    setOpenPrimaryAccountHolderDialog(false);
                                     }}>Cancel</Button>
                            <Button
                                size="small"
                                disableElevation
                                color="secondary" onClick={() => {
                                    handlerUpdatePrimaryAccount();
                                    setOpenPrimaryAccountHolderDialog(false);
                                }}>Save</Button>
                    </DialogActions>
                    
                    </Dialog>

                <Dialog open={openPassword} onClose={() => {
                    setOpenPassword(false)
                    }}>
                    <DialogTitle>Change Password</DialogTitle>
                    <DialogContent>
                    <FormControl fullWidth margin="dense" variant="outlined">
                                <InputLabel htmlFor="username">Current Password</InputLabel>
                                <OutlinedInput
                                    type="password"
                                    id='oldPassword'
                                    name='oldPassword'
                                    value={passwordFields.oldPassword}
                                    onChange={(event) => handlePasswordFields(event)}
                                    labelWidth={118}
                                    placeholder="Current Password"
                                    aria-label="Current Password"
                                ></OutlinedInput>
                            </FormControl>
                            <FormControl fullWidth margin="dense" variant="outlined">
                                <InputLabel htmlFor="username">New Password</InputLabel>
                                <OutlinedInput
                                    type="password"
                                    id='newPassword'
                                    name='newPassword'
                                    value={passwordFields.newPassword}
                                    onChange={(event) => handlePasswordFields(event)}
                                    labelWidth={94}
                                    placeholder="New Password"
                                    aria-label="New Password"
                                ></OutlinedInput>
                            </FormControl>


                    </DialogContent>
                    <DialogActions>
                            <Button
                                size="small"
                                disableElevation
                                color="primary" onClick={() => { setOpenPassword(false) }}>Cancel</Button>
                            <Button
                                size="small"
                                disableElevation
                                color="secondary" onClick={() => { handleCompleteNewPassword() }}>Submit New Password</Button>
                    </DialogActions>
                </Dialog>

            <ErrorSnackBar message={message} open={openErrorSnackbar} status={alertBarStatus} closeErrorSnackbar={closeErrorSnackbar} />
        </>

    )
}

export default AccountDetails;
