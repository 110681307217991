import React from 'react';

import {
    FormControl,
    OutlinedInput,
    FormHelperText,
    InputLabel,
    Typography,
  } from '@material-ui/core';


const RoomTitle = ({formik}) => {
    return (
        <FormControl fullWidth margin="normal" variant="outlined">
        <InputLabel htmlFor="title">Waiting Room</InputLabel>
        <OutlinedInput
            id="title"
            labelWidth={90}
            placeholder="Waiting Room"
            aria-label="Waiting Room Title"
            {...formik.getFieldProps('title')}
            style={{fontSize: '18px'}}
        />
        <FormHelperText>This name will be displayed to your users</FormHelperText>
        {formik.touched.title && formik.errors.title ? <Typography variant="subtitle2" color="error">{formik.errors.title}</Typography> : null}
        </FormControl>
    )
}

export default RoomTitle;