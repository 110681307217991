import React, {useState, useEffect} from "react";
import {
  IconButton,
  Tooltip,
} from '@material-ui/core';
import EyeIcon from '../icons/EyeIcon';

const TemplatePreviewButton = ({handleSetSelectedTemplate, template, rooms}) => {

  let [templateURL, setTemplateURL] = useState();

  useEffect(() => {

    const buildTemplateURL = () => {
      setTemplateURL(`/template-preview/${template.id}/${rooms[0].id}`);
    }
    if(template && rooms.length) {
      buildTemplateURL();
    }
    
  }, [template, rooms])

    return (
      <>
        {rooms && rooms.length > 1
        ?
        <Tooltip title="Preview this Template" aria-label="Preview this Template">
          <IconButton onClick={() => {

            handleSetSelectedTemplate(template)
          }} >
            <EyeIcon></EyeIcon>
          </IconButton>
         </Tooltip>
        :
        null
        }
        {rooms && rooms.length === 1 && templateURL
        ?
        <Tooltip title="Preview this Template" aria-label="Preview this Template">
          <IconButton target="_blank" rel="noopener noreferrer" href={templateURL} >
            <EyeIcon></EyeIcon>
          </IconButton>
         </Tooltip>
        :
        null
      }
      
        </>
      

    )
}


export default TemplatePreviewButton;