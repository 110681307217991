import React from 'react';
import { ReactComponent as AlertTriangle } from './feather/alert-triangle.svg';

import { SvgIcon } from '@material-ui/core';


export default function AlertTriangleIcon(props) {
    return (
        <SvgIcon {...props} component={AlertTriangle} className="feather"/>
    );
  }