import React from 'react';
import { ReactComponent as Monitor } from './feather/monitor.svg';

import { SvgIcon } from '@material-ui/core';


export default function MonitorIcon(props) {
    return (
        <SvgIcon {...props} component={Monitor} className="feather"/>
    );
  }