import React, {useState} from "react"
import {
    Grid,
    Slider,
    Typography,
    FormControl
} from '@material-ui/core';

const MaxIPAddressesControl = ({ formik, initialValue, allowFirewall, autoblockProcessing }) => {

    let [maxSessionsPerIP, setMaxSessionsPerIP] = useState(initialValue);

    const onSliderChangeCommitted = (event, value) => {
        formik.setFieldValue('maxSessionsPerIP', value);
        formik.handleSubmit()
    }
    const onSliderChange = (event, value) => {
        setMaxSessionsPerIP(value);
    }


    let marks = [
        {
          value: 0,
          label: 'Off',
        },
        {
          value: 99,
          label: '99',
        },
    ];

    return (

                <Grid item  md={4} xs={12}>
                <FormControl margin="dense" variant="outlined" fullWidth >

                    {maxSessionsPerIP > 0 ?
                        <Typography>Autoblock IPs with more then {maxSessionsPerIP} session{maxSessionsPerIP>1?`s`:null}</Typography>
                        
                    : <Typography>Autoblock IPs by number of sessions [Off]</Typography>}
                <Slider
                    disabled={!allowFirewall || autoblockProcessing}
                    onChangeCommitted={onSliderChangeCommitted}
                    value={maxSessionsPerIP}
                    onChange={onSliderChange}
                    defaultValue={50}
                    step={1}
                    name="maxSessionsPerIP"
                    min={0}
                    max={99}
                    marks={marks}
                    valueLabelDisplay="auto"
                    valueLabelFormat={(value) => {

                        if (value === 0) {
                            return 'Off';
                        }else {
                            return value;
                        }
                    }}
                />
                </FormControl>
            </Grid>
    )

}

export default MaxIPAddressesControl;