import React from 'react';
import { ReactComponent as RotateCW } from './feather/rotate-cw.svg';

import { SvgIcon } from '@material-ui/core';


export default function RotateCWIcon(props) {
    return (
        <SvgIcon {...props} component={RotateCW} className="feather"/>
    );
  }