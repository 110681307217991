import React, { useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { DownloadIcon, ArrowUpCircleIcon, ArrowDownCircleIcon } from '../icons';


import {
  Grid,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  Card,
  CardHeader,
  CardContent,
  Tooltip,
} from '@material-ui/core';

import { TemplatePreviewButton } from 'components';


const useStyles = makeStyles((theme) => ({
  table: {
    // maxWidth: 440,
  },
  paper: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  cell: {
    wordWrap: 'break-word',
    padding: '16px'
  },
  addRowForm : {
    marginTop: 8,
    '& .MuiGrid-item' : {
      paddingTop: 0, 
      paddingBottom: 0, 
    }
  },
}));

const StyledTableRow = withStyles((theme) => ({
  root: {
    '& th:nth-of-type(even)': {
      backgroundColor: theme.palette.grey[200],
    },
    '& td:nth-of-type(odd)': {
      backgroundColor: theme.palette.grey[200],
    }
  },
}))(TableRow);

export default function TemplatesTable({templates, name, handleDownloadTemplate, allowCustomTemplates, roomsMarkup, handleSetSelectedTemplate, rooms}) {

  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const handleOpen = () => {
    setOpen(!open);
  }

  return (
    <>
      <Grid container component={Card} className={classes.paper}>
        <Grid item xs={12}>
          <CardHeader title={name} onClick={handleOpen} action=
            {
              <IconButton aria-label="settings">
                {
                  (open) ? <ArrowUpCircleIcon /> : <ArrowDownCircleIcon />
                }
              </IconButton>
            }
          >
          </CardHeader>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
              <TableContainer component='div'>
                <Table className={classes.table} aria-label="List of templates for" >
                  <TableHead>
                    <TableRow>
                      <TableCell component="th" scope="row">Name</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody >
                    {templates.map((template, rowIndex) => (
                    <StyledTableRow key={template.id}>
                    <TableCell component="th" scope="row" className={classes.cell}>
                    <Typography>{template.name}</Typography>
                    </TableCell>
                    <TableCell align="center" style={{ width: '20px' }}>
                      {rooms.length
                      ?
                      <TemplatePreviewButton
                      handleSetSelectedTemplate={handleSetSelectedTemplate}
                      template={template}
                      rooms={rooms}
                      />
                    : null  
                    }


                   
                    </TableCell>
                    <TableCell align="center" style={{ width: '20px' }}>
                    <Tooltip title="Download Template" aria-label="Download this Template">
                    <IconButton target="_blank" rel="noopener noreferrer" onClick={() => { handleDownloadTemplate(template.url, template.name) }} >
                        <DownloadIcon></DownloadIcon>
                    </IconButton>
                    </Tooltip>
                    </TableCell>

                    </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              </Grid>

              </Grid>
            </CardContent>
          </Collapse>
        </Grid>
      </Grid>
    </>
  );
}