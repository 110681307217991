import React from "react";
import { lighten, makeStyles, fade } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import XCircleIcon from '../icons/XCircleIcon';
import SearchIcon from '../icons/SearchIcon';

import { Typography, Grid, IconButton, Tooltip, Toolbar, InputBase } from '@material-ui/core';

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: '1 1 100%',
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.grey[300]}`,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 'auto',
    marginRight: 0,
    width: 'auto',
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

const CodesTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected, deleteCode, filterSearch, groupName } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Grid container alignItems="center" className={classes.title}>
          <Typography color="inherit" variant="subtitle1" component="div">
            {numSelected} selected
        </Typography>
          {numSelected > 0 ? (
            <Tooltip title="Delete Selected">
              <IconButton aria-label="delete"
                onClick={() => deleteCode()}>
                <XCircleIcon />
              </IconButton>
            </Tooltip>
          ) : (
              null
            )}
        </Grid>
      ) : (

          (groupName
          ?
          <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          Codes for {groupName}
          </Typography>
            :
            null
          )
        )}

      <div className={classes.search}>
        <div className={classes.searchIcon}>
          <SearchIcon />
        </div>
        <InputBase
          placeholder="Search…"
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          onChange={(event) => { filterSearch(event.target.value) }}
          inputProps={{ 'aria-label': 'search' }}
        />
      </div>



    </Toolbar>
  );
}

CodesTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default CodesTableToolbar;

