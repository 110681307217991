import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2)
    }
}));

const GlobalHeader = props => {

    const classes = useStyles();

    return (
        <Grid
            className={classes.root}
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
        >
            <RouterLink to="/"><img src="/crowdhandler-logo--strip-blue.svg" alt="CrowdHandler" /></RouterLink>
           
        </Grid>
    )
}
export default GlobalHeader;