export default {
  root: {
    '& .MuiIconButton-label a:visited' : {
        color: '#ff5722'
    },
    '& .MuiIconButton-label a' : {
        lineHeight: 0
    }
  }
};
