import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { RoomsTable, UpgradeCTAPanel, ErrorBoundary } from '../../components';
import groupBy from 'lodash/groupBy';

import {
  Button,
  Container,
  Grid,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Typography,
  Link,
  LinearProgress
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  buttonHeader: {
    padding: theme.spacing(0),
    marginBottom: theme.spacing(2),
  },
  margin: {
    marginBottom: theme.spacing(6),
  },
  smallmargin: {
    marginBottom: theme.spacing(2),
  },
  withoutLabel: {
    marginTop: theme.spacing(6),
  },
  domainAdornment: {
    marginRight: 0
  },
  spinner: {
    marginLeft: 22,
    color: theme.palette.secondary
  },

}));


const Rooms = ({ api, state, ...rest }) => {
  const classes = useStyles();
  const [openDeleteRoomDialog, setOpenDeleteRoomDialog] = useState(false);
  const [roomToDelete, setRoomToDelete] = useState({});
  const [numberOfUsersInRoom, setNumberOfUsersInRoom] = useState(0);
  const [groupedRooms, setGroupedRooms] = useState([]);
  const [upgradeMessage, setUpgradeMessage] = useState('You have filled your available room allocation. Upgrade to get extra rooms');
  const [displayUpgradeButton, setDisplayUpgradeButton] = useState(true);
  const {dispatch} = rest;
  const [usedCapacity, setUsedCapacity] = useState(0);
  const [spareCapacity, setSpareCapacity] = useState(0);
  const [canAddRooms, setCanAddRooms] = useState();
 

  useEffect(() => {
    dispatch({ type: 'UPDATE_BACKGROUND', payload: {bg:false, 'bgColor' : false} });
  }, [dispatch]);

  useEffect(() => {
    const getData =  async () => {
      let domains = await api.getDomains();
      dispatch({ type: 'ADD_DOMAINS', payload: domains });
      let plan = await api.getPlan();
      dispatch({ type: 'SET_CURRENT_PLAN', payload: plan });
      let rooms = await api.getRooms();
      dispatch({ type: 'UPDATE_ROOMS', payload: rooms });
    }

    getData();
 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getRooms = () => {
      
      let roomsByDomain = groupBy(state.rooms, 'domainID');
      let groupedRooms = [];


      for (let i = 0; i < state.domains.length; i++) {

        groupedRooms.push({
            roomName: state.domains[i].name,
            rooms: (roomsByDomain[state.domains[i].id]) ? roomsByDomain[state.domains[i].id] :  [],
            domainId: state.domains[i].id
          });
        
      }

      setGroupedRooms(groupedRooms);

    }
    if (state.rooms && state.domains !== undefined) {
      getRooms();
    }
  }, [state.rooms, state.domains]);

  useEffect(  ()=>{
    const calcCapacity = async () => {
      let capacity = state.rooms.reduce((acc, room) => {
        acc = acc + parseInt(room.capacity, 10);
        return acc;
      }, 0);
      setUsedCapacity(capacity);
      setSpareCapacity(state.plan.maxWaitingRoomCapacity - capacity)
    }
    if(state.rooms !== undefined && state.plan !== undefined) {
      calcCapacity();
    }
  },[state.plan, state.rooms]);


  useEffect(() => {
    const canAddRooms = () => {
      let maxRooms = parseInt(state.plan.maxWaitingRoomCount, 10);
      let numOfRooms = parseInt(state.rooms.length, 10);

      if(state.plan.tier === state.plans.length) {
        setUpgradeMessage(<>You have reached the limit of your plan’s quota. <Link href="mailto:poweruser@crowdhandler.com">Contact us to discuss increasing your quota</Link></>);
        setDisplayUpgradeButton(false)
      }


      if (numOfRooms < maxRooms) {
        setCanAddRooms(true);
      } else {
        setCanAddRooms(false);
      }
    }

    if(state.rooms && state.plan) {

      canAddRooms()

    }

  }, [state.plan, state.rooms, state.plans]);



  const deleteRoomHandler = async (room) => {
    // get the number of users
    let report = await api.getRoomById(room.id);

    if (report) {
      //set the user number o display in dialog
      setNumberOfUsersInRoom(report.sessions);
    } else {
      setNumberOfUsersInRoom(0);
    }
    // set the data of the room to delete
    setRoomToDelete(room);
    // open the dialog to check that the user want to delete the room
    setOpenDeleteRoomDialog(true);
  }

  const handleCancel = () => {
    setRoomToDelete({});
    setOpenDeleteRoomDialog(false)
  }

  const handleOk = () => {
    let result = api.deleteRoom(roomToDelete.id);
    setRoomToDelete({});
    setOpenDeleteRoomDialog(false)
    result.then((response) => {
      dispatch({ type: 'UPDATE_ROOMS', payload: response });
    })
    .catch(function (error) {
      // console.log(error);
    });
  }


  return (

    <div className={classes.root}>

<Container
        maxWidth="lg"
        spacing={4}
      >

<Grid container spacing={2}>
  <Grid item xs={12}><LinearProgress variant="determinate" value={(parseInt(usedCapacity, 10) / parseInt(state.plan.maxWaitingRoomCapacity) * 100)} /></Grid>
{state.rooms && state.domains && state.plan
?         <>
          <Grid item xs={6} >
          <ErrorBoundary>
            <Typography>Allocated capacity {usedCapacity} / {state.plan.maxWaitingRoomCapacity}</Typography>
          </ErrorBoundary>
          </Grid>
          <Grid item xs={6} align="right">
            <Typography>Spare Capacity {spareCapacity}</Typography>
          </Grid>
          </>
:null}
        </Grid>

        </Container>

      {state.rooms && state.domains && state.plan
      ?
      <Container
        maxWidth="lg"
        spacing={4}
      >

        <Grid container className={classes.buttonHeader}>
          <Grid item xs={12} align="right">
            {
              (!canAddRooms)
                ?
                <UpgradeCTAPanel upgradeMessage={upgradeMessage} displayUpgradeButton={displayUpgradeButton} />
                :
                null
            }
          </Grid>
        </Grid>

        {(groupedRooms.length > 0)
          ?
          groupedRooms.map((roomsByDomain, index) => {
            return (
              <RoomsTable api={api} canAddRooms={canAddRooms} key={roomsByDomain.domainId} usedCapacity={usedCapacity} state={state} id={roomsByDomain.domainId} rooms={roomsByDomain.rooms} name={roomsByDomain.roomName} deleteRoomHandler={deleteRoomHandler} />

            )
          })

          :

          <Typography>No Rooms Found</Typography>
        }


      </Container>
      :
      null
      }
      <Dialog
        disableEscapeKeyDown
        maxWidth="xs"
        aria-labelledby="confirmation-dialog-title"
        open={openDeleteRoomDialog}
        onClose={(event, reason) => {
          if (reason === 'backdropClick') {
            return;
          }
          setOpenDeleteRoomDialog(false);
        }}

      >
        <DialogTitle id="confirmation-dialog-title">Delete Room?</DialogTitle>
        <DialogContent dividers>
          <Typography>{`Are you sure you want to delete the room ${roomToDelete.title}?`}</Typography>
          <Typography>{`There `} {(numberOfUsersInRoom === 1) ? `is` : `are`} {` currently ${numberOfUsersInRoom} user`}{(numberOfUsersInRoom === 1) ? `` : `s`} {`in this room`}</Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCancel} color="primary">
            Cancel
        </Button>
          <Button onClick={handleOk} color="secondary">
            Ok
        </Button>
        </DialogActions>
      </Dialog>

    </div>
  );
};

export default Rooms;