import React from 'react';
import { ReactComponent as ZoomIn } from './feather/zoom-out.svg';

import { SvgIcon } from '@material-ui/core';


export default function ZoomOutIcon(props) {
    return (
        <SvgIcon {...props} component={ZoomIn} className="feather"/>
    );
  }