import React, { useState, useRef, useEffect } from "react";
// import {Auth}from 'aws-amplify';
import { resetPassword, fetchMFAPreference, fetchAuthSession, confirmSignUp, resendSignUpCode } from 'aws-amplify/auth';

import {
    Redirect
} from "react-router-dom";
import { Container, Grid, Button, FormControl, InputLabel, OutlinedInput } from "@material-ui/core";
import {
    useHistory,
} from "react-router-dom";
// import PropTypes from 'prop-types';

import ErrorSnackBar from '../../components/ErrorSnackBar';


export default function Confirmation(props) {
    let history = useHistory();

    // Store a ref so we can clear up when the component is unmounted
    const isMountedRef = useRef(null);

    useEffect(() => {
        isMountedRef.current = true;
        return () => {
            isMountedRef.current = false;
        }
    });

    let [confirmed, setConfirmed] = useState();
    let [message, setMessage] = useState('');
    let [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
    let [alertBarStatus, setAlertBarStatus] = useState('success');

    let [fields, setFields] = useState({
        username: '',
        code: ''
    });

    const closeErrorSnackbar = () => {
        setOpenErrorSnackbar(false)
    }


    const handleVerification =  () => {
        confirmSignUp(fields.username, fields.code)
        .then((data) => {
            setConfirmed(true);
            const location_success = {
                pathname: '/',
                state: {}
              }
            history.push(location_success);
        })
        .catch((error) => {

            if (error.message.indexOf('Current status is CONFIRMED') > -1) {
                setMessage('Your account is already confirmed.');
            }else {
                setMessage(error.message);
            }
            setAlertBarStatus('error')
            setOpenErrorSnackbar(true)
        });
    }

    const handleResendConfirmationCode = async () => {

        if (fields.username) {
            try {
                await resendSignUpCode(fields.username);
                setMessage('Please check your email for a new confirmation code');
                setOpenErrorSnackbar(true)
            } catch (err) {
                setMessage('There was a problem sending your confirmation code. Please try again later');
                setAlertBarStatus('error');
                setOpenErrorSnackbar(true)
            }

        } else {
            setMessage('Please fill in your email address to receive a new code');
            setOpenErrorSnackbar(true)
        }
    }
  

    const handleFields = (event) => {

        if (isMountedRef.current) {
            setFields({
                ...fields,
                [event.target.name]: event.target.value
            })
        }
    }


    return (
<>
        {
            props.authorised || confirmed
            ?
            <Redirect to="/" />
            :

            <Container maxWidth='sm'>
         
            <Grid container component="form">
                
                <Grid item xs={12}>
                    <FormControl fullWidth margin="normal" variant="outlined">
                        <InputLabel htmlFor="username">Email Address</InputLabel>
                        <OutlinedInput
                            type="text"
                            id='username'
                            name='username'
                            value={fields.username}
                            onChange={(event) => handleFields(event)}
                            labelWidth={30}
                            placeholder="Email Address"
                            aria-label="Email Address"
                        ></OutlinedInput>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth margin="normal" variant="outlined">
                        <InputLabel htmlFor="username">Code</InputLabel>
                        <OutlinedInput
                            type="text"
                            id='verification_code'
                            name='code'
                            value={fields.code}
                            onChange={(event) => handleFields(event)}
                            labelWidth={30}
                            placeholder="Verification Code"
                            aria-label="Verification Code"
                        ></OutlinedInput>
                    </FormControl>
                </Grid>
                
                <Grid item xs={12}>
                    <Button variant="contained"
                        size="small"
                        disableElevation
                        color="secondary" onClick={() => { handleVerification() }}>Submit</Button>
                </Grid>
                <Grid item xs={12}>
                    <Button 
                        size="small"
                        disableElevation
                        color="primary" onClick={() => { handleResendConfirmationCode() }}>Resend Code</Button>
                </Grid>
               
            </Grid>
           
        <ErrorSnackBar message={message} open={openErrorSnackbar} status={alertBarStatus} closeErrorSnackbar={closeErrorSnackbar} />

        </Container>

        }
       
        
</>
    )
}