import Config from '../config';
import axios from 'axios';
import { datadogRum } from '@datadog/browser-rum';

const encodeQueryData = (data) => {
    const ret = [];
    for (let d in data) {
        ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
    }
    return ret.join('&');
 }


 const cognitoEndpoint = process.env.REACT_APP_CH_COGNITO_API_EU;
 const cognitoEndpointKey = process.env.REACT_APP_CH_COGNITO_API_KEY_EU;


export class API {

    constructor() {
        this.cancelTokenSource = axios.CancelToken.source();

        this.controller = new AbortController();
        
        this.chCognitoHeaders = {
            cancelToken: this.cancelTokenSource.token,
            signal: this.controller.signal,
            headers: {
                'x-api-key': cognitoEndpointKey,
                'Content-Type': 'application/json',
            }
        }

        this.chDashboardCheckHeaders = {
            headers: {
                'x-api-key': Config.CH_DASHBOARD_CHECKS_KEY,
                'Content-Type': 'application/json',
            }
        }

        this.cancelTokenSource = axios.CancelToken.source();

      }


    setRegion = (region) => {
        this.region = region;


        this.stripeOptions = {
            cancelToken: this.cancelTokenSource.token,
            signal: this.controller.signal,
            headers: {
                'x-api-key': Config.CH_STRIPE_APIKEY[this.region],
                'Content-Type': 'application/json',
            }
        }
    }

    initialize = async (idToken) => {
        this.custId = idToken.payload['cognito:groups'][0];

        this.cust_key_public = idToken.payload.keyPublic;
        this.cust_key_private = idToken.payload.chkey;
        /**
         * TODO - integrate with login when Cognito is set up
         */
        this.options = {
            cancelToken: this.cancelTokenSource.token,
            signal: this.controller.signal,
            headers: {
                'x-api-key': idToken.payload.keyAdmin || idToken.payload.chkey,
                'Content-Type': 'application/json'
            }
        }
        

        this.proxyOptions = {
            endpoint: Config.CH_PROXY_ENDPOINT,
            cancelToken: this.cancelTokenSource.token,
            signal: this.controller.signal,
            headers: {
                'x-api-key': Config.CH_PROXY_ENDPOINT_APIKEY,
                'Content-Type': 'application/json'
            }
        }
        
        try {
            let results = await Promise.all([
                this.getStripeCustomer(idToken.jwtToken),
                this.getDomains(),
                this.getAccount(),
                this.getPlan(),
                this.retrieveUpcomingInvoice(idToken.jwtToken),
                this.getLatestInvoice(idToken.jwtToken),
            ]);

            results = results.reduce((acc, result) => {
                if (result.status === 'Request Failed') {
                    acc.push(false);
                } else {
                    acc.push(result);
                }
                return acc;
            }, [] );

            return results;
        } catch (error) {
            console.warn(error);
            // return an empty array to display the error message
            return Promise.resolve([]);
        }
    }

    cancelRequest = () => {
        this.cancelTokenSource.cancel();
        this.controller.abort()
    }

    hasSubdomain = (domain) => {
        let config = {
            method: 'post',
            url: `${this.proxyOptions.endpoint}domainInfo`,
            headers: this.proxyOptions.headers,
            data: {
                domain: domain,
                authKey: this.cust_key_private
            }
        };

        return this.handleResponse(axios(config));
    }

    requestCertificate = (domain) => {
        let config = {
            method: 'post',
            url: `${this.proxyOptions.endpoint}requestCertificate`,
            headers: this.proxyOptions.headers,
            data: {
                domain: domain
            }
        };

        return this.handleResponse(axios(config));
    }

    cachePurge = (domain) => {
        let config = {
            method: 'post',
            url: `${this.proxyOptions.endpoint}cachePurge`,
            headers: this.proxyOptions.headers,
            data: {
                domain: domain,
                "pattern": "/*",
                authKey: this.cust_key_private
            }
        };

        return this.handleResponse(axios(config));
    }

    getCertificateStatus = (domain) => {
        let config = {
            method: 'post',
            url: `${this.proxyOptions.endpoint}certificateStatus`,
            headers: this.proxyOptions.headers,
            data: {
                domain: domain,
                
            }
        };

        return this.handleResponse(axios(config));
    }

    getOriginStatus = (domain) => {
        let config = {
            method: 'post',
            url: `${this.proxyOptions.endpoint}originStatus`,
            headers: this.proxyOptions.headers,
            data: {
                domain: domain,
                authKey: this.cust_key_private
            }
        };

        return this.handleResponse(axios(config));
    }

    createDistribution = (payload) => {

        let config = {
            method: 'post',
            url: `${this.proxyOptions.endpoint}createDistribution`,
            headers: this.proxyOptions.headers,
            data: {
                ...payload,
                pubKey: this.cust_key_public,
                authKey: this.cust_key_private
            }
        };

        return this.handleResponse(axios(config));
    }

    getDistributionStatus = (domain) => {
        let config = {
            method: 'post',
            url: `${this.proxyOptions.endpoint}distributionStatus`,
            headers: this.proxyOptions.headers,
            data: {
                domain: domain,
                authKey: this.cust_key_private
            }
        };

        return this.handleResponse(axios(config));
    }

    getBehaviourList = (domain) => {
        let config = {
            method: 'post',
            url: `${this.proxyOptions.endpoint}behaviourList`,
            headers: this.proxyOptions.headers,
            data: {
                domain: domain,
                authKey: this.cust_key_private
            }
        };

        return this.handleResponse(axios(config));
    }

    addBehaviourList = async (domain, pattern) => {

        let config = {
            method: 'post',
            url: `${this.proxyOptions.endpoint}addBehaviour`,
            headers: this.proxyOptions.headers,
            data: {
                domain: domain,
                pattern: pattern,
                authKey: this.cust_key_private
            }
        };

        return this.handleResponse(axios(config));

    }
    removeBehaviourList = async (domain, pattern) => {

        let config = {
            method: 'post',
            url: `${this.proxyOptions.endpoint}removeBehaviour`,
            headers: this.proxyOptions.headers,
            data: {
                domain: domain,
                pattern: pattern,
                authKey: this.cust_key_private
            }
        };

        return this.handleResponse(axios(config));

    }

    getTrafficStatus = (domain) => {
        let config = {
            method: 'post',
            url: `${this.proxyOptions.endpoint}trafficStatus`,
            headers: this.proxyOptions.headers,
            data: {
                domain: domain,
                authKey: this.cust_key_private
            }
        };

        return this.handleResponse(axios(config));
    }

    originUpdate = (domain, originDomain) => {
        let config = {
            method: 'post',
            url: `${this.proxyOptions.endpoint}originUpdate`,
            headers: this.proxyOptions.headers,
            data: {
                domain: domain,
                originDomain: originDomain,
                authKey: this.cust_key_private
            }
        };

        return this.handleResponse(axios(config));
    }

    getStripePlans = (idToken) => {

        let headers = {
            Authorization:  idToken,
            ...this.stripeOptions.headers
        }
        let config = {
            method: 'get',
            url: `${Config.CH_STRIPE_ENDPOINT[this.region]}getproducts`,
            headers: headers
        };

        return this.handleResponse(axios(config));
    }

    getRoomLive = (idToken, url) => {

        let headers = {
            Authorization:  idToken,
            ...this.stripeOptions.headers
        }
        let config = {
            method: 'post',
            url: `${Config.CH_STRIPE_ENDPOINT[this.region]}check-room-live`,
            headers: headers,
            data : {url}
        };

        return this.handleResponse(axios(config));
    }

    removePaymentPlan = (paymentMethodID, idToken) => {
        let headers = {
            Authorization: idToken,
            ...this.stripeOptions.headers
        }
        let config = {
            method: 'post',
            url: `${Config.CH_STRIPE_ENDPOINT[this.region]}remove-payment-method`,
            headers: headers,
            data: {
                paymentMethodID: paymentMethodID
            }
        };

        return this.handleResponse(axios(config));
    }

    getStripeCustomer = (idToken) => {

        let headers = {
            Authorization: idToken,
            ...this.stripeOptions.headers
        }
        let config = {
            method: 'post',
            url: `${Config.CH_STRIPE_ENDPOINT[this.region]}retrieve-customer`,
            headers: headers,
            data: {
                customerId: this.custId
            }
        };

        return this.handleResponse(axios(config));
    }

    createTaxId = (data, idToken) => {
        let headers = {
            Authorization: idToken,
            ...this.stripeOptions.headers
        }
        let config = {
            method: 'post',
            url: `${Config.CH_STRIPE_ENDPOINT[this.region]}create-tax-id`,
            headers: headers,
            data: {
                customerId: this.custId,
                taxdata: data
            }
        };

        return this.handleResponse(axios(config));
    }

    deleteTaxId = (taxId, idToken) => {
        let headers = {
            Authorization: idToken,
            ...this.stripeOptions.headers
        }
        let config = {
            method: 'post',
            url: `${Config.CH_STRIPE_ENDPOINT[this.region]}delete-tax-id`,
            headers: headers,
            data: {
                customerId: this.custId,
                taxId: taxId
            }
        };

        return this.handleResponse(axios(config));
    }


    resetTempPassword = (email) => {
        let headers = {
            ...this.chCognitoHeaders.headers,
        }
        let config = {
            method: 'post',
            url: `${cognitoEndpoint}reset-temp-password`,
            headers: headers,
            data: {
                email: email
            }
        };

        return this.handleResponse(axios(config));
    }

    getGroupUsers = (groupName, idToken, region) => {
        let headers = {
            Authorization: idToken,
            ...this.stripeOptions.headers
        }
        let config = {
            method: 'post',
            url: `${Config.CH_STRIPE_ENDPOINT[this.region]}get-group-users`,
            headers: headers,
            data: {
                groupName,
                region
            }
        };

        return this.handleResponse(axios(config));
    }

    addUser = (groupName, emailAddress, role, idToken, clientMetaData) => {
        let headers = {
            Authorization: idToken,
            ...this.stripeOptions.headers
        }
        let config = {
            method: 'post',
            url: `${Config.CH_STRIPE_ENDPOINT[this.region]}add-user`,
            headers: headers,
            data: {
                groupName,
                emailAddress,
                role: role.toString(),
                clientMetaData
            }
        };

        return this.handleResponse(axios(config));
    }

    /**
     * @TODO CHEKC THIS IS NEEDED!!!!
     * 
     * @param {*} payment_method 
     * @param {*} idToken 
     */

    addCard = (payment_method, idToken) => {
        let headers = {
            Authorization: idToken,
            ...this.stripeOptions.headers
        }
        let config = {
            method: 'post',
            url: `${Config.CH_STRIPE_ENDPOINT[this.region]}card-wallet`,
            headers: headers,
            data: {
                customerId: this.custId,
                paymentMethodId: payment_method
            }
        };

        return this.handleResponse(axios(config));
    }

    attachPaymentMethodToCustomer = (payment_method, idToken) => {
        let headers = {
            Authorization: idToken,
            ...this.stripeOptions.headers
        }
        let config = {
            method: 'post',
            url: `${Config.CH_STRIPE_ENDPOINT[this.region]}attach-payment-method-to-customer`,
            headers: headers,
            data: {
                customerId: this.custId,
                paymentMethodId: payment_method
            }
        };

        return this.handleResponse(axios(config));
    }

    updateStripeCustomer = (customerData, idToken) => {
        let headers = {
            Authorization: idToken,
            ...this.stripeOptions.headers
        }
        let config = {
            method: 'post',
            url: `${Config.CH_STRIPE_ENDPOINT[this.region]}update-customer`,
            headers: headers,
            data: {
                customerId: this.custId,
                address: customerData.address,
                name: customerData.name
            }
        };

        return this.handleResponse(axios(config));

    }

    getMailPreferences = (emailAddress, idToken) => {
        let headers = {
            Authorization: idToken,
            ...this.stripeOptions.headers
        }
        let config = {
            method: 'post',
            url: `${Config.CH_STRIPE_ENDPOINT[this.region]}get-mail-preferences`,
            headers: headers,
            data: {
                emailAddress
            }
        };

        return this.handleResponse(axios(config));
    }

    updatePrimaryAccount = (email, idToken) => {
        let headers = {
            Authorization: idToken,
            ...this.stripeOptions.headers
        }
        let config = {
            method: 'post',
            url: `${Config.CH_STRIPE_ENDPOINT[this.region]}update-primary-account`,
            headers: headers,
            data: {
                customerId: this.custId,
                email
            }
        };

        return this.handleResponse(axios(config));

    }

    setMailPreferences = (emailAddress, pref, idToken) => {
        let headers = {
            Authorization: idToken,
            ...this.stripeOptions.headers
        }
        let config = {
            method: 'post',
            url: `${Config.CH_STRIPE_ENDPOINT[this.region]}set-mail-preferences`,
            headers: headers,
            data: {
                emailAddress,
                pref

            }
        };

        return this.handleResponse(axios(config));

    }

    updateDefaultPaymentMethod = (payment_method, idToken) => {
        let headers = {
            Authorization: idToken,
            ...this.stripeOptions.headers
        }
        let config = {
            method: 'post',
            url: `${Config.CH_STRIPE_ENDPOINT[this.region]}update-default-payment-method`,
            headers: headers,
            data: {
                customerId: this.custId,
                paymentMethodId: payment_method
            }
        };

        return this.handleResponse(axios(config));

    }

    updateSubscription = (data, idToken) => {
        let headers = {
            Authorization: idToken,
            ...this.stripeOptions.headers
        }
        let config = {
            method: 'post',
            url: `${Config.CH_STRIPE_ENDPOINT[this.region]}update-subscription`,
            headers: headers,
            data: data
        };

        return this.handleResponse(axios(config));

    }

    cancelDowngrade = (data, idToken) => {
        let headers = {
            Authorization: idToken,
            ...this.stripeOptions.headers
        }
        let config = {
            method: 'post',
            url: `${Config.CH_STRIPE_ENDPOINT[this.region]}cancel-downgrade`,
            headers: headers,
            data: data
        };

        return this.handleResponse(axios(config));

    }
    retrieveUpcomingInvoice = (idToken) => {
        let headers = {
            Authorization: idToken,
            ...this.stripeOptions.headers
        }
        let config = {
            method: 'post',
            url: `${Config.CH_STRIPE_ENDPOINT[this.region]}retrieve-upcoming-invoice`,
            headers: headers,
            data: {
                customerId: this.custId,
            }
        };

        return this.handleResponse(axios(config));

    }
    retrieveUpcomingInvoicePreview = (data, idToken) => {
        let headers = {
            Authorization: idToken,
            ...this.stripeOptions.headers
        }
        let config = {
            method: 'post',
            url: `${Config.CH_STRIPE_ENDPOINT[this.region]}retrieve-upcoming-invoice-preview`,
            headers: headers,
            data: data
        };

        return this.handleResponse(axios(config));

    }
    getInvoices = (idToken) => {
        let headers = {
            Authorization: idToken,
            ...this.stripeOptions.headers
        }
        let config = {
            method: 'post',
            url: `${Config.CH_STRIPE_ENDPOINT[this.region]}list-invoices`,
            headers: headers,
            data: {
                customerId: this.custId,
            }
        };

        return this.handleResponse(axios(config));

    }
    getLatestInvoice = (idToken) => {
        let headers = {
            Authorization: idToken,
            ...this.stripeOptions.headers
        }
        let config = {
            method: 'post',
            url: `${Config.CH_STRIPE_ENDPOINT[this.region]}retrieve-latest-invoice`,
            headers: headers,
            data: {
                customerId: this.custId,
            }
        };

        return this.handleResponse(axios(config));

    }

    getStripePrices = (idToken) => {
        let headers = {
            Authorization: idToken,
            ...this.stripeOptions.headers
        }
        let config = {
            method: 'get',
            url: `${Config.CH_STRIPE_ENDPOINT[this.region]}getprices`,
            headers: headers
        };

        return this.handleResponse(axios(config));

    }

    getStripeProducts = (idToken) => {
        let headers = {
            Authorization: idToken,
            ...this.stripeOptions.headers
        }
        let config = {
            method: 'get',
            url: `${Config.CH_STRIPE_ENDPOINT[this.region]}getproducts`,
            headers: headers
        };

        return this.handleResponse(axios(config));

    }

    downloadTemplate = (idToken, data) => {
        let headers = {
            Authorization: idToken,
            ...this.stripeOptions.headers
        }
        let config = {
            method: 'post',
            url: `${Config.CH_STRIPE_ENDPOINT[this.region]}download-template`,
            headers: headers,
            data: JSON.stringify(data)
        };
        
        return this.handleResponse(axios(config));
    }

    checkDomain = (idToken, data) => {
        let headers = {
            Authorization: idToken,
            ...this.stripeOptions.headers
        }
        let config = {
            method: 'post',
            url: `${Config.CH_STRIPE_ENDPOINT[this.region]}verify-domain`,
            headers: headers,
            data: JSON.stringify(data)
        };
        
        return this.handleResponse(axios(config));
    }

    retrieveCustomer = (idToken) => {
        let headers = {
            Authorization: idToken,
            ...this.stripeOptions.headers
        }
        let config = {
            method: 'post',
            url: `${Config.CH_STRIPE_ENDPOINT[this.region]}retrieve-customer`,
            headers: headers,
            data: {
                customerId: this.custId,
            }
        };

        return this.handleResponse(axios(config));

    }

    retrieveCustomerPaymentMethod = (paymentMethodId, idToken) => {

        let headers = {
            Authorization: idToken,
            ...this.stripeOptions.headers
        }

        let config = {
            method: 'post',
            url: `${Config.CH_STRIPE_ENDPOINT[this.region]}retrieve-customer-payment-method`,
            headers: headers,
            data: JSON.stringify({ paymentMethodId })
        };

        return this.handleResponse(axios(config));

    }

    updateUserRole = (username, role, idToken, region) => {
        let headers = {
            Authorization: idToken,
            ...this.chCognitoHeaders.headers,
        }

        let config = {
            method: 'post',
            url: `${Config.CH_STRIPE_ENDPOINT[this.region]}update-userrole`,
            headers,
            data: JSON.stringify({ username, role, region })
        };

        return this.handleResponse(axios(config));

    }

    deleteUserRole = (username, email, region, idToken) => {
        let headers = {
            Authorization: idToken,
            ...this.stripeOptions.headers
        }
        let config = {
            method: 'post',
            url: `${Config.CH_STRIPE_ENDPOINT[this.region]}delete-user`,
            headers: headers,
            data: JSON.stringify({ username, email, region })
        };

        return this.handleResponse(axios(config));

    }

    retrieveCustomerPaymentMethods = (idToken) => {
        let headers = {
            Authorization: idToken,
            ...this.stripeOptions.headers
        }
        let config = {
            method: 'post',
            url: `${Config.CH_STRIPE_ENDPOINT[this.region]}retrieve-customer-payment-methods`,
            headers: headers,
            data: JSON.stringify({ customerId: this.custId })
        };

        return this.handleResponse(axios(config));

    }

    updatePaymentMethods = (values, idToken) => {
        let headers = {
            Authorization: idToken,
            ...this.stripeOptions.headers
        }
        let data = JSON.stringify(values );
       
        let config = {
            method: 'post',
            url: `${Config.CH_STRIPE_ENDPOINT[this.region]}update-payment-methods`,
            headers: headers,
            data: data
        };

        return this.handleResponse(axios(config));

    }

    getDocs = () => {
        return this.handleResponse(axios.get(`${Config.CH_ENDPOINT}docs`, this.options));
    }

    getDomains = () => {
        return this.handleResponse(axios.get(`${Config.CH_ENDPOINT}domains`, this.options));
    }

    getRooms = () => {
        return this.handleResponse(axios.get(`${Config.CH_ENDPOINT}rooms`, this.options));
    }

    getMultipleRoomsReport = (roomIds, from, to, period) => {

        let calls = [];
        let querystring = '';

        if (to && from) {
            const data = { 'from': from, 'to': to, 'period': (period) ? period : 'hour'};
            querystring = `?${encodeQueryData(data)}`;
        }

        for (let i = 0; i < roomIds.length; i++) {
            const id = roomIds[i];
            let call = axios.get(`${Config.CH_ENDPOINT}rooms/${id}/reports${querystring}`, this.options);
            calls.push(call)
        }

        return Promise.all(calls);
    }

    getRoomsReport = (id) => {
        return this.handleResponse(axios.get(`${Config.CH_ENDPOINT}rooms/${id}/reports`, this.options));
    }

    getDomainReport = (domainId, downloadTime, period) => {
        let querystring = '';
        if (downloadTime) {
            if (downloadTime.to && downloadTime.from) {
                const data = { 'from': downloadTime.from, 'to': downloadTime.to};
                // const data = { 'from': downloadTime.from, 'to': downloadTime.to};
                querystring = `?${encodeQueryData(data)}`;
            }
        }
        return this.handleResponse(axios.get(`${Config.CH_ENDPOINT}domains/${domainId}/reports${querystring}`, this.options));
    }

    getRoomById = (id) => {
        return this.handleResponse(axios.get(`${Config.CH_ENDPOINT}rooms/${id}`, this.options));
    }

    getAccount = () => {
        return this.handleResponse(axios.get(`${Config.CH_ENDPOINT}account`, this.options))
    }

    getPlan = () => {
        return this.handleResponse(axios.get(`${Config.CH_ENDPOINT}account/plan`, this.options))
    }

    getUrls = (domainId) => {
        return this.handleResponse(axios.get(`${Config.CH_ENDPOINT}domains/${domainId}/urls`, this.options));
    }

    getRequests = (domainId) => {
        return this.handleResponse(axios.get(`${Config.CH_ENDPOINT}domains/${domainId}/requests`, this.options));
    }

    getReport = (domainId) => {

        return this.handleResponse(axios.get(`${Config.CH_ENDPOINT}domains/${domainId}/reports`, this.options));
    }

    getIPData = (ip_address) => {

        let headers = {
            ...this.options.headers
        }
        let config = {
            method: 'get',
            url: `${Config.CH_IPDATA_ENDPOINT}ip_lookup?ip_address=${ip_address}`,
            headers,
        };

        return this.handleResponse(axios(config));
            
    }

    getBulkIPReport = (list) => {

        let headers = {
            ...this.stripeOptions.headers
        }

        let config = {
            method: 'post',
            url: `${Config.CH_STRIPE_ENDPOINT[this.region]}get-ip-data`,
            headers,
            data: JSON.stringify({ ip_addresses: list.join(',') })
        };

        return this.handleResponse(axios(config));
            
    }

    checkJs = (domain, key, idToken) => {
        let headers = {
            Authorization:  idToken.jwtToken,
            ...this.stripeOptions.headers
        }

        console.log(this.region);

        let config = {
            method: 'post',
            url: `${Config.CH_STRIPE_ENDPOINT[this.region]}check-js`,
            headers,
            data: JSON.stringify({ 
                domain, key
             })
        };

        return this.handleResponse(axios(config));
            
    }

    getReportByDate = (domainId, from, to) => {
        /* TESTING Report Data */
        // let response = require('../mock-data/getReport.json');
        // return Promise.resolve(response.result);
        let querystring = '';

        if (to && from) {
            const data = { 'from': from, 'to': to };
            querystring = `?${encodeQueryData(data)}`;
        }
        
        return this.handleResponse(axios.get(`${Config.CH_ENDPOINT}domains/${domainId}/reports?${querystring}`, this.options));
    }

    getDomainById = (id) => {
        return this.handleResponse(axios.get(`${Config.CH_ENDPOINT}domains/${id}`, this.options))
    }

    deleteRoom = (id) => {
        return this.handleResponse(axios.delete(`${Config.CH_ENDPOINT}rooms/${id}`, this.options));
    }

    addRoom = (value, method) => {
    

        let formatteddata = JSON.stringify(value);

        let roomID = '';

        if (method === 'put') {
            roomID = `/${value.id}`
        }

        let config = {
            method: method,
            url: `${Config.CH_ENDPOINT}rooms${roomID}`,
            headers: this.options.headers,
            data: formatteddata
        };
        return this.handleResponse(axios(config));

    }

    updateAccount = (value) => {
        let formatteddata = JSON.stringify(value);

        let config = {
            method: 'put',
            url: `${Config.CH_ENDPOINT}account`,
            headers: this.options.headers,
            data: formatteddata
        };
        return this.handleResponse(axios(config));
    }

    deleteDomain = (id) => {
        let config = {
            method: 'delete',
            url: `${Config.CH_ENDPOINT}domains/${id}`,
            headers: this.options.headers
        };
        return this.handleResponse(axios(config));
    }

    putDomain = (data, method) => {

        const {id:domainID, ...rest} = data;

        let id = (domainID) ? `/${domainID}` : ''

        data = JSON.stringify(rest);
        let config = {
            method: method,
            url: `${Config.CH_ENDPOINT}domains${id}`,
            headers: this.options.headers,
            data: data
        };
        return this.handleResponse(axios(config));
    }

    getRequestScore = (token) => {

        let config = {
            method: 'get',
            url: `${Config.CH_ENDPOINT}scores/${token}`,
            headers: this.options.headers,
        };

        return this.handleResponse(axios(config));
    }

    getSessions = (queryString) => {
        let config = {
            method: 'get',
            url: `${Config.CH_ENDPOINT}sessions${queryString}`,
            headers: this.options.headers,
        };

        return this.handleResponse(axios(config));

    }

    getRoomSessions = (roomID, searchTerm) => {

        let query = '';
        if(searchTerm){
            query = `?search=${encodeURIComponent(searchTerm)}`
        }
        return this.handleResponse(axios.get(`${Config.CH_ENDPOINT}rooms/${roomID}/sessions${query}`, this.options));
    }

    getAutoblocks = (ip, searchTerm) => {
        return this.handleResponse(axios.get(`${Config.CH_ENDPOINT}autoblocks/${ip}`, this.options));
    }
    
    getAutoblocksByDomain = (domainId) => {
        return this.handleResponse(axios.get(`${Config.CH_ENDPOINT}autoblocks?domainID=${domainId}`, this.options));
    }

    getRoomSessionsRequests = (token) => {
        return this.handleResponse(axios.get(`${Config.CH_ENDPOINT}sessions/${token}`, this.options));
    }

    getUserAgents = async (agent) => {
       
        let headers = {
            ...this.stripeOptions.headers,
        }

        let config = {
            method: 'get',
            url: `${Config.CH_STRIPE_ENDPOINT[this.region]}get-user-agent?agent=${encodeURIComponent(agent)}`,
            headers,
        };

        return this.handleResponse(axios(config));

       
    }

    putPromoteSession = (token) => {


        let data = JSON.stringify({promoted:1});
        let config = {
            method: 'put',
            url: `${Config.CH_ENDPOINT}sessions/${token}`,
            headers: this.options.headers,
            data: data
        };
        return this.handleResponse(axios(config));

    }

    deleteSession = (token) => {
        return this.handleResponse(axios.delete(`${Config.CH_ENDPOINT}sessions/${token}`, this.options));
    }

    getTemplates = () => {
        return this.handleResponse(axios.get(`${Config.CH_ENDPOINT}templates`, this.options));
    }

    getTemplatesList = () => {
        return this.handleResponse(axios.get(`${Config.CH_ENDPOINT}templates/list`, this.options));
    }

    addTemplate = (data, method, id) => {

        let idParam = (id) ? `/${id}` : '';
        data = JSON.stringify(data);
        let config = {
            method: method,
            url: `${Config.CH_ENDPOINT}templates${idParam}`,
            headers: this.options.headers,
            data: data
        };
        return this.handleResponse(axios(config));
    }

    deleteTemplate = (id) => {
        return this.handleResponse(axios.delete(`${Config.CH_ENDPOINT}templates/${id}`, this.options));
    }

    getDomainIPList = (id) => {
        return this.handleResponse(axios.get(`${Config.CH_ENDPOINT}domains/${id}/ips`, this.options));
    }

    getDomainIPRecommendList = (id) => {
        return this.handleResponse(axios.get(`${Config.CH_ENDPOINT}domains/${id}/ips?list=recommend`, this.options));
    }

    moveIPToList = (data) => {

        data = JSON.stringify(data);
        let config = {
            method: 'post',
            url: `${Config.CH_ENDPOINT}ips`,
            headers: this.options.headers,
            data: data
        };

        return this.handleResponse(axios(config));
    }

    updateIPToList = (values) => {

        let { id, ...rest } = values;
    
        let data = JSON.stringify(rest);

        id = encodeURIComponent(id)

        let config = {
            method: 'put',
            url: `${Config.CH_ENDPOINT}ips/${id}`,
            headers: this.options.headers,
            data: data
        };

        return this.handleResponse(axios(config));
    }

    passIP = (id) => {
        return this.handleResponse(axios.get(`${Config.CH_ENDPOINT}ips`, this.options));
    }

    /**
     * 
     * @param {String} id 
     */
    removeIPFromList = (id) => {

        id = encodeURIComponent(id)
        return this.handleResponse(axios.delete(`${Config.CH_ENDPOINT}ips/${id}`, this.options));
    }

    /**
     * 
     * @param {String} id 
     */
    getGroups = (id) => {
        return this.handleResponse(axios.get(`${Config.CH_ENDPOINT}groups`, this.options));
    }

    getGroupsCodes = (id) => {
        return this.handleResponse(axios.get(`${Config.CH_ENDPOINT}groups/${id}/codes`, this.options));
    }

    deleteCode = (id) => {
        return this.handleResponse(axios.delete(`${Config.CH_ENDPOINT}codes/${id}`, this.options));
    }

    deleteBatches = async (calls) => {
        return await Promise.all(calls).then((res) => {
            return res;
        });
    };

    deleteCodeByIds = (ids, groupId) => {
        let data = JSON.stringify({
            codeList: ids.join(',')
        });
        let config = {
            method: 'delete',
            url: `${Config.CH_ENDPOINT}groups/${groupId}/batch`,
            headers: this.options.headers,
            data: data
        };


        return this.handleResponse(axios(config));
    }

    postGroupsBatch = (data, id) => {
        data = JSON.stringify(data);
        let config = {
            method: 'post',
            url: `${Config.CH_ENDPOINT}groups/${id}/batch`,
            headers: this.options.headers,
            data: data
        };


        return this.handleResponse(axios(config));
    }

    postGroups = (data) => {

        data = JSON.stringify(data);
        let config = {
            method: 'post',
            url: `${Config.CH_ENDPOINT}groups`,
            headers: this.options.headers,
            data: data
        };
        return this.handleResponse(axios(config));
    }

    updateGroupsOrder = (groups) => {

        let calls = [];
        for (let index = 0; index < groups.length; index++) {
            const element = groups[index];
            let config = {
                method: 'put',
                url: `${Config.CH_ENDPOINT}groups/${element.id}`,
                headers: this.options.headers,
                data: element
            };
            calls.push(axios(config));
        }

        return Promise.all(calls);

    }

    deleteGroup = (id) => {
        return this.handleResponse(axios.delete(`${Config.CH_ENDPOINT}groups/${id}`, this.options));
    }

    updateGroup = (group) => {

        let config = {
            method: 'put',
            url: `${Config.CH_ENDPOINT}groups/${group.id}`,
            headers: this.options.headers,
            data: group
        };
        return this.handleResponse(axios(config));
    }
    checkRedirect = (path, domain) => {
        let headers = {
            // Authorization:  idToken.jwtToken,
            ...this.stripeOptions.headers
        }
        let config = {
            method: 'post',
            url: `${Config.CH_STRIPE_ENDPOINT[this.region]}check-redirect`,
            headers,
            data: JSON.stringify({
                domain: domain,
                urlRedirect: path,

            })
        };
        return this.handleResponse(axios(config));
    }

    newUserSetup = (data, idToken) => {
        let headers = {
            Authorization:  idToken.jwtToken,
            ...this.stripeOptions.headers
        }
        let config = {
            method: 'post',
            url: `${Config.CH_STRIPE_ENDPOINT[this.region]}setup-new-user`,
            headers : headers,
            data: data
        };
        return this.handleResponse(axios(config));
    }

    forgotPassword = (username) => {
        let headers = {
            ...this.chCognitoHeaders.headers,
        }

        let config = {
            method: 'post',
            url: `${cognitoEndpoint}reset-password`,
            headers,
            data:  JSON.stringify({
                username
            }),
        };
        return this.handleResponse(axios(config));

    }

    checkWP = (data) => {
        let headers = {
            ...this.chDashboardCheckHeaders.headers,
        }

        let config = {
            method: 'post',
            url: `${Config.CH_DASHBOARD_CHECKS_API}check-wordpress`,
            headers,
            data:  JSON.stringify({
                ...data
            }),
        };
        return this.handleResponse(axios(config));

    }


    checkJS = (data) => {
        let headers = {
            ...this.chDashboardCheckHeaders.headers,
        }

        let config = {
            method: 'post',
            url: `${Config.CH_DASHBOARD_CHECKS_API}check-javascript`,
            headers,
            data:  JSON.stringify({
                ...data
            }),
        };
        return this.handleResponse(axios(config));

    }

    shopifyIntegration = async (data, privateKey, subDomain) => {

        let config = {
            method: 'post',
            url: `https://${subDomain}.crowdhandler-webhook.com/crowdhandler/shopify/ping`,
            headers :  {
                'x-api-key': privateKey,
                'Content-Type': 'application/json',
            },
            data:  JSON.stringify({
                ...data
            }),
        };

        try {
            let res = await axios(config);
    
            if (res.status >= 200 && res.status < 400) {
                return {
                    message: res.data.message,
                    status: res.status
                };
            } else {
                return {
                    error: 'Error'
                }
            }
            
        } catch (error) {
            return {error};
        }
    }

    chShopifyDomain = (domain) => {
       
        let config = {
            method: 'post',
            url: process.env.REACT_APP_SHOPIFY_CHECK_DOMAIN,
            data: JSON.stringify({ 
                domain
             })
        };

        return this.handleResponse(axios(config));
            
    }
    
    shopifyIntegrationGenLambda = async (data, privateKey) => {

        let config = {
            method: 'post',
            url: Config.CH_SHOPIFY_LAMBDA,
            headers :  {
                'x-api-key': privateKey,
                'Content-Type': 'application/json',
            },
            data: JSON.stringify({
                ...data
            }),
        };

        return this.handleResponse(axios(config));
    }
    

    handleResponse = (axiosPromise) => {
        return axiosPromise
            .then(response => {
                if(response.data.result){
                    return response.data.result;
                }

                else if(response.data.customer){
                    return response.data.customer;
                }

                else if(response.data.prices){
                    return response.data.prices;
                }

                else if (response.data){
                    return response.data;
                }
            })
            .catch((error) => {

                let data = {
                    custId: this.custId,
                    'x-api-key' : error.config.headers['x-api-key'],
                    url: error.config.url,
                    payload: error.config.data,
                    message: error.message,
                    request: error.request,
                    response: error.response
                }


                if (process.env.NODE_ENV !== 'production') {
                    // console.log({error});
                }

                datadogRum.addError(error, data);
   
                if (error.response){
                    if (error.response.data && error.response.data.message === 'Too Many Requests') {
                        return {
                            status: error.response.status,
                            error: error.response.data.message,
                            type: 'error.response'
                        }
                    }
                    return {
                        status: error.response.status,
                        error: (error.response.data.error) ? error.response.data.error: error.response.data.results.error,
                        type: 'error.response'
                    }
                } else if (error.request) {
                    return {
                        status: 'Request Failed',
                        error: error.message,
                        type: 'error.request',
                        errorStatus: error.request.status
                    }
                } else if (error.message) {
                    if (process.env.NODE_ENV !== 'production') {
                       // alert(JSON.stringify(error.message));
                    }
                    return {
                        status: 'Request Failed',
                        error: error.message,
                        type: 'error.message',
                    }
                } else {
                    if (process.env.NODE_ENV !== 'production') {
                      //  alert(JSON.stringify(error));
                    }
                    return new Error("Network Error");

                }

            })
    }
}