import React from 'react';
import { ReactComponent as Save } from './feather/save.svg';

import { SvgIcon } from '@material-ui/core';


export default function SaveIcon(props) {
    return (
        <SvgIcon {...props} component={Save} className="feather"/>
    );
  }