import React, { useState, useEffect, useRef, useCallback } from "react";
import { makeStyles } from '@material-ui/core/styles';

import {
  Typography,
  Grid,
  FormControl,
  Link,
  TextField,
  Button,
  OutlinedInput,
  InputAdornment,
  CircularProgress
} from "@material-ui/core";

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Link as RouterLink } from 'react-router-dom'

import { CheckCircleIcon, InfoIcon } from "icons";


import { ErrorSnackBar, CopyButton } from '../';


const useStyles = makeStyles((theme) => ({
  section: {
    marginBottom: '40px',
    paddingBottom: '20px',
    borderBottom: '1px solid #cacaca',
    '& .MuiTypography-h6': {
      marginBottom: '10px'
    }
  },
  active: {
    color: theme.palette.success.main
  },
  inactive: {
    color: theme.palette.error.main
  },
}))



const DomainShopifyOnboarding = ({ domainName, publickey, privatekey, api, showGenerateWebhook }) => {
  const disableWebhook = useRef(false)
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [snackPack, setSnackPack] = useState([]);
  const [messageInfo, setMessageInfo] = useState(undefined);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarStatus, setSnackBarStatus] = useState('success');

  const [currentStateView, setCurrentStateView] = useState();

  const [isDomainSetup, setIsDomainSetup] = useState(false)
  const currentDomainValue = useRef()
  const currentSubDomainValue = useRef()
  const [crowdhandlerShopifyEndpoint, setCrowdhandlerShopifyEndpoint] = useState('')

  const [callPing, setCallPing] = useState(true)
  const [inProgress, setInProgress] = useState(false)
  const [shopifykey, setShopifyKey] = useState('')
  const [antiCheatEnabled, setAntiCheatEnabled] = useState(true)
  const [stockSyncEnabled, setStockSyncEnabled] = useState(true)
  const [refundEnabled, setRefundEnabled] = useState(true)
  const [shopifyApiEndpoint, setShopifyApiEndpoint] = useState('')
  const [orderCancellationEnabled, setOrderCancellationEnabled] = useState(true)
  const [shopifyWebhookSignature, setShopifyWebhookSignature] = useState('')
  const [enabled, setEnabled] = useState(true)
  const [updating, setUpdating] = useState(false)
  const webhookStatus = useRef()
  const webhookPingResponse = useRef()
  const intervalId = useRef()

  const [refundUpdating, setRefundUpdating] = useState(false)
  const [checkoutUpdating, setCheckoutUpdating] = useState(false)
  const [stockSyncUpdating, setStockSyncUpdating] = useState(false)
  const [orderCancellationEnabledUpdating, setOrderCancellationUpdating] = useState(false)

  const [shopifyData, setShopifyData] = React.useState(
    {
      domains: [],
      payloads: []
    }
  );


  const handleUpdating = useCallback(() => {

    if (webhookStatus.current && webhookStatus.current.status !== webhookPingResponse.current.status) {
      webhookStatus.current = webhookPingResponse.current;

      let message = 'Webhook enabled'
      if (webhookStatus.current.status === 200) {
        setEnabled(true);
      }

      if (webhookStatus.current.status === 202) {
        setEnabled(false);
        message = 'Webhook disabled'
      }

      if (intervalId.current) {
        clearInterval(intervalId.current);
      }
      setInProgress(false);
      setUpdating(false);
      setRefundUpdating(false);
      setCheckoutUpdating(false);
      setStockSyncUpdating(false);
      setOrderCancellationUpdating(false);

      setSnackBarStatus('success')
      setMessageInfo((prev) => {
        return {
          ...prev,
          message
        }
      });
      setOpenSnackBar(true)

    }
  }, [intervalId]);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    initialValues: {
      shopifykey: shopifykey,
      shopifyWebhookSignature: shopifyWebhookSignature,
      antiCheatEnabled: antiCheatEnabled,
      stockSyncEnabled: stockSyncEnabled,
      refundEnabled: refundEnabled,
      orderCancellationEnabled: orderCancellationEnabled,
    },
    validationSchema: Yup.object({
      shopifykey: Yup.string().required('Required')
        .test('Format test', 'Shopify Access Token is formatted incorrectly', (value, context) => {
          return value && value.indexOf('shpat_') === 0;
        }),
      shopifyWebhookSignature: Yup.string().required('Required'),
    }),
    onSubmit: async (values) => {
      setInProgress(true)
      let payload = {
        "CH_PUBLIC_KEY": publickey,
        "CH_PRIVATE_KEY": privatekey,
        "CONTEXT": []
      }

      if (disableWebhook.current) {
        payload.ENABLED = 'false'
      } else {
        payload.ENABLED = 'true'
      }

      let CONTEXT = []

      for (const key in shopifyData.payloads) {
        if (Object.hasOwnProperty.call(shopifyData.payloads, key)) {

          const element = shopifyData.payloads[key];
          if (element.site_domain !== currentDomainValue.current) {
            CONTEXT.push(element)
          }
        }
      }

      let domain_response;
      if (!isDomainSetup) {
        try {
          domain_response = await api.chShopifyDomain(currentDomainValue.current);
        } catch (error) {
          console.log(error);
        }
      }

      let context = {
        "site_domain": currentDomainValue.current,
        "shopify_key": values.shopifykey,
        "shopify_webhook_signature": values.shopifyWebhookSignature,
        
      }


      if (isDomainSetup) {
        context.anti_cheat_enabled = values.antiCheatEnabled ? 'true' : 'false';
        context.stock_sync_enabled = values.stockSyncEnabled ? 'true' : 'false';
        context.refund_enabled = values.refundEnabled ? 'true' : 'false';
        context.order_cancel_enabled = values.orderCancellationEnabled ? 'true' : 'false';

        if (!values.orderCancellationEnabled) {
          context.refund_enabled = 'false';
        }
      }

      if (domain_response && domain_response.shopify_api_endpoint && domain_response.domain !== 'DOMAIN_NOT_FOUND'){
        context.shopify_api_endpoint = domain_response.domain;
      }

      if (shopifyApiEndpoint) {
        context.shopify_api_endpoint = shopifyApiEndpoint
      }

      CONTEXT.push(context);

      payload.CONTEXT = CONTEXT;
      
      try {
        await api.shopifyIntegrationGenLambda(payload, privatekey);


        intervalId.current = setInterval(() => {
          setCallPing((prev) => {
            return !prev;
          });

          if (isDomainSetup && !updating) {
            clearInterval(intervalId.current);
            setInProgress(false);
            setSnackBarStatus('success')
            
            setMessageInfo((prev) => {
              return {
                ...prev,
                message: 'Updated'
              }
            });
            setOpenSnackBar(true)
            setRefundUpdating(false);
            setCheckoutUpdating(false);
            setStockSyncUpdating(false);
            setOrderCancellationUpdating(false);
          }
        }, 10000);

      } catch (error) {
        setSnackBarStatus('error')
        setMessageInfo((prev) => {
          return {
            ...prev,
            message: 'There has been an error'
          }
        });
        setOpenSnackBar(true);
        setInProgress(false);
        setRefundUpdating(false);
        setCheckoutUpdating(false);
        setStockSyncUpdating(false);
        setOrderCancellationUpdating(false);
      }


    }
  });

  useEffect(() => {
    const setEndpoint = () => {
      const subDomain = publickey.slice(0, 15);
      setCrowdhandlerShopifyEndpoint(`https://${subDomain}.crowdhandler-webhook.com/crowdhandler/shopify/cart`);
    }

    setEndpoint();
  }, [publickey]);

  useEffect(() => {
    const ping = async () => {
      // get the first 15 chars of the public key
      currentSubDomainValue.current = publickey.slice(0, 15);
      // strip the https:// from the domain name
      currentDomainValue.current = domainName.replace(/https:\/\//gm, '');
      let response;
      try {
        response = await api.shopifyIntegration({ DOMAIN: currentDomainValue.current }, privatekey, currentSubDomainValue.current);
      } catch (error) {
      }

      if (!response.error) {
        webhookPingResponse.current = response;
        if (Array.isArray(response.message)) {
          let values = response.message.reduce((acc, message) => {
            acc.domains.push(message.site_domain);
            acc.payloads.push(message);

            if (message.site_domain === currentDomainValue.current) {
              setIsDomainSetup(true);
              setShopifyKey(message.shopify_key);
              setShopifyWebhookSignature(message.shopify_webhook_signature);
              setAntiCheatEnabled(message.anti_cheat_enabled === 'true')
              setStockSyncEnabled(message.stock_sync_enabled === 'true')
              setRefundEnabled(message.refund_enabled === 'true')
              setShopifyApiEndpoint(message.shopify_api_endpoint);
              if (message.order_cancel_enabled) {
                setOrderCancellationEnabled(message.order_cancel_enabled === 'true')
              } else {
                setOrderCancellationEnabled('true')
              }
              setInProgress(false);
            }

            return acc;
          }, {
            domains: [],
            payloads: []
          });
          setShopifyData(values);

        }
        setCurrentStateView(2);

        // Set up initial states for enabled
        if (!webhookStatus.current) {

          webhookStatus.current = response;
          if (response.status === 200) {
            setEnabled(true);
          }

          if (response.status === 202) {
            setEnabled(false);
          }
        }

        handleUpdating();


      } else {
        setCurrentStateView(1);
      }


    }

    if (!callPing || callPing) {
      ping();
    }

  }, [api, domainName, privatekey, publickey, callPing, handleUpdating]);


  useEffect(() => {
    if (snackPack.length && !messageInfo) {
      // Set a new snack when we don't have an active one
      setMessageInfo({ ...snackPack[0] });
      setSnackPack((prev) => prev.slice(1));
      setOpenSnackBar(true);
    } else if (snackPack.length && messageInfo && open) {
      // Close an active snack when a new one is added
      setOpenSnackBar(false);
    }
  }, [snackPack, messageInfo, open]);

  const handleSnackBarClick = (message) => {
    setSnackPack((prev) => [...prev, { message, key: new Date().getTime() }]);
  };

  const handleSnackBarClose = (event, reason) => {


    if (reason === 'clickaway') {
      return;
    }
    setMessageInfo(undefined);
    setOpenSnackBar(false);
  };



  return (

    <Grid container direction="column">
      <Grid item xs={12}>
        {currentStateView === 1 || currentStateView === 2 ?
          <>
            <Grid container direction="row" className={classes.section} spacing={2}>
              <Grid item xs={12} md={3}>
                <Typography variant="body2" component="p" paragraph>
                  <Link target="_blank" href="https://www.crowdhandler.com/docs/80001119257-shopify-overview">
                    Getting started with the Shopify Integration
                  </Link>
                </Typography>


                {isDomainSetup ?
                <>
                <Typography paragraph><Link target="_blank" href="https://www.crowdhandler.com/docs/80001070393-javascript-integration-installing-on-shopify">Javascript installation instructions</Link></Typography>
                <Typography paragraph>
                <Link target="_blank" href="https://www.crowdhandler.com/docs/80001117490-shopify-generate-webhook-receiver">
                    Update Shopify Webhook Receiver
                  </Link>
                </Typography>
                <Typography paragraph>
                    <Link target="_blank" href="https://www.crowdhandler.com/docs/80001141240-shopify-anti-cheat-checkout-validator">
                    Installing the Anti-Cheat: Checkout Validator
                    </Link>
                  </Typography>
                </>
                :
                null}
              </Grid>
            
            {isDomainSetup && showGenerateWebhook ? 
              <Grid item xs={12} md={9}>
              {showGenerateWebhook ?
                <Grid item md={12} >
                  <Grid container direction="column" spacing={2}>
                    <Grid item md={12} xl={6}>
                    <Typography gutterBottom variant="h6">Update Shopify Webhook Receiver</Typography>
                    </Grid>
                    <Grid item md={12} xl={6}>
                      <Typography gutterBottom>CrowdHandler Webhook URL</Typography>
                      <FormControl fullWidth margin="dense" variant="outlined">
                        <OutlinedInput value={crowdhandlerShopifyEndpoint} readOnly
                          endAdornment={
                            <InputAdornment position="end">
                              <CopyButton
                                color="primary"
                                text={crowdhandlerShopifyEndpoint}
                                onCopy={() => {
                                  handleSnackBarClick(`CrowdHandler Webhook URL copied`);
                                }}
                              />
                            </InputAdornment>
                          }
                        ></OutlinedInput>
                      </FormControl>
                      <Grid container direction="row" alignItems="center" style={{ paddingTop: 12 }}>
                        {inProgress || updating ?
                          <>
                            <Grid item>
                              <CircularProgress style={{ width: 22, height: 22 }} />
                            </Grid>
                            <Grid item style={{ paddingLeft: '12px' }}>
                              <Typography>{isDomainSetup ? `Updating` : `Pending`}</Typography>
                              {!isDomainSetup
                                ?
                                <Typography>May take up to 5 minutes. Refresh the page if 5 minutes have passed and state is still pending.</Typography>
                                :
                                null
                              }
                            </Grid>
                          </>

                          :
                          isDomainSetup && enabled ?
                            <>
                              <Grid item className={classes.active}>
                                <CheckCircleIcon style={{ paddingRight: '12px' }} />
                              </Grid>
                              <Grid item>
                                <Typography>Webhook Active</Typography>
                              </Grid>
                            </>
                            :
                            <>
                              <Grid item className={classes.inactive}>
                                <InfoIcon style={{ paddingRight: '12px' }} />
                              </Grid>
                              <Grid item>
                                <Typography>Webhook Inactive</Typography>
                              </Grid>
                            </>

                        }


                      </Grid>
                    </Grid>

                    <Grid item md={12} xl={6} >
                      <FormControl fullWidth>
                        <Typography gutterBottom>Shopify Access Token</Typography>
                        <TextField variant="outlined"
                          {...formik.getFieldProps('shopifykey')}
                        ></TextField>
                        {formik.touched.shopifykey && formik.errors.shopifykey ? <Typography variant="subtitle2" color="error">{formik.errors.shopifykey}</Typography> : null}

                      </FormControl>
                    </Grid>
                    <Grid item md={12} xl={6}>
                      <FormControl fullWidth variant="outlined">
                        <Typography gutterBottom>Shopify Webhook Signature</Typography>
                        <TextField variant="outlined"
                          {...formik.getFieldProps('shopifyWebhookSignature')}
                        ></TextField>
                        {formik.touched.shopifyWebhookSignature && formik.errors.shopifyWebhookSignature ? <Typography variant="subtitle2" color="error">{formik.errors.shopifyWebhookSignature}</Typography> : null}
                      </FormControl>
                    </Grid>
                    <Grid item>
                      <Grid container spacing={2} direction="column">
                        <Grid item>
                          <FormControl variant="outlined">

                            {isDomainSetup
                              ?
                              <Button variant="outlined"

                                disabled={!formik.isValid || inProgress || updating || !enabled}
                                onClick={formik.handleSubmit}
                              >
                                Update
                              </Button>

                              :
                              <Button variant="outlined"

                                disabled={!formik.isValid || inProgress || updating}
                                onClick={formik.handleSubmit}
                              >
                                Install
                              </Button>

                            }


                          </FormControl>
                        </Grid>



                      </Grid>


                    </Grid>
                  </Grid>
                </Grid>
                :
                <Grid item md={9}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item>
                      <Typography>The ANTI-CHEAT Hook & Stock Syncer feature is available on Standard plan and higher</Typography>
                    </Grid>
                    <Grid item>
                      <Button
                        color="secondary"
                        variant="contained"
                        component={RouterLink} to='/account/upgrade'>
                        Upgrade
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>

              }
              </Grid>
          : null}

          

            </Grid>
            {!isDomainSetup ? 
            <Grid container direction="row" className={classes.section}>
              <Grid item md={5}>
                <Typography variant="h6" component="p">1. Install CrowdHandler JavaScript</Typography>
                <Typography>Follow the <Link target="_blank" href="https://www.crowdhandler.com/docs/80001070393-javascript-integration-installing-on-shopify">instructions</Link> to install the CrowdHandler JavaScript</Typography>
              </Grid>
            </Grid>
          : null}

{isDomainSetup && !showGenerateWebhook ?
            <Grid container spacing={2} alignItems="center"  className={classes.section}>
            <Grid item>
              <Typography><Link target="_blank" href="https://www.crowdhandler.com/docs/80001119257-shopify-overview">Advanced Shopify</Link> features are available on Standard plan and higher</Typography>
            </Grid>
            <Grid item>
              <Button
                color="secondary"
                variant="contained"
                component={RouterLink} to='/account/upgrade'>
                Upgrade
              </Button>
            </Grid>
          
          </Grid>
            : null} 

{!showGenerateWebhook && !isDomainSetup?
  <Grid container spacing={2} alignItems="center">
                    <Grid item>
                      <Typography><Link target="_blank" href="https://www.crowdhandler.com/docs/80001119257-shopify-overview">Advanced Shopify</Link> features are available on Standard plan and higher</Typography>
                    </Grid>
                    <Grid item>
                      <Button
                        color="secondary"
                        variant="contained"
                        component={RouterLink} to='/account/upgrade'>
                        Upgrade
                      </Button>
                    </Grid>
                  
                  </Grid>
: null}


          {showGenerateWebhook && !isDomainSetup ? 
           <Grid container direction="row" className={classes.section} spacing={2}>
              <Grid item md={5}>
              <Typography variant="h6" component="p">2. Install Anti-Cheat: Checkout Validator (optional)</Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                <Link target="_blank" href="https://www.crowdhandler.com/docs/80001141240-shopify-anti-cheat-checkout-validator">
                Install Anti-Cheat: Checkout Validator
                </Link>
              </Typography>
            </Grid>
            </Grid>
            : null}
              {!isDomainSetup && showGenerateWebhook ? 
            <Grid container direction="row" className={classes.section} spacing={2}>
              <Grid item md={5}>
                <Typography variant="h6" component="p">3. {isDomainSetup ? 'Update' : 'Generate'} Shopify Webhook Receiver (optional)</Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  <Link target="_blank" href="https://www.crowdhandler.com/docs/80001117490-shopify-generate-webhook-receiver">
                    {isDomainSetup ? 'Update' : 'Generate'} Shopify Webhook Receiver
                  </Link>
                </Typography>
              </Grid>

              
              

              {showGenerateWebhook && !isDomainSetup?
                <Grid item md={9}>
                  <Grid container direction="column" spacing={2}>
                    <Grid item md={6}>
                      <Typography gutterBottom>CrowdHandler Webhook URL</Typography>
                      <FormControl fullWidth margin="dense" variant="outlined">
                        <OutlinedInput value={crowdhandlerShopifyEndpoint} readOnly
                          endAdornment={
                            <InputAdornment position="end">
                              <CopyButton
                                color="primary"
                                text={crowdhandlerShopifyEndpoint}
                                onCopy={() => {
                                  handleSnackBarClick(`CrowdHandler Webhook URL copied`);
                                }}
                              />
                            </InputAdornment>
                          }
                        ></OutlinedInput>
                      </FormControl>
                      <Grid container direction="row" alignItems="center" style={{ paddingTop: 12 }}>
                        {inProgress || updating ?
                          <>
                            <Grid item>
                              <CircularProgress style={{ width: 22, height: 22 }} />
                            </Grid>
                            <Grid item style={{ paddingLeft: '12px' }}>
                              <Typography>{isDomainSetup ? `Updating` : `Pending`}</Typography>
                              {!isDomainSetup
                                ?
                                <Typography>May take up to 5 minutes. Refresh the page if 5 minutes have passed and state is still pending.</Typography>
                                :
                                null
                              }
                            </Grid>
                          </>

                          :
                          isDomainSetup && enabled ?
                            <>
                              <Grid item className={classes.active}>
                                <CheckCircleIcon style={{ paddingRight: '12px' }} />
                              </Grid>
                              <Grid item>
                                <Typography>Webhook Active</Typography>
                              </Grid>
                            </>
                            :
                            <>
                              <Grid item className={classes.inactive}>
                                <InfoIcon style={{ paddingRight: '12px' }} />
                              </Grid>
                              <Grid item>
                                <Typography>Webhook Inactive</Typography>
                              </Grid>
                            </>

                        }


                      </Grid>
                    </Grid>

                    <Grid item>
                      <FormControl fullWidth>
                        <Typography gutterBottom>Shopify Access Token</Typography>
                        <TextField variant="outlined"
                          {...formik.getFieldProps('shopifykey')}
                        ></TextField>
                        {formik.touched.shopifykey && formik.errors.shopifykey ? <Typography variant="subtitle2" color="error">{formik.errors.shopifykey}</Typography> : null}

                      </FormControl>
                    </Grid>
                    <Grid item>
                      <FormControl fullWidth variant="outlined">
                        <Typography gutterBottom>Shopify Webhook Signature</Typography>
                        <TextField variant="outlined"
                          {...formik.getFieldProps('shopifyWebhookSignature')}
                        ></TextField>
                        {formik.touched.shopifyWebhookSignature && formik.errors.shopifyWebhookSignature ? <Typography variant="subtitle2" color="error">{formik.errors.shopifyWebhookSignature}</Typography> : null}
                      </FormControl>
                    </Grid>
                    <Grid item>
                      <Grid container spacing={2} direction="column">
                        <Grid item>
                          <FormControl variant="outlined">

                            {isDomainSetup
                              ?
                              <Button variant="outlined"

                                disabled={!formik.isValid || inProgress || updating || !enabled}
                                onClick={formik.handleSubmit}
                              >
                                Update
                              </Button>

                              :
                              <Button variant="outlined"

                                disabled={!formik.isValid || inProgress || updating}
                                onClick={formik.handleSubmit}
                              >
                                Install
                              </Button>

                            }


                          </FormControl>
                        </Grid>



                      </Grid>


                    </Grid>
                  </Grid>
                </Grid>
                :
                <Grid item md={9}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item>
                      <Typography>The ANTI-CHEAT Hook & Stock Syncer feature is available on Standard plan and higher</Typography>
                    </Grid>
                    <Grid item>
                      <Button
                        color="secondary"
                        variant="contained"
                        component={RouterLink} to='/account/upgrade'>
                        Upgrade
                      </Button>
                    </Grid>
                  
                  </Grid>
                </Grid>

              }
            </Grid>
            : null}

            {isDomainSetup && showGenerateWebhook ?
            <>
              
              <Grid container direction="column" className={classes.section} spacing={2}>
                
                <Grid item md={5}>
                  <Typography variant="h6" component="p">1. Configure Checkout Post-Processing (optional)</Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                    <Link target="_blank" href="https://www.crowdhandler.com/docs/80001118987-shopify-enable-checkout-post-processing-stock-syncing">
                    Configure Checkout Post-Processing
                    </Link>
                  </Typography>
                </Grid>
                <Grid item>
                          <Grid container spacing={2}>

                          <Grid item>
                              <FormControl variant="outlined">
                                <Button variant="outlined"
                                color={(orderCancellationEnabled) ? 'primary' : 'secondary'}
                                disabled={!formik.isValid || inProgress || updating || !enabled}
                                onClick={() => {
                                  formik.setFieldValue('orderCancellationEnabled', !orderCancellationEnabled);
                                  setOrderCancellationUpdating(true);
                                  formik.handleSubmit();
                                }}>
                                  {orderCancellationEnabled ? `Disable Cancel Order ` : `Enable Cancel Order`}
                                  {orderCancellationEnabledUpdating ? 
                                  <CircularProgress style={{ width: 18, height: 18, marginLeft: 10 }} />
                                  : null}
                                </Button>
                              </FormControl>

                            </Grid>
                            
                            <Grid item>
                            <FormControl variant="outlined">
                                <Button variant="outlined"
                                color={(refundEnabled) ? 'primary' : 'secondary'}
                                disabled={!formik.isValid || inProgress || updating || !enabled || !orderCancellationEnabled}
                                 onClick={() => {
                                  formik.setFieldValue('refundEnabled', !refundEnabled);
                                  setRefundUpdating(true);
                                  formik.handleSubmit();
                                }}>
                                  {refundEnabled ? `Disable Auto Refund` : `Enable Auto Refund`}
                                  {refundUpdating ? 
                                  <CircularProgress style={{ width: 18, height: 18, marginLeft: 10 }} />
                                  : null}
                                </Button>

                              </FormControl>
                            </Grid>
                            <Grid item>
                              <FormControl variant="outlined">
                                <Button variant="outlined"
                                color={(antiCheatEnabled) ? 'primary' : 'secondary'}
                                disabled={!formik.isValid || inProgress || updating || !enabled}
                                onClick={() => {
                                  formik.setFieldValue('antiCheatEnabled', !antiCheatEnabled);
                                  setCheckoutUpdating(true);
                                  formik.handleSubmit();
                                }}>
                                  {antiCheatEnabled ? `Disable Checkout Post-processing` : `Enable Checkout Post-processing`}
                                  {checkoutUpdating ? 
                                  <CircularProgress style={{ width: 18, height: 18, marginLeft: 10 }} />
                                  : null}
                                </Button>

                              </FormControl>

                            </Grid>

                          </Grid>
                          </Grid>
                
              </Grid>
              <Grid container direction="column" className={classes.section} spacing={2}>
                <Grid item md={5}>
                  <Typography variant="h6" component="p">2. Configure Stock Syncing (optional)</Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                  <Link target="_blank" href="https://www.crowdhandler.com/docs/80001118987-shopify-enable-checkout-post-processing-stock-syncing">
                    Configure Stock Syncing
                    </Link>
                  </Typography>
                </Grid>
                <Grid item>
                  <Grid container spaing={2}>
                  <Grid item>
                            <FormControl variant="outlined">
                                <Button variant="outlined"
                                color={(stockSyncEnabled) ? 'primary' : 'secondary'}
                                disabled={!formik.isValid || inProgress || updating || !enabled}
                                 onClick={() => {
                                  formik.setFieldValue('stockSyncEnabled', !stockSyncEnabled);
                                  setStockSyncUpdating(true);
                                  formik.handleSubmit();
                                }}>
                                  {stockSyncEnabled ? `Disable Stock Sync` : `Enable Stock Sync`}
                                  {stockSyncUpdating ? 
                                  <CircularProgress style={{ width: 18, height: 18, marginLeft: 10 }} />
                                  : null}
                                </Button>

                              </FormControl>
                            </Grid>
                  </Grid>
                </Grid>

                

              </Grid>
              </>
              : null}
          </>

          :
          null}




      </Grid>



      <ErrorSnackBar
        status={snackBarStatus}
        open={openSnackBar}
        key={messageInfo ? messageInfo.key : undefined}
        autoHideDuration={2000}
        closeErrorSnackbar={() => {
          setOpenSnackBar(false)
        }}
        onClose={handleSnackBarClose}
        message={messageInfo ? messageInfo.message : undefined}
      />
    </Grid>
  )
}


export default DomainShopifyOnboarding;