import React, { useCallback, useState, useEffect } from "react";
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  TableCell,
  TableRow,
  FormControlLabel,
  Switch,
  Select,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  ListItemText,
  Checkbox,
  Typography,

} from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  enabled: {
    borderColor: theme.palette.primary.main,
    borderStyle: 'solid',
    borderWidth: '1px',
    boxShadow: `0px 1px 3px 0px rgba(0, 0, 0, 0.2)`
  },
  disabled : {
    borderStyle: 'none',
    borderWidth: '0'
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, emailState, theme) {
  return {
    fontWeight:
      emailState.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightBold,
  };
}

const emailMeStates = [
  "countdown",
  "queue",
  // "waitlist",
  // "required",
];

const emailMeStatesMap = {
  "countdown": 'Countdown',
  "queue": "Queue",
  "waitlist": "Waitlist",
  "required": "Required"
}



const EmailMeRow = ({ room, saving, saveRoom, setSaving, }) => {

  const classes = useStyles();
  const theme = useTheme();

  let [role, setRole] = useState(room.emailNotifications);

  const handleRoleSelectChange = () => {
    setSaving(true)
    let newValue = !role;
    setRole(newValue);
    saveRoom({
      ...room,
      emailNotifications: (newValue) ? 1 : 0
    });
  }

  const [emailState, setEmailState] = React.useState(room.email !== null ? room.email : []);


  const handleChange = (event) => {
    setEmailState(event.target.value);
  };

  const handleClose = () => {
    setSaving(true);
    saveRoom({
      id: room.id,
      email: emailState
    });
  };



  return (
    <TableRow>
      <TableCell><span style={{ wordBreak: 'break-all' }}>{room.title}</span></TableCell>
      <TableCell align="right">
        <FormControl className={clsx(classes.formControl, classes.noLabel)} margin="dense" variant="outlined" fullWidth >
        <Select
          classes={{
            outlined: emailState.length ? classes.enabled : classes.disabled
          }}
          multiple
          displayEmpty
          value={emailState}
          onChange={handleChange}
          onClose={handleClose}
          renderValue={(selected) => {
            if (!selected.length || selected.length === 0) {
              return ['Off'];
            }
            let content = selected.map((name)=> emailMeStatesMap[name]).join(', ');
            return <Typography style={{
              color: theme.palette.primary.main,
            }}>{content}</Typography>;
          }}
          MenuProps={MenuProps}
        >
          {emailMeStates.map((name) => (
            <MenuItem key={name} value={name} >
              <Checkbox checked={emailState.indexOf(name) > -1} />
              <ListItemText  primary={emailMeStatesMap[name]} />
            </MenuItem>
          ))}
        </Select>
        </FormControl>


        {/* <FormControlLabel
          control={
            <Switch
              checked={room.emailNotifications === 1}
              onChange={handleRoleSelectChange}
              name="roleSwitcher"
            />
          }
          label={(room.emailNotifications === 0) ? 'No' : 'Yes'}
        /> */}


      </TableCell>
    </TableRow>
  );

}


export default EmailMeRow;