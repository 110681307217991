import React, { FunctionComponent, useState } from 'react';
import {
    Grid,
    FormControl,
    Button,
    makeStyles,
    IconButton,
    Tooltip,
} from '@material-ui/core';
import { DateTime } from 'luxon';
import Config from '../../config';
import AdminHeaderDomainSelector from '../../layouts/components/AdminHeaderDomainSelector';
import ReportDateTimePicker from '../ReportDateTimePicker';
import { DownloadIcon, RotateCCWIcon } from "../../icons";

export type TUndoProps = {
    onUndo?: () => void;
    disabled?: boolean;
}

interface Props {
    globalState: { currentDomain: string, updating?: boolean, domains: { id: string, name: string }[] };
    downloadTime: { from: DateTime, to: DateTime };
    periodRange: 'day' | 'hour' | 'minute';
    undoProps?: TUndoProps;
    onUpdate?: () => void;
    onDownload?: () => void;
    onDomainChange?: (dId: string) => void;
    onDateChange?: (v: DateTime, n: string) => void;
}

const AdminHeaderDomainSelectorStyles = makeStyles((theme) => ({
    formControl: {
        marginLeft: 'auto',
    },
    formControlSelect: {
        color: theme.palette.primary.main,
        '& svg': {
            color: theme.palette.primary.main
        },
    },
    hideFormControlSelect: {
        display: 'none'
    },
    selector: {
        [theme.breakpoints.between("xs", 1052)]: {
            marginBottom: 10,
            width: '100%'
        }
    },
    actionButtonsContainer: {
        [theme.breakpoints.between("xs", 801)]: {
            width: '100%',
            justifyContent: 'flex-start'
        }
    },
    actionButtons: {
        [theme.breakpoints.between("xs", 801)]: {
            width: '100%',
            justifyContent: 'space-between'
        }
    }
}));

const ReportHeader: FunctionComponent<Props> = ({
    globalState,
    periodRange,
    downloadTime,
    undoProps,
    onDownload,
    onUpdate,
    onDomainChange,
    onDateChange
}) => {
    const [state, setState] = useState({
        domainId: '',
        dateFormat: Config.DATE_FORMATS.READABLE_FULL,
    });
    const handleChangeDomain = (dId: string) => {
        setState(s => ({ ...s, domainId: dId }));
        if (typeof onDomainChange === 'function') onDomainChange(dId);
    }

    const handleDateChange = (d: DateTime, n: string) => {
        if (typeof onDateChange === 'function') onDateChange(d, n);
    }

    // useEffect(() => {
    //     if (periodRange === 'day') 
    //         setState(s => ({ ...s, dateFormat: Config.DATE_FORMATS.READABLE_NO_TIME}))
    //     else
    //         setState(s => ({ ...s, dateFormat: Config.DATE_FORMATS.READABLE_FULL}))
    // }, [periodRange]);

    const { dateFormat } = state;

    return (
        <Grid container direction='row' justifyContent='space-between'>
            
            <Grid item className={AdminHeaderDomainSelectorStyles().selector}>
                <AdminHeaderDomainSelector 
                    domainId={globalState.currentDomain}
                    updating={globalState.updating}
                    domains={globalState.domains}
                    onChange={handleChangeDomain} 
                    stylesOverride={AdminHeaderDomainSelectorStyles}  />
            </Grid>

            <Grid item >
                <Grid container spacing={1} direction="row" alignItems="center" justifyContent="flex-end" className={AdminHeaderDomainSelectorStyles().actionButtonsContainer}>
                    <Grid item className={AdminHeaderDomainSelectorStyles().actionButtons}>
                        <ReportDateTimePicker
                            downloadTime={downloadTime}
                            dateFormat={dateFormat}
                            onDateChange={handleDateChange}
                            periodRange={periodRange}
                            minDate={false}
                            maxDate={false}
                        />
                    </Grid>
                    <Grid item>
                        <FormControl size="medium" variant="outlined" className={AdminHeaderDomainSelectorStyles().formControl}>
                            <Button variant="contained" disableElevation onClick={onUpdate} color="secondary">
                                Update
                            </Button>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl size="medium" variant="outlined" className={AdminHeaderDomainSelectorStyles().formControl}>
                            <Button variant="contained" disableElevation onClick={onDownload} color="primary" startIcon={<DownloadIcon />}>
                                Download
                            </Button>
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
};

export default ReportHeader;