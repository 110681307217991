import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';

const PublicRouteWithLayout = props => {

  const { authorized, layout: Layout, domainsSwitcherdata, updateSelectedDomain, currentlySelectedDomain, component: Component, ...rest } = props;
  let {state} = rest;
  return (
    <>

        <Route
          {...rest}
          render={props => (
            <Layout state={state} currentlySelectedDomain={currentlySelectedDomain} domainsSwitcherdata={domainsSwitcherdata} updateSelectedDomain={updateSelectedDomain} >
              <Component {...rest} />
            </Layout>
          )}
        />

    </>
  );
};

PublicRouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string
};

export default PublicRouteWithLayout;
