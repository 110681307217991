export default function CreatePreviewUrl (room, logo) {

    let payload = {
        captcha: room.captcha,
        countdownStartsOn: room.countdownStartsOn,
        message: room.message,
        onsale: room.queueActivatesOn,
        stock: room.stock,
        title: room.title,
        logo
      }
  
      let room_data = (encodeURIComponent(JSON.stringify(payload), null, 2));

      return room_data;


}