
import React from 'react';
import * as Yup from 'yup';
import { withFormik, Form, } from 'formik';
import { Grid, Button, FormControl, MenuItem } from "@material-ui/core";

import { ChFormikSelect, ChFormikTextField } from '..';




const formikEnhancer = withFormik({
    mapPropsToValues: (props) => {

        if (props.address) {

            if(!props.name) {

                return {
                    name: props.address.line1 ? props.address.line1 : '',
                    line1: props.address.line2 ? props.address.line2 : '',
                    line2: '',
                    city: props.address.city ? props.address.city : '',
                    country: props.address.country ? props.address.country : '',
                    postal_code: props.address.postal_code ? props.address.postal_code : '',
                }

            } else {

                return {
                    name: props.name ? props.name : '',
                    line1: props.address.line1 ? props.address.line1 : '',
                    line2: props.address.line2 ? props.address.line2 : '',
                    city: props.address.city ? props.address.city : '',
                    country: props.address.country ? props.address.country : '',
                    postal_code: props.address.postal_code ? props.address.postal_code : '',
                }
            }


        } else {
            return {
                name: '',
                line1: '',
                line2: '',
                city: '',
                country: '',
                postal_code: ''
            }
        }
    },
    validationSchema: Yup.object().shape({
        line1: Yup.string().required('This is a required field')
    }),
    handleSubmit: (values, { setSubmitting, props }) => {

        let formattedValues = {};

        let { name, ...rest } = values;

        for (const key in rest) {
            if (rest.hasOwnProperty(key)) {
                const value = rest[key];
                formattedValues[key] = value;
            }
        }

        props.handleAddressUpdate(
            {

                address: { ...formattedValues },
                name: values.name

            }
        );
    },

    displayName: 'AccountDetailsAddressForm', // helps with React DevTools
});

const AccountDetailsAddressForm = ({ handleReset, setEditBillingAddress, CountryCodes, errors, touched }) => {

    
    return (

        <Form>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormControl fullWidth margin="dense" variant="outlined">
                        <ChFormikTextField
                            size="small"
                            type="text"
                            name="name"
                            placeholder="Organisation Name"
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth margin="dense" variant="outlined">
                        <ChFormikTextField
                            size="small"
                            type="text"
                            name="line1"
                            placeholder="Address Line 1"
                        />
                    </FormControl>

                </Grid>
                
                <Grid item xs={12}>

                    <FormControl fullWidth margin="dense" variant="outlined">
                        <ChFormikTextField
                            size="small"
                            type="text"
                            name="line2"
                            placeholder="Address Line 2"
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>

                    <FormControl fullWidth margin="dense" variant="outlined">
                        <ChFormikTextField
                            size="small"
                            type="text"
                            name="city"
                            placeholder="City"
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth margin="dense" variant="outlined">
                        <ChFormikSelect
                            size="small"
                            type="text"
                            name="country"
                            label="Country"
                            notched={false}

                        >

                            {CountryCodes.map((country) => {
                                return <MenuItem key={country.code} value={country.code}>{country.name}</MenuItem>
                            })}



                        </ChFormikSelect>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth margin="dense" variant="outlined">
                        <ChFormikTextField
                            size="small"
                            type="text"
                            name="postal_code"
                            placeholder="ZIP / Postal Code"
                        >
                        </ChFormikTextField>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Button disableElevation variant="contained" size="small" color="secondary" type="submit"
                        style={{ "marginRight": "10px" }}>
                        Submit
                    </Button>
                    <Button disableElevation variant="contained" size="small" color="primary" onClick={() => {
                        handleReset();
                        setEditBillingAddress(false);
                    }}>
                        Cancel
                    </Button>

                </Grid>
            </Grid>
            
        </Form>
    );
};

export default formikEnhancer(AccountDetailsAddressForm);