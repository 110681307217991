import React, { useState, useEffect, useReducer, useRef, useCallback } from "react";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import sortBy from 'lodash/sortBy';
import { withRouter } from 'react-router-dom'; // To use the history object
import DomainIPAddressesTable from '../../components/DomainIPAddressesTable';
import ErrorSnackBar from '../../components/ErrorSnackBar';
import SavingBar from '../../components/SavingBar';
import { MaxIPAddressesControl, AutoblockAnomalyControl, AutoblockAttackerControl } from '../../components';
import { useFormik } from 'formik';
import withWidth from '@material-ui/core/withWidth';
import { ArrowUpCircleIcon, ArrowDownCircleIcon } from '../../icons';
import IPRecommendations from '../../components/IPManagement/IPRecommendations';

import HelpCircleIcon from '../../icons/HelpCircleIcon';
import './DomainIPAddresses.css';
import {
    Grid,
    Badge,
    Typography,
    Collapse,
    Card,
    IconButton,
    Dialog,
    DialogContent,
} from '@material-ui/core';

import theme from "theme";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import AutoBlockLog from '../../components/AutoBlock/AutoBlockLog';

import {
    getComparator,
    stableSort
} from '../../lib/tableComparators';


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                
                <>
                    {children}
                </>
                
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const useStyles = makeStyles((theme) => ({

    autoblockPanel: {

        justifyContent: 'flex-start',
        borderBottomRightRadius: 6,
        borderBottomLeftRadius: 6,
        borderTopRightRadius: 6,
        borderTopLeftRadius: 6,


    },
    autoblockHeader: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
        '& .MuiTypography-root': {
            color: '#fff'
        }
    },
    margin: {
        margin: theme.spacing(6),
    },
    ipBlockingPaper: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        marginBottom: theme.spacing(2),
        position: 'relative'
    },
    paper: {
        padding: theme.spacing(2),
    },
    tableGroup: {
        marginBottom: theme.spacing(0),
        width: '100%'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: theme.palette.secondary.main,
    },
}));

// const mock_blocks = require('../../mock-data/nycb_autoblock_data_1.json')


const DomainsIPAddresses = ({ computedMatch, api, state, width, ...rest }) => {
    const { dispatch } = rest;
    let [processing, setProcessing] = useState(false);
    let [autoblockProcessing, setAutoblockProcessing] = useState(false);
    // let [savingDomain, setSavingDomain] = useState(false);
    let [message, setMessage] = useState('');
    let [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
    let [alertBarStatus, setAlertBarStatus] = useState('success');

    let [domainID, setDomainID] = useState(computedMatch.params.domainId);
    let [domain, setDomain] = useState();
    let [maxSessionsPerIPValue, setMaxSessionsPerIPValue] = useState(0);
    let [maxScore, setMaxScore] = useState(0);
    let [useMaxScore, setUseMaxScore] = useState(false);
    let [blockThreats, setBlockThreats] = useState([]);
    let [open, setOpen] = useState(true);
    let [openAnomalyHelp, setOpenAnomalyHelp] = useState(false);

    const isMobile = width === 'xs' || width === 'sm';

    // AutoBlockTable
    const blockData = useRef([]);
    const rowsPerPageDefault = useRef(25);
    const rowsOrderByDefault = useRef('avg');
    const rowsOrderDefault = useRef('desc');
    const rowsPageDefault = useRef(0);
    const [loading, setLoading] = useState();
    const [count, setCount] = useState(0);
    const [blockCount, setBlockCount] = useState(0);
    const [page, setPage] = useState(rowsPageDefault.current);
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageDefault.current);
    const [orderBy, setOrderBy] = useState(rowsOrderByDefault.current);
    const [order, setOrder] = useState(rowsOrderDefault.current);
    const [filteredBlocks, setFilteredBlocks] = useState([]);
    const [searchValue, setSearchValue] = useState({
        value: ''
    });
    const [numberOfAutoBlockResults, setNumberOfAutoBlockResults] = useState(0);



    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    let [recommendations, setRecommendations] = useState([]);


    useEffect(() => {
        const set_domain = () => {
            let domain = state.domains.reduce((acc, domain) => {
                if (domain.id === computedMatch.params.domainId) {
                    acc = domain;
                }
                return acc;
            }, {})


            setDomain(domain);

            if (domain.maxSessionsPerIP === null) {
                setMaxSessionsPerIPValue(0);
            } else {
                setMaxSessionsPerIPValue(domain.maxSessionsPerIP);
            }

            if (domain.maxScore === null) {
                setUseMaxScore(false)
                setMaxScore(0);
            } else {
                setUseMaxScore(true)
                setMaxScore(domain.maxScore);
            }


            if (!Array.isArray(domain.blockThreats) && domain.blockThreats !== null) {
                domain.blockThreats = [domain.blockThreats];
            }

            setBlockThreats(domain.blockThreats ? domain.blockThreats : []);
        }
        if (computedMatch.params.domainId && state.domains) {
            set_domain();
        }

    }, [computedMatch.params.domainId, state.domains]);

    const processIPResponse = useCallback(async (responses) => {

        if (!responses || !responses.length) {
            return [];
        }

        try {

            let promises = responses.map(async (response, index) => {

                let ip_data = await api.getIPData(response.address);
                response.ip_data = (ip_data.status !== 400) ? ip_data : [];
                return response

            });

            let result = await Promise.all(promises);

            return result;

        } catch (error) {
            return [];
        }

    }, [api]);

    const getDomainIPRecommendList = useCallback(() => {

        const getRecommended = async () => {

            let recommended_response = await api.getDomainIPRecommendList(domain.id);
            recommended_response = await processIPResponse(recommended_response);

            if (!recommended_response.error) {
                setRecommendations(recommended_response);

                if (recommended_response.length < 1 && value === 1) {
                    setValue(0);
                }
            }
        }

        if (domain && domain.id) {
            getRecommended();
        }

    }, [api, domain, processIPResponse, value]);

    const getInitialDomainIPRecommendList = useCallback(() => {

        const getRecommended = async () => {

            let recommended_response = await api.getDomainIPRecommendList(domain.id);
            recommended_response = await processIPResponse(recommended_response);

            if (!recommended_response.error) {
                setRecommendations(recommended_response);
            }
        }

        if (domain && domain.id) {
            getRecommended();
        }

    }, [api, domain, processIPResponse]);

    // AutoBlock Table Handling

    // Getting the initial autoblocks data
    useEffect(() => {
        getAutoblockData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const processAutoBlockResponse = useCallback(async (responses) => {

        if (!responses || !responses.length) {
            return [];
        }

        try {

            let promises = responses.map(async (response, index) => {
                if (!response.ip_data) {
                    let ip_data = await api.getIPData(response.address);
                    response.ip_data = (ip_data.status !== 400) ? ip_data : [];
                }
                return response

            });

            let result = await Promise.all(promises);

            return result;

        } catch (error) {
            return [];
        }

    }, [api]);

    useEffect(() => {

        async function handleFilteredBlocks() {
            let data;
            if (searchValue.value) {
                data = blockData.current.filter((block) => {
                    return block.address.indexOf(searchValue.value.trim()) === 0;
                });
            } else {
                data = blockData.current
            }

            setCount(data.length)

            let visible_blocks = stableSort(data, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
            let processed_visible_blocks = await processAutoBlockResponse(visible_blocks);
            setFilteredBlocks(processed_visible_blocks);

        }

        // if we're not on page 1 and we have a search value then reset the page
        // this will then recall this effect
        if (searchValue.value && page !== 0) {
            setPage(0)
        } else {
            handleFilteredBlocks();
        }


    }, [order, orderBy, page, processAutoBlockResponse, rowsPerPage, searchValue.value]);


    

    


    const processAutoBlockData = useCallback(async () => {

        setLoading(true);
        // get the initial group of blocks to display
        let initial_visible_blocks = stableSort(blockData.current, getComparator(rowsOrderDefault.current, rowsOrderByDefault.current)).slice(rowsPageDefault.current * rowsPerPageDefault.current, rowsPageDefault.current * rowsPerPageDefault.current + rowsPerPageDefault.current);
        // set a blocks state to pass to AutoBockLog to build the donuts
        // can't use the ref because the ref won't update the view??
        setCount(blockData.current.length);
        let processed_visible_blocks;
        
        if (searchValue.value) {
            let data = blockData.current.filter((block) => {
                return block.address.indexOf(searchValue.value.trim()) === 0;
            });
            setCount(data.length);
            initial_visible_blocks = stableSort(data, getComparator(rowsOrderDefault.current, rowsOrderByDefault.current)).slice(rowsPageDefault.current * rowsPerPageDefault.current, rowsPageDefault.current * rowsPerPageDefault.current + rowsPerPageDefault.current);
        }
        
        setFilteredBlocks(initial_visible_blocks);
        processed_visible_blocks = await processAutoBlockResponse(initial_visible_blocks); 
        
        setNumberOfAutoBlockResults(blockData.current.length)
        
        setFilteredBlocks(processed_visible_blocks);

        setLoading(false)


    }, [processAutoBlockResponse, searchValue.value])


    const[range, setRange] = useState([])
    const getAutoblockData = useCallback(async () => {
        let data = [];
        try {
            data = await api.getAutoblocksByDomain(domainID);
            // data = mock_blocks.result;
        } catch (error) {
            setLoading(false)
        }


        // if anomaly is a reason then add a link for it
        let blocks = data.map((block) => {
            if (block.reasons.indexOf('anomaly score') !== -1) {
                block.link = `/rooms/sessions?roomID=all&domainID=${domainID}&includeActiveUsers=2&archivedUsers=1&search=${block.address}`
            }
            return block;
        },);

        let temp_range = []
        if (blocks.length) {
            temp_range[0] = blocks[0].time
        }

        if (blocks.length > 1) {
            temp_range[1] = blocks[blocks.length-1].time
        }

        setRange(temp_range);
        // set the total page count
        setCount(blocks.length);

        let only_blocks = blocks.reduce((acc, block) => {
            if(block.action === "block") {
                acc.push(block);
            }
            return acc;
        },[]);

        setBlockCount(only_blocks.length)

        // save the blocks to a reference
        blockData.current = blocks;
        processAutoBlockData();
    },[api, domainID, processAutoBlockData, setRange]);

    useEffect(() => {
        const getRecommendations = async () => {
            getInitialDomainIPRecommendList();
        }
        if (domain && domain.id) {
            getRecommendations();
        }
    }, [api, domain, getInitialDomainIPRecommendList]);

    const [ipGroups, dispatchIPGroups] = useReducer(reducer, []);

    const closeErrorSnackbar = () => {
        setOpenErrorSnackbar(false)
    }

    function reducer(ipGroups, action) {
        switch (action.type) {
            case 'SET_IP_GROUPS':

                let sorted = sortBy(action.payload, 'sessions');
                return sorted;
            default:
                throw new Error();
        }
    }

    const classes = useStyles();
    const isMounted = useRef(true);



    useEffect(() => {
        const getTheIps = async () => {

            setProcessing(true);



            // get the latest 100 domains
            let response = await api.getDomainIPList(computedMatch.params.domainId);

            //loop through the responses and add the existing ip_data to them

            // replace the ipGroups with the new data
            if (!response.error) {
                response = response.map((res) => {
                    res.ip_data = [];
                    return res;
                })
                dispatchIPGroups({ type: 'SET_IP_GROUPS', payload: response })
                setProcessing(false);

                response = await processIPResponse(response);


                dispatchIPGroups({ type: 'SET_IP_GROUPS', payload: response })
                setProcessing(false);

            }
        }
        if (computedMatch.params.domainId && isMounted.current && !processing) {
            setDomainID(computedMatch.params.domainId);
            getTheIps();
        }

        let intervalsID = setInterval(() => {
            if (!processing && value === 0) {
                getTheIps();
            }
        }, 60000);

        return () => {
            // Clean up the subscription
            clearInterval(intervalsID);
            setProcessing(false);
            isMounted.current = false;
        };
    }, [computedMatch.params.domainId, api, processing, ipGroups, processIPResponse, value]);

    const addIPAddresses = async (values) => {
        try {
            setProcessing(true);
            let response = await api.moveIPToList(values);

            if (response.error) {
                let message = 'Error Adding IP Address';
                if (response.error === ' invalid IP address') {
                    message = 'Invalid IP Address';
                }
                setMessage(message);
                setOpenErrorSnackbar(true);
                setAlertBarStatus('error');
                setProcessing(false);

                return true;
            } else if (response) {
                response = await processIPResponse(response);
                dispatchIPGroups({ type: 'SET_IP_GROUPS', payload: response })
                setProcessing(false);
                setMessage('IP Address Added')
                setOpenErrorSnackbar(true);
                setAlertBarStatus('success');
                getDomainIPRecommendList();
                return true;
            }
        } catch (error) {
            setProcessing(false);
            return true;
        }
    }

    const addIPAddressesFromAutoBlock = async (values) => {

        try {
            setProcessing(true);
            let response = await api.moveIPToList(values);

            
            return new Promise(async (resolve, reject) => {
                    if (response.error) {
                        let message = 'Error Adding IP Address';
                        if (response.error === ' invalid IP address') {
                            message = 'Invalid IP Address';
                        }
                        setMessage(message);
                        setOpenErrorSnackbar(true);
                        setAlertBarStatus('error');
                        setProcessing(false);
        
                        reject({error:'Failed to add ip address'})
                    } else if (response) {
                        let ip_response = await processIPResponse(response);
                        dispatchIPGroups({ type: 'SET_IP_GROUPS', payload: ip_response })
                        setProcessing(false);
                        setMessage(`IP Address ${values.list === "ignore" ? `ignored` : `blocked` }`)
                        setOpenErrorSnackbar(true);
                        setAlertBarStatus('success');
                        getAutoblockData();
                        resolve(values)
                    }
            });

        } catch (error) {
            setProcessing(false);
            return true;
        }
    }

    const updateIPAddress = async (values) => {
        try {
            setProcessing(true);
            let response = await api.updateIPToList(values);
            if (response) {
                response = await processIPResponse(response);
                dispatchIPGroups({ type: 'SET_IP_GROUPS', payload: response })
                setProcessing(false);
                setMessage('IP Address Updated')
                setOpenErrorSnackbar(true);
                setAlertBarStatus('success')
            }
        } catch (error) {
            setProcessing(false);
            setMessage('Error Updating IP Address');
            setOpenErrorSnackbar(true);
            setAlertBarStatus('error')
        }
    }


    const deleteIPAddress = async (id) => {

        try {
            setProcessing(true);
            let response = await api.removeIPFromList(id);
            if (!response.error) {
                response = await processIPResponse(response);
                dispatchIPGroups({ type: 'SET_IP_GROUPS', payload: response });
                setMessage('IP Address Deleted')
                setOpenErrorSnackbar(true);
                setAlertBarStatus('success');

                getDomainIPRecommendList();


            } else {
                setProcessing(false);
                setMessage('Error deleting IP Address')
                setOpenErrorSnackbar(true);
                setAlertBarStatus('error')
            }
            setProcessing(false);
        } catch (error) {
            setProcessing(false);
            setMessage('Error deleting IP Address')
            setOpenErrorSnackbar(true);
            setAlertBarStatus('error')
        }
    }

    const handleRecommendedAction = async (values) => {

        try {
            setProcessing(true);
            let response = await api.moveIPToList(values);
            if (response) {
                response = await processIPResponse(response);

                getDomainIPRecommendList();

                dispatchIPGroups({ type: 'SET_IP_GROUPS', payload: response })
                setProcessing(false);
                setMessage('IP Address Updated')
                setOpenErrorSnackbar(true);
                setAlertBarStatus('success');

                return true;
            } else {
                return true;
            }

        } catch (error) {
            setProcessing(false);
            setMessage('Error Updating IP Address');
            setOpenErrorSnackbar(true);
            setAlertBarStatus('error');
            return true;
        }



    }

    const formik = useFormik({
        initialValues: {
            maxSessionsPerIP: maxSessionsPerIPValue,
            maxScore: maxScore,
            useMaxScore: useMaxScore,
            blockThreats: blockThreats,
        },
        enableReinitialize: true,
        onSubmit: async (values) => {

            setAutoblockProcessing(true);
            let formattedData = {
                id: domainID,
                maxSessionsPerIP: parseInt(values.maxSessionsPerIP, 10),
                maxScore: parseInt(values.maxScore, 10),
                blockThreats: values.blockThreats.length ? values.blockThreats : [],
            }
            let result = await api.putDomain(formattedData, 'put');

            if (!result.error) {
                setAutoblockProcessing(false);
                dispatch({ type: 'UPDATE_DOMAINS', payload: result });
                setMessage('IP settings updated');
                setAlertBarStatus('success');
                setOpenErrorSnackbar(true);

            } else {
                setAutoblockProcessing(false);
                setMessage('Error updating IP settings');
                setAlertBarStatus('error');
                setOpenErrorSnackbar(true);
            }

        },
    });

    const handleOpen = () => {
        setOpen(!open)
    }

    const handleAnomalyHelpClose = () => {
        setOpenAnomalyHelp(false)
    }
    const handleAnomalyHelpOpen = () => {
        setOpenAnomalyHelp(true)
    }


    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const StyledBadge = withStyles((theme) => ({
        badge: {
            top: 0,
            right: -12,
        },
    }))(Badge);
    const StyledTab = withStyles((theme) => ({
        root: {
            padding: '6px 30px'
        },
    }))(Tab);


    return (

        <Grid
            component={Card}
            elevation={open ? 0 : 0}

            className={classes.autoblockPanel}
            style={{
                marginBottom: open ? '24px' : ' 10px'
            }}


        >

            <Grid>
                <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                    <StyledTab label="Manage IPs" {...a11yProps(0)} />
                    <StyledTab disabled={recommendations.length < 1} label={<StyledBadge overlap="rectangular" badgeContent={recommendations && recommendations.length} invisible={recommendations && recommendations.length < 1} color="primary"><Typography>Recommendations</Typography></StyledBadge>} {...a11yProps(1)} />
                    {state.hasAnomalyDetection ?
                        <StyledTab disabled={numberOfAutoBlockResults < 1}
                            // label="AutoBlock Log"
                            label={<StyledBadge max={999} overlap="rectangular" badgeContent={blockCount} invisible={blockCount < 1} color="primary"><Typography>AutoBlock Log</Typography></StyledBadge>}
                            {...a11yProps(2)}
                        />
                        : null
                    }
                </Tabs>

                <>

                    <TabPanel value={value} index={0}>
                        <>
                            <Grid className={classes.autoblockHeader} style={{ backgroundColor: open ? theme.palette.primary.main : theme.palette.primary.light }}>
                                <IconButton style={{ margin: '0 10px' }} aria-label="Open help panel" onClick={() => handleAnomalyHelpOpen()} size="small" color="inherit">
                                    <HelpCircleIcon />
                                </IconButton>
                                <Typography variant="body2" style={{ display: 'inline-block' }}>Autoblock IPs</Typography>
                                <IconButton style={{ margin: '0 10px 0 auto' }} small="small" aria-label="Minimize and maximise Autoblock panel" color="inherit" onClick={handleOpen}>
                                    {(open) ? <ArrowUpCircleIcon /> : <ArrowDownCircleIcon />}
                                </IconButton>
                            </Grid>
                            <SavingBar saving={autoblockProcessing} style={{ position: 'relative', height: '10px' }} />
                            <Collapse in={open} timeout={0} >
                                <Grid
                                    container
                                    spacing={4}
                                    alignItems="flex-start"
                                    item
                                    style={{ padding: '24px' }}
                                >
                                    <AutoblockAttackerControl formik={formik} isMobile={isMobile} values={formik.values.blockThreats} setProcessing={setAutoblockProcessing} hasAnomalyDetection={state.hasAnomalyDetection} autoblockProcessing={autoblockProcessing} />
                                    {domain ?
                                        <MaxIPAddressesControl formik={formik} isMobile={isMobile} setProcessing={setAutoblockProcessing} initialValue={domain.maxSessionsPerIP} allowFirewall={state.plan.allowFirewall} autoblockProcessing={autoblockProcessing} />
                                        :
                                        null}
                                    <AutoblockAnomalyControl formik={formik} isMobile={isMobile} setProcessing={setAutoblockProcessing} hasAnomalyDetection={state.hasAnomalyDetection} autoblockProcessing={autoblockProcessing} />
                                </Grid>
                            </Collapse>
                            <Grid container spacing={0}>
                                <Grid container item>
                                    <SavingBar saving={processing} />
                                    {ipGroups ?
                                        <>
                                            <Grid container direction="column">
                                                <Grid item xs className={classes.tableGroup}>
                                                    <DomainIPAddressesTable
                                                        name='Pass'
                                                        updateIPAddress={updateIPAddress}
                                                        addIPAddresses={addIPAddresses}
                                                        deleteIPAddress={deleteIPAddress}
                                                        addresses={ipGroups}
                                                        domainID={domainID}
                                                        processing={processing}
                                                        state={state}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </>
                                        :
                                        null
                                    }
                                </Grid>
                            </Grid>
                        </>



                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <IPRecommendations state={state} recommendations={recommendations} handleRecommendedAction={handleRecommendedAction} />
                    </TabPanel>
                    {state.hasAnomalyDetection ?
                        <TabPanel value={value} index={2}>
                            <AutoBlockLog
                                blocks={blockData.current}
                                filteredBlocks={filteredBlocks}
                                loading={loading}
                                order={order}
                                orderBy={orderBy}
                                handleRequestSort={handleRequestSort}
                                page={page}
                                rowsPerPage={rowsPerPage}
                                handleChangePage={handleChangePage}
                                handleChangeRowsPerPage={handleChangeRowsPerPage}
                                count={count}
                                setSearchValue={setSearchValue}
                                searchValue={searchValue}
                                range={range}
                                addIPAddresses={addIPAddressesFromAutoBlock}
                                processing={processing}
                            />
                        </TabPanel>
                        : null
                    }
                </>


            </Grid>
           
            <ErrorSnackBar message={message} open={openErrorSnackbar} status={alertBarStatus} closeErrorSnackbar={closeErrorSnackbar} />

            <Dialog open={openAnomalyHelp} onClose={handleAnomalyHelpClose}>
                <DialogContent>

                    <Grid container spacing={2}>
                        <Grid item>
                            <Typography variant="h6" style={{ marginBottom: '6px' }}>
                                Known threats
                            </Typography>
                            <Typography paragraph={true}>
                                IPs with the selected threat indicators will be automatically blocked
                            </Typography>
                        </Grid>

                        <Grid item>
                            <Typography variant="h6" style={{ marginBottom: '6px' }}>
                                Autoblock IPs with more then <em>n</em> sessions
                            </Typography>
                            <Typography paragraph={true}>
                                IPs with more than the selected number of sessions will be automatically blocked.
                            </Typography>
                        </Grid>

                        <Grid item>
                            <Typography variant="h6" style={{ marginBottom: '6px' }}>
                                Autoblock IPs with anomaly score above <em>n</em>
                            </Typography>
                            <Typography paragraph={true}>
                                IPs with an anomaly score greater than the selected number will be automatically blocked.
                            </Typography>
                        </Grid>
                    </Grid>


                </DialogContent>
            </Dialog>

        </Grid>
    );
};

export default withWidth()(withRouter(DomainsIPAddresses));


