import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import ErrorSnackBar from '../../components/ErrorSnackBar';
import {
  Grid,
  Typography,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  FormControl,
  Button,
  TextField
} from '@material-ui/core';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import grey from '@material-ui/core/colors/grey';
import {EmailMeRow, SavingBar} from '../../components'
import UpgradeCTAPanel from '../../components/UpgradeCTAPanel'
import theme from "theme";



const headerBGColor = grey[100];
const headerColor = grey[700];

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  container: {},
  titleRow: {
    '& td' : {
      paddingTop: '22px',
      paddingBottom: theme.spacing(2),
      borderBottom: 'none',
      borderTop: `2px solid ${grey[200]}`,
      backgroundColor: headerBGColor,
      color: headerColor,
      fontWeight: 'bold',
      fontSize: '12px'
    }
  },
  tableRowDomainName : {
    width: '75%',
    '& span' : {
      wordBreak: 'break-all',
      lineHeight:1,
      display:'inline-block'
    }
  },
  tableRowSwitch : {
    '& span' : {
      // wordBreak: 'break-all',
      lineHeight:1,
      display:'inline-block'
    }
  }
}));

const EmailMe = ({ api, state, dispatch, ...rest }) => {

  const classes = useStyles();

  useEffect(() => {
    const getData =  async () => {
      let rooms = await api.getRooms();
      dispatch({ type: 'UPDATE_ROOMS', payload: rooms });
    }

    if(!state.rooms) {
      getData();
    }
 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.rooms]);

  const [upgradeMessage, setUpgradeMessage] = useState('The Email Me feature is not available on your current plan. To enable Email Me please upgrade to a supporting plan.');
  const [displayUpgradeButton, setDisplayUpgradeButton] = useState(true);

  let [groups, setGroups] = useState([]);
  let [message, setMessage] = useState('');
  let [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  
  const closeErrorSnackbar = () => {
    setOpenErrorSnackbar(false)
  }

  useEffect(() => {
    const formatGroups = () => {

      let roomsByDomain = groupBy(orderBy(state.rooms, 'title'), 'domainID');
      let groups = orderBy(state.domains, 'name').map((domain) => {
        let rooms = roomsByDomain[domain.id];
        domain.rooms = rooms;
        return domain;
      });

      setGroups(groups);
      
    }
    
    if (state.rooms && state.domains) {
      formatGroups()
    }

    // return () => {
    //   cleanup
    // };
  }, [state.rooms, state.domains]);


  const EmailMeCard = ({domain}) => {

    let [saving, setSaving] = useState(false);


    const saveRoom = async (payload) => {

      let result = await api.addRoom(payload, 'put');
      setSaving(false);
      if (result) {
        try {
          let rooms = await api.getRooms();
          dispatch({'type':'UPDATE_ROOMS', payload: rooms});
        } catch (error) {
          console.warn('Error fetching new rooms', error);
        }
      }

    }

    

    let block = <Grid item xs={12} key={domain.id}>
        <Card style={{height: '100%'}}>
        <CardContent style={{position:'relative'}}>
            <SavingBar saving={saving} />
            <TableContainer className={classes.container}>
            <Table>
            <TableBody>
              <TableRow className={classes.titleRow} key={domain.id}>
                <TableCell className={classes.tableRowDomainName}><span>{domain.name}</span></TableCell>
                <TableCell className={classes.tableRowSwitch}></TableCell>
                {/* <TableCell>Pause Sessions Above</TableCell>
                <TableCell>Delete Sessions Above</TableCell> */}
                {/* <TableCell></TableCell> */}
              </TableRow>
              {domain.rooms.map((room) => {                  
                  return <EmailMeRow saving={saving} key={room.id} saveRoom={saveRoom} room={room} state={state} dispatch={dispatch} setSaving={setSaving} api={api}/>
              })}
              </TableBody>
            </Table>
            </TableContainer>
      </CardContent>
    </Card>
    </Grid>;

    return block;


  }

  const generateRows = () => {
    let rows = [];
    groups.forEach(
      (domain) => {
        if (domain.rooms) {

          rows.push(
            <EmailMeCard key={domain.id} domain={domain} />
          )
        }
      }
    )

    return rows;

  }

  


  return (

      <Grid container spacing={4} className={classes.root}>
          { !state.plan.allowEmailNotifications

          
          ?
          <Grid item xs={12}>
            <UpgradeCTAPanel upgradeMessage={upgradeMessage} displayUpgradeButton={displayUpgradeButton} />
          </Grid>
          :
            null
          }


        { state.plan.allowEmailNotifications && state.rooms
        ?

        <Grid container spacing={2}>
          <Grid item sm={12}  md={5}>
            <EmailMessageBlock
            dispatch={dispatch}
            api={api}
            state={state}
            />

          </Grid>
          <Grid item xs sm={12}  md={7}>
          <Grid container spacing={2}>
            {generateRows()}
          </Grid>
          </Grid>
        </Grid>
        :null
        }

      <ErrorSnackBar message={message} open={openErrorSnackbar} closeErrorSnackbar={closeErrorSnackbar} />

    </Grid>
  );
};

export default EmailMe;


const EmailMessageBlock = ({dispatch, api, state}) => {
  let [emailTemplate, setEmailTemplate] = useState(state.accountInformation.get('account').emailTemplate);
  let [strippedMessage, setStrippedMessage] = useState();
  let [previewSubject, setPreviewSubject] = useState('');
  let [saving, setSaving] = useState(false);
  const defaultMessage = 'Your number is up on $title, You have $timeout minutes to use this link: $domain$urlRedirect?ch-id=$token';


  const previewRoom = (() => {
    let room;
    // length of rooms

    if (!state.rooms || state.rooms.length === 0)
    {
      return false;
    }
    let lor = state.rooms.length;
    if (lor === 1) {
      room = state.rooms[0];
    } else {
      let pos= Math.floor(Math.random() * (state.rooms.length - 0) + 0);
      room = state.rooms[pos];
      
    }

    let domain = state.domains.filter((domain) => {
      return domain.id === room.domainID;
    })
    room.timeout = domain[0].timeout;
    room.domain = domain[0].name;
    return room;
  })()


  useEffect(() => {
    setEmailTemplate((prev) => {

      if(!prev) prev = '';

      let content = prev.replace(/<br\/>/gi, '\r\n');
      generatePreviewText(content)
      return content;
    });


  }, []);

  const generatePreviewText = (text) => {

    if (previewRoom) {
      let doc = new DOMParser().parseFromString(text, 'text/html');
      let content = doc.body.textContent || "";
      content = content.replace(/\$title/gm, previewRoom.title);
      content = content.replace(/\$timeout/gm, previewRoom.timeout);
      content = content.replace(/\$urlRedirect/gm, previewRoom.urlRedirect === '/' ? '' : previewRoom.urlRedirect);
      content = content.replace(/(\r\n|\r|\n)/gm, '<br/>');
      const regex_token = /\$token/gm;
      content = content.replace(regex_token, `tok0N7TFI7TH3Nmp`);
      // const subst = `$link`;
      // content = content.replace(regex, subst);
      content = content.replace(/\$domain/gm,  previewRoom.domain);
        setStrippedMessage(content);
      setPreviewSubject(previewRoom.title)
    }
  }

  const generateMessageText = (text) => {
    let doc = new DOMParser().parseFromString(emailTemplate, 'text/html');
    let content = doc.body.textContent || "";
    // content = content.replace(/\$link/gm, '$name$urlRedirect?ch-id=$token');
    content = content.replace(/(\r\n|\r|\n)/gm, '<br/>');
    return content; 
  }

  return (
    <>
        <Grid item xs={12} style={{marginBottom: 24}}>
        <Card>
        <CardContent>
          <FormControl fullWidth margin="dense" variant="outlined">
              <Typography><strong>Email Message</strong></Typography>
                <TextField
                    id="outlined-multiline-static"
                    multiline
                    minRows={10}
                    variant="outlined"
                    value={(emailTemplate) ? emailTemplate : ''}
                    onChange={(e) => {
                      setEmailTemplate(e.target.value)
                    }}
                    onFocus={(e) => {
                      setEmailTemplate(e.target.value)
                    }}
                    onKeyUp={(e) => {
                      generatePreviewText(e.target.value)
                    }}
                />
            </FormControl>

            <div style={{position:'relative'}}>
            <SavingBar saving={saving}/>
            <Button
                 style={{
                  marginRight: 0,
                  marginLeft: 'auto',
                  borderBottomRightRadius: 0,
                  borderBottomLeftRadius: 0,
                }}
                disabled={saving}
                fullWidth
                  variant="contained" size="small" color="secondary" disableElevation
                  onClick={async () => {
                    setSaving(true);
                    let message = generateMessageText(emailTemplate);
                    let response = await api.updateAccount({
                      emailTemplate: message
                    });
                    if (response) {
                      setSaving(false);
                      dispatch({ type: 'UPDATE_ACCOUNT_INFORMATION', payload: { account: response } });
                    }
                }}>Save message</Button>
            <Button
                 style={{
                  marginRight: 0,
                  marginLeft: 'auto',
                  borderTopRightRadius: 0,
                  borderTopLeftRadius: 0,
                }}
                disabled={saving}
                fullWidth
                  variant="contained" size="small" disableElevation
                  onClick={async () => {
                    setSaving(true);
                    let response = await api.updateAccount({
                      emailTemplate: defaultMessage
                    });
                    if (response) {
                      setEmailTemplate(defaultMessage);
                      generatePreviewText(defaultMessage);
                      dispatch({ type: 'UPDATE_ACCOUNT_INFORMATION', payload: { account: response } });
                      setSaving(false);
                    }
                }}>Set Default</Button>

            </div>
            </CardContent>
            </Card>
            </Grid>

            <Grid item xs={12} style={{marginBottom: 24}}>
                <Card style={{backgroundColor: theme.palette.primary.main, color:'#fff'}}>
                  <CardContent>

                    {/* Your number is up on $title, You have $timeout minutes to use this link: $domain$urlRedirect?ch-id=$token */}
                
                  <Typography><strong>Placeholders</strong></Typography>
                  <Typography><code>$title</code> The waiting room title</Typography>
                  <Typography><code>$timeout</code> Session timeout</Typography>
                  <Typography><code>$domain$urlRedirect?ch-id=$token</code>  Waiting room link</Typography>
                  <Typography><code>$domain</code> Domain name</Typography>
                  {/* <Typography>$link: The link for the user to follow</Typography> */}
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12} style={{marginBottom: 24}}>
                <Card>
                  <CardContent>
                      <Typography paragraph><strong>Preview</strong></Typography>
                      <Typography>From: noreply@crowdhandler.com</Typography>
                      <Typography paragraph>Subject: {previewSubject}</Typography>
                      <Typography dangerouslySetInnerHTML={{__html:strippedMessage}}></Typography>
                  </CardContent>
                </Card>

                {/* {saving?:null} */}
        </Grid>

          
       
        </>
  )
}



