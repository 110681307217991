import React, { useState, useEffect } from "react";
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import { XIcon } from '../../icons';
import { makeStyles } from '@material-ui/core/styles';
import { DateTime } from 'luxon';

import AutoBlockTable from "./AutoBlockTable";
import AutoBlockDonut from "./AutoBlockDonut";
import {
    Grid,
    IconButton,
    Typography,
    Paper,
    InputBase
} from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    root: {
      padding: '2px 14px',
      display: 'flex',
      alignItems: 'center',
      boxShadow: 'none',
      border: '1px solid #cacaca'
    },
    input: {
      flex: 1,
    },
    iconButton: {
      padding: 10,
    },
    divider: {
      height: 28,
      margin: 4,
    },
  }));




const AutoBlockLog = ({
    blocks,
    setSearchValue,
    searchValue,
    filteredBlocks,
    loading,
    order,
    orderBy,
    handleRequestSort,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    count,
    addIPAddresses,
    processing,
    range
}) => {


        const classes = useStyles();
    // get the blocks for donut
    //formatBlocksDonutData
    const [blockDonutData, setBlockDonutData] = useState({
        labels: [],
        data: [],
        title: 'BLOCKED',
        nodata: false
    })
    useEffect(() => {

        const formatBlocksDonutData = () => {

            let only_blocks = blocks.reduce((acc, block) => {
                if(block.action === "block") {
                    acc.push(block);
                }
                return acc;
            },[]);

            // split the reasons so that the threats are not consolidated
            only_blocks = only_blocks.reduce((acc, block) => {

                let reason = block.reasons.split(',');

                for (let i = 0; i < reason.length; i++) {
                    block.reasons = reason[i].trim();
                    acc.push(block);
                }

                return acc;

            },[])

            let blocks_reasons = only_blocks.map((block) => {
                if(block.reasons.indexOf('anomaly score') !== -1) {
                    block.reasons = 'anomaly score'
                }
                if(block.reasons.indexOf('sessions') !== -1) {
                    block.reasons = 'session'
                }
                return block;
            })

            let grouped_blocks = groupBy(blocks_reasons, 'reasons');
            let temp_collection = []
            for (const key in grouped_blocks) {
                if (Object.hasOwnProperty.call(grouped_blocks, key)) {
                    const reasons = grouped_blocks[key];
                    temp_collection.push({
                        key: key,
                        value: reasons.length
                    })
                    
                }
            }
            temp_collection = sortBy(temp_collection, 'value').reverse();
            let labels = [];
            let data = [];

            for (let i = 0; i < temp_collection.length; i++) {
                const element = temp_collection[i];
                    labels.push(element.key);
                    data.push(element.value);
            }

            const total = data.reduce((acc, value) => {
                return acc + value;
            }, 0)

            setBlockDonutData({
                data,
                labels,
                title: `BLOCKED (${total})`
            })

        }
        if(blocks && blocks.length) {
            formatBlocksDonutData();
        }
    }, [blocks]);

    //formatWarnDonutData
    const [warnDonutData, setWarnDonutData] = useState({
        labels: [],
        data: [],
    })
    useEffect(() => {

        const formatWarnDonutData = () => {

            let only_blocks = blocks.reduce((acc, block) => {
                if(block.action === "warn") {
                    acc.push(block);
                }
                return acc;
            },[]);

            only_blocks = only_blocks.reduce((acc, block) => {

                let reason = block.reasons.split(',');

                for (let i = 0; i < reason.length; i++) {
                    block.reasons = reason[i].trim();
                    acc.push(block);
                }

                return acc;

            },[])

            let blocks_reasons = only_blocks.map((block) => {
                if(block.reasons.indexOf('anomaly score') !== -1) {
                    block.reasons = 'anomaly score'
                }
                if(block.reasons.indexOf('sessions') !== -1) {
                    block.reasons = 'session'
                }
                return block;
            })

            let grouped_blocks = groupBy(blocks_reasons, 'reasons');
            let temp_collection = []
            for (const key in grouped_blocks) {
                if (Object.hasOwnProperty.call(grouped_blocks, key)) {
                    const reasons = grouped_blocks[key];
                    temp_collection.push({
                        key: key,
                        value: reasons.length
                    })
                    
                }
            }
            temp_collection = sortBy(temp_collection, 'value').reverse();
            let labels = [];
            let data = [];

            for (let i = 0; i < temp_collection.length; i++) {
                const element = temp_collection[i];
                    labels.push(element.key);
                    data.push(element.value);
            }

            const total = data.reduce((acc, value) => {
                return acc + value;
            }, 0)

            setWarnDonutData({
                labels,
                data,
                title: `WARNINGS (${total})`
            })
        }
        if(blocks && blocks.length) {
            formatWarnDonutData();
        }
    }, [blocks]);

    // formatFalseDonutData
    const [falseDonutData, setFalseDonutData] = useState({
        labels: [],
        data: [],
    })
    useEffect(() => {

        const formatFalseDonutData = () => {

            let only_blocks = blocks.reduce((acc, block) => {

                if(block.falsePositive === 1) {
                    acc.push(block);
                }
                return acc;
            },[]);

            only_blocks = only_blocks.reduce((acc, block) => {

                let reason = block.reasons.split(',');

                for (let i = 0; i < reason.length; i++) {
                    block.reasons = reason[i].trim();
                    acc.push(block);
                }

                return acc;

            },[])

            let blocks_reasons = only_blocks.map((block) => {
                if(block.reasons.indexOf('anomaly score') !== -1) {
                    block.reasons = 'anomaly score'
                }
                if(block.reasons.indexOf('sessions') !== -1) {
                    block.reasons = 'session'
                }
                return block;
            });

            let grouped_blocks = groupBy(blocks_reasons, 'reasons');
            let temp_collection = []
            for (const key in grouped_blocks) {
                if (Object.hasOwnProperty.call(grouped_blocks, key)) {
                    const reasons = grouped_blocks[key];
                    temp_collection.push({
                        key: key,
                        value: reasons.length
                    })
                    
                }
            }
            temp_collection = sortBy(temp_collection, 'value').reverse();
            let labels = [];
            let data = [];

            for (let i = 0; i < temp_collection.length; i++) {
                const element = temp_collection[i];
                    labels.push(element.key);
                    data.push(element.value);
            }

            const total = data.reduce((acc, value) => {
                return acc + value;
            }, 0)

            setFalseDonutData({
                labels,
                data,
                title: `FALSE POSITIVES (${total})`
            })
        }
        if(blocks && blocks.length) {
            formatFalseDonutData();
        }
    }, [blocks]);


    const handleFalsePositive = async (block, falsePositive) => {
        
        let data = {
            address: block.address,
            note: `${(falsePositive===1) ? `Blocked`: `Ignored`} from autoblock log`,
            list: (falsePositive===1) ? 'block' : 'ignore',
            domainID: block.domain_id
        }
        let result = await addIPAddresses(data);
        

        return result;
    }

    const getDataRange = () => {
        
        let from, to;
        if (range.length && range[1]) {
            from = DateTime.fromISO(range[1], { zone: "UTC" }).toLocaleString(DateTime.DATE_MED);
        } else {
            from  = DateTime.fromISO(range[0], { zone: "UTC" }).toLocaleString(DateTime.DATE_MED)
        }

        if (range.length && range[0]) {
            to = ` - ${DateTime.fromISO(range[0], { zone: "UTC" }).toLocaleString(DateTime.DATE_MED)}`;
        }

        return <Typography><strong>{from}{to}</strong></Typography>;

    }



    return (
        <Grid container spacing={0} direction="column">
            <Grid item xs={12}>
                <Grid container spacing={2} direction="row" alignItems="center" style={{ marginTop: '24px' }}>
                    <Grid item xs={12} md={4} style={{ textAlign: 'center' }}>
                        <AutoBlockDonut data={blockDonutData} title={blockDonutData.title} />
                    </Grid>
                    <Grid item xs={12} md={4} style={{ textAlign: 'center' }}>
                        <AutoBlockDonut data={warnDonutData} title={warnDonutData.title} />
                    </Grid>
                    <Grid item xs={12} md={4} style={{ textAlign: 'center' }}>
                        <AutoBlockDonut data={falseDonutData} title={falseDonutData.title} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} style={{
                marginTop: '20px'
            }}>
                <Grid container spacing={2} justifyContent="space-between" alignItems="center">
                    <Grid item xs>
                        <Paper className={classes.root}>
                            <InputBase
                                className={classes.input}
                                value={searchValue.value}
                                placeholder="Search IP"
                                inputProps={{ 'aria-label': 'Search Autoblock Data' }}
                                onChange={(event) => {
                                    setSearchValue({
                                        value: event.target.value
                                    })
                                }}
                            />
                            
                            {searchValue.value ?
                            <IconButton type="button" className={classes.iconButton} aria-label="search"
                            onClick={(event) => {
                                setSearchValue({value:''});
                            }}>
                                <XIcon />
                            </IconButton>
                            : null} 
                            
                        </Paper>

                    </Grid>
                    <Grid item style={{
                        paddingLeft: '13px',
                        marginLeft: '13px',
                        borderLeft: '1px solid #cacaca'
                    }}
                    >
                        {range.length ?
                        <Grid container justifyContent="center">
                            <div>{getDataRange()}</div>
                        </Grid>
                        : null}

                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <AutoBlockTable
                    count={count}
                    filteredBlocks={filteredBlocks}
                    loading={loading}
                    handleFalsePositive={handleFalsePositive}
                    order={order}
                    orderBy={orderBy}
                    handleRequestSort={handleRequestSort}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    processing={processing}
                />
            </Grid>

        </Grid>
    )
}

export default AutoBlockLog