import React, {useEffect, useRef} from "react";
import RoomSessions from "./RoomSessions";
import { withRouter } from 'react-router-dom'; // To use the history object


const RoomSessionLayout = ({state, api, dispatch}) => {

    let mounted = useRef(true);
    useEffect(() => {

    const getDomains = async () => {
      let domains = await api.getDomains();

      if(mounted.current) {
        dispatch({ type: 'ADD_DOMAINS', payload: domains });
      }
    }
    if (state.domains === undefined) {
      getDomains();
    }

    return () => {
      mounted.current = false;
    }
  }, [api, state.domains, dispatch]);
  
  useEffect(() => {
    
    const getRooms = async () => {
      let rooms = await api.getRooms();
      if(mounted.current) {
        dispatch({ type: 'UPDATE_ROOMS', payload: rooms });
      }
    }
    if (state.rooms === undefined) {
      getRooms();
    }
    return () => {
      mounted.current = false;
    }
      
  }, [api, state.rooms, dispatch]);



    return (
        <>
        { state.rooms ?
            <RoomSessions state={state} api={api} dispatch={dispatch} />
            :
            null
        }
        </>
    )
}

export default withRouter(RoomSessionLayout);
