import React, {useState, useEffect, useCallback} from 'react';
import { DateTime } from 'luxon';
import { Grid } from '@material-ui/core';
import RoomDateTimePicker from './RoomDateTimePicker';
import RoomCountdownSelect from './RoomCountdownSelect';
import Config from '../../config';


const dateTimeValues = {
  0 : {minutes: 0},
  1 : {minutes: 15},
  2 : {minutes: 30},
  3 : {minutes: 45},
  4 : {minutes: 60},
  5 : {hours: 2},
  6 : {hours: 4},
  7 : {hours: 8},
  8 : {hours: 12},
  9 : {hours: 24}
}

const defaultOptions = [
  {label: 'None', value: {minutes: 0}, threshold: 0 },
  {label: '15 minutes before', value: {minutes: 15}, threshold: 15 },
  {label: '30 minutes before', value: {minutes: 30}, threshold: 30 },
  {label: '45 minutes before', value: {minutes: 45}, threshold: 45 },
  {label: '60 minutes before', value: {minutes: 60}, threshold: 60 },
  {label: '2 hours before', value: {hours: 2}, threshold: 120 },
  {label: '4 hours before', value: {hours: 4}, threshold: 240 },
  {label: '8 hours before', value: {hours: 8}, threshold: 480 },
  {label: '12 Hours before', value: {hours: 12}, threshold: 720 },
  {label: '24 Hours before', value: {hours: 24}, threshold: 1440 },
]

const overrideOptions = [
  {label: 'None', value: {minutes: 0}, threshold: 0 },
  {label: '3 minutes before', value: {minutes: 3}, threshold: 3 },
  {label: '4 minutes before', value: {minutes: 4}, threshold: 4 },
  {label: '5 minutes before', value: {minutes: 5}, threshold: 5 },
  {label: '6 minutes before', value: {minutes: 6}, threshold: 6 },
  {label: '7 minutes before', value: {minutes: 7}, threshold: 7 },
  {label: '8 minutes before', value: {minutes: 8}, threshold: 8 },
  {label: '9 minutes before', value: {minutes: 9}, threshold: 9 },
  {label: '10 minutes before', value: {minutes: 10}, threshold: 10 },
  {label: '11 minutes before', value: {minutes: 11}, threshold: 11 },
  {label: '12 minutes before', value: {minutes: 12}, threshold: 12 },
  {label: '13 minutes before', value: {minutes: 13}, threshold: 13 },
  {label: '14 minutes before', value: {minutes: 14}, threshold: 14 },
  {label: '15 minutes before', value: {minutes: 15}, threshold: 15 },
  {label: '30 minutes before', value: {minutes: 30}, threshold: 30 },
  {label: '45 minutes before', value: {minutes: 45}, threshold: 45 },
  {label: '60 minutes before', value: {minutes: 60}, threshold: 60 },
  {label: '2 hours before', value: {hours: 2}, threshold: 120 },
  {label: '4 hours before', value: {hours: 4}, threshold: 240 },
  {label: '8 hours before', value: {hours: 8}, threshold: 480 },
  {label: '12 Hours before', value: {hours: 12}, threshold: 720 },
  {label: '24 Hours before', value: {hours: 24}, threshold: 1440 },
]

const dateTimeOverrideValues = {
  0 : {minutes: 0},
  1 : {minutes: 3},
  2 : {minutes: 4},
  3 : {minutes: 5},
  4 : {minutes: 6},
  5 : {minutes: 7},
  6 : {minutes: 8},
  7 : {minutes: 9},
  8 : {minutes: 10},
  9 : {minutes: 11},
  10 : {minutes: 12},
  11 : {minutes: 13},
  12 : {minutes: 14},
  13 : {minutes: 15},
  14 : {minutes: 30},
  15 : {minutes: 45},
  16 : {minutes: 60},
  17 : {hours: 2},
  18 : {hours: 4},
  19 : {hours: 8},
  20 : {hours: 12},
  21 : {hours: 24},
}

const get_date_override_values = (room_data, initial) => {

  const now = DateTime.local();
  const date1 = DateTime.fromISO(room_data.queueActivatesOn);
  const date2 = DateTime.fromISO(room_data.countdownStartsOn);
  const diff = date1.diff(date2, ["minutes"]);
  let minutes = diff.values.minutes;
  const in_the_past = date1.diff(now, ["minutes"]);
  const past_minutes = initial ? 0 : in_the_past.values.minutes;

  let val;

  if (minutes < 3) {
    val = 0;
  } else if (minutes < 4) {
    val = 1;
  } else if (minutes < 5) {
    val = 2;
  } else if (minutes < 6) {
    val = 3;
  } else if (minutes < 7) {
    val = 4;
  } else if (minutes < 8) {
    val = 5;
  } else if (minutes < 9) {
    val = 6;
  } else if (minutes < 10) {
    val = 7;
  } else if (minutes < 11) {
    val = 8;
  } else if (minutes < 12) {
    val = 9;
  } else if (minutes < 13) {
    val = 10;
  } else if (minutes < 14) {
    val = 11;
  } else if (minutes < 15) {
    val = 12;
  } else if (minutes < 30) {
    val = 13;
  } else if (minutes < 45) {
    val = 14;
  } else if (minutes < 60) {
    val = 15;
  } else if (minutes < 120) {
    val = 16;
  } else if (minutes < 240) {
    val = 17;
  } else if (minutes < 480) {
    val = 18;
  } else if (minutes < 720) {
    val = 19;
  } else if (minutes < 1440) {
    val = 20;
  } else {
    val = 21;
  }

  let result = {
    val,
    minutes,
    past_minutes
  }; 
  return result 
}


const get_date_values = (room_data, initial) => {

  const now = DateTime.local();
  const date1 = DateTime.fromISO(room_data.queueActivatesOn);
  const date2 = DateTime.fromISO(room_data.countdownStartsOn);
  const diff = date1.diff(date2, ["minutes"]);
  let minutes = diff.values.minutes;
  const in_the_past = date1.diff(now, ["minutes"]);
  const past_minutes = initial ? 0 : in_the_past.values.minutes;

  let val;
  if (minutes < 15) {
    val = 0
  } else if (minutes < 30) {
    val = 1
  } else if (minutes < 45) {
    val = 2
  } else if (minutes < 60) {
    val = 3
  } else if (minutes < 120) {
    val = 4
  } else if (minutes < 240) {
    val = 5
  } else if (minutes < 480) {
    val = 6
  } else if (minutes < 720) {
    val = 7
  } else if (minutes < 1440) {
    val = 8
  } else {
    val = 9
  }

  let result = {
    val,
    minutes,
    past_minutes
  }; 
  return result 
}

const RoomDateSelectors = ({roomData, setDateInfo, type, dateInfo, patternType, roomActiveState, calulateActiveState, customerID }) => {


    //rah override
  const overrideUser = "027578b80439f27bdb457fa0899e8a8fc329fec20f17da2f9e1f8cc7b116334a"
  // const overrideUser = "94ea09f553bf57af84c21427c68b3c89fc64e2044c347522ba4ca01363ffaf8b"

  const useDateOverride = customerID && customerID === overrideUser;

  const [countdownDateValue, setCountdownDateValue] = useState(() => {
    if (type === 'add') {
      return (useDateOverride) ? dateTimeOverrideValues[1] : dateTimeValues[1]
    } else {
      return (useDateOverride) ? dateTimeOverrideValues[get_date_override_values(roomData, true ).val] : dateTimeValues[get_date_values(roomData, true).val];
    }
  });

  const [countdownValue, setCountdownValue] = useState(
    () => {
      if (type === 'add') {
        return 1;
      } else {
        return (useDateOverride) ? get_date_override_values(roomData, true ).val : get_date_values(roomData, true).val;
      }
    }
  );
  const [activateIsInThePast, setActivateIsInThePast] = useState(false);
  const [minutesDiff, setMinutesDiff] = useState(0);
  const options = useDateOverride ? overrideOptions : defaultOptions;
  const [initialState, setInitialState] = useState(true);


  useEffect(() => {

    if(roomData) {
      if(useDateOverride) {
        roomData.initialCountdown = get_date_override_values(roomData);
      } else {
        roomData.initialCountdown = get_date_values(roomData);
      }
    }
    
  }, [roomData, useDateOverride]);

  const handleCountdownChange = (value) => {
    setCountdownValue(value);
  }

  useEffect(() => {
    if(countdownValue !== undefined){
      setCountdownDateValue(options[countdownValue].value)
    }
  }, [countdownValue, options]);

  const [fields, setFields] = useState({
    countdownStartsOn: {
      date: (dateInfo.countdownStartsOn.date) ? dateInfo.countdownStartsOn.date : DateTime.utc().startOf('minute')
    },
    queueActivatesOn: {
      date: (dateInfo.queueActivatesOn.date) ? dateInfo.queueActivatesOn.date : DateTime.utc().startOf('minute'),
    }
  });

  useEffect(() => {
      const now = DateTime.local();
      const date1 = DateTime.fromISO(fields.queueActivatesOn.date);
      const in_the_past = date1.diff(now, ["minutes"]);
      const val = Math.ceil(in_the_past.values.minutes);
      

      if(fields.queueActivatesOn.date && type === 'add'){
      
        if (val <= 0) {
          setActivateIsInThePast(true);
          setCountdownValue(0);
          setCountdownDateValue({minutes: 0});
          setInitialState(true)
        } else if (countdownValue === 0 && initialState) {
            setCountdownValue(1);
            setCountdownDateValue((useDateOverride) ? dateTimeOverrideValues[13] : dateTimeValues[1]);
            setActivateIsInThePast(false);
            setInitialState(false)
          } else {
            setActivateIsInThePast(false);
            setInitialState(false)
        }
        
      }

      if(fields.queueActivatesOn.date && type === 'edit'){
        if (val <= 0) {
          setActivateIsInThePast(true);
          setCountdownValue(0);
          setCountdownDateValue({minutes: 0});
        } else if (countdownValue === 0 && initialState) {
          setCountdownValue(roomData.initialCountdown.val);
          setCountdownDateValue((useDateOverride) ? dateTimeOverrideValues[roomData.initialCountdown.val] : dateTimeValues[roomData.initialCountdown.val]);
          setActivateIsInThePast(false);
          setInitialState(false)
        } else {
          setActivateIsInThePast(false);
          setInitialState(false)
      }

        
      }
      
  }, [type, fields.queueActivatesOn.date, countdownValue, roomData, useDateOverride, initialState]);


  useEffect(() => {
    setDateInfo(fields);

    

    let room_data = {
      ...roomData,
      countdownStartsOn: fields.countdownStartsOn.date.toUTC().toISO(),
      queueActivatesOn: fields.queueActivatesOn.date.toUTC().toISO(),
    }
    calulateActiveState(room_data);

    
  }, [calulateActiveState, fields, roomData, setDateInfo]);

  useEffect(() => {
    let countdownTime = dateInfo.queueActivatesOn.date.startOf('minute').minus(countdownDateValue);
        setFields((f) => {
        return {
          ...f,
          'countdownStartsOn' : { date: countdownTime },
        }
      });
  }, [countdownDateValue, dateInfo.queueActivatesOn.date]);


  const onDateChange = useCallback((date, field_name) => {
    if(field_name === 'queueActivatesOn') {
      let countdownTime = date.startOf('minute').minus(countdownDateValue);
        setFields((f) => {
        return {
          'queueActivatesOn' : { date },
          'countdownStartsOn' : { date: countdownTime },
        }
      });
    }
    
    if(field_name === 'countdownStartsOn') {
      setFields((f) => {
        return {
          ...f,
          [field_name] : { date }
        }
      });
    }

  },[countdownDateValue]);

    return (
        <Grid container className='ch-room-form-group'>
          <Grid item md={6}>
            <RoomDateTimePicker
              minDate={dateInfo.countdownStartsOn}
              dateTime={dateInfo.queueActivatesOn}
              dateFormat={Config.DATE_FORMATS.FULL_NO_TIME}
              label="Queue Activates"
              onDateChange={onDateChange}
              fieldName={'queueActivatesOn'}
              patternType={patternType}
              />
          </Grid>
          <Grid item md={6}>
            <RoomCountdownSelect
              label="Count Down"
              queueActivatesOn={dateInfo.queueActivatesOn}
              countdownStartsOn={dateInfo.countdownStartsOn}
              onDateChange={onDateChange}
              roomData={roomData}
              countdownDateValue={countdownDateValue}
              options={options}
              countdownValue={countdownValue}
              handleCountdownChange={handleCountdownChange}
              type={type}
              patternType={patternType}
              disabled={activateIsInThePast}
              minutesDiff={minutesDiff}
            />
          </Grid>
        </Grid>
    )
}


export default RoomDateSelectors;