import React, { useState, useEffect } from "react";
import {
    Typography,
    Grid
} from "@material-ui/core";
import { CopyButton, ErrorSnackBar } from '../';

const PendingCertificate = ({ status, validationInfo, domainName, classes, stripProtocol }) => {
    const [snackPack, setSnackPack] = useState([]);
    const [messageInfo, setMessageInfo] = useState(undefined);
    const [open, setOpen] = useState(false);
    useEffect(() => {
        if (snackPack.length && !messageInfo) {
          // Set a new snack when we don't have an active one
          setMessageInfo({ ...snackPack[0] });
          setSnackPack((prev) => prev.slice(1));
          setOpen(true);
        } else if (snackPack.length && messageInfo && open) {
          // Close an active snack when a new one is added
          setOpen(false);
        }
      }, [snackPack, messageInfo, open]);
    
      const handleClick = (message) => {
        setSnackPack((prev) => [...prev, { message, key: new Date().getTime() }]);
      };
    
      const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpen(false);
      };
    
      const handleExited = () => {
        setMessageInfo(undefined);
      };

      const displayCname = (name) => {

        let str = stripProtocol(name);
        if (str[str.length-1] === ".") {
          str = str.slice(0,-1);
        }
        return str;
      }

    


    return (
        <Grid container alignItems="center">
           
                <>

                    <Grid container spacing={2} className={classes.proxyInfoHeader}>
                        <Grid item>
                            <Typography variant="button">Pending validation</Typography>
                        </Grid>
                    </Grid>


                    <Grid container spacing={2} className={classes.proxyInfo}>
                        <Grid item>
                        <Typography>
                        Here are the DNS instructions required to validate your TLS certificate for your domain. 
                        </Typography>
                        <Typography >
                        <strong>Important:</strong> The displayed DNS record instructions will be invalidated after 72 hours if they are not installed and <strong>new DNS instructions will be provided.</strong>
                        </Typography>

                        <Typography className={classes.pendingPanel}>
                            For domain <code className={classes.code}>{stripProtocol(domainName)}</code> create a <strong>CNAME</strong> record named <code className={classes.code} >{displayCname(validationInfo.name)}
                            <CopyButton text={displayCname(validationInfo.name)} onCopy={(e) => {
                                            handleClick(`${displayCname(validationInfo.name)} copied`);
                                        }} />
                            </code>with the value <code className={classes.code}>{validationInfo.value}
                            <CopyButton text={validationInfo.value} onCopy={(e) => {
                                            handleClick(`${validationInfo.value} copied`);
                                        }} />
                            </code> (trailing period required)
                        </Typography>
                        </Grid>

                    </Grid>


                </>
               

            
        <ErrorSnackBar
            open={open}
            key={messageInfo ? messageInfo.key : undefined}
            autoHideDuration={2000}
            onClose={handleClose}
            onExited={handleExited}
            message={messageInfo ? messageInfo.message : undefined}
        />
        </Grid>
    )

}

export default PendingCertificate;