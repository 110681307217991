
import React from 'react';
import * as Yup from 'yup';
import { withFormik, Form } from 'formik';
import { Grid, Button, FormControl, MenuItem, Typography, IconButton } from "@material-ui/core";
import {ChFormikSelect, ChFormikTextField} from '.';
import TrashIcon from '../icons/TrashIcon';


const taxIds = [ {code: "ae_trn",
value: "United Arab Emirates TRN" },


{code: "au_abn",
value: "Australian Business Number (AU ABN)" },


{code: "br_cnpj",
value: "Brazilian CNPJ number" },


{code: "br_cpf",
value: "Brazilian CPF number" },


{code: "ca_bn",
value: "Canadian BN" },


{code: "ca_qst",
value: "Canadian QST number" },


{code: "ch_vat",
value: "Switzerland VAT number" },

{code: "cl_tin",
value: "Chilean TIN" },


{code: "es_cif",
value: "Spanish CIF number" },


{code: "eu_vat",
value: "European VAT number" },


{code: "hk_br",
value: "Hong Kong BR number" },


{code: "id_npwp",
value: "Indonesian NPWP number" },


{code: "in_gst",
value: "Indian GST number" },


{code: "jp_cn",
value: "Japanese Corporate Number (Hōjin Bangō)" },


{code: "kr_brn",
value: "Korean BRN" },


{code: "li_uid",
value: "Liechtensteinian UID number" },


{code: "mx_rfc",
value: "Mexican RFC number" },


{code: "my_frp",
value: "Malaysian FRP number" },


{code: "my_itn",
value: "Malaysian ITN" },

{code: "my_sst",
value: "Malaysian SST number" },


{code: "no_vat",
value: "Norwegian VAT number" },


{code: "nz_gst",
value: "New Zealand GST number" },


{code: "ru_inn",
value: "Russian INN" },


{code: "sa_vat",
value: "Saudi Arabia VAT" },


{code: "sg_gst",
value: "Singaporean GST" },


{code: "sg_uen",
value: "Singaporean UEN" },


{code: "th_vat",
value: "Thai VAT" },


{code: "tw_vat",
value: "Taiwanese VAT" },


{code: "us_ein",
value: "United States EIN" },


{code: "za_vat",
value: "South African VAT number" }
];


const formikEnhancer = withFormik({
    mapPropsToValues: (props) => {

        return {
            tax_id: props.tax_id ? props.tax_id : 'us_ein',
            tax_number: '',
            tax_status: 'none'
            
        }
    },
    validationSchema: Yup.object().shape({
        tax_number: Yup.string().required('This is a required field'),
        tax_status: Yup.string().required('This is a required field')
    }),
    handleSubmit: (values, { setSubmitting, props }) => {


        props.handleTaxUpdate(values)

        // let {name, ...rest} = values;

        // for (const key in rest) {
        //     if (values.hasOwnProperty(key)) {
        //         const value = values[key];
        //         if (value) {
        //             formattedValues[key] = value;
        //         }                
        //     }
        // }
        // props.handleAddressUpdate(
        //     {
                
        //             address: { ...formattedValues },
        //             name: name
                
        //     }
        // );
    },

    displayName: 'TaxIdsForm', // helps with React DevTools
});

const TaxIdsForm = ({handleReset, tax_ids, setEditTaxInformation, deleteTaxId, handleTaxUpdate, errors, touched}) => {
    
    /*
     * These props are available from the formik form
    */
    
    // const {
    //     values,
    //     touched,
    //     errors,
    //     dirty,
    //     isSubmitting,
    //     handleChange,
    //     handleBlur,
    //     handleSubmit,
    //     handleReset,
    //     disableEditing,
    //     setEditBillingAddress,
    //     address,
    //     CountryCodes
    // } = props;

    return (
        <Form>
            <Grid container spacing={2}>

                
                <Grid item xs={12}>

                    <Typography variant="button">Tax IDs</Typography>
               
                    {tax_ids.data.map((tax_id)=>{
                        return <Grid key={tax_id.id} container justifyContent="space-between" alignItems="center">

                        <Typography>{tax_id.value}</Typography>
                        <IconButton onClick={()=>{deleteTaxId(tax_id)}}>
                            <TrashIcon/>
                        </IconButton>
                        </Grid>
                        })}
                        

                </Grid>
                <Grid item xs={4}>
                    
                <FormControl fullWidth margin="dense" variant="outlined">
                    <ChFormikSelect
                        size="small"
                        type="text"
                        name="tax_id"
                        label="Tax Id"
                        labelWidth={0}
                        notched={false}
                        
                    >

                        {taxIds.map((taxId)=>{
                            return <MenuItem key={taxId.code} value={taxId.code}>{taxId.value}</MenuItem>
                        })}



                    </ChFormikSelect>
                </FormControl>
                </Grid>
                <Grid item xs={8}>
                <FormControl fullWidth margin="dense" variant="outlined">
                    <ChFormikTextField
                        size="small"
                        type="text"
                        name="tax_number"
                        placeholder="Tax Number"
                    >
                    </ChFormikTextField>
                </FormControl>

                </Grid>
                <Grid item xs={12}>

            <Button disableElevation variant="contained" size="small" color="secondary" type="submit"
            style={{"marginRight":"10px"}}>
                Add Tax ID
            </Button>
            <Button disableElevation variant="contained" size="small" color="primary"
            onClick={() => {setEditTaxInformation(false)}}
            style={{"marginRight":"10px"}}>
                Cancel
            </Button>
                </Grid>
            </Grid>

        </Form>
    );
};

export default formikEnhancer(TaxIdsForm);