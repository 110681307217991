import React from 'react';
import { ReactComponent as Dashboard } from './feather/grid.svg';

import { SvgIcon } from '@material-ui/core';


export default function DashboardIcon(props) {
    return (
        <SvgIcon {...props} component={Dashboard} className="feather" />
    );
  }