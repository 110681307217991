import React, { useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import TrashIcon from '../icons/TrashIcon';
import EditIcon from '../icons/EditIcon';
import LayersIcon from '../icons/LayersIcon';
import { Link as RouterLink } from 'react-router-dom';

import { DateTime } from 'luxon';
import { ArrowUpCircleIcon, ArrowDownCircleIcon, ExternalLinkIcon } from '../icons';
import RoomWRLink from './rooms/RoomWRLink';

import Config from '../config'

import {
  Grid,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Tooltip,
  Card,
  CardHeader,
  CardContent,
  Button
} from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  table: {
    // maxWidth: 440,
  },
  paper: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  cell: {
    wordWrap: 'break-word',
    padding: '8px'
  },
  addButton: {
    marginTop: theme.spacing(1)
  }
}));

const StyledTableRow = withStyles((theme) => ({
  root: {
    '& th:nth-of-type(even)': {
      backgroundColor: theme.palette.grey[200],
    },
    '& td:nth-of-type(odd)': {
      backgroundColor: theme.palette.grey[200],
    }
  },
}))(TableRow);

export default function RoomsTable({ id, rooms, name, deleteRoomHandler, state, usedCapacity, canAddRooms, api }) {
  const classes = useStyles();

  const [open, setOpen] = useState(true);

  let formattedRooms = rooms.map((room) => {
    let activateTime = DateTime.fromISO(room.queueActivatesOn, {zone: 'local'});
    let countdownTime = DateTime.fromISO(room.countdownStartsOn, {zone: 'local'});
    var now = DateTime.local();

    if (countdownTime < now) {
      room.countdownStartsOnFormatted = "Active";
    } else {
      room.countdownStartsOnFormatted = `${countdownTime.toFormat(Config.DATE_FORMATS.FULL_NO_TIME)} ${countdownTime.toFormat(Config.DATE_FORMATS.TIME_NO_DAY)}`
    }


    if (activateTime < now) {
      room.queueActivatesOnFormatted = "Active";
    } else {
      room.queueActivatesOnFormatted = `${activateTime.toFormat(Config.DATE_FORMATS.FULL_NO_TIME)} ${activateTime.toFormat(Config.DATE_FORMATS.TIME_NO_DAY)}`
    }

    return room;
  });

  const handleOpen = () => {
    setOpen(!open);
  }

  return (
    <>


      <Grid container component={Card} className={classes.paper}>
        <Grid item xs={12}>
          <CardHeader title={name} onClick={handleOpen} action=
            {
              <IconButton aria-label="settings">
                {
                  (open) ? <ArrowUpCircleIcon /> : <ArrowDownCircleIcon />
                }
              </IconButton>
            }
          >

          </CardHeader>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <CardContent>
              <TableContainer component='div'>
                <Table className={classes.table} aria-label="List of rooms for" >
                  <TableHead>
                    <TableRow>
                      <TableCell component="th" scope="row">Title</TableCell>
                      <TableCell align="center">URL Pattern</TableCell>
                      <TableCell align="center">Capacity</TableCell>
                      <TableCell align="center">Countdown Starts</TableCell>
                      <TableCell align="center">Queue Activates</TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody >
                    {formattedRooms.map((room, rowIndex) => (

                      <StyledTableRow key={room.id}>
                        <TableCell component="th" scope="row" style={{ width: '30%' }} className={classes.cell} data-name={room.title}>{room.title}</TableCell>
                        <TableCell align="center" style={{ width: '20%' }} className={classes.cell} >
                          {(room.patternType === 'all') ? 'ALL' : null}
                          {(room.patternType === 'disabled') ? 'Disabled' : null}
                          {(room.patternType === 'contains') ? room.urlPattern : null}
                          {(room.patternType === 'contains-not') ? `NOT ${room.urlPattern}` : null}
                          {(room.patternType === 'regex') ? 'RegEx' : null}
                          {(room.patternType === 'regex-not') ? 'RegEx NOT' : null}
                        </TableCell>
                        <TableCell align="center" style={{ width: '10%' }} className={classes.cell} >{room.capacity}</TableCell>

                        <TableCell align="center" style={{ width: '10%' }} className={classes.cell} >
                          {room.countdownStartsOnFormatted}
                          {/* {DateTime.fromISO(room.countdownStartsOn).toLocaleString(DateTime.DATETIME_MED)} */}
                          </TableCell>
                        <TableCell align="center" style={{ width: '10%' }} className={classes.cell} >
                          {room.queueActivatesOnFormatted}
                          {/* {DateTime.fromISO(room.queueActivatesOn).toLocaleString(DateTime.DATETIME_MED)} */}
                          </TableCell>

                        <TableCell align="center">


                        {room.countdownStartsOnFormatted === 'Active' || room.countdownStartsOnFormatted === 'Active'
                        ?
                        

                        <RoomWRLink color="secondary" api={api} slug={room.slug} countDown={room.countdownStartsOn} room={room}/>
                       
                        :

                        <Tooltip title="Inactive Waiting Room" aria-label="Inactive Waiting Room">
                        <IconButton
                        component="span"

                        >
                        <ExternalLinkIcon color="secondary" style={{color: 'gray'}}/>
                        </IconButton>
                        </Tooltip>



                        }


                        </TableCell>

                        <TableCell align="center">
                          <Tooltip title="Sessions">
                            <IconButton
                              component={RouterLink}
                              to={{ 
                                pathname: '/rooms/sessions', 
                                state: {
                                  domainID: room.domainID,
                                  roomID: room.id,
                                  archivedUsers: 0,
                                  includeActiveUsers: 1,
                                  pageSize: 200
                              } 
                              }}>
                              <LayersIcon color="secondary"/>
                            </IconButton>
                          </Tooltip>
                        </TableCell>

                        <TableCell align="center">
                          <Tooltip title="Edit">
                            <IconButton
                                component={RouterLink}
                                to={`/rooms/edit/${room.id}`}
                              >
                              <EditIcon color="secondary"/>
                            </IconButton>
                          </Tooltip>
                        </TableCell>

                        <TableCell align="center">
                          <Tooltip title="Delete">
                            <IconButton onClick={() => { deleteRoomHandler(room) }}>
                              <TrashIcon ></TrashIcon>
                            </IconButton>
                          </Tooltip>
                        </TableCell>

                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              {
              (canAddRooms)
                ?
                <Grid container justifyContent="flex-end">

                <Button
                  className={classes.addButton}
                  component={RouterLink}
                  to={`/rooms/add/${id}`}
                  variant="contained"
                  disableElevation
                  size="small"
                  color="secondary">
                  Add Room</Button>
                </Grid>
                :
                null
            }

            </CardContent>
          </Collapse>
        </Grid>
      </Grid>



    </>
  );
}
