import React from "react";
import {
  Grid,
  FormControl,
  Typography,
  Select,
  MenuItem,
} from '@material-ui/core';

const RoomCountdownSelect = ({handleCountdownChange, label, options, countdownValue, patternType, disabled }) => {
  
  
  return (

    <Grid container>
      <Grid item xs={12}>
        <Typography>{label}</Typography>

      <FormControl margin="dense" size="small" variant="outlined">

        <Select
          style={{backgroundColor: '#fff'}}
          value={countdownValue}
          onChange={
            (event) => {
              handleCountdownChange(event.target.value)
            }
          }
          label="Countdown"
          notched={false}
          disabled={(patternType === 'disabled' || disabled) ? true : false}
        >
          {
            options && options.map((option, index) => {
              // return <MenuItem disabled={option.threshold > minutesDiff} key={index} value={index}>{option.label}</MenuItem>
              return <MenuItem key={index} value={index}>{option.label}</MenuItem>
            })
          }
        </Select>
      </FormControl>


    </Grid>
    </Grid>
  )

}

export default RoomCountdownSelect;