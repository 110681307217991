import {
    CognitoUserPool,
    CognitoUser,
    CognitoUserSession,
    CognitoIdToken,
    CognitoAccessToken,
    CognitoRefreshToken,
} from 'amazon-cognito-identity-js';
import * as AWS from 'aws-sdk/global';


export const handleSignInSuccess = async (response) => {

    var poolData = {
        UserPoolId: response.userPoolId, // Your user pool id here
        ClientId: response.clientId, // Your client id here
      };

      var userPool = new CognitoUserPool(poolData);
      var userData = {
        Username: response.sub,
        Pool: userPool,
      };
      var cognitoUser = new CognitoUser(userData);

      const tokens = {
        IdToken: response.IdToken,
        AccessToken: response.AccessToken,
        RefreshToken: response.RefreshToken,
      }
      cognitoUser.setSignInUserSession(new CognitoUserSession({
        IdToken: new CognitoIdToken(tokens),
        AccessToken: new CognitoAccessToken(tokens),
        RefreshToken: new CognitoRefreshToken(tokens),
      }));

      AWS.config.region = response.region;

      // Add the User's Id Token to the Cognito credentials login map.
      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: response.identityPoolId,
        Logins: {
          [`cognito-idp.${response.region}.amazonaws.com/${response.userPoolId}`]: response.IdToken
        },
      });

      await AWS.config.credentials.refresh((error) => {
        if (error) {
          console.error(error);
        }
      });


      return {cognitoUser};
    
}