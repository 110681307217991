import React from "react";
import {
  Typography,
  Grid, 
  Link,
} from "@material-ui/core";



const DotNetOnboarding = () => {



  return (

    <Grid container spacing={4}  >

      <Grid item xs={12}>

        <Typography variant="body2" color="textSecondary" component="p">
          To install the .NET integration check out our guide:<br /><Link target="_blank" href="https://www.crowdhandler.com/docs/80001059728--net-integration-quick-start-guide">
            .NET Integration - Getting Started
          </Link>
        </Typography>





      </Grid>



    </Grid>
  )

}

export default DotNetOnboarding;