import React, { useState, useEffect, useCallback } from "react";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
    Container,
    Button,
    Grid,
    Paper,
    Backdrop,
    CircularProgress,
    Typography,
    Link
} from "@material-ui/core";
import { withRouter } from 'react-router-dom'; // To use the history object
import ErrorSnackBar from '../../components/ErrorSnackBar';
import DomainOptions from '../../components/DomainOptions';
import { AlertTriangleIcon } from "icons";
import theme from "theme";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    margin: {
        margin: theme.spacing(6),
    },
    noUnderline: {
        '& .MuiInput-underline:before': {
            display: 'none'
        },
        '& .MuiInput-underline:after': {
            display: 'none'
        }
    },
    smallmargin: {
        marginBottom: theme.spacing(2),
    },
    withoutLabel: {
        marginTop: theme.spacing(6),
    },
    domainAdornment: {
        marginRight: 0
    },
    spinner: {
        marginLeft: 22,
        color: theme.palette.secondary
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: theme.palette.secondary.main,
    },
    section: {
        margin: '0'
    },
    paper: {
        padding: theme.spacing(4),
        marginBottom: theme.spacing(2),
    },
    warningPanel: {
        marginBottom: 20
    }

}));

const WarningPaper = withStyles({
    root: {
        padding: 20,
        marginBottom: 20,

        '& .warnings-header' : {
            marginBottom: 20,
        },
        '& .session-warning' : {
            marginBottom: 20,
            marginTop: 20,
            
            '& .MuiGrid-item' : {
                marginBottom: 16,
            },
            '& .MuiGrid-item:last-of-type' : {
                marginBottom: 0,
            },
            '& .recommendations-title' : {
                marginBottom: 4,
                fontWeight: 400,
                fontSize: '1rem'
            }
        }
    },
})(Paper);

const AlertTriangleIconOrange = withStyles({
    root: {
        color: theme.palette.secondary.main,
        marginRight: 10
    },
})(AlertTriangleIcon);

const DomainsEdit = ({ state, computedMatch, history, api, ...rest }) => {

    const classes = useStyles();
    const { dispatch } = rest;
    let { domains, plan } = state;


    const [isEditing] = useState(computedMatch.params.domainId);
    const [ready, setReady] = useState((isEditing) ? false : true);
    const [processing, setProcessing] = React.useState(false);

    let [message, setMessage] = useState('');
    let [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
    let [alertBarStatus, setAlertBarStatus] = useState('success');
    let [sessionMessage, setSessionMessage] = useState();
    let [allowAutotune, setAllowAutotune] = useState(false);
    let [autotuneWarning, setAutotuneWarning] = useState(false);
    let [showSessionWarning, setShowSessionWarning] = useState(false);
    let [currentDomain, setCurrentDomain] = useState(false);

    const closeErrorSnackbar = () => {
        setOpenErrorSnackbar(false)
    }

    const [settings, setSettings] = useState({
        autotune: false,
        deployment: 'javascript',
        maxSlowRequestPercent: 2,
        slowRequestThreshold: 5000,
        timeout: 20,
        rate: 10,
        id: undefined,
        checkout: '',
        destroySessionsOnCheckout: true,
        oneInOneOut: true,
        trackStock: false,
        maxTransacting: 5000,
        maxSessionsPerIP: 20,
        sessionsExpireWhilstWaiting: false,
        fingerprint: 'none',
        blockThreats: []
    });

    useEffect(() => {
        const getDomains = async () => {
            let domains = await api.getDomains();
            dispatch({ type: 'ADD_DOMAINS', payload: domains });
        }
        if (state.domains === undefined) {
            getDomains();
        }
    }, [api, state.domains, dispatch]);


    useEffect(() => {
        const getRooms = async () => {
            let rooms = await api.getRooms();
            dispatch({ type: 'UPDATE_ROOMS', payload: rooms });
        }
        if (state.rooms === undefined) {
            getRooms();
        }
    }, [api, state.rooms, dispatch]);

    useEffect(() => {
        const getCurrentDomainData = async () => {

            if (computedMatch) {
                if (computedMatch.params.domainId) {
                    let domain = domains.filter(domain => {
                        return domain.id === computedMatch.params.domainId;
                    });
                    if (domain.length > 0) {

                        let atSetting = (domain[0].autotune === 1) ? true : false;
                        if (domain[0].deployment === 'shopifyjs') {
                            atSetting = false;
                        } 

                        let formattedValues = {
                            autotune: atSetting,
                            destroySessionsOnCheckout: (domain[0].destroySessionsOnCheckout === 1) ? true : false,
                            sessionsExpireWhilstWaiting: (domain[0].sessionsExpireWhilstWaiting === 1) ? true : false,
                            oneInOneOut: (domain[0].oneInOneOut === 1) ? true : false,
                            trackStock: (domain[0].trackStock === 1) ? true : false,
                        }
                        let mergeValues = {
                            ...domain[0],
                            ...formattedValues
                        }

                        setCurrentDomain({ ...mergeValues });
                        setSettings(mergeValues);
                        setReady(true);


                    }
                }
            }
        }
        if (state.domains !== undefined) {
            getCurrentDomainData();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.domains]);


    /**
       * Set Formik validation and Submit Handler
       */


    const formik = useFormik({
        enableReinitialize: true,
        validateOnChange: true,
        initialValues: { ...settings },
        validationSchema: Yup.object({
            checkout: Yup.string()
                .when('destroySessionsOnCheckout', {
                    is: (val) => {
                        return val === true;
                    }, // alternatively: (val) => val == true
                    then: Yup.string().required('Required when Destroy Session on Checkout is enabled')
                        .test('start-with-slash', 'Must start with a forward slash',
                            function (value) {
                                let starts_with_a_slash = /^\/{1}/;
                                let starts_with_a_slash_result = starts_with_a_slash.test(value);
                                return starts_with_a_slash_result;
                            })
                        .test('contains-whitespacec', 'Spaces are not allowed',
                            function (value) {
                                let white_space = /\s+/;
                                let white_space_result = white_space.test(value);
                                return !white_space_result;
                            })
                        .test('no-single-slash', 'This will cause all paths to destroy the session',
                            function (value) {
                                var inValid = /^[/]+$/;
                                let single_slash = inValid.test(value)
                                return !single_slash;
                            }),
                }),
            rate: Yup.number().max(plan.maxDomainRate, `Maximium value is ${plan.maxDomainRate}`),
            timeout: Yup.number().max(30, `Maximium timeout is 30`)
        }),
        onSubmit: async (values) => {

            // check domain name:

            if (values.timeout > 30) {
                values.timeout = 30;
            }

            setProcessing(true);
            let method = 'put';

            setSettings(values);

            let formattedData = {
                id: values.id,
                autotune: (values.autotune) ? 1 : 0,
                oneInOneOut: (values.oneInOneOut) ? 1 : 0,
                trackStock: (values.trackStock) ? 1 : 0,
                sessionsExpireWhilstWaiting: (values.sessionsExpireWhilstWaiting) ? 1 : 0,
                destroySessionsOnCheckout: (values.destroySessionsOnCheckout) ? 1 : 0,
                rate: parseInt(values.rate, 10),
                slowRequestThreshold: parseInt(values.slowRequestThreshold, 10),
                maxSlowRequestPercent: parseInt(values.maxSlowRequestPercent, 10),
                timeout: parseInt(values.timeout, 10),
                checkout: (values.checkout) ? values.checkout : null,
                blockThreats: values.blockThreats
            }



            let mergedData = { ...values, ...formattedData };

            if (mergedData.autotuneRate) {
                delete (mergedData.autotuneRate);
            }
            saveDomain(mergedData, method);

        },
    });

    const saveDomain = async (data, method) => {
        let result = await api.putDomain(data, method);

        if (!result.error) {
            dispatch({ type: 'UPDATE_DOMAINS', payload: result });
            dispatch({ type: 'UPDATE_SELECTED_DOMAIN', payload: data.id });

            let formattedValues = {
                autotune: (result.autotune === 1) ? true : false,
                destroySessionsOnCheckout: (result.destroySessionsOnCheckout === 1) ? true : false,
                // checkout: (result.checkout === "") ? "/" : result.checkout,
                sessionsExpireWhilstWaiting: (result.sessionsExpireWhilstWaiting === 1) ? true : false,
                oneInOneOut: (result.oneInOneOut === 1) ? true : false,
                trackStock: (result.trackStock === 1) ? true : false,
            }
            let mergeValues = {
                ...result,
                ...formattedValues
            }


            setSettings(mergeValues);
            setProcessing(false);
            setMessage('Domain Updated');
            setAlertBarStatus('success');
            setOpenErrorSnackbar(true);

        } else {
            setProcessing(false);
            setMessage('Error updating domain');
            setAlertBarStatus('error');
            setOpenErrorSnackbar(true);
        }
    }

    const checkOneInOneOut = useCallback((values) => {

        let avg_user_journey_in_mins = 10;
        let avg_checkout_rate = 0.5;

        let showWarning = false;

        try {
            let max_active_sessions = currentDomain.maxTransacting;
            let session_timeout = currentDomain.timeout;
            let destroy_sessions = currentDomain.destroySessionsOnCheckout;
            let one_in_one_out = currentDomain.oneInOneOut;
            let destroy_sessions_rate = 1;
            if (destroy_sessions) {
                destroy_sessions_rate = 1 - avg_checkout_rate;
            }

            let message = [];
            let recommendations = [];
            let effective_rate = max_active_sessions / (avg_user_journey_in_mins + (session_timeout * destroy_sessions_rate));
            let fixed_rate = Math.ceil(effective_rate);




            if ((effective_rate / currentDomain.rate < 0.8) && max_active_sessions && one_in_one_out) {
                message.push(`These settings are likely to result in fewer active sessions than you are expecting, and long, slow moving queues.`);
                message.push(`You will let in approximately <strong>${fixed_rate} users per minute</strong> when you reach <strong>${max_active_sessions} active sessions</strong> (given an average user journey of ${avg_user_journey_in_mins} minutes
                ${(destroy_sessions) ? ` and an average checkout ratio of ${avg_checkout_rate * 100}%` : ''})`);

                recommendations.push('Consider raising your active session limit.');
                if (session_timeout) {
                    recommendations.push('Consider reducing session timeout.');
                }
                if (!destroy_sessions) {
                    recommendations.push('Consider adding a confirmation URL to destroy sessions on checkout.');
                }

                let signoff = `If ${fixed_rate} users per minute is what you want, <strong>change the rate to ${fixed_rate}</strong>, and this warning will go away.`

                setSessionMessage((prev) => {

                    return {
                        ...prev,
                        message,
                        recommendations,
                        signoff,
                    }

                });




                showWarning = true;

            } else {
                setSessionMessage((prev) => {

                    return {
                        ...prev,
                        message: undefined,
                        recommendations: undefined,
                        signoff: undefined,
                    }

                });
            }

            if (!allowAutotune && state.plan.allowAutotune === 1 && currentDomain.autotune) {

                let autotuneWarning = <><Typography>To ensure autotune effectiveness, we recommend implementing a <Link target="_blank" href="https://www.crowdhandler.com/docs/80000184170-catch-all-waiting-rooms-">catch-all waiting room</Link>.</Typography><Typography>This will provide CrowdHandler with sufficient visibility into page performance.</Typography></>

                setSessionMessage((prev) => {
                    return {
                        ...prev,
                        autotuneWarning
                    }
                })
                showWarning = true;


            } else {
                setSessionMessage((prev) => {
                    return {
                        ...prev,
                        autotuneWarning: undefined
                    }
                })
            }

        } catch (error) {
            showWarning = false;
            setSessionMessage(undefined)
            console.log(error);
            return false;
        }

        return showWarning;

    }, [allowAutotune, currentDomain, state.plan.allowAutotune]);


    useEffect(() => {
        if (currentDomain) {
            let result = checkOneInOneOut();
            setShowSessionWarning(prev => result);
        }
    }, [currentDomain, checkOneInOneOut]);


    useEffect(() => {
        const checkAllowAutotune = () => {
            let allow = false;
            const domain_by_rooms = state.rooms.filter((room) => {
                return room.domainID === settings.id;
            });

            for (let i = 0; i < domain_by_rooms.length; i++) {
                const room = domain_by_rooms[i];
                if ((room.patternType === 'all')) {
                    allow = true;
                }
            }
            setAllowAutotune(allow);



        }

        if (state.rooms && state.rooms.length && settings.id) {
            checkAllowAutotune();
        }


    }, [state.rooms, settings.id, setAllowAutotune])


    return (
        <>
            {ready
                ?

                <Container maxWidth="lg" spacing={0} className={classes.root} >

                    <form autoComplete="off" onSubmit={formik.handleSubmit}>

                        <Paper className={classes.paper}>

                            {showSessionWarning && settings.deployment !== 'shopifyjs' ?
                            <WarningPaper elevation={2} >
                                <Grid spacing={2} container variant="outlined" className={classes.warningPanel} direction="column">



                                    <Grid item>

                                        <Grid container alignItems="center" className="warnings-header">
                                            <Grid item>
                                                <AlertTriangleIconOrange />
                                            </Grid>
                                            <Grid item>
                                                {sessionMessage.autotuneWarning && sessionMessage.message
                                                ?
                                                <Typography variant="h6" component="p">Warnings</Typography>
                                                :
                                                <Typography variant="h6" component="p">Warning</Typography>
                                                }
                                            </Grid>
                                        </Grid>

                                        {sessionMessage.autotuneWarning ?
                                            <Grid item className="autotune-warning">
                                                <Typography variant="h6" component="p" style={{ marginBottom: 4 }}>Autotune</Typography>
                                                {sessionMessage.autotuneWarning}
                                            </Grid>
                                            : null}

                                        {sessionMessage.message ?
                                        <div className="session-warning">

                                        
                                            <Grid item>
                                                <Typography variant="h6" component="p" style={{ marginBottom: 4 }}>One in one out</Typography>

                                                {sessionMessage.message && sessionMessage.message.map((message, i) => {
                                                    return (
                                                       
                                                            <Typography key={i} dangerouslySetInnerHTML={{ __html: message }}></Typography>
                                                      
                                                    )
                                                })}

                                            </Grid>

                                            <Grid item xs={12}>
                                                <Typography variant="h6" component="p" className="recommendations-title">Recommendations</Typography>
                                                <Typography>Consider switching off one in one out, or:</Typography>
                                                <ul style={{ marginTop: 0 }}>
                                                    {sessionMessage.recommendations && sessionMessage.recommendations.map((message, i) => {
                                                        return <li key={i}><Typography>{message}</Typography></li>
                                                    })}
                                                </ul>
                                            </Grid>

                                            </div>
                                            :
                                            null}

                                        {sessionMessage.signoff
                                            ?
                                            <Grid item>
                                                <Typography dangerouslySetInnerHTML={{ __html: sessionMessage.signoff }}></Typography>
                                            </Grid>
                                            : null
                                        }

                                    </Grid>

                                </Grid>
                            </WarningPaper>
                            : null}
                            <Grid container component={Paper} elevation={0} spacing={0}>
                                <DomainOptions state={state} settings={settings} formik={formik} allowAutotune={allowAutotune} currentDomain={currentDomain}/>
                            </Grid>

                            <Grid item xs={12} align="right">
                                <Button
                                    disableElevation
                                    color="primary"
                                    onClick={history.goBack}>
                                    Close</Button>
                                <Button
                                    style={{ marginLeft: 8 }}
                                    type="submit"
                                    disableElevation
                                    variant="contained"
                                    color="secondary"
                                    disabled={!formik.isValid}
                                >
                                    Save Domain</Button>
                            </Grid>
                        </Paper>

                    </form>

                    <Backdrop className={classes.backdrop} open={processing}>
                        <CircularProgress color="inherit" disableShrink />
                    </Backdrop>
                    <ErrorSnackBar message={message} open={openErrorSnackbar} status={alertBarStatus} closeErrorSnackbar={closeErrorSnackbar} />

                </Container>
                :
                null
            }
        </>
    );
};

export default withRouter(DomainsEdit);