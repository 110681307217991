import React from "react";
import { DateTime } from 'luxon';
import { DatePicker } from '@material-ui/pickers/';
import { makeStyles } from '@material-ui/core/styles';
import { TimePicker } from "@material-ui/pickers";

import {
  Grid,
  FormControl,
  Typography,
} from '@material-ui/core';


import RoomActiveCircle from "./RoomActiveCircle";


const useStyles = makeStyles((theme) => ({
  datePicker: {
    backgroundColor: '#fff',
    '& .MuiOutlinedInput-input': {
      padding: '10.5px 14px'
    }
  },
  timeGroup: {
    display: 'flex',
    alignItems: 'center'
  },
  dateGroup: {
    alignItems: 'center',
    '& .MuiFormControl-marginDense': {
      paddingRight: '5px'
    },
    '& .MuiSelect-select': {
      textAlign: 'right',
      padding: '10.5px 10px',
      paddingRight: '30px',
    }
  }
}));

const RoomDateTimePicker = ({ dateTime, dateFormat, label, onDateChange, fieldName, minDate, maxDate, patternType,  ...props }) => {


  const classes = useStyles();

  const onTimeChange = (value) => {

    if(value && value.isValid) {
      onDateChange(value, fieldName);
    }

  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography>{label}</Typography>
        <Grid container className={classes.dateGroup}>
          <Grid item xs={'auto'}>
            <FormControl margin="dense">
              
              <DatePicker
                className={classes.datePicker}
                inputVariant="outlined"
                onChange={(date, value) => {
                  onTimeChange(date, 'date')
                }}
                disabled={(patternType === 'disabled')?true:false}
                value={DateTime.fromISO(dateTime.date.toISO(), {zone:  'local'})}
                format={dateFormat}
                {...props}
              />
            </FormControl>
          </Grid>
          <Grid item xs={'auto'} className={classes.timeGroup}>
          <FormControl margin="dense">
            <TimePicker
              className={classes.datePicker}
              inputVariant="outlined"
              value={ DateTime.fromISO(dateTime.date.toISO(), {zone:  'local'}) }
              onChange={date => onTimeChange(date)}
              disabled={(patternType === 'disabled')?true:false}
              {...props}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )

}

export default RoomDateTimePicker;