import React from 'react';
import { ReactComponent as ChevronLeft } from './feather/chevron-left.svg';

import { SvgIcon } from '@material-ui/core';


export default function ChevronLeftIcon(props) {
    return (
        <SvgIcon {...props} component={ChevronLeft} className="feather"/>
    );
  }