import React, {useState, useEffect} from 'react'
import { Grid, Typography, Chip } from '@material-ui/core';
import Config from '../../config';
import { DateTime } from 'luxon';
import { makeStyles, useTheme } from '@material-ui/core/styles';

const plansAndPrices = require(`../../lib/plans_prices_${process.env.REACT_APP_ENV}.json`);


const useStyles = makeStyles(theme => ({
      globalMessageContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        [theme.breakpoints.up('lg')]: {
            justifyContent: 'center',
        },
    },
    globalMessage: {
        zIndex: theme.zIndex.drawer + 1,
        color: theme.palette.secondary.main,
        borderRadius: 0,
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      }
}));

const GlobalMessaging = ({downgradePending, accountInformation, plan, plans, currentPlanId, dispatch, customPlan, globalMessage}) => {

    const classes = useStyles();
    const dateFormat = Config.DATE_FORMATS.FULL_NO_TIME;

    useEffect(() => {

        const formatMessaging = () => {
            let isDowngrade;
            let is_temp_upgrade;
            let current_product_id;
            let latest_invoice;
            let upcoming_invoice;
            let db_product_id;
            
            try {
                isDowngrade = false;
                is_temp_upgrade = false;
                current_product_id = accountInformation ? accountInformation.get('productId') : '';
                latest_invoice = accountInformation ? accountInformation.get('latest_invoice') : '';
                upcoming_invoice = accountInformation ? accountInformation.get('upcoming_invoice') : '';
                db_product_id = plan.s_id;
                
            } catch (error) {
                console.log(error);
                return false;
            }

            if (!current_product_id || !latest_invoice || !upcoming_invoice){
                return false;
            }

            //prices are different so calculate what to do
            if (current_product_id !== db_product_id) {

                // fetch the product that matches the current_product_id from stripe
                let current_prices = plansAndPrices.filter((product) => {
                    return product.id === current_product_id;
                })

                // fetch the product that matches the id from the db
                let db_prices = plansAndPrices.filter((product) => {
                    return product.id === db_product_id;
                })

                let current_price = [];
                let db_price = [];

                // get the current price based on the customers currency
                if (current_prices.length) {
                    current_price = current_prices[0].prices.filter((price) => {
                        return price.currency === accountInformation.get('cust_currency');
                    })
                }
                // get the database price based on the customers currency
                if (db_prices.length) {
                    db_price = db_prices[0].prices.filter((price) => {
                    return price.currency === accountInformation.get('cust_currency');
                    })
                }

                // if we have current and db prices and the current price is less than
                // the db plan on the db then we have a temporary upgrade
                if (current_price.length && db_price.length) {
                    if (current_price[0].unit_amount < db_price[0].unit_amount) {
                        is_temp_upgrade = true;
                        isDowngrade = true;
                    }
                    dispatch({type:'SET_TEMP_UPGRADE', payload: is_temp_upgrade});
                }


            // if the upcoming invoice price is less than the current price then we have a downgrdae upcoming
            // and finally, if they're on a pln_30Day then we have a downgrade coming
                
            }

            if (upcoming_invoice.lines.data[upcoming_invoice.lines.data.length - 1].amount < accountInformation.get('price') || is_temp_upgrade) {
                isDowngrade = true;
            } else if (plan.id === 'pln_30Day') {
                isDowngrade = true;
            }

            if (isDowngrade && !customPlan) {
                try {
                    let nextLine = upcoming_invoice.lines.data[upcoming_invoice.lines.data.length - 1];
                    let upcomingPlan = plans.filter((plan) => {
                        return plan.id === nextLine.plan.product;
                    });

                    let period_end = DateTime.fromSeconds(upcoming_invoice.period_end).toFormat(dateFormat);
                    let to_plan = '';
                    if (upcomingPlan[0]) {
                        to_plan = `to ${upcomingPlan[0].name} `
                    }
    
                    let message = `Note: your account will downgrade ${to_plan}on ${period_end}.`;
                    
                    //Is pln_30Day?
                    if (currentPlanId === 'pln_30Day') {
                        message = <span><Chip color="secondary" label="Trial Plan" component={'span'} style={{ marginRight: '8px' }} /> {message}</span>;
                    }
    
                    dispatch({ type: 'SET_GLOBAL_MESSAGE', payload: message })
                    dispatch({ type: 'SET_DOWNGRADE_PENDING', payload: true })
                } catch (error) {
                    console.log(error);
                }
            }

        }

        if (accountInformation && plan ) {
            formatMessaging();
        }

        return () => {
            
        }
    }, [accountInformation, currentPlanId, customPlan, dateFormat, dispatch, plan, plans]);
    


    return (
        globalMessage ?
            <Grid item>
                <Grid container className={classes.globalMessageContainer}>
                    <Grid item className={classes.globalMessage}>
                        <Typography variant="body2">
                            {globalMessage}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        : null
    )

}

export default GlobalMessaging;